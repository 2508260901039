import { CostManagementRootState, WBSRow } from '../../stateTypes';
import {
  getDataByTimeInterval,
  getDataByTimeIntervalMap,
  TimeInterval
} from '../../../components/Project/ProjectDashboard/utils/chart';
import { isPastPeriodDate } from '../../../helpers/date';
import {
  CostManagementDashboardScenarioReportingPeriod
} from '../../../types/enums/CostManagementDashboardScenarioReportingPeriod';


const getLeafNodeForecast = (
  state: CostManagementRootState,
  currentRow: WBSRow,
  period?: string,
  doNotMatchEstimateToComplete?: boolean
): Record<string, number> => {

  const reportingPeriod = (state.selectedScenario?.reportingPeriod as TimeInterval) ?? 'Month';

  let result = getDataByTimeIntervalMap(
    currentRow.monthlyCosts.actual,
    reportingPeriod as TimeInterval,
    currentRow.monthlyCosts.startDate,
    currentRow.monthlyCosts.endDate
  );

  const rowEstimateToComplete = currentRow.estimateToComplete ?? 0;
  const pastDateKeys = new Set<string>();
  let currentAndFutureDatesTotal = 0;
  let allFutureValuesAre0 = true;
  let numCurrentAndFutureDates = 0;
  for (const [key, value] of Object.entries(result)) {
    const numericValue = value ?? 0;

    if (isPastPeriodDate(reportingPeriod as CostManagementDashboardScenarioReportingPeriod, key)) {
      pastDateKeys.add(key);
    }
    else {
      currentAndFutureDatesTotal += numericValue;
      numCurrentAndFutureDates++;
      if (numericValue !== 0) {
        allFutureValuesAre0 = false;
      }
    }
  }

  let currentAndFutureDatesMultiplier = (currentAndFutureDatesTotal <= 0 || Math.abs(currentAndFutureDatesTotal) < 0.001) ?
    1 :
    (rowEstimateToComplete / currentAndFutureDatesTotal);

  if (state.selectedScenario) {
    const curveData = state.selectedScenario.curveData.find(
      (curve) => (curve.wbsId === currentRow.id && curve.reportingPeriod === reportingPeriod && curve.period === period)
    );

    if (curveData?.curveData) {
      currentAndFutureDatesTotal = 0;
      allFutureValuesAre0 = true;
      numCurrentAndFutureDates = 0;
      for (const [key, value] of Object.entries(curveData.curveData)) {
        const numericValue = value ?? 0;

        if (isPastPeriodDate(reportingPeriod as CostManagementDashboardScenarioReportingPeriod, key)) {
          pastDateKeys.add(key);
        }
        else {
          currentAndFutureDatesTotal += numericValue;
          numCurrentAndFutureDates++;
          if (numericValue !== 0) {
            allFutureValuesAre0 = false;
          }
        }
      }

      currentAndFutureDatesMultiplier = (Math.abs(currentAndFutureDatesTotal) < 0.001) ?
        1 :
        (rowEstimateToComplete / currentAndFutureDatesTotal);

      const newResult = { ...curveData.curveData };
      for (const key of Object.keys(result)) {
        if (newResult[key] === undefined) {
          newResult[key] = 0;
        }
      }

      result = newResult;
    }
  }

  for (const key of Object.keys(result)) {
    if (result[key] === undefined || result[key] === null) {
      result[key] = 0;
    }

    if (!doNotMatchEstimateToComplete && !pastDateKeys.has(key)) {
      result[key] = (allFutureValuesAre0) ?
        (rowEstimateToComplete / numCurrentAndFutureDates) :
        (result[key] * (currentAndFutureDatesMultiplier || 1));
    }
  }

  return result;
};

const getNonLeafNodeForecast = (
  state: CostManagementRootState,
  currentRow: WBSRow,
  period?: string,
  doNotMatchEstimateToComplete?: boolean
): Record<string, number> => {

  const reportingPeriod = (state.selectedScenario?.reportingPeriod as TimeInterval) ?? 'Month';
  const leafNodes = (state.wbsLookup.codeLookup[currentRow.wbsCode]?.leafRows ?? []) as WBSRow[];

  const wbsRowForecastTable = leafNodes.reduce((map, row) => {

    map[row.id] = getLeafNodeForecast(state, row, period, doNotMatchEstimateToComplete);
    return map;
  }, {} as Record<number, Record<string, number>>);

  const [labels] = getDataByTimeInterval(
    currentRow.monthlyCosts.actual,
    reportingPeriod as TimeInterval,
    currentRow.monthlyCosts.startDate,
    currentRow.monthlyCosts.endDate
  );

  const rowIds = Object.keys(wbsRowForecastTable) as unknown as number[];
  const result = {} as Record<string, number>;
  labels.forEach((rowLabel) => {

    result[rowLabel] = 0;
    rowIds.forEach((rowId) => {

      result[rowLabel] += wbsRowForecastTable[rowId][rowLabel] ?? 0;
    });
  });

  return result;
};

export const getRowForecast = (
  state: CostManagementRootState,
  currentRow: WBSRow,
  period?: string,
  doNotMatchEstimateToComplete?: boolean
): Record<string, number> => {

  const children = (state.wbsLookup.codeLookup[currentRow.wbsCode]?.children ?? []) as WBSRow[];

  if (children.length === 0) {
    return getLeafNodeForecast(state, currentRow, period, doNotMatchEstimateToComplete);
  }

  return getNonLeafNodeForecast(state, currentRow, period, doNotMatchEstimateToComplete);
};
