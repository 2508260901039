import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import FullScreenDialog from '../Dialogs/FullScreenDialog';


const useStyles = makeStyles((theme) => ({
  closeToDue: {
    color: theme.palette.app.errorText
  },
  deadlineRow: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.875rem',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,

    '&.odd': {
      backgroundColor: theme.palette.primary.light + '80'
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'A0',
      borderRadius: '5px'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    width: '100%'
  },
  rowHalf: {
    width: 'calc(50% - 10px)',
    textAlign: 'left',

    '&.alignRight': {
      textAlign: 'right'
    }
  }
}));

const Deadlines = (props) => {

  const classes = useStyles();
  const history = useHistory();

  const {
    deadlines,
    isFullScreen,
    onCloseFullScreen
  } = props;

  const handleDeadlineClick = useCallback((evt) => {

    const { projectId, type } = evt.currentTarget.dataset;

    if (type === 'sectionPhase' || type === 'project') {
      history.push(`/projects/${projectId}`);
    }
  }, [history]);

  const deadlinesList = useMemo(() => {

    if (!deadlines) {
      return null;
    }

    return deadlines.map((deadline, index) => {

      const dueDateClass = (deadline.isCloseToDue) ? classes.closeToDue : undefined;
      const rowBackgroundClass = (index % 2 !== 0) ? ' odd' : '';

      let link = null;
      let key;
      if (deadline.type === 'project') {
        key = deadline.projectId;
        link = <span><u>{deadline.projectName}</u></span>;
      }
      else if (deadline.type === 'sectionPhase') {
        key = `p${deadline.phaseId}`;
        link = <span><u>{deadline.projectName}</u>: </span>;
      }

      return (
        <div
          key={key}
          className={classes.deadlineRow + rowBackgroundClass}
          data-project-id={deadline.projectId}
          data-type={deadline.type}
          data-cy="deadlines-list.deadline-row"
          onClick={handleDeadlineClick}
        >
          <span className={classes.rowHalf}>{link}{deadline.name || ''}</span>
          <span className={classes.rowHalf + ' ' + dueDateClass + ' alignRight'}>{deadline.dueDateDisplay}</span>
        </div>
      );
    });
  }, [classes, deadlines, handleDeadlineClick]);

  const componentOutput = useMemo(() => {

    return (
      <div
        className={classes.root}
        data-cy="deadlines-list"
      >
        {deadlinesList}
      </div>
    );
  }, [
    classes,
    deadlinesList
  ]);

  return useMemo(() => {

    if (isFullScreen) {
      return (
        <FullScreenDialog
          title="Deadlines"
          content={componentOutput}
          onClose={onCloseFullScreen}
          open={true}
        />
      );
    }

    return componentOutput;

  }, [componentOutput, isFullScreen, onCloseFullScreen]);
};

export default Deadlines;
