import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';

import api from '../../api';

import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';


const FileHistory = (props) => {

  const {
    anchorElement,
    attachmentNumber,
    canUpload,
    pastFileVersions,
    onClose,
    onUpload,
    originalAttachmentId,
    scope
  } = props;

  const handleFileVersionClick = useCallback((evt) => window.open(evt.currentTarget.dataset.href, '_blank'), []);

  const handleUploadClicked = useCallback((evt) => {

    onUpload(originalAttachmentId, scope);
  }, [onUpload, originalAttachmentId, scope]);

  const renderMenuItem = useCallback((fileInfo, idx) => {

    let fileUrl;
    switch (scope) {
      case 'bid':
        fileUrl = api.Project.GetBidAttachmentUrl(fileInfo.id);
        break;
      case 'item':
        fileUrl = api.Project.GetItemAttachmentUrl(fileInfo.id);
        break;
      case 'section':
        fileUrl = api.Project.GetSectionAttachmentUrl(fileInfo.id);
        break;
      case 'qualification':
        fileUrl = api.Qualification.GetQualificationAttachmentUrl(originalAttachmentId, attachmentNumber, idx - 1);
        break;
      case 'project':
      default:
        fileUrl = api.Project.GetProjectAttachmentUrl(fileInfo.id);
        break;
    }

    let displayDate = '';
    if (fileInfo.dateUploaded) {
      const formattedDate = DateTime.fromISO(fileInfo.dateUploaded).toLocal().toLocaleString(DateTime.DATETIME_MED);
      displayDate = `(${formattedDate})`;
    }

    return (
      <MenuItem
        key={fileInfo.id}
        dense
        onClick={handleFileVersionClick}
        data-href={fileUrl}
        data-cy="file-history.previous-version"
      >
        <ListItemText primary={<span><b>{fileInfo.displayFilename}</b> {displayDate}</span>}/>
      </MenuItem>
    );
  }, [attachmentNumber, handleFileVersionClick, originalAttachmentId, scope]);

  const menu = useMemo(() => {

    const isOpen = Boolean(anchorElement);
    if (!isOpen) {
      return null;
    }

    const uploadMenuItem = (canUpload && onUpload) ? (
      <MenuItem onClick={handleUploadClicked} dense data-cy="file-history.update-button">
        <ListItemIcon><AddCircleOutlineOutlinedIcon fontSize="small" /></ListItemIcon>
        <ListItemText primary="Upload New Version" />
      </MenuItem>
    ) : null;
    const menuItems = pastFileVersions.map((fileVersionInfo, idx) => renderMenuItem(fileVersionInfo, idx));

    let divider = null;
    if (menuItems.length && canUpload && onUpload) {
      divider = <Divider />;
    }

    let noItemsText = null;
    if (!menuItems.length && (!canUpload || !onUpload)) {
      noItemsText = (
        <MenuItem dense data-cy="file-history.no-files-indicator" onClick={onClose}>
          <ListItemText primary="This is the only version of this file" />
        </MenuItem>
      );
    }

    return (
      <Menu
        id="file-history-menu"
        data-cy="file-history-menu"
        anchorEl={anchorElement}
        keepMounted
        open={isOpen}
        onClose={onClose}
      >
        {uploadMenuItem}
        {divider}
        {menuItems}
        {noItemsText}
      </Menu>
    );
  }, [
    anchorElement,
    canUpload,
    handleUploadClicked,
    onClose,
    onUpload,
    pastFileVersions,
    renderMenuItem
  ]);

  const componentOutput = useMemo(() => {

    return menu;
  }, [menu]);

  return componentOutput;

};

export default FileHistory;
