import React from 'react';

import { CreateNewUserPrompt } from '../CreateNewUserPrompt/CreateNewUserPrompt';
import { ForgotPasswordPrompt } from '../ForgotPasswordPrompt/ForgotPasswordPrompt';
import { Inputs } from '../Login/Login';
import { LoginPrompt } from '../LoginPrompt/LoginPrompt';
import { UserMessageUI } from '../UserMessageUI/UserMessageUI';

export interface LoginFormProps {
  canSubmit: boolean;
  displayCreateNewUserPrompt: boolean;
  displayForgotPasswordPrompt: boolean;
  fullName: string;
  hasInviteDetails: boolean;
  inputs: Inputs;
  isNotCompanyMember: boolean;
  onInputChange: (e: React.ChangeEvent<{ id: any; value: any; }>) => void;
  onKeyDown: (evt: React.KeyboardEvent) => void;
  onSubmitCreateUser: (e: React.MouseEvent) => void;
  onSubmitForgotPassword: (evt: React.MouseEvent | undefined) => void;
  onSubmitLogin: (e: React.MouseEvent | undefined) => void;
  onToggleCreateNewUserPrompt: (evt: React.MouseEvent) => void;
  onToggleForgotPasswordPrompt: (evt: React.MouseEvent) => void;
  shouldDisplayCreateNewUserPrompt: boolean;
  userMessage: string;
  userMessageIsError: boolean;
}

const getClassName = ({ userMessageIsError }: { userMessageIsError: boolean }) => {

  return userMessageIsError ? 'userMessageError' : 'userMessageInfo';
};

export interface getShowCreateNewCompanyUserLinkShape {
  hasInviteDetails: boolean;
  shouldDisplayCreateNewUserPrompt: boolean
}

const getShowCreateNewCompanyUserLink = (
  {
    hasInviteDetails,
    shouldDisplayCreateNewUserPrompt
  }: getShowCreateNewCompanyUserLinkShape) => {

  return (hasInviteDetails && !shouldDisplayCreateNewUserPrompt);
};

const LoginForm = (
  {
    canSubmit,
    displayCreateNewUserPrompt,
    displayForgotPasswordPrompt,
    fullName,
    hasInviteDetails,
    inputs,
    isNotCompanyMember,
    onInputChange,
    onKeyDown,
    onSubmitCreateUser,
    onSubmitForgotPassword,
    onSubmitLogin,
    onToggleCreateNewUserPrompt,
    onToggleForgotPasswordPrompt,
    shouldDisplayCreateNewUserPrompt,
    userMessage,
    userMessageIsError
  }: LoginFormProps) => {

  const className = getClassName({ userMessageIsError });
  const showCreateNewCompanyUserLink =
    getShowCreateNewCompanyUserLink({ hasInviteDetails, shouldDisplayCreateNewUserPrompt });
  const userMessageUI = userMessage ?
    <UserMessageUI
      className={className}
      userMessage={userMessage}
    /> :
    null;

  if (displayCreateNewUserPrompt) {

    return (
      <CreateNewUserPrompt
        canSubmit={canSubmit}
        confirmPassword={inputs.confirmPassword}
        email={inputs.email}
        fullName={inputs.fullName}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        onSubmitCreateUser={onSubmitCreateUser}
        onToggleCreateNewUserPrompt={onToggleCreateNewUserPrompt}
        password={inputs.password}
        showCreateNewCompanyUserLink={showCreateNewCompanyUserLink}
        userMessageUI={userMessageUI}
      />
    );
  }

  if (!displayForgotPasswordPrompt) {

    return (
      <LoginPrompt
        email={inputs.email}
        canSubmit={canSubmit}
        fullName={fullName}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        hasInviteDetails={hasInviteDetails}
        isNotCompanyMember={isNotCompanyMember}
        password={inputs.password}
        onSubmitLogin={onSubmitLogin}
        onToggleCreateNewUserPrompt={onToggleCreateNewUserPrompt}
        onToggleForgotPasswordPrompt={onToggleForgotPasswordPrompt}
        userMessageUI={userMessageUI}
      />
    );
  }

  return (
    <ForgotPasswordPrompt
      canSubmit={canSubmit}
      email={inputs.email}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      onSubmitForgotPassword={onSubmitForgotPassword}
      onToggleForgotPasswordPrompt={onToggleForgotPasswordPrompt}
      userMessageUI={userMessageUI}
    />
  );
};

export { getClassName, getShowCreateNewCompanyUserLink, LoginForm };
