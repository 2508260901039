import _ from 'lodash';

const descendingComparator = (a: string | object, b: string | object, orderBy: string) => {

  a = _.get(a, orderBy) || '';
  b = _.get(b, orderBy) || '';

  // Make sort case insensitive for strings
  if (typeof a === 'string' && typeof b === 'string') {
    a = a.toLowerCase().trim();
    b = b.toLowerCase().trim();
  }

  if (b < a) {
    return -1;
  }

  if (b > a) {
    return 1;
  }

  return 0;
};

const getComparator = (order: string, orderBy: string) => {

  return (order === 'desc') ?
    (a: string | object, b: string | object) => descendingComparator(a, b, orderBy) :
    (a: string | object, b: string | object) => -descendingComparator(a, b, orderBy);
};

const stableSort = <T extends string | object>(array: T[], comparator: (a: T, b: T) => number) => {

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0] as T, b[0] as T);
    if (order !== 0) {
      return order;
    }

    return (a[1] as number) - (b[1] as number);
  });

  return stabilizedThis.map((el) => el[0] as T);
};

type SortInputObject = {
  [index: string]: any,
  tag?: string,
  label?: string,
  fullName?: string
};

const sortAlphaCaseInsensitive = <T extends string | SortInputObject>(a: T, b: T) => {

  const left = (typeof a === 'string') ? a.trim() : (a.tag || a.label || a.fullName || '').trim();
  const right = (typeof b === 'string') ? b.trim() : (b.tag || b.label || b.fullName || '').trim();

  return left.localeCompare(right, 'en', { 'sensitivity': 'base' });
};

export default { getComparator, sortAlphaCaseInsensitive, stableSort };
