"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseCodeNumbers = void 0;
/**
 * Takes a WBS code string and splits it into its parts, then returns an array of string parts.
 * @param {string} wbsCode - a WBS code string (e.g. "01 02 03")
 * @return {string[]} - an array of strings (e.g. ["01", "02", "03"])
 */
var parseCodeNumbers = function parseCodeNumbers(wbsCode) {
  if (!wbsCode) {
    return [];
  }
  return wbsCode.trim().split(' ').filter(function (x) {
    return x;
  });
};
exports.parseCodeNumbers = parseCodeNumbers;