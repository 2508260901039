import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';

export const wbsColumnOrder:
  (keyof Omit<WBSDataRowShape, 'id' | 'clientSidePredictionId' | 'projectId' | 'sortOrder' | 'isResource'>)[] =
  ['wbsCode', 'name', 'quantity', 'inputCost', 'total'];

export const wbsColumnTitleLookups: { [index: string]: string } = {
  'name': 'Name',
  'wbsCode': 'WBS ID',
  'quantity': 'Qty',
  'inputCost': 'Cost',
  'total': 'Total'
};
