import { MouseEvent, KeyboardEvent, useCallback, useMemo, ReactNode } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { OnMouseClickFunc } from '../../types/OnMouseClickFunc';

interface Props {
  autoFocusConfirm?: boolean;
  autoFocusConfirm2?: boolean;
  autoFocusConfirm3?: boolean;
  autoFocusConfirm4?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmLabel2?: string;
  confirmLabel3?: string;
  confirmLabel4?: string;
  description: ReactNode;
  dontAllowClose?: boolean;
  fullScreen?: boolean;
  handleCancel?: OnMouseClickFunc,
  handleConfirm?: () => void,
  handleConfirm2?: () => void,
  handleConfirm3?: () => void,
  handleConfirm4?: () => void,
  id?: string | undefined;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // this needs to match the type of mui's Dialog maxWidth
  open: boolean;
  title: string;
}

const ConfirmDialog = (props: Props) => {

  const {
    autoFocusConfirm,
    autoFocusConfirm2,
    autoFocusConfirm3,
    autoFocusConfirm4,
    cancelLabel,
    confirmLabel,
    confirmLabel2,
    confirmLabel3,
    confirmLabel4,
    description = null,
    dontAllowClose,
    fullScreen,
    handleCancel,
    handleConfirm,
    handleConfirm2,
    handleConfirm3,
    handleConfirm4,
    id,
    maxWidth,
    open,
    title
  } = props;


  const handleCancelInternal = useCallback((evt: MouseEvent<HTMLElement>) => {

    if (dontAllowClose || !handleCancel) {
      return;
    }

    if (handleCancel) {
      handleCancel(evt);
    }
  }, [dontAllowClose, handleCancel]);

  const handleKeyDown = useCallback((evt: KeyboardEvent) => {

    if (evt.key === 'Enter' && handleConfirm) {
      evt.preventDefault();
      handleConfirm();
    }
  }, [handleConfirm]);

  return useMemo(() => {

    let cancelButton = null;
    let confirmButton = null;
    let confirmButton2 = null;
    let confirmButton3 = null;
    let confirmButton4 = null;

    if (cancelLabel !== undefined) {
      cancelButton = (
        <Button
          onClick={handleCancelInternal}
          color="secondary"
          autoFocus={
            !(
              autoFocusConfirm ||
              autoFocusConfirm2 ||
              autoFocusConfirm3 ||
              autoFocusConfirm4
            )
          }
          data-cy={'dialog.confirm.cancel'}
        >
          {cancelLabel}
        </Button>
      );
    }

    if (confirmLabel !== undefined) {
      confirmButton = (
        <Button
          onClick={handleConfirm}
          color="secondary"
          variant="contained"
          autoFocus={autoFocusConfirm}
          data-cy="dialog.confirm.confirm"
        >
          {confirmLabel}
        </Button>
      );
    }

    if (confirmLabel2 !== undefined) {
      confirmButton2 = (
        <Button
          onClick={handleConfirm2}
          color="secondary"
          variant="contained"
          autoFocus={autoFocusConfirm2}
          data-cy="dialog.confirm.confirm2"
        >
          {confirmLabel2}
        </Button>
      );
    }

    if (confirmLabel3 !== undefined) {
      confirmButton3 = (
        <Button
          onClick={handleConfirm3}
          color="secondary"
          variant="contained"
          autoFocus={autoFocusConfirm3}
          data-cy="dialog.confirm.confirm3"
        >
          {confirmLabel3}
        </Button>
      );
    }

    if (confirmLabel4 !== undefined) {
      confirmButton4 = (
        <Button
          onClick={handleConfirm4}
          color="secondary"
          variant="contained"
          autoFocus={autoFocusConfirm4}
          data-cy="dialog.confirm.confirm4"
        >
          {confirmLabel4}
        </Button>
      );
    }

    return (
      <Dialog
        id={id}
        data-cy="dialog.confirm"
        open={open}
        onClose={handleCancelInternal}
        fullScreen={fullScreen}
        fullWidth={Boolean(maxWidth)}
        maxWidth={maxWidth}
        aria-labelledby={id + '_title'}
        aria-describedby={id + '_description'}
        onKeyDown={handleKeyDown}
        disableEnforceFocus={true}
      >
        <DialogTitle id={id + '_title'}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'} id={id + '_description'}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelButton}
          {confirmButton}
          {confirmButton2}
          {confirmButton3}
          {confirmButton4}
        </DialogActions>
      </Dialog>
    );
  }, [
    autoFocusConfirm,
    autoFocusConfirm2,
    autoFocusConfirm3,
    autoFocusConfirm4,
    cancelLabel,
    confirmLabel,
    confirmLabel2,
    confirmLabel3,
    confirmLabel4,
    description,
    fullScreen,
    handleCancelInternal,
    handleConfirm,
    handleConfirm2,
    handleConfirm3,
    handleConfirm4,
    handleKeyDown,
    id,
    maxWidth,
    open,
    title
  ]);
};

export default ConfirmDialog;
