import * as actions from './mainActions';

const initialState = {
  loggedInUserDetails: null
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actions.APP_SET_LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        loggedInUserDetails: action.userDetails
      };
    case actions.APP_SET_HAS_SET_PASSWORD:
      return {
        ...state,
        loggedInUserDetails: {
          ...state.loggedInUserDetails,
          hasSetPassword: true
        }
      };
    default:
      return state;
  }

}
