import request from 'superagent';

import apiCommon from './common';

export default {

  AddBidNote: async function (bidId, content) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/bid/add-bid-note')
      .send({
        bidId,
        content
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetBidNotes: async function (bidId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/bid/get-bid-notes')
      .query({
        bidId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteBidNote: async function (id) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/project/bid/delete-bid-note')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  }
};
