import _ from 'lodash';
import request from 'superagent';
import * as mainActions from '../redux/mainActions';

let CSRF_TOKEN = '';

const isCSRFError = (responseObj = {}) => responseObj?.body?.message === 'isCSRF';

const refreshCsrfToken = async () => {

  const tokenResult = await request
    .get(process.env.REACT_APP_API_URL + '/user/csrf')
    .withCredentials();

  if (tokenResult.err) {
    throw tokenResult.err;
  }

  CSRF_TOKEN = tokenResult.body.token;
};

const BaseQuery = async (args, api, extraOptions) => {

  // Have to pull this from args instead of extraOptions since it can differ between calls
  const redirectOnError = args.data?.redirectOnError ?? false;

  const mergedOptions = {
    affectsGrandTotal: false,
    excludeCredentials: false,
    skipCsrf: false,
    skipSetIsLoading: false,
    useJsonRequestType: true,
    isRetry: false,
    allowNullValues: false,
    redirectOnError,
    shouldNavigateToRootOnUnauthorized: true,
    ...extraOptions
  };

  const apiRequest = request(args.method, process.env.REACT_APP_API_URL + args.url);

  if (args.params) {
    apiRequest.query(args.params);
  }

  if (args.data) {
    const formData = {};
    const excludeDataKeys = _.compact([
      mergedOptions.attachmentKey,
      mergedOptions.onProgressKey,
      'redirectOnError',
      'shouldNavigateToRootOnUnauthorized'
    ]);
    Object.entries(args.data ?? {}).forEach(([key, value]) => {

      if (!excludeDataKeys.includes(key) && value !== undefined && (mergedOptions.allowNullValues || value !== null)) {
        formData[key] = value;
      }
    });

    if (mergedOptions.attachmentKey) {
      apiRequest.attach('attachment', args.data[mergedOptions.attachmentKey]);
      Object.entries(formData).forEach(([key, value]) => apiRequest.field(key, value));
    }
    else {
      apiRequest.send(formData);
    }
  }

  if (!mergedOptions.skipSetIsLoading) {
    api.dispatch(mainActions.setIsLoading(true));
    if (mergedOptions.affectsGrandTotal) {
      api.dispatch(mainActions.setIsLoadingGrandTotal(true));
    }
  }

  if (args.method !== 'GET') {
    api.dispatch(mainActions.setDidMakeApiChange());
  }

  const response = {};
  let messageVariant = 'info';
  let responseObject = {};

  try {
    if (!mergedOptions.skipCsrf && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(args.method)) {
      if (CSRF_TOKEN === '') {
        await refreshCsrfToken();
      }

      apiRequest.set('X-CSRF-Token', CSRF_TOKEN);
    }

    if (mergedOptions.useJsonRequestType) {
      apiRequest.type('json');
    }

    if (!mergedOptions.excludeCredentials) {
      apiRequest.withCredentials();
    }

    if (mergedOptions.onProgressKey && args.data[mergedOptions.onProgressKey]) {
      apiRequest.on('progress', args.data[mergedOptions.onProgressKey]);
    }

    response.res = await apiRequest;
    response.err = null;

    responseObject = response.res;

    if (responseObject?.body?.status === 'Server Initializing') {
      window.location.replace('/maintenance');
      return { error: 'initializing' };
    }
  }
  catch (e) {
    const unauthorizedStatus = 401;
    let shouldShowErrorMsg = !isCSRFError(e.response);

    if (_.get(e, 'response.statusCode') === unauthorizedStatus) {
      shouldShowErrorMsg = false;
      if (!mergedOptions.skipSetIsLoading) {
        if (mergedOptions.redirectOnError) {
          api.dispatch(mainActions.setRedirectOnError(true));
        }
        else if (mergedOptions.shouldNavigateToRootOnUnauthorized) {
          sessionStorage.setItem('navigateToRootIfLoggedOut', 'true');
          api.dispatch(mainActions.setIsLoggedIn(false));
        }
      }
    }

    if (shouldShowErrorMsg) {
      api.dispatch(mainActions.setShowError(true));
    }

    response.res = null;
    response.err = e;

    responseObject = response.err.response;
    messageVariant = 'error';
  }

  if (responseObject && responseObject.body && !_.isEmpty(responseObject.body.message)) {
    // TODO: display errors
    console.log(messageVariant);
  }

  // delay setting loading to false to give other api calls a chance to start running and set loading to true.
  // otherwise the loading state is constantly being turned off and on, and the loader in the gui keeps restarting.
  _.delay(() => {

    if (!mergedOptions.skipSetIsLoading) {
      api.dispatch(mainActions.setIsLoading(false));
      if (mergedOptions.affectsGrandTotal) {
        api.dispatch(mainActions.setIsLoadingGrandTotal(false));
      }
    }
  }, 200);

  if (!mergedOptions.isRetry && response?.err?.status === 403 && isCSRFError(responseObject)) {
    CSRF_TOKEN = '';

    // reset apiRequest so it can be called again
    delete apiRequest.xhr;
    delete apiRequest._callback;
    delete apiRequest._endCalled;
    delete apiRequest._fullfilledPromise;

    return await BaseQuery(args, api, { ...mergedOptions, isRetry: true });
  }

  return (response.err) ?
    { data: null, error: response.err, meta: response.payload } :
    { data: response.res.body, error: null, meta: response.payload };
};

export default BaseQuery;
