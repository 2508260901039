import React, { Fragment, useMemo } from 'react';
import { Link as BrowserLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import SmallAvatar from '../Icons/SmallAvatar';

import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { AppTheme } from '../../theme-relay';


const useStyles = makeStyles((theme: AppTheme) => ({
  listItem: {
    position: 'relative'
  }
}));

interface Props {
  activityProjectId: number;
  divisionId: number;
  divisionName: string;
  fromUserFullName: string;
  fromUserCompanyId: number;
  sectionId: number;
  sectionTitle: string;
}

const ActivityLogSubmitScope = (props: Props) => {

  const {
    activityProjectId,
    divisionId,
    divisionName,
    fromUserFullName,
    fromUserCompanyId,
    sectionId,
    sectionTitle
  } = props;

  const classes = useStyles();

  return useMemo(() => {

    let projectUrl = '/projects/' + activityProjectId;
    if (sectionId) {
      projectUrl += '/section/' + sectionId;
    }
    else if (divisionId) {
      projectUrl += '/division/' + divisionId;
    }

    const projectSpecificLevelText = `${divisionName || 'Unspecified Division'} - ${sectionTitle || 'Unspecified Area'}`;

    return (
      <Fragment>
        <ListItem className={classes.listItem} alignItems="flex-start" data-cy="activity-log.item" dense>
          <SmallAvatar
            name={(fromUserFullName?.length) ? fromUserFullName[0] : ''}
            id={fromUserCompanyId || 0}
            style={{ marginTop: '6px' }}
          />
          <ListItemText
            primary={
              <Fragment>
                <Link
                  component={BrowserLink}
                  to={'/companies/' + fromUserCompanyId}
                  variant="body1"
                  color="textPrimary"
                  style={{ textDecoration: 'underline' }}
                >
                  {fromUserFullName}
                </Link>
                {' submitted scope on '}
                <Link
                  component={BrowserLink}
                  to={projectUrl}
                  variant="body1"
                  color="textPrimary"
                  style={{ textDecoration: 'underline' }}
                >
                  {projectSpecificLevelText}
                </Link>
              </Fragment>
            }
          />
        </ListItem>
        <Divider component="li" />
      </Fragment>
    );
  }, [
    activityProjectId,
    classes,
    divisionId,
    divisionName,
    fromUserCompanyId,
    fromUserFullName,
    sectionId,
    sectionTitle
  ]);
};

export default ActivityLogSubmitScope;
