import _ from 'lodash';
import api from '../api';
import { getIsInvalidInvite } from '../components/Account/Login/Login';

interface GetInviteDetailsShape {
  setInviteDetails: any;
  setUserMessage: any;
  setUserMessageIsError: any;
}

const GetInviteDetails = async ({ setInviteDetails, setUserMessage, setUserMessageIsError }: GetInviteDetailsShape) => {

  const urlParams = new URLSearchParams(window.location.search);
  const inviteToken = urlParams.get('inviteToken');
  const response = inviteToken ? (await api.Invitation.GetDetails(inviteToken))?.res?.body : null;
  const responseInviteDetails = !_.isEmpty(response) ? response : null;

  const isInvalidInvite = getIsInvalidInvite({ inviteToken: inviteToken || '', responseInviteDetails });
  if (isInvalidInvite) {
    setUserMessage('Invalid Invitation');
    setUserMessageIsError(true);
  }
  else {
    setInviteDetails(responseInviteDetails);
  }
};

export { GetInviteDetails };
