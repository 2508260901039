import { combineReducers } from 'redux';

import appState from './mainReducer';
import ephemeralState from './ephemeralReducer';

import modulePdfMarkupState from '../components/Modules/PdfMarkup/reducer';
import projectReducer from './project/projectSlice';
import sectionReducer from './project/sectionSlice';
import wbsPhasesReducer from './project/wbsPhasesSlice';
import wbsResourcesReducer from './project/wbsResourcesSlice';
import wbsDataTableReducer from './project/wbsDataTableSlice';
import costManagementReducer from './project/costManagementSlice';

import { InvitationApiSlice } from '../api/invitation';
import { ProjectApiSlice } from '../api/project';

export const rootReducer = combineReducers({
  appState,
  ephemeralState,

  modulePdfMarkupState,
  project: projectReducer,
  section: sectionReducer,
  wbsPhases: wbsPhasesReducer,
  wbsResources: wbsResourcesReducer,
  wbsDataTable: wbsDataTableReducer,
  costManagement: costManagementReducer,

  [InvitationApiSlice.reducerPath]: InvitationApiSlice.reducer,
  [ProjectApiSlice.reducerPath]: ProjectApiSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
