import React, { ReactElement, useMemo } from 'react';

import { useTheme } from '@material-ui/core/styles';

import { Avatar, Tooltip, TooltipProps } from '@material-ui/core';

import { ProjectRole } from '../../types/enums/ProjectRole';
import { ProjectRoleType } from '../../types/enums/ProjectRoleType';

interface Props {
  ToolTipElement?: React.ComponentType<TooltipProps>;
  company?: string;
  disableTooltip?: boolean;
  extraClass?: string;
  id?: number;
  isForAdditionalAvatars?: boolean;
  isThirdPartyUser?: boolean;
  name?: string;
  numExtraAvatars?: number;
  role?: ProjectRole | ProjectRoleType;
  showOnlyFirstInitial?: boolean;
  style?: React.CSSProperties;
  tooltipArrow?: boolean;
  tooltipIsInteractive?: boolean;
  tooltipOverride?: string | ReactElement;
  tooltipPlacement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
  useDefaultSize?: boolean;
}

const SmallAvatar = React.forwardRef((props: Props, ref) => {

  const theme: any = useTheme();

  const {
    company,
    name = '',
    id,
    isForAdditionalAvatars = false,
    isThirdPartyUser = false,
    showOnlyFirstInitial = true,
    useDefaultSize = true,
    extraClass = '',
    disableTooltip = false,
    numExtraAvatars,
    role,
    style,
    tooltipArrow = false,
    ToolTipElement = Tooltip,
    tooltipIsInteractive = false,
    tooltipOverride,
    tooltipPlacement = 'bottom'
  } = props;

  return useMemo(() => {

    const title = (company) ? name + ' - ' + company : name;
    let initials = name ? name[0] : (company ? company[0] : '?');

    if (isForAdditionalAvatars && numExtraAvatars) {
      initials = `+${numExtraAvatars}`;
    }
    else if (!showOnlyFirstInitial && name) {
      const splitName = name.split(' ');
      if (splitName.length > 1) {
        initials += splitName[splitName.length - 1][0];
      }
    }

    const avatarStyle = style || {};

    if (!role && id) {
      avatarStyle.backgroundColor = avatarStyle?.backgroundColor || theme.getAvatarColor(id).primary;
    }

    if (role) {

      const getThemeNameBasedOnRole =
        (userRole: ProjectRole | ProjectRoleType): 'approver' | 'estimator' | 'contributor' | 'manager' | 'viewer' => {

          if (userRole === ProjectRoleType.Approver) {
            return 'approver';
          }
          else if (userRole === ProjectRoleType.Estimator) {
            return 'estimator';
          }
          else if (userRole === ProjectRoleType.Contributor) {
            return 'contributor';
          }
          else if (userRole === ProjectRoleType.Manager) {
            return 'manager';
          }
          else if (userRole === ProjectRoleType.Viewer) {
            return 'viewer';
          }

          return 'estimator';
        };

      const themeColor = theme.palette.userManagement[getThemeNameBasedOnRole(role)];
      avatarStyle.backgroundColor = themeColor;
    }

    if (useDefaultSize) {
      avatarStyle.fontSize = '12px';
      avatarStyle.height = '20px';
      avatarStyle.width = '20px';
      avatarStyle.marginRight = '8px';
    }

    if (isThirdPartyUser) {
      avatarStyle.borderRadius = 0;
    }

    return (
      <ToolTipElement
        title={(disableTooltip) ? '' : (tooltipOverride || title)}
        placement={tooltipPlacement}
        interactive={tooltipIsInteractive}
        arrow={tooltipArrow}
        area-label={name}
      >
        <Avatar
          style={avatarStyle}
          className={extraClass}
        >
          {initials.toUpperCase()}
        </Avatar>
      </ToolTipElement>
    );
  }, [
    company,
    disableTooltip,
    extraClass,
    id,
    isThirdPartyUser,
    isForAdditionalAvatars,
    name,
    numExtraAvatars,
    role,
    showOnlyFirstInitial,
    style,
    theme,
    tooltipArrow,
    ToolTipElement,
    tooltipIsInteractive,
    tooltipOverride,
    tooltipPlacement,
    useDefaultSize
  ]);
});

export default SmallAvatar;
