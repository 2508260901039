import { createEntityAdapter, createSelector, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { WBSPhaseShape } from '../../types/api/WBSPhaseShape';

const wbsPhasesAdapter = createEntityAdapter<WBSPhaseShape>({
  selectId: (col) => col.id,
  sortComparer: (a, b) => {

    if (a.index === b.index) {
      return a.id - b.id;
    }

    return a.index - b.index;
  }
});

const initialState = wbsPhasesAdapter.getInitialState();

const wbsPhasesSlice = createSlice({
  name: 'wbsPhases',
  initialState,
  reducers: {
    addPhase: (state, action: PayloadAction<WBSPhaseShape>) => {

      wbsPhasesAdapter.addOne(state, action.payload);
    },
    updateColumn: (state, action: PayloadAction<Update<WBSPhaseShape>>) => {

      wbsPhasesAdapter.updateOne(state, action.payload);
    },
    updateColumns: (state, action: PayloadAction<Update<WBSPhaseShape>[]>) => {

      wbsPhasesAdapter.updateMany(state, action.payload);
    },
    mergeColumnsFromServer: (state, action: PayloadAction<WBSPhaseShape[]>) => {

      const serverIds = action.payload.map((r) => r.id);
      const rowsToDelete = Object.values(state.entities).filter((r) => {

        return r !== undefined && !serverIds.includes(r.id);
      });

      if (rowsToDelete.length > 0) {
        const rowIdsToDelete = [];
        for (const row of rowsToDelete) {
          if (row?.id !== undefined) {
            rowIdsToDelete.push(row.id);
          }
        }

        wbsPhasesAdapter.removeMany(state, rowIdsToDelete);
      }

      const rowsToUpsert = [];
      for (const col of action.payload) {
        if (!col.id) {
          continue;
        }

        rowsToUpsert.push({ ...col });
      }

      if (rowsToUpsert.length > 0) {
        wbsPhasesAdapter.upsertMany(state, rowsToUpsert);
      }
    },
    removeColumnById: (state, action: PayloadAction<number>) => {

      wbsPhasesAdapter.removeOne(state, action.payload);
    }
  }
});

const {
  selectById
} = wbsPhasesAdapter.getSelectors((state: any) => state.wbsColumns);

const {
  selectAll: selectAllWbsPhases
} = wbsPhasesAdapter.getSelectors((state: any) => state.wbsColumns);

const selectAll = createSelector(
  [selectAllWbsPhases, (state, projectId) => projectId],
  (columns, projectId) => {

    return columns.filter(
      (col) => col.projectId === projectId
    );
  }
);

export const actions = wbsPhasesSlice.actions;

export default wbsPhasesSlice.reducer;

export const selectors = {
  selectAll,
  selectById
};
