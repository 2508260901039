"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLeafRows = void 0;
/**
 * getLeafRows takes in the wbsLookup, a wbsCode and returns all relevant leaf rows found in the lookup.
 * @param {WBSCodeLookupShape} wbsCodeLookup - the lookup containing all relevant data for all wbsCodes.
 * @param {string} wbsCode - the WBS Code to get children for.
 * @returns {WBSDataRowShape[]} - returns all relevant leaf rows for the given wbs code.
 */
var getLeafRows = function getLeafRows(wbsCodeLookup, wbsCode) {
  var leafRows = wbsCodeLookup[wbsCode] ? wbsCodeLookup[wbsCode].leafRows : [];
  return leafRows;
};
exports.getLeafRows = getLeafRows;