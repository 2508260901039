import React, { useMemo } from 'react';

import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';

const ContextMenu = (props) => {

  const {
    anchorPosition,
    anchorReference,
    menuOptions,
    onClose
  } = props;

  return useMemo(() => {

    return (
      <Menu
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        data-cy="context-menu"
        id="context-menu"
        onClose={onClose}
        open={Boolean(anchorPosition)}
        TransitionComponent={Fade}
      >
        {menuOptions}
      </Menu>
    );
  }, [anchorPosition, anchorReference, menuOptions, onClose]);
};

export default ContextMenu;
