import { padStart, replace, isEmpty } from 'lodash';
import { numericOnlyString } from './numericOnlyString';


const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const CURRENCY_FORMATTER_WITH_ALWAYS_SHOW_CENTS = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const Format = {

  Float: (floatValue: string | number) => {

    if (floatValue.toString().includes('%')) {
      return floatValue.toString();
    }

    const wasNegative = numericOnlyString(floatValue.toString(), true)[0] === '-';
    floatValue = numericOnlyString(floatValue.toString());

    if ((parseFloat(floatValue.toString()) >= 1) && (Math.abs(Math.round(parseFloat(floatValue.toString()) * 1)) > 0)) {
      const originalValue = floatValue;

      floatValue = parseFloat(floatValue.toString());
      if (floatValue > 0) {
        floatValue = Math.floor(floatValue);
      }
      else {
        floatValue = Math.ceil(floatValue);
      }

      floatValue = floatValue.toLocaleString();

      if ((originalValue + '').indexOf('.') >= 0) {
        floatValue = floatValue + '.';

        const parts = (originalValue + '').split('.');
        if (parts.length > 1) {
          floatValue = floatValue + parts[1];
        }
      }
    }

    return (wasNegative) ? '-' + floatValue.toString() : floatValue.toString();
  },

  FormatCurrency: (amount: string | number | null | undefined, currencySymbol: string, alwaysShowCents?: boolean) => {

    if (amount === undefined || amount === null) {
      amount = 0.00;
    }

    const roundedAmount = Number.parseFloat(Number.parseFloat(amount.toString().replace(currencySymbol, '')).toFixed(2));
    if (isNaN(roundedAmount)) {
      console.log(`Failed to convert amount in FormatCurrency ${amount}`);
      return Format.Cost(amount, currencySymbol, true, true, alwaysShowCents);
    }

    const hasDecimals = roundedAmount % 1 !== 0;

    const formattedAmount = (alwaysShowCents || hasDecimals) ?
      CURRENCY_FORMATTER_WITH_ALWAYS_SHOW_CENTS.format(roundedAmount) :
      CURRENCY_FORMATTER.format(roundedAmount);

    return (currencySymbol === '$') ? formattedAmount : formattedAmount.replace('$', currencySymbol);
  },

  Cost: (
    cost: string | number | null | undefined,
    currencySymbol: string,
    roundToCents?: boolean,
    removeTrailingZeros?: boolean,
    showCents?: boolean
  ) => {

    if (cost === undefined || cost === null) {
      cost = '0';
    }

    cost = cost.toString();
    if (cost !== '-' && cost !== '.' && !/[0-9]/.test(cost)) {
      cost = '';
    }

    cost = Format.Float(cost);

    if (roundToCents) {
      const parsedCost = parseFloat((cost + '').replace(/[^0-9.-]/g, ''));
      if (!isNaN(parsedCost)) {
        cost = parsedCost.toFixed(2);
        cost = parseFloat(cost).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    }

    cost = (cost.substring(0, currencySymbol.length) === currencySymbol || cost.charAt(0) === '(') ? cost : currencySymbol + cost;

    if (removeTrailingZeros) {
      const extraZerosRegex = /(.*?\.[0-9]{2}[0-9]*?)0+$/;
      const regexResult = extraZerosRegex.exec(cost);
      if (regexResult) {
        cost = regexResult[1];
      }

      cost = cost.replace(/\.00$/, '');
    }

    if (showCents) {
      if (!cost.includes('.')) {
        cost = cost + '.00';
      }
      else {
        const existingDecimalPlaces = cost.split('.')[1].length;
        // Add up to 2 '0's
        cost = cost + '0'.repeat(Math.max(2 - existingDecimalPlaces, 0));
      }
    }

    return cost;
  },

  DateTime: (timestamp: string | number | Date) => {

    const date = (timestamp === undefined) ? new Date() : new Date(timestamp);

    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    const hour = date.getHours();
    const formattedTime = `${!(hour % 12) ? 12 : hour % 12}:${padStart(date.getMinutes().toString(), 2, '0')}`;
    const timeOfDay = hour < 12 ? 'AM' : 'PM';

    return `${formattedDate} ${formattedTime} ${timeOfDay}`;
  },

  DueDateFormatString: 'L-dd-y\' at \'t',

  Quantity: (quantity: string | number | null | undefined, unitsLabel?: string, removeTrailingZeros = true) => {

    if (quantity === undefined || quantity === null) {
      quantity = '0';
    }

    quantity = quantity.toString().replaceAll(/[^0-9a-z%., -]/gi, '');

    if (unitsLabel === undefined && quantity.includes(' ')) {
      unitsLabel = quantity.split(' ')[1]?.trim();
    }
    else if (unitsLabel !== undefined && quantity.includes(' ')) {
      unitsLabel = quantity.split(' ')[1]?.trim() + ` ${unitsLabel}`;
    }

    quantity = Format.Float(quantity.replace(/[,]/g, '').split(' ')[0]);

    if (removeTrailingZeros && quantity.includes('.')) {
      quantity = replace(quantity, /\.?0+$/, '');
    }

    if (!isEmpty(unitsLabel)) {
      quantity = quantity + ' ' + unitsLabel;
    }

    return quantity;
  },

  Dollars: (amount: string | number | null | undefined, currencySymbol: string) => {
    // Accepts number or string and returns the numbers reformatted as a dollar amount without cents.

    if (typeof amount === 'undefined' || amount === '' || amount === currencySymbol || amount === null) {
      return '';
    }

    const str = amount.toString();
    const indexOfDecimal = str.indexOf('.');
    const onlyDollars = (indexOfDecimal === -1) ? str : str.slice(0, indexOfDecimal);

    const stripped = Number(onlyDollars.replace(/ [^0-9-]/g, '')).toString();

    const string = stripped
      .split('')
      .reverse()
      .join('');
    let newString = '';
    for (let i = 0; i < string.length; i++) {
      if (i % 3 === 0 && i !== 0) {
        newString = ',' + newString;
      }

      newString = string.charAt(i) + newString;
    }

    return newString === '' ? '' : currencySymbol + newString;
  },

  Count: (input: string | number | null | undefined, currencySymbol: string) => {

    if (typeof input === 'undefined' || input === '' || input === currencySymbol || input === null) {
      return '';
    }

    const str = input.toString();
    const stripped = str.replace(/[^0-9-.]/g, '');

    const [beforeDecimal, afterDecimal] = stripped.split('.');

    const string = beforeDecimal.split('').reverse().join('');
    let newString = '';
    for (let i = 0; i < string.length; i++) {
      if (i % 3 === 0 && i !== 0) {
        newString = ',' + newString;
      }

      newString = string.charAt(i) + newString;
    }

    return (afterDecimal) ? `${newString}.${afterDecimal}` : newString;
  },

  NormalizeStringNumber: (num: string | null) => {
    // Removes trailing zeros from a number string, turns null into '0'

    if (num === null) {
      return '0';
    }

    return parseFloat(num).toString();
  },

  FixCaretJump: (
    caret: number,
    element: HTMLInputElement,
    field: string,
    formatInput: (input1: string | number | null | undefined, input2: string | number | null | undefined) => string,
    currencySymbol: string | undefined,
    inputText: string,
    keyPressed: string | undefined = '',
    newLength: number,
    originalValue: string,
    setInputText: (newValue: string, field: string) => void
  ) => {

    // no formatting needed when editing quantity units labels
    if (field === 'quantity' && !inputText.includes(keyPressed) && keyPressed !== 'Delete' && keyPressed !== 'Backspace') {
      return;
    }

    const nonDigits = originalValue.replace(/\d/g, '').length;
    let beforeFormatNonDigLeft = originalValue.slice(0, caret).replace(/\d/g, '').length;
    let afterFormatNonDigLeft = inputText.slice(0, caret).replace(/\d/g, '').length;
    // A non numeric character was added to the string
    if (newLength > originalValue.length) {

      let formattedInput = null;
      if (keyPressed === 'Backspace') {
        // Make sure we are not at the beginning of the string
        if (caret) {
          // Move the carat to the first character to the left
          while (/\D/.test(inputText.charAt(caret - 1))) {
            caret--;
          }

          const formattedSlice = formatInput(
            (inputText.slice(0, caret - 1) + inputText.slice(caret)).replace(/[^0-9.-]+/g, ''),
            currencySymbol
          );
          const afterFormatNonDig = formattedSlice.replace(/[0-9.-]/g, '').length;
          setInputText(formatInput(
            (inputText.slice(0, caret - 1) + inputText.slice(caret)).replace(/[^0-9.-]+/g, ''),
            currencySymbol
          ), field);
          if (nonDigits === afterFormatNonDig) {
            caret--;
          }
        }
      }
      else if (keyPressed === 'Delete') {
        // Make sure we are not at the end of the string
        if (originalValue.length - caret) {
          // Move the carat to the first character to the right
          while (/\D/.test(inputText.charAt(caret))) {
            caret++;
          }

          beforeFormatNonDigLeft = inputText.slice(0, caret).replace(/\d/g, '').length;
          formattedInput = formatInput(
            (inputText.slice(0, caret) + inputText.slice(caret + 1)).replace(/[^0-9.-]+/g, ''),
            currencySymbol
          );
          afterFormatNonDigLeft = formattedInput.slice(0, caret).replace(/\d/g, '').length;
          setInputText(formattedInput, field);
        }

        if (formattedInput && /\d/.test(formattedInput.charAt(caret - 1))) {
          caret -= Math.abs(afterFormatNonDigLeft - beforeFormatNonDigLeft);
        }
      }
      else {
        const beforeFormatStringLeft = originalValue.slice(0, caret);
        const afterFormatStringLeft = inputText.slice(0, caret);
        // move cursor past non digits inserted by formatting
        while (/\D/.test(inputText.charAt(caret + 1))) {
          caret++;
        }

        beforeFormatNonDigLeft = originalValue.slice(0, caret).replace(/\d/g, '').length;
        afterFormatNonDigLeft = inputText.slice(0, caret).replace(/\d/g, '').length;
        if (beforeFormatStringLeft !== afterFormatStringLeft) {
          caret += Math.abs(afterFormatNonDigLeft - beforeFormatNonDigLeft);
        }
      }
    }
    // A non numeric value was removed from the string
    else if ((newLength < originalValue.length) && (caret !== 0)) {

      if (keyPressed !== 'Backspace' && keyPressed !== 'Delete') {
        caret--;
      }
      else {
        caret -= beforeFormatNonDigLeft - afterFormatNonDigLeft;
      }
    }

    // Inserted character was moved in formatting.
    else if (
      (inputText !== originalValue) &&
      (inputText.length === originalValue.length) &&
      (keyPressed !== 'Backspace') &&
      (keyPressed !== 'Delete')
    ) {
      // Move carat past non digit formatting characters and to the right inserted character
      while (/[\D, ]/.test(inputText.charAt(caret))) {
        caret++;
      }

      // Move caret if inserted character is moved forward by formatting
      if (
        /[ ]/.test(originalValue.charAt(caret)) &&
        /\d/.test(inputText.charAt(caret)) &&
        /\d/.test(originalValue.charAt(caret - 1)) &&
        /[ ]/.test(inputText.charAt(caret - 1))
      ) {
        caret++;
      }
    }

    // Move caret to right of dollar sign
    if (caret === 0 && inputText.charAt(0) === currencySymbol) {
      caret++;
    }

    // Starting a negative dollar amount after select all
    if (inputText === `${currencySymbol}-` && caret === 1) {
      caret++;
    }

    window.requestAnimationFrame(() => {

      element.setSelectionRange(caret, caret);
    });
  },

  CheckExistingUnits: (newInput: string, originalInput: string) => {

    // Use original units if none provided
    let updatedInput = newInput;
    const originalUnits = originalInput.replace(/[0-9.-]/g, '');
    const newUnits = newInput.replace(/[0-9.-]/g, '');
    if (!newUnits && originalUnits && newInput) {
      updatedInput += originalUnits;
    }

    return updatedInput;
  }
};

export default Format;
