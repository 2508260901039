import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllSectionIds, getSelectedId, toggleElement, updateTopLevelRows } from './utils';
import api from '../../api';
import {
  AreasWithNewActivity,
  AreaTitlesInfo,
  BidderInfo,
  ChangeHistoryState,
  CompanySettings,
  DivisionId,
  DivisionState,
  OriginalProject,
  PhaseSummarySheetPivotState,
  PhaseSummarySheetState,
  PhaseToShowState,
  ProjectInputState,
  ProjectRootState,
  ProjectState,
  UpdatedSection
} from '../stateTypes';
import _ from 'lodash';
import { defaultOrderedShorthandNotations, defaultShorthandNotations } from '../../consts/Company';
import { SectionItemShape } from '../../types/api/SectionItemShape';
import { SummarySheetPivotType } from '../../types/enums/SummarySheetPivotType';
import { ProjectRoleType } from '../../types/enums/ProjectRoleType';
import { BidShape } from '../../types/api/BidShape';
import { WBSColumnDataShape } from '../../types/api/WBSColumnDataShape';
import { WBSMetadataShape } from 'wbs/dist/types/WBSMetadataShape';
import { ProjectDefaultPermissionStructure } from '../../DefaultPermissionStructure';
import { ProjectCurrencyShape } from '../../types/api/ProjectCurrencyShape';

export const defaultCustomDesignations = {
  classification: 'Classification',
  division: 'Division',
  divisionManager: 'Division Manager',
  divisionContributor: 'Division Contributor',
  divisionEstimator: 'Division Estimator',
  area: 'Area',
  areaManager: 'Area Manager',
  areaContributor: 'Area Contributor',
  areaEstimator: 'Area Estimator',
  section: 'Section',
  summarySheetSubcontractor: 'Subcontractor'
};

const defaultProject = {
  id: 0,
  name: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  availableColorSchemes: {},
  headerImageTimestamp: new Date().getTime(),
  primaryCurrency: '',
  primaryCurrencySymbol: ''
};

const defaultHeaderSummarySheetInfo = {
  isPlaceholder: true,
  numPlaceholderRows: 1,
  numPlaceholderFinalCostRows: 1,
  reportingVariables: []
};

const initialState: ProjectRootState = {
  allAreaTitlesSorted: [],
  areaClassificationsSorted: [],
  areaTitlesSorted: [],
  areasWithNewActivity: {
    activityDivisions: {},
    activitySections: {},
    bidNoteDivisions: {},
    bidNoteSections: {},
    seenItemMessageIds: [],
    seenBidNoteIds: [],
    lastSeenActivityIds: {}
  },
  cancelPromotedItem: null,
  changeHistory: null,
  companyLimitations: { maxItemTitleLength: undefined },
  companySettings: {
    allowContributorRole: false,
    allowJoinExport: false,
    alwaysShowDecimals: false,
    companyId: 0,
    customDesignations: {},
    defaultAssignType: ProjectRoleType.Estimator,
    formulas: [],
    id: 0,
    isWbsEnabled: false,
    limitations: {},
    orderedShorthandNotations: defaultOrderedShorthandNotations,
    shorthandNotations: defaultShorthandNotations,
    stageGates: [],
    useShorthandNotation: false
  },
  currencies: [],
  bidderInfoSorted: [],
  copyAreasTitleOrLabel: null,
  customDesignations: defaultCustomDesignations,
  directCost: 0,
  divisions: [],
  deletingSectionIds: [],
  editingCell: null,
  expandedDivisions: [],
  expandedSections: [],
  finalCostsDivisions: [],
  finalCostsSections: [],
  headerOpacity: 0,
  headerSummarySheetInfo: defaultHeaderSummarySheetInfo,
  headerSummarySheetInfoLoaded: false,
  isComparingAllBids: false,
  isHighlighterActive: false,
  isNotDivisionView: false,
  isProjectAssignmentExpanded: false,
  isTodoDialogOpen: false,
  isResettingGrandTotal: false,
  loggedInUserRepCompanyId: null,
  originalProject: {
    ...defaultProject
  },
  phaseSummarySheetInfo: {
    divisionPhaseId: null,
    sectionPhaseId: null,
    name: null
  },
  phaseSummarySheetPivotInfo: {
    moduleName: undefined,
    moduleType: SummarySheetPivotType.Division
  },
  phaseToShow: null,
  project: {
    ...defaultProject
  },
  projectAccumulatedUnitStats: {},
  projectImportInProgress: false,
  projectInput: {
    name: '',
    address: '',
    city: '',
    state: '',
    postalCode: ''
  },
  projectPermissions: ProjectDefaultPermissionStructure,
  projectLoaded: false,
  sectionIds: [],
  strippedBids: [],
  sectionVisibility: {},
  selectedCell: null,
  selectedDivisionId: undefined,
  selectedItemId: null,
  selectedSectionId: undefined,
  summarySheetData: undefined,
  summarySheetDataIsLoading: false,
  viewType: 'Division',
  wbsEphemeral: {
    columnDataByColumnId: {},
    mainTableSelectedIds: [],
    resourcesTableSelectedIds: [],
    deleteRowTableOptions: {
      showSelectedRowsAsDeleting: false,
      isForResources: false
    },
    invalidTitleRowIds: [],
    wbsLookup: { wbs: { topLevelDataRows: [], codeLookup: {} }, rbs: { topLevelDataRows: [], codeLookup: {} }
    }
  },
  wbsPushIssuesDialogOpen: false,
  wbsPushPullInProgress: false,
  // null = not open, true = resources table, false = main WBS table
  wbsSplitDialogOpen: null
};

export interface UpdateBidInfoPayload {
  projectCompanyId: number,
  bids: BidShape[]
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reset: () => initialState,

    updateAllowContributorRole: (state, action: PayloadAction<CompanySettings['allowContributorRole']>) => {

      state.companySettings.allowContributorRole = action.payload;
    },

    updateAllowJoinExport: (state, action: PayloadAction<CompanySettings['allowJoinExport']>) => {

      state.companySettings.allowJoinExport = action.payload;
    },

    updateIsWbsEnabled: (state, action: PayloadAction<CompanySettings['isWbsEnabled']>) => {

      state.companySettings.isWbsEnabled = action.payload;
    },

    updateAreasWithNewActivity: (state, action: PayloadAction<AreasWithNewActivity>) => {

      state.areasWithNewActivity = action.payload;
    },

    updateAreasWithNewActivityWithNewBidInfo: (state, action: PayloadAction<BidShape[]>) => {

      const newAreasWithNewActivity: AreasWithNewActivity = _.cloneDeep(state.areasWithNewActivity);
      newAreasWithNewActivity.bidNoteDivisions = {};
      newAreasWithNewActivity.bidNoteSections = {};

      const seenBidNoteIdsSet = new Set(newAreasWithNewActivity.seenBidNoteIds);

      for (const bid of action.payload) {
        for (const unreadNoteId of (bid?.unreadNoteIds || [])) {
          if (seenBidNoteIdsSet.has(unreadNoteId)) {
            continue;
          }

          if (!newAreasWithNewActivity.bidNoteSections[bid.sectionId]) {
            newAreasWithNewActivity.bidNoteSections[bid.sectionId] = [unreadNoteId];
          }
          else {
            newAreasWithNewActivity.bidNoteSections[bid.sectionId].push(unreadNoteId);
          }

          if (!newAreasWithNewActivity.bidNoteDivisions[bid.divisionId]) {
            newAreasWithNewActivity.bidNoteDivisions[bid.divisionId] = [unreadNoteId];
          }
          else {
            newAreasWithNewActivity.bidNoteDivisions[bid.divisionId].push(unreadNoteId);
          }
        }
      }

      state.areasWithNewActivity = newAreasWithNewActivity;
    },

    updateAreaTitles: (state, action: PayloadAction<AreaTitlesInfo>) => {

      state.allAreaTitlesSorted = action.payload.allAreaTitlesSorted;
      state.areaClassificationsSorted = action.payload.areaClassificationsSorted;
      state.areaTitlesSorted = action.payload.areaTitlesSorted;
    },

    updateBidInfo: (state, action: PayloadAction<UpdateBidInfoPayload>) => {

      const { bids, projectCompanyId } = action.payload;
      const companyNamesSorted: BidderInfo[] = [];
      const strippedBids = [];

      if (!bids?.length) {
        state.bidderInfoSorted = companyNamesSorted;
      }
      else {
        const seenCompanies = new Set();
        for (const bid of bids) {
          if (bid.companyId === projectCompanyId) {
            continue;
          }

          if (!seenCompanies.has(bid.companyId)) {
            companyNamesSorted.push({ companyId: bid.companyId, name: bid.company.name });
          }

          strippedBids.push({ id: bid.id, companyId: bid.companyId, companyName: bid.company.name, status: bid.status });
          seenCompanies.add(bid.companyId);
        }

        state.strippedBids = strippedBids;
        state.bidderInfoSorted = companyNamesSorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }
    },

    updateCancelPromotedItem: (state, action: PayloadAction<SectionItemShape | null>) => {

      state.cancelPromotedItem = action.payload;
    },


    updateChangeHistory: (state, action: PayloadAction<ChangeHistoryState | null>) => {

      state.changeHistory = action.payload;
    },

    updateCompanySettings: (state, action: PayloadAction<CompanySettings>) => {

      state.companySettings = action.payload;
    },

    updateCopyAreasTitleOrLabel: (state, action: PayloadAction<string | null>) => {

      state.copyAreasTitleOrLabel = action.payload;
    },

    updateDeletingSectionIds: (state, action: PayloadAction<number[]>) => {

      state.deletingSectionIds = action.payload;
    },

    updateDirectCost: (state, action: PayloadAction<number>) => {

      state.directCost = action.payload;
    },

    updateEditingCell: (state, action: PayloadAction<string | null>) => {

      state.editingCell = action.payload;
    },

    updateIsHighlighterActive: (state, action: PayloadAction<boolean>) => {

      state.isHighlighterActive = action.payload;
      state.editingCell = null;
      state.selectedCell = null;
      state.selectedItemId = null;
    },

    updateSelectedCell: (state, action: PayloadAction<string | null>) => {

      state.selectedCell = action.payload;
    },

    updateSelectedItemId: (state, action: PayloadAction<number | null>) => {

      state.selectedItemId = action.payload;
    },

    updateViewType: (state, action: PayloadAction<string>) => {

      state.viewType = action.payload;
      state.isNotDivisionView = action.payload !== api.Project.ProjectViewTypes.Division;
    },

    updateDivisionVisibilityPercentages: (state, action) => {

      const highlightedDivisions = action.payload;
      state.headerOpacity = highlightedDivisions.has('HDR') ? Math.max(highlightedDivisions.get('HDR'), 0.1) : 0;

      const selectedDivisionId = getSelectedId(action.payload);
      if (selectedDivisionId) {
        state.selectedDivisionId = selectedDivisionId;
      }
    },

    updateIsResettingGrandTotal: (state, action: PayloadAction<boolean>) => {

      state.isResettingGrandTotal = action.payload;
    },

    updateIsTodoDialogOpen: (state, action: PayloadAction<boolean>) => {

      state.isTodoDialogOpen = action.payload;
    },

    updateSectionVisibilityPercentages: (state, action) => {

      const selectedSectionId = getSelectedId(action.payload);
      if (selectedSectionId) {
        state.selectedSectionId = selectedSectionId;
      }
    },

    toggleIsComparingAllBids: (state) => {

      state.isComparingAllBids = !state.isComparingAllBids;
    },

    toggleSection: (state, action: PayloadAction<number>) => {

      const sectionId = action.payload;
      toggleElement(state.expandedSections, sectionId);
    },

    toggleDivision: (state, action: PayloadAction<DivisionId>) => {

      const divisionId = action.payload;
      toggleElement(state.expandedDivisions, divisionId);
    },

    updateProject: (state, action: PayloadAction<Partial<ProjectState>>) => {

      state.projectLoaded = true;
      const project = action.payload;
      state.project = { ...state.project, ...project };
      state.project.id = (project.id || state.project.id) as number;
      state.projectInput = {
        name: state.project.name,
        address: state.project.address,
        city: state.project.city,
        state: state.project.state,
        postalCode: state.project.postalCode
      };
    },

    updateOriginalProject: (state, action: PayloadAction<OriginalProject>) => {

      state.projectLoaded = true;
      const originalProject = action.payload;
      state.project = {
        ...state.project,
        id: originalProject.id,
        name: originalProject.name,
        address: originalProject.address,
        city: originalProject.city,
        postalCode: originalProject.postalCode,
        state: originalProject.state,
        availableColorSchemes: originalProject.availableColorSchemes,
        colorScheme: originalProject.colorScheme,
        primaryCurrency: originalProject.primaryCurrency,
        primaryCurrencySymbol: originalProject.primaryCurrencySymbol
      };
      state.projectInput = {
        name: state.project.name,
        address: state.project.address,
        city: state.project.city,
        state: state.project.state,
        postalCode: state.project.postalCode
      };

      state.projectPermissions = originalProject.projectPermissions || ProjectDefaultPermissionStructure;
      const customDesignations = originalProject?.company?.settings?.customDesignations || {};
      state.customDesignations = _.defaults(customDesignations, defaultCustomDesignations);
      state.originalProject = originalProject;

      const limitations =
        _.defaults(originalProject.company?.settings?.limitations || {}, { maxItemTitleLength: undefined });
      state.companyLimitations = limitations;

      const currencies: ProjectCurrencyShape[] = [];
      originalProject.currencies.forEach((currency: ProjectCurrencyShape) => currencies.push(currency));
      state.currencies = currencies;

      const divisions: DivisionState[] = [];
      originalProject.divisions.forEach((division: DivisionState) => divisions.push(division));
      state.divisions = divisions;
      state.sectionIds = getAllSectionIds(divisions);

      const finalCostsDivisions = divisions.filter((division) => division.isFinalCosts);
      state.finalCostsDivisions = finalCostsDivisions;
      state.finalCostsSections = finalCostsDivisions.flatMap((division) => division.sections);

      if (!state.headerSummarySheetInfoLoaded) {
        state.headerSummarySheetInfo = {
          isPlaceholder: true,
          numPlaceholderRows:
            state.originalProject.divisions.filter((division: DivisionState) => !division.isFinalCosts).length,
          numPlaceholderFinalCostRows:
            state.originalProject.divisions.filter((division: DivisionState) => division.isFinalCosts).length,
          reportingVariables: []
        };
      }

      if (state.deletingSectionIds.length) {
        state.deletingSectionIds = [];
      }
    },

    updateProjectInput: (state, action: PayloadAction<ProjectInputState>) => {

      state.projectInput = action.payload;
    },

    updateDivision: (
      state,
      action: PayloadAction<{ divisionId: number, division: Partial<DivisionState>, isDelete: boolean }>
    ) => {

      const { divisionId, division, isDelete } = action.payload;

      if (!isDelete) {
        const originalDivision = state.divisions.find((d) => d.id === divisionId) as DivisionState;
        originalDivision.label = division.label ?? originalDivision.label;
        originalDivision.name = division.name ?? originalDivision.name;

        const finalCostsDivision = state.finalCostsDivisions.find((d) => d.id === divisionId) as DivisionState;
        if (finalCostsDivision) {
          finalCostsDivision.label = division.label ?? finalCostsDivision.label;
          finalCostsDivision.name = division.name ?? finalCostsDivision.name;
        }

        updateTopLevelRows(state.headerSummarySheetInfo.directCostsSheet?.topLevelRows ?? [], action.payload);
        updateTopLevelRows(state.headerSummarySheetInfo.finalCostsSheet?.topLevelRows ?? [], action.payload);

        state.headerSummarySheetInfo.trackerTotals?.divisions?.forEach((d: DivisionState) => {

          if (d.id === divisionId && division.name && d.name !== division.name) {
            d.name = division.name;
          }
        });
      }
      else {
        state.divisions = state.divisions.filter((div) => div.id !== divisionId);
      }

      state.originalProject.divisions = state.divisions;
    },

    updateSection: (state, action: PayloadAction<UpdatedSection>) => {

      const { sectionId, isDelete, divisionId, title, area } = action.payload;
      const division = _.find(state.divisions, { id: divisionId });
      const section = _.find(division?.sections, { id: sectionId });

      if (division && section) {
        if (isDelete) {
          division.sections = division.sections.filter((s) => s.id !== sectionId);
        }
        else {
          if (title) {
            section.title = title;
          }

          if (area) {
            section.area = area;
          }
        }
      }
    },

    updateSectionVisibility: (state, action: PayloadAction<{ [index: string]: boolean }>) => {

      state.sectionVisibility = action.payload;
    },

    updateHeaderImageTimestamp: (state) => {

      state.project.headerImageTimestamp = new Date().getTime();
    },

    updatePhaseSummarySheetPivotInfo: (state, action: PayloadAction<PhaseSummarySheetPivotState>) => {

      state.phaseSummarySheetPivotInfo = action.payload;
    },

    updatePhaseSummarySheetInfo: (state, action: PayloadAction<PhaseSummarySheetState>) => {

      state.phaseSummarySheetInfo = action.payload;
    },

    updatePhaseToShow: (state, action: PayloadAction<PhaseToShowState | null>) => {

      state.phaseToShow = action.payload;
    },

    updateProjectAccumulatedUnitStats: (state, action: PayloadAction<{ [index: string]: number }>) => {

      state.projectAccumulatedUnitStats = action.payload;
    },

    updatePhaseSummarySheet: (state, action: PayloadAction<PhaseSummarySheetState>) => {

      state.phaseSummarySheetInfo = { ...state.phaseSummarySheetInfo, summarySheet: action.payload };
    },

    // TODO: flesh out this any
    updateSummarySheetData: (state, action: PayloadAction<any>) => {

      // Ignore the new data if the existing data is more recent
      if (state.summarySheetData && state.summarySheetData.projectId === action.payload.projectId) {
        const newSheetTimestamp = new Date(action.payload.timestamp);
        const existingSheetTimestamp = new Date(state.summarySheetData.timestamp);

        if (newSheetTimestamp < existingSheetTimestamp) {
          return;
        }
      }

      state.summarySheetData = action.payload;
    },

    // TODO: flesh out this any
    updateSummarySheetDataIsLoading: (state, action: PayloadAction<boolean>) => {

      state.summarySheetDataIsLoading = action.payload;
    },

    updateHeaderSummarySheetInfo: (state, action: PayloadAction<any>) => {

      state.headerSummarySheetInfoLoaded = Boolean(action.payload);
      state.headerSummarySheetInfo = action.payload || defaultHeaderSummarySheetInfo;
    },

    setWBSMetadata: (state, action: PayloadAction<WBSMetadataShape>) => {

      state.wbsMetadata = action.payload;
    },

    updateWBSMetadata: (state, action: PayloadAction<Partial<Omit<WBSMetadataShape, 'projectId'>>>) => {

      if (state.wbsMetadata) {
        state.wbsMetadata = { ...state.wbsMetadata, ...action.payload };
      }
    },

    setWBSMainTableSelectedIds: (state, action: PayloadAction<number[]>) => {

      state.wbsEphemeral.mainTableSelectedIds = action.payload;
    },

    setWBSResourcesTableSelectedIds: (state, action: PayloadAction<number[]>) => {

      state.wbsEphemeral.resourcesTableSelectedIds = action.payload;
    },

    setWBSColumnDataByColumnId: (state, action: PayloadAction<{ [index: string]: WBSColumnDataShape[] }>) => {

      state.wbsEphemeral.columnDataByColumnId = action.payload;
    },

    setWBSShowSelectedRowsAsDeleting: (
      state,
      action: PayloadAction<{ showSelectedRowsAsDeleting: boolean, isForResources: boolean}>
    ) => {

      const { showSelectedRowsAsDeleting, isForResources } = action.payload;
      state.wbsEphemeral.deleteRowTableOptions.isForResources = isForResources;
      state.wbsEphemeral.deleteRowTableOptions.showSelectedRowsAsDeleting = showSelectedRowsAsDeleting;
    },

    updateIsProjectAssignmentExpanded: (state, action: PayloadAction<boolean>) => {

      state.isProjectAssignmentExpanded = action.payload;
    },

    updateProjectImportInProgress: (state, action: PayloadAction<boolean>) => {

      state.projectImportInProgress = action.payload;
    },

    updateWbsPushIssuesDialogOpen: (state, action: PayloadAction<boolean>) => {

      state.wbsPushIssuesDialogOpen = action.payload;
    },

    updateWbsPushPullInProgress: (state, action: PayloadAction<boolean>) => {

      state.wbsPushPullInProgress = action.payload;
    },

    updateWbsSplitDialogOpen: (state, action: PayloadAction<boolean | null>) => {

      state.wbsSplitDialogOpen = action.payload;
    },

    updateProjectLockedByUserId: (state, action: PayloadAction<number | null>) => {

      state.originalProject.lockedByUserId = action.payload;
    }
  }
});

export const {
  reset,
  setWBSColumnDataByColumnId,
  setWBSShowSelectedRowsAsDeleting,
  setWBSMainTableSelectedIds,
  setWBSResourcesTableSelectedIds,
  setWBSMetadata,
  toggleDivision,
  toggleIsComparingAllBids,
  toggleSection,
  updateAllowContributorRole,
  updateAllowJoinExport,
  updateIsWbsEnabled,
  updateAreasWithNewActivity,
  updateAreasWithNewActivityWithNewBidInfo,
  updateAreaTitles,
  updateBidInfo,
  updateCancelPromotedItem,
  updateChangeHistory,
  updateCompanySettings,
  updateCopyAreasTitleOrLabel,
  updateDirectCost,
  updateDivision,
  updateDeletingSectionIds,
  updateDivisionVisibilityPercentages,
  updateEditingCell,
  updateHeaderImageTimestamp,
  updateHeaderSummarySheetInfo,
  updateIsHighlighterActive,
  updateIsProjectAssignmentExpanded,
  updateIsTodoDialogOpen,
  updateIsResettingGrandTotal,
  updateOriginalProject,
  updatePhaseSummarySheet,
  updatePhaseSummarySheetInfo,
  updatePhaseSummarySheetPivotInfo,
  updatePhaseToShow,
  updateProject,
  updateProjectAccumulatedUnitStats,
  updateProjectImportInProgress,
  updateProjectInput,
  updateProjectLockedByUserId,
  updateSection,
  updateSectionVisibility,
  updateSectionVisibilityPercentages,
  updateSelectedCell,
  updateSelectedItemId,
  updateSummarySheetData,
  updateSummarySheetDataIsLoading,
  updateViewType,
  updateWBSMetadata,
  updateWbsPushIssuesDialogOpen,
  updateWbsPushPullInProgress,
  updateWbsSplitDialogOpen
} = projectSlice.actions;

export default projectSlice.reducer;
