import request from 'superagent';

import apiCommon from './common';

export default {

  AllowedLogoImageTypes: [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp'
  ],

  ContactTypes: {
    Email: 'Email',
    TextMessage: 'Text Message'
  },

  CompanyListMessageTypes: {
    ImportStatus: 'ImportStatus',
    ImportError: 'ImportError',
    ImportFinished: 'ImportFinished'
  },

  DefaultXmlImportTableNames: {
    area: 'LocationTable',
    classification: 'MinorSectionTable',
    division: 'MajorSectionTable',
    hiercode: 'WBS8Table'
  },

  AddProjectLabel: async function (companyId, label) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/add-label')
      .send({
        companyId,
        label
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddStageGate: async function (companyId, name, color) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/add-stage-gate')
      .send({
        companyId,
        color,
        name
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AssignLabels: async function (companyIds, labelIds, expirationDate) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/assign-labels')
      .send({ companyIds, labelIds, expirationDate });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AssignLabelToProject: async function (projectLabelId, projectId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/assign-label-to-project')
      .send({
        projectLabelId,
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);

  },

  CheckNameInUse: async function (name, ignoreId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/check-name-in-use')
      .query({ name, ignoreId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  // name (required), type (required), address1, address2, city, state, postalCode,
  // country, phone, fax, email, contactPreference, logoFile, isPrivate, sendAppInviteEmail
  Create: async function (details) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/create');

    for (const [field, value] of Object.entries(details)) {
      if (field !== 'logoFile') {
        apiRequest.field(field, value);
      }
    }

    if (details.logoFile) {
      apiRequest.attach('logoFile', details.logoFile);
    }

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  CreateLabels: async function (names, assignToCompanyIds, expirationDate) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/create-labels')
      .send({
        names,
        assignToCompanyIds,
        expirationDate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ChangeCompanyUserType: async function (userId, userType) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/change-company-user-type')
      .send({
        userId,
        userType
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Delete: async function (id) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/delete')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteStageGate: async function (stageGateId) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/company/delete-stage-gate')
      .send({ stageGateId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteProjectLabel: async function (id) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/delete-project-label')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteLabel: async function (companyLabelId) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/delete-label')
      .send({ companyLabelId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteLogo: async function (companyId) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/delete-logo')
      .send({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveProjectLabel: async function (labelId, projectId) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/remove-project-label')
      .send({
        labelId,
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DownloadLogo: async function (companyId, noRedirect) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/download-logo')
      .query({ companyId, noRedirect });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  // id (required), name, type, address1, address2, city, state, postalCode
  // country, phone, fax, email, contactPreference, logoFile, isPrivate
  Edit: async function (details) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/edit');

    for (const [field, value] of Object.entries(details)) {
      if (field !== 'logoFile') {
        apiRequest.field(field, value);
      }
    }

    if (details.logoFile) {
      apiRequest.attach('logoFile', details.logoFile);
    }

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditAssignedCompanyLabel: async function (id, expirationDate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/edit-assigned-label')
      .send({
        id,
        expirationDate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditSettings: async function ({
    companyId,
    customDesignations,
    activeFormulaIds,
    alwaysShowDecimals,
    limitations,
    defaultAssignType,
    defaultXmlImportTableNames,
    allowJoinExport,
    allowContributorRole,
    isWbsEnabled,
    persistentModuleNames,
    useShorthandNotation,
    shorthandNotations
  }) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/edit-settings')
      .send({
        companyId,
        customDesignations,
        activeFormulaIds,
        alwaysShowDecimals,
        limitations,
        defaultAssignType,
        defaultXmlImportTableNames,
        allowJoinExport,
        allowContributorRole,
        isWbsEnabled,
        persistentModuleNames,
        useShorthandNotation,
        shorthandNotations
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EventLog: async (type, page, pageSize, orderBy, sortOrder, searchDescription, selectedUserIds, selectedFilterIds) => {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/event-log')
      .query({
        type,
        page,
        pageSize,
        orderBy,
        sortOrder,
        searchDescription,
        selectedUserIds: JSON.stringify(selectedUserIds),
        selectedFilterIds: JSON.stringify(selectedFilterIds)
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetAllLabels: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-all-labels')
      .send({});

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetArchivedProjects: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-archived-projects');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetProjectLabelsAssigned: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-assigned-labels')
      .send({});

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetDetails: async function (id) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/details')
      .query({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetHierarchyNamesOnly: async function (id) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-hierarchy-names-only')
      .query({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSettings: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-settings')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetStageGates: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-stage-gates')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetUsers: async function (id) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-users')
      .query({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  IgnoreCompanyLabel: async function (companyLabelAssignedId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/ignore-company-label')
      .send({ companyLabelAssignedId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ImportCompanies: async function (fieldToIndexMap, dataRows, sendAppInviteEmail, labels) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/import-companies')
      .send({ fieldToIndexMap, dataRows, sendAppInviteEmail, labels });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ImportWbsValues: async function (projectId, dataRows, wbsValueType) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/cost-management-dashboard/import-wbs-values')
      .send({ projectId, dataRows, wbsValueType });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  SearchLabels: async function (search, skipSetIsLoading) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/search-labels')
      .query({ search });

    return await apiCommon.ProcessRequest(apiRequest, { skipSetIsLoading });
  },

  UpdateStageGate: async function (stageGateId, updates) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/update-stage-gate')
      .send({
        id: stageGateId,
        ...updates
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UnassignLabel: async function (companyIds, labelId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/unassign-label')
      .send({ companyIds, labelId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UnignoreCompanyLabel: async function (id) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/unignore-company-label')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UpdateLabelColor: async function (labelInfo) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/assign-label-color')
      .send({
        id: labelInfo.assignedLabelId,
        color: labelInfo.color,
        companyLabelId: labelInfo.companyLabelId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddCurve: async function ({ companyId, name, points }) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/add-curve')
      .send({ companyId, name, points });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditCurve: async function ({ id, companyId, name, points }) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/edit-curve')
      .send({ id, companyId, name, points });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteCurve: async function ({ companyId, id }) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/delete-curve')
      .send({ companyId, id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetCurves: async function ({ companyId }) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-curves')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteSamlConfig: async function ({ id }) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/company/delete-saml-config')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSamlConfigs: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-saml-configs');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetPrimaryCompanies: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/company/get-primary-companies');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UpdateSamlConfig: async function ({ id, domain, entityId, metadata, companyId }) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/company/edit-saml-config')
      .send({ id, domain, entityId, metadata, companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  CreateSamlConfig: async function ({ domain, entityId, metadata, companyId }) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/company/create-saml-config')
      .send({ domain, entityId, metadata, companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  }
};
