import { WBSDataRowShapeWithStates } from '../../wbs/calculateWBSState';
import { WBSColumnDataDataFields } from 'wbs/dist/types/WBSColumnDataShape';
import { wbsColumnOrder } from '../../../components/Project/WBSTable/wbsColumnDetails';
import { WBSColumnDataShape } from '../../../types/api/WBSColumnDataShape';
import { WBSColumnShape } from '../../../types/api/WBSColumnShape';
import api from '../../../api';

const getCellValue = (
  dataRow: WBSDataRowShapeWithStates,
  columns: WBSColumnShape[],
  columnDataByColumnId: { [index: string]: WBSColumnDataShape[] },
  columnIndex: number,
  customCodesEnabled: boolean
) => {

  if (columnIndex < wbsColumnOrder.length) {
    const columnName = wbsColumnOrder[columnIndex];
    return (customCodesEnabled && columnName === 'wbsCode') ?
      dataRow.customWbsCode :
      dataRow[columnName];
  }

  const additionalColumnIndex = columnIndex - wbsColumnOrder.length;
  const column = columns[additionalColumnIndex];
  const data = columnDataByColumnId[column.id]?.find((d: WBSColumnDataShape) => d.wbsDataId === dataRow.id);
  let value = '';
  if (data) {
    value =
      data[api.Project.WBSColumnTypeToDataField[column.columnType] as keyof Omit<WBSColumnDataDataFields, 'units'>] ??
      '';
  }

  return value || api.Project.WBSColumnTypeToDefaultValue[column.columnType];
};

export const copyTableColumn = (
  dataRows: WBSDataRowShapeWithStates[],
  columns: WBSColumnShape[],
  columnDataByColumnId: { [index: string]: WBSColumnDataShape[] },
  columnIndex: number,
  customCodesEnabled: boolean
) => {

  const rowData =
    dataRows.map((dataRow) => getCellValue(dataRow, columns, columnDataByColumnId, columnIndex, customCodesEnabled));

  navigator.clipboard.writeText(rowData.join('\r\n'));
};

export const copyWholeTable = (
  dataRows: WBSDataRowShapeWithStates[],
  columns: WBSColumnShape[],
  columnDataByColumnId: { [index: string]: WBSColumnDataShape[] },
  customCodesEnabled: boolean
) => {

  const rowData = [];
  for (const dataRow of dataRows) {
    const columnData = [];
    const totalColumnLength = wbsColumnOrder.length + columns.length;

    for (let columnIndex = 0; columnIndex < totalColumnLength; ++columnIndex) {
      columnData.push(getCellValue(dataRow, columns, columnDataByColumnId, columnIndex, customCodesEnabled));
    }

    rowData.push(columnData.join('\t'));
  }

  navigator.clipboard.writeText(rowData.join('\r\n'));
};
