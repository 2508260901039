import request from 'superagent';

import apiCommon from './common';

export default async function (searchQuery) {

  const apiRequest = request
    .get(process.env.REACT_APP_API_URL + '/search')
    .query({ q: searchQuery });

  return await apiCommon.ProcessRequest(apiRequest);

}
