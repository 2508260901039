import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import api from '../../api';
import Chat from './Chat';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import CreateProject from './CreateProject';
import Deadlines from './Deadlines';
import Format from '../../helpers/Format';
import ListSorting from '../../helpers/ListSorting';
import ProjectOptions from './ProjectOptions';
import RecentProjects from './RecentProjects';
import Todos from './Todos';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import FullscreenIcon from '@material-ui/icons/Fullscreen';

import { UserType } from '../../types/enums/UserType';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    maxWidth: '99%'
  },
  panel: {
    backgroundColor: theme.palette.table.light,
    borderRadius: '5px',
    boxShadow: `1px 2px 8px ${theme.palette.app.shadowLight}`,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 375px',
    height: '60vh',
    margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    minHeight: '450px',
    padding: theme.spacing(2),
    width: '100%'
  },
  panelGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(8),
    minHeight: '400px'
  },
  panelTitle: {
    alignItems: 'center',
    borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    height: '1.75rem',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    width: '100%'
  },
  panelTitleFullScreenButton: {
    marginLeft: theme.spacing(0.5)
  },
  panelTitleLink: {
    fontSize: '1rem',
    border: `1px solid transparent`,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    padding: `0 ${theme.spacing(0.5)}px`,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '5px',
      color: theme.palette.secondary.dark
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(5) + 1
  }
}));

const UserDashboard = (props) => {

  const classes = useStyles();
  const loggedInUserDetails = useSelector((state) => state.ephemeralState.loggedInUserDetails);
  const history = useHistory();
  const isChrome = navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc');
  const thirtyDays = 2592000000;
  const timeSinceLastRecommendChrome = new Date().getTime() -
                                       new Date(localStorage.getItem('dateRecommendedChrome')).getTime();
  const showRecommendChrome = !isChrome && timeSinceLastRecommendChrome > thirtyDays;

  const [activityLog, setActivityLog] = useState({});
  const [deadlines, setDeadlines] = useState([]);
  const [fullScreenModalType, setFullScreenModalType] = useState(null);
  const [trackingIdsToNewProjectIds, setTrackingIdsToNewProjectIds] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectsInfo, setProjectsInfo] = useState([]);
  const [projectTemplates, setProjectTemplates] = useState([]);
  const [recentProjects, setRecentProjects] = useState([]);
  const [recommendChrome, setRecommendChrome] = useState(showRecommendChrome);

  const isDateCloseOrPastNow = useCallback((isoDate) => {

    const tenDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 10);
    const checkDate = new Date(isoDate);

    return checkDate > tenDaysAgo;
  }, []);

  const handleLinkClicked = useCallback((event) => {

    const targetURL = event.currentTarget.dataset.targetUrl;
    history.push(targetURL);
  }, [history]);

  const afterGetDashboardInfo = useCallback((result) => {

    if (result.err) {
      return;
    }

    const {
      activityLog: newActivityLog,
      projects: newProjects,
      templates
    } = result.res.body;

    if (newActivityLog) {
      setActivityLog(newActivityLog);
    }

    if (templates) {
      setProjectTemplates(templates);
    }

    if (newProjects) {

      setProjects(newProjects);
      const newProjectsInfo = newProjects.map((project) => {

        return {
          companyId: project.companyId,
          cust: project.cust,
          id: project.id,
          isTemplate: project.isTemplate,
          name: project.name,
          projectPermissions: project.projectPermissions
        };
      });

      newProjectsInfo.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      setProjectsInfo(newProjectsInfo);

      const projectsSortedByDueDate = ListSorting.stableSort(
        newProjects,
        ListSorting.getComparator('desc', 'recentLogDate')
      ).slice(0, 10);

      const recentProjectInfo = projectsSortedByDueDate.map((p) => ({

        dueDate: DateTime.fromISO(p.dueDate).toLocal().toFormat(Format.DueDateFormatString),
        id: p.id,
        isCloseToDue: isDateCloseOrPastNow(p.dueDate),
        name: p.name
      }));

      setRecentProjects(recentProjectInfo);

      const deadlinesByPhaseNameAndOrProjectId = {};
      for (const project of projectsSortedByDueDate) {

        deadlinesByPhaseNameAndOrProjectId[project.id] = {
          dueDate: new Date(project.dueDate),
          dueDateDisplay: DateTime.fromISO(project.dueDate).toLocal().toFormat(Format.DueDateFormatString),
          isCloseToDue: isDateCloseOrPastNow(project.dueDate),
          projectId: project.id,
          projectName: project.name,
          type: 'project'
        };

        for (const division of project.divisions) {
          for (const section of division.sections) {

            if (!section.hasPhases) {
              continue;
            }

            for (const phase of section.phases) {
              if (phase.dateAdvanced) {
                continue;
              }

              const key = `${phase.name.toLowerCase()}_${project.id}`;
              let shouldAddPhase = true;

              if (deadlinesByPhaseNameAndOrProjectId[key]) {
                if (deadlinesByPhaseNameAndOrProjectId[key].dueDate < new Date(phase.dueDate)) {
                  shouldAddPhase = false;
                }
              }

              if (shouldAddPhase) {
                deadlinesByPhaseNameAndOrProjectId[key] = {
                  dueDate: new Date(phase.dueDate),
                  dueDateDisplay: DateTime.fromISO(phase.dueDate).toLocal().toFormat(Format.DueDateFormatString),
                  isCloseToDue: isDateCloseOrPastNow(phase.dueDate),
                  name: phase.name,
                  phaseId: phase.id,
                  projectId: project.id,
                  projectName: project.name,
                  sectionId: section.id,
                  type: 'sectionPhase'
                };
              }
            }
          }
        }
      }

      const newDeadlines = [...Object.values(deadlinesByPhaseNameAndOrProjectId)].filter((d) => d.isCloseToDue);
      newDeadlines.sort((a, b) => a.dueDate - b.dueDate);
      setDeadlines(newDeadlines);
    }
  }, [isDateCloseOrPastNow]);

  const getDashboardInfo = useCallback((onlyItems) => {

    api.User.GetDashboardInfo(onlyItems).then(afterGetDashboardInfo);
  }, [afterGetDashboardInfo]);

  useEffect(() => {

    getDashboardInfo();
  }, [getDashboardInfo]);

  const onRealtimeActivity = useCallback((update) => {

    const itemsToUpdate = [];
    if (update.projectCreated) {
      itemsToUpdate.push('projects');
      if (update.trackingId) {
        setTrackingIdsToNewProjectIds((prev) => ({ ...prev, [update.trackingId]: update.project.id }));
      }
    }

    if (update.activity) {
      itemsToUpdate.push('todos', 'chat');
    }

    if (update.projectUpdated || update.phaseUpdated) {
      itemsToUpdate.push('projects', 'templates');
    }

    if (itemsToUpdate.length) {
      getDashboardInfo(itemsToUpdate);
    }
  }, [getDashboardInfo]);

  useEffect(() => {

    api.Realtime.Subscribe('/projects-list', onRealtimeActivity);
    api.Realtime.Subscribe('/activity', onRealtimeActivity);

    return () => {

      api.Realtime.Unsubscribe('/projects-list', onRealtimeActivity);
      api.Realtime.Unsubscribe('/activity', onRealtimeActivity);
    };
  }, [onRealtimeActivity]);

  const handleCloseFullScreenModal = useCallback(() => setFullScreenModalType(null), []);

  const handleConfirmRecommendChrome = useCallback(() => {

    localStorage.setItem('dateRecommendedChrome', new Date().toISOString());
    setRecommendChrome(false);
  }, []);


  const handleFullScreenClick = useCallback((evt) => {

    const { type } = evt.currentTarget.dataset;
    setFullScreenModalType(type);
  }, []);

  const handleProjectsUpdated = useCallback(async () => await getDashboardInfo(['projects']), [getDashboardInfo]);

  const renderFullScreenButton = useCallback((type) => {

    if (fullScreenModalType) {
      return null;
    }

    return (
      <IconButton
        className={classes.panelTitleFullScreenButton}
        onClick={handleFullScreenClick}
        size="small"
        data-type={type}
        data-cy="full-screen-button"
      >
        <FullscreenIcon />
      </IconButton>
    );
  }, [classes, fullScreenModalType, handleFullScreenClick]);

  const todosUI = useMemo(() => {

    return (
      <div className={classes.panel} data-cy="dashboard.todos">
        <span className={classes.panelTitle}>
          To-Do
          {renderFullScreenButton('todos')}
        </span>
        <Todos
          isFullScreen={fullScreenModalType === 'todos'}
          onCloseFullScreen={handleCloseFullScreenModal}
          projectsInfo={projectsInfo}
          loggedInUserDetails={loggedInUserDetails}
        />
      </div>
    );
  }, [
    classes,
    fullScreenModalType,
    handleCloseFullScreenModal,
    loggedInUserDetails,
    projectsInfo,
    renderFullScreenButton
  ]);

  const chatUI = useMemo(() => {

    return (
      <div className={classes.panel} data-cy="dashboard.chat">
        <span className={classes.panelTitle}>
          Chat
          {renderFullScreenButton('chat')}
        </span>
        <Chat
          activityLog={activityLog.activity}
          isFullScreen={fullScreenModalType === 'chat'}
          onCloseFullScreen={handleCloseFullScreenModal}
        />
      </div>
    );
  }, [activityLog, classes, fullScreenModalType, handleCloseFullScreenModal, renderFullScreenButton]);

  const deadlinesUI = useMemo(() => {

    return (
      <div className={classes.panel} data-cy="dashboard.deadlines">
        <span className={classes.panelTitle}>
          Upcoming Deadlines
          {renderFullScreenButton('deadlines')}
        </span>
        <Deadlines
          isFullScreen={fullScreenModalType === 'deadlines'}
          onCloseFullScreen={handleCloseFullScreenModal}
          deadlines={deadlines}
        />
      </div>
    );
  }, [classes, deadlines, fullScreenModalType, handleCloseFullScreenModal, renderFullScreenButton]);

  const recentProjectsUI = useMemo(() => {

    return (
      <div className={classes.panel} data-cy="dashboard.recent-projects">
        <span className={classes.panelTitle}>
          Recent Projects
          <span>
            <span
              className={classes.panelTitleLink}
              data-target-url="/projects"
              onClick={handleLinkClicked}
            >
              View All
            </span>

            {renderFullScreenButton('recentProjects')}
          </span>
        </span>
        <RecentProjects
          isFullScreen={fullScreenModalType === 'recentProjects'}
          onCloseFullScreen={handleCloseFullScreenModal}
          projects={recentProjects}
        />
      </div>
    );
  }, [
    classes,
    fullScreenModalType,
    handleCloseFullScreenModal,
    handleLinkClicked,
    recentProjects,
    renderFullScreenButton
  ]);

  const createProjectUI = useMemo(() => {

    if (!loggedInUserDetails?.primaryCompanyId) {
      return null;
    }

    return (
      <div className={classes.panel} data-cy="dashboard.create-project">
        <span className={classes.panelTitle}>Create Project</span>
        <CreateProject
          onNonTemplateProjectAdded={handleProjectsUpdated}
          projectsInfo={projectTemplates}
          trackingIdsToNewProjectIds={trackingIdsToNewProjectIds}
          userCompanyId={loggedInUserDetails?.primaryCompanyId}
        />
      </div>
    );
  }, [
    classes,
    handleProjectsUpdated,
    loggedInUserDetails,
    trackingIdsToNewProjectIds,
    projectTemplates
  ]);

  const projectOptionsUI = useMemo(() => {

    return (
      <div className={classes.panel} data-cy="dashboard.project-options">
        <span className={classes.panelTitle}>
          Project Options
          {renderFullScreenButton('projectOptions')}
        </span>
        <ProjectOptions
          activityLog={activityLog.activity}
          isFullScreen={fullScreenModalType === 'projectOptions'}
          onCloseFullScreen={handleCloseFullScreenModal}
          onProjectUpdated={handleProjectsUpdated}
          projects={projects}
        />
      </div>
    );
  }, [
    activityLog,
    classes,
    fullScreenModalType,
    handleCloseFullScreenModal,
    handleProjectsUpdated,
    projects,
    renderFullScreenButton
  ]);

  return useMemo(() => {

    if (_.isEmpty(loggedInUserDetails) ) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Typography className={classes.pageTitle} variant="h5" component="div" data-cy="dashboard.header">
          {loggedInUserDetails.fullName}
        </Typography>

        <div className={classes.panelGrid}>
          {todosUI}
          {chatUI}
          {deadlinesUI}
          {recentProjectsUI}
          {createProjectUI}
          {projectOptionsUI}
        </div>
        <ConfirmDialog
          open={recommendChrome}
          description={
            'This website is optimized and tested on Google Chrome. We suggest you use Google Chrome for the' +
            ' optimal experience.'
          }
          confirmLabel="Got it"
          handleConfirm={handleConfirmRecommendChrome}
        />
      </div>
    );
  }, [
    chatUI,
    classes,
    createProjectUI,
    deadlinesUI,
    handleConfirmRecommendChrome,
    loggedInUserDetails,
    projectOptionsUI,
    recommendChrome,
    recentProjectsUI,
    todosUI
  ]);
};

export default withRouter(UserDashboard);
