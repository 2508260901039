import request from 'superagent';
import store from '../redux/store';

import apiCommon from './common';
import {
  attachmentList,
  attachmentTags,
  baseApi,
  bidList,
  bidTags,
  divisionTags,
  phaseSummarySheetTags,
  projectTags,
  projectDetailsTags,
  sectionHierarchyTags,
  sectionTags,
  sectionItemTags,
  summarySheetTags,
  summarySheetCollectionTags,
  summarySheetCollectionList,
  projectTagList,
  projectTagTags,
  projectVariableTags,
  trackerList,
  trackerTags,
  divisionList,
  sectionList,
  metadataList,
  sectionItemList,
  projectVariableList,
  wbsDataList,
  wbsHiddenRowList,
  wbsPhasesList,
  wbsPhasesTags,
  wbsDataTags,
  wbsMetadataList,
  curveList
} from './baseApi';
import { CurrencyShape } from '../types/api/ProjectCurrencyShape';


const constants = {

  DraggableTypes: {
    Dialog: 'Dialog',
    Division: 'Division',
    Module: 'Module',
    ModuleHeader: 'ModuleHeader',
    Section: 'Section',
    SectionItem: 'SectionItem',
    User: 'User',
    VarianceTableColumn: 'VarianceTableColumn'
  },

  DroppableRoleListsOrder: [
    'ProjectManagerList',
    'ProjectEstimatorList',
    'ProjectContributorList',
    'ProjectApproverList',
    'ProjectViewerList',
    'UnassignedUserList'
  ],

  UserManagementDraggingSources: [
    'AutoAddSectionModuleList',
    'DetailsViewModuleList',
    'Division',
    'Phase',
    'ProjectApproverList',
    'ProjectEstimatorList',
    'ProjectContributorList',
    'ProjectLevelApproverList',
    'ProjectLevelEstimatorList',
    'ProjectLevelContributorList',
    'ProjectLevelManagerList',
    'ProjectManagerList',
    'ProjectViewerList',
    'Section',
    'SimilarPhase',
    'UnassignedUserList'
  ],

  AttachmentType: {
    PDF: 'PDF',
    TakeoffMarkup: 'TakeoffMarkup',
    FullPageMarkup: 'FullPageMarkup',
    File: 'File'
  },

  InviteTypes: {
    App: 'App',
    Bid: 'Bid',
    EstimatorAssignment: 'EstimatorAssignment',
    Project: 'Project'
  },

  MessageRepeatType: {
    Always: 'Always',
    Once: 'Once'
  },

  MessageVisibleToType: {
    Everyone: 'Everyone',
    InvitedSubs: 'InvitedSubs',
    MyTeam: 'MyTeam'
  },

  ModuleType: {
    Accounting: 'Accounting',
    Details: 'Details',
    EarnedValue: 'EarnedValue',
    PdfMarkup: 'PdfMarkup',
    Time: 'Time',
    CostQuantity: 'CostQuantity',
    CostQuantityActual: 'CostQuantityActual',
    Comment: 'Comment',
    RawData: 'RawData',
    Metadata: 'Metadata',
    ReportingVariable: 'ReportingVariable',
    SetMultiplier: 'SetMultiplier'
  },

  ModuleDisplayName: {
    Accounting: 'Accounting',
    Details: 'Details',
    EarnedValue: 'Earned Value',
    PdfMarkup: 'Pdf Markup',
    Time: 'Time',
    CostQuantity: 'Cost Quantity',
    CostQuantityActual: 'Cost Quantity Actual',
    Comment: 'Comment',
    Metadata: 'Metadata',
    RawData: 'Raw Data',
    ReportingVariable: 'Reporting Variable',
    SetMultiplier: 'Multiplier'
  },

  DuplicatableModules: {
    Accounting: 'Accounting',
    CostQuantity: 'CostQuantity',
    Comment: 'Comment',
    RawData: 'RawData',
    ReportingVariable: 'ReportingVariable',
    SetMultiplier: 'SetMultiplier'
  },

  DefaultModuleIcon: {
    Accounting: 'icon_building_office.svg',
    Details: 'icon_building_office.svg',
    EarnedValue: 'icon_network_01.svg',
    CostQuantity: 'icon_materials_brick.svg',
    CostQuantityActual: 'icon_materials_brick.svg',
    Comment: 'icon_chat.svg',
    Metadata: 'icon_wheelbarrow.svg',
    PdfMarkup: 'icon_search-cloud.svg',
    Time: 'icon_circular_clock.svg',
    RawData: 'icon_database.svg',
    ReportingVariable: 'icon_layers.svg',
    SetMultiplier: 'icon_shapes_molecule_01.svg'
  },

  ModulesRequiringUniqueNames: [
    'Accounting',
    'RawData'
  ],

  PivotableModuleTypes: [
    'Accounting',
    'Metadata',
    'RawData'
  ],

  ClientDataCrossReferenceFilterFields: {
    DivisionName: 'DivisionName',
    DivisionNumber: 'DivisionNumber',
    DivisionMetadata: 'DivisionMetadata',
    SectionTitle: 'SectionTitle',
    SectionLabel: 'SectionLabel',
    ItemTitle: 'ItemTitle'
  },

  ClientDataCrossReferenceAutoPopulateFields: {
    Module: 'Module'
  },

  CostQuantityStatus: {
    Undefined: 'Undefined',
    Included: 'Included',
    NotIncluded: 'Not Included',
    CostQuantity: 'Cost Quantity',
    LumpSum: 'Lump Sum',
    Response: 'Response'
  },

  CostQuantityNonNumericStatus: {
    Included: 'Included',
    NotIncluded: 'Not Included',
    Response: 'Response'
  },

  CostQuantStatusTypes: ['Undefined', 'Cost Quantity'],

  KPIWidgets: {
    BidsWidget: 'BidsWidget',
    CommentsWidget: 'CommentsWidget',
    CostReportWidget: 'CostReportWidget',
    CostsWidget: 'CostsWidget',
    EstimateComparisonWidget: 'EstimateComparisonWidget',
    FileWidget: 'FileWidget',
    IntegrationsWidget: 'IntegrationsWidget',
    MetadataWidget: 'MetadataWidget',
    MilestonesWidget: 'MilestonesWidget',
    ProjectMilestonesWidget: 'ProjectMilestonesWidget',
    ReportsWidget: 'ReportsWidget',
    SCurveWidget: 'SCurveWidget',
    VarianceWidget: 'VarianceWidget'
  },

  KPIWidgetToDisplayName: {
    BidsWidget: 'Bids',
    CommentsWidget: 'Comments',
    CostReportWidget: 'Cost Report',
    CostsWidget: 'Costs',
    EstimateComparisonWidget: 'Estimate Comparison',
    FileWidget: 'File',
    IntegrationsWidget: 'Integrations',
    MetadataWidget: 'Metadata',
    MilestonesWidget: 'Milestones',
    ProjectMilestonesWidget: 'Project Milestones',
    ReportsWidget: 'Reports',
    SCurveWidget: 'S Curve',
    VarianceWidget: 'Variance'
  },

  KPIWidgetMinDimensions: {
    BidsWidget: {
      minH: 3,
      minW: 6
    },
    CommentsWidget: {
      minH: 2,
      minW: 4
    },
    CostReportWidget: {
      minH: 4,
      minW: 8
    },
    CostsWidget: {
      minH: 4,
      minW: 5
    },
    EstimateComparisonWidget: {
      minH: 3,
      minW: 5
    },
    FileWidget: {
      minH: 4,
      minW: 5
    },
    IntegrationsWidget: {
      minH: 2,
      minW: 5
    },
    MetadataWidget: {
      minH: 3,
      minW: 5
    },
    MilestonesWidget: {
      minH: 3,
      minW: 3
    },
    ProjectMilestonesWidget: {
      minH: 4,
      minW: 6
    },
    ReportsWidget: {
      minH: 3,
      minW: 4
    },
    SCurveWidget: {
      minH: 5,
      minW: 8
    },
    VarianceWidget: {
      minH: 3,
      minW: 8
    }
  },

  KPIWidgetDefaultMinDimensions: {
    minH: 2,
    minW: 2
  },

  Roles: {
    Approver: 'Approver',
    Contributor: 'Contributor',
    Estimator: 'Estimator',
    Manager: 'Manager',
    Viewer: 'Viewer'
  },

  TrackerType: {
    AreaTotals: 'AreaTotals',
    BidderTotals: 'BidderTotals',
    CostQuantityTotals: 'CostQuantityTotals',
    CostTotals: 'CostTotals',
    DivisionTotals: 'DivisionTotals',
    FormulaTotals: 'FormulaTotals',
    LineItemTotals: 'LineItemTotals',
    QuantityTotals: 'QuantityTotals',
    TagTotals: 'TagTotals',
    UnitTotals: 'UnitTotals',
    VariableTotals: 'VariableTotals',
    WithIdObjects: [
      'UnitTotals'
    ]
  },

  MetadataScopeTypes: {
    Division: 'Division',
    Project: 'Project',
    Section: 'Section'
  },

  PostMessageFields: {
    CreateDetailsViewClicked: 'CreateDetailsViewClicked'
  },

  ProjectViewTypes: {
    Division: 'Division',
    Classification: 'Classification',
    Area: 'Area'
  },

  VisibilityTypes: {
    Restricted: 'Restricted',
    CompanyWide: 'CompanyWide',
    Private: 'Private'
  },

  VisibilityNames: {
    Restricted: 'Team-wide',
    CompanyWide: 'Company-wide',
    Private: 'Private'
  },

  BuffFieldType: {
    CostQuantity: 'CostQuantity',
    ManualEntry: 'ManualEntry',
    SectionTotal: 'SectionTotal',
    SectionRollingTotal: 'SectionRollingTotal',
    SectionMin: 'SectionMin',
    SectionMax: 'SectionMax',
    SectionAvg: 'SectionAvg',
    ProjectTag: 'ProjectTag',
    DirectCostProjectTag: 'DirectCostProjectTag',
    DirectCostQuantity: 'DirectCostQuantity',
    ProjectVariable: 'ProjectVariable',
    LineItem: 'LineItem',
    ProjectTotal: 'ProjectTotal',
    RollingCost: 'RollingCost',
    OtherSectionTotal: 'OtherSectionTotal',
    Unit: 'Unit'
  },

  BuffOperator: {
    Add: 'Add',
    Subtract: 'Subtract',
    Multiply: 'Multiply',
    Divide: 'Divide'
  },

  SectionItemRevertTypes: {
    Response: 'Response',
    Title: 'Title'
  },

  SectionLiveUpdatesLockedReason: {
    EditingModuleData: 'EditingModuleData',
    SplitViewOpen: 'SplitViewOpen'
  },

  WBSColumnDisplayName: {
    Accounting: 'Accounting',
    RawData: 'Raw Data',
    SetMultiplier: 'Set Multiplier'
  },

  WBSColumnType: {
    Accounting: 'Accounting',
    RawData: 'RawData',
    SetMultiplier: 'SetMultiplier'
  },

  WBSColumnTypeToDataField: {
    Accounting: 'data',
    RawData: 'rawData',
    SetMultiplier: 'setMultiplier'
  },

  WBSColumnTypeToDefaultValue: {
    Accounting: '',
    RawData: '',
    SetMultiplier: '1'
  },

  NumericWBSColumnTypes: ['SetMultiplier']
};

const RequestAttachmentUrl = async function (url) {

  const apiRequest = request
    .get(url)
    .query({
      noRedirect: true
    });

  const result = await apiCommon.ProcessRequest(apiRequest);

  if (result.err) {
    // TODO: error out?
    return '';
  }

  return result.res.body.downloadUrl;
};

export const ProjectApiSlice = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({

    addCurve: builder.mutation({
      query: ({ companyId, name, points }) => ({
        url: '/project/add-curve',
        method: 'POST',
        data: { companyId, name, points }
      }),
      invalidatesTags: [curveList]
    }),

    addBids: builder.mutation({
      query: ({ sectionIds, companyIds, userIdsToAssignByProjectId, autoAddToDivisionIds }) => ({
        url: '/project/add-bids',
        method: 'POST',
        data: { sectionIds, companyIds, userIdsToAssignByProjectId, autoAddToDivisionIds }
      }),
      invalidatesTags: (_result, _error, arg) => [bidList, sectionTags(arg.sectionIds)]
    }),

    addContract: builder.mutation({
      query: ({ projectId, name, description }) => ({
        url: '/project/add-contract',
        method: 'POST',
        data: { projectId, name, description }
      })
    }),

    addDivision: builder.mutation({
      query: ({ projectId, name }) => ({
        url: '/project/add-division',
        method: 'POST',
        data: { projectId, name }
      }),
      invalidatesTags: [divisionList]
    }),

    addHiddenWbsRows: builder.mutation({
      query: ({
        companyId,
        dashboardId,
        widgetIdent,
        rows
      }) => ({
        url: '/project/wbs/add-hidden-rows',
        method: 'POST',
        data: {
          companyId,
          dashboardId,
          widgetIdent,
          rows
        }
      }),
      invalidatesTags: [wbsHiddenRowList]
    }),

    addLineItem: builder.mutation({
      query: ({ sectionId, afterItemId, insertAtEnd, title }) => ({
        url: '/project/add-line-item',
        method: 'POST',
        data: { sectionId, afterItemId, insertAtEnd, title }
      }),
      invalidatesTags: (_result, _error, arg) => [
        sectionItemList,
        ...sectionTags(arg.sectionId)
      ]
    }),

    addMetadata: builder.mutation({
      query: ({ comment, metadataCategoryId, projectId, divisionId, sectionId, isPrivate }) => ({
        url: '/project/add-metadata',
        method: 'POST',
        data: { comment, metadataCategoryId, projectId, divisionId, sectionId, isPrivate }
      }),
      invalidatesTags: (_result, _error, arg) => [
        'ProjectDetails',
        ...sectionTags(arg.sectionId),
        ...divisionTags(arg.divisionId),
        ...projectTags(arg.projectId)
      ]
    }),

    addModule: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ sectionId, moduleType, name, isNewModule, moduleId, callback }) => ({
        url: '/project/add-module',
        method: 'PATCH',
        data: { sectionId, moduleType, name, isNewModule, moduleId, callback }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return [
          ...sectionTags(arg.sectionId),
          ...summarySheetTags(arg.projectId)
        ];
      }
    }),

    addProjectMilestoneWidgetEntry: builder.mutation({
      query: ({ projectId, description, date }) => ({
        url: '/project/kpi-widgets/add-project-milestone-widget-entry',
        method: 'POST',
        data: { projectId, description, date }
      })
    }),

    addSectionBuff: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ sectionId, afterOrderId }) => ({
        url: '/project/add-section-buff',
        method: 'POST',
        data: { sectionId, afterOrderId }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return sectionTags(arg.sectionId);
      }
    }),

    addSectionModulesToDivision: builder.mutation({
      query: ({ divisionId, moduleType, name }) => ({
        url: '/project/add-section-module-to-division',
        method: 'POST',
        data: { divisionId, moduleType, name }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...divisionTags(arg.divisionId),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    addSummarySheetCollection: builder.mutation({
      query: ({ projectId, name, description }) => ({
        url: '/project/add-summary-sheet-collection',
        method: 'POST',
        data: { projectId, name, description }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    addSummarySheetCollectionSnapshotComment: builder.mutation({
      extraOptions: { skipCsrf: true },
      query: ({ id, snapshotUUID, comment }) => ({
        url: '/project/add-summary-sheet-collection-snapshot-comment',
        method: 'POST',
        data: { id, snapshotUUID, comment }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    addSummarySheetCollectionView: builder.mutation({
      query: ({ collectionId, name, description, staticHtml }) => ({
        url: '/project/add-summary-sheet-collection-view',
        method: 'POST',
        data: { collectionId, name, description, staticHtml }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    addTag: builder.mutation({
      query: ({ projectId, name }) => ({
        url: '/project/add-tag',
        method: 'POST',
        data: { projectId, name }
      }),
      invalidatesTags: (_result, _error, arg) => [
        projectTagList,
        ...projectTags(arg.projectId),
        ...summarySheetTags(arg.projectId)
      ]
    }),

    addTagsToLineItems: builder.mutation({
      query: ({ itemIds, tagIds, phaseId }) => ({
        url: '/project/add-tags-to-line-items',
        method: 'POST',
        data: { itemIds, tagIds, phaseId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTagTags(arg.tagIds),
        ...sectionTags(arg.sectionId)
      ]
    }),

    addVariables: builder.mutation({
      query: ({ projectId, data }) => ({
        url: '/project/add-variables',
        method: 'POST',
        data: { projectId, data }
      }),
      invalidatesTags: (_result, _error, arg) => [
        projectVariableList,
        ...projectDetailsTags(arg.projectId),
        ...projectTags(arg.projectId)
      ]
    }),

    addTracker: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ tracker }) => ({
        url: '/project/add-tracker',
        method: 'POST',
        data: { tracker }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectDetailsTags(arg.projectId),
        trackerList
      ]
    }),

    addOrUpdateWbsCodeCustomization: builder.mutation({
      query: ({ isResources, level, mappingIndex, projectId, value }) => ({
        url: '/project/wbs/add-or-update-wbs-code-customization',
        method: 'POST',
        data: { isResources, level, mappingIndex, projectId, value }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList, wbsMetadataList]
    }),

    addWbsColumn: builder.mutation({
      query: ({ columnType, name, projectId, sortOrder }) => ({
        url: '/project/wbs/add-column',
        method: 'POST',
        data: { columnType, name, projectId, sortOrder }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList]
    }),

    addWbsPhase: builder.mutation({
      query: ({ projectId, name, dueDate }) => ({
        url: '/project/wbs/add-phase',
        method: 'POST',
        data: { projectId, name, dueDate }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsPhasesList]
    }),

    addWbsRow: builder.mutation({
      query: ({ wbsDataArray }) => ({
        url: '/project/wbs/add-row',
        method: 'POST',
        data: { wbsDataArray }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...wbsDataTags(_result.map((res) => res.id))
      ]
    }),

    advanceWbsPhase: builder.mutation({
      query: ({ projectId, contractId }) => ({
        url: '/project/wbs/advance-phase',
        method: 'POST',
        data: { projectId, contractId }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsPhasesList, wbsDataList]
    }),

    deleteProjectMilestoneWidgetEntry: builder.mutation({
      query: ({ id }) => ({
        url: '/project/kpi-widgets/delete-project-milestone-widget-entry',
        method: 'DELETE',
        data: { id }
      })
    }),

    editProjectMilestoneWidgetEntry: builder.mutation({
      query: ({ id, description, date }) => ({
        url: '/project/kpi-widgets/edit-project-milestone-widget-entry',
        method: 'PATCH',
        data: { id, description, date }
      })
    }),

    reassignWbsPull: builder.mutation({
      query: ({ projectId }) => ({
        url: '/project/wbs/reassign-pull',
        method: 'PATCH',
        data: { projectId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectDetailsTags(arg.projectId),
        wbsDataList,
        wbsMetadataList
      ]
    }),

    importXer: builder.mutation({
      query: ({ data }) => ({
        url: '/project/wbs/import-xer',
        method: 'POST',
        data: { ...data }
      }),
      invalidatesTags: (_result, _error, arg) => {

        return _result.id ? wbsDataTags(_result.id) : wbsDataList;
      }
    }),

    importMasterXer: builder.mutation({
      query: ({ data }) => ({
        url: '/project/wbs/import-master-xer',
        method: 'POST',
        data: { ...data }
      }),
      invalidatesTags: (_result, _error, arg) => {

        return _result.id ? wbsDataTags(_result.id) : wbsDataList;
      }
    }),

    importXerNodes: builder.mutation({
      query: ({ content, edgeClassName }) => ({
        url: '/project/wbs/import-xer-nodes',
        method: 'POST',
        data: { content, edgeClassName }
      })
    }),

    generateXerContent: builder.mutation({
      query: ({ projectId, content }) => ({
        url: '/project/wbs/generate-xer-content',
        method: 'POST',
        data: { projectId, content }
      })
    }),

    advancePhases: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ contractId, divisionIds, sectionIds, replyToProjectId, replyToProjectIgnoreResults }) => ({
        url: '/project/advance-phases',
        method: 'POST',
        data: { contractId, divisionIds, sectionIds, replyToProjectId, replyToProjectIgnoreResults }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...(arg.divisionIds.length ? ['ProjectDetails'] : []), // Division phases come from the requestProjectDetails call
        ...divisionTags(arg.divisionIds),
        ...sectionTags(arg.sectionIds)
      ]
    }),

    applyDivisionAutoAddBids: builder.mutation({
      query: ({ divisionId, companyIds }) => ({
        url: '/project/apply-division-auto-add-bids',
        method: 'POST',
        data: { divisionId, companyIds }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...divisionTags(arg.divisionId),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    applyDivisionAutoAddSectionModule: builder.mutation({
      query: ({ divisionId, moduleType, name, reportingProjectVariableId }) => ({
        url: '/project/apply-division-auto-add-section-module',
        method: 'POST',
        data: { divisionId, moduleType, name, reportingProjectVariableId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...divisionTags(arg.divisionId),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    applyDivisionWideTags: builder.mutation({
      query: ({ divisionId, tagIds }) => ({
        url: '/project/apply-division-wide-tags',
        method: 'POST',
        data: { id: divisionId, tagIds }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...divisionTags(arg.divisionId),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    applyLineItemCsv: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ itemId, bidId, columns, data, isDirectEntry }) => ({
        url: '/project/apply-line-item-csv',
        method: 'POST',
        data: { itemId, bidId, columns, data, isDirectEntry }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        sectionTags(arg.sectionId);
      }
    }),

    applySectionWideTags: builder.mutation({
      query: ({ sectionId, tagIds }) => ({
        url: '/project/apply-section-wide-tags',
        method: 'POST',
        data: { id: sectionId, tagIds }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTagTags(arg.tagIds),
        ...sectionTags(arg.sectionId)
      ]
    }),

    archiveDivision: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ id }) => ({
        url: '/project/archive-division',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return [
          ...divisionTags(arg.id),
          'ProjectTag'
        ];
      }
    }),

    archiveSection: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ id }) => ({
        url: '/project/archive-section',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return [
          ...projectTagTags(arg.tagIds),
          ...sectionTags(arg.id)
        ];
      }
    }),

    copyFilesToBidders: builder.mutation({
      query: ({ projectId, bidsToAdd, bidFiles, projectFiles, sectionFiles }) => ({
        url: '/project/copy-files-to-bidders',
        method: 'POST',
        data: { projectId, bidsToAdd, bidFiles, projectFiles, sectionFiles }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...attachmentTags(arg.bidFiles?.map(([id]) => id)),
        ...attachmentTags(arg.projectFiles?.map(([id]) => id)),
        ...attachmentTags(arg.sectionFiles?.map(([id]) => id))
      ]
    }),

    changeLineItemsDemotedPhase: builder.mutation({
      extraOptions: { allowNullValues: true },
      query: ({ itemIds, demotedInPhaseId }) => ({
        url: '/project/change-line-items-demoted-phase',
        method: 'PATCH',
        data: { itemIds, demotedInPhaseId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...sectionTags(arg.sectionId),
        ...sectionItemTags(arg.itemIds)
      ]
    }),

    copyLineItems: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ itemIds, afterItemId }) => ({
        url: '/project/copy-line-items',
        method: 'POST',
        data: { itemIds, afterItemId }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return sectionTags(arg.sectionId);
      }
    }),

    deleteBidAttachments: builder.mutation({
      query: ({ ids }) => ({
        url: '/project/delete-bid-attachments',
        method: 'DELETE',
        data: { ids }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...attachmentTags(arg.ids),
        'ProjectDetails'
      ]
    }),

    deleteItemAttachments: builder.mutation({
      query: ({ ids }) => ({
        url: '/project/delete-item-attachments',
        method: 'DELETE',
        data: { ids }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...attachmentTags(arg.ids),
        'ProjectDetails'
      ]
    }),

    deleteLineItems: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ lineItemIds }) => ({
        url: '/project/delete-line-items',
        method: 'DELETE',
        data: { lineItemIds }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);

        return [
          ...projectTagTags(arg.tagIds),
          ...sectionItemTags(arg.lineItemIds)
        ];
      }
    }),

    deleteProjectAttachments: builder.mutation({
      query: ({ ids }) => ({
        url: '/project/delete-project-attachments',
        method: 'DELETE',
        data: { ids }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...attachmentTags(arg.ids),
        'ProjectDetails'
      ]
    }),

    deleteSectionAttachments: builder.mutation({
      query: ({ ids }) => ({
        url: '/project/delete-section-attachments',
        method: 'DELETE',
        data: { ids }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...attachmentTags(arg.ids),
        'ProjectDetails'
      ]
    }),

    deleteSummarySheetCollection: builder.mutation({
      query: ({ id }) => ({
        url: '/project/delete-summary-sheet-collection',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    deleteSummarySheetCollectionAttachment: builder.mutation({
      query: ({ id }) => ({
        url: '/project/delete-summary-sheet-collection-attachment',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    deleteSummarySheetCollectionView: builder.mutation({
      query: ({ id }) => ({
        url: '/project/delete-summary-sheet-collection-view',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    deleteTag: builder.mutation({
      query: ({ projectId, id }) => ({
        url: '/project/delete-tag',
        method: 'POST',
        data: { projectId, id }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectDetailsTags(arg.projectId),
        ...projectTagTags(arg.id)
      ]
    }),

    deleteVariable: builder.mutation({
      query: ({ projectVariableId }) => ({
        url: '/project/delete-variable',
        method: 'DELETE',
        data: { projectVariableId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectDetailsTags(arg.projectId),
        ...projectVariableTags(arg.projectVariableId),
        'SectionItem'
      ]
    }),

    deleteMetadataWidgetRow: builder.mutation({
      query: ({ id }) => ({
        url: '/project/delete-metadata-widget-row',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    deleteWbsCodeCustomization: builder.mutation({
      query: ({ id }) => ({
        url: '/project/wbs/delete-wbs-code-customization',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList, wbsMetadataList]
    }),

    deleteWbsColumn: builder.mutation({
      query: ({ id }) => ({
        url: '/project/wbs/delete-column',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList]
    }),

    deleteWbsPhase: builder.mutation({
      query: ({ id }) => ({
        url: '/project/wbs/delete-phase',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsPhasesList]
    }),

    deleteWbsRow: builder.mutation({
      query: ({ ids }) => ({
        url: '/project/wbs/delete-rows',
        method: 'DELETE',
        data: { ids }
      }),
      invalidatesTags: (_result, _error, arg) => (

        (arg.skipInvalidate) ? [wbsMetadataList] : [wbsDataList, wbsMetadataList]
      )
    }),

    editAttachment: builder.mutation({
      query: ({ id, type, isPrivate }) => ({
        url: '/project/edit-attachment',
        method: 'POST',
        data: { id, type, isPrivate }
      }),
      invalidatesTags: (_result, _error, arg) => {

        const tags = [
          ...attachmentTags(arg.id),
          'ProjectDetails'
        ];

        if (arg.itemId) {
          tags.push(...sectionItemTags(arg.itemId));
        }

        return tags;
      }
    }),

    editBidStatus: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ id, status }) => ({
        url: '/project/edit-bid-status',
        method: 'PATCH',
        data: { id, status }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return [
          projectTagList,
          ...bidTags(arg.id)
        ];
      }
    }),

    editBidTotalOverride: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ id, total, isDirectEntry }) => ({
        url: '/project/edit-bid-total-override',
        method: 'PATCH',
        data: { id, total, isDirectEntry }
      }),
      // This is probably broader than it needs to be - may be narrowed later with additional args
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return (_result?.sectionId) ? sectionTags(_result.sectionId) : ['Project'];
      }
    }),

    editWbsCodeCustomization: builder.mutation({
      query: ({ id, value }) => ({
        url: '/project/wbs/edit-wbs-code-customization',
        method: 'PATCH',
        data: { id, value }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList, wbsMetadataList]
    }),

    editWbsColumn: builder.mutation({
      query: ({ id, name, newIndex, sortOrder }) => ({
        url: '/project/wbs/edit-column',
        method: 'PATCH',
        data: { id, name, newIndex, sortOrder }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList]
    }),

    editDivision: builder.mutation({
      query: ({ id, name, projectVariableId, phasesEnabled, advancePhase }) => ({
        url: '/project/edit-division',
        method: 'PATCH',
        data: { id, name, projectVariableId, phasesEnabled, advancePhase }
      }),
      invalidatesTags: (_result, _error, arg) => {

        return (arg.phasesEnabled !== undefined || arg.advancePhase) ? sectionTags(arg.sectionIds) : [];
      }
    }),

    editDivisionAutoAddSectionModule: builder.mutation({
      query: ({ id, name, newIndex }) => ({
        url: '/project/edit-division-auto-add-section-module',
        method: 'PATCH',
        data: { id, name, newIndex }
      }),
      invalidatesTags: (_result, _error, arg) => projectDetailsTags(arg.projectId)
    }),

    editDivisionLabel: builder.mutation({
      query: ({ id, label }) => ({
        url: '/project/edit-division-label',
        method: 'PATCH',
        data: { id, label }
      })
    }),

    editKpiLayouts: builder.mutation({
      query: ({ id, kpiLayouts }) => ({
        url: '/project/edit-kpi-layouts',
        method: 'PATCH',
        data: { id, kpiLayouts }
      }),
      invalidatesTags: (_result, _error, arg) => projectDetailsTags(arg.id)
    }),

    editLineItem: builder.mutation({
      extraOptions: { allowNullValues: true },
      query: ({
        id,
        title,
        projectVariableId,
        highlightColor
      }) => ({
        url: '/project/edit-line-item',
        method: 'PATCH',
        data: {
          id,
          title,
          projectVariableId,
          highlightColor
        }
      }),
      // This is probably broader than it needs to be - may be narrowed later with additional args
      invalidatesTags: (_result, _error, arg) => {

        return (_result?.sectionId) ? sectionTags(_result.sectionId) : ['Section'];
      }
    }),

    editLineItemModuleData: builder.mutation({
      extraOptions: { affectsGrandTotal: true, allowNullValues: true },
      query: ({ moduleType, id, payload, directEntry }) => {

        let routeName;
        const extras = {};

        switch (moduleType) {
          case constants.ModuleType.Accounting:
            routeName = 'accounting';
            break;
          case constants.ModuleType.Comment:
            routeName = 'comment';
            break;
          case constants.ModuleType.CostQuantity:
            routeName = 'cost-quantity';
            break;
          case constants.ModuleType.CostQuantityActual:
            routeName = 'cost-quantity';
            extras.isCostQuantityActual = true;
            break;
          case constants.ModuleType.Details:
            routeName = 'details';
            break;
          case constants.ModuleType.EarnedValue:
            routeName = 'earned-value';
            break;
          case constants.ModuleType.Metadata:
            routeName = 'metadata';
            break;
          case constants.ModuleType.PdfMarkup:
            routeName = 'pdf';
            break;
          case constants.ModuleType.RawData:
            routeName = 'raw-data';
            break;
          case constants.ModuleType.ReportingVariable:
            routeName = 'reporting-variable';
            break;
          case constants.ModuleType.SetMultiplier:
            routeName = 'set-multiplier';
            break;
          case constants.ModuleType.Time:
            routeName = 'time';
            break;

          default:
            return;
        }

        return {
          url: `/project/edit-module-data/${routeName}`,
          method: 'PATCH',
          data: { id, ...payload, ...extras, directEntry }
        };
      },
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return (_result?.sectionId) ? sectionHierarchyTags(_result.sectionId) : [];
      }
    }),

    editMetadata: builder.mutation({
      query: ({ id, comment, isPrivate }) => ({
        url: '/project/edit-metadata',
        method: 'PATCH',
        data: { id, comment, isPrivate }
      }),
      invalidatesTags: (_result, _error, arg) => {

        const tags = [metadataList];
        if (arg.sectionId) {
          tags.push(sectionTags(arg.sectionId)[0]);
        }
        else {
          tags.push('ProjectDetails');
        }

        return tags;
      }
    }),

    editModuleIcon: builder.mutation({
      query: ({ id, iconName, sectionId }) => ({
        url: '/project/edit-module-icon',
        method: 'PATCH',
        data: { id, iconName, sectionId }
      }),
      invalidatesTags: (_result, _error, arg) => sectionTags(arg.sectionId)
    }),

    editSection: builder.mutation({
      query: ({ id, area, title, area_projectVariableId, title_projectVariableId, hasPhases }) => ({
        url: '/project/edit-section',
        method: 'PATCH',
        data: { id, area, title, area_projectVariableId, title_projectVariableId, hasPhases }
      }),
      invalidatesTags: (_result, _error, arg) => {

        if (arg.hasPhases !== undefined) {
          return [...projectTags(), ...sectionTags(arg.id)];
        }

        return sectionTags(arg.id);
      }
    }),

    editSectionWithoutInvalidateTags: builder.mutation({
      query: ({ id, area, title, area_projectVariableId, title_projectVariableId, hasPhases }) => ({
        url: '/project/edit-section',
        method: 'PATCH',
        data: { id, area, title, area_projectVariableId, title_projectVariableId, hasPhases }
      })
    }),

    /*
  payload looks like this (all fields optional):
  {
    label: Joi.string().allow('').optional(),
    operandType: Joi.string().optional(),
    operandManualValue: Joi.number().optional(),
    operandProjectTagId: Joi.number().integer().optional(),
    operandProjectVariableId: Joi.number().integer().optional(),
    operandLineItemId: Joi.number().integer().optional(),
    operator: Joi.string().optional(),
    argumentType: Joi.string().optional(),
    argumentManualValue: Joi.number().optional(),
    argumentProjectTagId: Joi.number().integer().optional(),
    argumentProjectVariableId: Joi.number().integer().optional(),
    argumentLineItemId: Joi.number().integer().optional()
  }
   */
    editSectionBuff: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ sectionBuffId, payload }) => ({
        url: '/project/edit-section-buff',
        method: 'PATCH',
        data: { sectionBuffId, ...payload }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return sectionTags(arg.sectionId);
      }
    }),

    editSummarySheetCollection: builder.mutation({
      query: ({ id, name, description }) => ({
        url: '/project/edit-summary-sheet-collection',
        method: 'PATCH',
        data: { id, name, description }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    editSummarySheetCollectionView: builder.mutation({
      query: ({ id, name, description, filters, pivotType, plugIsPercent }) => ({
        url: '/project/edit-summary-sheet-collection-view',
        method: 'PATCH',
        data: { id, name, description, filters, pivotType, plugIsPercent }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    editSummarySheetComment: builder.mutation({
      query: ({ comment, companyId, divisionId, sectionId, sectionItemId }) => ({
        url: '/project/edit-summary-sheet-comment',
        method: 'POST',
        data: { comment, companyId, divisionId, sectionId, sectionItemId }
      }),
      invalidatesTags: (_result, _error, arg) => sectionTags(arg.sectionId)
    }),

    editTag: builder.mutation({
      query: ({ projectId, id, name }) => ({
        url: '/project/edit-tag',
        method: 'POST',
        data: { projectId, id, name }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTags(arg.projectId),
        ...summarySheetTags(arg.projectId),
        ...projectDetailsTags(arg.projectId),
        ...projectTagTags(arg.id)
      ]
    }),

    editVariable: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ projectVariableId, name, value }) => ({
        url: '/project/edit-variable',
        method: 'PATCH',
        data: { projectVariableId, name, value }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTags(arg.projectId),
        ...projectVariableTags(arg.projectVariableId)
      ]
    }),

    editWbsPhase: builder.mutation({
      query: ({ phaseId, name, dueDate }) => ({
        url: '/project/wbs/edit-phase',
        method: 'PATCH',
        data: { phaseId, name, dueDate }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...wbsPhasesTags(arg.phaseId)
      ]
    }),

    getCostQuantityHierarchy: builder.query({
      query: ({ projectId }) => ({
        url: '/project/cost-quantity-hierarchy',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...['Project'],
        ...projectTags(arg.projectId),

        divisionList,
        sectionList,
        bidList,

        ...divisionTags(result?.divisions?.map(({ id }) => id)),
        ...sectionTags(result?.divisions?.flatMap(({ sections }) => sections?.map(({ id }) => id))),
        ...bidTags(result?.divisions?.flatMap(({ sections }) => sections?.flatMap(({ bids }) => bids?.map(({ id }) => id))))
      ]
    }),

    getDetails: builder.query({
      query: ({ id, retainStructureIfTemplate, includeHierarchy, redirectOnError }) => ({
        url: '/project/details',
        method: 'GET',
        params: { id, retainStructureIfTemplate, includeHierarchy },
        data: { redirectOnError }
      }),
      providesTags: (result, _error, arg) => [
        ...['Project', 'ProjectDetails'],

        divisionList,
        metadataList,
        sectionList,

        ...projectTags(arg.id),
        ...projectDetailsTags(arg.id)
      ]
    }),

    getFiles: builder.query({
      query: ({ projectId }) => ({
        url: '/project/files',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...['Attachment','Project'],

        attachmentList,
        ...attachmentTags(result?.project?.attachments?.map(({ id }) => id)),
        ...attachmentTags(
          result?.project?.divisions?.flatMap((division) =>

            division.sections?.flatMap((section) =>

              section.attachments.map(({ id }) => id))
          )
        ),
        ...attachmentTags(
          result?.project?.divisions?.flatMap((division) =>

            division.sections?.flatMap((section) =>

              section.bids?.flatMap((bid) =>

                bid.attachments.map(({ id }) => id)
              )
            )
          )
        ),
        ...attachmentTags(
          result?.project?.divisions?.flatMap((division) =>

            division.sections?.flatMap((section) =>

              section.items?.flatMap((item) =>

                item.attachments.map(({ id }) => id)
              )
            )
          )
        ),
        ...projectTags(arg.projectId)
      ]
    }),

    getHierarchy: builder.query({
      query: ({ projectId, sectionId }) => ({
        url: '/project/hierarchy',
        method: 'GET',
        params: { projectId, sectionId }
      }),
      providesTags: (result, _error, arg) => [
        ...['Attachment','Bid','Division','Project','Section', 'SectionItem'],

        attachmentList,
        bidList,
        sectionItemList,
        ...attachmentTags(result?.divisions?.[0]?.sections?.[0]?.attachments.map(({ id }) => id)),
        ...divisionTags(result?.divisions?.[0]?.id),
        ...projectTags(arg.projectId),
        ...sectionTags(arg.sectionId),
        ...sectionHierarchyTags(arg.sectionId),
        ...sectionItemTags(result?.divisions?.[0]?.sections?.[0]?.items?.map(({ id }) => id)),

        ...(result?.divisions?.[0]?.sections?.[0]?.bids ?? []).flatMap((bid) => [
          ...attachmentTags((bid.attachments ?? []).map(({ id }) => id)),
          ...bidTags(bid.id)
        ])
      ]
    }),

    getProjectTags: builder.query({
      query: ({ projectId }) => ({
        url: '/project/tags',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...['Project','ProjectTag'],
        ...projectTags(arg.projectId),
        projectTagList,
        ...projectTagTags(result?.map(({ id }) => id))
      ]
    }),

    getProjectVariables: builder.query({
      query: ({ projectId }) => ({
        url: '/project/variables',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...['Project','ProjectVariable'],
        ...projectTags(arg.projectId),
        projectVariableList,
        ...projectVariableTags(result?.map(({ id }) => id))
      ]
    }),

    getWbsRowHiddenStates: builder.query({
      query: () => ({
        url: '/project/wbs/get-row-hidden-states',
        method: 'GET'
      }),
      providesTags: () => [wbsHiddenRowList]
    }),

    getSummarySheet: builder.query({
      extraOptions: { affectsGrandTotal: true },
      query: ({
        projectId,
        pivotType,
        pivotModuleName,
        filteredAreas,
        showOnlyCompanyIds,
        filteredPhaseNames,
        filteredTagIds,
        filteredDivisionPhaseId,
        filteredSectionPhaseId,
        omitBuffs,
        outputFilteredPdfs,
        grandTotalOnly,
        trackerTotalsOnly,
        immediateResponseRequired,
        shouldCacheParameters
      }) => ({
        url: '/project/summary-sheet',
        method: 'GET',
        params: {
          projectId,
          pivotType,
          pivotModuleName,
          filteredAreas,
          showOnlyCompanyIds,
          filteredPhaseNames,
          filteredTagIds,
          filteredDivisionPhaseId,
          filteredSectionPhaseId,
          omitBuffs,
          outputFilteredPdfs,
          grandTotalOnly,
          trackerTotalsOnly,
          immediateResponseRequired,
          shouldCacheParameters
        }
      }),
      providesTags: (result, _error, arg) => {

        const tags = [
          divisionList,
          ...divisionTags(result?.trackerTotals?.divisions?.map(({ id }) => id)),
          ...projectTags(arg.projectId),
          ...sectionTags(result?.trackerTotals?.sections?.map(({ id }) => id)),
          ...summarySheetTags(arg.projectId)
        ];

        if (arg.filteredDivisionPhaseId || arg.filteredSectionPhaseId) {
          tags.push(...phaseSummarySheetTags(arg.projectId));
        }

        return tags;
      }
    }),

    getSummarySheetCollections: builder.query({
      query: ({ includeStaticHtml, projectId }) => ({
        url: '/project/get-summary-sheet-collections',
        method: 'GET',
        params: { includeStaticHtml, projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...summarySheetCollectionTags(result.map(({ id }) => id)),
        summarySheetCollectionList
      ]
    }),

    getSummarySheetCollectionSnapshot: builder.query({
      extraOptions: { skipCsrf: true },
      query: ({ id, snapshotUUID }) => ({
        url: '/project/get-summary-sheet-collection-snapshot',
        method: 'GET',
        params: { id, snapshotUUID }
      })
    }),

    getSummarySheetCollectionViewHtml: builder.query({
      extraOptions: { skipCsrf: true },
      query: ({ id, snapshotUUID }) => ({
        url: '/project/get-summary-sheet-collection-view-html',
        method: 'GET',
        params: { id, snapshotUUID }
      })
    }),

    getTrackers: builder.query({
      query: ({ projectId, trackerIds }) => ({
        url: '/project/get-trackers',
        method: 'GET',
        params: { projectId, trackerIds }
      }),
      providesTags: (result, _error, arg) => [
        ...['Project', 'Tracker'],
        ...projectTags(arg.projectId),
        ...trackerTags(result.map(({ id }) => id)),
        trackerList
      ]
    }),

    getWbsData: builder.query({
      query: ({ projectId, isResources }) => ({
        url: '/project/wbs/get-data',
        method: 'GET',
        params: { projectId, isResources }
      }),
      providesTags: (result, _error, arg) => [
        ...projectTags(arg.projectId),
        wbsDataList
      ]
    }),

    getWbsPhases: builder.query({
      query: ({ projectId }) => ({
        url: '/project/wbs/get-phases',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...projectTags(arg.projectId),
        ...wbsPhasesTags(result.phases.map(({ id }) => id)),
        wbsPhasesList
      ]
    }),

    getDashboard: builder.query({
      query: ({ projectId, scenarioId }) => ({
        url: '/project/get-dashboard',
        method: 'GET',
        params: { projectId, scenarioId }
      })
    }),

    getProjectPermissions: builder.query({
      query: ({ projectId }) => ({
        url: '/project/get-permissions',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...projectTags(arg.projectId)
      ]
    }),

    getWbsMetadata: builder.query({
      query: ({ projectId }) => ({
        url: '/project/wbs/get-metadata',
        method: 'GET',
        params: { projectId }
      }),
      providesTags: (result, _error, arg) => [
        ...projectTags(arg.projectId),
        wbsMetadataList
      ]
    }),

    pullWbs: builder.mutation({
      query: ({ projectId }) => ({
        url: '/project/wbs/pull',
        method: 'POST',
        data: { projectId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        // no need to invalidate projectTagId here since it is manually done in the wbsPushPullDone socket
        wbsDataList,
        wbsMetadataList
      ]
    }),

    pushWbs: builder.mutation({
      query: ({ projectId }) => ({
        url: '/project/wbs/push',
        method: 'POST',
        data: { projectId }
      }),
      invalidatesTags: (_result, _error, arg) => [
        divisionList, sectionList, wbsMetadataList, projectDetailsTags(arg.projectId)
      ]
    }),

    removeDivisionAutoAddBids: builder.mutation({
      query: ({ divisionId, companyIds }) => ({
        url: '/project/remove-division-auto-add-bids',
        method: 'POST',
        data: { divisionId, companyIds }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...divisionTags(arg.divisionId),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    removeDivisionAutoAddSectionModule: builder.mutation({
      query: ({ id }) => ({
        url: '/project/remove-division-auto-add-section-module',
        method: 'POST',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => projectDetailsTags(arg.projectId)
    }),

    removeDivisionWideTags: builder.mutation({
      query: ({ divisionId, tagIds, removeAllExisting }) => ({
        url: '/project/remove-division-wide-tags',
        method: 'POST',
        data: { id: divisionId, tagIds, removeAllExisting }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...divisionTags(arg.divisionId),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    removeHiddenWbsRows: builder.mutation({
      query: ({
        companyId,
        dashboardId,
        widgetIdent,
        rows
      }) => ({
        url: '/project/wbs/remove-hidden-rows',
        method: 'DELETE',
        data: {
          companyId,
          dashboardId,
          widgetIdent,
          rows
        }
      }),
      invalidatesTags: [wbsHiddenRowList]
    }),

    removeMetadata: builder.mutation({
      query: ({ id }) => ({
        url: '/project/remove-metadata',
        method: 'POST',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => {

        const tags = [metadataList];
        if (arg.sectionId) {
          tags.push(sectionTags(arg.sectionId)[0]);
        }

        return tags;
      }
    }),

    removeModule: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ sectionId, moduleId }) => ({
        url: '/project/remove-module',
        method: 'PATCH',
        data: { sectionId, moduleId }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        sectionTags(arg.sectionId);
      }
    }),

    removeSectionBuff: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ sectionBuffId }) => ({
        url: '/project/remove-section-buff',
        method: 'DELETE',
        data: { sectionBuffId }
      }),
      invalidatesTags: (_result, _error, arg) => {

        invalidateFinalCostSectionTagsIfNeeded(arg.isFinalCosts);
        return sectionTags(arg.sectionId);
      }
    }),

    removeSectionWideTags: builder.mutation({
      query: ({ sectionId, tagIds, removeAllExisting }) => ({
        url: '/project/remove-section-wide-tags',
        method: 'POST',
        data: { id: sectionId, tagIds, removeAllExisting }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...sectionTags(arg.sectionId)
      ]
    }),

    removeTagsFromLineItems: builder.mutation({
      query: ({ itemIds, tagIds }) => ({
        url: '/project/remove-tags-from-line-items',
        method: 'POST',
        data: { itemIds, tagIds }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTagTags(arg.tagIds),
        ...sectionTags(arg.sectionId)
      ]
    }),

    removeTracker: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ trackerId }) => ({
        url: '/project/remove-tracker',
        method: 'DELETE',
        data: { trackerId }
      }),
      invalidatesTags: (_result, _error, arg) => trackerTags(arg.trackerId)
    }),

    reorderSectionModule: builder.mutation({
      query: ({ sectionId, moduleId, moveToSortOrder }) => ({
        url: '/project/reorder-section-module',
        method: 'PATCH',
        data: { sectionId, moduleId, moveToSortOrder }
      }),
      invalidatesTags: (_result, _error, arg) => sectionTags(arg.sectionId)
    }),

    reorderSection: builder.mutation({
      query: ({ id, newIndex, toDivisionId }) => ({
        url: '/project/reorder-section',
        method: 'PATCH',
        data: { id, toDivisionId, newIndex }
      }),
      invalidatesTags: (_result, _error, arg) => {

        const changedHierarchyTags = arg.changedSectionIds.flatMap((id) => sectionHierarchyTags(id));
        return [divisionList, ...changedHierarchyTags];
      }
    }),

    reorderSummarySheetCollectionChild: builder.mutation({
      query: ({ collectionId, childId, childType, newIndex }) => ({
        url: '/project/reorder-summary-sheet-collection-child',
        method: 'PATCH',
        data: { collectionId, childId, childType, newIndex }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    resendSummarySheetCollectionSnapshotEmail: builder.mutation({
      query: ({ id, email }) => ({
        url: '/project/resend-summary-sheet-collection-snapshot-email',
        method: 'PATCH',
        data: { id, email }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    respondToSummarySheetCollectionSnapshot: builder.mutation({
      extraOptions: { skipCsrf: true },
      query: ({ id, snapshotUUID, isApproved }) => ({
        url: '/project/respond-to-summary-sheet-collection-snapshot',
        method: 'PATCH',
        data: { id, snapshotUUID, isApproved }
      })
    }),

    saveSummarySheetCollectionSnapshot: builder.mutation({
      query: ({ id, email, name, description }) => ({
        url: '/project/save-summary-sheet-collection-snapshot',
        method: 'POST',
        data: { id, email, name, description }
      }),
      invalidatesTags: (_result, _error, arg) => [summarySheetCollectionList]
    }),

    saveVarianceReportSettings: builder.mutation({
      query: ({
        dashboardId,
        projectId,
        isDefaultForCompanyId,
        widgetIdent,
        columns,
        scenarioLevel
      }) => ({
        url: '/project/save-variance-report-settings',
        method: 'POST',
        data: {
          dashboardId,
          projectId,
          isDefaultForCompanyId,
          widgetIdent,
          columns,
          scenarioLevel
        }
      })
    }),

    sectionInfo: builder.query({
      query: ({ sectionId }) => ({
        url: '/project/section-info',
        method: 'GET',
        params: { sectionId }
      }),
      providesTags: (_result, _error, arg) => sectionTags(arg.sectionId)
    }),

    sendAllInvites: builder.mutation({
      query: ({ projectId, phaseId, divisionPhaseId }) => ({
        url: '/project/send-all-invites',
        method: 'POST',
        data: { projectId, phaseId, divisionPhaseId }
      }),
      invalidatesTags: (_result, _error, arg) => projectTags(arg.projectId)
    }),

    setWbsData: builder.mutation({
      query: ({ projectId, isResources, wbsDataArray }) => ({
        url: '/project/wbs/set-data',
        method: 'POST',
        data: { projectId, isResources, wbsDataArray }
      }),
      invalidatesTags: (_result, _error, arg) => [
        wbsDataList
      ]
    }),

    setDefineResources: builder.mutation({
      query: ({ projectId, rowSplits }) => ({
        url: '/project/wbs/set-define-resources',
        method: 'PATCH',
        data: { projectId, rowSplits }
      }),
      invalidatesTags: (_result, _error, arg) => [wbsDataList, wbsMetadataList]
    }),

    submitBidDraft: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ arrayOfBidIds }) => ({
        url: '/project/bid/submit-bid-draft',
        method: 'PATCH',
        data: { arrayOfBidIds }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...bidTags(arg.arrayOfBidIds),
        ...projectDetailsTags(arg.projectId)
      ]
    }),

    toggleSectionMetadataCategory: builder.mutation({
      query: ({ sectionId, metadataCategoryId }) => ({
        url: '/project/toggle-section-metadata-category',
        method: 'PATCH',
        data: { sectionId, metadataCategoryId }
      }),
      invalidatesTags: (_result, _error, arg) => sectionTags(arg.sectionId)
    }),

    toggleShowReportingVariable: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ sectionId, projectVariableId, divisionId, onlyToggleOn }) => ({
        url: '/project/toggle-show-reporting-variable',
        method: 'PATCH',
        data: { sectionId, projectVariableId, divisionId, onlyToggleOn }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...sectionTags(arg.sectionId),
        ...divisionTags(arg.divisionId),
        ...(!arg.sectionId && !arg.divisionId ? ['Project'] : [])
      ]
    }),

    toggleReportingVariable: builder.mutation({
      extraOptions: { affectsGrandTotal: true },
      query: ({ variableId, boolean }) => ({
        url: '/project/toggle-reporting-variable',
        method: 'PATCH',
        data: { variableId, boolean }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectVariableTags(arg.variableId),
        ...projectTags(arg.projectId)
      ]
    }),

    toggleShowTags: builder.mutation({
      query: ({ projectId, boolean }) => ({
        url: '/project/toggle-show-tags',
        method: 'PATCH',
        data: { projectId, boolean }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTags(arg.projectId),
        ...summarySheetTags(arg.projectId)
      ]
    }),

    updateApproverChecklist: builder.mutation({
      query: ({ id, isSummarySheetReviewed }) => ({
        url: '/project/update-approver-checklist',
        method: 'PATCH',
        data: { id, isSummarySheetReviewed }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTags(arg.projectId)
      ]
    }),

    updateWbsMetadata: builder.mutation({
      query: ({ projectId, metadata }) => ({
        url: '/project/wbs/update-metadata',
        method: 'PATCH',
        data: { projectId, ...metadata }
      }),
      invalidatesTags: (_result, _error, arg) => {

        const invalidatedTags = [wbsMetadataList];

        const {
          rbsCodeCustomizationsEnabled,
          rbsCodeSeparator,
          wbsCodeCustomizationsEnabled,
          wbsCodeSeparator
        } = arg.metadata;
        if (
          rbsCodeCustomizationsEnabled !== undefined ||
          rbsCodeSeparator !== undefined ||
          wbsCodeCustomizationsEnabled !== undefined ||
          wbsCodeSeparator !== undefined
        ) {
          invalidatedTags.push(wbsDataList);
        }

        return invalidatedTags;
      }
    }),

    updateWbsRows: builder.mutation({
      query: ({ wbsDataArray }) => {

        // it's possible that some of the calc functions have populated the cost field, but cost is not a valid field
        // to send to the server (inputCost is what the server expects).
        // TODO: If we ever switch back to the redux toolkit updateWbsRows function, we need to be sure to still use this.
        for (const change of wbsDataArray) {
          delete change.cost;

          if (change.resourceOverrides) {
            for (const override of change.resourceOverrides) {
              delete override.cost;
            }
          }
        }

        return {
          url: '/project/wbs/update-rows',
          method: 'PATCH',
          data: { wbsDataArray }
        };
      },
      invalidatesTags: (_result, _error, arg) => {

        const invalidatedTags = [...wbsDataTags(...arg.wbsDataArray.map((w) => w.id))];
        if (arg.forceRefreshDataList || arg.wbsDataArray.findIndex((d) => d.additionalColumnData) !== -1) {
          invalidatedTags.push(wbsDataList);
        }

        return invalidatedTags;
      }
    }),

    uploadBidAttachment: builder.mutation({
      extraOptions: {
        attachmentKey: 'file',
        useJsonRequestType: false,
        onProgressKey: 'onUploadProgress'
      },
      query: ({
        bidId,
        itemId,
        originalAttachmentId,
        attachmentType,
        displayFilename,
        folders,
        file,
        parentProjectAttachmentId,
        parentSectionAttachmentId,
        parentBidAttachmentId,
        isPrivate = false,
        pdfAnnotationPageNum,
        onUploadProgress
      }) => ({
        url: '/project/upload-bid-attachment',
        method: 'POST',
        data: {
          bidId,
          itemId,
          originalAttachmentId,
          attachmentType,
          displayFilename,
          folders,
          file,
          parentProjectAttachmentId,
          parentSectionAttachmentId,
          parentBidAttachmentId,
          isPrivate,
          pdfAnnotationPageNum,
          onUploadProgress
        }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...bidTags(arg.bidId),
        attachmentList,
        'ProjectDetails'
      ]
    }),

    uploadItemAttachment: builder.mutation({
      extraOptions: {
        attachmentKey: 'file',
        useJsonRequestType: false,
        onProgressKey: 'onUploadProgress'
      },
      query: ({
        bidId,
        itemId,
        originalAttachmentId,
        attachmentType,
        displayFilename,
        folders,
        file,
        parentProjectAttachmentId,
        parentSectionAttachmentId,
        parentBidAttachmentId,
        isPrivate = false,
        onUploadProgress
      }) => ({
        url: '/project/upload-item-attachment',
        method: 'POST',
        data: {
          bidId,
          itemId,
          originalAttachmentId,
          attachmentType,
          displayFilename,
          folders,
          file,
          parentProjectAttachmentId,
          parentSectionAttachmentId,
          parentBidAttachmentId,
          isPrivate,
          onUploadProgress
        }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...bidTags(arg.bidId),
        attachmentList,
        'ProjectDetails'
      ]
    }),

    uploadProjectAttachment: builder.mutation({
      extraOptions: {
        attachmentKey: 'file',
        useJsonRequestType: false,
        onProgressKey: 'onUploadProgress'
      },
      query: ({
        projectId,
        originalAttachmentId,
        attachmentType,
        displayFilename,
        folders,
        file,
        isPrivate = false,
        onUploadProgress
      }) => ({
        url: '/project/upload-project-attachment',
        method: 'POST',
        data: {
          projectId,
          originalAttachmentId,
          attachmentType,
          displayFilename,
          folders,
          file,
          isPrivate,
          onUploadProgress
        }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...projectTags(arg.projectId),
        attachmentList,
        'ProjectDetails'
      ]
    }),

    uploadSectionAttachment: builder.mutation({
      extraOptions: {
        attachmentKey: 'file',
        useJsonRequestType: false,
        onProgressKey: 'onUploadProgress'
      },
      query: ({
        sectionIds,
        originalAttachmentId,
        attachmentType,
        displayFilename,
        folders,
        file,
        isPrivate = false,
        onUploadProgress
      }) => ({
        url: '/project/upload-section-attachment',
        method: 'POST',
        data: {
          sectionIds,
          originalAttachmentId,
          attachmentType,
          displayFilename,
          folders,
          file,
          isPrivate,
          onUploadProgress
        }
      }),
      invalidatesTags: (_result, _error, arg) => [
        ...sectionTags(arg.sectionIds),
        attachmentList,
        'ProjectDetails'
      ]
    }),

    uploadFileWidgetAttachment: builder.mutation({
      extraOptions: {
        attachmentKey: 'file',
        useJsonRequestType: false,
        onProgressKey: 'onUploadProgress'
      },
      query: ({
        attachment,
        attachmentType,
        displayFileName,
        existingAttachmentId,
        existingAttachmentAreaType,
        projectId, uniqueWidgetName
      }) => ({
        url: '/project/kpi-widgets/upload-file-widget-attachment',
        method: 'POST',
        data: {
          attachment,
          attachmentType,
          displayFileName,
          existingAttachmentId,
          existingAttachmentAreaType,
          projectId,
          uniqueWidgetName
        }
      }),
      invalidatesTags: (_result, _error, arg) => projectDetailsTags(arg.projectId)
    }),

    uploadSummarysheetCollectionAttachment: builder.mutation({
      extraOptions: {
        attachmentKey: 'file',
        useJsonRequestType: false,
        onProgressKey: 'onUploadProgress'
      },
      query: ({
        collectionId,
        attachmentType,
        displayFilename,
        file,
        onUploadProgress
      }) => ({
        url: '/project/upload-summary-sheet-collection-attachment',
        method: 'POST',
        data: {
          collectionId,
          attachmentType,
          displayFilename,
          file,
          onUploadProgress
        }
      }),
      invalidatesTags: (_result, _error, arg) => [
        summarySheetCollectionList
      ]
    }),

    verifyWbsValue: builder.mutation({
      query: ({ status, wbsValueId }) => ({
        url: '/project/wbs/verify-value',
        method: 'POST',
        data: { status, wbsValueId }
      })
    })
  })
});

export const invalidateFinalCostSectionTagsIfNeeded = function (isFinalCosts) {

  if (!isFinalCosts) {
    return false;
  }

  const state = store.getState();
  const finalCostsDivision = state.project.originalProject.divisions.find((d) => d.isFinalCosts);
  if (!finalCostsDivision) {
    return false;
  }

  const finalCostSectionIds = finalCostsDivision.sections.map((s) => s.id);
  if (finalCostSectionIds.length) {
    const tagsToInvalidate = [];
    for (const id of finalCostSectionIds) {
      tagsToInvalidate.push(...sectionTags(id));
    }

    store.dispatch(ProjectApiSlice.util.invalidateTags(tagsToInvalidate));
    return true;
  }

  return false;
};

export default {

  ...constants,

  AddMetadataCategory: async function (projectId, scope, name, isPrivate) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-metadata-category')
      .send({
        projectId,
        scope,
        name,
        isPrivate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddPhases: async function (divisionIds, sectionIds, name, dueDate, startDate, stageGateId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-phases')
      .send({
        divisionIds,
        sectionIds,
        name,
        dueDate,
        startDate,
        stageGateId
      });

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true });
  },

  AddProjectAnnouncement: async function (projectId, sectionIds, title, message, repeatType, visibleTo) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-project-announcement')
      .send({
        projectId,
        sectionIds,
        title,
        message,
        repeatType,
        visibleTo
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddQuickNote: async function (projectId, note, itemId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-quick-note')
      .send({
        projectId,
        note,
        itemId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddSections: async function (divisionIds, area, title) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-sections')
      .send({
        divisionIds,
        area,
        title
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddSummarySheetColumn: async function (args) {

    const {
      projectId,
      commentModuleName,
      costQuantName,
      multiplierName,
      bidderId,
      showBidRisk,
      costQuantDisplayType,
      phaseName
    } = args;

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-summary-sheet-column')
      .send({
        projectId,
        commentModuleName,
        costQuantName,
        costQuantDisplayType,
        multiplierName,
        phaseName,
        bidderId,
        showBidRisk
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddThirdPartyUser: async function (projectId, userId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/add-third-party-user')
      .send({ projectId, userId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AlertBadData: async function (alertInfo) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/alert-bad-data')
      .send({
        alertInfo
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ApplyClientDataCrossReferences: async function (projectId, data) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/apply-linked-metadata')
      .send({
        projectId,
        data
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Archive: async function (id) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/archive')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ArchiveBid: async function (projectId, companyId) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/project/archive-bid')
      .send({
        projectId,
        companyId
      });

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true });
  },

  AssignDivisionUsers: async function (divisionId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/assign-division-users')
      .send({
        divisionId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AssignPhaseUsers: async function (phaseId, userIds, isApprover) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/assign-phase-users')
      .send({
        phaseId,
        userIds,
        isApprover
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AssignProjectUsers: async function (projectId, users, inviteToken = undefined) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/assign-project-users')
      .send({
        projectId,
        users,
        inviteToken
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AssignSectionUsers: async function (sectionId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/assign-section-users')
      .send({
        sectionId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AssignSimilarPhaseUsers: async function (phaseName, userIds, projectId, approver, specificDivisionId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/assign-similar-phase-users')
      .send({
        phaseName,
        userIds,
        projectId,
        approver,
        specificDivisionId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  CopyAreas: async function (projectId, areaTitleOrLabel, divisionIds, newAreaTitle, viewType) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/copy-areas')
      .send({
        projectId,
        areaTitleOrLabel,
        divisionIds,
        newAreaTitle,
        viewType
      });

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true });
  },

  CopySection: async function (fromSectionId, toDivisionIds, title) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/copy-section')
      .send({
        fromSectionId,
        toDivisionIds,
        title
      });

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true });
  },

  CostQuantModulePhaseData: async function (companyId, moduleId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/cost-quant-module-phase-data')
      .query({
        companyId,
        moduleId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Create: async function (
    name,
    description,
    address,
    city,
    state,
    postalCode,
    dueDate,
    visibility,
    importData,
    projectLabelIds,
    primaryCurrency,
    primaryCurrencySymbol,
    currencies
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/create')
      .send({
        name,
        description,
        address,
        city,
        state,
        postalCode,
        dueDate,
        visibility,
        importData,
        projectLabelIds,
        primaryCurrency,
        primaryCurrencySymbol,
        currencies
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  CreateWithTemplate: async function (
    name,
    description,
    address,
    city,
    state,
    postalCode,
    dueDate,
    visibility,
    template,
    templatePhasesDueDate,
    templateOmittedAreas,
    trackingId,
    importData,
    projectLabelIds,
    primaryCurrency,
    primaryCurrencySymbol,
    currencies
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/create-with-template')
      .send({
        name,
        description,
        address,
        city,
        state,
        postalCode,
        dueDate,
        visibility,
        template,
        templatePhasesDueDate,
        templateOmittedAreas,
        trackingId,
        importData,
        projectLabelIds,
        primaryCurrency,
        primaryCurrencySymbol,
        currencies
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Delete: async function (id) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/project/delete')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteQuickNote: async function (itemId) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/project/delete-quick-note')
      .send({ itemId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteLogo: async function (id, deleteLogo) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('deleteLogo', deleteLogo);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  DeletePhase: async function (divisionPhaseId, sectionPhaseId) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/project/delete-phase')
      .send({
        divisionPhaseId,
        sectionPhaseId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteProjectAnnouncement: async function (id) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/delete-project-announcement')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DownloadLogo: async function (projectId, noRedirect) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/download-logo')
      .query({ projectId, noRedirect });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DownloadJoinSpreadsheet: async function (projectId, expandedSections, filteredPhaseNames) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/download-join-spreadsheet')
      .responseType('blob')
      .query({
        projectId,
        expandedSections,
        filteredPhaseNames
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Edit: async function (
    id,
    name,
    description,
    address,
    city,
    state,
    postalCode,
    dueDate,
    companyUsersCanAssignEstimators
  ) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id);

    if (name !== undefined) {
      apiRequest.field('name', name);
    }

    if (description !== undefined) {
      apiRequest.field('description', description);
    }

    if (address !== undefined) {
      apiRequest.field('address', address);
    }

    if (city !== undefined) {
      apiRequest.field('city', city);
    }

    if (state !== undefined) {
      apiRequest.field('state', state);
    }

    if (postalCode !== undefined) {
      apiRequest.field('postalCode', postalCode);
    }

    if (dueDate !== undefined) {
      apiRequest.field('dueDate', dueDate);
    }

    if (dueDate !== undefined) {
      apiRequest.field('dueDate', dueDate);
    }

    if (companyUsersCanAssignEstimators !== undefined) {
      apiRequest.field('companyUsersCanAssignEstimators', companyUsersCanAssignEstimators);
    }

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditQuickNote: async function (itemId, content) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-quick-note')
      .send({
        itemId,
        content
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditColorScheme: async function (id, colorScheme) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('colorScheme', colorScheme);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditDirectCostOverride: async function (projectId, value) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-direct-cost-override')
      .send({ value, projectId });

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true });
  },

  EditModule: async function (id, name, isShown) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-module')
      .send({
        id,
        name,
        isShown
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditGrandTotalOverride: async function (id, grandTotalOverride) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('grandTotalOverride', grandTotalOverride ?? '');

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true, useJsonRequestType: false });
  },

  EditHideBidAmounts: async function (id, hideBidAmounts) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('hideBidAmounts', hideBidAmounts);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditPrepopulateSubUnits: async function (id, prepopulateSubUnits) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('prepopulateSubUnits', prepopulateSubUnits);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditIsStrictModeForBids: async function (id, isStrictMode) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('isStrictMode', isStrictMode);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditIsTemplate: async function (id, isTemplate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('isTemplate', isTemplate);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  EditMetadataCategory: async function (id, name, isPrivate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-metadata-category')
      .send({
        id,
        name,
        isPrivate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditPhase: async function (divisionPhaseId, sectionPhaseId, name, dueDate, startDate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-phase')
      .send({
        divisionPhaseId,
        sectionPhaseId,
        name,
        dueDate,
        startDate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditProjectAnnouncement: async function (id, sectionIds, title, message, repeatType, visibleTo) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-project-announcement')
      .send({
        id,
        sectionIds,
        title,
        message,
        repeatType,
        visibleTo
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditProjectDueDate: async function (id, newDueDate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-project-due-date')
      .send({
        id,
        newDueDate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },


  EditSimilarPhases: async function (projectId, searchName, name, dueDate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit-similar-phases')
      .send({
        projectId,
        searchName,
        name,
        dueDate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditVisibility: async function (id, visibility) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id)
      .field('visibility', visibility);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  },

  UpdateAndGetKpiData: async function (projectId, widgetDataChanges = {}) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/update-and-get-kpi-data')
      .send({ projectId, ...widgetDataChanges });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetBidDraftData: async function (projectId, sectionIds) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/bid/get-bid-draft-data')
      .query({
        projectId,
        sectionIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetBidAttachmentUrl: function (id, isThumbnail) {

    const thumbNailQuery = isThumbnail ? '&isThumbnail=true' : '';

    return `${process.env.REACT_APP_API_URL}/project/download-bid-attachment?id=${id}${thumbNailQuery}`;
  },

  GetBidAttachmentUrlNoRedirect: async function (id, isThumbnail) {

    const thumbNailQuery = isThumbnail ? '&isThumbnail=true' : '';

    const url = `${process.env.REACT_APP_API_URL}/project/download-bid-attachment?id=${id}${thumbNailQuery}`;
    return await RequestAttachmentUrl(url);
  },

  GetItemAttachmentUrl: function (id) {

    return `${process.env.REACT_APP_API_URL}/project/download-item-attachment?id=${id}`;
  },

  GetItemAttachmentUrlNoRedirect: async function (id) {

    const url = `${process.env.REACT_APP_API_URL}/project/download-item-attachment?id=${id}`;
    return await RequestAttachmentUrl(url);
  },

  GetChangeHistory: async function (type, id, sectionId, divisionId, projectId) {

    const apiRequest = request.get(process.env.REACT_APP_API_URL + '/project/get-change-history')
      .query({ type, id, sectionId, divisionId, projectId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSectionModuleInfo: async function (projectId, combineModuleData, limitToModuleTypes, limitToDivisionId) {

    const apiRequest = request.get(process.env.REACT_APP_API_URL + '/project/section-module-info')
      .query({
        projectId,
        combineModuleData,
        limitToDivisionId,
        limitToModuleTypes
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSpellCheck: async function (word) {

    const apiRequest = request.get(process.env.REACT_APP_API_URL + '/project/get-spell-check')
      .query({ word });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSummarySheetCollectionAttachmentUrl: function (id, collectionSnapshotUUID) {

    const uuid = (collectionSnapshotUUID) ? `&collectionSnapshotUUID=${collectionSnapshotUUID}` : '';
    return `${process.env.REACT_APP_API_URL}/project/download-summary-sheet-collection-attachment?id=${id}${uuid}`;
  },

  GetSummarySheetColumnsInfo: async function (projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/summary-sheet-columns-info')
      .query({
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSummarySheetPdf: async function ({
    projectId,
    pivotType,
    pivotModuleName,
    filteredAreas,
    showOnlyCompanyIds,
    filteredPhaseNames,
    filteredTagIds,
    filteredDivisionPhaseId,
    filteredSectionPhaseId,
    omitBuffs,
    outputFilteredPdfs,
    grandTotalOnly,
    trackerTotalsOnly,
    expandedDivisions,
    expandedSections,
    hiddenSections,
    hiddenItems,
    allowAdjacentPages,
    showComments,
    showSubcontractor,
    showPlugs,
    displayDate
  }) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/summary-sheet-pdf')
      .responseType('blob')
      .query({
        projectId,
        pivotType,
        pivotModuleName,
        filteredAreas,
        showOnlyCompanyIds,
        filteredPhaseNames,
        filteredTagIds,
        filteredDivisionPhaseId,
        filteredSectionPhaseId,
        omitBuffs,
        outputFilteredPdfs,
        grandTotalOnly,
        trackerTotalsOnly,
        expandedDivisions,
        expandedSections,
        hiddenSections,
        hiddenItems,
        allowAdjacentPages,
        showComments,
        showSubcontractor,
        showPlugs,
        displayDate
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSummarySheetReportRows: async function (companyId, projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/summary-sheet-report-rows')
      .query({
        companyId,
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetGeneratedPdfInfo: async function (id, noRedirect) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/download-generated-pdf')
      .query({ id, noRedirect });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  // queries is an object with same properties as the summary sheet request + a queryId property that uniquely
  // identifies each query
  GetMultipleSummarySheets: async function (queries) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/get-multiple-summary-sheets')
      .send({
        queries
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GeneratePivotableReport: async function (
    id,
    scope,
    includeTags,
    includeUnits,
    includeMetadata,
    includePlugs,
    showMultipliedTotals,
    activeOnly,
    outputCsv,
    outputExcel
  ) {

    const apiRequest = request.get(process.env.REACT_APP_API_URL + '/project/pivotable-report')
      .query({
        id,
        scope,
        includeTags,
        includeUnits,
        includeMetadata,
        includePlugs,
        showMultipliedTotals,
        activeOnly,
        outputCsv,
        outputExcel
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetPossibleThirdPartyUsers: async function (projectId, searchInput) {

    const apiRequest = request.get(process.env.REACT_APP_API_URL + '/project/get-possible-third-party-users')
      .query({ projectId, searchInput });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetProjectAttachmentUrl: function (id, isThumbnail) {

    const thumbNailQuery = isThumbnail ? '&isThumbnail=true' : '';

    return `${process.env.REACT_APP_API_URL}/project/download-project-attachment?id=${id}${thumbNailQuery}`;
  },

  GetProjectAttachmentUrlNoRedirect: async function (id, isThumbnail) {

    const thumbNailQuery = isThumbnail ? '&isThumbnail=true' : '';

    const url = `${process.env.REACT_APP_API_URL}/project/download-project-attachment?id=${id}${thumbNailQuery}`;
    return await RequestAttachmentUrl(url);
  },

  GenerateProjectBinderPdf: async function (
    projectId,
    divisionIds,
    sectionPhaseIds,
    useLandscape,
    allowAdjacentPages,
    noDirectDownload
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/generate-project-binder-pdf')
      .responseType('blob')
      .send({ projectId, divisionIds, sectionPhaseIds, useLandscape, allowAdjacentPages, noDirectDownload });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetProjectImageUrl: function (projectId) {

    return process.env.REACT_APP_API_URL + '/project/download-project-image?projectId=' + projectId;
  },

  GetReportingVariableChartData: async function (projectLabelIds, filteredDivisionNames) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/reporting-variable-chart-data')
      .query({
        projectLabelIds,
        filteredDivisionNames
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSectionAttachmentUrl: function (id, isThumbnail) {

    const thumbNailQuery = isThumbnail ? '&isThumbnail=true' : '';

    return `${process.env.REACT_APP_API_URL}/project/download-section-attachment?id=${id}${thumbNailQuery}`;
  },

  GetSectionAttachmentUrlNoRedirect: async function (id, isThumbnail) {

    const thumbNailQuery = isThumbnail ? '&isThumbnail=true' : '';

    const url = `${process.env.REACT_APP_API_URL}/project/download-section-attachment?id=${id}${thumbNailQuery}`;
    return await RequestAttachmentUrl(url);
  },

  GetSummarySheet: async function (args) {

    const {
      bidSpecificPhaseId,
      projectId,
      pivotType,
      pivotModuleName,
      filteredAreas,
      showOnlyCompanyIds,
      filteredPhaseNames,
      filteredTagIds,
      filteredDivisionPhaseId,
      filteredSectionPhaseId,
      omitBuffs,
      outputFilteredPdfs,
      grandTotalOnly,
      trackerTotalsOnly,
      immediateResponseRequired
    } = args;

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/summary-sheet')
      .query({
        bidSpecificPhaseId,
        projectId,
        pivotType,
        pivotModuleName,
        filteredAreas,
        showOnlyCompanyIds,
        filteredPhaseNames,
        filteredTagIds,
        filteredDivisionPhaseId,
        filteredSectionPhaseId,
        omitBuffs,
        outputFilteredPdfs,
        grandTotalOnly,
        trackerTotalsOnly,
        immediateResponseRequired
      });

    return await apiCommon.ProcessRequest(apiRequest, { affectsGrandTotal: true });
  },

  GetSectionBuffTotalsForSpecificBids: async function (bidIds) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/section-buffs-for-specific-bids')
      .query({
        bidIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetSectionBuffs: async function (sectionIds) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/section-buffs')
      .query({
        sectionIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  InviteThirdPartyUser: async function (data) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/invite-third-party-user')
      .send(
        data
      );

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetLinkedMetadata: async (projectId) => {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/linked-metadata')
      .query({
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  MarkProjectAnnouncementsRead: async function (projectAnnouncementIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/mark-project-announcements-read')
      .send({
        projectAnnouncementIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  NotifyAwardedBidder: async function (bidId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/bid/notify-awarded-vendor')
      .send({
        bidId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  PromoteLineItem: async function (id, newOwnerId) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/promote-line-item')
      .send({
        id,
        newOwnerId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveDivisionUsers: async function (divisionId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/remove-division-users')
      .send({
        divisionId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveLinkedMetadata: async function (projectId) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/project/remove-linked-metadata')
      .send({
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveMetadataCategory: async function (id) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/remove-metadata-category')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemovePhaseUsers: async function (phaseId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/remove-phase-users')
      .send({
        phaseId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveSimilarPhaseUsers: async function (phaseName, userIds, projectId, removeApproverOnly) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/remove-similar-phase-users')
      .send({
        phaseName,
        userIds,
        projectId,
        removeApproverOnly
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },


  RemoveSectionUsers: async function (sectionId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/remove-section-users')
      .send({
        sectionId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveSummarySheetColumn: async function (id) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/project/remove-summary-sheet-column')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveThirdPartyUser: async function (projectId, userId) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/project/remove-third-party-user')
      .send({
        projectId,
        userId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ReorderDivision: async function (id, newIndex) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/reorder-division')
      .send({
        id,
        newIndex
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ReorderLineItem: async function (id, newIndex) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/reorder-line-item')
      .send({
        id,
        newIndex
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ReorderSection: async function (id, toDivisionId, newIndex) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/reorder-section')
      .send({
        id,
        toDivisionId,
        newIndex
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Search: async function (id, searchTerm) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/search')
      .query({
        id,
        searchTerm
      });

    return await apiCommon.ProcessRequest(apiRequest, { skipSetIsLoading: true });
  },

  GetAccountingModuleAutocompleteOptions: async function (moduleDataId, searchTerm) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/accounting-module-autocomplete-options')
      .query({
        moduleDataId,
        searchTerm
      });

    return await apiCommon.ProcessRequest(apiRequest, { skipSetIsLoading: true });
  },

  SearchCompanies: async function (search, ignoreIds, skipSetIsLoading, maxResults) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/search-companies')
      .query({ ignoreIds, maxResults, search });

    return await apiCommon.ProcessRequest(apiRequest, { skipSetIsLoading });
  },

  SearchCompaniesWithoutBids: async function (sectionId, search, skipSetIsLoading, maxResults) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/search-companies-without-bids')
      .query({
        maxResults,
        search,
        sectionId
      });

    return await apiCommon.ProcessRequest(apiRequest, { skipSetIsLoading });
  },

  SectionModuleNames: async function (id) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/section-module-names')
      .query({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  SectionModulesInfo: async function (projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/section-modules-info')
      .query({
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  SendQueuedInvite: async function (bidId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/send-queued-invite')
      .send({
        bidId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  SuggestSubcontractors: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/suggest-subcontractors');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ToggleInvitesLocked: async function (projectId, locked) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/toggle-invites-locked')
      .send({
        projectId,
        locked
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ToggleSendInvitesByDefault: async function (projectId, sendByDefault) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/toggle-send-invites-by-default')
      .send({
        projectId,
        sendByDefault
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UnArchiveProject: async function (projectId) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/un-archive')
      .send({ projectId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UnassignProjectUsers: async function (projectId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/unassign-project-users')
      .send({
        projectId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UnassignUsersFromAllProjectItems: async function (projectId, userIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/unassign-users-from-all-project-items')
      .send({
        projectId,
        userIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UpdateAreaLabels: async function (sectionIds, labelName) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/update-area-labels')
      .send({
        sectionIds,
        labelName
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UpdateIncludedReportRows: async function (lineItems, sections, isIncluded) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/update-included-report-rows')
      .send({
        lineItems,
        sections,
        isIncluded
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  // TODO: [CA] Confirm this is no longer needed, then remove
  UploadBidAttachment: async function (
    bidId,
    itemId,
    originalAttachmentId,
    attachmentType,
    displayFilename,
    folders,
    file,
    parentProjectAttachmentId,
    parentSectionAttachmentId,
    parentBidAttachmentId,
    isPrivate = false,
    pdfAnnotationPageNum,
    onUploadProgress
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/upload-bid-attachment')
      .field('bidId', bidId)
      .field('attachmentType', attachmentType)
      .field('displayFilename', displayFilename)
      .field('isPrivate', isPrivate);

    if (itemId) {
      apiRequest.field('itemId', itemId);
    }

    if (originalAttachmentId) {
      apiRequest.field('originalAttachmentId', originalAttachmentId);
    }

    if (parentProjectAttachmentId) {
      apiRequest.field('parentProjectAttachmentId', parentProjectAttachmentId);
    }

    if (parentSectionAttachmentId) {
      apiRequest.field('parentSectionAttachmentId', parentSectionAttachmentId);
    }

    if (parentBidAttachmentId) {
      apiRequest.field('parentBidAttachmentId', parentBidAttachmentId);
    }

    if (pdfAnnotationPageNum) {
      apiRequest.field('pdfAnnotationPageNum', pdfAnnotationPageNum);
    }

    if (folders) {
      apiRequest.field('folders', folders);
    }

    apiRequest.attach('attachment', file);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false, onProgress: onUploadProgress });
  },

  UploadProjectLogo: async function (id, logoImage) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/project/edit')
      .field('id', id);

    apiRequest.attach('logoImage', logoImage);

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false });
  }

};
