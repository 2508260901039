import React, { useCallback, useState, useMemo, Fragment, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import api from '../../api';
import { ProjectApiSlice } from '../../api/project';
import { updateProjectImportInProgress } from '../../redux/project/projectSlice';

import _ from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { EmptyFunction } from '../../consts/EmptyFunction';


const useStyles = makeStyles((theme) => ({
  bidCompany: {
    margin: theme.spacing(1)
  },
  circularProgress: {
    marginTop: theme.spacing(2)
  },
  companyNotFound: {
    marginLeft: '4px',
    color: theme.palette.app.errorText
  },
  dialogTitle: {
    padding: '30px',
    position: 'relative'
  },
  dataContainer: {
    paddingTop: '15px',
    backgroundColor: theme.palette.app.background,
    padding: '10px',
    borderRadius: '6px',
    position: 'relative'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'right'
  },
  dialogContent: {
    padding: '8px 24px'
  },
  divisionTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    padding: '4px',

    '&.matchedData': {
      outline: '2px solid ' + theme.palette.keyLegend.matchedData
    },
    '&.newData': {
      outline: '2px solid ' + theme.palette.keyLegend.newData
    }
  },
  finalCostsBtn: {
    marginBottom: theme.spacing(2),
    margin: '0 auto',
    display: 'block'
  },
  importButton: {
    marginTop: theme.spacing(2),
    display: 'block'
  },
  innerContainer: {
    background: theme.palette.table.light,
    padding: '18px',
    margin: '12px',
    border: '1px solid ' + theme.palette.app.shadowDark,
    borderRadius: '5px',
    boxShadow: '1px 1px 3px ' + theme.palette.app.shadowDark,
    position: 'relative',

    '&.omittedDivision': {
      filter: 'grayscale(100%)'
    }
  },
  importSpinner: {
    marginTop: theme.spacing(2)
  },
  keyLegend: {
    border: '1px solid lightgrey',
    borderRadius: '4px',
    padding: '12px',
    position: 'absolute',
    right: '100px',
    top: '10px'
  },
  lineItem: {
    fontWeight: 600,
    fontSize: '.8rem',
    marginLeft: '40px',
    width: 'max-content',
    maxWidth: 'calc(100% - 40px)',
    margin: '8px',
    padding: '4px',

    '&.matchedData': {
      outline: '2px solid ' + theme.palette.keyLegend.matchedData
    },
    '&.newData': {
      outline: '2px solid ' + theme.palette.keyLegend.newData
    }
  },
  metadataCategory: {
    fontWeight: 600,
    fontSize: '.8rem',
    width: 'max-content',
    maxWidth: 'calc(100% - 40px)',
    margin: '8px',
    padding: '4px',

    '&.newData': {
      outline: '2px solid ' + theme.palette.keyLegend.newData
    }
  },
  legendItem: {
    fontSize: '.8rem',
    color: theme.palette.primary.textColorMedium
  },
  legendItemWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  legendColor: {
    width: '10px',
    height: '10px',
    marginRight: '8px',

    '&.newData': {
      background: theme.palette.keyLegend.newData
    },
    '&.matchedData': {
      background: theme.palette.keyLegend.matchedData
    }
  },
  modal: {
    maxHeight: '93vh'
  },
  mergingDataProgress: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  replaceBidButton: {
    backgroundColor: theme.palette.bidStatus.queuedInvite.button
  },
  root: {
    backgroundColor: theme.palette.table.dark,
    borderRadius: '8px',
    padding: '12px',
    marginTop: theme.spacing(2),
    border: '1px solid lightgrey'
  },
  sectionTitle: {
    fontSize: '.9rem',
    fontWeight: 600,
    padding: '4px',

    '&.matchedData': {
      outline: '2px solid ' + theme.palette.keyLegend.matchedData
    },
    '&.newData': {
      outline: '2px solid ' + theme.palette.keyLegend.newData
    }
  },
  snackbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    zIndex: 10050
  },
  savedChanges: {
    color: theme.palette.labelDisplay.selected,
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px'
  },
  selectCompanyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const removeEscapeCharacters = (string) => string?.replace(/[\n\t\b\f\r\v]/g, '');

const parsePotentialProjectVariable = (cellValue) => {

  let value = cellValue;
  try {
    const parsedValue = JSON.parse(cellValue);
    if (parsedValue?.name && parsedValue?.value) {
      value = parsedValue.value;
    }
  }
  catch (e) {
    // DO nothing
  }

  return value;
};

const dummyObj = {};

const ImportDataDialog = (props) => {

  const classes = useStyles();
  const {
    cust,
    importType,
    onConfirmImportData,
    projectDetails = dummyObj,
    shouldShowImport = true,
    selectedTemplateId,
    shouldRenderDialogOnly,
    providedImportData, // If shouldRenderDialogOnly is true, we should be providing import data directly through props.
    onCloseDialog = EmptyFunction,
    isXMLImport = false
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [importData, setImportData] = useState(null);
  const [importedCompanies, setImportedCompanies] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showMarkAreasFinalCosts, setShowMarkAreasFinalCosts] = useState(false);
  const [clickedBidId, setClickedBidId] = useState(null);
  const [foundCompanies, setFoundCompanies] = useState(null);
  const [isSearchingForValidCompanies, setIsSearchingForValidCompanies] = useState(false);
  const [selectedSearchedCompany, setSelectedSearchedCompany] = useState('');
  const [shouldDisableOwnerSelections, setShouldDisableOwnerSelections] = useState(false);
  const [hasSavedChanges, setHasSavedChanges] = useState(false);
  const [omittedDivisionIds, setOmittedDivisionIds] = useState(null);

  const loggedInUserDetails = useSelector((state) => state.ephemeralState.loggedInUserDetails);

  const baseImportDataRef = useRef(null);
  const selectedTemplateRef = useRef(null);

  const [getDetails] = ProjectApiSlice.useLazyGetDetailsQuery();

  const attemptToMatchData = useCallback((responseImportData, projectHierarchy) => {

    if (_.isEmpty(projectHierarchy) || !responseImportData?.divisions?.length) {
      return;
    }

    // Using a copy here because we mutate this object in this function and we could potentially use the projectHierarchy
    // object for something else in the future
    const projectHierarchyClone = _.cloneDeep(projectHierarchy);

    for (const division of responseImportData.divisions) {
      const divisionTitle = division.divisionTitle.toLowerCase();
      const foundSimilarDivision = _.find(projectHierarchyClone.divisions, (d) => d.name.toLowerCase() === divisionTitle);
      if (foundSimilarDivision) {
        division.isMatch = true;
        for (const section of division.sections) {
          const sectionTitle = section.sectionTitle.toLowerCase();
          const foundSimilarSection = _.find(foundSimilarDivision.sections, (s) => s.title.toLowerCase() === sectionTitle);
          if (foundSimilarSection) {
            section.isMatch = true;
            for (const item of section.lineItems) {
              const itemTitle = removeEscapeCharacters(parsePotentialProjectVariable(item.itemTitle));
              const foundSimilarItemIndex = _.findIndex(foundSimilarSection.items, (i) => removeEscapeCharacters(i.title.toLowerCase()) === itemTitle.toLowerCase());
              // Since there can be duplicate section items, once we find a match for one, we need to remove it from the comparing data.
              // Otherwise one single matching section item will return a true match for all imported section items with the same name
              if (foundSimilarItemIndex !== -1) {
                foundSimilarSection.items.splice(foundSimilarItemIndex, 1);
                item.isMatch = true;
              }
            }
          }
        }
      }
    }
  }, []);

  useEffect(() => {

    if (isXMLImport && providedImportData && !importData) {

      const providedImportDataClone = _.cloneDeep(providedImportData);
      getDetails({
        id: projectDetails.id,
        retainStructureIfTemplate: false,
        includeHierarchy: true
      }).then((hierarchyResponse) => {

        if (hierarchyResponse.data) {
          const projectHierarchy = hierarchyResponse.data;
          attemptToMatchData(providedImportDataClone, projectHierarchy);
          setImportData(providedImportDataClone);
          setIsOpen(true);
        }
      });
    }
  }, [attemptToMatchData, getDetails, providedImportData, isXMLImport, importData, projectDetails.id]);

  useEffect(() => {

    if (importData?.divisions?.length && !baseImportDataRef.current) {
      baseImportDataRef.current = importData;
    }
  }, [importData]);

  useEffect(() => {

    if (importData && omittedDivisionIds === null) {
      setOmittedDivisionIds(new Set(importData.divisions.filter((d) => !d.sections.length).map((d) => d.tempId)));
    }

    if (!isOpen) {
      setOmittedDivisionIds(null);
    }
  }, [importData, isOpen, omittedDivisionIds]);

  useEffect(() => {

    // This is used to essentially refresh state once a new template option is selected
    if (selectedTemplateId) {
      selectedTemplateRef.current = selectedTemplateId;
    }

    if (selectedTemplateRef.current) {
      setHasSavedChanges(false);
      setImportedCompanies(null);
      setImportData(null);
    }
  }, [selectedTemplateId]);

  const handleCloseDialog = useCallback(() => {

    setIsOpen(false);
    setShouldDisableOwnerSelections(false);
    setImportedCompanies(null);

    if (isXMLImport) {
      setImportData(null);
    }

    onCloseDialog();
  }, [isXMLImport, onCloseDialog]);

  const getImportData = useCallback(async (_event, optionalProjectOwnerCompany = undefined) => {

    if (hasSavedChanges) {
      setIsOpen(true);
      return;
    }

    const importDataResponse = await api.DataImport.GetImportData(loggedInUserDetails.primaryCompanyId, optionalProjectOwnerCompany);
    if (importDataResponse.res) {
      const responseImportData = importDataResponse.res.body.importData;
      const responseImportedCompanies = importDataResponse.res.body.allImportedCompanies;

      if (importType === api.DataImport.ImportTypes.ExistingProject || importType === api.DataImport.ImportTypes.TemplatedProject) {
        const projectIdToUse = selectedTemplateId || projectDetails.id;
        const hierarchyResponse = await getDetails({
          id: projectIdToUse,
          retainStructureIfTemplate: false,
          includeHierarchy: true
        });
        if (hierarchyResponse.data) {
          const projectHierarchy = hierarchyResponse.data;
          attemptToMatchData(responseImportData, projectHierarchy);
        }
      }

      setImportedCompanies(responseImportedCompanies);
      setImportData(responseImportData);
      setIsOpen(true);
      return responseImportData;
    }
    else if (importDataResponse.err) {
      handleCloseDialog();
      enqueueSnackbar(
        'Server Error: could not get import data',
        { className: classes.snackbar }
      );
    }
  }, [
    attemptToMatchData,
    classes,
    enqueueSnackbar,
    getDetails,
    handleCloseDialog,
    hasSavedChanges,
    loggedInUserDetails.primaryCompanyId,
    projectDetails,
    importType,
    selectedTemplateId
  ]);

  const afterSearchForValidCompanies = useCallback((result) => {

    setIsSearchingForValidCompanies(false);
    if (result.err || !_.isArray(result.res.body)) {
      // TODO: show error?
      return;
    }

    setFoundCompanies(result.res.body);
  }, []);

  const handleSearchCompanies = useCallback((searchValue) => {

    if (searchValue.length < 3) {
      return;
    }

    setIsSearchingForValidCompanies(true);
    api.Project.SearchCompanies(searchValue, [loggedInUserDetails.primaryCompanyId], true)
      .then(afterSearchForValidCompanies);
  }, [afterSearchForValidCompanies, loggedInUserDetails.primaryCompanyId]);

  const searchCompaniesInputChange = useCallback((event, newValue) => {

    if (newValue.length >= 3) {
      handleSearchCompanies(newValue);
    }
    else {
      setFoundCompanies(null);
    }
  }, [handleSearchCompanies]);

  const handleAssignFinalCostSection = useCallback((divisionId, sectionId) => {

    const currentImportData = _.cloneDeep(importData);
    const isValidIndex = (index) => index !== -1;

    const foundDivisionIndex = _.findIndex(currentImportData.divisions, { tempId: divisionId });
    if (isValidIndex(foundDivisionIndex)) {
      const foundSectionIndex = _.findIndex(currentImportData.divisions[foundDivisionIndex].sections, { tempId: sectionId });
      if (isValidIndex(foundSectionIndex)) {
        const section = currentImportData.divisions[foundDivisionIndex].sections[foundSectionIndex];
        if (section.isFinalCosts) {
          delete section.isFinalCosts;
          // Re assign the base import data bids for this section if final costs is unchecked
          const baseImportDataSection = baseImportDataRef.current.divisions[foundDivisionIndex].sections[foundSectionIndex];
          section.bids = baseImportDataSection.bids;
        }
        else {
          section.isFinalCosts = true;
          // Remove bids here since final costs can not have bids
          section.bids = [];
        }
      }
    }

    setImportData(currentImportData);
  }, [importData, baseImportDataRef]);

  const handleBidClicked = useCallback((event) => {

    const bidId = event.currentTarget.dataset.bidid;
    if (bidId) {
      setClickedBidId(bidId);
    }
  }, []);

  const handleConfirm = useCallback(() => {

    const importDataToSend = _.cloneDeep(importData);
    if (omittedDivisionIds?.size) {
      importDataToSend.divisions = importDataToSend.divisions.filter((d) => !omittedDivisionIds.has(d.tempId));
    }

    if (importType === api.DataImport.ImportTypes.NewProject || importType === api.DataImport.ImportTypes.TemplatedProject) {
      onConfirmImportData(importDataToSend);
      setHasSavedChanges(true);
    }
    else if (importType === api.DataImport.ImportTypes.ExistingProject) {

      dispatch(updateProjectImportInProgress(true));
      api.DataImport.MergeImportData(projectDetails.id, importDataToSend, isXMLImport)
        .then((response) => {

          if (response.err) {
            dispatch(updateProjectImportInProgress(false));
          }
        });
    }

    handleCloseDialog();
  }, [
    dispatch,
    onConfirmImportData,
    omittedDivisionIds,
    projectDetails.id,
    importData,
    importType,
    isXMLImport,
    handleCloseDialog
  ]);

  const handlePrimaryCompanySelected = useCallback(async (e) => {

    setShouldDisableOwnerSelections(true);
    const selectedPrimaryCompany = e.currentTarget.dataset.company;
    if (selectedPrimaryCompany) {
      const newImportData = await getImportData(undefined, selectedPrimaryCompany);
      setImportData(newImportData);
      setShouldDisableOwnerSelections(false);
    }
  }, [getImportData]);

  const handleToggleOmitDivision = useCallback((evt) => {

    const divisionId = evt.target.name;
    const isCurrentlyOmitted = evt.target.checked;

    setOmittedDivisionIds((prev) => {

      const newOmittedIds = new Set([...prev]);
      (isCurrentlyOmitted) ? newOmittedIds.delete(divisionId) : newOmittedIds.add(divisionId);
      if (!newOmittedIds.size) {
        return prev;
      }

      return newOmittedIds;
    });
  }, []);

  const getMainContent = useCallback(() => {

    const shouldRenderCompanySelection = Boolean(!importData?.divisions?.length && importedCompanies?.length);
    let content;

    if (shouldRenderCompanySelection) {
      const companyUI = importedCompanies.sort().map((company, index) =>

        <ListItem
          data-cy="import-company"
          key={index}
          button
          disabled={shouldDisableOwnerSelections}
          onClick={handlePrimaryCompanySelected}
          data-company={company}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={company.toUpperCase()} />
        </ListItem>
      );

      content = (
        <div className={classes.selectCompanyWrapper}>
          <Typography variant="h6" color="textPrimary">Select which company is associated with the estimate</Typography>
          <div className={classes.root}>
            <List>
              {companyUI}
            </List>
          </div>
        </div>
      );
    }
    else if (importData.divisions.length) {

      let importDataMetadataUI = null;
      if (!_.isEmpty(importData.projectMetadata)) {
        importDataMetadataUI = (
          <div className={classes.innerContainer}>
            <span className={classes.divisionTitle}>
              Project Metadata
            </span>
            <div className={classes.innerContainer}>
              {Object.entries(importData.projectMetadata).map(([category, metadataValue]) => {

                return (
                  <div key={category} className={classes.metadataCategory + ' newData'}>
                    {category} - {metadataValue}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      const importDataDivisionsUI = importData.divisions.map((division) => {

        const extraDivisionClass = (division.isMatch) ? ' matchedData' : ' newData';
        const isDivisionIncluded = (!omittedDivisionIds?.has(division.tempId));
        const omitDivisionTooltipTitle =
          `This ${cust.division} will be ${(isDivisionIncluded) ? 'included' : 'excluded'}.  ` +
          `Click to ${(isDivisionIncluded) ? 'exclude' : 'include'}.`;

        return (
          <div
            className={classes.innerContainer + ((isDivisionIncluded) ? '' : ' omittedDivision')}
            key={division.tempId}
            data-cy="import-data.division"
          >
            <Tooltip title={omitDivisionTooltipTitle} placement="top" arrow>
              <Switch
                checked={isDivisionIncluded}
                onChange={handleToggleOmitDivision}
                name={division.tempId}
                inputProps={{ 'data-cy': `import-data.division.omit.toggle.${isDivisionIncluded}` }}
              />
            </Tooltip>
            <span
              className={classes.divisionTitle + extraDivisionClass}
              data-cy="import-data.division.title"
            >
              {`${division.divisionLabel} - ${division.divisionTitle}`}
            </span>
            <div>
              {division.sections.map((section, sectionIdx) => {

                const extraSectionClass = (section.isMatch) ? ' matchedData' : ' newData';
                const bidsLabel = (section.bids.length) ? <Typography variant='body1'>Bids</Typography> : null;
                const labelNumber = _.padStart((sectionIdx + 1).toString(), 2, '0');
                const sectionTitle = section.sectionTitle || `Untitled ${cust.area}`;
                const sectionLabel = section.sectionLabel || `Unlabeled ${cust.area}`;
                const checkboxUI = (showMarkAreasFinalCosts && !division.isFinalCosts) ?
                  <Checkbox
                    data-cy="import-checkbox"
                    size="small"
                    checked={Boolean(section.isFinalCosts)}
                    onChange={() => handleAssignFinalCostSection(division.tempId, section.tempId)}
                  /> : null;

                return (
                  <div key={section.tempId} className={classes.innerContainer} data-cy="import-data.section">
                    {checkboxUI}
                    <span
                      className={classes.sectionTitle + extraSectionClass}
                      data-cy="import-data.section.title"
                    >
                      {labelNumber} - {sectionLabel} - {sectionTitle}
                    </span>
                    {section.lineItems.map((lineItem, itemIdx) => {

                      const extraItemClass = (lineItem.isMatch) ? ' matchedData' : ' newData';
                      const itemTitle = parsePotentialProjectVariable(lineItem.itemTitle) || `Untitled Item`;
                      return (<div key={itemIdx} className={classes.lineItem + extraItemClass} data-cy="import-data.item.title">- {itemTitle}</div>);
                    })}

                    {bidsLabel}
                    {section.bids.map((bid) => {

                      return (
                        <Button
                          variant="contained"
                          color="primary"
                          data-bidid={bid.tempId}
                          data-companyid={bid.tempId}
                          onClick={handleBidClicked}
                          className={classes.bidCompany}
                          key={bid.tempId}
                          data-cy="import-data.bid"
                        >
                          {bid.companyName}
                          {(!bid.companyId) ? <span className={classes.companyNotFound}>(Company Not Found)</span> : null}
                        </Button>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      });

      content = (
        <div className={classes.dataContainer} data-cy="import-data.container">
          <Button
            data-cy="import-data.mark-final-costs"
            onClick={() => setShowMarkAreasFinalCosts(true)}
            className={classes.finalCostsBtn}
            disabled={showMarkAreasFinalCosts}
            variant="contained"
            color='secondary'
          >
            Mark some areas as final costs
          </Button>
          {importDataMetadataUI}
          {importDataDivisionsUI}
        </div>
      );
    }
    else {
      content = (<Typography align='center' variant='h6'>No import data available</Typography>);
    }

    return content;
  }, [
    classes,
    cust,
    handleAssignFinalCostSection,
    handleBidClicked,
    handlePrimaryCompanySelected,
    handleToggleOmitDivision,
    importData,
    importedCompanies,
    omittedDivisionIds,
    showMarkAreasFinalCosts,
    shouldDisableOwnerSelections
  ]);

  const renderDialog = useMemo(() => {

    if (!isOpen || !importData) {
      return null;
    }

    const keyLegend = (importData.divisions.length) ? (
      <div className={classes.keyLegend} data-cy="import-data.legend">
        <div className={classes.legendItemWrapper}>
          <div className={classes.legendColor + ' newData'}/><p className={classes.legendItem}>New Data</p>
        </div>
        <div className={classes.legendItemWrapper}>
          <div className={classes.legendColor + ' matchedData'}/><p className={classes.legendItem}>Matched Data</p>
        </div>
      </div>
    ) : null;

    const confirmButton = (importData.divisions.length) ?
      (<Button data-cy="import.confirm" color="secondary" variant="contained" onClick={handleConfirm} autoFocus>Confirm</Button>) : null;

    return (
      <Dialog
        className={classes.modal}
        open={isOpen}
        onClose={handleCloseDialog}
        disableEnforceFocus={true}
        fullWidth
        maxWidth='xl'
        data-cy='import-data.dialog'
      >
        <DialogTitle className={classes.dialogTitle}>
          Import Details
          {keyLegend}
        </DialogTitle>
        <DialogContent>
          {getMainContent()}
        </DialogContent>
        <DialogActions>
          <Button data-cy="import.cancel" color='secondary' onClick={handleCloseDialog}>Cancel</Button>
          {confirmButton}
        </DialogActions>
      </Dialog>
    );
  }, [classes, getMainContent, handleConfirm, isOpen, importData, handleCloseDialog]);

  const confirmSelectedCompany = useCallback((e) => {

    if (!selectedSearchedCompany) {
      return;
    }

    let relevantCompanyName = '';
    const selectionType = e.currentTarget.dataset.selection;
    const clonedImportData = _.cloneDeep(importData);

    for (const division of clonedImportData.divisions) {
      for (const section of division.sections) {
        const foundReplacementBidIndex = _.findIndex(section.bids, { tempId: clickedBidId });
        if (foundReplacementBidIndex !== -1) {
          relevantCompanyName = section.bids[foundReplacementBidIndex].companyName;
          const bid = section.bids[foundReplacementBidIndex];
          bid.companyName = selectedSearchedCompany.split('_')[0];
          bid.companyId = parseInt(selectedSearchedCompany.split('_')[1]);
          break;
        }
      }
    }

    if (relevantCompanyName && selectionType === 'multiple') {
      // If the 'multiple' option was selected, we need to find all bids with the same relevantCompanyName
      // and replace all with the new company
      for (const division of clonedImportData.divisions) {
        for (const section of division.sections) {
          const foundReplacementBidIndex = _.findIndex(section.bids, { companyName: relevantCompanyName });
          if (foundReplacementBidIndex !== -1) {
            const bid = section.bids[foundReplacementBidIndex];
            bid.companyName = selectedSearchedCompany.split('_')[0];
            bid.companyId = parseInt(selectedSearchedCompany.split('_')[1]);
          }
        }
      }
    }

    setImportData(clonedImportData);
    setClickedBidId(null);
    setSelectedSearchedCompany('');
  }, [clickedBidId, importData, selectedSearchedCompany]);

  const handleCompanySelected = useCallback((event, selectedValue) => setSelectedSearchedCompany(selectedValue), []);

  const handleCancelSearch = useCallback(() => {

    setClickedBidId(null);
    setSelectedSearchedCompany('');
    setFoundCompanies(null);
  }, []);

  const searchCompanyDialog = useMemo(() => {

    const getCompanyName = (string) => string?.split('_')[0];

    return (
      <Dialog open={Boolean(clickedBidId)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Search Companies</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Search for subcontractors by name or email address.
          </DialogContentText>
          <Autocomplete
            id="auto-highlight"
            noOptionsText="No matching companies"
            autoHighlight
            value={_.defaultTo(getCompanyName(selectedSearchedCompany), '')}
            getOptionSelected={(option, value) => getCompanyName(option) === value || value === ''}
            onInputChange={searchCompaniesInputChange}
            onChange={handleCompanySelected}
            options={(foundCompanies || []).map((company) => `${company.companyName}_${company.companyId}`)}
            renderOption={(option) => {

              const companyName = getCompanyName(option);
              return <div data-cy="import-data.searched-company">{companyName}</div>;
            }}
            renderInput={(params) => (

              <TextField
                {...params}
                label="Name or Email"
                variant="standard"
                data-cy="import-data.search-company"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isSearchingForValidCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  )
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCancelSearch} color="secondary">
            Cancel
          </Button>
          <Button
            data-cy="import-data.replace-all"
            onClick={confirmSelectedCompany}
            data-selection="multiple"
            color="secondary"
            variant="contained"
            className={classes.replaceBidButton}
          >
            Replace All Similar
          </Button>
          <Button
            data-cy="import-data.replace"
            onClick={confirmSelectedCompany}
            color="secondary"
            variant="contained"
            className={classes.replaceBidButton}
          >
            Replace
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [
    clickedBidId,
    confirmSelectedCompany,
    classes,
    foundCompanies,
    handleCancelSearch,
    handleCompanySelected,
    isSearchingForValidCompanies,
    selectedSearchedCompany,
    searchCompaniesInputChange
  ]);

  return useMemo(() => {

    if (!shouldShowImport) {
      return null;
    }

    if (shouldRenderDialogOnly) {
      return renderDialog;
    }

    return (
      <Fragment>
        <Button
          data-cy="import-data-button"
          className={classes.importButton}
          color='secondary'
          variant='contained'
          onClick={getImportData}
        >
          Import Data
        </Button>
        {hasSavedChanges ? <div className={classes.savedChanges} data-cy="import.saved-text"><SaveAltIcon/>Saved Changes.</div> : null}
        {renderDialog}
        {searchCompanyDialog}
      </Fragment>
    );
  }, [
    classes,
    getImportData,
    hasSavedChanges,
    searchCompanyDialog,
    renderDialog,
    shouldRenderDialogOnly,
    shouldShowImport
  ]);
};

export default ImportDataDialog;
