export const CurrencySymbolMaxLength = 5;
export const ProjectNameMaxLength = 100;
export const ProjectDescriptionMaxLength = 1000;
export const ProjectAddressMaxLength = 150;
export const ProjectCityMaxLength = 100;
export const ProjectStateMaxLength = 100;
export const ProjectPostalCodeMaxLength = 15;
export const ProjectAnnouncementTitleMaxLength = 200;
export const ProjectAnnouncementMaxLength = 5000;
export const QuickNoteMaxLength = 1024;
export const SectionItemProjectVariableIdFields = ['title_projectVariableId'];
export const DivisionListWidth = 270;
export const PhaseStepWidth = 232;
export const PhaseMatrixDroppableContainerHeight = 50;
export const ContractNameMaxLength = 50;
export const ContractDescriptionMaxLength = 2000;
export const CommentWidgetMaxTitleLength = 50;
export const CommentWidgetMaxCommentLength = 2000;

// This doesn't include the 'subcontractor_' variable ids as those are never visible to the front end
export const ResponseProjectVariableIdFields = [
  'cost_projectVariableId',
  'quantity_projectVariableId',
  'time_projectVariableId',
  'costActual_projectVariableId',
  'quantityActual_projectVariableId'
];
export const ProjectFileBrowserIgnoreAttachmentTypes = new Set();
export const ProjectFileBrowserImageAttachmentTypes = new Set(['TakeoffMarkup']);
export const RadarTickAvgCharWidth = 7.5;
export const DivisionHotkeyType = 'D';
export const SectionHotkeyType = 'S';
export const BidHotkeyType = 'B';
export const SectionItemHotkeyType = 'I';
export const CellHotkeyType = 'C';
export const HotkeyTypeChildMap = {
  D: 'S',
  S: 'B',
  B: 'I',
  I: 'C'
};

export const UserManagementContainers = {
  Division: 'DIVISION',
  Phase: 'PHASE',
  Section: 'SECTION',
  SimilarPhase: 'SIMILAR_PHASE',
  SimilarPhaseApproval: 'SIMILAR_PHASE_APPROVAL'
};
