import { eachWeekOfInterval, eachDayOfInterval, format, parseISO, startOfMonth, endOfMonth, endOfWeek } from 'date-fns';
import { WBSValue, WbsValueType } from './types';
import { MonthlyCosts } from '../WBSLineChart';


export type TimeInterval = 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year';

const getMonthlyData = (monthlyData: Record<string, number>): [string[], number[]] => {

  const monthlyLabels: string[] = Object.keys(monthlyData);
  const labels: string[] = [];
  const data: number[] = [];

  for (const label of monthlyLabels) {
    labels.push(label.slice(0, 7));
    data.push(monthlyData[label]);
  }

  return [labels, data];
};

const getQuarterlyData = (monthlyData: Record<string, number>): [string[], number[]] => {

  const monthlyLabels: string[] = Object.keys(monthlyData);
  const labels: string[] = [];
  const data: number[] = [];
  let currentLabel: string | null = null;
  let currentSum: number = 0;

  for (const label of monthlyLabels) {
    const [year, month] = label.split('-');

    const quarter = Math.ceil(parseInt(month) / 3).toString();
    const currentDisplayLabel = `${year}-Q${quarter}`;

    if (currentLabel !== currentDisplayLabel) {
      if (currentLabel) {
        data.push(currentSum);
      }

      labels.push(currentDisplayLabel);
      currentLabel = currentDisplayLabel;
      currentSum = 0;
    }

    const dataPoint = monthlyData[label];
    if (typeof dataPoint === 'number') {
      currentSum += dataPoint;
    }
  }

  if (currentLabel) {
    data.push(currentSum);
  }

  return [labels, data];
};

const daysInMonth = (date: string, startDate: string, endDate: string): Date[] => {

  const targetDate = parseISO(date);
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  targetDate.setHours(0, 0, 0, 0);
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  const firstDayOfMonth = startOfMonth(targetDate);
  const lastDayOfMonth = endOfMonth(targetDate);
  const days = eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth });
  return days.filter((day) => day >= start && day <= end);
};

export const getDailyData = (
  monthlyData: Record<string, number>,
  startDate: string,
  endDate: string
): [string[], number[]] => {

  const labels: string[] = [];
  const data: number[] = [];

  for (const month of Object.keys(monthlyData)) {

    const dataPoint = monthlyData[month];
    const days = daysInMonth(month, startDate, endDate);
    const numberPerDay = dataPoint / days.length;
    days.forEach((day) => {

      labels.push(format(day, 'yyyy-MM-dd'));
      data.push(numberPerDay);
    });
  }

  return [labels, data];
};

const convertToWeeklyData = (dailyLabels: string[], dailyData: number[]): [string[], number[]] => {

  const weeklyLabels: string[] = [];
  const weeklyData: number[] = [];

  const dailyDates = dailyLabels.map((label) => parseISO(label));

  const startDate = dailyDates[0];
  const endDate = dailyDates[dailyDates.length - 1];

  if (startDate && endDate) {
    const weeklyDates = eachWeekOfInterval({ start: startDate, end: endDate });

    for (const weekStart of weeklyDates) {
      const weekEnd = endOfWeek(weekStart);

      const weeklyLabel = format(weekEnd, 'yyyy-MM-dd');
      const weeklyStartIndex = dailyDates.findIndex((date) => date >= weekStart);
      const weeklyEndIndex = dailyDates.findIndex((date) => date > weekEnd) - 1;
      const weeklySum = dailyData.slice(weeklyStartIndex, weeklyEndIndex + 1).reduce((acc, val) => acc + val, 0);

      weeklyLabels.push(weeklyLabel);
      weeklyData.push(weeklySum);
    }
  }

  return [weeklyLabels, weeklyData];
};

const convertToYearlyData = (monthLabels: string[], monthlyData: number[]): [string[], number[]] => {

  const yearlyLabels: string[] = [];
  const yearlyData: number[] = [];

  monthLabels.forEach((label) => {

    const yearLabel = label.slice(0, 4);

    if (!yearlyLabels.includes(yearLabel)) {
      yearlyLabels.push(yearLabel);
      yearlyData.push(0);
    }
    else {
      const index = yearlyLabels.indexOf(yearLabel);
      yearlyData[index] += monthlyData[monthLabels.indexOf(label)];
    }
  });

  return [yearlyLabels, yearlyData];
};


export const getDataByTimeInterval = (
  monthlyData: Record<string, number>,
  timeInterval: TimeInterval,
  startDate?: string,
  endDate?: string
): [string[], number[]] => {

  if (timeInterval === 'Day') {
    return getDailyData(monthlyData, startDate ?? '', endDate ?? '');
  }
  else if (timeInterval === 'Week') {
    const [labels, data] = getDailyData(monthlyData, startDate ?? '', endDate ?? '');

    return convertToWeeklyData(labels, data);
  }
  else if (timeInterval === 'Month') {
    return getMonthlyData(monthlyData);
  }
  else if (timeInterval === 'Quarter') {
    return getQuarterlyData(monthlyData);
  }
  else if (timeInterval === 'Year') {
    const [labels, data] = getDailyData(monthlyData, startDate ?? '', endDate ?? '');

    return convertToYearlyData(labels, data);
  }

  return [[], []];
};

export const getDataByTimeIntervalMap = (
  monthlyData: Record<string, number>,
  timeInterval: TimeInterval,
  startDate?: string,
  endDate?: string
): Record<string, number> => {

  const [labels, data] = getDataByTimeInterval(monthlyData, timeInterval, startDate, endDate);

  const map: Record<string, number> = {};
  labels.forEach((label, index) => {

    map[label] = data[index];
  });

  return map;
};


export const accumulateArray = (array: number[], displayOption: 'monthly' | 'quarterly' = 'monthly'): number[] => {

  return array.reduce((accumulator, currentValue) => {

    if (accumulator.length > 0) {
      currentValue += accumulator[accumulator.length - 1];
    }

    accumulator.push(currentValue);
    return accumulator;
  }, [] as number[]);
};

export const getEndDate = (wbsValues: WBSValue[]) => {

  const datetimeObjects: Date[] = wbsValues.map((wbsValue) => new Date(wbsValue.date));

  const highestMonth: Date = datetimeObjects.reduce((a: Date, b: Date) => {

    return a > b ? a : b;
  });

  return highestMonth.toISOString().slice(0, 10);
};

export const getFirstDayOfMonth = (isoDatetime: string): string => {

  const datetime: Date = new Date(isoDatetime);
  const year: number = datetime.getFullYear();
  const month: number = datetime.getMonth() + 1;
  const firstDayOfMonth: string = `${year}-${String(month).padStart(2, '0')}-01`;

  return firstDayOfMonth;
};

export const getDefaultEarnedValue = (
  monthlyCosts: MonthlyCosts,
  wbsCode: string,
  wbsValues: WBSValue[]
): Record<string, number> => {

  const newEarned = { ...(monthlyCosts.calculatedEarned ?? monthlyCosts.earned) };
  const dates = Object.keys(newEarned);

  const filteredByCodeWbsValues = wbsValues.filter((val) => val.wbsCode.startsWith(wbsCode));
  const earned = filteredByCodeWbsValues.filter((wbsValue) => wbsValue.type === WbsValueType.Earned);

  if (earned.length !== 0) {

    const endDate = getEndDate(earned);

    for (const date of dates) {
      if (new Date(date) <= new Date(endDate)) {
        newEarned[date] = 0.00;
      }
    }

    for (const actual of earned) {
      const month = getFirstDayOfMonth(actual.date);
      newEarned[month] += parseFloat(actual.value);
    }
  }

  return newEarned;
};
