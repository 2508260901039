import _ from 'lodash';
import * as actions from './actions';


export default function (state = {}, action: { [index: string]: any }) {

  _.defaultsDeep(state, {
    currentScope: null,
    showSwagPdf: true,
    currentSwagPdfItem: {},
    currentSwagPdfModuleData: {},
    showTakeoffMarkupModal: false,
    currentTakeoffMarkupAttachment: {}
  });

  switch (action.type) {
    case actions.MODULE_PDFMARKUP_OPEN_SWAGPDF:
      return {
        ...state,
        showSwagPdf: true,
        currentSwagPdfItem: action.item,
        currentSwagPdfModuleData: action.moduleData,
        currentScope: action.scope
      };
    case actions.MODULE_PDFMARKUP_CLOSE_SWAGPDF:
      return {
        ...state,
        showSwagPdf: false,
        currentSwagPdfItem: {},
        currentSwagPdfModuleData: {},
        currentSwagPdfBid: {}
      };
    case actions.MODULE_PDFMARKUP_OPEN_TAKEOFF_MARKUP_MODAL:
      return {
        ...state,
        showTakeoffMarkupModal: true,
        currentTakeoffMarkupAttachment: action.attachment,
        currentScope: action.scope
      };
    case actions.MODULE_PDFMARKUP_CLOSE_TAKEOFF_MARKUP_MODAL:
      return {
        ...state,
        showTakeoffMarkupModal: false,
        currentTakeoffMarkupAttachment: {},
        currentTakeoffMarkupBid: {}
      };
    default:
      return state;
  }

}
