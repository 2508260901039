interface IsMatchingStringCaseInsensitiveShape {
  firstString: string;
  secondString: string;
}

export const IsMatchingStringCaseInsensitive = (
  {
    firstString,
    secondString
  }: IsMatchingStringCaseInsensitiveShape) => {

  const isValidInput = firstString?.length && (secondString?.length === firstString?.length);
  return isValidInput && firstString.toLowerCase() === secondString.toLowerCase();
};
