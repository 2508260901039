import request from 'superagent';

import apiCommon from './common';


export default {

  PostMessageTypes: {
    DashboardVisited: 'DashboardVisited',
    ProjectVisited: 'ProjectVisited'
  },

  PrependApiUrl: (urlSuffix) => {

    return process.env.REACT_APP_API_URL + urlSuffix;
  },

  PrependApiUrlNoRedirect: async (urlSuffix) => {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + urlSuffix)
      .query({
        noRedirect: true
      });

    const result = await apiCommon.ProcessRequest(apiRequest);

    if (result.err) {
      // TODO: error out?
      return '';
    }

    return result.res.body.downloadUrl;
  }
};
