import baseQuery from './baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

export const baseApi = createApi({
  reducerPath: 'projectApi',
  tagTypes: [
    'Attachment',
    'Bid',
    'CostManagementDashboard',
    'Division',
    'Metadata',
    'PhaseSummarySheet',
    'Project',
    'ProjectDetails',
    'ProjectTag',
    'ProjectVariable',
    'Section',
    'SectionHierarchy',
    'SectionItem',
    'SummarySheet',
    'SummarySheetCollection',
    'Tracker'
  ],
  baseQuery,
  endpoints: () => ({})
});

const tagsFactory = (type) => {

  return (...ids) => (ids?.length ? [...ids.flat().flatMap((id) => (id ? [{ type, id }].filter(Boolean) : []))] : []);
};

const listFactory = (type) => {

  return { type, id: 'LIST' };
};

export const attachmentTags = tagsFactory('Attachment');
export const bidTags = tagsFactory('Bid');
export const costManagementDashboardTags = tagsFactory('CostManagementDashboard');
export const divisionTags = tagsFactory('Division');
export const metadataTags = tagsFactory('Metadata');
export const phaseSummarySheetTags = tagsFactory('PhaseSummarySheet');
export const projectTags = tagsFactory('Project');
export const projectDetailsTags = tagsFactory('ProjectDetails');
export const sectionHierarchyTags = tagsFactory('SectionHierarchy');
export const sectionTags = tagsFactory('Section');
export const sectionItemTags = tagsFactory('SectionItem');
export const summarySheetTags = tagsFactory('SummarySheet');
export const summarySheetCollectionTags = tagsFactory('SummarySheetCollection');
export const projectTagTags = tagsFactory('ProjectTag');
export const projectVariableTags = tagsFactory('ProjectVariable');
export const trackerTags = tagsFactory('Tracker');
export const wbsDataTags = tagsFactory('WBSData');
export const wbsPhasesTags = tagsFactory('WBSPhases');

export const attachmentList = listFactory('Attachment');
export const bidList = listFactory('Bid');
export const costManagementDashboardList = listFactory('CostManagementDashboard');
export const divisionList = listFactory('Division');
export const metadataList = listFactory('Metadata');
export const phaseSummarySheetList = listFactory('PhaseSummarySheet');
export const projectList = listFactory('Project');
export const projectDetailsList = listFactory('ProjectDetails');
export const sectionList = listFactory('Section');
export const sectionItemList = listFactory('SectionItem');
export const summarySheetList = listFactory('SummarySheet');
export const summarySheetCollectionList = listFactory('SummarySheetCollection');
export const projectTagList = listFactory('ProjectTag');
export const projectVariableList = listFactory('ProjectVariable');
export const trackerList = listFactory('Tracker');
export const wbsDataList = listFactory('WBSData');
export const wbsHiddenRowList = listFactory('HiddenWbsRow');
export const wbsPhasesList = listFactory('WBSPhases');
export const wbsMetadataList = listFactory('WBSMetadata');
export const curveList = listFactory('Curve');

export default baseApi;
