import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import api from '../../api';
import CreateProjectDialog from '../Dialogs/CreateProjectDialog';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CurrencyShape } from '../../types/api/ProjectCurrencyShape';

const { v4: Uuidv4 } = require('uuid');


const useStyles = makeStyles((theme) => ({
  addProjectButton: {
    marginTop: theme.spacing(1),
    maxWidth: 'fit-content'
  },
  projectName: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',

    '&.loading': {
      textDecoration: 'none'
    }
  },
  newProjectRow: {
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,

    '&.loading': {
      cursor: 'auto'
    },

    '&.odd': {
      backgroundColor: theme.palette.primary.main + '80'
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.dark + '80',
      borderRadius: '5px'
    }
  },
  newProjectsRows: {
    marginTop: theme.spacing(1)
  },
  newProjectLoadingIndicator: {
    marginRight: theme.spacing(1)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    width: '100%'
  }
}));

const CreateProject = (props) => {

  const classes = useStyles();
  const history = useHistory();

  const {
    onNonTemplateProjectAdded,
    projectsInfo,
    trackingIdsToNewProjectIds,
    userCompanyId
  } = props;

  const [newProjects, setNewProjects] = useState([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  useEffect(() => {

    if (!_.isEmpty(trackingIdsToNewProjectIds)) {
      setNewProjects((prev) => {

        return prev.map((projectInfo) => {

          if (trackingIdsToNewProjectIds[projectInfo.trackingId]) {
            return {
              ...projectInfo,
              id: trackingIdsToNewProjectIds[projectInfo.trackingId],
              isLoading: false,
              trackingId: undefined
            };
          }

          return projectInfo;
        });
      });
    }
  }, [trackingIdsToNewProjectIds]);

  const handleCloseCreateDialog = useCallback(() => setShowCreateDialog(false), []);

  const handleShowCreateDialog = useCallback(() => setShowCreateDialog(true), []);

  const handleConfirmCreateDialog = useCallback((
    name,
    description,
    address,
    city,
    state,
    postalCode,
    dueDate,
    visibility,
    template,
    templatePhasesDueDate,
    templateOmittedAreas,
    importData,
    labelIds,
    primaryCurrency,
    primaryCurrencySymbol,
    currencies
  ) => {

    handleCloseCreateDialog(false);

    const trackingId = Uuidv4();

    setNewProjects((prev) => {

      const newProject = {
        id: trackingId,
        isLoading: true,
        name,
        trackingId
      };

      return [...prev, newProject];
    });

    if (template) {
      api.Project
        .CreateWithTemplate(
          name,
          description,
          address,
          city,
          state,
          postalCode,
          dueDate,
          visibility,
          template,
          templatePhasesDueDate,
          templateOmittedAreas,
          trackingId,
          importData,
          labelIds,
          primaryCurrency,
          primaryCurrencySymbol,
          currencies
        ).then((result) => {

          if (result.err) {
            setNewProjects((prev) => prev.filter((p) => p.id !== trackingId));
            return;
          }
        });
    }
    else {
      api.Project
        .Create(
          name,
          description,
          address,
          city,
          state,
          postalCode,
          dueDate,
          visibility,
          importData,
          labelIds,
          primaryCurrency,
          primaryCurrencySymbol,
          currencies
        )
        .then((result) => {

          if (!result.err) {
            setNewProjects((prev) => {

              if (result.err) {
                return prev.filter((p) => p.id !== trackingId);
              }

              const updatedProjects = [...prev];
              const actualProjectId = result.res.body.id;
              const loadingProject = updatedProjects.find((p) => p.id === trackingId);

              loadingProject.id = actualProjectId;
              loadingProject.isLoading = false;

              return updatedProjects;
            });

            onNonTemplateProjectAdded();
          }
        });
    }
  }, [handleCloseCreateDialog, onNonTemplateProjectAdded]);

  const handleProjectRowClick = useCallback((evt) => {

    const projectId = evt.currentTarget.dataset.projectId;
    history.push(`/projects/${projectId}`);
  }, [history]);

  const templateProjectInfo = useMemo(() => {

    return projectsInfo.filter(
      (project) => (project.isGlobalTemplate || (project.isTemplate && project.companyId === userCompanyId))
    );
  }, [projectsInfo, userCompanyId]);

  const creatingProjectsUI = useMemo(() => {

    return newProjects.map(({ id, isLoading, name }, index) => {

      let clickHandler = handleProjectRowClick;
      let loadingClass = '';
      let loadingIndicator = null;
      if (isLoading) {
        clickHandler = null;
        loadingClass = ' loading';
        loadingIndicator = (
          <CircularProgress
            className={classes.newProjectLoadingIndicator}
            color="secondary"
            size={15}
          />
        );
      }

      const rowBackgroundClass = (index % 2 !== 0) ? ' odd' : '';

      return (
        <div
          key={id}
          className={classes.newProjectRow + rowBackgroundClass + loadingClass}
          data-project-id={id}
          data-cy={`create-project-list.new-project-row${(isLoading) ? '.loading' : ''}`}
          onClick={clickHandler}
        >
          <span className={classes.projectName + loadingClass}>
            {loadingIndicator}
            {name}
          </span>
          <span />
        </div>
      );
    });
  }, [classes, handleProjectRowClick, newProjects]);

  return useMemo(() => {

    return (
      <div className={classes.root} data-cy="create-project-list">
        <Button
          className={classes.addProjectButton}
          onClick={handleShowCreateDialog}
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          data-cy="create-project-list.create-button"
        >
          Add Project
        </Button>
        <div className={classes.newProjectsRows}>
          {creatingProjectsUI}
        </div>

        <CreateProjectDialog
          forCompanyId={userCompanyId}
          id="create_project"
          open={showCreateDialog}
          onCancel={handleCloseCreateDialog}
          onConfirm={handleConfirmCreateDialog}
          templates={templateProjectInfo}
          shouldShowImport={false}
        />
      </div>
    );
  }, [
    classes,
    creatingProjectsUI,
    handleCloseCreateDialog,
    handleConfirmCreateDialog,
    handleShowCreateDialog,
    showCreateDialog,
    templateProjectInfo,
    userCompanyId
  ]);
};

export default CreateProject;
