import React, { ReactComponentElement } from 'react';
import { NavLink } from 'react-router-dom';

import { styles } from '../styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


export interface CreateNewUserPromptProps {
  canSubmit: boolean;
  confirmPassword: string;
  email: string;
  fullName: string;
  onInputChange: (e: React.ChangeEvent<{ id: any; value: any; }>) => void;
  onKeyDown: (evt: React.KeyboardEvent) => void;
  onSubmitCreateUser: (e: React.MouseEvent) => void;
  onToggleCreateNewUserPrompt: (evt: React.MouseEvent<Element, MouseEvent>) => void;
  password: string;
  showCreateNewCompanyUserLink: boolean;
  userMessageUI: ReactComponentElement<any> | null;
}

const CreateNewUserPrompt = (
  {
    canSubmit,
    confirmPassword,
    email,
    fullName,
    onInputChange,
    onKeyDown,
    onSubmitCreateUser,
    onToggleCreateNewUserPrompt,
    password,
    showCreateNewCompanyUserLink,
    userMessageUI
  }: CreateNewUserPromptProps) => {

  const classes = styles();

  return (
    <div className={classes.form}>
      <div className={classes.loginHeader}>
        <Typography variant="h5" gutterBottom>Create User</Typography>
        {showCreateNewCompanyUserLink
          ?
          <NavLink
            to=""
            onClick={onToggleCreateNewUserPrompt}
            color="secondary"
            data-cy="login.create-new-user-link"
          >
            {'Create new company user'}
          </NavLink>
          :
          null
        }
      </div>

      {userMessageUI}

      <form>
        <div>
          <TextField
            type="text"
            id="email"
            data-cy="new-user.email"
            label="Email"
            margin="normal"
            fullWidth
            value={email}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="fullName"
            data-cy="new-user.full-name"
            label="Full Name"
            margin="normal"
            fullWidth
            value={fullName}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div>
          <TextField
            type="password"
            id="password"
            data-cy="new-user.password"
            label="Password"
            margin="normal"
            fullWidth
            value={password}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div>
          <TextField
            type="password"
            id="confirmPassword"
            data-cy="new-user.confirm-password"
            label="Confirm Password"
            margin="normal"
            fullWidth
            value={confirmPassword}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={classes.controls}>
          <Button
            variant="contained"
            color="primary"
            data-cy="new-user.create"
            onClick={onSubmitCreateUser}
            disabled={!canSubmit}
          >
            Create
          </Button>
          <NavLink
            to=""
            onClick={onToggleCreateNewUserPrompt}
            color="secondary"
            data-cy="new-user.cancel-button"
          >
            Cancel
          </NavLink>
        </div>
      </form>
    </div>
  );
};

export { CreateNewUserPrompt };
