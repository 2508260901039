import { generateCustomWbsCode } from 'wbs/dist/generateCustomWbsCode';
import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';
import { WBSCodeCustomizationShape } from 'wbs/dist/types/WBSCodeCustomizationShape';


export const getParentCode = (wbsCode: string, separator: string = ' ') => {

  return wbsCode.split(separator).slice(0, -1).join(separator);
};

export const calculateWbsCode = (
  dataRows: WBSDataRowShape[],
  rbsCodeSeparator: string,
  rbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  projectDataId: number,
  directCostDataId: number,
  finalCostsDataId: number
): Partial<WBSDataRowShape>[] => {

  let keepTrackOfLevel = [0];
  const updates = [] as Partial<WBSDataRowShape>[];

  for (const row of dataRows) {

    const indentationLevel = (row.name.match(/^(\s*)/)?.[1].length || 0);
    keepTrackOfLevel = keepTrackOfLevel.slice(0, indentationLevel + 1);
    const wbsCodes = [];
    for (let index = 0; index < indentationLevel + 1; index++) {
      if (keepTrackOfLevel[index] === undefined) {
        keepTrackOfLevel.push(0);
      }

      if (index === indentationLevel) {
        keepTrackOfLevel[index] += 1;
      }

      const nextCode = keepTrackOfLevel[index];
      wbsCodes.push(`${String(nextCode).padStart(2, '0')}`);
    }

    let newWbsCode = wbsCodes.join(' ');
    // ensure top level rows keep their required wbs code
    if (row.id === projectDataId && row.wbsCode !== '01') {
      newWbsCode = '01';
    }
    else if (row.id === directCostDataId && row.wbsCode !== '01 01') {
      newWbsCode = '01 01';
    }
    else if (row.id === finalCostsDataId && row.wbsCode !== '01 02') {
      newWbsCode = '01 02';
    }

    row.customWbsCode = (row.isResource) ?
      generateCustomWbsCode(newWbsCode, rbsCodeSeparator, rbsCodeCustomizationsMatrix) :
      generateCustomWbsCode(newWbsCode, wbsCodeSeparator, wbsCodeCustomizationsMatrix);

    if (row.wbsCode !== newWbsCode) {
      updates.push({ id: row.id!, wbsCode: newWbsCode });
      row.wbsCode = newWbsCode;
    }
  }

  return updates;
};

export const calculateWbsCodeAndGetChangeSet = (
  dataRows: WBSDataRowShape[],
  changes: Partial<WBSDataRowShape>[],
  rbsCodeSeparator: string,
  rbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  projectDataId: number,
  directCostDataId: number,
  finalCostDataId: number
): Partial<WBSDataRowShape>[] => {

  const wbsCodeChanges = calculateWbsCode(
    dataRows,
    rbsCodeSeparator,
    rbsCodeCustomizationsMatrix,
    wbsCodeSeparator,
    wbsCodeCustomizationsMatrix,
    projectDataId,
    directCostDataId,
    finalCostDataId
  );

  for (const wbsCodeChange of wbsCodeChanges) {

    const existingChange = changes.find((c) => c.id === wbsCodeChange.id);

    if (existingChange) {
      existingChange.wbsCode = wbsCodeChange.wbsCode;
    }
    else {
      changes.push(wbsCodeChange);
    }
  }

  return changes;
};

export const decreaseLevelOfRowsByIds = (
  dataRows: WBSDataRowShape[],
  rowIds: number[],
  rbsCodeSeparator: string,
  rbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  projectDataId: number,
  directCostDataId: number,
  finalCostDataId: number
): Partial<WBSDataRowShape>[] => {

  const changes = [] as Partial<WBSDataRowShape>[];

  for (const rowId of rowIds) {
    const dataRow = dataRows.find((r) => r.id === rowId);

    if (dataRow?.name?.startsWith(' ')) {

      const newName = dataRow.name.substring(1);
      dataRow.name = newName;
      const change = { id: rowId, name: newName };

      changes.push(change);
    }
  }

  return calculateWbsCodeAndGetChangeSet(
    dataRows,
    changes,
    rbsCodeSeparator,
    rbsCodeCustomizationsMatrix,
    wbsCodeSeparator,
    wbsCodeCustomizationsMatrix,
    projectDataId,
    directCostDataId,
    finalCostDataId
  );
};

export const increaseLevelOfRowsByIds = (
  dataRows: WBSDataRowShape[],
  rowIds: number[],
  rbsCodeSeparator: string,
  rbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  projectDataId: number,
  directCostDataId: number,
  finalCostDataId: number
): Partial<WBSDataRowShape>[] => {

  const changes = [] as Partial<WBSDataRowShape>[];

  for (const rowId of rowIds) {
    const dataRow = dataRows.find((r) => r.id === rowId);

    if (dataRow) {
      const newName = ` ${dataRow.name}`;
      dataRow.name = newName;
      const change = { id: rowId, name: newName };

      changes.push(change);
    }
  }

  return calculateWbsCodeAndGetChangeSet(
    dataRows,
    changes,
    rbsCodeSeparator,
    rbsCodeCustomizationsMatrix,
    wbsCodeSeparator,
    wbsCodeCustomizationsMatrix,
    projectDataId,
    directCostDataId,
    finalCostDataId
  );
};

/**
 * Reverts a customized WBS code back to its original form based on mappings.
 * @param {string} customWbsCode - the customized WBS code (e.g. "BLDG-TEST-FLOORING")
 * @param {string} separator - the separator used in the customized code (e.g. "-")
 * @param {WBSCodeCustomizationShape[][]} mappingsMatrix - a 2D array of mappings by their level index [level][mapping]
 * @return {string} - the original WBS code (e.g. "01 02 03")
 */
export const convertCustomWbsCodeToWbsCode = (
  customWbsCode: string,
  separator: string,
  mappingsMatrix: WBSCodeCustomizationShape[][]
) => {

  const customCodeParts = customWbsCode.split(separator);
  const originalCodeParts: string[] = [];

  for (const customCodePart of customCodeParts) {
    const mappingIndex = findCustomMappingIndex(customCodePart, mappingsMatrix[originalCodeParts.length]);

    if (mappingIndex >= 0) {
      originalCodeParts.push(String(mappingIndex + 1).padStart(2, '0'));
    }
    else {
      originalCodeParts.push(customCodePart);
    }
  }

  return originalCodeParts.join(' ');
};

const findCustomMappingIndex = (customCodePart: string, mappings?: WBSCodeCustomizationShape[]) => {

  if (!mappings) {
    return -1;
  }

  for (let index = 0; index < mappings.length; index++) {
    if (mappings[index]?.value === customCodePart) {
      return index;
    }
  }

  return -1;
};

const isNormalCode = (part: string) => /^\d+$/.test(part);

export const fillMissingWBSMappings = (
  customWbsCodes: string[],
  isResources: boolean,
  separator: string,
  mappingsMatrix: WBSCodeCustomizationShape[][],
  projectId: number
) => {

  const updatedMappingsMatrix: WBSCodeCustomizationShape[][] = [...mappingsMatrix];

  customWbsCodes.forEach((code) => {

    const parts = code.split(separator);

    parts.forEach((part, index) => {

      const level = index + 1;

      if (!updatedMappingsMatrix[level - 1]) {
        updatedMappingsMatrix.push([]);
      }

      if (!isNormalCode(part)) {
        const currentLevelMappings = updatedMappingsMatrix[level - 1];

        const existingMappingIndex = currentLevelMappings.findIndex((mapping) => mapping.value === part);
        if (existingMappingIndex === -1) {
          const newMapping: WBSCodeCustomizationShape = {
            id: -1,
            isResources,
            projectId,
            level,
            value: part
          };

          currentLevelMappings.push(newMapping);
        }
      }
    });
  });

  return updatedMappingsMatrix;
};
