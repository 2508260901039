import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'right'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    minWidth: '300px'
  }
}));

const SelectSectionInProjectDialog = (props) => {

  const classes = useStyles();

  const cust = useSelector((state) => state.project.customDesignations);

  const {
    divisions = [],
    isOpen,
    onCancel,
    onSectionSelected,
    title
  } = props;

  const [divisionId, setDivisionId] = useState('');
  const [sectionId, setSectionId] = useState('');

  useEffect(() => {

    setDivisionId('');
    setSectionId('');
  }, [divisions, isOpen]);

  const handleSelectDivision = useCallback((e) => {

    setSectionId('');
    setDivisionId(parseInt(e.target.value));
  }, []);

  const handleSelectSection = useCallback((e) => {

    setSectionId(parseInt(e.target.value));
  }, []);

  const handleClose = useCallback(() => {

    setDivisionId('');
    setSectionId('');
    onCancel();
  }, [onCancel]);

  const handleConfirmClicked = useCallback(() => {

    onSectionSelected(sectionId, divisionId);
  }, [divisionId, onSectionSelected, sectionId]);

  const divisionDropdown = useMemo(() => {

    const menuItems = [];

    _.each(divisions, (division) => {

      if (!division.sections.length) {
        return;
      }

      menuItems.push(
        <MenuItem key={division.id} value={division.id} data-cy="select-section-dialog.division.option">
          {division.name}
        </MenuItem>
      );
    });

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="select-section-dialog-division">{cust.division}</InputLabel>
        <Select
          className={classes.select}
          labelId="select-section-dialog-division"
          data-cy="select-section-dialog.division"
          onChange={handleSelectDivision}
          value={divisionId}
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  }, [classes, cust, divisions, divisionId, handleSelectDivision]);

  const sectionDropdown = useMemo(() => {

    const menuItems = [];

    if (divisionId) {
      const sections = divisions.find((d) => d.id === divisionId).sections;

      _.each(sections, (section) => {

        menuItems.push(
          <MenuItem key={section.id} value={section.id} data-cy="select-section-dialog.section.option">
            {section.name}
          </MenuItem>
        );
      });
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel>{cust.area}</InputLabel>
        <Select
          className={classes.select}
          data-cy="select-section-dialog.section"
          disabled={!divisionId}
          labelId="select-section-dialog-section"
          onChange={handleSelectSection}
          value={sectionId}
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  }, [classes, cust, divisionId, divisions, sectionId, handleSelectSection]);

  const actionButtons = useMemo(() => {

    return (
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={sectionId === ''}
          onClick={handleConfirmClicked}
          variant="contained"
          data-cy="select-section-dialog.confirm"
        >
          Select
        </Button>
      </DialogActions>
    );
  }, [classes, sectionId, handleClose, handleConfirmClicked]);

  return useMemo(() => {

    return (
      <Dialog
        data-cy="select-section-dialog"
        disableEnforceFocus={true}
        maxWidth="lg"
        onClose={handleClose}
        open={isOpen}
      >
        <DialogTitle>{title || `Select ${cust.area}`}</DialogTitle>
        <DialogContent>
          {divisionDropdown}
          {sectionDropdown}
        </DialogContent>
        {actionButtons}
      </Dialog>
    );
  }, [actionButtons, cust, divisionDropdown, handleClose, isOpen, sectionDropdown, title]);
};

export default SelectSectionInProjectDialog;
