import {
  baseApi,
  costManagementDashboardList,
  costManagementDashboardTags
} from './baseApi';

export const CostManagementDashboardApiSlice = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({

    addDashboard: builder.mutation({
      query: ({ title }) => ({
        url: '/cost-management-dashboard/add-dashboard',
        method: 'POST',
        data: { title }
      }),
      invalidatesTags: (_result, _error, arg) => [
        costManagementDashboardList
      ]
    }),

    addScenario: builder.mutation({
      query: ({ dashboardId, isDefault, note, reportingPeriod, title, wbsLevel }) => ({
        url: '/cost-management-dashboard/add-dashboard-scenario',
        method: 'POST',
        data: { dashboardId, isDefault, note, reportingPeriod, title, wbsLevel }
      }),
      invalidatesTags: (_result, _error, arg) => [...costManagementDashboardTags([arg.dashboardId])]
    }),

    addScenarioCurve: builder.mutation({
      query: ({ curveData, scenarioId, wbsDataId }) => ({
        url: '/cost-management-dashboard/add-dashboard-scenario-curve',
        method: 'POST',
        data: { curveData, scenarioId, wbsDataId }
      })
    }),

    importWbsValues: builder.mutation({
      query: ({ projectId, wbsValueType, dataRows, curveData, customCurveId }) => ({
        url: '/cost-management-dashboard/import-wbs-values',
        method: 'POST',
        data: { projectId, wbsValueType, dataRows, curveData, customCurveId }
      })
    }),

    addScenarioRowOptions: builder.mutation({
      query: ({ scenarioId, wbsCode, forceEacMatchPv, forceEcdMatchWbsEndDate }) => ({
        url: '/cost-management-dashboard/add-dashboard-scenario-row-options',
        method: 'POST',
        data: { scenarioId, wbsCode, forceEacMatchPv, forceEcdMatchWbsEndDate }
      }),
      invalidatesTags: (_result, _error, arg) => {

        return (arg.dashboardId) ?
          [...costManagementDashboardTags([arg.dashboardId])] :
          [];
      }
    }),

    deleteDashboard: builder.mutation({
      query: ({ id }) => ({
        url: '/cost-management-dashboard/delete-dashboard',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [
        costManagementDashboardList
      ]
    }),

    deleteScenario: builder.mutation({
      query: ({ id }) => ({
        url: '/cost-management-dashboard/delete-dashboard-scenario',
        method: 'DELETE',
        data: { id }
      }),
      invalidatesTags: (_result, _error, arg) => [...costManagementDashboardTags([arg.dashboardId])]
    }),

    deleteAdditionalCurve: builder.mutation({
      query: ({ id }) => ({
        url: '/cost-management-dashboard/delete-dashboard-additional-curve',
        method: 'DELETE',
        data: { id }
      })
    }),

    editAdditionalCurve: builder.mutation({
      query: ({
        id,
        color,
        weight,
        name
      }) => ({
        url: '/cost-management-dashboard/edit-dashboard-additional-curve',
        method: 'PATCH',
        data: {
          id,
          color,
          weight,
          name
        }
      })
    }),

    deleteScenarioCurve: builder.mutation({
      query: ({ id }) => ({
        url: '/cost-management-dashboard/delete-dashboard-scenario-curve',
        method: 'DELETE',
        data: { id }
      })
    }),

    editDashboard: builder.mutation({
      query: ({
        forecastColumnTotalsEnabled,
        forecastColumnTotalsOnTop,
        forecastRowTotalsEnabled,
        forecastRowTotalsOnLeft,
        id,
        title,
        projectIds
      }) => ({
        url: '/cost-management-dashboard/edit-dashboard',
        method: 'PATCH',
        data: {
          forecastColumnTotalsEnabled,
          forecastColumnTotalsOnTop,
          forecastRowTotalsEnabled,
          forecastRowTotalsOnLeft,
          id,
          title,
          projectIds
        }
      }),
      invalidatesTags: (_result, _error, arg) => [
        costManagementDashboardList,
        ...costManagementDashboardTags([arg.id])
      ]
    }),

    editScenario: builder.mutation({
      query: ({
        id,
        isDefault,
        note,
        reportingPeriod,
        title,
        wbsLevel
      }) => ({
        url: '/cost-management-dashboard/edit-dashboard-scenario',
        method: 'PATCH',
        data: {
          id,
          isDefault,
          note,
          reportingPeriod,
          title,
          wbsLevel
        }
      }),
      invalidatesTags: (_result, _error, arg) => {

        return (arg.dashboardId) ?
          [...costManagementDashboardTags([arg.dashboardId])] :
          [];
      }
    }),

    editScenarioCurve: builder.mutation({
      query: ({
        scenarioId,
        wbsId,
        wbsCode,
        wbsName,
        reportingPeriod,
        forceEacMatchPv,
        forceEcdMatchWbsEndDate,
        curveData
      }) => ({
        url: '/cost-management-dashboard/edit-dashboard-scenario-curve',
        method: 'PATCH',
        data: {
          scenarioId,
          wbsId,
          wbsCode,
          wbsName,
          reportingPeriod,
          forceEacMatchPv,
          forceEcdMatchWbsEndDate,
          curveData
        }
      })
    }),

    editScenarioRowOptions: builder.mutation({
      query: ({ id, forceEacMatchPv, forceEcdMatchWbsEndDate }) => ({
        url: '/cost-management-dashboard/edit-dashboard-scenario-row-options',
        method: 'PATCH',
        data: { id, forceEacMatchPv, forceEcdMatchWbsEndDate }
      }),
      invalidatesTags: (_result, _error, arg) => {

        return (arg.dashboardId) ?
          [...costManagementDashboardTags(arg.dashboardId)] :
          [];
      }
    }),

    getDashboardData: builder.query({
      query: ({ id, selectedScenarioId }) => ({
        url: '/cost-management-dashboard/get-dashboard-data',
        method: 'GET',
        data: {  },
        params: { id, selectedScenarioId }
      }),
      providesTags: (result, _error, arg) => {

        if (!result) {
          return [];
        }

        return [...costManagementDashboardTags([result.id])];
      }
    }),

    getOwnCompanyDashboards: builder.query({
      query: () => ({
        url: '/cost-management-dashboard/get-own-company-dashboards',
        method: 'GET',
        data: {  }
      }),
      providesTags: (result, _error, arg) => [
        ...costManagementDashboardTags(result.map(({ id }) => id)),
        costManagementDashboardList
      ]
    }),

    getOwnCompanyDashboardScenarioTitles: builder.query({
      query: () => ({
        url: '/cost-management-dashboard/get-own-company-dashboard-scenario-titles',
        method: 'GET',
        data: {  }
      })
    })
  })
});

