import api from './index';
import io, { Socket } from 'socket.io-client';
import { socketioDebug } from '../hooks/projects/socketioDebug';
import { dispatch as dispatchRealtime } from './eventBus';

let rootSocket: Socket;

export const connectRootSocket = () => {

  const socketUrl = api.General.PrependApiUrl(`/`).replace('/api', '');
  rootSocket = io(socketUrl, {
    withCredentials: true
  });
  rootSocket.onAny((event, args) => {

    socketioDebug(`message - ${event}`);
    dispatchRealtime(event, args);
  });
  socketioDebug(`Connecting socket ${socketUrl}`);
};

export const disconnectRootSocket = () => {

  socketioDebug('Disconnect Root Socketio...');
  rootSocket?.disconnect();
};

export const reconnectUserSocket = (userId: string) => {

  disconnectRootSocket();
  connectRootSocket();
};

export const connectProjectSocket = (projectId: string) => {

  const socketUrl = api.General.PrependApiUrl(`/projects/${projectId}`).replace('/api', '');
  const projectSocket = io(socketUrl, {
    withCredentials: true
  });
  socketioDebug(`Connecting socket ${socketUrl}`);

  return projectSocket;
};
