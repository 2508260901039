import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  addButton: {
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  }
}));

export interface addButtonProps {
  onClick: (event?: any) => void,
  label?: string,
  dataCy?: string,
  disabled?: boolean
}

const AddButton = ({ onClick, label, dataCy = 'add-button', disabled = false }: addButtonProps) => {

  const classes = useStyles();

  return !label ?
    <IconButton
      className={classes.addButton}
      size="small"
      color="secondary"
      onClick={onClick}
      data-cy={dataCy}
      disabled={disabled}
    >
      <AddCircleOutlineIcon />
    </IconButton>
    :
    <Button
      className={classes.addButton}
      color="secondary"
      startIcon={<AddCircleOutlineIcon />}
      onClick={onClick}
      data-cy={dataCy}
      disabled={disabled}
    >
      {label}
    </Button>;
};

export { AddButton };
