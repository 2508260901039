import React from 'react';

interface EnterToSubmitShape {
  evt: React.KeyboardEvent;
  canSubmit?: boolean;
  useOptionalCallback?: boolean;
  callback: (evt: React.MouseEvent | undefined) => void;
  callbackParameter?: any;
  optionalCallback?: (e: React.MouseEvent | undefined) => void;
  optionalCallbackParameter?: any;
}

const EnterToSubmit = (
  {
    evt,
    canSubmit = true,
    useOptionalCallback = false,
    callback,
    callbackParameter = undefined,
    optionalCallback,
    optionalCallbackParameter = undefined
  }: EnterToSubmitShape) => {

  if (evt.key === 'Enter') {
    evt.preventDefault();

    if (canSubmit && (!useOptionalCallback || !Boolean(optionalCallback))) {
      callback(callbackParameter);
    }
    else if (canSubmit && optionalCallback) {
      optionalCallback(optionalCallbackParameter);
    }
  }
};

export { EnterToSubmit };
