import React, { useState, useMemo, useEffect, useCallback, useRef, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import api from '../../api';

import * as markerjs2 from 'markerjs2';
import { ProjectApiSlice } from '../../api/project';
import { ResizableBox } from 'react-resizable';
import { useSnackbar } from 'notistack';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import pdfjs from '@bundled-es-modules/pdfjs-dist';
import Tooltip from '@material-ui/core/Tooltip';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { RootStateOrAny, useSelector } from 'react-redux';

const workerSource = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = workerSource;

const TotalZoomLevels = 11; // make sure this is an odd number so we have a zoom level right in the middle

const nullFunction = () => null;

const useStyles = makeStyles((theme) => ({

  closeButton: {
    position: 'absolute',
    top: 0,
    right: '20px'
  },
  documentButton: {
    padding: '2px 4px',
    margin: '2px 4px',
    fontSize: '0.5rem',
    width: 'auto'
  },
  goToPage: {
    fontSize: '0.6rem'
  },
  input: {
    width: '40px',
    margin: '4px'
  },
  markerJs: {
    height: '100%',
    position: 'absolute',
    width: '100%'
  },
  modal: {
    position: 'relative',
    transform: 'translateY(-50%)',
    maxWidth: '65vw'
  },
  pdfCanvas: {
    pointerEvents: 'none',
    maxWidth: 'unset'
  },
  pdfCanvasWrapper: {
    position: 'relative',
    marginTop: '70px'
  },
  pdfPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    height: 'calc(100vh - 200px)',
    padding: '15px',
    backgroundColor: theme.palette.bidDocumentation.background,
    overflow: 'auto',
    overscrollBehavior: 'contain',
    scrollbarColor: theme.palette.primary.dark,

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 14px 14px transparent',
      border: 'solid 4px transparent'
    },
    '&::-webkit-scrollbar': {
      width: '18px'
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 14px 14px ' + theme.palette.primary.dark,
      border: 'solid 4px transparent',
      borderRadius: '14px'
    }
  },
  resizeHandle: {
    position: 'absolute',
    top: '50%',
    left: -16,
    cursor: 'w-resize',
    backgroundColor: theme.palette.primary.dark,
    border: `3px solid ${theme.palette.bidDocumentation.background}`,
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.primary.light }
  },
  root: {
    display: 'none'
  },
  snackbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    zIndex: 10050
  },
  toolbar: {
    position: 'fixed',
    width: '96%',
    backgroundColor: theme.palette.bidDocumentation.toolbar,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    top: '2px',
    height: '50px',
    zIndex: 100,
    boxShadow: '1px 1px 2px',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  toolbarButton: {
    padding: '3px',
    fontSize: '0.6rem',
    margin: '4px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const AttachmentSplitViewDialog = (props) => {

  const classes = useStyles();
  const pdfCanvasRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const permissions = useSelector((state) => state.project.projectPermissions);

  const [uploadBidAttachment] = ProjectApiSlice.useUploadBidAttachmentMutation();

  const {
    bid,
    onChangeSectionUpdatesLocked = nullFunction,
    onPinBidAttachment = nullFunction,
    onUnpinBidAttachment = nullFunction,
    onSplitScreenChange = nullFunction,
    onSplitViewClose,
    splitViewAttachment
  } = props;

  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfWrapperAlignment, setPdfWrapperAlignment] = useState('center');
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [annotationAttatchment, setAnnotationAttatchment] = useState(
    splitViewAttachment?.bidAttachments?.find((att) => att.pdfAnnotationPageNum === pageNumber)
  );
  const [numPages, setNumPages] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [pdfAttachment, setPdfAttachment] = useState(null);
  const [isSavingMarkup, setIsSavingMarkup] = useState(false);
  const availableZoomLevelsRef = useRef([]);
  const zoomLevelIndexRef = useRef(-1);
  const markupRef = useRef();
  const markerAreaRef = useRef();
  const annotationCanvasRef = useRef();
  const scrollYWhenOpenedRef = useRef(null);
  const pdfPaperRef = useRef();

  const handleReturnValuesToOriginalState = useCallback((closeFilesDialogIfOpen) => {

    // when closing split screen mode all values need to be returned to original state
    setPdf(null);
    onSplitScreenChange(false);
    onUnpinBidAttachment();
    setPdfAttachment(null);
    setPdfWrapperAlignment('center');
    setOpen(false);
    onChangeSectionUpdatesLocked(api.Project.SectionLiveUpdatesLockedReason.SplitViewOpen, false);
    scrollYWhenOpenedRef.current = null;
    onSplitViewClose(closeFilesDialogIfOpen);

  }, [onChangeSectionUpdatesLocked, onUnpinBidAttachment, onSplitScreenChange, onSplitViewClose]);

  useEffect(() => {

    // Handle the section unloading if the user scrolls away.
    return () => {

      if (scrollYWhenOpenedRef.current !== null && Math.abs(window.scrollY - scrollYWhenOpenedRef.current) > 500) {
        handleReturnValuesToOriginalState(true);
      }
    };
  }, [handleReturnValuesToOriginalState]);

  useEffect(() => {

    setAnnotationAttatchment(splitViewAttachment?.bidAttachments?.find((att) => att.pdfAnnotationPageNum === pageNumber));
  }, [pageNumber, splitViewAttachment]);

  const pdfPaperRefCurrent = pdfPaperRef.current;

  useEffect(() => {

    const preventOverscroll = (e) => {

      // check if the element has a scroll bar, and if not, don't allow mouse wheel scrolling.  this prevents
      // overscroll when there's no scroll bar, and the overscrollBehavior css on the element prevents overscroll
      // when  there is a scroll bar.
      if (pdfPaperRefCurrent.scrollHeight === pdfPaperRefCurrent.offsetHeight) {
        e.preventDefault();
      }
    };

    if (pdfPaperRefCurrent) {
      pdfPaperRefCurrent.addEventListener('wheel', (e) => {

        preventOverscroll(e);
      });
    }

    return () => {

      if (pdfPaperRefCurrent) {
        pdfPaperRefCurrent.removeEventListener('wheel', preventOverscroll);
      }
    };
  }, [pdfPaperRefCurrent]);

  const dataUrlToFile = (dataUrl, fileName, mimeType) => {

    const urlSplit = dataUrl.split(',');
    const mime = urlSplit[0].match(/:(.*?);/)[1];
    const bstr = window.atob(urlSplit[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([u8arr], { type: mime });
    return new File([blob], fileName, { type: mimeType });
  };

  const handleSaveFullPageMarkup = useCallback((markupDataUrl) => {

    const projectAttachmentId = pdfAttachment.projectId ? pdfAttachment.id : undefined;
    const sectionAttachmentId = pdfAttachment.sectionId ? pdfAttachment.id : undefined;
    const bidAttachmentId = pdfAttachment.bidId ? pdfAttachment.id : undefined;

    const file = dataUrlToFile(markupDataUrl, 'fullPageMarkup.png', 'image/png');

    if (file) {
      uploadBidAttachment({
        bidId: bid.id,
        attachmentType: api.Project.AttachmentType.FullPageMarkup,
        displayFilename: 'fullPageMarkup.png',
        file,
        parentProjectAttachmentId: projectAttachmentId,
        parentSectionAttachmentId: sectionAttachmentId,
        parentBidAttachmentId: bidAttachmentId,
        pdfAnnotationPageNum: pageNumber
      });
    }
  }, [bid, pageNumber, pdfAttachment, uploadBidAttachment]);

  const onMakerAreaRender = useCallback(async (dataUrl) => {

    if (isSavingMarkup) {
      return;
    }

    setIsSavingMarkup(true);

    if (markupRef.current) {
      const annotationsContext = annotationCanvasRef.current.getContext('2d');

      const newAnnotationsImage = new Image();
      await new Promise((r) => (newAnnotationsImage.onload = r), newAnnotationsImage.src = dataUrl);
      annotationsContext.drawImage(newAnnotationsImage, 0, 0);

      markupRef.current.src = annotationCanvasRef.current.toDataURL('image/png');
      handleSaveFullPageMarkup(annotationCanvasRef.current.toDataURL('image/png'));
    }

    setIsSavingMarkup(false);
  }, [handleSaveFullPageMarkup, isSavingMarkup]);

  const onMarkerAreaClose = useCallback(() => {

    // if a marker is left active after the marker area is closed, the render function called if the save button is
    // clicked will pick it up and save it to the image, even though it's not currently shown.
    if (markerAreaRef.current) {
      markerAreaRef.current.deleteSelectedMarker();
    }

    setIsAnnotating(false);
  }, []);

  const handleSaveButtonClicked = useCallback(async () => {

    if (markerAreaRef.current) {
      const dataUrl = await markerAreaRef.current.render();
      await onMakerAreaRender(dataUrl);
      if (markerAreaRef.current.isOpen) {
        markerAreaRef.current.close();
        onMarkerAreaClose();
      }
    }
  }, [onMakerAreaRender, onMarkerAreaClose]);

  const handleShowSplitView = useCallback(async () => {

    let response = null;

    if (splitViewAttachment.projectId) {
      response = await api.Project.GetProjectAttachmentUrlNoRedirect(splitViewAttachment.id);
    }
    else if (splitViewAttachment.sectionId) {
      response = await api.Project.GetSectionAttachmentUrlNoRedirect(splitViewAttachment.id);
    }
    else if (splitViewAttachment.bidId) {
      response = await api.Project.GetBidAttachmentUrlNoRedirect(splitViewAttachment.id);
    }

    if (response.err) {
      enqueueSnackbar(
        'Error: Could not find document',
        { className: classes.snackbar }
      );
      return;
    }

    onSplitScreenChange(true);
    onPinBidAttachment();
    scrollYWhenOpenedRef.current = window.scrollY;

    const loadingTask = pdfjs.getDocument(response);
    const pdfResult =  await loadingTask.promise;

    setPdf(pdfResult);
    setNumPages(pdfResult.numPages);
  }, [
    classes,
    enqueueSnackbar,
    onPinBidAttachment,
    onSplitScreenChange,
    splitViewAttachment
  ]);

  useEffect(() => {

    if (open && !splitViewAttachment) {
      handleReturnValuesToOriginalState();
    }
    else if (!open && splitViewAttachment) {
      setPdfAttachment(splitViewAttachment);
      handleShowSplitView();
    }
  }, [handleReturnValuesToOriginalState, handleShowSplitView, open, splitViewAttachment]);

  const showMarkerArea = useCallback(() => {

    if (!isAnnotating && markupRef.current !== null && permissions.BidFiles.Upload) {

      const mergedCanvas = document.createElement('canvas');
      mergedCanvas.height = pdfCanvasRef.current.height;
      mergedCanvas.width = pdfCanvasRef.current.width;
      const mergedCanvasContext = mergedCanvas.getContext('2d');

      mergedCanvasContext.drawImage(pdfCanvasRef.current, 0, 0);
      mergedCanvasContext.drawImage(annotationCanvasRef.current, 0, 0);

      markupRef.current.src = mergedCanvas.toDataURL('image/png');

      const markerArea = new markerjs2.MarkerArea(markupRef.current);
      markerArea.targetRoot = markupRef.current.parentElement;
      markerArea.addRenderEventListener(onMakerAreaRender);

      markerArea.addCloseEventListener(onMarkerAreaClose);

      markerArea.renderImageType = 'image/png';
      markerArea.renderMarkersOnly = true;
      markerArea.renderAtNaturalSize = true;
      markerArea.renderImageQuality = 0.7;
      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;
      setIsAnnotating(true);
      markerArea.show();
      markerAreaRef.current = markerArea;
    }
  }, [isAnnotating, onMarkerAreaClose, onMakerAreaRender, permissions]);

  const renderDocument = useCallback(async () => {

    const page = await pdf.getPage(pageNumber);

    let zoomLevel;
    let viewport;
    if (zoomLevelIndexRef.current < 0) {
      zoomLevel = 1.0;
      viewport = page.getViewport({ scale: zoomLevel });

      // optimal zoom level based on a desired width
      zoomLevel = 560.0 / viewport.width;

      const newZoomLevels = [];
      const middleZoomLevelIndex = Math.ceil((TotalZoomLevels - 1) / 2.0);
      for (let i = 1; i <= TotalZoomLevels; ++i) {
        let availableZoomLevel = ((zoomLevel * 1.5) / TotalZoomLevels) * i;
        if (i > middleZoomLevelIndex) {
          availableZoomLevel *= 1 + ((i - middleZoomLevelIndex) * 0.5);
        }

        newZoomLevels.push(availableZoomLevel);
      }

      // make sure the middle zoom level is exactly our optimal zoomLevel from above
      newZoomLevels[middleZoomLevelIndex] = zoomLevel;
      availableZoomLevelsRef.current = newZoomLevels;
      zoomLevelIndexRef.current = middleZoomLevelIndex;
    }
    else {
      const index = Math.max(0, Math.min(availableZoomLevelsRef.current.length - 1, zoomLevelIndexRef.current));
      zoomLevel = availableZoomLevelsRef.current[index];
    }

    viewport = page.getViewport({ scale: availableZoomLevelsRef.current[TotalZoomLevels - 1] });
    const scaledViewport = page.getViewport({ scale: zoomLevel });

    const scaledViewportHeight = `${scaledViewport.height}px`;
    const scaledViewportWidth = `${scaledViewport.width}px`;

    const pdfCanvas = pdfCanvasRef.current;
    const context = pdfCanvas.getContext('2d');

    pdfCanvas.height = viewport.height;
    pdfCanvas.width = viewport.width;
    pdfCanvas.style.height = scaledViewportHeight;
    pdfCanvas.style.width = scaledViewportWidth;

    const renderContext = {
      canvasContext: context,
      viewport
    };

    const renderTask = page.render(renderContext);
    await renderTask.promise;

    annotationCanvasRef.current.height = pdfCanvas.height;
    annotationCanvasRef.current.width = pdfCanvas.width;
    annotationCanvasRef.current.style.height = scaledViewportHeight;
    annotationCanvasRef.current.style.width = scaledViewportWidth;

    const annotationCanvasContext = annotationCanvasRef.current.getContext('2d');
    annotationCanvasContext.clearRect(0, 0, annotationCanvasRef.current.width, annotationCanvasRef.current.height);

    if (annotationAttatchment) {
      const img = new Image();
      img.onload = () => {

        annotationCanvasContext
          .drawImage(img, 0, 0, annotationCanvasRef.current.width, annotationCanvasRef.current.height);
      };

      img.crossOrigin = '';
      img.src = await api.Project.GetBidAttachmentUrlNoRedirect(annotationAttatchment.id);
    }

    markupRef.current.src = annotationCanvasRef.current.toDataURL('image/png');
    markupRef.current.height = pdfCanvas.height;
    markupRef.current.width = pdfCanvas.width;
    markupRef.current.style.height = scaledViewportHeight;
    markupRef.current.style.width = scaledViewportWidth;
    markupRef.current.style.display = 'inline-block';

    setOpen(true);
    onChangeSectionUpdatesLocked(api.Project.SectionLiveUpdatesLockedReason.SplitViewOpen, true);
  }, [annotationAttatchment, onChangeSectionUpdatesLocked, pageNumber, pdf]);

  useEffect(() => {

    if (pdf) {
      renderDocument();
    }

  }, [pdf, renderDocument]);

  const handleZoomInClick = useCallback(() => {

    const zoomLevelIndex = zoomLevelIndexRef.current + 1;
    if (zoomLevelIndex > availableZoomLevelsRef.current.length - 1) {
      return;
    }

    zoomLevelIndexRef.current = zoomLevelIndex;
    setPdfWrapperAlignment(zoomLevelIndex <= Math.ceil((TotalZoomLevels - 1) / 2.0) ? 'center' : 'baseline');
    renderDocument();
  }, [renderDocument]);

  const handleZoomOutClick = useCallback(() => {

    const zoomLevelIndex = zoomLevelIndexRef.current - 1;
    if (zoomLevelIndex < 0) {
      return;
    }

    zoomLevelIndexRef.current = zoomLevelIndex;
    setPdfWrapperAlignment(zoomLevelIndex <= Math.ceil((TotalZoomLevels - 1) / 2.0) ? 'center' : 'baseline');
    renderDocument();
  }, [renderDocument]);

  const handleNextPageClick = useCallback(() => {

    if (pageNumber < numPages) {

      const pageNum = pageNumber + 1;
      setPageNumber(pageNum);
      setAnnotationAttatchment(
        splitViewAttachment?.bidAttachments?.find((att) => att.pdfAnnotationPageNum === pageNum)
      );
    }
    else {
      return;
    }

  }, [pageNumber, numPages, splitViewAttachment]);

  const handlePreviousPageClick = useCallback(() => {

    if (pageNumber > 1) {

      const pageNum = pageNumber - 1;
      setPageNumber(pageNum);
      setAnnotationAttatchment(
        splitViewAttachment?.bidAttachments?.find((att) => att.pdfAnnotationPageNum === pageNum)
      );
    }

  }, [pageNumber, splitViewAttachment]);

  const validatePages = useCallback((event) => {

    const userInput = parseInt(event.target.value);
    if (userInput <= numPages && userInput > 0) {
      setPageNumber(userInput);
      setAnnotationAttatchment(
        splitViewAttachment?.bidAttachments?.find((att) => att.pdfAnnotationPageNum === userInput)
      );
    }

  }, [numPages, splitViewAttachment]);

  const componentOutput = useMemo(() => {

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          // only solution to override inset on modal, setting class styles does not work
          style={{ inset: '50% 10px auto auto' }}
          open={open}
          disableEnforceFocus={true}
          disableScrollLock={true}
          BackdropComponent={Backdrop}
          keepMounted={true} // allows canvasRef.current to be accessed in time
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
        >
          <Fade in={open}>
            <ResizableBox
              height={window.innerHeight - 200}
              width={0.35 * window.innerWidth}
              handle={(handle, handleRef) => (

                <Tooltip title="Drag to resize" placement="left" arrow>
                  <IconButton className={classes.resizeHandle} ref={handleRef} size="small">
                    <DragIndicatorIcon color="action"/>
                  </IconButton>
                </Tooltip>
              )}
              axis="x"
              resizeHandles={['w']}
              minConstraints={[500, 10]}
            >
              <div className={classes.pdfPaper} ref={pdfPaperRef}>
                <div className={classes.toolbar}>
                  <IconButton aria-label="zoom in" onClick={handleZoomInClick}>
                    <ZoomInIcon />
                  </IconButton>
                  <IconButton aria-label="zoom out" onClick={handleZoomOutClick}>
                    <ZoomOutIcon />
                  </IconButton>
                  { permissions.BidFiles.Upload ? (
                    <IconButton aria-label="Annotate" onClick={showMarkerArea}>
                      <BorderColorIcon />
                    </IconButton>
                  ) : null
                  }
                  <Button
                    className={classes.toolbarButton}
                    variant="contained" color="secondary"
                    onClick={handlePreviousPageClick}
                  >
                  Previous
                  </Button>
                  <Button
                    className={classes.toolbarButton}
                    variant="contained" color="secondary"
                    onClick={handleNextPageClick}
                  >
                  Next
                  </Button>
                  <span className={classes.goToPage}>
                    Page
                    <input
                      className={classes.input}
                      type="number"
                      onChange={validatePages}
                      placeholder={1}
                      maxLength="1"
                      min="1"
                      max={numPages}
                      value={pageNumber}
                    />
                    / {numPages}
                  </span>
                  <Button
                    className={classes.toolbarButton}
                    variant="contained" color="secondary"
                    onClick={handleSaveButtonClicked}
                  >
                  Save
                  </Button>
                  <Button
                    className={classes.toolbarButton}
                    variant="contained" color="secondary"
                    onClick={handleReturnValuesToOriginalState}
                  >
                  Close
                  </Button>
                </div>
                <div className={classes.pdfCanvasWrapper} style={{ alignSelf: pdfWrapperAlignment }}>
                  <canvas className={classes.markerJs} ref={annotationCanvasRef} />
                  <img className={classes.markerJs} ref={markupRef} alt="PDF Markup" onClick={showMarkerArea} />
                  <canvas className={classes.pdfCanvas} ref={pdfCanvasRef} />
                </div>
              </div>
            </ResizableBox>
          </Fade>
        </Modal>
      </div>
    );

  }, [
    classes,
    handlePreviousPageClick,
    handleNextPageClick,
    handleReturnValuesToOriginalState,
    handleSaveButtonClicked,
    handleZoomInClick,
    handleZoomOutClick,
    numPages,
    open,
    pageNumber,
    permissions,
    pdfWrapperAlignment,
    showMarkerArea,
    validatePages
  ]);

  return (
    <Fragment>
      {componentOutput}
    </Fragment>
  );
};

export default AttachmentSplitViewDialog;
