"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateCustomWbsCode = void 0;
var _parseCodeNumbers = require("./parseCodeNumbers");
/**
 * Creates a customized wbsCode based on user generated mappings.
 * @param {string} wbsCode - a WBS code string (e.g. "01 02 03")
 * @param {string} separator - a separator string (e.g. "-")
 * @param {WBSCodeCustomizationShape[][]} mappingsMatrix - a 2d array of mappings by their level index [level][mapping]
 * @return {string} - the customized wbs code (e.g. "BLDG-TEST-FLOORING")
 */
var generateCustomWbsCode = function generateCustomWbsCode(wbsCode, separator, mappingsMatrix) {
  if (!wbsCode.length) {
    return wbsCode;
  }
  var newCodeParts = [];
  var parsedCode = (0, _parseCodeNumbers.parseCodeNumbers)(wbsCode);
  for (var level = 0; level < parsedCode.length; level++) {
    var _mappingsMatrix$level;
    var originalCodePart = parsedCode[level];
    var mappingIndex = parseInt(originalCodePart);
    var mapping = isNaN(mappingIndex) || mappingIndex < 0 ? undefined : (_mappingsMatrix$level = mappingsMatrix[level]) === null || _mappingsMatrix$level === void 0 ? void 0 : _mappingsMatrix$level[mappingIndex - 1];
    if (mapping !== null && mapping !== void 0 && mapping.value) {
      newCodeParts.push(mapping.value);
    } else {
      newCodeParts.push(originalCodePart);
    }
  }
  return newCodeParts.join(separator);
};
exports.generateCustomWbsCode = generateCustomWbsCode;