import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pdfjs from '@bundled-es-modules/pdfjs-dist';
import Selection from '@simonwep/selection-js';
import { MarkerArea } from 'markerjs';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const workerSource = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = workerSource;

// exposed to component via this.props.classes
const styles = (theme) => ({
  buttonHolder: {
    height: 60
  },
  markupImage: {
    display: 'none'
  },
  markupOffset: {
    position: 'relative',
    top: '-20px',
    left: '-20px'
  },
  markupPaper: {
    display: 'inline-block',
    padding: 20,
    width: 600,
    height: 600,
    overflow: 'scroll',
    backgroundColor: 'rgb(240, 240, 240)',
    zIndex: '500000'
  },
  markupWrapper: {
    display: 'inline-block',
    verticalAlign: 'top',
    textAlign: 'right',
    marginLeft: 20
  },
  pdfCanvas: {
    maxWidth: 'unset'
  },
  pdfPaper: {
    display: 'inline-block',
    width: 600,
    height: 600,
    padding: 20,
    textAlign: 'center',
    overflow: 'scroll',
    backgroundColor: 'rgb(240, 240, 240)'
  },
  pdfWrapper: {
    display: 'inline-block',
    verticalAlign: 'top'
  },
  root: {
    margin: 20,
    minWidth: 1240
  },
  selection: {
    background: 'rgba(46, 115, 252, 0.11)',
    borderRadius: '0.1em',
    border: '2px solid rgba(98, 155, 255, 0.81)'
  }
});

class SwagPDF extends Component {

  constructor(props) {

    super(props);

    this._isMounted = false;

    this.rootRef = React.createRef();
    this.canvasRef = React.createRef();
    this.markupRef = React.createRef();
    this.markupImageRef = React.createRef();

    this.pdf = null;
    this.markerArea = null;
    this.isRenderingPdf = false;
    this.saveOnMarkerClose = false;

    this.startDrag = {
      x: 0,
      y: 0
    };

    this.availableZoomLevels = [];

    this.state = {
      pageNum: 1,
      zoomLevelIndex: -1,
      isSaveAllowed: false,
      pdfLoaded: false
    };
  }

  componentWillUnmount = () => {

    this._isMounted = false;

    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  componentDidMount = () => {

    // Fix for text not bieng editable in marker.js edit dialog
    this.timer = setInterval(() => {

      const editorOpen = document.getElementsByClassName('markerjs-text-editor').length;
      const swagPdf = document.getElementById('swagpdf');
      if (editorOpen) {
        if (swagPdf.style.display !== 'none') {
          swagPdf.style.display = 'none';
        }
      }
      else {
        if (swagPdf.style.display === 'none') {
          swagPdf.style.display = 'initial';
        }
      }
    }
    , 100);

    this._isMounted = true;

    const requestedPdfSrc = this.props.pdfUrl;
    const requestedPageName = this.props.pageName;
    const requestedPageNumber = this.props.pageNumber;

    if (!_.isEmpty(requestedPdfSrc)) {
      Selection.create({
        class: this.props.classes.selection,
        boundaries: ['.' + this.props.classes.pdfPaper.replace('(', '\\(').replace(')', '\\)')],
        startThreshold: 0,
        selectionAreaContainer: this.rootRef.current
      })
        .on('start', ({ oe }) => {

          this.startDrag.x = oe.offsetX;
          this.startDrag.y = oe.offsetY;
        })
        .on('stop', ({ oe }) => {

          const size = {
            width: oe.offsetX - this.startDrag.x,
            height: oe.offsetY - this.startDrag.y
          };

          this.handlePdfSelect(this.startDrag, size);
        });

      this.fetchPdf(requestedPdfSrc).then(async () => {

        if (!this._isMounted) {
          return;
        }

        const stateUpdates = {};

        if (!_.isEmpty(requestedPageNumber)) {
          stateUpdates.pageNum = requestedPageNumber;
        }

        if (!_.isEmpty(requestedPageName)) {
          const pageNumberForName = await this.getPageNumberForName(requestedPageName);
          if (pageNumberForName > 0) {
            stateUpdates.pageNum = pageNumberForName;
          }
        }

        this.setState(stateUpdates, () => {

          this.renderPage();
        });
      });
    }
  };

  fetchPdf = async (src) => {

    const loadingTask = pdfjs.getDocument(src);

    this.pdf = await loadingTask.promise;
  };

  getPageNumberForName = async (pageName) => {

    if (this.pdf === null) {
      return 0;
    }

    const pageLabels = await this.pdf.getPageLabels();
    if (!_.isEmpty(pageLabels)) {
      const labelIndex = _.indexOf(pageLabels, pageName);
      if (labelIndex >= 0) {
        return labelIndex + 1;
      }
    }

    return 0;
  };

  renderPage = async () => {

    if (this.pdf === null) {
      return;
    }

    this.isRenderingPdf = true;

    const pageNum = Math.max(0, Math.min(this.pdf.numPages, this.state.pageNum));
    const page = await this.pdf.getPage(pageNum);

    let zoomLevelIndex = this.state.zoomLevelIndex;
    let zoomLevel;
    let viewport;
    if (this.state.zoomLevelIndex < 0) {
      zoomLevel = 1.0;
      viewport = page.getViewport({ scale: zoomLevel });

      // optimal zoom level based on a desired width
      zoomLevel = 560.0 / viewport.width;

      this.availableZoomLevels = [];
      const totalZoomLevels = 15; // make sure this is an odd number so we have a zoom level right in the middle
      const middleZoomLevelIndex = Math.ceil((totalZoomLevels - 1) / 2.0);
      for (let i = 1; i <= totalZoomLevels; ++i) {
        let availableZoomLevel = ((zoomLevel * 2.0) / totalZoomLevels) * i;
        if (i > middleZoomLevelIndex) {
          availableZoomLevel *= 1 + ((i - middleZoomLevelIndex) * 0.5);
        }

        this.availableZoomLevels.push(availableZoomLevel);
      }

      // make sure the middle zoom level is exactly our optimal zoomLevel from above
      this.availableZoomLevels[middleZoomLevelIndex] = zoomLevel;

      this.setState({ zoomLevelIndex: middleZoomLevelIndex });
    }
    else {
      zoomLevelIndex = Math.max(0, Math.min(this.availableZoomLevels.length - 1, zoomLevelIndex));
      zoomLevel = this.availableZoomLevels[zoomLevelIndex];
    }

    viewport = page.getViewport({ scale: zoomLevel });

    // Prepare canvas using PDF page dimensions
    const canvas = this.canvasRef.current;

    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport
    };
    const renderTask = page.render(renderContext);

    await renderTask.promise;

    this.isRenderingPdf = false;
    this.setState({ pdfLoaded: true });
  };

  handlePdfSelect = (position, size) => {

    if (size.width < 0) {
      position.x += size.width;
      size.width *= -1;
    }
    else if (size.width === 0) {
      size.width = 1;
    }

    if (size.height < 0) {
      position.y += size.height;
      size.height *= -1;
    }
    else if (size.height === 0) {
      size.height = 1;
    }

    const cropCanvas = document.createElement('canvas');
    const context = cropCanvas.getContext('2d');
    cropCanvas.width = size.width;
    cropCanvas.height = size.height;
    context.drawImage(this.canvasRef.current, position.x, position.y, size.width, size.height, 0, 0, size.width, size.height);

    this.markupImageRef.current.src = cropCanvas.toDataURL();
    this.markupImageRef.current.width = size.width;
    this.markupImageRef.current.height = size.height;
    this.markupImageRef.current.style.display = 'block';

    this.saveOnMarkerClose = false;
    this.closeMarkerArea();

    this.markerArea = new MarkerArea(document.getElementById('markupImage'), {
      targetRoot: this.markupRef.current
    });
    this.markerArea.show((dataUrl) => {

      this.markupImageRef.current.src = dataUrl;
      this.markerArea = null;

      if (this.saveOnMarkerClose) {
        this.saveOnMarkerClose = false;
        this.saveSnippet();
      }
    });

    this.setState({ isSaveAllowed: true });
  };

  closeMarkerArea = () => {

    if (this.markerArea !== null) {
      try {
        this.markerArea.close();
      }
      catch (e) {
        // ignore error -- sometimes marker.js throws an error here because it assumes we're not reusing the marker image
      }
    }
  };

  handleSaveClick = () => {

    if (this.markerArea !== null) {
      this.saveOnMarkerClose = true;
      this.markerArea.complete();
    }
    else {
      this.saveSnippet();
    }
  };

  saveSnippet = () => {

    this.props.onSaveSnippet(this.markupImageRef.current.src);
  };

  handlePreviousPageClick = () => {

    if (this.isRenderingPdf) {
      return;
    }

    const pageNum = this.state.pageNum - 1;
    if (pageNum < 1) {
      return;
    }

    this.setState({ pageNum }, this.renderPage);
  };

  handleNextPageClick = () => {

    if (this.isRenderingPdf) {
      return;
    }

    if (this.pdf === null) {
      return;
    }

    const pageNum = this.state.pageNum + 1;
    if (pageNum > this.pdf.numPages) {
      return;
    }

    this.setState({ pageNum }, this.renderPage);
  };

  handleZoomInClick = () => {

    if (this.isRenderingPdf) {
      return;
    }

    const zoomLevelIndex = this.state.zoomLevelIndex + 1;
    if (zoomLevelIndex > this.availableZoomLevels.length - 1) {
      return;
    }

    this.setState({ zoomLevelIndex }, this.renderPage);
  };

  handleZoomOutClick = () => {

    if (this.isRenderingPdf) {
      return;
    }

    const zoomLevelIndex = this.state.zoomLevelIndex - 1;
    if (zoomLevelIndex < 0) {
      return;
    }

    this.setState({ zoomLevelIndex }, this.renderPage);
  };

  render = () => {

    return (
      <div className={this.props.classes.root} ref={this.rootRef}>
        <div className={this.props.classes.pdfWrapper}>
          <div className={this.props.classes.buttonHolder}>
            <IconButton aria-label="previous page" onClick={this.handlePreviousPageClick}>
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton aria-label="next page" onClick={this.handleNextPageClick}>
              <NavigateNextIcon />
            </IconButton>
            <IconButton aria-label="zoom in" onClick={this.handleZoomInClick}>
              <ZoomInIcon />
            </IconButton>
            <IconButton aria-label="zoom out" onClick={this.handleZoomOutClick}>
              <ZoomOutIcon />
            </IconButton>
          </div>
          <Paper elevation={0} className={this.props.classes.pdfPaper}>
            <canvas
              ref={this.canvasRef}
              className={this.props.classes.pdfCanvas}
              data-cy={ this.state.pdfLoaded ? 'swagpdf-dialog.pdf-loaded' : 'swagpdf-dialog.pdf-not-loaded'}
            />
          </Paper>
        </div>

        <div className={this.props.classes.markupWrapper}>
          <div className={this.props.classes.buttonHolder}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSaveClick}
              disabled={!this.state.isSaveAllowed}
              data-cy="swagpdf-dialog.save-button"
            >
              Save
            </Button>
          </div>
          <Paper elevation={0} className={this.props.classes.markupPaper}>
            <div ref={this.markupRef} className={this.props.classes.markupOffset} />
            <img
              id="markupImage"
              ref={this.markupImageRef}
              alt="snippet"
              className={this.props.classes.markupImage}
              data-cy="swagpdf-dialog.marker-area"
            />
          </Paper>
        </div>
      </div>
    );
  };

}

const mapStateToProps = (state, ownProps) => {

  return {
    //
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    //
  };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SwagPDF)));
