import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import FullScreenDialog from '../Dialogs/FullScreenDialog';

const useStyles = makeStyles((theme) => ({
  closeToDue: {
    color: theme.palette.app.errorText
  },
  projectRow: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.875rem',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,

    '&.odd': {
      backgroundColor: theme.palette.primary.light + '80'
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'A0',
      borderRadius: '5px'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    width: '100%'
  },
  rowHalf: {
    width: 'calc(50% - 10px)',
    textAlign: 'left',

    '&.alignRight': {
      textAlign: 'right'
    }
  }
}));

const RecentProjects = (props) => {

  const classes = useStyles();
  const history = useHistory();

  const {
    isFullScreen,
    onCloseFullScreen,
    projects
  } = props;

  const handleProjectRowClick = useCallback((evt) => {

    const projectId = evt.currentTarget.dataset.projectId;
    history.push(`/projects/${projectId}`);
  }, [history]);

  const projectsList = useMemo(() => {

    if (!projects) {
      return null;
    }

    return projects.map((project, index) => {

      const dueDateClass = (project.isCloseToDue) ? classes.closeToDue : undefined;
      const rowBackgroundClass = (index % 2 !== 0) ? ' odd' : '';

      return (
        <div
          key={project.id}
          className={classes.projectRow + rowBackgroundClass}
          data-project-id={project.id}
          data-cy="recent-projects-list.project-row"
          onClick={handleProjectRowClick}
        >
          <u className={classes.rowHalf}>{project.name}</u>
          <span className={classes.rowHalf + ' ' + dueDateClass + ' alignRight'}>{project.dueDate}</span>
        </div>
      );
    });
  }, [classes, handleProjectRowClick, projects]);

  const componentOutput = useMemo(() => {

    return (
      <div
        className={classes.root}
        data-cy="recent-projects-list"
      >
        {projectsList}
      </div>
    );
  }, [
    classes,
    projectsList
  ]);

  return useMemo(() => {

    if (isFullScreen) {
      return (
        <FullScreenDialog
          title="Recent Projects"
          content={componentOutput}
          onClose={onCloseFullScreen}
          open={true}
        />
      );
    }

    return componentOutput;

  }, [componentOutput, isFullScreen, onCloseFullScreen]);
};

export default RecentProjects;
