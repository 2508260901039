import React, { useMemo } from 'react';

import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';

interface Props {
  suggestions?: { [index: string]: string[] },
  onClick: (suggestedWord: string) => void,
  potentialMisspelledWord?: string
}

const SpellCheckMenuOptions = (props: Props) => {

  const { suggestions, onClick, potentialMisspelledWord } = props;

  return useMemo(() => {

    if (_.isEmpty(suggestions) || !potentialMisspelledWord || !suggestions[potentialMisspelledWord]) {
      return null;
    }

    const menuOptions = [];
    let index = 0;

    for (const suggestedWord of suggestions[potentialMisspelledWord]) {
      index++;
      menuOptions.push(

        <MenuItem data-cy='spell-check.option' onClick={() => onClick(suggestedWord)} key={index}>
          "{potentialMisspelledWord}" to "{suggestedWord}"
        </MenuItem>
      );
    }

    return <>{menuOptions}</>;
  }, [potentialMisspelledWord, suggestions, onClick]);
};

export default SpellCheckMenuOptions;
