import React from 'react';

import { ReactComponent as Svg } from './logo_relay_source.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {

  return (
    <SvgIcon component={Svg} viewBox="0 0 402 128" style={{ width: 'auto', height: '33px' }} />
  );
};
