import React, { useMemo } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import _ from 'lodash';

import ActivityLogInvite from './ActivityLogInvite';
import ActivityLogItemMessage from './ActivityLogItemMessage';
import ActivityLogQuickNote from './ActivityLogQuickNote';

import List from '@material-ui/core/List';
import { Link as BrowserLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { ActivityLogCategory } from '../../types/enums/ActivityLogCategory';
import { ProjectPermissionInterface } from '../../PermissionInterface';
import { ProjectDefaultPermissionStructure } from '../../DefaultPermissionStructure';
import ActivityLogSubmitScope from './ActivityLogSubmitScope';

interface Props {
  activityItems: any[]; // TODO: flesh this out
  cust?: object;
  filters: any[];
  groupInvitesByCompany?: boolean;
  headerComponent?: React.ReactElement;
  onDeleteEstimatorInvite?: () => void;
  onRespondToEstimatorInvite?: () => void;
  styleOverrides?: React.CSSProperties;
  selectedHeader?: string;
  permissions?: ProjectPermissionInterface;
}

const ActivityList = (props: Props) => {

  const {
    activityItems,
    cust,
    filters = [],
    headerComponent,
    groupInvitesByCompany,
    onDeleteEstimatorInvite,
    onRespondToEstimatorInvite,
    styleOverrides = {},
    selectedHeader,
    permissions = ProjectDefaultPermissionStructure
  } = props;

  const loggedInUserDetails = useSelector((state: RootStateOrAny) => state.ephemeralState.loggedInUserDetails);

  const listItems = useMemo((): React.ReactElement[] => {

    const items = [];

    if (headerComponent) {
      items.push(headerComponent);
    }

    let groupedItems = activityItems;
    if (groupInvitesByCompany && filters && filters.indexOf(ActivityLogCategory.Invite) >= 0) {
      groupedItems = [];
      const seenInvites: { [index: string]: any } = {};

      for (const activityItem of (activityItems || [])) {
        if (activityItem.category === ActivityLogCategory.Invite && activityItem.projectId) {
          const inviteKey = `${activityItem.toCompanyId}_${activityItem.projectId}`;
          if (!seenInvites[inviteKey]) {
            activityItem.numDuplicates = 1;
            seenInvites[inviteKey] = activityItem;
            groupedItems.push(activityItem);
          }
          else {
            seenInvites[inviteKey].numDuplicates = (seenInvites[inviteKey].numDuplicates ?? 1) + 1;
          }
        }
        else {
          groupedItems.push(activityItem);
        }
      }
    }

    _.each(groupedItems, (activityItem) => {

      if (
        activityItem.category === ActivityLogCategory.ItemMessage &&
        filters?.indexOf(ActivityLogCategory.ItemMessage) >= 0
      ) {

        items.push(
          <ActivityLogItemMessage
            activityProjectId={activityItem.projectId}
            bidCompanyId={activityItem.bidCompanyId}
            bidCompanyName={activityItem.bidCompanyName}
            bidId={activityItem.bidId}
            content={activityItem.content}
            divisionLabel={activityItem.divisionLabel}
            divisionName={activityItem.divisionName}
            divisionNumber={activityItem.divisionNumber}
            fromUserCompanyId={activityItem.fromUserCompanyId}
            fromUserCompanyName={activityItem.fromUserCompanyName}
            fromUserFullName={activityItem.fromUserFullName}
            key={activityItem.id}
            projectCompanyId={activityItem.projectCompanyId}
            projectCompanyName={activityItem.projectCompanyName}
            projectName={activityItem.projectName}
            sectionArea={activityItem.sectionArea}
            sectionId={activityItem.sectionId}
            sectionItemTitle={activityItem.sectionItemTitle}
            sectionTitle={activityItem.sectionTitle}
          />
        );
      }
      else if (
        [ActivityLogCategory.Invite, ActivityLogCategory.AcceptInvite, ActivityLogCategory.DeclineInvite]
          .includes(activityItem.category) &&
        filters?.indexOf(ActivityLogCategory.Invite) >= 0
      ) {

        items.push(
          <ActivityLogInvite
            activityProjectId={activityItem.projectId}
            category={activityItem.category}
            cust={cust}
            divisionId={activityItem.divisionId}
            divisionLabel={activityItem.divisionLabel}
            divisionName={activityItem.divisionName}
            divisionNumber={activityItem.divisionNumber}
            fromUserCompanyId={activityItem.fromUserCompanyId}
            fromUserFullName={activityItem.fromUserFullName}
            inviteId={activityItem.invitationId}
            isAccepted={activityItem.invitationIsAccepted}
            isForCurrentUser={activityItem.toUserId === loggedInUserDetails.id}
            isForInvitedCompany={activityItem.toCompanyId === loggedInUserDetails.primaryCompanyId}
            isFromUser={activityItem.fromUserId === loggedInUserDetails.id}
            key={activityItem.id}
            numDuplicates={activityItem.numDuplicates}
            onDeleteEstimatorInvite={onDeleteEstimatorInvite}
            onRespondToEstimatorInvite={onRespondToEstimatorInvite}
            projectName={activityItem.projectName}
            permissions={permissions}
            sectionArea={activityItem.sectionArea}
            sectionId={activityItem.sectionId}
            sectionTitle={activityItem.sectionTitle}
            toCompanyId={activityItem.toCompanyId}
            toCompanyName={activityItem.toCompanyName}
            toUserCompanyId={activityItem.toUserCompanyId}
            toUserFullName={activityItem.toUserFullName}
            type={activityItem.invitationType}
          />
        );
      }
      else if (
        activityItem.category === ActivityLogCategory.QuickNote &&
        filters?.indexOf(ActivityLogCategory.QuickNote) >= 0
      ) {

        let content = activityItem.content;
        // Manually added quick notes will not have a section item id, but auto-added quick notes such as earned value notes
        // will contain a relation to a section item and need to be displayed with the proper project info and hyperlinks.
        if (activityItem.sectionItemId !== null) {
          const link = `/projects/${activityItem.projectId}/section/${activityItem.sectionId}`;
          const relevantAccountingCodeText = (activityItem.relevantAccountingCode) ? `(${activityItem.relevantAccountingCode})` : '';
          const projectInfoContent = (
            `${activityItem.divisionLabel || activityItem.divisionNumber} - ${activityItem.divisionName || 'Untitled'} - ` +
            `${activityItem.sectionArea || 'Unlabeled'} - ${activityItem.sectionTitle || 'Untitled'} - ` +
            `${activityItem.sectionItemTitle || 'Untitled'} ${relevantAccountingCodeText} `
          );

          content = (
            <>
              <Link
                component={BrowserLink}
                to={link}
                variant="body2"
                color="textPrimary"
                style={{ textDecoration: 'underline' }}
              >
                {projectInfoContent}
              </Link>
              {activityItem.content}
            </>
          );
        }

        items.push(
          <ActivityLogQuickNote
            key={activityItem.id}
            content={content}
            fromUserCompanyId={activityItem.fromUserCompanyId}
            fromUserCompanyName={activityItem.fromUserCompanyName}
            fromUserId={activityItem.fromUserId}
            fromUserFullName={activityItem.fromUserFullName}
            itemId={activityItem.id}
            selectedHeader={selectedHeader}
            permissions={permissions}
          />
        );
      }
      else if (
        activityItem.category === ActivityLogCategory.SubmitScope &&
        filters?.indexOf(ActivityLogCategory.SubmitScope) >= 0
      ) {
        items.push(
          <ActivityLogSubmitScope
            activityProjectId={activityItem.projectId}
            divisionId={activityItem.divisionId}
            divisionName={activityItem.divisionName}
            fromUserFullName={activityItem.fromUserFullName}
            fromUserCompanyId={activityItem.fromUserCompanyId}
            sectionId={activityItem.sectionId}
            sectionTitle={activityItem.sectionTitle}
          />
        );
      }
    });

    return items;
  }, [
    activityItems,
    cust,
    filters,
    groupInvitesByCompany,
    headerComponent,
    loggedInUserDetails,
    onDeleteEstimatorInvite,
    onRespondToEstimatorInvite,
    permissions,
    selectedHeader
  ]);


  return useMemo(() => {

    const style: React.CSSProperties = {
      wordBreak: 'break-word',
      maxHeight: 'calc(100vh - 200px)',
      maxWidth: 'calc(100vw - 200px)',
      overflowY: 'auto',
      paddingLeft: '8px',
      ...styleOverrides
    };

    return (
      <List style={style}>
        {listItems}
      </List>
    );
  }, [listItems, styleOverrides]);
};

export default ActivityList;
