import _ from 'lodash';
import { CustShape } from '../types/api/CustShape';
import { DivisionShape } from '../types/api/DivisionShape';
import { DummyDivisionShape } from '../types/api/DummyDivisionShape';
import { DummySectionShape } from '../types/api/DummySectionShape';
import { SectionShape } from '../types/api/SectionShape';

export const separateCamelcaseWords = (string: string) => {

  console.warn('separateCamelcaseWord has no tests written for it as it is currently unused');
  return string.replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => {

      return str.toUpperCase().trim();
    });
};

export const makeDivisionDisplayName = (cust?: CustShape, division?: DivisionShape | DummyDivisionShape) => {

  const custName = (cust?.division) ? (cust.division + ' ') : '';
  const labelOrNum = (division?.label) ? (division.label + ' ') : (division?.number) ? (division.number + ' ') : '';
  const divTitle = division?.name || 'Untitled';
  return `${custName}${labelOrNum}"${divTitle}"`;
};

export const makeSectionDisplayName = (
  cust: CustShape | undefined,
  section: SectionShape | DummySectionShape,
  index?: number,
  skipNumber?: boolean
) => {

  let output = (cust) ? (cust.area + ' ') : '';
  if (!skipNumber) {
    output += `${_.padStart(((index || 0) + 1).toString(), 2, '0')} - `;
  }

  return  output + `${(section.title?.length) ? `${section.title} - ` : ''}` +
         `${(section.area?.length) ? section.area : ''}`;
};

export const equalIfDefinedElse = (defaultValue?: boolean) => {

  if (defaultValue === undefined) {
    defaultValue = true;
  }

  return (a: any, b: any) => {

    return (a === undefined || b === undefined) ?
      defaultValue :
      _.isEqual(a,b);
  };
};
