import { WBSMetadataShape } from 'wbs/dist/types/WBSMetadataShape';
import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';
import { getRowLevel } from 'wbs/dist/getRowLevel';
import { DivisionNameMaxLength } from '../../consts/Division';
import { SectionClassificationMaxLength, SectionTitleMaxLength } from '../../consts/Section';
import { SectionItemTitleMaxLength } from '../../consts/SectionItem';
import { CompanyLimitationsShape } from '../../types/api/CompanyLimitationsShape';
import _ from 'lodash';

export const getMaxTitleLengthForLevel = (
  metadata: WBSMetadataShape | undefined,
  companyLimitations: CompanyLimitationsShape,
  level: number
) => {

  if (
    level <= 0 ||
    !metadata ||
    !metadata.divisionLevel ||
    !metadata.areaLevel ||
    !metadata.classificationLevel ||
    !metadata.itemLevel
  ) {
    return Infinity;
  }

  const maxItemTitleLength = _.defaultTo(companyLimitations.maxItemTitleLength, SectionItemTitleMaxLength);
  const levelByMaxTitleLength = {
    [metadata.divisionLevel]: DivisionNameMaxLength,
    [metadata.areaLevel]: SectionTitleMaxLength,
    [metadata.classificationLevel]: SectionClassificationMaxLength,
    [metadata.itemLevel]: maxItemTitleLength
  };

  const foundMaxTitleLength = levelByMaxTitleLength[level];
  if (foundMaxTitleLength) {
    return foundMaxTitleLength;
  }
  else if (level > metadata.itemLevel) {
    // this can happen for child item levels greater than the regular item level (should still use the item title max)
    return maxItemTitleLength;
  }

  return Infinity;
};

export const getIsRowTitleValid = (
  metadata: WBSMetadataShape | undefined,
  wbsDataRow: WBSDataRowShape,
  companyLimitations: CompanyLimitationsShape
) => {

  const level = getRowLevel(wbsDataRow);
  const maxTitleLengthForLevel = getMaxTitleLengthForLevel(metadata, companyLimitations, level);
  return (wbsDataRow.name.length <= maxTitleLengthForLevel);
};

export const getIsRowTitleValidAndMaxTitleLength = (
  metadata: WBSMetadataShape | undefined,
  wbsDataRow: WBSDataRowShape,
  companyLimitations: CompanyLimitationsShape
) => {

  const level = getRowLevel(wbsDataRow);
  const maxTitleLength = getMaxTitleLengthForLevel(metadata, companyLimitations, level);
  return { isTitleValid: wbsDataRow.name.length <= maxTitleLength, maxTitleLength };
};

