import { ChangeEvent, MouseEvent, KeyboardEvent, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { connect, MapDispatchToProps, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import _ from 'lodash';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import * as actions from '../../redux/mainActions';
import { ActivityLogCategory } from '../../types/enums/ActivityLogCategory';
import api from '../../api';
import { AppDispatch } from '../../redux/store';
import { AppTheme } from '../../theme-relay';
import { CostManagementDashboardApiSlice } from '../../api/costManagementDashboard';
import { RecentDashboardShape } from '../../types/api/CostManagementDashboardShape';
import { RecentProjectShape } from '../../types/api/ProjectShape';
import { RootState } from '../../redux/reducers';
import { UserShape } from '../../types/api/UserShape';

import Backdrop from '@material-ui/core/Backdrop';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { ReactComponent as ActivityAwardsIcon } from '../../assets/images/icon_sidebar_awards.svg';
import { ReactComponent as ActivityInvitesIcon } from '../../assets/images/icon_sidebar_invites.svg';
import { ReactComponent as ActivityMessagesIcon } from '../../assets/images/icon_sidebar_messages.svg';
import { ReactComponent as ContactsIcon } from '../../assets/images/icon_sidebar_contacts.svg';
import CostManagementGraph from '../Icons/CostManagementGraph';
import { ReactComponent as ProjectsIcon } from '../../assets/images/icon_sidebar_projects.svg';
import { ReactComponent as ReportsIcon } from '../../assets/images/icon_sidebar_reports.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/icon_sidebar_settings.svg';
import SearchIcon from '../Icons/Search';
import SmallAvatar from '../Icons/SmallAvatar';

import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import baseApi  from '../../api/baseApi';
import { getCompanyPermissions } from '../../helpers/getCompanyPermissions';

interface Message {
  activity: [];
}

const TransitionDuration = '195ms';

const useStyles = makeStyles((theme: AppTheme) => ({

  accountMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '0px',
    overflow: 'hidden',
    backgroundColor: theme.palette.app.innerBorder + '40', // 25% opacity

    '& div': {
      color: theme.palette.sidebar.defaultText,
      fontSize: '1rem',
      margin: '2px 0px',
      cursor: 'pointer',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',

      '&:hover': {
        color: theme.palette.app.innerBorder
      },

      transition: theme.transitions.create('color', {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    '&.open': {
      height: 30
    },

    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    })
  },
  accountMenuButton: {
    color: theme.palette.sidebar.defaultText,
    backgroundColor: 'transparent',
    borderColor: theme.palette.sidebar.defaultText,
    borderWidth: '2px',
    borderStyle: 'solid',
    width: 24,
    height: 24,
    '& svg': {
      width: '0.75em',
      height: '0.75em'
    }
  },
  accountWrapper: {
    overflow: 'hidden',

    '&.hidden': {
      pointerEvents: 'none'
    }
  },
  activityLink: {
    cursor: 'pointer'
  },
  activityNumUnseen: {
    backgroundColor: theme.palette.app.innerBorder,
    borderRadius: 5,
    color: theme.palette.sidebar.background,
    marginRight: theme.spacing(6),
    minWidth: 20,
    padding: `0 4px`,
    textAlign: 'center'
  },
  avatarWrapper: {
    padding: 2
  },
  backdrop: {
    zIndex: 1000
  },
  companyLogo: {
    alignItems: 'center',
    color: theme.palette.sidebar.defaultText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    width: '100%',

    '& .title': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      display: 'block',
      width: 260,

      '&.closed': {
        opacity: 0,
        width: 0
      },

      transition: theme.transitions.create(['width', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    '& img': {
      borderRadius: 5,
      width: 64,

      '&.closed': {
        width: 0,
        opacity: 0
      },

      transition: theme.transitions.create(['filter', 'opacity', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    '&:hover': {
      color: theme.palette.app.innerBorder,

      '& img': {
        filter: 'brightness(125%)'
      }
    },

    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    })
  },
  companyLogoImage: {
    borderRadius: 5
  },
  closeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.app.innerBorder,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '24px',
    width: '48px',
    minWidth: '48px',
    height: '48px',
    minHeight: '48px',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    opacity: 1,

    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    })
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
    height: '1px',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.07)',

    '&.halfHeight': {
      marginTop: '5px',
      marginBottom: '5px'
    },

    '&.invisible': {
      visibility: 'hidden'
    }
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1001,
    width: '260px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.sidebar.background,

    transition: theme.transitions.create(['width', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    }),

    '&.searching': {
      width: '400px'
    },

    '&.closed': {
      width: '0px',

      '& $closeButton': {
        opacity: 0
      },

      '& $footer': {
        padding: '0px 0px',

        '& svg': {
          ...(theme.css.sidebar.closed.footer.svg || {})
        },

        '& .title': {
          opacity: 0
        }
      },

      '& $hamburgerIcon': {
        position: 'absolute',
        left: 'calc(50% - 24px)',
        top: '8px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '24px',
        width: '48px',
        minWidth: '48px',
        height: '48px',
        minHeight: '48px'
      },

      '& $search': {
        height: '0px'
      },

      '& $topLevelItem': {

        '& .title, .more': {
          opacity: 0
        }
      },

      '& $listIcon': {
        margin: '4px auto'
      }
    },

    '&.hidden': {
      width: '0px'
    }
  },
  flexSpacer: {
    flexGrow: 1
  },
  footer: {
    position: 'relative',
    width: '100%',
    height: '80px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 36px',
    background: theme.palette.app.innerBorder,
    color: theme.palette.sidebar.titleText,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightDark,
    textTransform: 'uppercase',

    '& svg': {
      position: 'absolute',
      bottom: '12px',
      height: '38px',
      overflow: 'hidden',

      transition: theme.transitions.create(['clip', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      }),

      ...(theme.css.sidebar.footer.svg || {})
    },

    '& .title': {
      position: 'absolute',
      top: '8px',
      opacity: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
      textAlign: 'center',

      transition: theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    })
  },
  hamburgerIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.toolbar.icon,
    backgroundColor: theme.palette.app.innerBorder,
    justifyContent: 'center',
    position: 'fixed',
    left: '12px',
    top: '10px',
    width: '42px',
    height: '42px',
    cursor: 'pointer',
    borderRadius: '24px',
    zIndex: 1001,

    '&:hover': {
      backgroundColor: theme.palette.app.innerBorder
    }
  },
  header: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    '& .top': {
      display: 'flex',
      alignItems: 'center',
      height: '64px',
      overflow: 'hidden',
      marginBottom: '-13px',

      '& .companyName': {
        flexGrow: 1,
        fontFamily: theme.typography.headerFontFamily,
        fontWeight: theme.typography.fontWeightDark,
        overflow: 'hidden',

        '& img': {
          width: '100%',
          height: '50px',
          objectFit: 'contain'
        }
      }
    }
  },
  listIcon: {
    width: '18px',
    height: '18px',
    marginLeft: '-28px',
    marginRight: '10px',
    color: theme.palette.toolbar.icon,

    '& path': {
      fill: 'rgba(255, 255, 255, 0.75)'
    }
  },
  projectLink: {
    cursor: 'pointer'
  },
  search: {
    height: '42px',
    overflow: 'hidden',

    '& div': {
      height: '100%'
    },

    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    })
  },
  searchField: {
    backgroundColor: theme.palette.toolbar.grandTotalBackground,
    border: `2px solid ${theme.palette.app.defaultText}`,
    borderRadius: '10px',
    fontSize: '1rem',
    height: 'calc(100% - 4px)',
    margin: '0px 8px',
    padding: '0px 4px',
    color: 'white'
  },
  searchFieldFocused: {
    borderColor: theme.palette.secondary.light,

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `none`
      }
    }
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    minWidth: 160
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1)
  },
  topLevelHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    overflow: 'hidden',
    height: '30px',
    cursor: 'pointer',

    '& .title': {
      alignItems: 'center',
      color: theme.palette.sidebar.defaultText,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      overflow: 'hidden',
      marginLeft: 6,
      paddingLeft: 6,
      fontFamily: theme.typography.headerFontFamily,
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightLight,
      whiteSpace: 'nowrap',

      '&:not(.selected):hover': {
        color: theme.palette.app.innerBorder
      },

      transition: theme.transitions.create(['color', 'width', 'margin', 'padding', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    '& .selected': {
      backgroundColor: theme.palette.app.innerBorder,
      borderRadius: 5,
      color: theme.palette.sidebar.titleText
    }
  },
  topLevelIcon: {
    width: '22px',
    minWidth: '22px',
    height: '22px',
    minHeight: '22px',

    '&.extraLeftMargin': {
      marginLeft: theme.spacing(1)
    }
  },
  topLevelItem: {
    marginTop: '5px',
    marginBottom: '5px',
    width: 'calc(100% - 16px)',
    overflow: 'hidden',

    transition: theme.transitions.create(['height', 'width', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    })
  },
  topLevelList: {
    overflow: 'hidden',
    color: theme.palette.sidebar.defaultText,
    padding: '12px 8px 4px 48px',

    transition: theme.transitions.create(['height', 'opacity', 'padding', 'margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: TransitionDuration
    }),

    '& div': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightLight,
      padding: '2px',

      transition: theme.transitions.create(['padding'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    '& .title': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',

      '&:not(.selected):hover': {
        color: theme.palette.app.innerBorder
      },

      transition: theme.transitions.create(['color', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: TransitionDuration
      })
    },

    '& .selected': {
      backgroundColor: theme.palette.app.innerBorder,
      borderRadius: 5,
      color: theme.palette.sidebar.titleText
    }
  }
}));

const mapStateToProps = (state: RootState) => {

  return {
    loggedInUserDetails: state.ephemeralState.loggedInUserDetails
  };
};

// TODO: flesh out this any
const mapDispatchToProps: MapDispatchToProps<any, any> = (dispatch: AppDispatch) => {

  return {
    logOut: () => {

      api.User.LogOut();
      dispatch(baseApi.util.resetApiState());
      dispatch(actions.setIsLoggedIn(false));
    }
  };
};

interface Props extends RouteComponentProps {
  loggedInUserDetails: UserShape | null,
  logOut: () => void
}

const Sidebar = connect(mapStateToProps, mapDispatchToProps)((props: Props) => {

  const classes = useStyles();
  const theme: AppTheme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const {
    logOut
  } = props;

  const loggedInUserDetails: UserShape | { [index: string]: any } =
    useMemo(() => (props.loggedInUserDetails || {}), [props.loggedInUserDetails]);

  const companyPermissions = getCompanyPermissions(props.loggedInUserDetails);
  const isWbsEnabled = useSelector((state: RootState) => state.project.companySettings.isWbsEnabled);

  const getOriginalInputs = useCallback(() => {

    return { search: '' };
  }, []);

  const originalUnseenActivity = useMemo(() => {

    const output: { [index: string]: number } = {};
    for (const value of Object.values(ActivityLogCategory)) {
      output[value] = 0;
    }

    return output;
  }, []);

  const [inputs, setInputs] = useState(getOriginalInputs());
  const [isOpen, setIsOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [recentDashboards, setRecentDashboards] = useState<RecentDashboardShape[]>([]);
  const [recentProjects, setRecentProjects] = useState<RecentProjectShape[]>([]);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [unseenActivity, setUnseenActivity] = useState(originalUnseenActivity);

  const [addDashboard] = CostManagementDashboardApiSlice.useAddDashboardMutation();

  const requestRecentlyViewedItems = useCallback(() => {

    api.User.GetRecentItems()
      .then((result) => {

        if (result.err) {
          // TODO: error out somehow
          console.error(result.err);
        }
        else {
          setRecentDashboards(result.res.body.costManagmentDashboards);
          setRecentProjects(result.res.body.projects);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {

    requestRecentlyViewedItems();
  }, [requestRecentlyViewedItems]);

  const handleIncomingPostMessage = useCallback((evt: MessageEvent) => {

    if (evt.origin !== window.origin || !evt.data?.type) {
      return;
    }

    if (evt.data.type === api.General.PostMessageTypes.ProjectVisited) {
      const visitedProject: RecentProjectShape = evt.data.data;

      setRecentProjects((prev) => {

        const updatedRecentProjects = [...prev];
        const existingProjectIndex = prev.findIndex((p) => p.id === visitedProject.id);

        if (existingProjectIndex === -1) {
          updatedRecentProjects.pop();
          updatedRecentProjects.unshift(visitedProject);
        }
        else {
          updatedRecentProjects[existingProjectIndex] = visitedProject;

          if (existingProjectIndex !== 0) {
            updatedRecentProjects.unshift(updatedRecentProjects.splice(existingProjectIndex, 1)[0]);
          }
        }

        return updatedRecentProjects;
      });
    }

    else if (evt.data.type === api.General.PostMessageTypes.DashboardVisited) {
      const visitedDashboard: RecentDashboardShape = evt.data.data;

      setRecentDashboards((prev) => {

        const updatedRecentDashboards = [...prev];
        const existingDashboardIndex = prev.findIndex((p) => p.id === visitedDashboard.id);

        if (existingDashboardIndex === -1) {
          if (updatedRecentDashboards.length >= 5) {
            updatedRecentDashboards.pop();
          }

          updatedRecentDashboards.unshift(visitedDashboard);
        }
        else {
          updatedRecentDashboards[existingDashboardIndex] = visitedDashboard;

          if (existingDashboardIndex !== 0) {
            updatedRecentDashboards.unshift(updatedRecentDashboards.splice(existingDashboardIndex, 1)[0]);
          }
        }

        return updatedRecentDashboards;
      });
    }
  }, []);

  useEffect(() => {

    window.addEventListener('message', handleIncomingPostMessage);

    return () => {

      window.removeEventListener('message', handleIncomingPostMessage);
    };
  }, [handleIncomingPostMessage]);

  const performSearch = useCallback(() => {

    setIsOpen(false);
    history.push('/search/' + inputs.search);
  }, [history, inputs.search]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {

    e.preventDefault();

    const value = e.target.value;

    setInputs({ ...inputs, search: value });
  }, [inputs]);

  const handleKeyPress = useCallback((e: KeyboardEvent) => {

    if (e.key === 'Enter') {
      performSearch();
    }
  }, [performSearch]);

  const handleSearchBlur = useCallback(() => setIsSearching(false), []);

  const handleSearchFocus = useCallback(() => setIsSearching(true), []);

  const checkUnseenActivity = useCallback(async () => {

    const result = (await api.Activity.UnseenActivity())?.res?.body;

    if (result) {
      setUnseenActivity(result);
    }

    return result;
  }, []);

  useEffect(() => {

    checkUnseenActivity();
  }, [checkUnseenActivity]);

  const handleAddAndVisitNewDashboard = useCallback(() => {

    setIsOpen(false);
    addDashboard({ title: '' }).then((result) => {

      const newDashboardId = (result as { data?: any }).data?.id;
      if (newDashboardId) {
        history.push(`/cmdashboards/${newDashboardId}`);
      }
    });
  }, [addDashboard, history]);


  // this is necessary to keep onRealtimeActivity from being regenerated every time it is called, which in turn would
  // cause the useEffect below that has api.Realtime.Subscribe/Unsubscribe to unsubscribe and re-subscribe every time
  // the activity log changes (e.g. when a quick note is added).  And since the subscribe/unsubscribe api calls do not
  // wait on each other in the useEffect, if the useEffect return function is called immediately after the useEffect,
  // the subscribe/unsubscribe calls can get out of order, causing the page to permanently unsubscribe from the WS
  const activityLogRef = useRef({ activity: [] });
  activityLogRef.current = { activity: [] };

  const onRealtimeActivity = useCallback((message: Message) => {

    if (message?.activity?.length) {

      setUnseenActivity((prev) => {

        const newUnseenActivity = { ...prev };
        for (const { category, id, fromUserId } of message.activity) {

          if (fromUserId === loggedInUserDetails.id) {
            continue;
          }

          newUnseenActivity[category]++;
          newUnseenActivity[category + 'LastId'] = Math.max(id, newUnseenActivity[category + 'LastId'] || -1);
        }

        return newUnseenActivity;
      });
    }
  }, [loggedInUserDetails]);

  useEffect(() => {

    api.Realtime.Subscribe('/activity', onRealtimeActivity);

    return () => {

      api.Realtime.Unsubscribe('/activity', onRealtimeActivity);
    };
  }, [onRealtimeActivity]);

  const onCloseClick = useCallback(() => {

    setIsOpen(false);
  }, []);

  const onHamburgerClick = useCallback(() => {

    setIsOpen(true);
  }, []);

  const toggleAccountMenu = useCallback(() => {

    setShowAccountMenu(!showAccountMenu);
  }, [showAccountMenu]);

  const handleClickLogOut = useCallback(() => {

    logOut();
    history.push('/');
  }, [history, logOut]);

  const handleLinkClicked = useCallback(async (event: MouseEvent<HTMLElement>) => {

    const targetURL = event.currentTarget.dataset.targeturl as string;
    const activityCategory = event.currentTarget.dataset.activityCategory;

    if (activityCategory) {
      if (unseenActivity[activityCategory + 'LastId']) {
        await api.User.EditLastActivitySeen(activityCategory, unseenActivity[activityCategory + 'LastId']);
      }

      setUnseenActivity((prev) => ({ ...prev, [activityCategory]: 0 }));
    }

    setIsOpen(false);
    history.push(targetURL);
  }, [unseenActivity, history]);

  const accountMenuUI = useMemo(() => {

    const open = (isOpen) ? ' open' : '';

    return (
      <div className={classes.accountMenu + open}>
        <div>
          <div onClick={handleClickLogOut}  data-cy="sidebar.drawer.account.logout">
            Log Out
          </div>
        </div>
      </div>
    );
  }, [classes, handleClickLogOut, isOpen]);

  const costManagementDashboardSubItems = useMemo(() => {

    const pathname = location.pathname;

    const list = [];

    if (companyPermissions && companyPermissions.WBS?.CanModifyDashboard) {
      const targetUrl = '/cmdashboards/?create=true';
      const titleClass = clsx('title', pathname === targetUrl && ' selected');
      list.push(
        <div
          key="newButton"
          data-cy="sidebar.dashboard.add-new-button"
          onClick={handleAddAndVisitNewDashboard}
          className={classes.projectLink}
        >
          <div className={titleClass}>[+] Add New Dashboard</div>
        </div>
      );
    }

    for (const dashboard of recentDashboards) {
      const targetUrl = `/cmdashboards/${dashboard.id}`;
      const titleClass = clsx('title', pathname === targetUrl && ' selected');
      list.push(
        <div
          key={dashboard.id}
          data-targeturl={targetUrl}
          data-cy={'sidebar.dashboard.' + dashboard.id}
          onClick={handleLinkClicked}
          className={classes.projectLink}
        >
          <div className={titleClass}>{dashboard.title}</div>
        </div>
      );
    }

    return list;
  }, [
    classes,
    companyPermissions,
    handleAddAndVisitNewDashboard,
    handleLinkClicked,
    location.pathname,
    recentDashboards
  ]);

  const costManagementDashboardSection = useMemo(() => {

    if (!companyPermissions?.WBS?.CanModifyDashboard || !isWbsEnabled) {
      return null;
    }

    return (
      <>
        <div className={classes.divider + ' halfHeight'} />

        <div className={classes.topLevelItem}>
          <div
            data-targeturl="/cmdashboards"
            onClick={handleLinkClicked}
            data-cy="sidebar.drawer.cost-management"
            className={classes.topLevelHeader}
          >
            <CostManagementGraph data-cy="sidebar.cost-management.icon" className={classes.topLevelIcon} />
            <div className={clsx('title', location.pathname.startsWith('/cmdashboards') && ' selected')}>
              Cost Management
            </div>
          </div>
          <div className={classes.topLevelList} data-cy='sidebar.dashboards.top-level-list'>
            {costManagementDashboardSubItems}
          </div>
        </div>
      </>
    );
  }, [
    classes,
    companyPermissions,
    costManagementDashboardSubItems,
    handleLinkClicked,
    isWbsEnabled,
    location.pathname
  ]);

  const projectSubItems = useMemo(() => {

    const pathname = location.pathname;

    const list = [];
    for (const project of recentProjects) {
      const titleClass =
        clsx('title', pathname === (`/projects/${project.id}`) && ' selected');
      list.push(
        <div
          key={project.id}
          data-targeturl={'/projects/' + project.id}
          data-cy={'sidebar.project.' + project.id}
          onClick={handleLinkClicked}
          className={classes.projectLink}
        >
          <div className={titleClass}>{project.name}</div>
        </div>
      );
    }

    return list;
  }, [classes, handleLinkClicked, location.pathname, recentProjects]);

  const hamburgerIcon = (
    <div
      data-cy="sidebar.hamburger-button"
      onClick={onHamburgerClick}
      className={classes.hamburgerIcon}
    >
      <MenuIcon />
    </div>
  );

  const minimalModeHamburgerIcon = hamburgerIcon;

  return useMemo(() => {

    const accountWrapperCollapsedClass = (isOpen) ? '' : ' hidden';
    const invitesUrl = `/activity/${ActivityLogCategory.Invite}`;
    const messagesUrl = `/activity/${ActivityLogCategory.ItemMessage}`;
    const contactsUrl = `/companies/${loggedInUserDetails.primaryCompanyId}`;
    const settingsUrl = `/companies/${loggedInUserDetails.primaryCompanyId}?showTab=settings`;
    const pathname = location.pathname;

    let unseenInvitesIndicator = null;
    let unseenMessagesIndicator = null;

    if (unseenActivity[ActivityLogCategory.Invite]) {
      unseenInvitesIndicator = (
        <div className={classes.activityNumUnseen} data-cy="sidebar.activity.invites.numUnseen">
          {unseenActivity[ActivityLogCategory.Invite]}
        </div>
      );
    }

    if (unseenActivity[ActivityLogCategory.ItemMessage]) {
      unseenMessagesIndicator = (
        <div className={classes.activityNumUnseen} data-cy="sidebar.activity.messages.numUnseen">
          {unseenActivity[ActivityLogCategory.ItemMessage]}
        </div>
      );
    }

    let logoImage;
    if (!_.isEmpty((loggedInUserDetails.primaryCompany || {}).logoUrl)) {
      logoImage = (
        <img
          className={(isOpen) ? '' : 'closed'}
          src={api.General.PrependApiUrl(loggedInUserDetails.primaryCompany.logoUrl + '&size=Small')}
          alt={loggedInUserDetails.primaryCompany.name || ''}
          title={loggedInUserDetails.primaryCompany.name || ''}
        />
      );
    }

    let settingsItem = null;
    if (companyPermissions.CompanySettings?.IsVisible) {
      settingsItem = (
        <div className={classes.topLevelItem}>
          <div
            data-targeturl={settingsUrl}
            onClick={handleLinkClicked}
            className={classes.topLevelHeader}
            data-cy="sidebar.drawer.settings"
          >
            <SettingsIcon className={classes.topLevelIcon} />
            <div className={clsx('title', pathname.startsWith(contactsUrl) && ' selected')}>Settings</div>
          </div>
        </div>
      );
    }

    // TODO: flesh out this any
    const LogoComponent: any = theme.images.sidebarLogo;
    const logo = (LogoComponent) ? <LogoComponent handColor={theme.palette.sidebar.logoHighlight} /> : null;

    return (
      <Fragment>
        {minimalModeHamburgerIcon}
        <div
          id="sidebar-drawer"
          data-cy="sidebar.drawer"
          className={clsx(classes.drawer, {
            'open': isOpen,
            'closed': !isOpen,
            'hidden': !isOpen,
            'searching': isSearching
          })}
        >
          <div className={classes.header}>
            <div className="top">
              <div className={classes.searchWrapper}>
                <div
                  data-cy="sidebar.close-button"
                  onClick={onCloseClick}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </div>
                <div className={classes.search}>
                  <OutlinedInput
                    classes={{ focused: classes.searchFieldFocused }}
                    className={classes.searchField}
                    inputProps={{
                      value: inputs.search,
                      placeholder: `Search ${loggedInUserDetails?.primaryCompany?.name || ''}`,
                      onChange: handleInputChange,
                      onKeyPress: handleKeyPress,
                      onFocus: handleSearchFocus,
                      onBlur: handleSearchBlur,
                      'data-cy': 'sidebar.search'
                    }}
                    startAdornment={<InputAdornment position="start"><SearchIcon color="primary" /></InputAdornment>}
                  />
                </div>
              </div>
            </div>

            <div className={classes.divider + ' invisible'} />

            <div className={classes.topLevelItem}>
              <div
                data-targeturl="/dashboard"
                onClick={handleLinkClicked}
                data-cy="sidebar.drawer.dashboard"
                className={classes.topLevelHeader}
              >
                <ViewModuleIcon
                  className={classes.topLevelIcon + ' extraLeftMargin'}
                  data-cy="sidebar.dashboard.icon"
                  color="primary"
                />
                <div className={clsx('title', (pathname === '/' || pathname.includes('/dashboard')) && ' selected')}>
                  Home
                  <div className={classes.avatarWrapper}>
                    <SmallAvatar
                      id={loggedInUserDetails.id}
                      name={loggedInUserDetails.fullName}
                      style={{ height: 24,  width: 24 }}
                      useDefaultSize={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.divider + ' halfHeight'} />

          </div>
          <div className={classes.topLevelItem}>
            <div
              data-targeturl="/projects"
              onClick={handleLinkClicked}
              data-cy="sidebar.drawer.projects"
              className={classes.topLevelHeader}
            >
              <ProjectsIcon className={classes.topLevelIcon} data-cy="sidebar.projects.icon" />
              <div className={clsx('title', pathname === '/projects' && ' selected')}>Projects</div>
            </div>
            <div className={classes.topLevelList} data-cy='sidebar.projects.top-level-list'>
              {projectSubItems}
            </div>
          </div>

          {costManagementDashboardSection}

          <div className={classes.divider + ' halfHeight'} />

          <div className={classes.topLevelItem}>
            <div
              data-targeturl="/reports"
              onClick={handleLinkClicked}
              data-cy="sidebar.drawer.reports"
              className={classes.topLevelHeader}
            >
              <ReportsIcon data-cy="sidebar.reports.icon" className={classes.topLevelIcon} />
              <div className={clsx('title', pathname.startsWith('/reports') && ' selected')}>Reports</div>
            </div>
          </div>
          <div className={classes.topLevelItem}>
            <div
              data-targeturl="/contacts"
              onClick={handleLinkClicked}
              data-cy="sidebar.drawer.contacts"
              className={classes.topLevelHeader}
            >
              <ContactsIcon data-cy="sidebar.contacts.icon" className={classes.topLevelIcon} />
              <div
                className={
                  clsx(
                    'title',
                    (pathname.startsWith('/contacts') || pathname.startsWith('/companies')) &&
                    !pathname.startsWith(contactsUrl) &&
                    ' selected'
                  )
                }
              >
                Contacts
              </div>
            </div>
          </div>

          <div className={classes.topLevelItem}>
            <div
              data-targeturl={invitesUrl}
              data-activity-category={ActivityLogCategory.Invite}
              onClick={handleLinkClicked}
              className={classes.topLevelHeader}
              data-cy="sidebar.drawer.activity.invites"
            >
              <ActivityInvitesIcon className={classes.topLevelIcon} />
              <div className={clsx('title', pathname.startsWith(invitesUrl) && ' selected')}>
                Invites
                {unseenInvitesIndicator}
              </div>
            </div>
          </div>

          <div className={classes.topLevelItem}>
            <div
              data-targeturl={'/search/Awarded'}
              onClick={handleLinkClicked}
              className={classes.topLevelHeader}
              data-cy="sidebar.drawer.awards"
            >
              <ActivityAwardsIcon className={classes.topLevelIcon} />
              <div className={clsx('title', pathname.startsWith('/search/Awarded') && ' selected')}>
                Awards
              </div>
            </div>
          </div>

          <div className={classes.topLevelItem}>
            <div
              data-targeturl={messagesUrl}
              data-activity-category={ActivityLogCategory.ItemMessage}
              onClick={handleLinkClicked}
              className={classes.topLevelHeader}
              data-cy="sidebar.drawer.activity.messages"
            >
              <ActivityMessagesIcon className={classes.topLevelIcon} />
              <div className={clsx('title', pathname.startsWith(messagesUrl) && ' selected')}>
                Messages
                {unseenMessagesIndicator}
              </div>
            </div>
          </div>

          {settingsItem}

          <div className={classes.flexSpacer} />

          <div
            className={classes.accountWrapper + accountWrapperCollapsedClass}
            onClick={toggleAccountMenu}
            data-cy="sidebar.drawer.account"
          >
          </div>

          <div>
            <div
              className={classes.companyLogo}
              data-targeturl={'/companies/' + loggedInUserDetails.primaryCompanyId}
              onClick={handleLinkClicked}
              data-cy="sidebar.drawer.company"
            >
              {logoImage}
              <span className={'title' + ((isOpen) ? '' : ' closed')}>
                {(loggedInUserDetails.primaryCompany || {}).name || 'My Company'}
              </span>
            </div>
          </div>

          {accountMenuUI}

          <div className={classes.footer}>
            <span className="title">Powered By</span>
            {logo}
          </div>
        </div>
        <Backdrop
          open={isOpen}
          invisible
          onClick={onCloseClick}
          className={classes.backdrop}
        />
      </Fragment>
    );
  }, [
    accountMenuUI,
    classes,
    companyPermissions,
    costManagementDashboardSection,
    handleInputChange,
    handleKeyPress,
    handleLinkClicked,
    handleSearchBlur,
    handleSearchFocus,
    inputs.search,
    isOpen,
    isSearching,
    location.pathname,
    loggedInUserDetails,
    minimalModeHamburgerIcon,
    onCloseClick,
    projectSubItems,
    theme.images.sidebarLogo,
    theme.palette.sidebar.logoHighlight,
    toggleAccountMenu,
    unseenActivity
  ]);
});

export default withRouter(Sidebar);
