import { createEntityAdapter, createSelector, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';

export default ((stateName: string) => {

  const wbsDataAdapter = createEntityAdapter<WBSDataRowShape>({
    selectId: (dataRow) => dataRow.clientSidePredictionId,
    sortComparer: (a, b) => (a.sortOrder - b.sortOrder)
  });

  const initialState = wbsDataAdapter.getInitialState();

  const slice = createSlice({
    name: stateName,
    initialState,
    reducers: {
      addRow: (state, action: PayloadAction<WBSDataRowShape>) => {

        wbsDataAdapter.addOne(state, action.payload);
      },
      updateRow: (state, action: PayloadAction<Update<WBSDataRowShape>>) => {

        wbsDataAdapter.updateOne(state, action.payload);
      },
      updateRows: (state, action: PayloadAction<Update<WBSDataRowShape>[]>) => {

        wbsDataAdapter.updateMany(state, action.payload);
      },
      mergeRowsFromServer: (state, action: PayloadAction<WBSDataRowShape[]>) => {

        const serverIds = action.payload.map((r) => r.id);
        const rowsToDelete = Object.values(state.entities).filter((r) => {

          return r !== undefined && !serverIds.includes(r.id);
        });

        if (rowsToDelete.length > 0) {
          const rowIdsToDelete = [];
          for (const row of rowsToDelete) {
            if (row?.id !== undefined) {
              rowIdsToDelete.push(row.id);
            }
          }

          wbsDataAdapter.removeMany(state, rowIdsToDelete);
        }

        const rowsToUpsert = [];
        for (const dataRow of action.payload) {
          if (!dataRow.id) {
            continue;
          }

          rowsToUpsert.push({ ...dataRow, clientSidePredictionId: dataRow.id });
        }

        if (rowsToUpsert.length > 0) {
          wbsDataAdapter.upsertMany(state, rowsToUpsert);
        }
      },
      removeRowsById: (state, action: PayloadAction<number[]>) => {

        wbsDataAdapter.removeMany(state, action.payload);
      }
    }
  });

  const {
    selectById
  } = wbsDataAdapter.getSelectors((state: any) => state[stateName]);

  const {
    selectAll: selectAllWbsDataRows
  } = wbsDataAdapter.getSelectors((state: any) => state[stateName]);

  const selectAll = createSelector(
    [selectAllWbsDataRows, (state, projectId) => projectId],
    (dataRows, projectId) => {

      return dataRows.filter(
        (dataRow) => dataRow.projectId === projectId
      );
    }
  );

  const {
    addRow,
    mergeRowsFromServer,
    updateRow,
    updateRows,
    removeRowsById
  } = slice.actions;

  return {
    reducer: slice.reducer,
    selectors: {
      selectAll,
      selectById
    },
    actions: {
      addRow,
      mergeRowsFromServer,
      updateRow,
      updateRows,
      removeRowsById
    }
  };
});
