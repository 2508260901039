import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../api';
import clsx from 'clsx';
import Format from '../../helpers/Format';
import SmallAvatar from '../Icons/SmallAvatar';
import { DateTime } from 'luxon';

import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  assignedByRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '30px'
  },
  checkbox: {
    marginRight: '6px',
    padding: '0',
    top: '6px'
  },
  done: {
    textDecoration: 'line-through'
  },
  dueDate: {
    marginLeft: '30px',
    color: theme.palette.app.errorText
  },
  editButton: {
    position: 'absolute',
    left: '-12px',
    padding: '6px'
  },
  inline: {
    display: 'inline'
  },
  linkText: {
    color: theme.palette.app.defaultText
  },
  todo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  }
}));

const TodoItem = (props) => {

  const classes = useStyles();

  const { loggedInUserDetails, refreshTodos, setTodoToEdit, todoData, isShowAll  = false } = props;
  const { assignedByUser, assignedByUserId, assignedToUserId, id, text, isDone, dueDate, urlPath } = todoData;
  const isSelfAssigned = assignedToUserId === assignedByUserId;

  const [isHovered, setIsHovered] = useState(false);

  const isOwner = useMemo(() => assignedByUserId === loggedInUserDetails.id ,
    [assignedByUserId, loggedInUserDetails]);

  const handleToggleDone = useCallback(async () => {

    const newTodoStatus = !isDone;
    const editedResult = await api.User.EditTodo(id, newTodoStatus);

    if (editedResult.err) {
      console.error(editedResult.err);
    }
    else {
      refreshTodos();
    }
  }, [id, refreshTodos, isDone]);

  const dueDateUI = useMemo(() => {

    const displayDueDate = DateTime.fromISO(dueDate).toLocal().toFormat(Format.DueDateFormatString);
    return !dueDate ? null :
      <Typography className={classes.dueDate} data-cy="todo-item.due-date">
        {displayDueDate}
      </Typography>;

  }, [classes, dueDate]);

  const textOrLink = useMemo(() => {

    return urlPath ?
      <Tooltip title={isShowAll ? todoData.project.name : ''} arrow placement="top">
        <Link className={classes.linkText} data-cy="todo-item.link" to={urlPath}>
          {text}
        </Link>
      </Tooltip>
      :
      text;
  }, [classes, isShowAll, text, todoData.project.name, urlPath]);

  return useMemo(() => {

    return (
      <>
        <ListItem alignItems="flex-start" data-cy="todo-list.item" dense>
          <div onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className={classes.todo}>
              {(!isHovered  || !isOwner) ? null :
                <IconButton
                  className={classes.editButton}
                  data-cy="todo-item.edit-button"
                  aria-label="edit-todo"
                  onClick={() => setTodoToEdit(todoData)}
                >
                  <EditIcon/>
                </IconButton>
              }
              <Checkbox
                aria-label="toggle-done"
                data-cy="todo-item.checkbox"
                className={classes.checkbox}
                onClick={handleToggleDone}
                checked={isDone}
              />
              <Typography className={clsx(isDone && classes.done)}>{textOrLink}</Typography>
            </div>
            {dueDateUI}
            {isSelfAssigned ? null :
              <div className={classes.assignedByRow}>
                <SmallAvatar
                  name={(assignedByUser.fullName.length) ? assignedByUser.fullName[0] : ''}
                  id={assignedByUser.primaryCompany.id || 0}
                  style={{ marginTop: '8px' }}
                />
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {assignedByUser.fullName} (
                  <Link
                    component={Link}
                    to={'/companies/' + assignedByUser.primaryCompany.id}
                    variant="body2"
                    color="textPrimary"
                    style={{ textDecoration: 'underline' }}
                  >
                    {assignedByUser.primaryCompany.name}
                  </Link>
                  )
                </Typography>
              </div>
            }
          </div>
        </ListItem>
        <Divider varant="inset" component="li" />
      </>
    );
  }, [
    assignedByUser.fullName,
    assignedByUser.primaryCompany.id,
    assignedByUser.primaryCompany.name,
    classes,
    handleToggleDone,
    isHovered,
    isOwner,
    isSelfAssigned,
    dueDateUI,
    setTodoToEdit,
    textOrLink,
    todoData,
    isDone
  ]);
};

export default TodoItem;
