import { render } from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import { ampli } from './ampli';

import App from './App';
import themeRelay from './theme-relay';
import themeRfp from './theme-rfp';

import { Provider } from 'react-redux';
import store from './redux/store';

let theme;

switch (process.env.REACT_APP_THEME) {
  case 'rfp':
    theme = themeRfp;
    break;
  case 'relay':
  default:
    theme = themeRelay;
    break;
}

ampli.load({
  client: {
    apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY
  }
});

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        hideIconVariant={true}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <CssBaseline />
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
