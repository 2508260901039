import React, { Component, createContext, Fragment, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { Userpilot } from 'userpilot';

import './reset.css';
import './App.css';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import Login from './components/Account/Login/Login';
import Maintenance from './components/Maintenance';
import UserDashboard from './components/UserDashboard/UserDashboard';
import Sidebar from './components/Sidebar/Sidebar';

import api from './api';
import * as actions from './redux/mainActions';
import ContextMenu from './components/Menus/ContextMenu';
import LinearProgress from './components/Generic/CSSLinearProgress';
import { updateCompanySettings } from './redux/project/projectSlice';
import { connectRootSocket } from './api/socketioClient';
import CircularProgress from '@material-ui/core/CircularProgress';
import store from './redux/store';
import * as mainActions from './redux/mainActions';

const Acknowledgement = lazy(() =>

  import('./components/Acknowledgement/Acknowledgement')
);
const Activity = lazy(() =>

  import('./components/Activity')
);
const CompanyDetails = lazy(() =>

  import('./components/Company/CompanyDetails')
);
const CompanyList = lazy(() =>

  import('./components/Company/CompanyList')
);
const CostManagementDashboard = lazy(() =>

  import('./components/CostManagement/CostManagementDashboard')
);
const CostManagementDashboardList = lazy(() =>

  import('./components/CostManagement/CostManagementDashboardList')
);
const Invitation = lazy(() =>

  import('./components/Invitation/Invitation')
);
const UnauthorizedError = lazy(() =>

  import('./components/Error/UnauthorizedError')
);
const ProjectDetails = lazy(() =>

  import('./components/Project/ProjectDetails')
);
const ProjectList = lazy(() =>

  import('./components/Project/ProjectList/ProjectList')
);
const ResetPassword = lazy(() =>

  import('./components/Account/ResetPassword/ResetPassword')
);
const Reports = lazy(() =>

  import('./components/Reports/Reports')
);
const Search = lazy(() =>

  import('./components/Search')
);
const SummarySheetCollectionSnapshotPage  = lazy(() =>

  import('./components/Project/SummarySheetCollectionList/SummarySheetCollectionSnapshotPage')
);

window.React = React;

// exposed to component via this.props.classes
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    paddingBottom: '20px',
    paddingTop: '20px',
    paddingLeft: '20px'
  },
  mainProgressBar: {
    position: 'fixed',
    width: '100%',
    zIndex: 10001
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.app.background
  }
});


let currentPath = window.location.pathname;
let isProjectPage = currentPath.includes('/projects/');

let currentPanningElement = null;
let panStartMouseX = 0;
let panStartMouseY = 0;
let panStartWindowX = 0;
let panStartWindowY = 0;
let panStartElementX = 0;
let panStartElementY = 0;

export const ContextMenuContext = createContext();

class App extends Component {

  constructor(props) {

    super(props);
    this.state = {
      contextMenuAnchorPos: null,
      contextMenuOptions: []
    };
  }

  setContextMenuOptions = (newOptions) => {

    this.setState({ contextMenuOptions: newOptions || [] });
  };

  handleKeypressWhenContextMenuIsOpen = (evt) => {

    const allowedKeys = [13, 27, 32, 33, 34, 35, 36, 37, 37, 39, 40];

    if (!allowedKeys.includes(evt.keyCode)) {
      this.setState({ contextMenuAnchorPos: null });
      document.removeEventListener('keydown', this.handleKeypressWhenContextMenuIsOpen);
    }
  };

  handleContextMenuAnchorPos = (evt, options = {}) => {

    if (!evt) {
      this.setState({ contextMenuAnchorPos: null });
      document.removeEventListener('keydown', this.handleKeypressWhenContextMenuIsOpen);
    }
    else {
      const mouseX =  evt.clientX - (options?.pos?.x || 2);
      const mouseY = evt.clientY - (options?.pos?.y || 4);
      const newAnchorPos = (this.state.contextMenuAnchorPos === null) ? { mouseX, mouseY } : null;
      this.setState({ contextMenuAnchorPos: newAnchorPos });
      document.addEventListener('keydown', this.handleKeypressWhenContextMenuIsOpen);
    }
  };

  handleCloseContextMenu = () => {

    this.setState({ contextMenuAnchorPos: null });
  };

  renderContextMenu = () => {

    if (!this.state.contextMenuOptions || !this.state.contextMenuAnchorPos) {
      return null;
    }

    return (
      <ContextMenu
        anchorReference="anchorPosition"
        anchorPosition={{ top: this.state.contextMenuAnchorPos.mouseY, left: this.state.contextMenuAnchorPos.mouseX }}
        menuOptions={this.state.contextMenuOptions}
        onClose={this.handleCloseContextMenu}
      />
    );
  };

  importModuleSvgs = () => {

    const result = require.context('./components/Modules/Icons', false, /\.(png|jpe?g|svg)$/);
    const moduleIconInfo = result.keys().map((path) => ({ name: path.split('/')[1], path: result(path) }));
    window.ModuleIcons = moduleIconInfo;
  };


  traverseDomUpForScrollableElement = (element) => {

    if (element) {
      if (element === document.body || !isProjectPage) {
        return window;
      }

      const scrollTop = element.scrollTop;
      const scrollLeft = element.scrollLeft;

      if (scrollTop > 0 || scrollLeft > 0) {
        return element;
      }

      if (!element.dataset.disallowVerticalPan) {
        element.scrollTop += 10;
      }

      if (!element.dataset.disallowHorizontalPan) {
        element.scrollLeft += 10;
      }

      let canScroll = false;

      if (element.scrollTop !== scrollTop || element.scrollLeft !== scrollLeft) {
        canScroll = true;
      }

      element.scrollTop = scrollTop;
      element.scrollLeft = scrollLeft;

      if (canScroll) {
        return element;
      }

      return this.traverseDomUpForScrollableElement(element.parentElement);
    }

    return null;
  };

  handleMousePan = (event) => {

    if (!currentPanningElement || !isProjectPage) {
      return;
    }

    let deltaX = 0;
    let deltaY = 0;

    if (!currentPanningElement.dataset?.disallowHorizontalPan) {
      deltaX = panStartMouseX - event.screenX;
    }

    if (!currentPanningElement.dataset?.disallowVerticalPan) {
      deltaY = panStartMouseY - event.screenY;
    }

    if (deltaY !== 0 || deltaX !== 0) {
      const scrollLeft = currentPanningElement.scrollLeft;
      const scrollTop = currentPanningElement.scrollTop;

      currentPanningElement.scroll(panStartElementX + deltaX, panStartElementY + deltaY);

      if (currentPanningElement !== window) {
        if (currentPanningElement.scrollLeft === scrollLeft) {
          deltaX = panStartMouseX - event.screenX;
          window.scroll(panStartWindowX + deltaX, window.scrollY);
        }

        if (currentPanningElement.scrollTop === scrollTop) {
          deltaY = panStartMouseY - event.screenY;
          window.scroll(window.scrollX, panStartWindowY + deltaY);
        }
      }

      this.props.setHasSeenDragTooltip();
    }
  };

  handleContextMenu = (event) => {

    if (!event.ctrlKey && isProjectPage) {
      // Prevent child element's context menu listeners from firing if the user panned an element
      const movedX = Math.abs(panStartMouseX - event.screenX);
      const movedY = Math.abs(panStartMouseY - event.screenY);
      if ((movedX + movedY) > 5) {
        event.stopPropagation();
      }

      event.preventDefault();
    }
  };

  handleMouseUp = (event) => {

    if (currentPanningElement && isProjectPage) {
      document.removeEventListener('mousemove', this.handleMousePan);
    }

    currentPanningElement = null;
  };

  handleMouseDown = (event) => {

    if (event.button === 2 && isProjectPage) { // right click
      currentPanningElement = this.traverseDomUpForScrollableElement(event.target);

      if (currentPanningElement) {
        panStartMouseX = event.screenX;
        panStartMouseY = event.screenY;
        panStartElementX = (currentPanningElement === window) ?
          currentPanningElement.scrollX :
          currentPanningElement.scrollLeft;
        panStartElementY = (currentPanningElement === window) ?
          currentPanningElement.scrollY :
          currentPanningElement.scrollTop;

        panStartWindowX = window.scrollX;
        panStartWindowY = window.scrollY;

        document.addEventListener('contextmenu', this.handleContextMenu, { capture: true, once: true });
        document.addEventListener('mousemove', this.handleMousePan);
        document.addEventListener('mouseup', this.handleMouseUp, { once: true });
      }
    }
  };

  handleTouchScroll = (event) => {

    // touchpad scroll.  this works because touchpads scroll tiny amounts in order to follow the user's fingers.
    // mouse wheels scroll in larger chunks (e.g. 100 pixels at a time).  also, wheelDeltaY references how far
    // the physical device scrolled, while deltaY references how many pixels where scrolled on screen.  for a
    // touchpad, they will be the same, but one is negative.
    if (event.wheelDeltaY === -event.deltaY && Math.abs(event.wheelDeltaY) <= 5 && Math.abs(event.wheelDeltaY) > 0) {
      this.props.setHasSeenDragTooltip();
      document.removeEventListener('wheel', this.handleTouchScroll);
    }
  };

  componentDidMount = () => {

    console.log('APP VERSION: ' + process.env.REACT_APP_VERSION);

    if (process.env.NODE_ENV !== 'development') {
      Userpilot.initialize(process.env.REACT_APP_USERPILOT_TOKEN);
    }

    if (this.props.isLoggedIn) {
      this.requestLoggedInUserDetails();
      this.importModuleSvgs();
    }

    // setting this flag so that the project hierarchy will load the first time the page loads
    this.props.setDidMakeApiChange();
    document.addEventListener('mousedown', this.handleMouseDown);

    if (!this.props.hasSeenDragTooltip) {
      document.addEventListener('wheel', this.handleTouchScroll);
    }

    if (this.props.redirectOnError) {
      this.props.afterRedirect();
    }
  };

  componentWillUnmount = () => {

    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mousemove', this.handleMousePan);

    if (!this.props.hasSeenDragTooltip) {
      document.removeEventListener('wheel', this.handleTouchScroll);
    }
  };

  componentDidUpdate = (prevProps) => {

    if (process.env.NODE_ENV !== 'development') {
      if (window.location.pathname !== currentPath) {
        Userpilot.reload();
      }
    }

    currentPath = window.location.pathname;
    isProjectPage = currentPath.includes('/projects/');

    if (this.props.isLoggedIn && this.props.isLoggedIn !== prevProps.isLoggedIn) {
      this.requestLoggedInUserDetails();

      if (!window.ModuleIcons) {
        this.importModuleSvgs();
      }
    }

    if (this.props.showError) {
      this.props.enqueueSnackbar('Something went wrong', { autoHideDuration: 5000, variant: 'error' });
      this.props.afterErrorShown();
    }
  };

  requestLoggedInUserDetails = () => {

    if (window.location.pathname.includes('/maintenance')) {
      return;
    }

    api.User.GetDetails().then(this.afterRequestLoggedInDetails);
  };

  afterRequestLoggedInDetails = (result) => {

    if (result.err) {
      // TODO: error out somehow
    }
    else {
      sessionStorage.removeItem('navigateToRootIfLoggedOut');
      this.props.setLoggedInUserDetails(result.res.body);
      if (result.res.body.primaryCompany.settings) {
        this.props.updateCompanySettings(result.res.body.primaryCompany.settings);
      }

      const userDetails = result.res.body.spoofedByUser || result.res.body;
      const spoofedUserInfo = (result.res.body.spoofedByUser) ? ` (spoofing ${result.res.body.fullName})` : '';

      // connect to the realtime webservice
      connectRootSocket();

      if (process.env.NODE_ENV !== 'development') {
        window.FS.identify(userDetails.id, {
          displayName: userDetails.fullName + spoofedUserInfo,
          email: userDetails.email
        });

        const superUserPrefix = (userDetails.privileges === 'SuperAdmin') ? 'SU-' : '';

        Userpilot.identify(
          `${process.env.REACT_APP_DOMAIN}-${superUserPrefix}${userDetails.id}`,
          {
            name: userDetails.fullName + spoofedUserInfo,
            email: userDetails.email,
            company: {
              ...userDetails.primaryCompany
            }
          }
        );
      }
    }
  };

  handleNavigateToRoot = () => {

    if (window.location.pathname !== '/') {
      // Delay here to prevent issues with the loading bar getting stuck on
      setTimeout(() => {

        window.location = '/';
      }, 250);
      return null;
    }

    sessionStorage.removeItem('navigateToRootIfLoggedOut');
  };

  renderMainContent = (child, allowProgressBar = true) => {

    let mainContent = child;
    let sidebar = <Sidebar />;
    const shouldNavigateToRoot = sessionStorage.getItem('navigateToRootIfLoggedOut') === 'true';

    if (window.location.pathname.includes('/logged-in')) {
      store.dispatch(mainActions.setIsLoggedIn(true));
    }
    else if (!this.props.isLoggedIn) {

      if (window.location.pathname !== '/reset-password') {
        mainContent = (<Login loadSsoUrls />);
      }

      sidebar = null;
    }

    let mainProgressBar = null;
    if (this.props.isLoading && allowProgressBar) {
      mainProgressBar = (
        <LinearProgress className={this.props.classes.mainProgressBar} color="secondary" />
      );
    }

    if (shouldNavigateToRoot) {
      this.handleNavigateToRoot();
    }

    if (this.props.redirectOnError) {
      mainContent = (<LazyUnauthorizedError />);
    }

    return (
      <Fragment>
        {mainProgressBar}
        <div className={this.props.classes.root}>
          {sidebar}
          <div className={this.props.classes.content}>
            {mainContent}
          </div>
        </div>
      </Fragment>
    );
  };

  render = () => {

    const contextMenuContextProviderValue = {
      handleContextMenuAnchorPos: this.handleContextMenuAnchorPos,
      setContextMenuOptions: this.setContextMenuOptions,
      contextMenuAnchorPos: this.state.contextMenuAnchorPos
    };

    return (
      <ContextMenuContext.Provider value={contextMenuContextProviderValue}>
        <ReactFlowProvider>
          <Router>
            <Switch>
              <Route path=":topLevelPage(/maintenance)">
                <Maintenance />
              </Route>

              <Route path=":topLevelPage(/companies)/:companyId/:edit">
                {this.renderMainContent(<LazyCompanyDetails />)}
              </Route>
              <Route path=":topLevelPage(/companies)/:companyId">
                {this.renderMainContent(<LazyCompanyDetails />)}
              </Route>
              <Route path=":topLevelPage(/companies)">
                {this.renderMainContent(<LazyCompanyList />)}
              </Route>

              <Route path=":topLevelPage(/contacts)/:companyId">
                {this.renderMainContent(<LazyCompanyList />)}
              </Route>
              <Route path=":topLevelPage(/contacts)">
                {this.renderMainContent(<LazyCompanyList />)}
              </Route>

              <Route path=":topLevelPage(/cmdashboards)/:dashboardId">
                {this.renderMainContent(<LazyCostManagementDashboard />, false)}
              </Route>

              <Route path=":topLevelPage(/cmdashboards)">
                {this.renderMainContent(<LazyCostManagementDashboardList />)}
              </Route>

              <Route path=":topLevelPage(/projects)/:projectId/section/:sectionId/bid/:bidId">
                {this.renderMainContent(<LazyProjectDetails />, false)}
              </Route>
              <Route path=":topLevelPage(/projects)/:projectId/section/:sectionId">
                {this.renderMainContent(<LazyProjectDetails />, false)}
              </Route>
              <Route path=":topLevelPage(/projects)/:projectId">
                {this.renderMainContent(<LazyProjectDetails />, false)}
              </Route>
              <Route path=":topLevelPage(/projects)">
                {this.renderMainContent(<LazyProjectList />)}
              </Route>

              <Route path=":topLevelPage(/summary-sheet-collection-snapshot)/:snapshotUUID">
                <LazySummarySheetCollectionSnapshotPage />
              </Route>

              <Route path=":topLevelPage(/invitation)/:inviteToken">
                <LazyInvitation />
              </Route>

              <Route path=":topLevelPage(/acknowledgement)/:token">
                <LazyAcknowledgement />
              </Route>

              <Route path=":topLevelPage(/search)/:searchQuery">
                {this.renderMainContent(<LazySearch />)}
              </Route>

              <Route path=":topLevelPage(/login)">
                <Login loadSsoUrls />
              </Route>

              <Route path=":topLevelPage(/reset-password)">
                {this.renderMainContent(<LazyResetPassword />)}
              </Route>

              <Route path=":topLevelPage(/activity)/:category">
                {this.renderMainContent(<LazyActivity />)}
              </Route>

              <Route path=":topLevelPage(/activity)">
                {this.renderMainContent(<LazyActivity />)}
              </Route>

              <Route path=":topLevelPage(/reports)">
                {this.renderMainContent(<LazyReports />)}
              </Route>

              <Route path=":topLevelPage(/dashboard)">
                {this.renderMainContent(<UserDashboard />)}
              </Route>

              <Route path="/">
                {this.renderMainContent(<UserDashboard />)}
              </Route>
            </Switch>
          </Router>
        </ReactFlowProvider>
        {this.renderContextMenu()}
      </ContextMenuContext.Provider>
    );
  };

}

const mapStateToProps = (state, ownProps) => {

  return {
    isLoggedIn: state.appState.isLoggedIn,
    isLoading: state.appState.isLoading,
    hasSeenDragTooltip: state.appState.hasSeenDragTooltip,
    redirectOnError: state.appState.redirectOnError,
    showError: state.appState.showError
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    afterErrorShown: () => {

      dispatch(actions.setShowError(false));
    },
    afterRedirect: () => {

      dispatch(actions.setRedirectOnError(false));
    },
    setLoggedInUserDetails: (userDetails) => {

      dispatch(actions.setLoggedInUserDetails(userDetails));
    },
    setDidMakeApiChange: () => {

      dispatch(actions.setDidMakeApiChange());
    },
    setHasSeenDragTooltip: () => {

      dispatch(actions.setHasSeenDragTooltip());
    },
    updateCompanySettings: (companySettings) => {

      dispatch(updateCompanySettings(companySettings));
    }
  };
};

const LazyLoader = () => (

  <div style={{ margin: 'auto', width: 'fit-content', height: 'fit-content' }}>
    <CircularProgress color="inherit" size={20} />
  </div>
);

const LazyAcknowledgement = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <Acknowledgement />
  </Suspense>
);

const LazyActivity = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <Activity />
  </Suspense>
);

const LazyCompanyDetails = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <CompanyDetails />
  </Suspense>
);

const LazyCompanyList = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <CompanyList />
  </Suspense>
);

const LazyCostManagementDashboard = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <CostManagementDashboard />
  </Suspense>
);

const LazyCostManagementDashboardList = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <CostManagementDashboardList />
  </Suspense>
);

const LazyInvitation = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <Invitation />
  </Suspense>
);

const LazyUnauthorizedError = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <UnauthorizedError />
  </Suspense>
);

const LazyProjectDetails = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <ProjectDetails />
  </Suspense>
);

const LazyProjectList = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <ProjectList />
  </Suspense>
);

const LazyResetPassword = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <ResetPassword />
  </Suspense>
);

const LazyReports = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <Reports />
  </Suspense>
);

const LazySearch = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <Search />
  </Suspense>
);

const LazySummarySheetCollectionSnapshotPage = () => (

  <Suspense fallback={<CircularProgress color="inherit" size={20} />}>
    <SummarySheetCollectionSnapshotPage />
  </Suspense>
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(App)));
