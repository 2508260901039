import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import api from '../../api';
import * as Consts from '../../consts/Project';
import { ContextMenuContext } from '../../App';
import Labels from '../Menus/Labels';
import MultiItemSelectDialog from './MultiItemSelectDialog';
import { ProjectApiSlice } from '../../api/project';
import SpellCheck from '../../helpers/SpellCheck';
import SpellCheckMenuOptions from '../Menus/SpellCheckMenuOptions';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import ImportDataDialog from './ImportDataDialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DateTimeDialog from './DateTimeDialog';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ProjectLabelShape } from '../../types/api/ProjectLabelShape';
import { ProjectShape } from '../../types/api/ProjectShape';
import { VisibilityType } from '../../types/enums/VisibilityType';
import { ImportDataShape } from '../../types/api/ImportDataShape';
import ProjectCurrencies from '../Menus/ProjectCurrencies';
import { CurrencyShape } from '../../types/api/ProjectCurrencyShape';

const getOriginalInputs = () => {

  const nextWeek = new Date();
  nextWeek.setFullYear(nextWeek.getFullYear() + 1);

  return {
    name: '',
    description: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    dueDate: nextWeek,
    visibility: api.Project.VisibilityTypes.Restricted,
    template: '',
    templateOmitAreasDialogShown: false,
    templateOmitAreasDialogItemList: null,
    templateOmittedAreas: [] as number[],
    templatePhasesDueDate: undefined
  };
};

const generateCust = (customDesignations = {}) => {

  return {
    division: 'Division',
    divisionManager: 'Division Manager',
    divisionContributor: 'Division Contributor',
    divisionEstimator: 'Division Estimator',
    area: 'Area',
    areaManager: 'Area Manager',
    areaContributor: 'Area Contributor',
    areaEstimator: 'Area Estimator',
    ...customDesignations
  };
};

const useStyles = makeStyles((theme) => ({
  buttonsGrid: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center'
  },
  currencyManagement: {
    marginTop: theme.spacing(2)
  },
  currencyManagementTitleRow: {
    cursor: 'pointer',
    fontSize: '1.25rem',
    width: '100%'
  },
  description: {
    marginBottom: theme.spacing(2)
  },
  gridButton: {
    height: '75%'
  },
  labelManagement: {
    marginTop: theme.spacing(2)
  },
  labelManagementTitleRow: {
    cursor: 'pointer',
    fontSize: '1.25rem',
    width: '100%'
  },
  omittedLabel: {
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
  },
  select: {
    marginTop: '8px'
  },
  templateOptions: {
    marginTop: theme.spacing(1)
  }
}));

interface Props {
  forCompanyId: number,
  id?: string,
  onCancel: () => void,
  onConfirm: (
    name: string,
    description: string | undefined,
    address: string,
    city: string | undefined,
    state: string | undefined,
    postalCode: string | undefined,
    dueDate: Date | undefined,
    visibility: VisibilityType | undefined,
    template: number | undefined,
    templatePhasesDueDate: Date | undefined,
    templateOmittedAreas: number[] | undefined,
    confirmedImportData: ImportDataShape,
    labelIds: number[],
    primaryCurrency: string,
    primaryCurrencySymbol: string,
    currencies: CurrencyShape[]
  ) => void,
  open: boolean,
  shouldShowImport?: boolean,
  templates: ProjectShape[]
}

interface TemplateOmitAreasDialogItem {
  id: string,
  isHeader?: boolean,
  isSelected: boolean,
  isSelectable: boolean,
  isSubItem?: true,
  toggleOthersWhenClicked?: string[],
  label: string
}

const CreateProjectDialog = (props: Props) => {

  const classes = useStyles();

  const {
    forCompanyId,
    id,
    onCancel,
    onConfirm,
    open,
    shouldShowImport,
    templates
  } = props;

  const originalErrorText = useMemo(() => {

    return {
      name: '',
      address: ''
    };
  }, []);

  const [allProjectLabels, setAllProjectLabels] = useState<ProjectLabelShape[]>([]);
  // TODO: flesh out this any
  const [confirmedImportData, setConfirmedImportData] = useState<ImportDataShape>(
    { divisions: [], projectMetadata: {} }
  );
  const [currenciesHasError, setCurrenciesHasError] = useState(false);
  const [errorText, seteErrorText] = useState(originalErrorText);
  const [inputs, setInputs] = useState<{ [index: string]: any }>(getOriginalInputs());
  const [selectedProjectLabels, setSelectedProjectLabels] = useState<ProjectLabelShape[]>([]);
  const [showLabelManagement, setShowLabelManagement] = useState(false);
  const [showCurrencyManagement, setShowCurrencyManagement] = useState(false);
  const [primaryCurrency, setPrimaryCurrency] = useState('USD');
  const [primaryCurrencySymbol, setPrimaryCurrencySymbol] = useState('$');
  const [projectCurrencies, setProjectCurrencies] = useState<CurrencyShape[]>([]);

  const { handleContextMenuAnchorPos, setContextMenuOptions } = useContext(ContextMenuContext);

  // TODO: flesh out this any
  const renderContextMenuOptions = useCallback((evt: EventTarget, spellCheckData: any = {}) => {

    const spellCheckOptions = (
      <SpellCheckMenuOptions
        suggestions={spellCheckData.suggestions}
        potentialMisspelledWord={spellCheckData.potentialMisspelledWord}
        onClick={(suggestedWord) => {

          const options = { inputs };
          SpellCheck.reassignClicked(
            spellCheckData.potentialMisspelledWord,
            suggestedWord,
            inputs[spellCheckData.selectedInputName],
            spellCheckData.selectedInputName,
            setInputs,
            options
          );
          handleContextMenuAnchorPos(null);
        }}
      />
    );

    setContextMenuOptions([spellCheckOptions]);
  }, [
    handleContextMenuAnchorPos,
    inputs,
    setContextMenuOptions
  ]);

  const handleUpdateProjectLabels = useCallback(() => {

    api.Company.GetAllLabels().then((result) => {

      if (!result.err) {
        setAllProjectLabels(result.res.body);
      }
    });
  }, []);

  useEffect(() => {

    if (open) {
      handleUpdateProjectLabels();
    }
    else {
      setAllProjectLabels([]);
      setSelectedProjectLabels([]);
      setShowLabelManagement(false);
    }
  }, [forCompanyId, handleUpdateProjectLabels, open]);

  const handleModifyProjectLabels = useCallback((label: ProjectLabelShape, shouldRemove: boolean) => {

    setSelectedProjectLabels((prev) => ((shouldRemove) ? prev.filter((l) => l.id !== label.id) : [...prev, label]));
  }, []);

  const handleRightClickInput = useCallback((evt: React.MouseEvent<HTMLInputElement>) => {

    const evtTarget = { ...evt.currentTarget, id: evt.currentTarget.id };
    handleContextMenuAnchorPos(evt);
    const selectedInputName: string = evt.currentTarget.id;
    const inputText: string = inputs[selectedInputName];
    const potentialMisspelledWord = SpellCheck.handleWordRightClicked(
      evt.currentTarget.selectionStart,
      inputText
    );
    if (potentialMisspelledWord) {
      return api.Project.GetSpellCheck(potentialMisspelledWord).then((response) => {

        const spellCheckData = {
          potentialMisspelledWord,
          suggestions: _.defaultTo(response.res?.body, {}),
          selectedInputName
        };
        renderContextMenuOptions(evtTarget, spellCheckData);
      });
    }

    renderContextMenuOptions(evtTarget);
  }, [handleContextMenuAnchorPos, renderContextMenuOptions, inputs]);

  // TODO: flesh out this any
  const handleConfirmImportData = useCallback((importData: ImportDataShape) => {

    setConfirmedImportData(importData);
  }, []);

  const handleInputChange = useCallback((evt: React.ChangeEvent<{ id?: string, name?: string; value: any; }>) => {

    evt.preventDefault();

    const itemName = evt.target.id ?? evt.target.name;
    if (!itemName) {
      return;
    }

    const value = evt.target.value;
    const newInputs = { ...inputs };
    newInputs[itemName] = value;

    if (itemName === 'template') {
      newInputs.templateOmitAreasDialogItemList = null;
      newInputs.templateOmittedAreas = [];
      setConfirmedImportData({ divisions: [], projectMetadata: {} });

      if (value) {
        // Scroll to template options using a timeout with no delay to allow them to render
        setTimeout(() => {

          const content = document.getElementById('createProjectDialogContent');
          if (content) {
            content.scroll({ top: content.scrollHeight, behavior: 'smooth' });
          }
        });
      }
    }

    setInputs(newInputs);
  }, [inputs]);

  const handleDateChange = useCallback((newDate: Date | string) => setInputs((prev) => ({ ...prev, dueDate: newDate })), []);

  const handleTemplatePhasesDueDateChange =
    useCallback((newDate: Date | string) => setInputs((prev) => ({ ...prev, templatePhasesDueDate: newDate })), []);

  const checkInputErrors = useCallback(() => {

    const newErrorText = { ...originalErrorText };
    let hasErrors = false;

    if (!inputs.name.length) {
      newErrorText.name = 'A name is required';
      hasErrors = true;
    }

    if (
      !inputs.address.trim().length &&
      !inputs.city.trim().length &&
      !inputs.state.trim().length &&
      !inputs.postalCode.trim().length
    ) {
      newErrorText.address = 'One of location, city, state, or postal code is required';
      hasErrors = true;
    }

    seteErrorText(newErrorText);
    return hasErrors;
  }, [inputs, originalErrorText]);

  const handleConfirm = useCallback(() => {

    if (!checkInputErrors()) {
      onConfirm(
        inputs.name,
        inputs.description,
        inputs.address,
        inputs.city,
        inputs.state,
        inputs.postalCode,
        inputs.dueDate,
        inputs.visibility,
        inputs.template,
        (inputs.template) ? inputs.templatePhasesDueDate : undefined,
        (inputs.templateOmittedAreas.length) ? inputs.templateOmittedAreas : undefined,
        confirmedImportData,
        selectedProjectLabels.map((l) => l.id),
        primaryCurrency,
        primaryCurrencySymbol,
        projectCurrencies
      );

      seteErrorText(originalErrorText);
      setInputs(getOriginalInputs());
    }
  }, [
    checkInputErrors,
    confirmedImportData,
    inputs,
    onConfirm,
    originalErrorText,
    primaryCurrency,
    primaryCurrencySymbol,
    projectCurrencies,
    selectedProjectLabels
  ]);

  const handleCancel = useCallback((evt: React.MouseEvent, reason?: string) => {

    if (reason === 'backdropClick') {
      return;
    }

    seteErrorText(originalErrorText);
    setInputs(getOriginalInputs());
    onCancel();
  }, [onCancel, originalErrorText]);

  const handleIncludeMilestonesClick =
    useCallback(() => setInputs((prev) => ({ ...prev, templatePhasesDueDate: new Date() })), []);

  const [getDetails] = ProjectApiSlice.useLazyGetDetailsQuery();

  const handleShowOmitAreaDialog = useCallback(() => {

    setInputs((prev) => ({ ...prev, templateOmitAreasDialogShown: true }));

    if (!inputs.templateOmitAreasDialogItemList) {
      getDetails({ id: inputs.template, retainStructureIfTemplate: true }).then((response) => {

        if (!response.error) {
          const projectDetails = response.data;
          const cust = generateCust(projectDetails?.company?.settings?.customDesignations);
          const templateOmitAreasDialogItemList: TemplateOmitAreasDialogItem[] = [];

          for (const division of projectDetails.divisions) {
            const sectionListIds: string[] = [];
            templateOmitAreasDialogItemList.push({
              id: `div_${division.id}`,
              isSelected: false,
              isSelectable: false,
              isHeader: true,
              toggleOthersWhenClicked: sectionListIds,
              label: `${cust.division} ${division.label ?? division.number} - ${division.name || `${cust.division} Name`}`
            });

            let index = 0;
            for (const section of division.sections) {
              const areaNumber = _.padStart((++index).toString(), 2, '0');
              const sectionListId = `sec_${section.id}`;
              sectionListIds.push(sectionListId);
              templateOmitAreasDialogItemList.push({
                id: sectionListId,
                isSelected: false,
                isSelectable: true,
                isSubItem: true,
                label: `${cust.area} ${areaNumber} - ${section.title || 'Untitled'} - ${section.area || 'Unlabeled'}`
              });
            }
          }

          setInputs((prev) => ({ ...prev, templateOmitAreasDialogItemList }));
        }
      });
    }
  }, [getDetails, inputs]);

  const handleCloseTemplateOmitAreasDialog =
    useCallback(() => setInputs((prev) => ({ ...prev, templateOmitAreasDialogShown: false })), []);

  const handleConfirmTemplateOmitAreasDialog = useCallback((omittedAreaIds: string[]) => {

    const templateOmittedAreas: number[] = omittedAreaIds.map((a: string) => parseInt(a.split('_')[1]));
    setInputs((prev) => ({ ...prev, templateOmittedAreas, templateOmitAreasDialogShown: false }));
  }, []);

  const handleToggleLabelManagement = useCallback(() => setShowLabelManagement((prev) => !prev), []);
  const handleToggleCurrencyManagement = useCallback(() => setShowCurrencyManagement((prev) => !prev), []);

  const handleCurrenciesErrorStateChange = useCallback((errorState: boolean) => setCurrenciesHasError(errorState), []);

  const handleCurrenciesUpdated = useCallback((currencies: CurrencyShape[]) => {

    setProjectCurrencies(currencies);
  }, []);

  const handlePrimaryCurrencyUpdated = useCallback((currency: string, currencySymbol: string) => {

    setPrimaryCurrency(currency);
    setPrimaryCurrencySymbol(currencySymbol);
  }, []);

  const templateOptions = useMemo(() => {

    const options = templates.map((template) => {

      if (template) {
        return (
          <MenuItem key={template.id} value={template.id} data-cy="create-project-dialog.template-option">
            {template.name}
          </MenuItem>
        );
      }

      return null;
    });

    options.unshift(<MenuItem data-cy="create-project-dialog.template-option-none" key={-1} value={''}>None</MenuItem>);
    return options;
  }, [templates]);

  const labelManagement = useMemo(() => {

    const icon = (showLabelManagement) ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    const selectedLabelsText = (!showLabelManagement && selectedProjectLabels.length) ?
      ` (${selectedProjectLabels.length} selected)` :
      null;

    return (
      <div className={classes.labelManagement}>
        <span className={classes.labelManagementTitleRow} onClick={handleToggleLabelManagement}>
          Labels
          {selectedLabelsText}
          <IconButton size="small" data-cy="create-project-dialog.toggle-label-management">
            {icon}
          </IconButton>
        </span>
        <Collapse in={showLabelManagement} timeout="auto" unmountOnExit>
          <Labels
            allLabels={allProjectLabels}
            isForNewProject
            onLabelsUpdated={handleUpdateProjectLabels}
            onModifyProjectLabels={handleModifyProjectLabels}
            projectLabels={selectedProjectLabels}
          />
        </Collapse>
      </div>
    );
  }, [
    allProjectLabels,
    classes,
    handleModifyProjectLabels,
    handleToggleLabelManagement,
    handleUpdateProjectLabels,
    selectedProjectLabels,
    showLabelManagement
  ]);

  const currencyManagement = useMemo(() => {

    const icon = (showCurrencyManagement) ? <ExpandLessIcon /> : <ExpandMoreIcon />;

    return (
      <div className={classes.currencyManagement}>
        <span className={classes.currencyManagementTitleRow} onClick={handleToggleCurrencyManagement}>
          Currencies
          <IconButton size="small" data-cy="create-project-dialog.toggle-currency-management">
            {icon}
          </IconButton>
        </span>
        <Collapse in={showCurrencyManagement} timeout="auto" unmountOnExit>
          <ProjectCurrencies
            onCurrenciesUpdated={handleCurrenciesUpdated}
            onErrorStateChange={handleCurrenciesErrorStateChange}
            onPrimaryCurrencyUpdated={handlePrimaryCurrencyUpdated}
          />
        </Collapse>
      </div>
    );
  }, [
    classes,
    handleCurrenciesErrorStateChange,
    handleCurrenciesUpdated,
    handlePrimaryCurrencyUpdated,
    handleToggleCurrencyManagement,
    showCurrencyManagement
  ]);

  return useMemo(() => {

    const customDesignations = templates.find((t) => t?.id === inputs.template);
    const cust = generateCust(customDesignations);

    let templateOptionsDisplay = null;
    if (inputs.template) {
      templateOptionsDisplay = (
        <Grid container spacing={1} classes={{ root: classes.templateOptions }}>
          <Grid item xs={5} className={classes.buttonsGrid}>
            {inputs.templatePhasesDueDate ?
              <DateTimeDialog
                dataCy='create-project-dialog.template-phases-start-due-date'
                onChange={handleTemplatePhasesDueDateChange}
                defaultDate={inputs.templatePhasesDueDate}
                label='Milestones Starting Due Date'
                styles={{ width: '100%' }}
              />
              :
              <Button
                className={classes.gridButton}
                onClick={handleIncludeMilestonesClick}
                color="primary"
                variant="contained"
                size="small"
                data-cy="create-project-dialog.include-milestones-button"
              >
                Include Milestones
              </Button>
            }
          </Grid>
          <Grid item xs={5} className={classes.buttonsGrid}>
            <Button
              className={classes.gridButton}
              onClick={handleShowOmitAreaDialog}
              color="primary"
              variant="contained"
              size="small"
              data-cy="create-project-dialog.select-areas-button"
            >
                Omit {cust.area}s {
                inputs.templateOmittedAreas.length ? `(${inputs.templateOmittedAreas.length})` : ''
              }
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Dialog
        id={id}
        open={open}
        onClose={handleCancel}
        aria-labelledby={id + '_title'}
        aria-describedby={id + '_form'}
        data-cy="project-list.create-project-dialog"
        disableEnforceFocus={true}
      >
        <DialogTitle id={id + '_title'}>Create Project</DialogTitle>
        <DialogContent id="createProjectDialogContent">
          <DialogContentText id={id + '_form'}>
            Enter details for your new project.
          </DialogContentText>

          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={inputs.name}
            error={Boolean(errorText.name.length)}
            helperText={errorText.name}
            onChange={handleInputChange}
            onContextMenu={handleRightClickInput}
            inputProps={{
              'data-cy': 'create-project-dialog.name',
              maxLength: Consts.ProjectNameMaxLength,
              spellCheck: 'true'
            }}
          />

          <TextField
            className={classes.description}
            id="description"
            label="Description"
            inputProps={{
              'data-cy': 'create-project-dialog.description',
              maxLength: Consts.ProjectDescriptionMaxLength,
              spellCheck: 'true'
            }}
            onChange={handleInputChange}
            onContextMenu={handleRightClickInput}
            value={inputs.description}
            fullWidth
            multiline
            minRows={5}
          />

          <Grid container spacing={1}>
            <Grid item xs={5}>
              <DateTimeDialog
                defaultDate={inputs.dueDate}
                onChange={handleDateChange}
                label="Due Date"
                dataCy="create-project-dialog.due-date"
              />
            </Grid>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <InputLabel id="type_label">Visibility</InputLabel>
                <Select
                  native
                  labelId="type_label"
                  id="visibility"
                  value={inputs.visibility}
                  onChange={handleInputChange}
                  inputProps={{ 'data-cy': 'create-project-dialog.visibility' }}
                >
                  <option value="Restricted">Restricted to assigned users</option>
                  <option value="CompanyWide">Visible to entire company</option>
                  <option value="Private">Only visible to me</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TextField
            margin="dense"
            id="address"
            label="Location"
            type="text"
            fullWidth
            value={inputs.address}
            error={Boolean(errorText.address.length)}
            helperText={errorText.address}
            onChange={handleInputChange}
            onContextMenu={handleRightClickInput}
            inputProps={{
              'data-cy': 'create-project-dialog.address',
              maxLength: Consts.ProjectAddressMaxLength,
              spellCheck: 'true'
            }}
          />

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="city"
                label="City"
                type="text"
                value={inputs.city}
                onChange={handleInputChange}
                onContextMenu={handleRightClickInput}
                inputProps={{
                  'data-cy': 'create-project-dialog.city',
                  maxLength: Consts.ProjectCityMaxLength,
                  spellCheck: 'true'
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                id="state"
                label="State"
                type="text"
                value={inputs.state}
                onChange={handleInputChange}
                onContextMenu={handleRightClickInput}
                inputProps={{
                  'data-cy': 'create-project-dialog.state',
                  maxLength: Consts.ProjectStateMaxLength,
                  spellCheck: 'true'
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                id="postalCode"
                label="Postal Code"
                type="text"
                value={inputs.postalCode}
                onChange={handleInputChange}
                onContextMenu={handleRightClickInput}
                inputProps={{
                  'data-cy': 'create-project-dialog.postalCode',
                  maxLength: Consts.ProjectPostalCodeMaxLength
                }}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth className={classes.select}>
            <InputLabel id="type_label">Project Template</InputLabel>
            <Select
              labelId="type_label"
              name="template"
              value={inputs.template}
              onChange={handleInputChange}
              inputProps={{ id: 'template' }}
              data-cy="create-project-dialog.template"
            >
              {templateOptions}
            </Select>
          </FormControl>
          {templateOptionsDisplay}
          <ImportDataDialog
            onConfirmImportData={handleConfirmImportData}
            shouldShowImport={shouldShowImport}
            importType={
              (inputs.template) ? api.DataImport.ImportTypes.TemplatedProject : api.DataImport.ImportTypes.NewProject
            }
            cust={cust}
            selectedTemplateId={inputs.template}
          />
          {labelManagement}
          {currencyManagement}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            autoFocus
            color="secondary"
            data-cy="create-project-dialog.cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="secondary"
            disabled={currenciesHasError}
            variant="contained"
            data-cy="create-project-dialog.confirm"
          >
            Create
          </Button>
        </DialogActions>
        <MultiItemSelectDialog
          dialogDescription={`Select ${cust.area.toLowerCase()}s to omit from the new project:`}
          dialogId="create-project-dialog.omit-areas-dialog"
          dialogTitle={`Select ${cust.area}s To Omit`}
          isOpen={inputs.templateOmitAreasDialogShown}
          itemList={inputs.templateOmitAreasDialogItemList}
          onCancel={handleCloseTemplateOmitAreasDialog}
          onConfirm={handleConfirmTemplateOmitAreasDialog}
          selectedItemSuffix={<span className={classes.omittedLabel}>{' Omitted'}</span>}
        />
      </Dialog>
    );
  }, [
    classes,
    currenciesHasError,
    currencyManagement,
    errorText,
    handleCancel,
    handleConfirmImportData,
    handleCloseTemplateOmitAreasDialog,
    handleConfirm,
    handleConfirmTemplateOmitAreasDialog,
    handleDateChange,
    handleIncludeMilestonesClick,
    handleInputChange,
    handleRightClickInput,
    handleShowOmitAreaDialog,
    handleTemplatePhasesDueDateChange,
    id,
    inputs,
    labelManagement,
    open,
    shouldShowImport,
    templateOptions,
    templates
  ]);
};

export default CreateProjectDialog;
