import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Link as BrowserLink } from 'react-router-dom';

import api from '../../api';

import { makeStyles } from '@material-ui/core/styles';

import SmallAvatar from '../Icons/SmallAvatar';

import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

import * as Consts from '../../consts/ProjectToolbar';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ProjectPermissionInterface } from '../../PermissionInterface';
import { DeleteButton } from '../Shared/Buttons/DeleteButton';


const useStyles = makeStyles(() => ({
  icon: {
    cursor: 'pointer'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '3px',
    right: '3px'
  },
  inline: {
    display: 'inline'
  }
}));

interface Props {
  content: string;
  fromUserCompanyId: number;
  fromUserCompanyName: string;
  fromUserFullName: string;
  fromUserId: number;
  itemId: number;
  selectedHeader?: string;
  permissions: Partial<ProjectPermissionInterface>;
}

const ActivityLogQuickNote = (props: Props) => {

  const classes = useStyles();

  const {
    content,
    fromUserCompanyId,
    fromUserCompanyName,
    fromUserFullName,
    fromUserId,
    itemId,
    selectedHeader,
    permissions
  } = props;

  const loggedInUserDetails = useSelector((state: RootStateOrAny) => state.ephemeralState.loggedInUserDetails);

  const [isDeleted, setIsDeleted] = useState(false);
  const [isEditQuickNoteDialogOpen, setIsEditQuickNoteDialogOpen] = useState(false);
  const [editedInput, setEditedInput] = useState(content);
  const [isHovered, setIsHovered] = useState(false);

  const isAbleToAlterQuickNotes = useMemo(() => {

    // Currently only want this working for team board

    const isOwnNote = loggedInUserDetails.id === fromUserId;
    const isAllowedToEditAndDelete = (
      (permissions?.TeamBoard?.EditDeleteOwnNote && isOwnNote) ||
      (permissions?.TeamBoard?.EditDeleteOtherNote)
    );

    return (selectedHeader === 'Team Board' && isAllowedToEditAndDelete);
  }, [fromUserId, permissions, loggedInUserDetails, selectedHeader]);

  const handleDeleteQuickNoteClicked = useCallback(() => {

    // Don't need await here. Will auto update via webhook
    api.Project.DeleteQuickNote(itemId);
    setIsDeleted(true);
  }, [itemId]);

  const handleOpenEditDialog = useCallback(() => {

    setIsEditQuickNoteDialogOpen(true);
  }, []);

  const handleCloseEditDialog = useCallback(() => {

    setIsEditQuickNoteDialogOpen(false);
    setEditedInput(content);
  }, [content]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    const value = e.target.value;
    setEditedInput(value);
  }, []);

  const handleConfirm = useCallback(() => {

    setIsEditQuickNoteDialogOpen(false);
    // Don't need await here. Will auto update via webhook
    api.Project.EditQuickNote(itemId, editedInput);
  }, [itemId, editedInput]);

  const renderEditQuickNoteDialog = useMemo(() => {

    if (!isAbleToAlterQuickNotes) {
      return null;
    }

    return (
      <Dialog open={isEditQuickNoteDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>
          <div data-cy="change-history.dialog.title">
            Edit Quick Note
          </div>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            error={!editedInput.length}
            fullWidth
            id="fullName"
            label="Title"
            type="text"
            value={editedInput}
            onChange={handleInputChange}
            inputProps={{
              'data-cy': 'activity-quick-note.edit-input',
              maxLength: Consts.QuickNoteMaxLength
            }}
          />
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="secondary">Cancel</Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleConfirm}
              disabled={!editedInput.length}
              data-cy='activity-quick-note.edit-confirm'
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }, [
    editedInput,
    handleInputChange,
    isAbleToAlterQuickNotes,
    isEditQuickNoteDialogOpen,
    handleCloseEditDialog,
    handleConfirm
  ]);

  const renderIcons = useMemo(() => {

    if (isAbleToAlterQuickNotes && isHovered) {
      return (
        <div className={classes.iconWrapper}>
          <Tooltip title='Edit quick note' arrow placement='bottom'>
            <CreateIcon
              className={classes.icon}
              color='secondary'
              onClick={handleOpenEditDialog}
              data-cy='activity-quick-note.edit'
            />
          </Tooltip>
          <Tooltip title='Delete quick note' arrow placement='bottom'>
            <DeleteButton
              onClick={handleDeleteQuickNoteClicked}
              dataCy='activity-quick-note.delete'
            />
          </Tooltip>
        </div>
      );
    }
  }, [classes, isAbleToAlterQuickNotes, handleDeleteQuickNoteClicked, handleOpenEditDialog, isHovered]);

  return useMemo(() => {

    // This item will be removed via the websocket, but until then we want to not display it so that the delete button
    // cannot be clicked a second time which would result in an error from the server
    if (isDeleted) {
      return null;
    }

    return (
      <Fragment>
        <ListItem
          onMouseLeave={() => setIsHovered(false)}
          onMouseEnter={() => setIsHovered(true)}
          style={{ position: 'relative' }}
          alignItems="flex-start"
          data-cy="activity-log.item"
          dense
        >
          <SmallAvatar
            name={(fromUserFullName.length) ? fromUserFullName[0] : ''}
            id={fromUserCompanyId || 0}
            style={{ marginTop: '8px' }}
          />
          <ListItemText
            primary={content}
            secondary={
              <Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {fromUserFullName} (
                  <Link
                    component={BrowserLink}
                    to={'/companies/' + fromUserCompanyId}
                    variant="body2"
                    color="textPrimary"
                    style={{ textDecoration: 'underline' }}
                  >
                    {fromUserCompanyName}
                  </Link>
                )
                </Typography>
              </Fragment>
            }
          />
          {renderIcons}
        </ListItem>
        <Divider component="li" />
        {renderEditQuickNoteDialog}
      </Fragment>
    );
  }, [
    classes,
    content,
    fromUserCompanyId,
    fromUserFullName,
    fromUserCompanyName,
    isDeleted,
    renderIcons,
    renderEditQuickNoteDialog
  ]);
};

export default ActivityLogQuickNote;
