import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    // Avoid overlap issues with any chat buttons at bottom of screen
    height: 'calc(100vh - 175px)'
  }
}));

const FullScreenDialog = (props) => {

  const classes = useStyles();

  const {
    content,
    onClose,
    open,
    title
  } = props;

  return useMemo(() => {

    return (
      <Dialog
        classes={{ paper: classes.fullHeight }}
        data-cy="full-screen-dialog"
        fullWidth
        maxWidth="xl"
        onClose={onClose}
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.content}>{content}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained" data-cy="full-screen-dialog.close-button">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [classes, content, onClose, open, title]);
};

export default FullScreenDialog;
