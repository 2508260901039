import { WBSDataRowShapeWithStates } from '../../wbs/calculateWBSState';
import { WBSTableRootState } from '../../stateTypes';
import { calculateResourceTableWithSelectedRow } from './calculateTotal';

export const getParentCodes = (wbsCode: string): string[] => {

  const codes = wbsCode.split(' ');

  let parentCode = '';
  const parentCodes: string[] = [];

  for (const code of codes) {
    parentCode += code;
    parentCodes.push(parentCode);
    parentCode += ' ';
  }

  return parentCodes;
};

const updateRelatedRowsBySelectedWbsCode = (selectedWbsCode: string, wbsDataRows: WBSDataRowShapeWithStates[]) => {

  const parentCodes = getParentCodes(selectedWbsCode);
  for (const row of wbsDataRows) {

    const { wbsCode } = row;
    if (parentCodes.includes(wbsCode) || wbsCode === selectedWbsCode || wbsCode.startsWith(`${selectedWbsCode} `)) {

      row.uiState.isRelatedToSelectedRow = true;
    }
    else {
      row.uiState.isRelatedToSelectedRow = false;
    }
  }
};

export const deselectRelatedRows = (dataRows: WBSDataRowShapeWithStates[]) => {

  dataRows.forEach((row) => (row.uiState.isRelatedToSelectedRow = false));
};

export const getSelectedRows = (dataRows: WBSDataRowShapeWithStates[]) => {

  return dataRows.filter((r) => r.uiState.isSelected);
};

export const updateRelatedRows = (dataRows: WBSDataRowShapeWithStates[]) => {

  const currentSelectedRows = getSelectedRows(dataRows);

  if (currentSelectedRows.length === 1) {

    const row = currentSelectedRows[0];
    const selectedWbsCode = row.wbsCode;
    updateRelatedRowsBySelectedWbsCode(selectedWbsCode, dataRows);
  }
  else {

    deselectRelatedRows(dataRows);
  }
};

export const selectWbsRows = (state: WBSTableRootState, isResources: boolean, clientSidePredictionId: number, ctrlIsDown: boolean, shiftIsDown: boolean) => {

  const dataRows = (isResources ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];

  const selectedRows = dataRows.filter((r) => r.uiState.isSelected);
  const visibleRows = dataRows.filter((r) => !r.uiState.hidden);

  if (ctrlIsDown) {
    const row = visibleRows.find((r) => (r.clientSidePredictionId === clientSidePredictionId));

    if (row) {
      row.uiState.isSelected = !row.uiState.isSelected;
    }
  }
  else if (shiftIsDown) {

    const selectedRowIndex = visibleRows.findIndex((r) => (r.clientSidePredictionId === selectedRows[0]?.clientSidePredictionId));
    const currentSelectedRowIndex = visibleRows.findIndex((r) => (r.clientSidePredictionId === clientSidePredictionId));
    dataRows.forEach((r) => (r.uiState.isSelected = false));

    if (currentSelectedRowIndex !== -1) {
      for (let i = Math.min(selectedRowIndex, currentSelectedRowIndex); i <= Math.max(selectedRowIndex, currentSelectedRowIndex); i++) {
        visibleRows[i].uiState.isSelected = true;
      }
    }
  }
  else {

    dataRows.forEach((r) => (r.uiState.isSelected = (clientSidePredictionId === r.clientSidePredictionId)));
  }

  updateRelatedRows(dataRows);
  const currentSelectedRows = getSelectedRows(dataRows);
  const noMoreThan1MainRowSelected = currentSelectedRows.length <= 1;
  isResources ? state.rbsNoMoreThan1MainRowSelected = noMoreThan1MainRowSelected : state.wbsNoMoreThan1MainRowSelected = noMoreThan1MainRowSelected;

  if (!isResources && currentSelectedRows.length === 1) {

    calculateResourceTableWithSelectedRow(state.rbsDataRows, state.wbsDataRows, currentSelectedRows[0]);
  }
};
