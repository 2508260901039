import Big from 'big.js';
import { WBSDataRowShapeWithStates } from '../../wbs/calculateWBSState';
import { getLeafRows } from 'wbs/dist/getLeafRows';
import { createWbsLookup } from 'wbs/dist/createWbsLookup';
import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';
import { WBSOverrideShapeClient } from 'wbs/dist/types/WBSOverrideShapeClient';
import { getSelectedRows } from './selection';
import { WBSCodeLookupShape } from 'wbs/dist/types/WBSLookupShape';
import { WBSUnitsShape } from 'wbs/dist/packages/wbs/src/types/WBSUnitsShape';
import { getWbsFullValue } from 'wbs/dist/getFullValue';
import { WBSColumnDataUnitsShape } from 'wbs/dist/types/WBSColumnDataUnitsShape';
import { getDefaultUnits } from 'wbs/dist/getDefaultUnits';
import { numericOnlyString } from '../../../helpers/numericOnlyString';

import Parse from 'parse';
import { ProjectCurrencyShape } from '../../../types/api/ProjectCurrencyShape';


export const decimal = (value?: string): Big => {

  try {
    return new Big(value?.replace(/[^%0-9.-]/g, '') ?? '0.00');
  }
  catch (e) {

    console.error(e, value);
    return new Big('0.00');
  }
};

export const zero = (): Big => {

  return new Big('0.00');
};

export const isZero = (num: Big): boolean => {

  return num.toNumber() === 0;
};

export const copyParentValueIfOnlyChildAndReturnChangeSet = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  currentRow: WBSDataRowShapeWithStates,
  isResources: boolean,
  changeSet: any[]
) => {

  if (decimal(currentRow.quantity).toNumber() !== 0 && decimal(currentRow.cost).toNumber() !== 0) {
    return changeSet;
  }

  const wbsLookup = createWbsLookup(
    rbsDataRows.filter((r) => !r.uiState.isDeleting),
    wbsDataRows.filter((r) => !r.uiState.isDeleting)
  );

  const codeLookup = (isResources ? wbsLookup.rbs : wbsLookup.wbs).codeLookup;

  const currentCodeLookup = codeLookup[currentRow.wbsCode];
  const parentRow = currentCodeLookup?.parentRow;
  const parentRowCodeLookup = codeLookup[parentRow?.wbsCode ?? ''];

  const addToChangeSet = (additionalChanges: { [index: string]: any }) => {

    const existingChange = changeSet.find((c) => c.id === currentRow.id);
    if (existingChange) {
      for (const [key, value] of Object.entries(additionalChanges)) {
        existingChange[key] = value;
      }
    }
    else {
      changeSet.push(additionalChanges);
    }
  };

  if (
    parentRow &&
    parentRowCodeLookup &&
    parentRowCodeLookup.children.filter((r) => r.wbsCode.trim() === '').length <= 1
  ) {

    currentRow.quantity = parentRow.quantity;
    currentRow.cost = parentRow.cost;
    currentRow.inputCost = parentRow.inputCost;
    currentRow.inputProjectCurrencyId = parentRow.inputProjectCurrencyId;
    currentRow.total = parentRow.total;
    currentRow.units = { ...parentRow.units };

    if (isResources) {
      currentRow.originalValue = {
        quantity: currentRow.quantity,
        cost: currentRow.cost,
        inputCost: currentRow.inputCost,
        inputProjectCurrencyId: currentRow.inputProjectCurrencyId,
        units: currentRow.units
      };
    }

    addToChangeSet({
      id: currentRow.id,
      quantity: currentRow.quantity,
      inputCost: currentRow.inputCost,
      inputProjectCurrencyId: currentRow.inputProjectCurrencyId,
      units: currentRow.units
    });
  }

  return changeSet;
};

export const calculateTotal = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  hasDefinedResources: boolean
) => {

  const wbsLookup = createWbsLookup(
    rbsDataRows.filter((r) => !r.uiState.isDeleting),
    wbsDataRows.filter((r) => !r.uiState.isDeleting)
  );

  // Calculate leaf resources
  const topMostRbsRows = wbsLookup.rbs.topLevelDataRows;
  const allResourceLeafNodes: WBSDataRowShape[] = [];

  for (const rbsRow of topMostRbsRows) {
    allResourceLeafNodes.push(...(getLeafRows(wbsLookup.rbs.codeLookup, rbsRow.wbsCode)));
  }

  // Calculate task total
  const topMostWbsRows = wbsLookup.wbs.topLevelDataRows;

  const allLeafNodes: WBSDataRowShape[] = [];
  for (const wbsRow of topMostWbsRows) {
    allLeafNodes.push(...getLeafRows(wbsLookup.wbs.codeLookup, wbsRow.wbsCode));
  }

  for (const leaf of allLeafNodes) {

    const columnState = (leaf as WBSDataRowShapeWithStates).columnState;
    const allMultipliers = Object.values(columnState ?? [])
      .filter((column) => column.setMultiplier !== undefined)
      .map((m) => decimal(m.setMultiplier));

    const combinedMultipliers = allMultipliers.reduce((accumulator, currentValue) => {

      return accumulator.mul(currentValue);
    }, Big(1));

    if (hasDefinedResources) {

      let taskTotal = zero();

      for (const wbsDataResource of allResourceLeafNodes) {
        const resourceWithOverrides = leaf?.resourceOverrides?.find((override) => override.id === wbsDataResource.id);
        const quantity = decimal(
          (resourceWithOverrides?.quantity) ?? (wbsDataResource as WBSDataRowShapeWithStates).originalValue?.quantity
        );
        const cost = decimal(
          (resourceWithOverrides?.cost) ?? (wbsDataResource as WBSDataRowShapeWithStates).originalValue?.cost
        );
        const total = quantity.mul(cost);
        taskTotal = taskTotal.plus(total);
      }

      leaf.total = `${taskTotal.mul(combinedMultipliers)}`;
    }
    else {
      const quantity = decimal(leaf.quantity);
      const cost = decimal(leaf.cost);
      const total = quantity.mul(cost);
      leaf.total = total.mul(combinedMultipliers).toString();
    }
  }

  // calculate all parents
  const nonLeafNodes = wbsDataRows.filter((wbs) => allLeafNodes.indexOf(wbs) === -1);
  for (const nonLeafNode of nonLeafNodes) {

    const leafRows = getLeafRows(wbsLookup.wbs.codeLookup, nonLeafNode.wbsCode) as WBSDataRowShape[];
    if (hasDefinedResources) {

      const columnState = (nonLeafNode as WBSDataRowShapeWithStates).columnState;
      const setMultiplier = Object.values(columnState ?? [])
        .find((column) => column.setMultiplier !== undefined)?.setMultiplier;
      const multiplier = decimal(setMultiplier ?? '1');
      const total = leafRows.reduce((accumulator, currentValue) => {

        return accumulator.plus(decimal(currentValue?.total));
      }, zero());

      nonLeafNode.total = multiplier.mul(total).toString();
    }
    else {

      const quantity = leafRows.reduce((accumulator, currentValue) => {

        return accumulator.plus(decimal(currentValue?.quantity));
      }, zero());

      const accumulatedCost = (quantity.toFixed(2) === '0.00') ?
        Big(0) :
        leafRows.reduce((accumulator, currentValue) => {

          // Need to use (cost * quantity) for the total here instead of currentValue?.total since currentValue?.total
          //  can be affected by multipliers which will then affect the cost column of parent rows
          const total = decimal(currentValue?.cost).mul(decimal(currentValue?.quantity));
          return accumulator.plus(total);
        }, zero()).div(quantity);

      // total for parents will always be the sum of the leaf rows currentValue.total column
      const total = leafRows.reduce((accumulator, currentValue) => {

        return accumulator.plus(decimal(currentValue?.total));
      }, zero());

      nonLeafNode.quantity = quantity.toString();
      nonLeafNode.cost = accumulatedCost.toString();
      nonLeafNode.inputCost = nonLeafNode.cost;
      nonLeafNode.inputProjectCurrencyId = null;
      nonLeafNode.total = total.toString();

      nonLeafNode.units = {
        quantity: getWbsFullValue(
          nonLeafNode.quantity,
          nonLeafNode.units,
          'quantity',
          { wbsCode: nonLeafNode.wbsCode, codeLookup: wbsLookup.wbs.codeLookup },
          true
        )
      };
    }
  }
};

export const calculateResourceTableWithoutSelectedRow = (rbsDataRows: WBSDataRowShapeWithStates[], wbsDataRows: WBSDataRowShapeWithStates[]) => {

  const wbsLookup = createWbsLookup(
    rbsDataRows.filter((r) => !r.uiState.isDeleting),
    wbsDataRows.filter((r) => !r.uiState.isDeleting)
  );

  // Calculate leaf resources
  const topMostRbsRows = wbsLookup.rbs.topLevelDataRows;
  const allResourceLeafNodes: WBSDataRowShape[] = [];

  for (const rbsRow of topMostRbsRows) {
    allResourceLeafNodes.push(...(getLeafRows(wbsLookup.rbs.codeLookup, rbsRow.wbsCode)));
  }

  for (const resource of allResourceLeafNodes) {
    const cost = decimal((resource as WBSDataRowShapeWithStates).originalValue?.cost);
    const quantity = decimal((resource as WBSDataRowShapeWithStates).originalValue?.quantity);
    const inputCost = decimal((resource as WBSDataRowShapeWithStates).originalValue?.inputCost);

    resource.cost = cost.toString();
    resource.inputCost = inputCost.toString();
    resource.inputProjectCurrencyId = (resource as WBSDataRowShapeWithStates).originalValue?.inputProjectCurrencyId ?? null;
    resource.quantity = quantity.toString();
    resource.total = cost.mul(quantity).toString();
    const originalUnits = (resource as WBSDataRowShapeWithStates).originalValue?.units;
    if (originalUnits) {
      resource.units = originalUnits;
    }
  }

  // Calculate all resource parents
  calculateAllResourceParents(rbsDataRows, allResourceLeafNodes, wbsLookup.rbs.codeLookup);
};

export const calculateResourceTableWithSelectedRow = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  selectedRow: WBSDataRowShapeWithStates
) => {

  const wbsLookup = createWbsLookup(
    rbsDataRows.filter((r) => !r.uiState.isDeleting),
    wbsDataRows.filter((r) => !r.uiState.isDeleting)
  );

  const wbsLeafNodesFromSelected: WBSDataRowShape[] = [];
  wbsLeafNodesFromSelected.push(...getLeafRows(wbsLookup.wbs.codeLookup, selectedRow.wbsCode));

  const topMostRbsRows = wbsLookup.rbs.topLevelDataRows;
  const allResourceLeafNodes: WBSDataRowShape[] = [];

  for (const rbsRow of topMostRbsRows) {
    allResourceLeafNodes.push(...(getLeafRows(wbsLookup.rbs.codeLookup, rbsRow.wbsCode)));
  }

  const wbsOverrides = selectedRow.resourceOverrides ?? [];

  // Get all resource overrides
  for (const resourceLeaf of allResourceLeafNodes) {
    let quantity = zero();
    let total = zero();
    let inputCost = zero();
    let inputProjectCurrencyId = null;

    for (const wbsLeaf of wbsLeafNodesFromSelected) {
      const override = wbsLeaf.resourceOverrides?.find((o) => o.id === resourceLeaf.id);
      const currentCost = (override?.cost === undefined) ?
        decimal((resourceLeaf as WBSDataRowShapeWithStates).originalValue?.cost) :
        decimal(override.cost);
      const currentQuantity = (override?.quantity === undefined) ?
        decimal((resourceLeaf as WBSDataRowShapeWithStates).originalValue?.quantity) :
        decimal(override.quantity);
      const currentTotal = currentCost?.mul(currentQuantity);
      const currentInputCost = (override?.inputCost === undefined) ?
        (resourceLeaf as WBSDataRowShapeWithStates).originalValue?.inputCost :
        override.inputCost;
      const currentInputProjectCurrencyId = (override?.inputProjectCurrencyId === undefined) ?
        (resourceLeaf as WBSDataRowShapeWithStates).originalValue?.inputProjectCurrencyId ?? null :
        override.inputProjectCurrencyId;

      inputCost = decimal(currentInputCost);
      inputProjectCurrencyId = currentInputProjectCurrencyId;
      quantity = quantity.plus(currentQuantity);
      total = total.plus(currentTotal);
    }

    resourceLeaf.quantity = quantity.toString();
    resourceLeaf.total = total.toString();
    resourceLeaf.cost = (quantity > zero()) ? total.div(quantity).toString() : '0.00';
    resourceLeaf.inputCost = inputCost.toString();
    resourceLeaf.inputProjectCurrencyId = inputProjectCurrencyId;

    const existingOverride = wbsOverrides.find((o) => o.id === resourceLeaf.id);
    if (existingOverride) {
      resourceLeaf.units = { ...existingOverride.units };
    }
  }

  // Calculate all resource parents
  calculateAllResourceParents(rbsDataRows, allResourceLeafNodes, wbsLookup.rbs.codeLookup);
};

const calculateAllResourceParents = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  allResourceLeafNodes: WBSDataRowShape[],
  codeLookup: WBSCodeLookupShape
) => {

  const nonLeafNodes = rbsDataRows.filter((wbs) => allResourceLeafNodes.indexOf(wbs) === -1);
  for (const nonLeafNode of nonLeafNodes) {

    const leafRows = getLeafRows(codeLookup, nonLeafNode.wbsCode) as WBSDataRowShape[];

    const quantity = leafRows.reduce((accumulator, currentValue) => {

      return accumulator.plus(decimal(currentValue?.quantity));
    }, zero());

    const accumulatedCost = quantity.toFixed(2) === '0.00' ? Big(0) : leafRows.reduce((accumulator, currentValue) => {

      // Need to use (cost * quantity) for the total here instead of currentValue?.total since currentValue?.total
      // can be affected by multipliers which will then affect the cost column of parent rows
      const total = decimal(currentValue?.cost).mul(decimal(currentValue?.quantity));
      return accumulator.plus(total);
    }, zero()).div(quantity);

    const accumulatedInputCost = quantity.toFixed(2) === '0.00' ? Big(0) : leafRows.reduce((accumulator, currentValue) => {

      // Need to use (inputCost * quantity) for the total here instead of currentValue?.total since currentValue?.total
      // can be affected by multipliers which will then affect the cost column of parent rows
      const total = decimal(currentValue?.inputCost).mul(decimal(currentValue?.quantity));
      return accumulator.plus(total);
    }, zero()).div(quantity);

    const total = quantity.mul(accumulatedCost);
    nonLeafNode.quantity = quantity.toString();
    nonLeafNode.cost = accumulatedCost.toString();
    nonLeafNode.inputCost = accumulatedInputCost.toString();
    nonLeafNode.inputProjectCurrencyId = null;
    nonLeafNode.total = total.toString();
    nonLeafNode.units = {
      ...nonLeafNode.units,
      quantity: getWbsFullValue(
        nonLeafNode.quantity,
        nonLeafNode.units,
        'quantity',
        { wbsCode: nonLeafNode.wbsCode, codeLookup },
        true
      )
    };
  }
};

export const updateQuantity = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  currentRow: WBSDataRowShapeWithStates,
  value: any,
  hasDefinedResources: boolean
) => {

  const wbsLookup = createWbsLookup(rbsDataRows, wbsDataRows);
  const changeSet: { id?: number, quantity?: string, units?: WBSUnitsShape }[] = [];
  const quantityNumeric = numericOnlyString(value);

  const topMostWbsRows = wbsLookup.wbs.topLevelDataRows;
  const allLeafNodes: WBSDataRowShape[] = [];
  for (const wbsRow of topMostWbsRows) {
    allLeafNodes.push(...getLeafRows(wbsLookup.wbs.codeLookup, wbsRow.wbsCode));
  }

  const leafRows = allLeafNodes.filter((r) => r.wbsCode.startsWith(`${currentRow.wbsCode} `));
  if (leafRows.length === 0) {

    currentRow.quantity = quantityNumeric;
    changeSet.push({
      id: currentRow.id,
      quantity: quantityNumeric,
      units: { ...currentRow.units }
    });

    if (currentRow.originalValue) {
      currentRow.originalValue = { ...currentRow.originalValue, quantity: quantityNumeric, units: { ...currentRow.units } };
    }
  }
  else {

    const currentQuantity = decimal(currentRow.quantity ?? '0.00');
    const shouldUpdateLeafQuantUnits = !currentRow.units.quantity.includes('/');
    const originalTotalQuantity = leafRows.reduce((accumulator, currentValue) => {

      return accumulator.plus(decimal(currentValue?.quantity));
    }, zero());

    let percentages = {} as { [key: string]: Big };

    if (isZero(originalTotalQuantity)) {
      percentages = getQuantityPercentages(wbsLookup.wbs.codeLookup, currentRow.wbsCode);
    }

    leafRows.forEach((r) => {

      const updatedQuantity = (isZero(originalTotalQuantity)) ?
        (percentages[r.wbsCode]?.mul(currentQuantity) ?? zero()) :
        decimal(r.quantity ?? '0.00').div(originalTotalQuantity).mul(currentQuantity);
      r.quantity = updatedQuantity.toString();

      const leafNonOverrideChangeSet: { id?: number, quantity: string, units?: WBSUnitsShape } = {
        id: r.id,
        quantity: updatedQuantity.toString()
      };

      if (shouldUpdateLeafQuantUnits) {
        leafNonOverrideChangeSet.units = { ...currentRow.units };
        r.units = { ...currentRow.units };
      }

      if ((r as WBSDataRowShapeWithStates).originalValue) {
        // @ts-ignore
        r.originalValue = { ...r.originalValue, quantity: updatedQuantity.toString(), units: { ...r.units } };
      }

      changeSet.push(leafNonOverrideChangeSet);
    });
  }

  calculateTotal(rbsDataRows, wbsDataRows, hasDefinedResources);
  return changeSet;
};

const getTotalOfDescendantsWithRelevantMultiplier = (leafRows: WBSDataRowShapeWithStates[], columnId: number) => {

  let total = zero();
  for (const leafRow of leafRows) {
    const relevantMultiplier = leafRow.columnState?.[columnId]?.setMultiplier ?? '1';
    total = total.plus(decimal(leafRow.quantity).mul(relevantMultiplier).mul(leafRow.cost));
  }

  return total;
};

export const updateSetMultiplier = (
  wbsDataRows: WBSDataRowShapeWithStates[],
  editedDataRow: WBSDataRowShapeWithStates,
  value: string,
  columnId: number
) => {

  const localChangeSet: {
    additionalColumnData?: { columnId: number, newValue: string, units?: WBSColumnDataUnitsShape }[]
    id: number,
    units?: WBSUnitsShape
  }[] = [];

  const applyToChangeSetAndUpdateRow = (rowToUpdate: WBSDataRowShapeWithStates, newValue: string) => {

    const update = { setMultiplier: newValue, units: getDefaultUnits('columnData') };
    // @ts-ignore
    rowToUpdate.columnState[columnId] = update;

    localChangeSet.push({ id: rowToUpdate.id!, additionalColumnData: [{ columnId, newValue }] });
  };

  const multiplierNumeric = numericOnlyString(value);
  const units = editedDataRow.columnState[columnId ?? -1].units;

  localChangeSet.push({ id: editedDataRow.id!, additionalColumnData: [{ columnId, newValue: multiplierNumeric, units }] });

  const allAncestorRows = wbsDataRows
    .filter((r) => editedDataRow.wbsCode.startsWith(r.wbsCode) && editedDataRow.wbsCode !== r.wbsCode);
  const allDescendantRows = wbsDataRows
    .filter((r) => r.wbsCode.startsWith(`${editedDataRow.wbsCode} `));

  // Update all descendants first. All descendants will inherit the same multiplier value.
  allDescendantRows.forEach((r) => applyToChangeSetAndUpdateRow(r, multiplierNumeric));

  // Update all ancestor rows.
  const wbsLookup = createWbsLookup([], wbsDataRows);
  allAncestorRows.forEach((r) => {

    const leafRows = getLeafRows(wbsLookup.wbs.codeLookup, r.wbsCode);
    const totalOfDescendantsOnlyIncludingRelevantMultiplier = getTotalOfDescendantsWithRelevantMultiplier(
      leafRows as WBSDataRowShapeWithStates[],
      columnId
    );
    const ancestorTotalIgnoringMultipliers = decimal(r.quantity).mul(r.cost);
    if (isZero(totalOfDescendantsOnlyIncludingRelevantMultiplier)) {
      applyToChangeSetAndUpdateRow(r, multiplierNumeric);
    }
    else {
      const newValue = totalOfDescendantsOnlyIncludingRelevantMultiplier.div(ancestorTotalIgnoringMultipliers);
      applyToChangeSetAndUpdateRow(r, newValue.toString());
    }
  });

  return localChangeSet;
};

export const updateResourceQuantity = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  currentRbsRow: WBSDataRowShapeWithStates,
  value: string,
  currentWbsRow?: WBSDataRowShapeWithStates
) => {

  const wbsLookup = createWbsLookup(rbsDataRows, wbsDataRows);
  const quantityNumeric = numericOnlyString(value);
  const changeSet: {
    id: number,
    quantity?: string,
    resourceOverrides?: WBSOverrideShapeClient[],
    units?: WBSUnitsShape
  }[] = [];

  // Calculate resource leaf nodes
  const topMostRbsRows = wbsLookup.rbs.topLevelDataRows;
  const allLeafNodes: WBSDataRowShape[] = [];
  for (const rbsRow of topMostRbsRows) {
    allLeafNodes.push(...getLeafRows(wbsLookup.rbs.codeLookup, rbsRow.wbsCode));
  }

  const isWritingOverride = Boolean(currentWbsRow);
  const leafRows = allLeafNodes.filter((r) => r.wbsCode.startsWith(`${currentRbsRow.wbsCode} `));
  const wbsOverrides = currentWbsRow?.resourceOverrides ?? [] as WBSOverrideShapeClient[];

  if (leafRows.length === 0) {

    // Calculate non leaf nodes
    currentRbsRow.quantity = quantityNumeric;
    if (isWritingOverride) {

      const existingOverride = wbsOverrides.find((o) => o.id === currentRbsRow.id);
      if (existingOverride) {
        existingOverride.quantity = quantityNumeric;
        existingOverride.units = { ...currentRbsRow.units };
      }
      else {
        wbsOverrides.push({
          id: currentRbsRow.id!,
          quantity: quantityNumeric,
          inputCost: currentRbsRow.inputCost,
          cost: currentRbsRow.cost,
          inputProjectCurrencyId: currentRbsRow.inputProjectCurrencyId,
          units: { ...currentRbsRow.units }
        });
      }

      currentWbsRow!.resourceOverrides = wbsOverrides;
      changeSet.push({ id: currentWbsRow?.id!, resourceOverrides: [...wbsOverrides] });
    }
    else {

      if (currentRbsRow.originalValue) {
        const newOriginalValue = { quantity: quantityNumeric, units: { ...currentRbsRow.units } };
        currentRbsRow.originalValue = { ...currentRbsRow.originalValue, ...newOriginalValue };
      }

      changeSet.push({ id: currentRbsRow.id!, quantity: quantityNumeric, units: { ...currentRbsRow.units } });
    }
  }
  else {

    // Calculate resource leaf nodes
    const shouldUpdateLeafQuantUnits = !currentRbsRow.units.quantity.includes('/');
    const currentQuantity = decimal(currentRbsRow.quantity ?? '0.00');
    const originalTotalQuantity = leafRows.reduce((accumulator, currentValue) => {

      return accumulator.plus(currentValue?.quantity);
    }, zero());

    let percentages = {} as { [key: string]: Big };

    if (isZero(originalTotalQuantity)) {
      percentages = getQuantityPercentages(wbsLookup.rbs.codeLookup, currentRbsRow.wbsCode);
    }

    leafRows.forEach((r) => {

      const updatedQuantity = (isZero(originalTotalQuantity)) ?
        (percentages[r.wbsCode]?.mul(currentQuantity) ?? zero()) :
        decimal(r.quantity ?? '0.00').div(originalTotalQuantity).mul(currentQuantity);
      r.quantity = updatedQuantity.toString();

      if (isWritingOverride) {

        const existingOverride = wbsOverrides.find((o) => o.id === r.id);
        if (existingOverride) {

          existingOverride.quantity = updatedQuantity.toString();
          existingOverride.units = { ...r.units };

          if (shouldUpdateLeafQuantUnits) {
            existingOverride.units = { ...currentRbsRow.units };
            r.units = { ...currentRbsRow.units };
          }
        }
        else {
          wbsOverrides.push({
            id: r.id!,
            quantity: updatedQuantity.toString(),
            inputCost: r.inputCost,
            cost: r.cost,
            inputProjectCurrencyId: r.inputProjectCurrencyId,
            units: { ...r.units }
          });
        }
      }
      else {

        const leafNonOverrideChangeSet: { id: number, quantity: string, units?: WBSUnitsShape } = {
          id: r.id!,
          quantity: updatedQuantity.toString()
        };

        if (shouldUpdateLeafQuantUnits) {
          leafNonOverrideChangeSet.units = { ...currentRbsRow.units };
          r.units = { ...currentRbsRow.units };
        }

        if ((r as WBSDataRowShapeWithStates).originalValue) {
          const newOriginalValue = { quantity: updatedQuantity.toString(), units: { ...r.units } };
          // @ts-ignore
          r.originalValue = { ...r.originalValue, ...newOriginalValue };
        }

        changeSet.push(leafNonOverrideChangeSet);
      }
    });

    if (isWritingOverride) {
      currentWbsRow!.resourceOverrides = wbsOverrides;
      changeSet.push({ id: currentWbsRow?.id!, resourceOverrides: [...wbsOverrides] });
    }
  }

  calculateTotal(rbsDataRows, wbsDataRows, true);
  const selectedWbsRows = getSelectedRows(wbsDataRows);
  if (selectedWbsRows.length === 1) {
    calculateResourceTableWithSelectedRow(rbsDataRows, wbsDataRows, selectedWbsRows[0]);
  }
  else {
    calculateResourceTableWithoutSelectedRow(rbsDataRows, wbsDataRows);
  }

  return changeSet;
};


export const updateCost = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  currentRow: WBSDataRowShapeWithStates,
  value: string,
  currency: ProjectCurrencyShape,
  hasDefinedResources: boolean
) => {

  const wbsLookup = createWbsLookup(rbsDataRows, wbsDataRows);
  const changeSet: { id?: number, inputCost?: string, inputProjectCurrencyId?: number | null }[] = [];
  const inputCost = numericOnlyString(value);
  const cost = (Parse.Float(inputCost) * Parse.Float(currency.exchangeRate)).toString();
  const isChangingCurrency = (currentRow.originalValue?.inputProjectCurrencyId !== currency.id);

  // Calculate leaf nodes
  const topMostWbsRows = wbsLookup.wbs.topLevelDataRows;
  const allLeafNodes: WBSDataRowShape[] = [];
  for (const wbsRow of topMostWbsRows) {
    allLeafNodes.push(...getLeafRows(wbsLookup.wbs.codeLookup, wbsRow.wbsCode));
  }

  // Calculate children leaf nodes
  const leafRows = allLeafNodes.filter((r) => r.wbsCode.startsWith(`${currentRow.wbsCode} `));

  if (leafRows.length === 0) {
    changeSet.push({ id: currentRow.id, inputCost, inputProjectCurrencyId: currency.id });

    if (currentRow.originalValue) {
      currentRow.originalValue = { ...currentRow.originalValue, inputCost, inputProjectCurrencyId: currency.id, cost };
    }

    currentRow.cost = cost;
    currentRow.inputCost = inputCost;
    currentRow.inputProjectCurrencyId = currency.id;
  }
  else {

    const rowsToIterate = [...leafRows];
    if (isChangingCurrency) {
      // We need to run calculations against leaf rows INCLUDING the parent row if changing currencies.
      rowsToIterate.push(currentRow);
    }

    rowsToIterate.forEach((r) => {

      if ((r as WBSDataRowShapeWithStates).originalValue) {
        // @ts-ignore
        r.originalValue = { ...r.originalValue, inputCost, inputProjectCurrencyId: currency.id, cost };
      }

      changeSet.push({ id: r.id, inputCost, inputProjectCurrencyId: currency.id });

      r.cost = cost;
      r.inputCost = inputCost;
      r.inputProjectCurrencyId = currency.id;
    });
  }

  calculateTotal(rbsDataRows, wbsDataRows, hasDefinedResources);
  return changeSet;
};

export const updateResourceCost = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  currentRbsRow: WBSDataRowShapeWithStates,
  value: string,
  currency: ProjectCurrencyShape,
  currentWbsRow?: WBSDataRowShapeWithStates
) => {

  const inputCost = numericOnlyString(value);
  const cost = (Parse.Float(inputCost) * Parse.Float(currency.exchangeRate)).toString();
  const wbsLookup = createWbsLookup(rbsDataRows, wbsDataRows);
  const changeSet: {
    id: number,
    inputCost?: string,
    inputProjectCurrencyId?: number | null,
    resourceOverrides?: WBSOverrideShapeClient[]
  }[] = [];

  // Calculate resource leaf nodes
  const topMostRbsRows = wbsLookup.rbs.topLevelDataRows;
  const allLeafNodes: WBSDataRowShape[] = [];
  for (const rbsRow of topMostRbsRows) {
    allLeafNodes.push(...getLeafRows(wbsLookup.rbs.codeLookup, rbsRow.wbsCode));
  }

  const leafRows = allLeafNodes.filter((r) => r.wbsCode.startsWith(`${currentRbsRow.wbsCode} `));
  const isWritingOverride = Boolean(currentWbsRow);
  const wbsOverrides = currentWbsRow?.resourceOverrides ?? [] as WBSOverrideShapeClient[];

  if (leafRows.length === 0) {

    currentRbsRow.cost = cost;
    currentRbsRow.inputCost = inputCost;
    currentRbsRow.inputProjectCurrencyId = currency.id;
    if (isWritingOverride) {

      const existingOverride = currentWbsRow?.resourceOverrides?.find((o) => o.id === currentRbsRow.id);
      if (existingOverride) {
        existingOverride.cost = cost;
        existingOverride.inputCost = inputCost;
        existingOverride.inputProjectCurrencyId = currency.id;
      }
      else {
        wbsOverrides.push({
          id: currentRbsRow.id!,
          inputCost,
          cost,
          inputProjectCurrencyId: currency.id,
          quantity: currentRbsRow.quantity,
          units: { ...currentRbsRow.units }
        });
      }

      currentWbsRow!.resourceOverrides = wbsOverrides;
      changeSet.push({ id: currentWbsRow?.id!, resourceOverrides: [...wbsOverrides] });
    }
    else {

      if (currentRbsRow.originalValue) {
        const newOriginalValue = { inputCost, inputProjectCurrencyId: currency.id, cost };
        currentRbsRow.originalValue = { ...currentRbsRow.originalValue, ...newOriginalValue };
      }

      changeSet.push({ id: currentRbsRow.id!, inputCost, inputProjectCurrencyId: currency.id });
    }
  }
  else {

    leafRows.forEach((r) => {

      r.cost = cost;
      r.inputCost = inputCost;
      r.inputProjectCurrencyId = currency.id;
      if (isWritingOverride) {

        const existingOverride = wbsOverrides.find((o) => o.id === r.id);
        if (existingOverride) {
          existingOverride.cost = cost;
          existingOverride.inputCost = inputCost;
          existingOverride.inputProjectCurrencyId = currency.id;
        }
        else {
          wbsOverrides.push({
            id: currentRbsRow.id!,
            inputCost,
            cost,
            inputProjectCurrencyId: currency.id,
            quantity: currentRbsRow.quantity,
            units: { ...currentRbsRow.units }
          });
        }
      }
      else {

        if ((r as WBSDataRowShapeWithStates).originalValue) {
          const newOriginalValue = { inputCost, inputProjectCurrencyId: currency.id, cost };
          // @ts-ignore
          r.originalValue = { ...r.originalValue, ...newOriginalValue };
        }

        changeSet.push({
          id: r.id!,
          inputCost,
          inputProjectCurrencyId: currency.id
        });
      }
    });

    if (isWritingOverride) {
      currentWbsRow!.resourceOverrides = wbsOverrides;
      changeSet.push({ id: currentWbsRow?.id!, resourceOverrides: [...wbsOverrides] });
    }
  }

  calculateTotal(rbsDataRows, wbsDataRows, true);
  const selectedWbsRows = getSelectedRows(wbsDataRows);
  if (selectedWbsRows.length === 1) {
    calculateResourceTableWithSelectedRow(rbsDataRows, wbsDataRows, selectedWbsRows[0]);
  }
  else {
    calculateResourceTableWithoutSelectedRow(rbsDataRows, wbsDataRows);
  }

  return changeSet;
};

const getDeltaValues = (oldValue: string, newValue: string) => {

  let deltaMultiplier = 1;
  let deltaAdder = 0;

  if (newValue !== undefined) {
    const oldFloat = Parse.Float(oldValue);
    const newFloat = Parse.Float(newValue);
    deltaMultiplier = (oldFloat === 0) ? 0 : (newFloat / oldFloat);

    if (oldFloat === 0) {
      deltaAdder = newFloat;
    }
  }

  return { deltaMultiplier, deltaAdder };
};

export const updateTotal = (
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  currentWbsRow: WBSDataRowShapeWithStates,
  value: string,
  currency: ProjectCurrencyShape
) => {

  const wbsLookup = createWbsLookup(rbsDataRows, wbsDataRows);
  const leafRbsRows: WBSDataRowShape[] = [];
  for (const rbsRow of wbsLookup.rbs.topLevelDataRows) {
    leafRbsRows.push(...getLeafRows(wbsLookup.rbs.codeLookup, rbsRow.wbsCode));
  }

  const resourceOverrides = currentWbsRow.resourceOverrides ?? [];
  const currentTotal = leafRbsRows.reduce(
    (accumulator, leafRow) => (Parse.Float(leafRow.quantity) * Parse.Float(leafRow.cost)) + accumulator,
    0
  );
  const deltas = getDeltaValues(currentTotal.toString(), value);

  if (currentTotal === 0) {
    deltas.deltaAdder /= (leafRbsRows.length || 1);
  }

  for (const leafRbsRow of leafRbsRows) {
    const existingOverride = currentWbsRow.resourceOverrides?.find((o) => o.id === leafRbsRow.id);
    if (existingOverride) {
      const newInputCost = ((Parse.Float(existingOverride.inputCost || '0') * deltas.deltaMultiplier) + deltas.deltaAdder).toString();
      const newCost = (Parse.Float(newInputCost) * Parse.Float(currency.exchangeRate)).toString();
      existingOverride.cost = newCost;
      existingOverride.inputCost = newInputCost;
      existingOverride.inputProjectCurrencyId = null;
      if (currentTotal === 0) {
        existingOverride.quantity = '1';
      }
    }
    else {
      const newInputCost = ((Parse.Float(leafRbsRow.inputCost) * deltas.deltaMultiplier) + deltas.deltaAdder).toString();
      const newCost = (Parse.Float(newInputCost) * Parse.Float(currency.exchangeRate)).toString();
      resourceOverrides!.push({
        id: leafRbsRow.id!,
        cost: newCost,
        inputCost: newInputCost,
        inputProjectCurrencyId: null,
        quantity: (currentTotal === 0) ? '1' : leafRbsRow.quantity,
        units: { ...leafRbsRow.units }
      });
    }

    currentWbsRow!.resourceOverrides = resourceOverrides;
  }

  return [{ id: currentWbsRow?.id!, resourceOverrides }];

};

const calculatePercentage = (codeLookup: WBSCodeLookupShape, wbsCode: string, percentages:  { [key: string]: Big }) => {

  const codeLookupValue = codeLookup[wbsCode];
  const children = codeLookupValue.children;

  if (children.length === 0) {
    return;
  }

  for (const child of children) {
    percentages[child.wbsCode] = percentages[codeLookupValue.self.wbsCode].div(children.length);
    calculatePercentage(codeLookup, child.wbsCode, percentages);
  }
};

const getQuantityPercentages = (codeLookup: WBSCodeLookupShape, wbsCode: string) => {

  const percentages = {} as { [key: string]: Big };
  percentages[wbsCode] = decimal('1.00');

  calculatePercentage(codeLookup, wbsCode, percentages);

  return percentages;
};


