import React from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const LinearProgressWithLabel = (props) => {

  let textValue = `${Math.round(props.value)}%`;
  if (props.label) {
    textValue = `${props.label} - ${textValue}`;
  }

  return (
    <Box display="flex" alignItems="center" margin={2}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={props.label ? 90 : 35}>
        <Typography variant="caption" color="textSecondary">{textValue}</Typography>
      </Box>
    </Box>
  );
};

export default  LinearProgressWithLabel;
