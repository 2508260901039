import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@material-ui/core';

// exposed to component via this.props.classes
const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '20px'
  },
  displayNone: {
    display: 'none'
  },
  itemHeader: {
    fontWeight: 'bold',
    margin: `${theme.spacing(1)}px 0`
  },
  loadingIndicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    userSelect: 'none'
  },
  subItem: {
    marginLeft: theme.spacing(2)
  }
}));

const MultiItemSelectDialog = (props) => {

  const classes = useStyles();

  const {
    cancelLabel = 'Cancel',
    confirmLabel = 'OK',
    dialogDescription = '',
    dialogId = 'dialog.multi-item-select',
    dialogTitle = 'Select Items',
    isOpen,
    itemList,
    onCancel,
    onConfirm,
    selectedItemSuffix = null
  } = props;

  const [selectedItemIds, setSelectedItemIds] = useState(new Set());

  useEffect(() => {

    if (!itemList) {
      setSelectedItemIds(new Set());
    }
    else {
      setSelectedItemIds(new Set(itemList.filter((item) => item.isSelected).map((item) => item.id)));
    }
  }, [itemList]);

  const handleConfirmClicked = useCallback(() => onConfirm([...selectedItemIds]), [onConfirm, selectedItemIds]);

  const handleToggleItemClicked = useCallback((evt) => {

    const itemId = evt.target.dataset.id;
    const newSelectedState = !selectedItemIds.has(itemId);

    const newSelectedIds = new Set([...selectedItemIds]);
    const item = itemList.find((i) => i.id === itemId);

    if (item.toggleOthersWhenClicked?.length) {
      let shouldDeselectAll = false;
      for (const otherId of item.toggleOthersWhenClicked) {
        if (selectedItemIds.has(otherId)) {
          shouldDeselectAll = true;
          break;
        }
      }

      for (const otherId of item.toggleOthersWhenClicked) {
        shouldDeselectAll ? newSelectedIds.delete(otherId) : newSelectedIds.add(otherId);
      }
    }

    if (item.isSelectable) {
      newSelectedState ? newSelectedIds.add(itemId) : newSelectedIds.delete(itemId);
    }

    setSelectedItemIds(newSelectedIds);
  }, [itemList, selectedItemIds]);

  const itemElements = useMemo(() => {

    if (!itemList) {
      return null;
    }

    const output = [];

    for (const item of itemList) {
      const switchElement =
        <Switch
          checked={selectedItemIds.has(item.id)}
          classes={{ root: item.isSelectable ? undefined : classes.displayNone }}
          inputProps={{
            'data-id': item.id,
            'data-cy': `${dialogId}.${item.label}`
          }}
        />;

      const styling = {};
      if (item.isHeader) {
        styling.label = classes.itemHeader;
      }
      else if (item.isSubItem) {
        styling.root = classes.subItem;
      }

      const suffix = (selectedItemIds.has(item.id)) ? selectedItemSuffix : null;

      output.push(
        <FormControlLabel
          key={item.id}
          classes={styling}
          control={switchElement}
          label={<span>{item.label}{suffix}</span>}
          onChange={handleToggleItemClicked}
        />
      );
    }

    return output;
  }, [classes, dialogId, handleToggleItemClicked, itemList, selectedItemIds, selectedItemSuffix]);

  return useMemo(() => {

    const dialogContent = itemList ?
      (
        <DialogContent>
          <DialogContentText>{dialogDescription}</DialogContentText>
          <div
            data-cy="multi-select-dialog.item-title"
            className={classes.options}
          >
            {itemElements}
          </div>
        </DialogContent>
      ) :
      <DialogContent className={classes.loadingIndicator}><CircularProgress color="secondary" /></DialogContent>;

    return (
      <Dialog
        className={classes.dialog}
        id={dialogId}
        data-cy={dialogId}
        open={isOpen}
        onClose={onCancel}
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{ className: classes.dialog }}
        disableEnforceFocus={true}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        {dialogContent}
        <DialogActions>
          <Button className={classes.button} onClick={onCancel} color="secondary" data-cy="dialog.button.confirm">
            {cancelLabel}
          </Button>
          <Button
            autoFocus
            className={classes.button}
            data-cy="dialog.button.confirm"
            onClick={handleConfirmClicked}
            color="secondary"
            variant="contained"
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [
    cancelLabel,
    classes,
    confirmLabel,
    dialogDescription,
    dialogId,
    dialogTitle,
    handleConfirmClicked,
    isOpen,
    itemElements,
    itemList,
    onCancel
  ]);
};

export default MultiItemSelectDialog;
