import { DivisionId, DivisionState } from '../stateTypes';
import _ from 'lodash';

export const getSelectedId = (highlighted: number[][]) => {

  let dominantDivision;
  for (const visibleDivision of highlighted) {
    if (!dominantDivision || (visibleDivision[1] > dominantDivision.dominance)) {
      dominantDivision = { id: visibleDivision[0], dominance: visibleDivision[1] };
    }
  }

  return dominantDivision?.id;
};

export const toggleElement = (array: DivisionId[], element: DivisionId) => {

  const index = array.indexOf(element);

  if (index === -1) {
    array.push(element);
  }
  else {
    array.splice(index, 1);
  }
};

export const getAllSectionIds = (divisions: DivisionState[]) => {

  return _.flatten(divisions
    .filter((division) => !division.isHidden)
    .map((division) => {

      return division.sections
        .filter((section) => !section.isHidden)
        .map((section) => section.id);
    }));
};

interface TopLevelRow {
  id: number;
  name: string;
  divisionNumber: string;
}

export const updateTopLevelRows = (topLevelRows: TopLevelRow[], updatedDivision: { divisionId: number, division: Partial<DivisionState> }) => {

  const { divisionId, division } = updatedDivision;

  topLevelRows?.forEach((row) => {

    if (row.id === divisionId) {
      if (division.name && row.name !== division.name) {
        row.name = division.name;
      }

      if (division.label && row.divisionNumber !== division.label) {
        row.divisionNumber = division.label;
      }
    }
  });
};
