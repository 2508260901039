import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

export interface AlternateLinkProps {
  fullName: string;
  isNotCompanyMember: boolean;
  onToggleForgotPasswordPrompt: (evt: React.MouseEvent) => void;
}

const AlternateLink = (
  {
    fullName,
    isNotCompanyMember,
    onToggleForgotPasswordPrompt
  }: AlternateLinkProps) => {

  const history = useHistory();

  if (isNotCompanyMember) {
    return (
      <NavLink
        to="/projects"
        onClick={() => history.push('/projects')}
        color="secondary"
        data-cy="login.continue-as-user-link"
      >
        {`Continue to ${fullName}'s projects`}
      </NavLink>
    );
  }

  return (
    <NavLink
      to=""
      onClick={onToggleForgotPasswordPrompt}
      color="secondary"
      data-cy="login.forgot-password-link"
    >
      Forgot password?
    </NavLink>
  );
};

export { AlternateLink };
