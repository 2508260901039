"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrentPhaseId = void 0;
var getCurrentPhaseId = function getCurrentPhaseId(sortedMilestones, isResources) {
  if (sortedMilestones.length === 0 || isResources) {
    return null;
  }
  var advancedPhases = sortedMilestones.filter(function (milestone) {
    return milestone.dateAdvanced !== null;
  });
  if (sortedMilestones.length === 1 || advancedPhases.length === 0) {
    return sortedMilestones[0].id;
  } else if (sortedMilestones.length === advancedPhases.length) {
    return sortedMilestones[sortedMilestones.length - 1].id;
  }
  var phaseIdAfterLastAdvanced = sortedMilestones[advancedPhases.length].id;
  return phaseIdAfterLastAdvanced !== null && phaseIdAfterLastAdvanced !== void 0 ? phaseIdAfterLastAdvanced : null;
};
exports.getCurrentPhaseId = getCurrentPhaseId;