import { getCsrfToken } from '../../../api/common';
import superagent from 'superagent';
export const updateDashboardScenarioCurve = async (
  wbsRowsWithCurveData: {
    scenarioId: number,
    reportingPeriod: string,
    wbsRowId: number,
    wbsRowCode: string,
    wbsRowName: string,
    curveData: Record<string, number>,
    newRowEndDate?: string
  }[]
) => {

  const csrfToken = await getCsrfToken();
  const request = await superagent.patch(process.env.REACT_APP_API_URL + '/cost-management-dashboard/edit-dashboard-scenario-curve')
    .withCredentials()
    .set('X-CSRF-Token', csrfToken)
    .send({ wbsRowsWithCurveData });

  return request;
};
