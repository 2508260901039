import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { styles } from '../styles';
import superagent from 'superagent';
import { getClassName } from '../LoginForm/LoginForm';
import { UserMessageUI } from '../UserMessageUI/UserMessageUI';

interface State {
  email: string;
  submitting: boolean;
  errorMessage?: string;
}

const SingleSignOn = () => {

  const classes = styles();

  const className = getClassName({ userMessageIsError: true });
  const [state, setState] = React.useState<State>({ email: '', submitting: false });
  const onSubmitLogin = React.useCallback(async () => {

    setState((prev) => ({ ...prev, submitting: true, errorMessage: undefined }));

    const response = (await superagent.post(process.env.REACT_APP_API_URL + '/user/sso')
      .send({ email: state.email })) as { body: { redirectUrl?: string, errorMessage?: string } };

    const { redirectUrl, errorMessage } = response.body;
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }

    if (errorMessage) {
      setState((prev) => ({ ...prev, submitting: false, errorMessage }));
      return;
    }

    setState((prev) => ({ ...prev, submitting: false }));

  }, [setState, state]);

  return (
    <div className={classes.form}>
      <div className={classes.loginHeader}>
        <Typography variant="h5" gutterBottom>Single Sign-on </Typography>
      </div>

      {state.errorMessage && <UserMessageUI
        className={className}
        userMessage={state.errorMessage}
      />}
      <form>
        <div>
          <TextField
            type="text"
            id="email"
            data-cy="login.email"
            label="Email"
            margin="normal"
            fullWidth
            value={state.email}
            onChange={(e) => setState((prev) => ({ ...prev, email: e.target.value }))}
          />
        </div>
        <div className={classes.controls}>
          <Button
            variant="contained"
            color="primary"
            data-cy="login.submit"
            onClick={onSubmitLogin}
            disabled={!/(.+)@(.+)+\.(.+){2,}/.test(state.email) || state.submitting}
          >
            {state.submitting ? 'Loading...' : 'Continue to SSO'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export { SingleSignOn };
