import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Consts from '../../consts/Project';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { AddButton } from '../Shared/Buttons/AddButton';
import { CurrencyShape } from '../../types/api/ProjectCurrencyShape';
import { DeleteButton } from '../Shared/Buttons/DeleteButton';
import _ from 'lodash';

const getOriginalInputs = () => {

  return {
    primaryCurrency: 'USD',
    primaryCurrencySymbol: '$'
  };
};

interface Props {
  onCurrenciesUpdated: (currencies: CurrencyShape[]) => void;
  onErrorStateChange?: (hasErrors: boolean) => void;
  onPrimaryCurrencyUpdated: (primaryCurrency: string, primaryCurrencySymbol: string) => void;
}

const ProjectCurrencies = (props: Props) => {

  const {
    onCurrenciesUpdated,
    onErrorStateChange,
    onPrimaryCurrencyUpdated
  } = props;

  const originalErrorText = useMemo(() => {

    return {
      primaryCurrency: '',
      primaryCurrencySymbol: '',
      currencies: [],
      currencySymbols: [],
      exchangeRates: []
    };
  }, []);

  const [errorText, setErrorText] = useState(originalErrorText);
  const [inputs, setInputs] = useState<{ [index: string]: any }>(getOriginalInputs());
  const [projectCurrencies, setProjectCurrencies] = useState<CurrencyShape[]>([]);

  const errorTextRef = useRef<any>();
  errorTextRef.current = errorText;

  const checkInputErrors = useCallback(() => {

    const newErrorText = { ...errorTextRef.current };

    newErrorText.primaryCurrency = '';
    if (_.isEmpty(inputs.primaryCurrency)) {
      newErrorText.primaryCurrency = 'Cannot be empty';
    }

    newErrorText.primaryCurrencySymbol = '';
    if (_.isEmpty(inputs.primaryCurrencySymbol)) {
      newErrorText.primaryCurrencySymbol = 'Empty';
    }

    newErrorText.currencies = [];
    newErrorText.currencySymbols = [];
    newErrorText.exchangeRates = [];

    for (let i = 0; i < projectCurrencies.length; ++i) {
      const currency = projectCurrencies[i];

      let currencyError = '';
      if (_.isEmpty(currency.currency)) {
        currencyError = 'Cannot be empty';
      }

      let currencySymbolError = '';
      if (_.isEmpty(currency.currencySymbol)) {
        currencySymbolError = 'Empty';
      }

      let exchangeRateError = '';
      if (_.isEmpty(currency.exchangeRate)) {
        exchangeRateError = 'Empty';
      }

      (newErrorText.currencies as any).push(currencyError);
      (newErrorText.currencySymbols as any).push(currencySymbolError);
      (newErrorText.exchangeRates as any).push(exchangeRateError);
    }

    setErrorText(newErrorText);

    if (onErrorStateChange) {
      onErrorStateChange(Object.values(newErrorText).flat().some((err) => err));
    }
  }, [inputs, onErrorStateChange, projectCurrencies]);

  useEffect(() => {

    onPrimaryCurrencyUpdated(inputs.primaryCurrency, inputs.primaryCurrencySymbol);
  }, [inputs, onPrimaryCurrencyUpdated]);

  useEffect(() => {

    onCurrenciesUpdated(projectCurrencies);
    checkInputErrors();
  }, [checkInputErrors, onCurrenciesUpdated, projectCurrencies]);

  const handleInputChange = useCallback((evt: React.ChangeEvent<{ id?: string, name?: string; value: any; }>) => {

    evt.preventDefault();

    const itemName = evt.target.id ?? evt.target.name;
    if (!itemName) {
      return;
    }

    const value = evt.target.value;
    const newInputs = { ...inputs };

    const currencyMatches = itemName.match(/^currencies\[([0-9]+)\]$/);
    if (currencyMatches) {
      const newProjectCurrencies = [...projectCurrencies];
      newProjectCurrencies[parseInt(currencyMatches[1])].currency = value;
      setProjectCurrencies(newProjectCurrencies);
      return;
    }

    const currencySymbolMatches = itemName.match(/^currencySymbols\[([0-9]+)\]$/);
    if (currencySymbolMatches) {
      const newProjectCurrencies = [...projectCurrencies];
      newProjectCurrencies[parseInt(currencySymbolMatches[1])].currencySymbol = value;
      setProjectCurrencies(newProjectCurrencies);
      return;
    }

    const exchangeRateMatches = itemName.match(/^exchangeRates\[([0-9]+)\]$/);
    if (exchangeRateMatches) {
      const newProjectCurrencies = [...projectCurrencies];
      newProjectCurrencies[parseInt(exchangeRateMatches[1])].exchangeRate = value.replace(/[^0-9.]/g, '');
      setProjectCurrencies(newProjectCurrencies);
      return;
    }

    newInputs[itemName] = value;
    setInputs(newInputs);
  }, [inputs, projectCurrencies]);

  const handleAddCurrency = useCallback(() => {

    setProjectCurrencies([
      ...projectCurrencies,
      {
        currency: '',
        currencySymbol: '',
        exchangeRate: '1'
      }
    ]);
  }, [projectCurrencies]);

  const handleDeleteCurrency = useCallback((index) => {

    const newCurrencies = [...projectCurrencies];
    newCurrencies.splice(index, 1);
    setProjectCurrencies(newCurrencies);
  }, [projectCurrencies]);

  const currencies = useMemo(() => {

    const currencyList = [];

    for (let i = 0; i < projectCurrencies.length; ++i) {
      const currency = projectCurrencies[i];
      currencyList.push(
        <Grid key={i} container spacing={1}>
          <Grid item xs={7}>
            <TextField
              data-cy="project-currencies.currency"
              margin="dense"
              id={`currencies[${i}]`}
              label="Currency"
              type="text"
              fullWidth
              value={currency.currency}
              error={Boolean((errorText.currencies[i] as string)?.length)}
              helperText={errorText.currencies[i] ?? ''}
              onChange={handleInputChange}
              inputProps={{
                'data-cy': `create-project-dialog.currency.${i}`
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              data-cy="project-currencies.symbol"
              margin="dense"
              id={`currencySymbols[${i}]`}
              label="Symbol"
              type="text"
              fullWidth
              value={currency.currencySymbol}
              error={Boolean((errorText.currencySymbols[i] as string)?.length)}
              helperText={errorText.currencySymbols[i] ?? ''}
              onChange={handleInputChange}
              inputProps={{
                'data-cy': `create-project-dialog.currencySymbol.${i}`,
                maxLength: Consts.CurrencySymbolMaxLength
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <div style={{ display: 'flex' }}>
              <TextField
                data-cy="project-currencies.exchange-rate"
                margin="dense"
                id={`exchangeRates[${i}]`}
                label="Exchange"
                type="text"
                fullWidth
                value={currency.exchangeRate}
                error={Boolean((errorText.exchangeRates[i] as string)?.length)}
                helperText={errorText.exchangeRates[i] ?? ''}
                onChange={handleInputChange}
                inputProps={{
                  'data-cy': `create-project-dialog.exchangeRate.${i}`
                }}
              />
              <div style={{ marginTop: '15px' }}>
                <DeleteButton
                  data-cy="project-currencies.delete-button"
                  onClick={() => handleDeleteCurrency(i)}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }

    return currencyList;
  }, [errorText, handleDeleteCurrency, handleInputChange, projectCurrencies]);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <TextField
            data-cy="project-currencies.currency.primary"
            margin="dense"
            id="primaryCurrency"
            label="Primary Currency"
            type="text"
            fullWidth
            value={inputs.primaryCurrency}
            error={Boolean(errorText.primaryCurrency.length)}
            helperText={errorText.primaryCurrency}
            onChange={handleInputChange}
            inputProps={{
              'data-cy': 'create-project-dialog.primaryCurrency'
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            data-cy="project-currencies.symbol.primary"
            margin="dense"
            id="primaryCurrencySymbol"
            label="Symbol"
            type="text"
            fullWidth
            value={inputs.primaryCurrencySymbol}
            error={Boolean(errorText.primaryCurrencySymbol.length)}
            helperText={errorText.primaryCurrencySymbol}
            onChange={handleInputChange}
            inputProps={{
              'data-cy': 'create-project-dialog.primaryCurrencySymbol',
              maxLength: Consts.CurrencySymbolMaxLength
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            data-cy="project-currencies.exchange-rate.primary"
            margin="dense"
            id="exchangeRate"
            label="Exchange Rate"
            type="text"
            fullWidth
            value="1"
            disabled={true}
          />
        </Grid>
      </Grid>
      {currencies}
      <AddButton onClick={handleAddCurrency} label="Add Another Currency" />
    </Fragment>
  );
};

export default ProjectCurrencies;
