import * as React from 'react';
import _ from 'lodash';

const SpellCheck = {

  handleWordRightClicked: (
    cursorIndex: number | null | undefined,
    input: string,
    optionalHookCallback?: (type: string, word: string) => void
  ) => {

    if (_.isNil(cursorIndex) || _.isNil(input)) {
      return;
    }

    // Search a string and find the entire word that was right clicked
    const isSpace = (c: string) => /\s/.exec(c);
    let start = cursorIndex - 1;
    let end = cursorIndex;

    while (start >= 0 && !isSpace(input[start])) {
      start -= 1;
    }

    while (end < input.length && !isSpace(input[end])) {
      end += 1;
    }

    const wordRightClicked = input.substring(start + 1, end);
    if (optionalHookCallback) {
      return optionalHookCallback('potentialMisspelledWord', wordRightClicked);
    }

    return wordRightClicked;
  },

  reassignClicked: (
    misspelledWord: string | undefined,
    correctWord: string,
    input: string,
    valueNotation: string,
    setter: (newInputs: { [index: string]: string }) => void,
    options?: {
      inputs?: { [index: string]: string },
      onBlur?: ((evt: React.FocusEvent<HTMLElement, HTMLElement> | undefined, newInput: string) => void) | null
    }
  ) => {

    if (!options) {
      options = {};
    }

    const splitInput = input.trim().split(' ');
    for (let i = 0; i < splitInput.length; i++) {
      if (splitInput[i] === misspelledWord) {
        splitInput[i] = correctWord;
      }
    }

    const newInput = splitInput.join(' ');
    if (options.inputs) {
      const newInputs = _.clone(options.inputs);
      newInputs[valueNotation] = newInput;
      setter(newInputs);
    }

    if (options.onBlur) {
      options.onBlur(undefined, newInput);
    }
  }
};

export default SpellCheck;
