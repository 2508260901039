"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var DECIMAL_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});
var DECIMAL_FORMATTER_UP_TO_TWO_DECIMALS = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});
var DECIMAL_FORMATTER_ALWAYS_SHOW_TWO_DECIMALS = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
var Parse = {
  Float: function Float(_float) {
    var _float2;
    if (typeof _float === 'string') {
      _float = _float.replace(/[^0-9.-]/g, '');
    }
    _float = parseFloat(((_float2 = _float) === null || _float2 === void 0 ? void 0 : _float2.toString()) || '');
    if (isNaN(_float)) {
      return 0;
    }
    return _float;
  },
  FloatPossiblePercent: function FloatPossiblePercent(value) {
    var output = value;
    if (typeof value === 'string') {
      output = parseFloat(value.replace(/[^0-9.-]/g, ''));
      if (isNaN(output)) {
        output = 0;
      }
      if (value.trimEnd().endsWith('%')) {
        output *= 0.01;
      }
    } else if (typeof value !== 'number') {
      output = 0;
    }
    return output;
  },
  FormatShorthand: function FormatShorthand(amount, currencySymbol, orderedNotations) {
    var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'units';
    var allowDecimals = arguments.length > 4 ? arguments[4] : undefined;
    var alwaysShowDecimals = arguments.length > 5 ? arguments[5] : undefined;
    var parensAroundNegatives = arguments.length > 6 ? arguments[6] : undefined;
    if (amount === undefined || amount === null) {
      amount = '0';
    }
    amount = parseFloat(amount.toString());
    if (isNaN(amount)) {
      amount = 0;
    }
    var notations = orderedNotations[type];
    var foundNotation = false;
    var symbol = '';
    var _iterator = _createForOfIteratorHelper(notations),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var notation = _step.value;
        if (amount > notation.factor) {
          foundNotation = true;
          amount = amount / notation.factor;
          symbol = notation.symbol;
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    var formatter = allowDecimals ? DECIMAL_FORMATTER_UP_TO_TWO_DECIMALS : DECIMAL_FORMATTER;
    if (alwaysShowDecimals) {
      formatter = DECIMAL_FORMATTER_ALWAYS_SHOW_TWO_DECIMALS;
    }
    var amountIsNegative = amount < 0;
    amount = formatter.format(amount);
    if (foundNotation) {
      amount += symbol;
    }
    if (type === 'money') {
      amount = currencySymbol + amount;
    }
    if (parensAroundNegatives && amountIsNegative) {
      amount = "(".concat(amount, ")");
    }
    return amount;
  },
  NumericValueOfVariable: function NumericValueOfVariable(value, variable) {
    if (!_lodash["default"].isEmpty(variable)) {
      var _variable$value;
      var variableValue = ((_variable$value = variable.value) === null || _variable$value === void 0 ? void 0 : _variable$value.toString()) || '';
      value = parseFloat((variableValue + '').replace(/[^0-9.-]/g, ''));
      if (isNaN(value)) {
        value = 0;
      }
      if (variableValue.trimEnd().endsWith('%')) {
        value *= 0.01;
      }
    } else {
      value = 0;
    }
    return value;
  },
  NumericValueUsingVariableIdLookup: function NumericValueUsingVariableIdLookup(value, overrideValue, projectVariableId, variableIdLookup) {
    if (projectVariableId !== null) {
      var variable = variableIdLookup.get(projectVariableId);
      if (variable) {
        value = Parse.NumericValueOfVariable(value, variable);
      }
    } else if (overrideValue !== null) {
      value = overrideValue;
    }
    return value;
  },
  NumericValue: function NumericValue(value, overrideValue, projectVariableId, projectVariables) {
    if (projectVariableId !== null) {
      var variable = _lodash["default"].find(projectVariables, {
        id: projectVariableId
      });
      if (variable) {
        value = Parse.NumericValueOfVariable(value, variable);
      }
    } else if (overrideValue !== null) {
      value = overrideValue;
    }
    return value;
  }
};
var _default = Parse;
exports["default"] = _default;
