import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rootReducer } from './reducers';

import { InvitationApiSlice } from '../api/invitation';
import { ProjectApiSlice } from '../api/project';

import _ from 'lodash';

const localStorageStateName = 'app-state';

const loadState = () => {

  try {
    const serializedState = localStorage.getItem(localStorageStateName);
    if (serializedState === null) {
      return undefined;
    }

    const state = JSON.parse(serializedState);
    if (state?.appState) {
      state.appState.loadingCount = 0;
      state.appState.loadingGrandTotalCount = 0;
      state.appState.socketioConnected = false;
    }

    return state;
  }
  catch (e) {
    console.log('Error loading local state!', e);
    return undefined;
  }
};

let currentState: any | undefined;
const saveState = (state: any) => {

  const prevState = currentState;
  const whitelist = ['appState', 'ephemeralState', 'modulePdfMarkupState'];

  currentState = _.pick(state, ...whitelist);

  if (!_.isEqual(prevState, currentState)) {
    try {
      localStorage.setItem(localStorageStateName, JSON.stringify(currentState));
    }
    catch (e) {
      console.log('Error saving local state!', e);
    }
  }
};

const middlewareOptions = {
  serializableCheck: false
};

const apiMiddleware = [
  InvitationApiSlice.middleware,
  ProjectApiSlice.middleware
];

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware): any[] => getDefaultMiddleware(middlewareOptions).concat(apiMiddleware)
});

store.subscribe(() => {

  saveState(store.getState());
});

setupListeners(store.dispatch);

if ((window as any).Cypress) {
  (window as any).reduxStore = store;
}

export type AppDispatch = typeof store.dispatch

export default store;
