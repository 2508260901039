import request from 'superagent';

import apiCommon from './common';

export default {
  AddGlobalRequirement: async function (companyId, qualificationType) {

    const apiRequest = request.post(process.env.REACT_APP_API_URL + '/qualification/add-global-requirement').send({
      companyId,
      qualificationType
    });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddInProfile: async function (companyId, qualificationType) {

    const apiRequest = request.post(process.env.REACT_APP_API_URL + '/qualification/add-in-profile').send({
      companyId,
      qualificationType
    });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddIsPublic: async function (companyId, qualificationType) {

    const apiRequest = request.post(process.env.REACT_APP_API_URL + '/qualification/add-is-public').send({
      companyId,
      qualificationType
    });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddQualificationDetails: async function (payload) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/qualification/add-qualification-details')
      .send(payload);

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditQualificationDetails: async function (payload) {

    const apiRequest = request
      .put(process.env.REACT_APP_API_URL + '/qualification/edit-qualification-details')
      .send(payload);

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetGlobalRequirements: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/qualification/get-global-requirements')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetInProfile: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/qualification/get-in-profile')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetIsPublic: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/qualification/get-is-public')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetProjectsRequirements: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/qualification/get-projects-requirements')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetQualificationAttachmentUrl: function (_id, attachmentNumber, versionIndex) {

    let url = process.env.REACT_APP_API_URL + `/qualification/download-attachment?_id=${_id}`;

    if (attachmentNumber) {
      url += `&attachmentNumber=${attachmentNumber}`;
    }

    if (versionIndex !== undefined) {
      url += `&versionIndex=${versionIndex}`;
    }

    return url;
  },

  GetQualificationDetails: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/qualification/get-qualification-details')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveGlobalRequirement: async function (companyId, qualificationType) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/qualification/remove-global-requirement')
      .send({
        companyId,
        qualificationType
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveInProfile: async function (companyId, qualificationType) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/qualification/remove-in-profile')
      .send({
        companyId,
        qualificationType
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveIsPublic: async function (companyId, qualificationType) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/qualification/remove-is-public')
      .send({
        companyId,
        qualificationType
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RemoveQualificationDetails: async function (companyId, qualificationId, addBlankTypeIfAllRemoved) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/qualification/remove-qualification-details')
      .send({
        companyId,
        qualificationId,
        addBlankTypeIfAllRemoved
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UploadAttachment: async function (
    _id,
    attachmentType,
    displayFilename,
    file,
    qualificationType,
    fieldsIfDetailsDoNotExist,
    attachmentNumber,
    onUploadProgress
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/qualification/upload-qualification-attachment')
      .field('_id', _id)
      .field('attachmentType', attachmentType)
      .field('displayFilename', displayFilename)
      .field('qualificationType', qualificationType)
      .attach('attachment', file);

    if (fieldsIfDetailsDoNotExist) {
      apiRequest.field('fieldsIfDetailsDoNotExist', JSON.stringify(fieldsIfDetailsDoNotExist));
    }

    if (attachmentNumber) {
      apiRequest.field('attachmentNumber', attachmentNumber);
    }

    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false, onProgress: onUploadProgress });
  },

  deleteAttachment: async function (_id, attachmentNumber) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/qualification/delete-qualification-attachment')
      .send({
        _id,
        attachmentNumber
      });

    return await apiCommon.ProcessRequest(apiRequest);
  }
};
