import request from 'superagent';

import apiCommon from './common';

export default {

  ImportTypes: {
    NewProject: 'NewProject',
    TemplatedProject: 'TemplatedProject',
    ExistingProject: 'ExistingProject'
  },

  GetImportData: async function (companyId, projectOwnerCompany) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/import/get-import-data')
      .query({ companyId, projectOwnerCompany });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  MergeImportData: async function (projectId, importData, isXMLImport) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/import/merge-import-data')
      .send({ projectId, importData, isXMLImport });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UploadTakeoff: async function (
    projectId,
    file,
    json,
    areaTable,
    classificationTable,
    divisionTable,
    hiercodeTable,
    onUploadProgress
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/import/upload-takeoff')
      .field('projectId', projectId)
      .field('divisionTable', divisionTable)
      .field('json', JSON.stringify(json));

    if (areaTable) {
      apiRequest.field('areaTable', areaTable);
    }

    if (classificationTable) {
      apiRequest.field('classificationTable', classificationTable);
    }

    if (hiercodeTable) {
      apiRequest.field('hiercodeTable', hiercodeTable);
    }

    apiRequest.attach('attachment', file);
    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false, onProgress: onUploadProgress });
  },

  ImportExcel: async function (
    projectId,
    excelFile,
    onUploadProgress
  ) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/import/import-excel')
      .field('projectId', projectId);

    apiRequest.attach('excelFile', excelFile);
    return await apiCommon.ProcessRequest(apiRequest, { useJsonRequestType: false, onProgress: onUploadProgress });
  }
};
