import React from 'react';

import { ReactComponent as Svg } from '../../assets/images/icon_search.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {

  return (
    <SvgIcon {...props} component={Svg} viewBox="0 0 128 128" />
  );
};
