import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  format,
  getQuarter,
  parse,
  startOfWeek,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears
} from 'date-fns';
import {
  CostManagementDashboardScenarioReportingPeriod as ReportingPeriod
} from '../types/enums/CostManagementDashboardScenarioReportingPeriod';


export interface DateAndLabel {
  date: Date;
  label: string;
}

const dayFormatStr = 'yyyy-MM-dd';
const monthFormatStr = 'yyyy-MM';
const yearFormatStr = 'yyyy';

export const getCurrentDate = (): string => {

  const currentDate = new Date();
  return format(currentDate, 'yyyy-MM-dd');
};

export const getNextDate = (startDate: Date = new Date()): DateAndLabel => {

  const nextDate = addDays(startDate, 1);
  return {
    date: nextDate,
    label: format(nextDate, dayFormatStr)
  };
};

export const getPreviousDate = (startDate: Date = new Date()): DateAndLabel => {

  const previousDate = subDays(startDate, 1);

  return {
    date: previousDate,
    label: format(previousDate, dayFormatStr)
  };
};

export const getCurrentMonth = (): string => {

  const currentDate = new Date();
  return format(currentDate, 'yyyy-MM');
};

export const getNextMonth = (startDate: Date = new Date()): DateAndLabel => {

  const nextMonth = addMonths(startDate, 1);

  return {
    date: nextMonth,
    label: format(nextMonth, monthFormatStr)
  };
};

export const getPreviousMonth = (startDate: Date = new Date()): DateAndLabel => {

  const previousMonth = subMonths(startDate, 1);

  return {
    date: previousMonth,
    label: format(previousMonth, monthFormatStr)
  };
};

export const getCurrentQuarter = (): string => {

  const currentDate = new Date();
  const quarter = getQuarter(currentDate);
  const formattedQuarter = `${format(currentDate, 'yyyy')}-Q${quarter}`;
  return formattedQuarter;
};

export const getNextQuarter = (startDate: Date = new Date()): DateAndLabel => {

  const nextQuarterDate = addQuarters(startDate, 1);
  const quarter = getQuarter(nextQuarterDate);
  const formattedNextQuarter = `${format(startDate, yearFormatStr)}-Q${quarter}`;

  return {
    date: nextQuarterDate,
    label: formattedNextQuarter
  };
};

export const getPreviousQuarter = (startDate: Date = new Date()): DateAndLabel => {

  const previousQuarterDate = subQuarters(startDate, 1);
  const quarter = getQuarter(previousQuarterDate);
  const formattedPreviousQuarter = `${format(startDate, yearFormatStr)}-Q${quarter}`;

  return {
    date: previousQuarterDate,
    label: formattedPreviousQuarter
  };
};

export const getCurrentYear = (): string => {

  const currentDate = new Date();
  return format(currentDate, 'yyyy');
};

export const getNextYear = (startDate: Date = new Date()): DateAndLabel => {

  const nextYearDate = addYears(startDate, 1);

  return {
    date: nextYearDate,
    label: format(nextYearDate, yearFormatStr)
  };
};

export const getPreviousYear = (startDate: Date = new Date()): DateAndLabel => {

  const previousYearDate = subYears(startDate, 1);

  return {
    date: previousYearDate,
    label: format(previousYearDate, yearFormatStr)
  };
};

export const getCurrentWeek = (): string => {

  const currentDate = new Date();
  const sundayOfWeek = startOfWeek(currentDate, { weekStartsOn: 0 });
  const formattedDate = format(sundayOfWeek, 'yyyy-MM-dd');
  return formattedDate;
};

export const getNextWeek = (startDate: Date = new Date()): DateAndLabel => {

  const nextWeekDate = addWeeks(startDate, 1);
  const sundayOfNextWeek = startOfWeek(nextWeekDate, { weekStartsOn: 0 });
  const formattedDate = format(sundayOfNextWeek, dayFormatStr);

  return {
    date: nextWeekDate,
    label: formattedDate
  };
};

export const getPreviousWeek = (startDate: Date = new Date()): DateAndLabel => {

  const previousWeekDate = subWeeks(startDate, 1);
  const sundayOfPreviousWeek = startOfWeek(previousWeekDate, { weekStartsOn: 0 });
  const formattedDate = format(sundayOfPreviousWeek, dayFormatStr);

  return {
    date: previousWeekDate,
    label: formattedDate
  };
};

export const getDateFromDayLabel = (label: string) => {

  const parsedDate = parse(label, dayFormatStr, new Date());
  return parsedDate;
};

export const getDateFromMonthLabel = (label: string) => {

  const parsedDate = parse(label, monthFormatStr, new Date());
  return parsedDate;
};

export const getDateFromQuarterLabel = (label: string) => {

  const [year, quarter] = label.split('-Q');
  const parsedDate = parse(year, yearFormatStr, new Date());
  return addQuarters(parsedDate, parseInt(quarter));
};

export const getDateFromYearLabel = (label: string) => {

  const parsedDate = parse(label, yearFormatStr, new Date());
  return parsedDate;
};

export const isPastPeriodDate = (period: ReportingPeriod, dateStr: string): boolean => {

  const interval = period;
  const periodDate = getDateFromPeriodLabel(interval, dateStr);
  const now = getDateFromPeriodLabel(interval, getCurrentPeriod(interval));

  return (now > periodDate);
};

export const getCurrentPeriod = (reportingPeriod: 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year'): string => {

  switch (reportingPeriod) {
    case 'Day':
      return getCurrentDate();
    case 'Week':
      return getCurrentWeek();
    case 'Month':
      return getCurrentMonth();
    case 'Quarter':
      return getCurrentQuarter();
    case 'Year':
      return getCurrentYear();
    default:
      return '';
  }
};

export const getDateFromPeriodLabel = (
  reportingPeriod: ReportingPeriod,
  label: string
): Date => {

  switch (reportingPeriod) {
    case 'Day':
      return getDateFromDayLabel(label);
    case 'Week':
      return getDateFromDayLabel(label);
    case 'Month':
      return getDateFromMonthLabel(label);
    case 'Quarter':
      return getDateFromQuarterLabel(label);
    case 'Year':
      return getDateFromYearLabel(label);
    default:
      return new Date();
  }
};

export const getNextPeriod = (reportingPeriod: ReportingPeriod, label: string): DateAndLabel => {

  const dateFromLabel = getDateFromPeriodLabel(reportingPeriod, label);

  switch (reportingPeriod) {
    case 'Day':
      return getNextDate(dateFromLabel);
    case 'Week':
      return getNextWeek(dateFromLabel);
    case 'Month':
      return getNextMonth(dateFromLabel);
    case 'Quarter':
      return getNextQuarter(dateFromLabel);
    case 'Year':
      return getNextYear(dateFromLabel);
    default:
      return {
        date: new Date(),
        label: ''
      };
  }
};

export const getPreviousPeriod = (reportingPeriod: ReportingPeriod, label: string): DateAndLabel => {

  const dateFromLabel = getDateFromPeriodLabel(reportingPeriod, label);

  switch (reportingPeriod) {
    case 'Day':
      return getPreviousDate(dateFromLabel);
    case 'Week':
      return getPreviousWeek(dateFromLabel);
    case 'Month':
      return getPreviousMonth(dateFromLabel);
    case 'Quarter':
      return getPreviousQuarter(dateFromLabel);
    case 'Year':
      return getPreviousYear(dateFromLabel);
    default:
      return {
        date: new Date(),
        label: ''
      };
  }
};

export const isFutureLabel = (
  label: string,
  interval: ReportingPeriod,
  countTodayAsFuture?: boolean
): boolean => {

  const labelDate = getDateFromPeriodLabel(interval, label);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (countTodayAsFuture) ? labelDate > today : labelDate >= today;
};
