import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { WBSTableRootState } from '../stateTypes';
import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';
import { WBSColumnShape } from '../../types/api/WBSColumnShape';
import { WBSColumnDataShape } from '../../types/api/WBSColumnDataShape';
import { WBSRowSplitShape } from '../../types/api/WBSRowSplitShape';
import { WBSOverrideShapeClient } from 'wbs/dist/types/WBSOverrideShapeClient';
import {
  calculateTitleUIState,
  calculateWBSState,
  getDetailLevel,
  updateDetailLevel,
  WBSDataRowShapeWithStates
} from '../wbs/calculateWBSState';
import { deselectRelatedRows, selectWbsRows } from './wbs/selection';
import {
  calculateResourceTableWithoutSelectedRow,
  calculateTotal,
  copyParentValueIfOnlyChildAndReturnChangeSet,
  updateCost,
  updateQuantity,
  updateResourceCost,
  updateResourceQuantity,
  updateSetMultiplier,
  updateTotal
} from './wbs/calculateTotal';
import {
  addNewWbsRows,
  duplicateWbsRow,
  firstRow,
  NewRowOverride,
  splitWbsRow
} from './wbs/wbsUtils';
import { updateWbsRows } from './wbs/api';
import { WBSMetadataShape } from 'wbs/dist/types/WBSMetadataShape';
import {
  calculateWbsCode,
  calculateWbsCodeAndGetChangeSet,
  decreaseLevelOfRowsByIds,
  increaseLevelOfRowsByIds
} from './wbs/wbsCode';
import { WBSPhaseShape } from '../../types/api/WBSPhaseShape';
import { WBSColumnDataDataFields } from 'wbs/dist/types/WBSColumnDataShape';
import { copyTableColumn, copyWholeTable } from './wbs/copy';
import { insertInitialLevelsToWbsDataBeforePush } from './wbs/wbsPush';
import { generateCustomWbsCode } from 'wbs/dist/generateCustomWbsCode';
import { getDefaultUnits } from 'wbs/dist/getDefaultUnits';
import { WBSColumnDataUnitsShape } from 'wbs/dist/types/WBSColumnDataUnitsShape';
import { getPasteOnWBSCodeColumnChangeSet } from './wbs/paste';
import { getCurrentPhaseId } from 'wbs/dist/getCurrentPhaseId';
import { parseCodeNumbers } from 'wbs/dist/parseCodeNumbers';
import { ProjectCurrencyShape } from '../../types/api/ProjectCurrencyShape';
import { numericOnlyString } from '../../helpers/numericOnlyString';
import { getWbsPermissions } from 'wbs/dist/getWbsPermissions';

const initialState: WBSTableRootState = {
  deletedRowRevertInfoByRowId: {},
  itemLevel: 0,
  wbsDetailLevel: -1,
  rbsDetailLevel: -1,
  wbsNoMoreThan1MainRowSelected: true,
  rbsNoMoreThan1MainRowSelected: true,
  wbsDataRows: [],
  wbsColumns: [],
  wbsColumnDataByColumnId: {},
  preparingForWbsPush: false,
  wbsPushRequested: false,
  rbsDataRows: [],
  rbsColumns: [],
  rbsColumnDataByColumnId: {},
  projectId: -1,
  milestones: []
};

// only allow letters and slice to the max unit length (currently 10)
const getUnitsString = (unit: string) => ` ${unit.replace(/[^A-Za-z]/g, '').slice(0, 10)}`;

const getNumLeadingSpaces = (value: string) => {

  let numSpaces = 0;
  for (let i = 0; i < value.length; i++) {
    if (value[i] !== ' ') {
      break;
    }

    numSpaces++;
  }

  return numSpaces;
};

export const wbsDataTableSlice = createSlice({
  name: 'wbsDataTable',
  initialState,
  reducers: {
    reset: () => initialState,

    updateDefineResources: (state, action: PayloadAction<{ hasDefinedResources: boolean }>) => {

      const { hasDefinedResources } = action.payload;
      if (state.wbsMetadata) {
        state.wbsMetadata.hasDefinedResources = hasDefinedResources;
      }
    },

    setDataTableWBSMetadata: (state, action: PayloadAction<WBSMetadataShape>) => {

      state.wbsMetadata = action.payload;
    },

    updateDataTableWBSMetadata: (state, action: PayloadAction<Partial<Omit<WBSMetadataShape, 'projectId'>>>) => {


      if (state.wbsMetadata) {
        state.wbsMetadata = { ...state.wbsMetadata, ...action.payload };
      }
    },

    updateWbsTableData: (
      state,
      action: PayloadAction<{
        projectId: number,
        isResources: boolean,
        dataRows: WBSDataRowShape[],
        columns: WBSColumnShape[],
        columnDataByColumnId: { [index: string]: WBSColumnDataShape[] },
        metadata?: WBSMetadataShape,
        companyLimitations?: { maxItemTitleLength: number | undefined },
        milestones: WBSPhaseShape[]
      }>
    ) => {

      const {
        projectId,
        isResources,
        dataRows,
        columns,
        columnDataByColumnId,
        metadata,
        companyLimitations,
        milestones
      } = action.payload;

      state.milestones = milestones;
      state.itemLevel = metadata?.itemLevel ?? 0;
      state.projectId = projectId;
      if (!isResources && metadata) {
        state.wbsMetadata = metadata;
      }

      if (companyLimitations) {
        state.companyLimitations = companyLimitations;
      }

      const dataRowsClone = _.cloneDeep(dataRows);
      const changeSet = calculateWbsCode(
        dataRowsClone,
        state.wbsMetadata?.rbsCodeSeparator ?? ' ',
        state.wbsMetadata?.rbsCodeCustomizations ?? [],
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.wbsMetadata?.projectDataId ?? -1,
        state.wbsMetadata?.directCostDataId ?? -1,
        state.wbsMetadata?.finalCostsDataId ?? -1
      );

      if (changeSet.length > 0) {
        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }

      if (isResources) {
        if (state.rbsDetailLevel === -1) {
          state.rbsDetailLevel = getDetailLevel(dataRowsClone);
        }

        state.rbsDataRows = calculateWBSState(
          dataRowsClone,
          columnDataByColumnId,
          metadata,
          companyLimitations,
          state.rbsDataRows
        );
        state.rbsColumns = columns;
        state.rbsColumnDataByColumnId = columnDataByColumnId;
        updateDetailLevel(state.rbsDataRows, state.rbsDetailLevel);

        for (const row of state.rbsDataRows) {
          row.originalValue = {
            cost: row.cost,
            inputCost: row.inputCost,
            inputProjectCurrencyId: row.inputProjectCurrencyId,
            quantity: row.quantity,
            units: row.units
          };
        }

        if (state.rbsDataRows.length === 0 && state.wbsMetadata?.hasDefinedResources) {
          state.wbsDataRows = [
            firstRow(
              isResources,
              projectId,
              state.wbsMetadata?.wbsCodeSeparator ?? ' ',
              state.wbsMetadata?.wbsCodeCustomizations ?? [],
              getCurrentPhaseId(state.milestones, isResources)
            )
          ];
        }
        else {
          calculateResourceTableWithoutSelectedRow(state.rbsDataRows, state.wbsDataRows);
        }
      }
      else {
        if (state.wbsDetailLevel === -1) {
          state.wbsDetailLevel = getDetailLevel(dataRowsClone);
        }

        state.wbsDataRows = calculateWBSState(
          dataRowsClone,
          columnDataByColumnId,
          metadata,
          companyLimitations,
          state.wbsDataRows
        );
        state.wbsColumns = columns;
        state.wbsColumnDataByColumnId = columnDataByColumnId;
        updateDetailLevel(state.wbsDataRows, state.wbsDetailLevel);

        if (state.wbsDataRows.length === 0) {
          state.wbsDataRows = [
            firstRow(
              isResources,
              projectId,
              state.wbsMetadata?.wbsCodeSeparator ?? ' ',
              state.wbsMetadata?.wbsCodeCustomizations ?? [],
              getCurrentPhaseId(state.milestones, isResources)
            )
          ];
        }
      }

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
    },

    toggleExpandRow: (state, action: PayloadAction<{ isResources: boolean, clientSidePredictionId: number }>) => {

      const { isResources, clientSidePredictionId } = action.payload;

      const dataRows = (isResources ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];
      const row = dataRows.find((r) => r.clientSidePredictionId === clientSidePredictionId);

      if (row) {
        row.uiState.isExpanded = !row.uiState.isExpanded;

        const notExpandedWbsCodes = dataRows.filter((r) => r.uiState.rowCanBeExpanded && !r.uiState.isExpanded)
          .map((r) => r.wbsCode);

        for (const dataRow of dataRows) {

          dataRow.uiState.hidden =
            notExpandedWbsCodes.find((wbsCode) => dataRow.wbsCode.startsWith(`${wbsCode} `)) !== undefined;
        }
      }
    },

    deleteRows: (
      state,
      action: PayloadAction<{
        isResources: boolean,
        currentRowId: number
      }>
    ) => {

      const { isResources, currentRowId } = action.payload;

      const dataRows = (isResources ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];
      state.deletedRowRevertInfoByRowId = {};
      const currentPhaseId = getCurrentPhaseId(state.milestones, isResources);
      const wbsPermissions = getWbsPermissions(dataRows, currentPhaseId, state.wbsMetadata, isResources);

      dataRows.forEach((r) => {

        if (!wbsPermissions[r.wbsCode].canDelete) {
          return;
        }

        if (r.uiState.isSelected || r.id === currentRowId) {
          state.deletedRowRevertInfoByRowId[r.clientSidePredictionId] = _.cloneDeep(r);
          r.uiState.isDeleting = true;
        }
      });

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));

      if (dataRows.length === 0) {
        if (isResources) {
          state.rbsDataRows = [
            firstRow(
              isResources,
              state.projectId,
              state.wbsMetadata?.wbsCodeSeparator ?? ' ',
              state.wbsMetadata?.wbsCodeCustomizations ?? [],
              getCurrentPhaseId(state.milestones, isResources)
            )
          ];
        }
        else {
          state.wbsDataRows = [
            firstRow(
              isResources,
              state.projectId,
              state.wbsMetadata?.wbsCodeSeparator ?? ' ',
              state.wbsMetadata?.wbsCodeCustomizations ?? [],
              getCurrentPhaseId(state.milestones, isResources)
            )
          ];
        }
      }

      const currentDetailLevel = (isResources) ? state.rbsDetailLevel : state.wbsDetailLevel;
      updateDetailLevel(dataRows, currentDetailLevel);
    },

    completeDeleteRows: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;

      const dataRows = (isResources ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];

      const newDataRows = dataRows.filter((r) => !r.uiState.isDeleting);

      isResources ? (state.rbsDataRows = newDataRows) : (state.wbsDataRows = newDataRows);
    },

    copyColumn: (state, action: PayloadAction<{ isResources: boolean, columnIndex: number }>) => {

      const { isResources, columnIndex } = action.payload;
      const dataRows = isResources ? state.rbsDataRows : state.wbsDataRows;
      const columns = isResources ? [] : state.wbsColumns;
      const columnDataByColumnId = isResources ? {} : state.wbsColumnDataByColumnId;

      copyTableColumn(
        dataRows,
        columns,
        columnDataByColumnId,
        columnIndex,
        Boolean(
          (isResources) ?
            state.wbsMetadata?.rbsCodeCustomizationsEnabled :
            state.wbsMetadata?.wbsCodeCustomizationsEnabled
        )
      );
    },

    copyTable: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;
      const dataRows = isResources ? state.rbsDataRows : state.wbsDataRows;
      const columns = isResources ? [] : state.wbsColumns;
      const columnDataByColumnId = isResources ? {} : state.wbsColumnDataByColumnId;

      copyWholeTable(
        dataRows,
        columns,
        columnDataByColumnId,
        Boolean(
          (isResources) ?
            state.wbsMetadata?.rbsCodeCustomizationsEnabled :
            state.wbsMetadata?.wbsCodeCustomizationsEnabled
        )
      );
    },

    updateCell: (
      state,
      action: PayloadAction<{
        isResources: boolean,
        rowClientSidePredictionId: number,
        columnName: string,
        value: string,
        columnId: string,
        originalValue: string,

        canDecreaseLevel?: boolean,
        canIncreaseLevel?: boolean
      }>) => {

      const {
        isResources,
        rowClientSidePredictionId,
        columnName,
        value,
        columnId,
        originalValue,
        canDecreaseLevel,
        canIncreaseLevel
      } = action.payload;
      const dataRows = (isResources ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];
      const row = dataRows.find((r) => r.clientSidePredictionId === rowClientSidePredictionId);

      if (!row) {
        return;
      }

      const rowCodes = parseCodeNumbers(row.wbsCode);
      const valueNumeric = numericOnlyString(value);

      if (columnName === 'name') {

        const originalPadding = getNumLeadingSpaces(originalValue);
        const newPadding = getNumLeadingSpaces(value);
        let valueToUse = value;

        if (newPadding < originalPadding) {
          // If canDecreaseLevel = true, only allow max of 1 backspace (originalPadding - 1) otherwise you can select all
          // + backspace to forcefully change wbs levels.
          const indentation = (canDecreaseLevel) ? ' '.repeat(originalPadding - 1) : ' '.repeat(rowCodes.length - 1);
          valueToUse = `${indentation}${value.trimStart()}`;
        }
        else if (newPadding > originalPadding) {
          const indentation = (canIncreaseLevel) ? ' '.repeat(newPadding) : ' '.repeat(rowCodes.length - 1);
          valueToUse = `${indentation}${value.trimStart()}`;
        }

        row[columnName] = valueToUse;
      }
      else if (columnName === 'wbsCode') {

        row[columnName] = value;
      }
      else if (['inputCost', 'quantity', 'total'].includes(columnName)) {

        // handle units
        if (columnName === 'quantity') {
          const units = value.split(' ', 2);
          const hasUnits = (units.length === 2);
          row.units.quantity = '';

          if (hasUnits) {
            row.units.quantity = getUnitsString(units[1]);
          }
        }

        // @ts-ignore
        row[columnName] = valueNumeric;
      }
      else if (columnId) {

        const camelCaseColumnName = columnName.charAt(0).toLowerCase() + columnName.slice(1);
        const data = row.columnState[parseInt(columnId)] as WBSColumnDataDataFields;
        const isSetMultiplierCol = (camelCaseColumnName === 'setMultiplier');
        const units = value.split(' ', 2);
        const hasUnits = (units.length === 2);

        if (data) {
          // @ts-ignore
          data[camelCaseColumnName] = (isSetMultiplierCol) ? valueNumeric : value;
          if (isSetMultiplierCol) {
            data.units.setMultiplier = '';
            if (hasUnits) {
              data.units.setMultiplier = getUnitsString(units[1]);
            }
          }
        }
        else {

          const newColumnState = {
            [camelCaseColumnName]: value,
            units: getDefaultUnits('columnData') as WBSColumnDataUnitsShape
          };
          if (isSetMultiplierCol && hasUnits) {
            newColumnState.units.setMultiplier = getUnitsString(units[1]);
          }

          // @ts-ignore
          row.columnState[parseInt(columnId)] = newColumnState;
        }
      }

      if (columnName === 'name') {
        (isResources) ?
          (state.rbsDataRows = calculateTitleUIState(dataRows)) :
          (state.wbsDataRows = calculateTitleUIState(dataRows, state.wbsMetadata, state.companyLimitations));
      }
    },

    saveCell: (
      state,
      action: PayloadAction<{
        isResources: boolean,
        rowClientSidePredictionId: number,
        fieldType: string,
        value: string,
        currency: ProjectCurrencyShape,

        columnId?: number,
        isFrozen?: boolean,
        levelChanged?: boolean,
        originalValue?: string
      }>) => {

      const {
        isResources,
        rowClientSidePredictionId,
        fieldType,
        columnId,
        value,
        currency,
        levelChanged
      } = action.payload;
      const dataRows = ((isResources) ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];
      const row = dataRows.find((r) => r.clientSidePredictionId === rowClientSidePredictionId);

      if (!row) {
        return;
      }

      let changeSet: any[] = [];

      const wbsRow = state.wbsDataRows.find((r) => r.uiState.isSelected);
      const columnIdToUse = columnId ?? -1;

      switch (fieldType) {
        case 'inputCost':
          changeSet = (
            (isResources) ?
              updateResourceCost(state.rbsDataRows, state.wbsDataRows, row, value, currency, wbsRow) :
              updateCost(state.rbsDataRows, state.wbsDataRows, row, value, currency, Boolean(state.wbsMetadata?.hasDefinedResources))
          ) as any[];
          break;
        case 'quantity':
          changeSet = (
            (isResources) ?
              updateResourceQuantity(state.rbsDataRows, state.wbsDataRows, row, value, wbsRow) :
              updateQuantity(
                state.rbsDataRows,
                state.wbsDataRows,
                row,
                value,
                Boolean(state.wbsMetadata?.hasDefinedResources)
              )
          ) as any[];
          break;
        case 'total':
          changeSet = updateTotal(state.rbsDataRows, state.wbsDataRows, row, value, currency);
          break;
        case 'data':
        case 'rawData':
          changeSet = [{ id: row.id, additionalColumnData: [{ columnId: columnIdToUse, newValue: value }] }];
          break;
        case 'setMultiplier':
          changeSet = updateSetMultiplier(state.wbsDataRows, row, value, columnIdToUse) as any[];
          break;
        case 'name':
          // @ts-ignore
          row[fieldType] = value.trimEnd();
          changeSet.push({ id: row.id, [fieldType]: value.trimEnd() });
          break;
        case 'inputProjectCurrencyId':
          changeSet = (isResources) ?
            updateResourceCost(state.rbsDataRows, state.wbsDataRows, row, value, currency, wbsRow) :
            updateCost(state.rbsDataRows, state.wbsDataRows, row, value, currency, Boolean(state.wbsMetadata?.hasDefinedResources));
          break;
        default:
          // @ts-ignore
          row[fieldType] = value;
          changeSet.push({ id: row.id, [fieldType]: value });
          break;
      }

      if (fieldType === 'name') {

        changeSet = calculateWbsCodeAndGetChangeSet(
          dataRows,
          changeSet,
          state.wbsMetadata?.rbsCodeSeparator ?? ' ',
          state.wbsMetadata?.rbsCodeCustomizations ?? [],
          state.wbsMetadata?.wbsCodeSeparator ?? ' ',
          state.wbsMetadata?.wbsCodeCustomizations ?? [],
          state.wbsMetadata?.projectDataId ?? -1,
          state.wbsMetadata?.directCostDataId ?? -1,
          state.wbsMetadata?.finalCostsDataId ?? -1
        );

        if (levelChanged) {
          changeSet = copyParentValueIfOnlyChildAndReturnChangeSet(
            state.rbsDataRows,
            state.wbsDataRows,
            row,
            isResources,
            changeSet
          );
          calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
        }

        (isResources) ?
          (state.rbsDataRows = calculateTitleUIState(dataRows)) :
          (state.wbsDataRows = calculateTitleUIState(dataRows, state.wbsMetadata, state.companyLimitations));
      }

      // This should be move out of there as reducer should not call apis.
      updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {
      });

      if (fieldType === 'setMultiplier') {
        calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
      }
    },

    selectRows: (
      state,
      action: PayloadAction<{
        isResources: boolean,
        clientSidePredictionId: number,
        ctrlIsDown: boolean,
        shiftIsDown: boolean
      }>
    ) => {

      const { isResources, clientSidePredictionId, ctrlIsDown, shiftIsDown } = action.payload;
      selectWbsRows(state, isResources, clientSidePredictionId, ctrlIsDown, shiftIsDown);
    },

    deselectAllRows: (state) => {

      const selectedWbsRows = state.wbsDataRows.filter((r) => r.uiState.isSelected);
      const selectedRbsRows = state.rbsDataRows.filter((r) => r.uiState.isSelected);
      const allSelectedRows = [...selectedWbsRows, ...selectedRbsRows];
      const relatedRows = [...state.rbsDataRows, ...state.wbsDataRows].filter((r) => r.uiState.isRelatedToSelectedRow);
      if (selectedWbsRows.length === 1) {
        // Reset resource table to original values (non overrides)
        calculateResourceTableWithoutSelectedRow(state.rbsDataRows, state.wbsDataRows);
      }

      allSelectedRows.forEach((r) => (r.uiState.isSelected = false));
      deselectRelatedRows(relatedRows);
    },

    addNewRows: (
      state,
      action: PayloadAction<{
        isResources: boolean,
        primaryCurrencySymbol: string,
        currentRow: WBSDataRowShapeWithStates,
        overrides?: NewRowOverride[],
        ignoreBaseLevel?: boolean,
        forceTopLevelRow?: boolean,
        forceLastChild?: boolean
      }>) => {

      const {
        currentRow,
        forceTopLevelRow,
        forceLastChild,
        ignoreBaseLevel,
        isResources,
        overrides,
        primaryCurrencySymbol
      } = action.payload;

      const dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;
      const { updatedDataRows, newDataRows, changeSet } = addNewWbsRows(
        isResources,
        primaryCurrencySymbol,
        dataRows,
        currentRow,
        overrides,
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.rbsDataRows,
        state.wbsDataRows,
        Boolean(state.wbsMetadata?.hasDefinedResources),
        getCurrentPhaseId(state.milestones, isResources),
        ignoreBaseLevel,
        forceTopLevelRow,
        forceLastChild
      );

      (isResources) ? (state.rbsDataRows = updatedDataRows) : (state.wbsDataRows = updatedDataRows);
      selectWbsRows(state, isResources, newDataRows[newDataRows.length - 1].clientSidePredictionId, false, false);

      if (changeSet.length > 0) {
        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }
    },

    pasteOnWBSCodeColumn: (
      state,
      action: PayloadAction<{ isResources: boolean, primaryCurrencySymbol: string, currentRow: WBSDataRowShapeWithStates, data: string[][] }>
    ) => {

      const { isResources, data, primaryCurrencySymbol } = action.payload;
      const wbsCodeSeparator =
        ((isResources) ? state.wbsMetadata?.rbsCodeSeparator : state.wbsMetadata?.wbsCodeSeparator) ?? ' ';
      const wbsCodeCustomizations =
        ((isResources) ? state.wbsMetadata?.rbsCodeCustomizations : state.wbsMetadata?.wbsCodeCustomizations) ?? [];
      const {
        updatedDataRows,
        changeSet,
        newWbsCodeCustomizations
      }  = getPasteOnWBSCodeColumnChangeSet(state, isResources, primaryCurrencySymbol, data, wbsCodeSeparator, wbsCodeCustomizations);

      if (state.wbsMetadata) {
        const stateKey = (isResources) ? 'rbsCodeCustomizations' : 'wbsCodeCustomizations';
        state.wbsMetadata[stateKey] = newWbsCodeCustomizations;
      }

      (isResources) ? (state.rbsDataRows = updatedDataRows) : (state.wbsDataRows = updatedDataRows);

      if (changeSet.length > 0) {
        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }
    },

    insertInitialLevelsToWbsData: (state, action: PayloadAction<{ primaryCurrencySymbol: string }>) => {

      state.preparingForWbsPush = true;
      insertInitialLevelsToWbsDataBeforePush(state, action.payload.primaryCurrencySymbol);
    },

    updateTitleUIState: (state) => {

      state.wbsDataRows = calculateTitleUIState(state.wbsDataRows, state.wbsMetadata, state.companyLimitations);
    },

    updateIds: (state, action: PayloadAction<{ isResources: boolean, oldIds: number[], updatedIds: number[] }>) => {

      const { isResources, oldIds, updatedIds } = action.payload;
      const dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;

      for (const row of dataRows) {
        const oldId = row.clientSidePredictionId;
        if (oldIds.includes(oldId)) {
          const index = oldIds.indexOf(oldId);
          const newId = updatedIds[index];
          row.id = newId;
          row.clientSidePredictionId = newId;
        }
      }
    },

    updateResources: (
      state,
      action: PayloadAction<{
        hasAddedAllToServer: Boolean,
        oldIdToNewMap: { [index: string]: number }
      }>
    ) => {

      const { oldIdToNewMap } = action.payload;

      for (const wbsRow of state.wbsDataRows) {
        if (!wbsRow.resourceOverrides) {
          continue;
        }

        for (const override of wbsRow.resourceOverrides) {
          if (oldIdToNewMap[override.id]) {
            override.id = oldIdToNewMap[override.id];
          }

          if (override.needsAddToServer && action.payload.hasAddedAllToServer) {
            override.needsAddToServer = false;
          }
        }
      }
    },

    decreaseLevel: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;
      const dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;
      const selectedRowIds = dataRows.filter((r) => (r.uiState.isSelected)).map((r) => r.clientSidePredictionId);
      const changeSet = decreaseLevelOfRowsByIds(
        dataRows,
        selectedRowIds,
        state.wbsMetadata?.rbsCodeSeparator ?? ' ',
        state.wbsMetadata?.rbsCodeCustomizations ?? [],
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.wbsMetadata?.projectDataId ?? -1,
        state.wbsMetadata?.directCostDataId ?? -1,
        state.wbsMetadata?.finalCostsDataId ?? -1
      );

      (isResources) ?
        (state.rbsDataRows = calculateTitleUIState(dataRows)) :
        (state.wbsDataRows = calculateTitleUIState(dataRows, state.wbsMetadata, state.companyLimitations));

      if (changeSet.length > 0) {
        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
    },

    increaseLevel: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;
      const dataRows = isResources ? state.rbsDataRows : state.wbsDataRows;
      const selectedRowIds = dataRows.filter((r) => (r.uiState.isSelected)).map((r) => r.clientSidePredictionId);
      const changeSet = increaseLevelOfRowsByIds(
        dataRows,
        selectedRowIds,
        state.wbsMetadata?.rbsCodeSeparator ?? ' ',
        state.wbsMetadata?.rbsCodeCustomizations ?? [],
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.wbsMetadata?.projectDataId ?? -1,
        state.wbsMetadata?.directCostDataId ?? -1,
        state.wbsMetadata?.finalCostsDataId ?? -1
      );

      (isResources) ?
        (state.rbsDataRows = calculateTitleUIState(dataRows)) :
        (state.wbsDataRows = calculateTitleUIState(dataRows, state.wbsMetadata, state.companyLimitations));
      if (changeSet.length > 0) {
        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
    },

    duplicateRows: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;
      let dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;
      const selectedRows = dataRows.filter((r) => (r.uiState.isSelected));
      const currentPhaseId = getCurrentPhaseId(state.milestones, isResources);
      const wbsPermissions = getWbsPermissions(dataRows, currentPhaseId, state.wbsMetadata, isResources);
      const parentSelectedRowsOnly = [];
      const selectedRowIds = new Set(selectedRows.map((r) => r.id));
      const parentRowCodeByAllChildrenLookup: { [key: string]: WBSDataRowShapeWithStates[] } = {};

      let prevParentRowCode = undefined;
      for (const dataRow of dataRows) {
        if (!wbsPermissions[dataRow.wbsCode].canAddSibling) {
          continue;
        }

        const isChildOfSelectedParent = (prevParentRowCode && dataRow.wbsCode.includes(prevParentRowCode));
        if (isChildOfSelectedParent) {
          parentRowCodeByAllChildrenLookup[prevParentRowCode as string].push(dataRow);
          continue;
        }

        // At this point "dataRow" can only be a selected parent row
        const isSelected = selectedRowIds.has(dataRow.id);
        if (isSelected) {
          parentSelectedRowsOnly.push(dataRow);
          prevParentRowCode = dataRow.wbsCode;
          if (parentRowCodeByAllChildrenLookup[dataRow.wbsCode] === undefined) {
            parentRowCodeByAllChildrenLookup[dataRow.wbsCode] = [];
          }
        }
      }

      for (const parentSelectedRow of parentSelectedRowsOnly) {
        const allChildren = parentRowCodeByAllChildrenLookup[parentSelectedRow.wbsCode];
        dataRows = duplicateWbsRow(dataRows, parentSelectedRow, allChildren);
      }

      const changeSet = calculateWbsCode(
        dataRows,
        state.wbsMetadata?.rbsCodeSeparator ?? ' ',
        state.wbsMetadata?.rbsCodeCustomizations ?? [],
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.wbsMetadata?.projectDataId ?? -1,
        state.wbsMetadata?.directCostDataId ?? -1,
        state.wbsMetadata?.finalCostsDataId ?? -1
      );

      (isResources) ? (state.rbsDataRows = dataRows) : (state.wbsDataRows = dataRows);

      if (changeSet.length > 0) {
        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
    },

    splitRows: (state, action: PayloadAction<{ isResources: boolean, rowSplits: WBSRowSplitShape[] }>) => {

      const { isResources, rowSplits } = action.payload;
      let dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;

      // Ignore the descendents of selected rows as attempting to split them at the same time adds a large amount of
      // complexity
      const selectedRows: WBSDataRowShapeWithStates[] = [];
      const selectedRowPrefixes: string[] = [];
      for (const row of dataRows) {
        if (row.uiState.isSelected && !selectedRowPrefixes.find((prefix) => row.wbsCode.startsWith(prefix))) {
          selectedRows.push(row);
          selectedRowPrefixes.push(row.wbsCode);
        }
      }

      const matchingOverridesByWbsRowId: { [index: string]: WBSOverrideShapeClient[] } = {};
      const overridesToAddByWbsRowId: { [index: string]: WBSOverrideShapeClient[] } = {};

      if (isResources) {
        for (const wbsRow of state.wbsDataRows) {
          for (const override of wbsRow.resourceOverrides ?? []) {
            if (!matchingOverridesByWbsRowId[wbsRow.clientSidePredictionId]) {
              matchingOverridesByWbsRowId[wbsRow.clientSidePredictionId] = [];
            }

            matchingOverridesByWbsRowId[wbsRow.clientSidePredictionId].push(override);
          }
        }
      }

      const originalSortOrderById = Object.fromEntries(dataRows.map((r) => [r.id, r.sortOrder]));

      for (const row of selectedRows) {
        const {
          newDataRows,
          newOverridesByWbsRowId
        } = splitWbsRow(dataRows, row, rowSplits, isResources, matchingOverridesByWbsRowId);
        dataRows = newDataRows;

        for (const [wbsRowId, newOverrides] of Object.entries(newOverridesByWbsRowId)) {
          if (!overridesToAddByWbsRowId[wbsRowId]) {
            overridesToAddByWbsRowId[wbsRowId] = [];
          }

          overridesToAddByWbsRowId[wbsRowId].push(...newOverrides);
        }
      }

      // Remove temporary rows created when cases where both a row and its descendent row are selected to be split
      dataRows = dataRows.filter((row) => !row.id || !(row.id && row.id < 1 && row.uiState.isDeleting));

      // Resource overrides will be automatically sent to the server when they get their actual ids after the new rows
      // have been added
      for (const wbsRow of ((isResources) ? state.wbsDataRows : dataRows)) {
        if (overridesToAddByWbsRowId[wbsRow.clientSidePredictionId]) {
          wbsRow.resourceOverrides?.push(...overridesToAddByWbsRowId[wbsRow.clientSidePredictionId]);
        }
      }

      const changeSet = calculateWbsCode(
        dataRows,
        state.wbsMetadata?.rbsCodeSeparator ?? ' ',
        state.wbsMetadata?.rbsCodeCustomizations ?? [],
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.wbsMetadata?.projectDataId ?? -1,
        state.wbsMetadata?.directCostDataId ?? -1,
        state.wbsMetadata?.finalCostsDataId ?? -1
      );
      (isResources) ? (state.rbsDataRows = dataRows) : (state.wbsDataRows = dataRows);

      if (changeSet.length > 0) {
        const changeSetIds = new Set(changeSet.map(({ id }) => id));
        for (const dataRow of dataRows) {
          if (
            dataRow.id &&
            dataRow.id > 0 &&
            originalSortOrderById[dataRow.id] !== undefined &&
            originalSortOrderById[dataRow.id] !== dataRow.sortOrder
          ) {
            if (changeSetIds.has(dataRow.id)) {
              const existingChange = changeSet.find((c) => c.id === dataRow.id);
              if (existingChange) {
                existingChange.sortOrder = dataRow.sortOrder;
              }
            }
            else {
              changeSet.push({ id: dataRow.id, sortOrder: dataRow.sortOrder });
              changeSetIds.add(dataRow.id);
            }
          }
        }

        // This should be move out of there as reducer should not call apis.
        updateWbsRows(JSON.parse(JSON.stringify(changeSet))).then(() => {});
      }

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
    },

    saveDetailLevel: (state, action: PayloadAction<{ isResources: boolean, detailLevel: number }>) => {

      const { isResources, detailLevel } = action.payload;
      const dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;
      (isResources) ? (state.rbsDetailLevel = detailLevel) : (state.wbsDetailLevel = detailLevel);
      updateDetailLevel(dataRows, detailLevel);
    },

    updateRowsDetailLevel: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;
      const dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;
      const detailLevel = (isResources) ? state.rbsDetailLevel : state.wbsDetailLevel;
      updateDetailLevel(dataRows, detailLevel);
    },

    recalculateTotals: (state, action: PayloadAction) => {

      calculateTotal(state.rbsDataRows, state.wbsDataRows, Boolean(state.wbsMetadata?.hasDefinedResources));
    },

    recalculateWbsCodes: (state, action: PayloadAction<{ isResources: boolean }>) => {

      const { isResources } = action.payload;
      const dataRows = (isResources) ? state.rbsDataRows : state.wbsDataRows;

      const changeSet = calculateWbsCode(
        dataRows.filter((r) => !r.uiState.isDeleting),
        state.wbsMetadata?.rbsCodeSeparator ?? ' ',
        state.wbsMetadata?.rbsCodeCustomizations ?? [],
        state.wbsMetadata?.wbsCodeSeparator ?? ' ',
        state.wbsMetadata?.wbsCodeCustomizations ?? [],
        state.wbsMetadata?.projectDataId ?? -1,
        state.wbsMetadata?.directCostDataId ?? -1,
        state.wbsMetadata?.finalCostsDataId ?? -1
      );

      if (changeSet.length > 0) {
        updateWbsRows(changeSet).then(() => {});

        const codesById: { [index: string]: string } = {};
        for (const { id, wbsCode } of changeSet) {
          if (!id || wbsCode === undefined) {
            continue;
          }

          codesById[id] = wbsCode;
        }

        for (const row of dataRows) {
          if (codesById[row.clientSidePredictionId] !== undefined) {
            row.wbsCode = codesById[row.clientSidePredictionId];
            row.customWbsCode = generateCustomWbsCode(
              row.wbsCode,
              state.wbsMetadata?.wbsCodeSeparator ?? ' ',
              state.wbsMetadata?.wbsCodeCustomizations ?? []
            );
          }
        }
      }
    },

    updateWbsPushRequested: (state, action: PayloadAction<boolean>) => {

      state.wbsPushRequested = action.payload;
      state.preparingForWbsPush = false;
    }
  }
});

export const {
  deselectAllRows,
  reset,
  updateDefineResources,
  updateDataTableWBSMetadata,
  setDataTableWBSMetadata,
  updateWbsTableData,
  toggleExpandRow,
  copyColumn,
  updateCell,
  selectRows,
  saveCell,
  addNewRows,
  pasteOnWBSCodeColumn,
  insertInitialLevelsToWbsData,
  updateIds,
  deleteRows,
  completeDeleteRows,
  decreaseLevel,
  increaseLevel,
  duplicateRows,
  splitRows,
  saveDetailLevel,
  copyTable,
  recalculateTotals,
  recalculateWbsCodes,
  updateResources,
  updateRowsDetailLevel,
  updateTitleUIState,
  updateWbsPushRequested
} = wbsDataTableSlice.actions;

export default wbsDataTableSlice.reducer;
