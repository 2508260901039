/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.0.0
 * Tracking Plan Version: 6
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {AmplitudeClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'development'|'production'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef {Object} IdentifyOptions
 * @type {object}
 */

/**
 * @typedef {Object} GroupOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} development
 * @property {string} production
 */
export const ApiKey = {
  development: '',
  production: '417b47af64b379978df66083712a56fc'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '6',
    branch: 'main',
    source: 'web',
    versionId: 'abec3534-3825-4567-9487-b3bc5ef11fa1'
  }
};

export class Identify {
  constructor() {
    this.event_type = 'Identify';
  }
}

export class Group {
  constructor() {
    this.event_type = 'Group';
  }
}

export class AddTag {
  constructor() {
    this.event_type = 'AddTag';
  }
}

export class AddVariable {
  constructor() {
    this.event_type = 'AddVariable';
  }
}

export class DeleteTag {
  constructor() {
    this.event_type = 'DeleteTag';
  }
}

export class DeleteVariable {
  constructor() {
    this.event_type = 'DeleteVariable';
  }
}

export class EditTag {
  constructor() {
    this.event_type = 'EditTag';
  }
}

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {AmplitudeClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {AmplitudeClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} [options] Configuration options to initialize the Ampli SDK with.
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude;
      return this.amplitude?.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {IdentifyOptions} [options] Optional event options.
   */
  identify(userId, options) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    return this.amplitude?.identify(ampIdentify, options);
  }

  /**
   * Set Group for the current user
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   */
  setGroup(groupType, groupName) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(groupType, groupName);
  }

  /**
   * Identify a group.
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {GroupOptions} [options] Options for this groupIdentify call.
   */
  groupIdentify(groupType, groupName, options) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude?.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    return this.amplitude?.track(event, undefined, options);
  }

  /**
   * AddTag
   *
   * [View in Tracking Plan](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/events/main/latest/AddTag)
   *
   * Owner: Jordan Szymczyk
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  addTag(options) {
    return this.track(new AddTag(), options);
  }

  /**
   * AddVariable
   *
   * [View in Tracking Plan](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/events/main/latest/AddVariable)
   *
   * Owner: Jordan Szymczyk
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  addVariable(options) {
    return this.track(new AddVariable(), options);
  }

  /**
   * DeleteTag
   *
   * [View in Tracking Plan](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/events/main/latest/DeleteTag)
   *
   * Owner: Jordan Szymczyk
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  deleteTag(options) {
    return this.track(new DeleteTag(), options);
  }

  /**
   * DeleteVariable
   *
   * [View in Tracking Plan](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/events/main/latest/DeleteVariable)
   *
   * Owner: Jordan Szymczyk
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  deleteVariable(options) {
    return this.track(new DeleteVariable(), options);
  }

  /**
   * EditTag
   *
   * [View in Tracking Plan](https://data.amplitude.com/relay-construction/dev.relayconstructionsolutions.com/events/main/latest/EditTag)
   *
   * Owner: Jordan Szymczyk
   *
   * @param {Object} properties The event's properties.
   * @param {EventOptions} [options] Options for this track call.
   */
  editTag(properties, options) {
    return this.track(new EditTag(properties), options);
  }
}

export const ampli = new Ampli();
