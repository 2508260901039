import React, { useCallback, useEffect } from 'react';
import request from 'superagent';

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2em'
  }
}));

const Maintenance = (props) => {

  const classes = useStyles();

  const redirectToHomeIfSiteIsOnline = useCallback(async () => {

    try {
      const result = await request
        .get(process.env.REACT_APP_API_URL + '/user/details')
        .withCredentials();

      if (result?.body?.id) {
        window.location.replace('/');
      }
    }
    catch (e) {
      if (e?.response?.statusCode >= 400 && e?.response?.statusCode <= 499) {
        window.location.replace('/login');
      }
    }
  }, []);

  useEffect(() => {

    redirectToHomeIfSiteIsOnline();
  }, [redirectToHomeIfSiteIsOnline]);

  return (
    <Container className={classes.root}>
      We're doing some updates behind the scenes.  Be back soon!
    </Container>
  );
};

export default Maintenance;
