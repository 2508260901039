import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: any) => ({
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  form: {
    padding: theme.spacing(2),
    overflowWrap: 'break-word'
  },
  loginHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  successLink: {
    color: theme.palette.primary.textColorMedium,
    fontSize: '1.25rem'
  },
  userMessageError: {
    color: theme.palette.response.warning
  },
  userMessageInfo: {
    color: theme.palette.secondary.dark
  }
}));

export { styles };
