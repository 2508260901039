export const basicLineHeight = 32;
export const buffLineHeight = 35;
export const buffTotalRowHeight = 50;
export const CostQuantityExtraWidth = 20;
export const DefaultLineHeight = 16;
export const FolderSwoopWidth = 90;
export const FolderColumnShift = 30;
export const FolderTabContentWidth = 325;
export const FolderTabWidth = FolderTabContentWidth + FolderSwoopWidth;
export const FirstSectionPhasesExtraMargin = 24;
export const InputCostHeight = 100;
export const ITEM_HEADER_TOP = 68;
export const ItemPaddingRight = 14;
export const ItemPaddingLeft = 24;
export const ItemNumberColumnWidth = 51;
export const ItemTitleWidth = (FolderTabWidth - ItemPaddingLeft - ItemNumberColumnWidth) + FolderColumnShift;
export const PaddingForBuffsAddRemoveButtons = 55;
export const SectionNameMaxLength = 50;
export const SectionClassificationMaxLength = 50;
export const SectionTitleMaxLength = 50;
export const SectionBuffMaxLength = 256;
export const TagHeight = 16 + 4;
export const TagWidth = 110;
export const TotalOverrideMaxDigits = 15;
