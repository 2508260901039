"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRowLevel = void 0;
var _parseCodeNumbers = require("./parseCodeNumbers");
/**
 * Takes a WBS data row and returns the row level determined by the wbsCode.
 * @param {WBSDataRowShape} dataRow - a WBS data row object that should include the wbsCode
 * @returns {number} - a number representing the WBS level of the data row
 */
var getRowLevel = function getRowLevel(dataRow) {
  var codeNumbers = (0, _parseCodeNumbers.parseCodeNumbers)(dataRow.wbsCode);
  return codeNumbers.length;
};
exports.getRowLevel = getRowLevel;