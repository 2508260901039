/* eslint-disable */
// This file should never be manually changed.
// It should only be created using the create-permission-files script in the server folder.
// Make the changes you need to permissions.json and then run the script to generate this export.

export const ProjectDefaultPermissionStructure = { 

	AdditionalPerms: { 
		IsThirdPartyUser: false,
		IsViewingOwnCompanysProject: false,
		InviteEstimatorsEnabled: false,
		ShouldShowBlankBidValues: false,
	},

	Announcements: { 
		AddEditDeleteArea: false,
		AddEditDeleteProject: false,
	},

	Area: { 
		AddAnnouncement: false,
		AddEditDeleteBuff: false,
		AddEditDeleteMilestones: false,
		AddRemoveEstimateOverride: false,
		AddRemoveModules: false,
		AdvanceMilestone: false,
		Copy: false,
		Delete: false,
		EditClassification: false,
		EditDeleteAdminAnnouncements: false,
		EditDeleteEstimatorAnnouncement: false,
		EditMetadata: false,
		EditModuleIcon: false,
		EditModuleName: false,
		EditModuleOrder: false,
		EditTitle: false,
		EnableDisableMilestones: false,
		OpenMilestoneSummarySheet: false,
		ToggleTags: false,
	},

	AreaFiles: { 
		Archive: false,
		ChangeVisibility: false,
		Upload: false,
		UploadNewVersion: false,
	},

	Bid: { 
		Add: false,
		AddDeleteOwnMessage: false,
		AddDeleteOwnNote: false,
		AddRemoveOverrideOtherCompanyBidTotal: false,
		AddRemoveOverrideOwnCompanyBidTotal: false,
		ApproveUnapprove: false,
		ArchiveUnarchive: false,
		AwardUnaward: false,
		DeleteAnyFromCompanyMessage: false,
		DeleteAnyFromCompanyNote: false,
		EditCostTypeWhenStrictModeOff: false,
		EditCostTypeWhenStrictModeOn: false,
		EditOtherCompanyCostWhenHideOff: false,
		EditOtherCompanyCostWhenHideOn: false,
		EditOtherCompanyLineItemValues: false,
		EditOwnCompanyCost: false,
		EditOwnCompanyLineItemValues: false,
		EditSummarySheetComment: false,
		OtherCompanyCostQuantLumpSumIsVisibleWhenHideOn: false,
		SendInvitation: false,
		SummarySheetIsVisible: false,
	},

	BidFiles: { 
		Archive: false,
		ChangeVisibility: false,
		Upload: false,
		UploadNewVersion: false,
	},

	Division: { 
		AddArea: false,
		AddBids: false,
		AddEditDeleteAutoAddedModule: false,
		AddEditDeleteMilestones: false,
		AddModules: false,
		AddRemoveTags: false,
		AdvanceMilestone: false,
		Delete: false,
		EditLabel: false,
		EditMetadata: false,
		EditName: false,
		EnableDisableMilestones: false,
		ExportPDF: false,
		Merge: false,
		MilestoneSummarySheetIsVisible: false,
		ToggleAutomaticallyAddedBids: false,
	},

	DivisionList: { 
		PhaseMatrixIsVisible: false,
		AddDeleteDivision: false,
		ReorderItems: false,
		ToggleVisibility: false,
	},

	ItemFiles: { 
		Archive: false,
		ChangeVisibility: false,
		Upload: false,
		UploadNewVersion: false,
	},

	Invites: { 
		AllAreVisible: false,
		DeleteAnyEstimatorInvite: false,
		DeleteSentBySelfEstimatorInvite: false,
		LockUnlockInvites: false,
		SendAll: false,
		ToggleCompareBids: false,
		ToggleSendBidsByDefault: false,
	},

	KPIs: { 
		AddEditDeleteProjectMilestoneWidgetEntries: false,
		AddRemoveLinkedMetadata: false,
		ChangeVarianceWidgetSettings: false,
		CreateBinderReport: false,
		EditKpiLayouts: false,
		IsVisible: false,
	},

	LineItem: { 
		AddDupeEditDelOwnerLineItem: false,
		AddRemoveTag: false,
		DemotePromoteBidder: false,
		DemotePromoteOwner: false,
		ElevateBidderLineItem: false,
		Rearrange: false,
	},

	Markup: { 
		ApplyRemove: false,
	},

	MetadataCategories: { 
		AddEditDeleteDivision: false,
		AddEditDeleteProject: false,
		AddEditDeleteSection: false,
	},

	MilestoneManagement: { 
		Add: false,
		Advance: false,
		CreateAndAssociateContracts: false,
		IsVisible: false,
	},

	MySettings: { 
		CreateDetailsView: false,
		EditDefaultEstimateStyle: false,
		EditQuickReportStyle: false,
	},

	PDFModule: { 
		AddMarkup: false,
		Archive: false,
		DeleteMarkup: false,
		Download: false,
		isVisible: false,
		TogglePrivacy: false,
		Upload: false,
	},

	Project: { 
		ChangeProjectVisibility: false,
		ImportEstimateOrTakeoff: false,
		EditDescription: false,
		EditLocation: false,
		EditMetadata: false,
		EditName: false,
		IsVisible: false,
		SeeProjectCosts: false,
	},

	ProjectFiles: { 
		AssignToBids: false,
		ChangeVisibility: false,
		UploadDelete: false,
		UploadNewVersion: false,
	},

	ProjectSettings: { 
		AddDeleteLogo: false,
		CanImportData: false,
		ChangeDueDate: false,
		SetAsTemplate: false,
		SetEstimatorsCanAssignOtherEstimators: false,
		SetHideBidAmountsForEstimators: false,
		SetPrepopulatedBidWithUints: false,
		SetUseStrictModeForBids: false,
	},

	SummarySheet: { 
		AddRemoveBidderReport: false,
		AddRemoveCommentReport: false,
		AddRemoveCostQauntReport: false,
		AddRemoveEditModule: false,
		AddRemoveMilestoneComparison: false,
		AddRemoveEditOwnCollections: false,
		AddRemoveSetMultiplierReport: false,
		EditComment: false,
		ExportJoinExcelFile: false,
		OverrideDirectCost: false,
		OverrideGrandTotal: false,
	},

	Tags: { 
		AddEditDelete: false,
		Toggle: false,
	},

	TeamBoard: { 
		AddNote: false,
		EditDeleteOtherNote: false,
		EditDeleteOwnNote: false,
	},

	Trackers: { 
		AddRemove: false,
		IsVisible: false,
		Pin: false,
	},

	UserManager: { 
		AddRemoveThirdPartyUser: false,
		AssignRemoveApprover: false,
		AssignRemoveContributor: false,
		AssignRemoveEstimatorSettingDisabled: false,
		AssignRemoveEstimatorSettingEnabled: false,
		AssignRemoveManager: false,
		AssignRemoveViewer: false,
	},

	Variables: { 
		AddEditDelete: false,
	},

	WBS: { 
		AddRemoveEditWBSColumns: false,
		AddRemoveEditWBSRows: false,
		AddRemoveEditWBSPhases: false,
		EditWBSMetadata: false,
		ModifyWBSCodeCustomization: false,
		PushPull: false,
		UseWBS: false,
	},
}

export const CompanyDefaultPermissionStructure = { 

	AdditionalPerms: {	},

	APIOnly: { 
		AddDeleteViewWebhookCallbacks: false,
		MergeCompanies: false,
	},

	CompanyContacts: { 
		AddReactivateUser: false,
		ChangeUserType: false,
		DeactivateUser: false,
		DemotePromoteAdmin: false,
		EditOwnProfile: false,
		IsVisible: false,
		SetPrimaryContact: false,
	},

	CompanyEventLog: { 
		IsVisible: false,
	},

	CompanyGeneral: { 
		EditDetails: false,
		EditLabels: false,
		IsVisible: false,
		MergeCompanies: false,
	},

	CompanyInvitations: { 
		IsVisible: false,
	},

	CompanyQualifications: { 
		Edit: false,
		IsVisible: false,
	},

	CompanyRequirements: { 
		Edit: false,
		IsVisible: false,
	},

	CompanySettings: { 
		AddEditDeletePersistentModuleNames: false,
		AddEditDeleteStageGates: false,
		AddDeleteProjectLabel: false,
		AlwaysShowDecimal: false,
		AppearanceTabIsVisible: false,
		AuthTokenTabIsVisible: false,
		CreateDeleteAuthToken: false,
		CustTabIsVisible: false,
		EditAllowContributorRole: false,
		EditAllowJoinExport: false,
		EditCustomDesignations: false,
		EditDefaultRoleType: false,
		EditItemTitleLimitation: false,
		EditLoginPersistance: false,
		FeaturesTabIsVisible: false,
		FormulasTabIsVisible: false,
		IsVisible: false,
		LimitationsTabIsVisible: false,
		LoginAsUserTabIsVisible: false,
		ModifyXmlImportDefaultTables: false,
		ProjectLabelTabIsVisible: false,
		RoleTypesTabIsVisible: false,
		UseShorthandNotation: false,
		CurvesTabIsVisible: false,
		ImportTabIsVisible: false,
		ToggleWbsFeature: false,
	},

	Contacts: { 
		AddCompany: false,
		AddRemoveLabel: false,
		CreateDeleteLabel: false,
		ImportCompany: false,
	},

	OtherCompanyContacts: { 
		AddReactivateUser: false,
		AllAreVisible: false,
		DeactivateUser: false,
		DemotePromoteAdmin: false,
		SetPrimaryContact: false,
		SetUserPasswords: false,
		SetUserTypes: false,
	},

	OtherCompanySettings: { 
		Edit: false,
		IsVisible: false,
	},

	ProjectsPage: { 
		AddProject: false,
		AllProjectsButPrivateAreVisible: false,
		ArchiveAnyCompanyProject: false,
		ArchiveOwnProject: false,
		CreateLabel: false,
		DeleteAnyCompanyProject: false,
		DeleteOwnProject: false,
		UnArchiveAnyCompanyProject: false,
		UnArchiveOwnProject: false,
	},

	WBS: { 
		ReassignPull: false,
		CanHideCompanyWbsRows: false,
		CanHideUserWbsRows: false,
		CanModifyDashboard: false,
		CanModifyDefaultVarianceReportSettings: false,
	},
}

