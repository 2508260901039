import { Draft } from '@reduxjs/toolkit';
import { WBSTableRootState } from '../../stateTypes';
import { WBSDataRowShapeWithStates } from '../../wbs/calculateWBSState';
import { generatePreviousWBSLevels, getCorrectWBSName, nextRow } from './wbsUtils';
import { WBSCodeCustomizationShape } from 'wbs/dist/types/WBSCodeCustomizationShape';
import { convertCustomWbsCodeToWbsCode, fillMissingWBSMappings } from './wbsCode';
import { addWBSCodeCustomizations } from './api';
import { getCurrentPhaseId } from 'wbs/dist/getCurrentPhaseId';

export const getPasteOnWBSCodeColumnChangeSet = (
  state: Draft<WBSTableRootState>,
  isResources: boolean,
  primaryCurrencySymbol: string,
  data: string[][],
  wbsCodeSeparator: string,
  wbsCodeCustomizations: WBSCodeCustomizationShape[][]
) => {

  const changeSet: { id?: number, name: string, quantity: string, cost: string, inputCost: string, inputProjectCurrencyId: null }[] = [];
  const newDataRows: WBSDataRowShapeWithStates[] = [];
  const dataRows = (isResources ? state.rbsDataRows : state.wbsDataRows) as WBSDataRowShapeWithStates[];
  const wbsCustomCodes = data.map((row) => row[0]);
  let updatedDataRows: WBSDataRowShapeWithStates[] = dataRows;


  const newWbsCodeCustomizations = fillMissingWBSMappings(
    wbsCustomCodes,
    isResources,
    wbsCodeSeparator,
    wbsCodeCustomizations,
    state.projectId
  );

  const notExistWbsCodeCustomizations = [];

  for (const newWbsCodeCustomizationLevel of newWbsCodeCustomizations) {
    for (const newWbsCodeCustomization of newWbsCodeCustomizationLevel) {
      if (newWbsCodeCustomization.id === -1) {
        notExistWbsCodeCustomizations.push(newWbsCodeCustomization);
      }
    }
  }

  addWBSCodeCustomizations(notExistWbsCodeCustomizations).then(() => {});

  for (const dataRow of data) {

    const wbsCustomCode = dataRow[0];
    const wbsCode = convertCustomWbsCodeToWbsCode(wbsCustomCode, wbsCodeSeparator, newWbsCodeCustomizations);
    const wbsRow = dataRows.find((row) => row.wbsCode === wbsCode);
    if (wbsRow) {
      if (dataRow.length > 1) {
        const name = getCorrectWBSName(wbsCode, dataRow[1]);
        if (
          wbsRow.name !== name ||
          wbsRow.quantity !== (dataRow[2] ?? wbsRow.quantity) ||
          wbsRow.cost !== (dataRow[3] ?? wbsRow.cost)
        ) {
          wbsRow.name = name;
          wbsRow.quantity = dataRow[2] ?? wbsRow.quantity;
          wbsRow.cost = dataRow[3] ?? wbsRow.cost;
          wbsRow.inputCost = wbsRow.cost;
          changeSet.push({ id: wbsRow.id, name, quantity: wbsRow.quantity, cost: wbsRow.cost, inputCost: wbsRow.inputCost, inputProjectCurrencyId: null });
        }
      }
    }
    else {

      const previousWBSLevels = generatePreviousWBSLevels(wbsCode);

      let lastPreviousRow: WBSDataRowShapeWithStates | undefined = undefined;
      for (const previousWBSLevel of previousWBSLevels) {
        const previousWBSRow = updatedDataRows.find((row) => row.wbsCode === previousWBSLevel);
        if (previousWBSRow) {
          lastPreviousRow = previousWBSRow;
          continue;
        }

        if (!lastPreviousRow) {
          lastPreviousRow = updatedDataRows.find((row) => row.uiState.isLastRow) ?? updatedDataRows[0];
        }

        if (lastPreviousRow) {
          const name = getCorrectWBSName(previousWBSLevel, dataRow?.[1] ?? '');
          const newDataRow = nextRow(
            isResources,
            primaryCurrencySymbol,
            lastPreviousRow,
            { wbsCode: previousWBSLevel, name },
            wbsCodeSeparator,
            newWbsCodeCustomizations,
            getCurrentPhaseId(state.milestones, isResources)
          );
          newDataRow.name = name;

          if (previousWBSLevel === wbsCode) {
            newDataRow.quantity = dataRow[2] ?? newDataRow.quantity;
            newDataRow.cost = dataRow[3] ?? newDataRow.cost;
            newDataRow.inputCost = newDataRow.cost;
          }

          newDataRows.push(newDataRow);
        }
      }

      const currentIndex = dataRows.indexOf(lastPreviousRow!);
      const beforeCurrentRows = dataRows.slice(0, currentIndex + 1);
      const afterCurrentRows = dataRows.slice(currentIndex + 1);

      updatedDataRows = [
        ...beforeCurrentRows,
        ...newDataRows,
        ...afterCurrentRows
      ];

      updatedDataRows.forEach((r) => (r.uiState.isLastRow = false));

      const lastRow = updatedDataRows[updatedDataRows.length - 1];
      lastRow.uiState.isLastRow = true;
    }
  }

  return { updatedDataRows, newDataRows, changeSet, newWbsCodeCustomizations };
};
