import React, { ReactComponentElement } from 'react';
import { NavLink } from 'react-router-dom';

import { styles } from '../styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export interface ForgotPasswordPromptProps {
  canSubmit: boolean;
  email: string;
  onInputChange: (e: React.ChangeEvent<{ id: any; value: any; }>) => void;
  onKeyDown: (evt: React.KeyboardEvent) => void;
  onSubmitForgotPassword: (evt: React.MouseEvent | undefined) => void;
  onToggleForgotPasswordPrompt: (evt: React.MouseEvent) => void;
  userMessageUI: ReactComponentElement<any> | null;
}

const ForgotPasswordPrompt = (
  {
    canSubmit,
    email,
    onInputChange,
    onKeyDown,
    onSubmitForgotPassword,
    onToggleForgotPasswordPrompt,
    userMessageUI
  }: ForgotPasswordPromptProps) => {

  const classes = styles();

  return (
    <div className={classes.form}>
      <Typography variant="h5" gutterBottom>Forgot password</Typography>

      {userMessageUI}

      <form>
        <div>
          <TextField
            type="text"
            id="email"
            data-cy="forgot-password.email"
            label="Email"
            margin="normal"
            fullWidth
            value={email}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={classes.controls}>
          <Button
            variant="contained"
            color="primary"
            data-cy="forgot-password.submit"
            onClick={onSubmitForgotPassword}
            disabled={!canSubmit}
          >
            Send Reset Link
          </Button>
          <NavLink
            to=""
            onClick={onToggleForgotPasswordPrompt}
            color="secondary"
            data-cy="login.back-to-login-link"
          >
            Back to login
          </NavLink>
        </div>
      </form>
    </div>
  );
};

export { ForgotPasswordPrompt };
