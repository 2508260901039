import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import SwagPDF from '../SwagPDF';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// exposed to component via this.props.classes
const styles = () => ({
  //
});

class SwagPDFDialog extends Component {

  render = () => {

    return (
      <Dialog
        id={this.props.id}
        open={this.props.open}
        onClose={this.props.handleCancel}
        aria-labelledby={this.props.id + '_title'}
        maxWidth={false}
        data-cy="swagpdf-dialog"
        disableEnforceFocus={true}
      >
        <DialogTitle id={this.props.id + '_title'}>{this.props.title}</DialogTitle>
        <DialogContent>
          <SwagPDF
            key={'swagpdf_' + this.props.id}
            pdfUrl={this.props.pdfUrl}
            pageName={this.props.pageName}
            pageNumber={this.props.pageNumber}
            onSaveSnippet={this.props.onSaveSnippet}
          />
        </DialogContent>
      </Dialog>
    );
  };

}

const mapStateToProps = () => {

  return {
    //
  };
};

const mapDispatchToProps = () => {

  return {
    //
  };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SwagPDFDialog)));
