import request from 'superagent';

import apiCommon from './common';

export default {
  Acknowledge: async function (token) {

    const apiRequest = request.patch(process.env.REACT_APP_API_URL + '/acknowledgement/acknowledge')
      .send({ token });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AcknowledgeMultiple: async function (ids) {

    const apiRequest = request.patch(process.env.REACT_APP_API_URL + '/acknowledgement/acknowledge-multiple')
      .send({ ids });

    return await apiCommon.ProcessRequest(apiRequest);
  }
};
