import request from 'superagent';

import apiCommon from './common';

export default {

  SendMethods: {
    WebApp: 'Web App',
    Email: 'Email',
    TextMessage: 'Text Message'
  },

  SendMessage: async function (bidId, itemId, content, header) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/bid/item/send-message')
      .send({
        bidId,
        itemId,
        content,
        header
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteMessage: async function (id) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/bid/item/delete-message')
      .send({
        id
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  BidItemMessages: async function (bidId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/project/bid/item-messages')
      .query({
        bidId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  MarkAsRead: async function (itemMessageIds) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/project/bid/item/mark-messages-read')
      .send({
        itemMessageIds
      });

    return await apiCommon.ProcessRequest(apiRequest);
  }
};
