import * as actions from './mainActions';

class Setup {

  static isFirstLoadSincePageRefresh = true;

}

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  loadingCount: 0,
  isLoadingGrandTotal: false,
  loadingGrandTotalCount: 0,
  hasSeenDragTooltip: false,
  didMakeApiChange: false, // used to determine if ProjectHeader.js needs to refresh when it's scrolled to,
  projectImportingDataId: null,
  redirectOnError: false,
  showError: false,
  socketioConnected: false
};

export default function (state = initialState, action) {

  switch (action.type) {
    case actions.APP_SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn
      };
    case actions.APP_SET_IS_LOADING:
      let loadingCount = state.loadingCount;
      if (Setup.isFirstLoadSincePageRefresh) {
        loadingCount = 0;
        Setup.isFirstLoadSincePageRefresh = false;
      }

      if (action.isLoading) {
        loadingCount++;
      }
      else {
        loadingCount--;
      }

      if (loadingCount < 0) { // should never happen, but just in case
        console.warn('loadingCount went below zero!');
        loadingCount = 0;
      }

      return {
        ...state,
        isLoading: (loadingCount > 0),
        loadingCount
      };
    case actions.APP_SET_IS_LOADING_GRAND_TOTAL:
      let loadingGrandTotalCount = state.loadingGrandTotalCount;
      if (Setup.isFirstLoadSincePageRefresh) {
        loadingGrandTotalCount = 0;
        Setup.isFirstLoadSincePageRefresh = false;
      }

      if (action.isLoadingGrandTotal) {
        loadingGrandTotalCount++;
      }
      else {
        loadingGrandTotalCount = Math.max(0, loadingGrandTotalCount - 1);
      }

      return {
        ...state,
        isLoadingGrandTotal: (loadingGrandTotalCount > 0),
        loadingGrandTotalCount
      };
    case actions.APP_SET_DID_MAKE_API_CHANGE:
      return {
        ...state,
        didMakeApiChange: true
      };
    case actions.APP_RESET_DID_MAKE_API_CHANGE:
      return {
        ...state,
        didMakeApiChange: false
      };
    case actions.APP_SET_HAS_SEEN_DRAG_TOOLTIP:
      return {
        ...state,
        hasSeenDragTooltip: true
      };
    case actions.APP_SET_REDIRECT_ON_ERROR:
      return {
        ...state,
        redirectOnError: action.redirectOnError
      };
    case actions.APP_SET_SHOW_ERROR:
      return {
        ...state,
        showError: action.showError
      };
    case actions.APP_SET_SOCKETIO_CONNECTED:
      return {
        ...state,
        socketioConnected: action.socketioConnected
      };
    case actions.APP_SET_PROJECT_IMPORTING_DATA_ID:
      return {
        ...state,
        projectImportingDataId: action.projectImportingDataId
      };
    default:
      return state;
  }

}
