import React, { useMemo } from 'react';

import TodoItem from './TodoItem';

import List from '@material-ui/core/List';

const TodoList = (props) => {

  const {
    isShowAll,
    loggedInUserDetails,
    refreshTodos,
    setTodoToEdit,
    styleOverrides = {},
    todoList
  } = props;

  const listItems = useMemo(() => {

    return todoList.map((todoData) => {

      return (
        <TodoItem
          isShowAll={isShowAll}
          key={todoData.id}
          loggedInUserDetails={loggedInUserDetails}
          refreshTodos={refreshTodos}
          todoData={todoData}
          setTodoToEdit={setTodoToEdit}
        />
      );
    });
  }, [isShowAll, loggedInUserDetails, refreshTodos, setTodoToEdit, todoList]);

  return useMemo(() => {

    const style = {
      wordBreak: 'break-word',
      maxHeight: 'calc(100vh - 200px)',
      maxWidth: 'calc(100vw - 200px)',
      overflowY: 'auto',
      paddingLeft: '8px',
      ...styleOverrides
    };

    return (
      <List style={style}>
        {listItems}
      </List>
    );
  }, [listItems, styleOverrides]);
};

export default TodoList;
