import request from 'superagent';

import apiCommon from './common';

export default {

  Users: {
    All: 'All',
    LoggedInUserOnly: 'LoggedInUserOnly'
  },

  // projectId is optional.  if omitted, activity from all the user's projects will be returned
  // categories is optional.  if omitted, activity from all categories will be returned
  Log: async function (projectId, categories) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/activity/log')
      .query({
        projectId,
        categories
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  UnseenActivity: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/activity/unseen-activity')
      .query();

    return await apiCommon.ProcessRequest(apiRequest);
  }

};
