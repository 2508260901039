import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostManagementDashboardScenarioShapeWithPeriods, CostManagementRootState, WBSRow } from '../stateTypes';
import {
  CostManagementDashboardScenarioRowOptionsShape
} from '../../types/api/CostManagementDashboardScenarioRowOptionsShape';
import { updateDashboardScenarioCurve } from './costManagement/api';
import {
  calculateSelectedWBSRowCalculateForecast,
  calculateSelectedWBSRowCalculatePreviousForecast,
  calculateSelectedWBSRowForecastTable,
  calculateSelectedWBSRowPreviousForecastTable,
  getUnmodifiedEditedWBSRowForecastTable,
  getUnmodifiedSelectedWBSRowForecastTable,
  selectRowId,
  updateVariances,
  updateScenarioCurveForRow
} from './costManagement/selectedRow';
import {
  AdditionalCurveShape,
  WBSValue,
  WBSValueVerification
} from '../../components/Project/ProjectDashboard/utils/types';
import { createWbsLookup } from 'wbs/dist/createWbsLookup';
import { ProjectShape } from '../../types/api/ProjectShape';
import { getLeafRows } from 'wbs/dist/getLeafRows';
import store from '../store';
import { CostManagementDashboardApiSlice } from '../../api/costManagementDashboard';
import { costManagementDashboardTags } from '../../api/baseApi';


const initialState: CostManagementRootState = {
  dashboardScenarios: [],
  forecastColumnTotalsOnTop: false,
  forecastRowTotalsOnLeft: false,
  isMultiProject: false,
  projectIds: [],
  wbsRows: [],
  selectedWBSRowChildren: [],
  selectedWBSRowForecastTable: {},
  selectedWBSRowPreviousForecastTable: {},
  selectedWBSRowLabels: [],
  selectedWBSRowCalculateForecast: [],
  selectedWBSRowCalculatePreviousForecast: [],
  selectedWBSRowCalculateActuals: [],
  selectedWBSRowPeriodLabels: [],
  selectedWBSValueCustomCurves: [],
  selectedAdditionalCurves: [],
  additionalCurves: [],
  unmodifiedSelectedWBSRowForecastTable: {},
  wbsValues: [],
  wbsLookup: {
    codeLookup: {},
    topLevelDataRows: []
  },
  maxNumberOfDays: 0
};

export const costManagementSlice = createSlice({
  name: 'wbsDataTable',
  initialState,
  reducers: {
    reset: () => initialState,

    setSelectedWbsRowId: (state, action: PayloadAction<number>) => {

      selectRowId(state, action.payload);
    },

    setWBSValues: (state, action: PayloadAction<WBSValue[]>) => {

      state.wbsValues = action.payload;
    },

    setDashboard: (
      state,
      action: PayloadAction<{
        scenarios: CostManagementDashboardScenarioShapeWithPeriods[],
        data: WBSRow[],
        forecastColumnTotalsOnTop: boolean,
        forecastRowTotalsOnLeft: boolean,
        wbsValues: WBSValue[]
        projects: ProjectShape[]
        additionalCurves: AdditionalCurveShape[];
      }>
    ) => {

      const {
        forecastColumnTotalsOnTop,
        forecastRowTotalsOnLeft,
        projects,
        scenarios,
        data,
        wbsValues,
        additionalCurves
      } = action.payload;

      state.projectIds = projects.map((p) => p.id);
      state.isMultiProject = (projects.length > 1);
      state.dashboardScenarios = scenarios;
      state.additionalCurves = additionalCurves;
      state.forecastColumnTotalsOnTop = forecastColumnTotalsOnTop;
      state.forecastRowTotalsOnLeft = forecastRowTotalsOnLeft;

      const selectedScenario = state.selectedScenario;
      // If there's an existing selected scenario, then we need to make sure the new one coming from the server updates
      // the existing one with the latest data.
      if (selectedScenario) {
        const latestScenarioData = scenarios.find((s) => s.id === selectedScenario.id);
        if (latestScenarioData) {
          state.selectedScenario = latestScenarioData;
        }
      }
      else {
        // default to first scenario
        state.selectedScenario = scenarios[0];
      }

      state.wbsRows = data;
      state.wbsValues = wbsValues;
      const wbsLookup = createWbsLookup([], data);
      state.wbsLookup = wbsLookup.wbs;
      updateVariances(state);

      const existingSelectedRow = data.find((r) => r.id === state.selectedWBSRow?.id);

      if (existingSelectedRow) {
        selectRowId(state, existingSelectedRow.id);
      }
      else {
        selectRowId(state, data[0]?.id);
      }
    },

    setSelectedScenarioId: (state, action: PayloadAction<number>) => {

      state.selectedScenario = state.dashboardScenarios.find((scenario) => scenario.id === action.payload);

      if (state.selectedWBSRow) {
        selectRowId(state, state.selectedWBSRow.id);
      }
    },

    deleteSelectedScenarioId: (state, action: PayloadAction<void>) => {

      state.dashboardScenarios = state.dashboardScenarios.filter((scenario) => scenario.id !== state.selectedScenario?.id);
      state.selectedScenario = state.dashboardScenarios[0];
    },

    updateScenarioRowOptions: (state, action: PayloadAction<CostManagementDashboardScenarioRowOptionsShape>) => {

      const scenario = state.dashboardScenarios.find((s) => s.id === action.payload.scenarioId);
      if (scenario) {
        const rowOptionsIndex = scenario.rowOptions.findIndex((o) => o.id === action.payload.id);
        if (rowOptionsIndex !== -1) {
          scenario.rowOptions[rowOptionsIndex] = action.payload;
        }
        else {
          scenario.rowOptions.push(action.payload);
        }

        if (state.selectedScenario?.id === action.payload.scenarioId) {
          state.selectedScenario = scenario;
        }
      }
    },

    deleteAdditionalCurve: (state, action: PayloadAction<number>) => {

      const deletedAdditionalCurveId = action.payload;

      state.additionalCurves = state.additionalCurves.filter((curve) => curve.id !== deletedAdditionalCurveId);
      state.selectedAdditionalCurves =
        state.selectedAdditionalCurves.filter((curve) => curve.id !== deletedAdditionalCurveId);
      state.wbsValues = state.wbsValues.filter((val) => val.customCurveId !== deletedAdditionalCurveId);
    },

    editAdditionalCurve: (state, action: PayloadAction<{ id: number, name: string, color: string, weight: number }>) => {

      const { id, name, color, weight } = action.payload;

      const additionalCurve = state.additionalCurves.find((curve) => curve.id === id);
      if (additionalCurve) {
        additionalCurve.name = name;
        additionalCurve.color = color;
        additionalCurve.weight = weight;
      }

      const selectedAdditionalCurve = state.selectedAdditionalCurves.find((curve) => curve.id === id);
      if (selectedAdditionalCurve) {
        selectedAdditionalCurve.name = name;
        selectedAdditionalCurve.color = color;
        selectedAdditionalCurve.weight = weight;
      }
    },

    setSelectedControlAccountId: (state, action: PayloadAction<string | undefined>) => {

      state.selectedControlAccountId = action.payload;
    },

    updateSelectRowForecastingValue: (state, action: PayloadAction<{ wbsRowId: number, label: string, value: number }>) => {

      const { wbsRowId, label, value } = action.payload;

      state.selectedWBSRowForecastTable[wbsRowId][label] = value;
    },

    updateWBSValueVerification: (
      state,
      action: PayloadAction<{
        wbsValueId: number,
        newVerification: WBSValueVerification
      }>
    ) => {

      const matchingValue = state.wbsValues.find((v) => v.id === action.payload.wbsValueId);
      if (matchingValue) {
        matchingValue.verification = action.payload.newVerification;
      }
    },

    calculateSelectRowForecast: (
      state,
      action: PayloadAction<{ wbsRowId: number, label: string, increasedValue: number }>
    ) => {

      const { wbsRowId, label, increasedValue } = action.payload;
      const editedWbsRow = state.wbsRows.find((row) => row.id === wbsRowId);
      const selectedScenario = state.selectedScenario;
      if (!editedWbsRow || !selectedScenario) {
        return;
      }

      const dashboardId = selectedScenario.dashboardId;
      const currentLabelValue = state.selectedWBSRowForecastTable[editedWbsRow.id][label];
      const wbsLookup = createWbsLookup([], state.wbsRows);
      const leafRows = getLeafRows(wbsLookup.wbs.codeLookup, editedWbsRow.wbsCode)
        .filter((wbsRow) => wbsRow.id !== editedWbsRow.id); // filter self

      const editedRowHasLeafNodes = (leafRows.length > 0);
      const updatedRowsForEndpoint = [];
      let didCreateNewEndDate = false;

      const unmodifiedForecastTable = (editedRowHasLeafNodes) ?
        getUnmodifiedEditedWBSRowForecastTable(state, leafRows) :
        getUnmodifiedSelectedWBSRowForecastTable(state);

      if (editedRowHasLeafNodes) {
        const amountPerLeaf = (currentLabelValue / leafRows.length) || 0;
        const perLeafIncreasedValue = (increasedValue / leafRows.length) || 0;
        const forecastData = Object.values(unmodifiedForecastTable) as Record<string, number>[];
        forecastData.forEach((forecast) => (forecast[label] = amountPerLeaf));
        for (const leafRow of leafRows) {
          const dataForEndpoint = updateScenarioCurveForRow(
            selectedScenario,
            label,
            unmodifiedForecastTable,
            leafRow as WBSRow,
            perLeafIncreasedValue
          );

          updatedRowsForEndpoint.push(dataForEndpoint);
          if (dataForEndpoint.newRowEndDate) {
            didCreateNewEndDate = true;
          }
        }
      }
      else {
        unmodifiedForecastTable[wbsRowId][label] = currentLabelValue;
        const dataForEndpoint = updateScenarioCurveForRow(
          selectedScenario,
          label,
          unmodifiedForecastTable,
          editedWbsRow,
          increasedValue
        );

        updatedRowsForEndpoint.push(dataForEndpoint);
        if (dataForEndpoint.newRowEndDate) {
          didCreateNewEndDate = true;
        }
      }

      // Update all affected rows server side
      updateDashboardScenarioCurve(updatedRowsForEndpoint).catch(console.error).then(() => {

        if (didCreateNewEndDate && dashboardId) {
          store.dispatch(
            CostManagementDashboardApiSlice.util.invalidateTags([...costManagementDashboardTags(dashboardId)])
          );
        }
      });

      // current forecast
      calculateSelectedWBSRowForecastTable(state);
      calculateSelectedWBSRowCalculateForecast(state);
      state.unmodifiedSelectedWBSRowForecastTable = getUnmodifiedSelectedWBSRowForecastTable(state);
      // previous forecast
      calculateSelectedWBSRowPreviousForecastTable(state);
      calculateSelectedWBSRowCalculatePreviousForecast(state);

      updateVariances(state);
    }
  }
});

export const {
  reset,
  setWBSValues,
  setDashboard,
  setSelectedControlAccountId,
  setSelectedWbsRowId,
  setSelectedScenarioId,
  deleteAdditionalCurve,
  editAdditionalCurve,
  deleteSelectedScenarioId,
  updateScenarioRowOptions,
  updateSelectRowForecastingValue,
  updateWBSValueVerification,
  calculateSelectRowForecast
} = costManagementSlice.actions;

export default costManagementSlice.reducer;
