import { subscribe, unsubscribe } from './eventBus';


export default {

  Subscribe: async function (path, handler) {

    return await new Promise((resolve) => {

      subscribe(path, handler);
      resolve();
    });
  },

  Unsubscribe: async function (path, handler) {

    return await new Promise((resolve) => {

      unsubscribe(path, handler);
      resolve();
    });
  }
};
