"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChildRows = void 0;
/**
 * getChildRows takes a wbsLookup, a wbsCode, and returns all related children from that lookup.
 * @param {WBSCodeLookupShape} wbsCodeLookup - the lookup containing all relevant data for all wbsCodes.
 * @param {string} wbsCode - the WBS Code to get children for.
 * @returns {WBSDataRowShape[]} - an array of WBS Data Rows that are children of the given wbs code.
 */
var getChildRows = function getChildRows(wbsCodeLookup, wbsCode) {
  var children = wbsCodeLookup[wbsCode] ? wbsCodeLookup[wbsCode].children : [];
  return children;
};
exports.getChildRows = getChildRows;