import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  deleteButton: {
    '&:hover': {
      color: theme.palette.app.errorText
    }
  }
}));

export interface deleteButtonProps {
  onClick: () => void,
  dataCy?: string
}

const DeleteButton = ({ onClick, dataCy = 'delete-button' }: deleteButtonProps) => {

  const classes = useStyles();

  return (
    <IconButton
      className={classes.deleteButton}
      size="small"
      color="secondary"
      onClick={onClick}
      data-cy={dataCy}
    >
      <HighlightOffIcon/>
    </IconButton>
  );
};

export { DeleteButton };
