import { UserShape } from '../../../../types/api/UserShape';

export enum WbsValueType {
  Earned = 'earned',
  Commitments = 'commitments',
  Actuals = 'actuals',
  CustomCurve = 'custom curve'
}

export interface WBSValueVerification {
  id: number;
  status: string;
  setByUser: UserShape;
  setByUserId: number;
  timestamp: string;
  wbsValueId: number;
}

interface CustomCurveShape {
  color: string;
  costManagementDashboardId: number;
  id: number;
  name: string;
  weight: number;
}

export interface WBSValue {
  id: number;
  customCurveId: number | null;
  customCurve?: CustomCurveShape;
  type: WbsValueType;
  date: string;
  source: string;
  value: string;
  projectId: number;
  wbsCode: string;
  wbsName: string;
  extraData: { [key: string]: string };
  verification: WBSValueVerification;
}

export interface AdditionalCurveShape {
  id: number;
  costManagementDashboardId: number;
  name: string;
  weight: number;
  color: string;
}

interface Company {
  name: string;
}

export interface SamlConfig {
  id: number;
  domain: string;
  entityId: string;
  metadata: string;
  companyId?: number;
  company?: Company;
}
