import React, { ReactComponentElement } from 'react';
import { NavLink } from 'react-router-dom';

import { AlternateLink } from '../AlternateLink/AlternateLink';
import { styles } from '../styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export interface LoginPromptProps {
  canSubmit: boolean;
  email: string;
  fullName: string;
  hasInviteDetails: boolean;
  isNotCompanyMember: boolean;
  onInputChange: (e: React.ChangeEvent<{ id: any; value: any; }>) => void;
  onKeyDown: (evt: React.KeyboardEvent) => void;
  onSubmitLogin: (e: React.MouseEvent | undefined) => void;
  onToggleCreateNewUserPrompt: (evt: React.MouseEvent<Element, MouseEvent>) => void;
  onToggleForgotPasswordPrompt: (evt: React.MouseEvent<Element, MouseEvent>) => void;
  password: string;
  userMessageUI: ReactComponentElement<any> | null
}

const LoginPrompt = (
  {
    canSubmit,
    email,
    fullName,
    onInputChange,
    onKeyDown,
    hasInviteDetails,
    isNotCompanyMember,
    password,
    onSubmitLogin,
    onToggleCreateNewUserPrompt,
    onToggleForgotPasswordPrompt,
    userMessageUI
  }: LoginPromptProps) => {

  const classes = styles();

  return (
    <div className={classes.form}>
      <div className={classes.loginHeader}>
        <Typography variant="h5" gutterBottom>Log in</Typography>
        {hasInviteDetails
          ?
          <NavLink
            to=""
            onClick={onToggleCreateNewUserPrompt}
            color="secondary"
            data-cy="login.create-new-user-link"
          >
            {'Create new company user'}
          </NavLink>
          :
          null
        }
      </div>

      {userMessageUI}

      <form>
        <div>
          <TextField
            type="text"
            id="email"
            data-cy="login.email"
            label="Email"
            margin="normal"
            fullWidth
            value={email}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div>
          <TextField
            type="password"
            id="password"
            data-cy="login.password"
            label="Password"
            margin="normal"
            fullWidth
            value={password}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={classes.controls}>
          <Button
            variant="contained"
            color="primary"
            data-cy="login.submit"
            onClick={onSubmitLogin}
            disabled={!canSubmit}
          >
            Log In
          </Button>
          <AlternateLink
            fullName={fullName ?? 'user'}
            isNotCompanyMember={isNotCompanyMember}
            onToggleForgotPasswordPrompt={onToggleForgotPasswordPrompt}
          />
        </div>
      </form>
    </div>
  );
};

export { LoginPrompt };
