const events: Record<string, Function[]> = {};

export const subscribe = (event: string, callback: any) => {

  events[event] = events[event] || [];
  events[event].push(callback);
};

export const dispatch = (event: string, data: any) => {

  const callbacks = events[event] || [];
  for (const callback of callbacks) {
    callback(data);
  }
};

export const unsubscribe = (event: string, callback: any) => {

  events[event] = events[event] || [];
  events[event] = events[event].filter((cb) => cb !== callback);
};

