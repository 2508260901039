import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ActivityList from '../Activity/ActivityList';
import FullScreenDialog from '../Dialogs/FullScreenDialog';
import { ActivityLogCategory } from '../../types/enums/ActivityLogCategory';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    width: '100%'
  }
}));

const Chat = (props) => {

  const classes = useStyles();

  const {
    activityLog = {},
    isFullScreen,
    onCloseFullScreen
  } = props;

  const componentOutput = useMemo(() => {

    return (
      <div className={classes.root} data-cy="chat-list">
        <ActivityList
          activityItems={activityLog}
          filters={[ActivityLogCategory.ItemMessage]}
          styleOverrides={{ maxWidth: '100%' }}
        />
      </div>
    );
  }, [
    activityLog,
    classes
  ]);


  return useMemo(() => {

    if (isFullScreen) {
      return (
        <FullScreenDialog
          title="Chat"
          content={componentOutput}
          onClose={onCloseFullScreen}
          open={true}
        />
      );
    }

    return componentOutput;

  }, [componentOutput, isFullScreen, onCloseFullScreen]);
};

export default Chat;
