"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidWbsCode = void 0;
var invalidRegex = /\b(0{2,})\b/;
var isValidWbsCode = function isValidWbsCode(wbsCode) {
  return wbsCode.length >= 2 && !invalidRegex.test(wbsCode);
};
exports.isValidWbsCode = isValidWbsCode;