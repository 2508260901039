"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWbsFullValue = void 0;
var _getLeafRows = require("./getLeafRows");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var getWbsFullValue = function getWbsFullValue(numericStringValue, units, columnType, inheritOptions, unitsOnly) {
  // ** We should not do any formatting to the numericStringValue here. **
  // The numericStringValue should come into this function already numerically formatted the way you want it, e.g. ($1,043 | 104.45) etc.
  // this functions only purpose should be to take that value, combine it with the accumulated units that are found, and return it.

  if (!units || !units.hasOwnProperty(columnType)) {
    return numericStringValue;
  }
  if (!_lodash["default"].isEmpty(inheritOptions)) {
    var leafRows = (0, _getLeafRows.getLeafRows)(inheritOptions.codeLookup, inheritOptions.wbsCode);
    var allUnits;
    if (columnType === 'quantity') {
      allUnits = leafRows.map(function (row) {
        return row.units.quantity.trim();
      }).filter(function (u) {
        return u.length;
      });
    }
    var collectiveUnits = Array.from(new Set(allUnits)).join('/');
    var collectiveUnitsWithPadding = collectiveUnits.length > 0 ? " ".concat(collectiveUnits) : '';
    return unitsOnly ? collectiveUnitsWithPadding : "".concat(numericStringValue).concat(collectiveUnitsWithPadding);
  }

  // @ts-ignore
  var foundUnits = units[columnType];
  if (foundUnits) {
    // pad start if units don't already have it
    var unitsWithPadding = foundUnits[0] !== ' ' ? " ".concat(foundUnits) : foundUnits;
    return unitsOnly ? unitsWithPadding : "".concat(numericStringValue).concat(unitsWithPadding);
  }
  return unitsOnly ? '' : numericStringValue;
};
exports.getWbsFullValue = getWbsFullValue;