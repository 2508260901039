import request from 'superagent';
import _ from 'lodash';

import apiCommon from './common';

const deleteAllCookies = () => {

  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = (eqPos !== -1) ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

const BidStylePrefValues = {
  Included: 'Included',
  Undefined: 'Undefined',
  NotIncluded: 'Not Included',
  LumpSum: 'Lump Sum',
  Response: 'Response'
};

const QuickReportPrefValues = {
  Amount: 'Amount',
  AmountChanged: 'AmountChanged'
};

export default {

  UserTypes: {
    Admin: 'Admin',
    User: 'User'
  },

  ContactTypes: {
    Email: 'Email',
    TextMessage: 'Text Message'
  },

  Privileges: {
    SuperAdmin: 'SuperAdmin',
    Undefined: 'Undefined'
  },

  PreferenceOptions: {
    defaultEstimateStyle: 'defaultEstimateStyle',
    detailsView: 'detailsView',
    isHidden: 'isHidden',
    moduleWidths: 'moduleWidths',
    pinnedTrackerId: 'pinnedTrackerId',
    plugView: 'plugView',
    quickReportStyle: 'quickReportStyle'
  },

  BidStylePrefValues,

  BidStylePrefDisplayNames: {
    [BidStylePrefValues.Included]: 'Included',
    [BidStylePrefValues.Undefined]: 'Quantity/Cost',
    [BidStylePrefValues.NotIncluded]: 'Not Included',
    [BidStylePrefValues.LumpSum]: 'Lump Sum',
    [BidStylePrefValues.Response]: 'Response'
  },

  DefaultUserPrefs: {
    app: {},
    project: {},
    division: {},
    section: {}
  },

  QuickReportPrefValues,

  QuickReportPrefDisplayNames: {
    [QuickReportPrefValues.Amount]: 'Amount',
    [QuickReportPrefValues.AmountChanged]: 'Amount Changed'
  },

  Create: async function (
    fullName,
    userType,
    email,
    password,
    phone,
    contactPreference,
    userRole,
    companyId,
    createdFromInviteId = null
  ) {

    const payload = {
      fullName,
      userType,
      email,
      password,
      phone,
      contactPreference,
      userRole,
      companyId
    };

    if (createdFromInviteId) {
      payload.createdFromInviteId = createdFromInviteId;
    }

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/create')
      .send(payload);

    return await apiCommon.ProcessRequest(apiRequest);
  },

  // preferences is an array of objects with the format { projectId, divisionId, sectionId, option, value }
  AddOrUpdatePreferences: async function (preferences) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/add-or-update-preferences')
      .send({ preferences });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  AddTodo: async function (assignedToUserId, projectId, text, dueDate, urlPath) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/add-todo')
      .send({ assignedToUserId, projectId, text, dueDate, urlPath });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  CreateAuthToken: async function (name) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/create-auth-token')
      .send({ name });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Deactivate: async function (id) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/deactivate')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteAuthToken: async function (id) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/user/delete-auth-token')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteTodos: async function (todoIdList) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/user/delete-todos')
      .send({ todoIdList });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditLastActivitySeen: async function (category, activityId) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/edit-last-activity-seen')
      .send({
        category,
        activityId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditPassword: async function (oldPassword, newPassword) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/edit-password')
      .send({
        oldPassword,
        newPassword
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditProfile: async function (fullName, email, oldPassword, password, phone, contactPreference, userRole) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/edit')
      .send({
        fullName,
        email,
        oldPassword,
        password,
        phone,
        contactPreference,
        userRole
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  EditTodo: async function (id, isDone, text, dueDate) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/edit-todo')
      .send({ dueDate, id, isDone, text });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ForgotPassword: async function (email) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/forgot-password')
      .send({ email });

    return await apiCommon.ProcessRequest(apiRequest, { skipCsrf: true });
  },

  GetAuthTokens: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-auth-tokens');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetCompanies: async function (page, resultsPerPage, orderBy, orderDirection, searchString, filteredLabelNames, filteredCityNames, filterByServiceArea, filterByDataExperience, filterByProjectExperience) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-companies')
      .query({
        page,
        resultsPerPage,
        orderBy,
        orderDirection,
        searchString,
        filteredLabelNames,
        filteredCityNames,
        filterByServiceArea,
        filterByDataExperience,
        filterByProjectExperience
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetCompanyFilterOptions: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-company-filter-options');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetCompanyFilterOptionsByField: async function (fieldName) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-company-filter-options-by-field')
      .query({ fieldName });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetDashboardInfo: async (onlyItems) => {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-dashboard-info')
      .query({
        onlyItems
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetDeactivatedUsers: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-deactivated')
      .query({
        companyId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetDetails: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/details');

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetDetailsView: async function (projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-details-view')
      .query({
        projectId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetPreference: async function (option, projectId, divisionId, sectionId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-preference')
      .query({
        option,
        projectId,
        divisionId,
        sectionId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetProjects: async function (includeDivisionNames, omitProjectsWhereUserIsSub) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-projects')
      .query({
        includeDivisionNames,
        omitProjectsWhereUserIsSub
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetRecentItems: async function () {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-recent-items')
      .query();

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetTodos: async function (projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/get-todos')
      .query({ projectId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  IsExistingUserByEmail: async function (email) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/user/is-existing-user-by-email')
      .query({ email });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  LogIn: async function (email, password, emailVerifyToken, inviteToken) {

    const requestObject = {
      email,
      password
    };

    if (!_.isEmpty(emailVerifyToken)) {
      requestObject.emailVerifyToken = emailVerifyToken;
    }

    if (!_.isEmpty(inviteToken)) {
      requestObject.inviteToken = inviteToken;
    }

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/login')
      .send(requestObject);

    return await apiCommon.ProcessRequest(
      apiRequest,
      { skipCsrf: true, shouldNavigateToRootOnUnauthorized: false }
    );
  },

  LogOut: async function () {

    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/logout');

    return await apiCommon.ProcessRequest(apiRequest, { skipCsrf: true });
  },

  Reactivate: async function (id) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/reactivate')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  // preferences is an array of either ids or objects with the format { projectId, divisionId, sectionId, option }
  RemovePreferences: async function (preferences) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/user/remove-preferences')
      .send({ preferences });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  ResetPassword: async function (password, passwordResetToken) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/reset-password')
      .send({
        password,
        passwordResetToken
      });

    return await apiCommon.ProcessRequest(apiRequest, { skipCsrf: true });
  },

  SuperUserLoginAsUser: async function (email) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/user/super-user-login-as-user')
      .send({ email });

    return await apiCommon.ProcessRequest(apiRequest, { skipCsrf: true });
  },

  ToggleIsAPrimaryContact: async function (userId) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/user/toggle-primary-contact')
      .send({ userId });

    return await apiCommon.ProcessRequest(apiRequest);
  }

};
