import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

import { TodoMaxLength } from '../../consts/Todo';
import api from '../../api';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import EditTodoDialog from '../Dialogs/EditTodoDialog/EditTodoDialog';
import FullScreenDialog from '../Dialogs/FullScreenDialog';
import PlainInput from '../Input/PlainInput';
import TodoList from '../Todos/TodoList';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
    paddingTop: '8px'
  },
  projectSelect: {
    paddingLeft: '6px'
  },
  todoInput: {
    borderBottom: `2px solid ${theme.palette.primary.contrastText}`,

    '&.endItems': {
      alignSelf: 'end',
      display: 'flex',
      maxHeight: '1.5rem',
      paddingBottom: '4px'
    }
  },
  todoInputSendButton: {
    marginLeft: theme.spacing(1)
  },
  todoInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.15rem',
    marginTop: theme.spacing(1),
    padding: `0 ${theme.spacing(2)}px`
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '256px',
    overflowY: 'auto',
    width: '100%'
  },
  snackbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    zIndex: 10050
  }
}));

const Todos = (props) => {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    currentProjectId,
    isFullScreen,
    loggedInUserDetails,
    onCloseFullScreen,
    projectsInfo = []
  } = props;

  const originalInputs = useMemo(() => {

    return {
      projectId: currentProjectId || '',
      todo: ''
    };
  }, [currentProjectId]);

  const [inputs, setInputs] = useState(originalInputs);
  const [todoList, setTodoList] = useState([]);
  const [isDoneHidden, setIsDoneHidden] = useState(false);
  const [showDeleteTodosDialog, setShowDeleteTodosDialog] = useState(false);
  const [todoToEdit, setTodoToEdit] = useState(null);

  const getTodos = useCallback(async () => {

    const response = await api.User.GetTodos(currentProjectId);
    if (response.err) {
      console.error(response.err);
      enqueueSnackbar(
        'Error getting todos',
        { className: classes.snackbar }
      );
    }
    else {
      setTodoList(response.res.body);
    }
  }, [classes.snackbar, currentProjectId, enqueueSnackbar]);

  useEffect(() => {

    getTodos();
  }, [getTodos]);

  const stringOfCompletedTodos = useMemo(() => {

    return '\n  "' + todoList
      .filter((todo) => todo.isDone)
      .map((todo) => todo.text)
      .join('"\n  "') + '"';
  }, [todoList]);

  const numTodosDone = useMemo(() => {

    return todoList.filter((todo) => todo.isDone).length;
  }, [todoList]);

  const handleProjectSelectChange = useCallback((evt) => {

    evt.preventDefault();
    const value = evt.target.value;

    setInputs((prevInputs) => ({ ...prevInputs, projectId: value }));
  }, []);

  const handleTodoInputChange = useCallback((evt) => {

    evt.preventDefault();
    const value = evt.currentTarget.value;

    setInputs((prevInputs) => ({ ...prevInputs, todo: value }));
  }, []);

  const handleSubmitTodo = useCallback(async () => {

    if (!inputs.projectId || !inputs.todo) {
      return;
    }

    const urlPath = `/projects/${inputs.projectId}`;
    await api.User.AddTodo(loggedInUserDetails.id, inputs.projectId, inputs.todo, undefined, urlPath);
    setInputs({ ...inputs, todo: '' });
    await getTodos();
  }, [getTodos, inputs, loggedInUserDetails]);

  const handleDeleteTodos = useCallback(async () => {

    const todoIdsToDelete = todoList.filter((todo) => todo.isDone).map((todo) => todo.id);
    if (todoIdsToDelete.length) {
      await api.User.DeleteTodos(todoIdsToDelete);
      await getTodos();
    }

    setShowDeleteTodosDialog(false);
  }, [getTodos, todoList]);

  const filteredTodos = useMemo(() => {

    const selectedProjectId = parseInt(inputs.projectId);
    const filteredByProject = selectedProjectId ? todoList.filter((todo) => todo.projectId === selectedProjectId) : todoList;
    return isDoneHidden ? filteredByProject.filter((todo) => !todo.isDone) : filteredByProject;
  }, [inputs, isDoneHidden, todoList]);

  const handleCloseEditTodo = useCallback(() => {

    setTodoToEdit(null);
    getTodos();
  }, [getTodos]);


  const handleHideDoneClick = useCallback(() => {

    setIsDoneHidden(true);
  }, []);

  const handleShowAllClick = useCallback(() => {

    setIsDoneHidden(false);
  }, []);

  const projectSelectOptions = useMemo(() => {

    return projectsInfo.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>);
  }, [projectsInfo]);

  const todoInput = useMemo(() => {

    return (
      <div key="todosInput" className={classes.todoInputWrapper}>
        <PlainInput
          inputProps={{
            className: classes.todoInput,
            fullWidth: true,
            multiline: true,
            value: inputs.todo,
            disabled: !inputs.projectId,
            onChange: handleTodoInputChange,
            onKeyPress: (evt) => {

              if (evt.key === 'Enter' && inputs.todo) {
                evt.preventDefault();
                handleSubmitTodo();
              }
            },
            inputProps: {
              'data-cy': 'todos.input',
              maxLength: TodoMaxLength,
              placeholder: (inputs.projectId) ? 'Add a to-do' : ''
            }
          }}
        />
        <span className={classes.todoInput + ' endItems'}>

          {currentProjectId ? null :
            <Select
              displayEmpty
              value={inputs.projectId}
              onChange={handleProjectSelectChange}
              classes={{ select: classes.projectSelect }}
              data-cy="todos.project-select"
            >
              <MenuItem value="">All Projects</MenuItem>
              {projectSelectOptions}
            </Select>
          }
          <IconButton
            className={classes.todoInputSendButton}
            size="small"
            onClick={handleSubmitTodo}
            data-cy="todos.send-button"
            disabled={!inputs.todo.length || !inputs.projectId}
          >
            <SendIcon />
          </IconButton>
        </span>
      </div>
    );
  }, [
    classes,
    currentProjectId,
    handleProjectSelectChange,
    handleSubmitTodo,
    handleTodoInputChange,
    inputs,
    projectSelectOptions
  ]);

  const actionButtons = useMemo(() => {

    return (
      <div className={classes.actionButtons}>
        <ButtonGroup>
          <Button color={isDoneHidden ? 'secondary' : 'default'} onClick={handleShowAllClick}>
            Show All
          </Button>
          <Button color={isDoneHidden ? 'default' : 'secondary'} onClick={handleHideDoneClick}>
            Hide Done
          </Button>
        </ButtonGroup>
        <Tooltip title={isDoneHidden ? 'Show all to enable delete' : 'Delete all todos marked done'} arrow position="top">
          <div>
            <Button
              data-cy="todos.delete-done-button"
              onClick={() => setShowDeleteTodosDialog(true)}
              disabled={isDoneHidden || !numTodosDone}
            >
              Delete Done
            </Button>
          </div>
        </Tooltip>
      </div>
    );
  }, [
    classes.actionButtons,
    handleHideDoneClick,
    handleShowAllClick,
    isDoneHidden,
    numTodosDone,
    setShowDeleteTodosDialog
  ]);

  const componentOutput = useMemo(() => {

    return (
      <div className={classes.root} data-cy="todos-list">
        {todoInput}
        <TodoList
          loggedInUserDetails={loggedInUserDetails}
          todoList={filteredTodos}
          styleOverrides={{ maxWidth: '100%' }}
          refreshTodos={getTodos}
          setTodoToEdit={setTodoToEdit}
          isShowAll={!inputs.projectId}
        />
        {actionButtons}
        <ConfirmDialog
          open={showDeleteTodosDialog}
          title="Delete completed todos"
          description={<span style={{ whiteSpace: 'pre-wrap' }}>{`Permanently delete todo${numTodosDone > 1 ? 's' : ''}? ${stringOfCompletedTodos}`}</span>}
          handleConfirm={handleDeleteTodos}
          confirmLabel="Delete"
          handleCancel={() => setShowDeleteTodosDialog(false)}
          cancelLabel="Cancel"
        />
        {!todoToEdit ? null :
          <EditTodoDialog
            todoData={todoToEdit}
            open={Boolean(todoToEdit)}
            onClose={handleCloseEditTodo}
          />
        }
      </div>
    );
  }, [
    actionButtons,
    classes,
    filteredTodos,
    getTodos,
    handleCloseEditTodo,
    handleDeleteTodos,
    inputs.projectId,
    loggedInUserDetails,
    numTodosDone,
    setTodoToEdit,
    showDeleteTodosDialog,
    stringOfCompletedTodos,
    todoInput,
    todoToEdit
  ]);

  return useMemo(() => {

    if (isFullScreen) {
      return (
        <FullScreenDialog
          title="To-Do"
          content={componentOutput}
          onClose={onCloseFullScreen}
          open={true}
        />
      );
    }

    return componentOutput;

  }, [componentOutput, isFullScreen, onCloseFullScreen]);
};

export default Todos;
