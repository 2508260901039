export const CompanyNameMaxLength = 100;
export const CompanyAddressMaxLength = 100;
export const CompanyCityMaxLength = 100;
export const CompanyStateMaxLength = 100;
export const CompanyPostalCodeMaxLength = 15;
export const CompanyCountryMaxLength = 60;
export const CompanyPhoneMaxLength = 35;
export const CompanyFaxMaxLength = 35;
export const CompanyEmailMaxLength = 255;
export const defaultShorthandNotations = {
  money: [
    { symbol: 'K', base: 10, exponent: 3 },
    { symbol: 'MM', base: 10, exponent: 6 },
    { symbol: 'B', base: 10, exponent: 9 },
    { symbol: 'T', base: 10, exponent: 12 }
  ],
  units: [
    { symbol: 'K', base: 10, exponent: 3 },
    { symbol: 'MM', base: 10, exponent: 6 },
    { symbol: 'B', base: 10, exponent: 9 },
    { symbol: 'T', base: 10, exponent: 12 }
  ]
};
export const defaultOrderedShorthandNotations = {
  money: [
    { symbol: 'T', factor: 1000000000000 },
    { symbol: 'B', factor: 1000000000 },
    { symbol: 'MM', factor: 1000000 },
    { symbol: 'K', factor: 1000 }
  ],
  units: [
    { symbol: 'T', factor: 1000000000000 },
    { symbol: 'B', factor: 1000000000 },
    { symbol: 'MM', factor: 1000000 },
    { symbol: 'K', factor: 1000 }
  ]
};
