import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import * as ProjectConsts from '../../consts/ProjectToolbar';
import ActivityList from '../Activity/ActivityList';
import api from '../../api';
import AttachmentsDialog from '../Dialogs/AttachmentsDialog';
import FullScreenDialog from '../Dialogs/FullScreenDialog';
import PlainInput from '../Input/PlainInput';
import { ProjectApiSlice } from '../../api/project';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import SendIcon from '@material-ui/icons/Send';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import { ActivityLogCategory } from '../../types/enums/ActivityLogCategory';
import { ProjectRole } from '../../types/enums/ProjectRole';
import { UserType } from '../../types/enums/UserType';


const useStyles = makeStyles((theme) => ({
  activitySection: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  activitySectionHeader: {
    borderBottom: `2px solid ${theme.palette.secondary.light}`,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1rem',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(0.5)}px`,
    userSelect: 'none',

    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  controlsLoadingIndicator: {
    alignSelf: 'center',
    marginTop: theme.spacing(4)
  },
  mainButton: {
    marginTop: theme.spacing(1),
    width: 'fit-content'
  },
  optionHeader: {
    backgroundColor: theme.palette.primary.light,
    borderBottom: `1px solid ${theme.palette.primary.dark}`
  },
  projectSelect: {
    width: '100%'
  },
  projectSelectWrapper: {
    margin: `${theme.spacing(1)}px 0`
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    width: '100%'
  },
  sectionSelectIcon: {
    marginRight: theme.spacing(0.5),

    '&.level2': {
      marginLeft: theme.spacing(3)
    }
  },
  sectionSelectWrapper: {
    alignItems: 'center',
    display: 'flex',
    margin: `0 0 ${theme.spacing(1)}px 0`
  },
  selectedDivisionName: {
    alignItems: 'center',
    color: theme.palette.secondary.light,
    display: 'flex',
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5)
  },
  selectSubOption: {
    marginLeft: theme.spacing(2)
  },
  teamBoardInput: {
    borderBottom: `2px solid ${theme.palette.primary.contrastText}`
  },
  teamBoardInputSendButton: {
    marginLeft: theme.spacing(1)
  },
  teamBoardInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.15rem',
    marginTop: theme.spacing(1),
    padding: `0 ${theme.spacing(2)}px`
  }
}));

const ProjectOptions = (props) => {

  const classes = useStyles();

  const {
    activityLog = [],
    isFullScreen,
    onCloseFullScreen,
    onProjectUpdated,
    projects
  } = props;

  const originalInputs = useMemo(() => {

    return {
      teamBoard: ''
    };
  }, []);

  const [inputs, setInputs] = useState(originalInputs);
  const [isTeamBoardExpanded, setIsTeamBoardExpanded] = useState(true);
  const [isExternalMessagingExpanded, setIsExternalMessagingExpanded] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [showAttachmentsDialog, setShowAttachmentsDialog] = useState(false);

  const { data: selectedSectionInfo, refetch: getSectionInfo } =
    ProjectApiSlice.useSectionInfoQuery({ sectionId: selectedSectionId }, { skip: !selectedSectionId });

  useEffect(() => {

    if (selectedProjectId) {
      const selectedProject = projects.find((p) => p.id === selectedProjectId);
      if (!selectedProject) {
        setSelectedProjectId(null);
        setSelectedSectionId(null);
        return;
      }

      if (selectedSectionId) {
        for (const division of selectedProject.divisions) {
          for (const section of division.sections) {
            if (section.id === selectedSectionId) {
              return;
            }
          }
        }

        setSelectedSectionId(null);
      }
    }
  }, [projects, selectedProjectId, selectedSectionId]);

  const selectedProjectInfo = useMemo(() => {

    if (!selectedProjectId) {
      return null;
    }

    return projects.find((p) => p.id === selectedProjectId);
  }, [projects, selectedProjectId]);

  const projectPermissions = useMemo(() => {

    if (!selectedProjectInfo) {
      return new Set();
    }

    const project = selectedProjectInfo;
    return project.projectPermissions;
  }, [selectedProjectInfo]);

  const sectionPermissions = useMemo(() => {

    if (!selectedSectionId || !selectedSectionInfo) {
      return {};
    }

    const project = _.cloneDeep(selectedSectionInfo.hierarchy);
    return project.projectPermissions;
  }, [selectedSectionId, selectedSectionInfo]);

  const handleCloseAttachmentsDialog = useCallback(() => setShowAttachmentsDialog(false), []);

  const handleOpenAttachmentsDialog = useCallback(() => {

    if (!(selectedProjectInfo || selectedSectionInfo)) {
      return;
    }

    setShowAttachmentsDialog(true);
  }, [selectedProjectInfo, selectedSectionInfo]);

  const handleProjectSelectChange = useCallback((evt) => {

    evt.preventDefault();
    const selectedId = parseInt(evt.target.value);

    setSelectedProjectId((isNaN(selectedId)) ? null : selectedId);
    setSelectedSectionId(null);
  }, []);

  const handleSectionSelectChange = useCallback((evt) => {

    evt.preventDefault();
    const selectedId = parseInt(evt.target.value);

    setSelectedSectionId((isNaN(selectedId)) ? null : selectedId);
  }, []);

  const handleClearSelectedSection = useCallback(() => setSelectedSectionId(null), []);

  const handleToggleExternalMessagingExpanded = useCallback(() => setIsExternalMessagingExpanded((prev) => !prev), []);

  const handleToggleTeamBoardExpanded = useCallback(() => setIsTeamBoardExpanded((prev) => !prev), []);

  const handleTeamBoardInputChange = useCallback((evt) => {

    evt.preventDefault();
    const value = evt.currentTarget.value;

    setInputs((prevInputs) => ({ ...prevInputs, teamBoard: value }));
  }, []);

  const handleSubmitTeamBoardMessage = useCallback(() => {

    if (!selectedProjectId || !inputs.teamBoard) {
      return;
    }

    api.Project.AddQuickNote(selectedProjectId, inputs.teamBoard);
    setInputs({ ...inputs, teamBoard: originalInputs.teamBoard });
  }, [inputs, originalInputs, selectedProjectId]);

  const filteredLogItems = useMemo(() => {

    if (selectedSectionId) {
      return activityLog.filter((l) => l.sectionId === selectedSectionId);
    }

    if (selectedProjectId) {
      return activityLog.filter((l) => l.projectId === selectedProjectId);
    }

    return activityLog;
  }, [activityLog, selectedProjectId, selectedSectionId]);

  const projectSelectOptions = useMemo(() => {

    return projects.map(({ id, name }) => <MenuItem key={id} value={id} data-cy="project-option">{name}</MenuItem>);
  }, [projects]);

  const projectSelect = useMemo(() => {

    return (
      <div className={classes.projectSelectWrapper}>
        <Select
          className={classes.projectSelect}
          displayEmpty
          value={selectedProjectId || ''}
          onChange={handleProjectSelectChange}
          data-cy="project-options.project-select"
        >
          <MenuItem value="" disabled>Select Project</MenuItem>
          {projectSelectOptions}
        </Select>
      </div>
    );
  }, [classes, handleProjectSelectChange, projectSelectOptions, selectedProjectId]);

  const sectionSelectOptions = useMemo(() => {

    if (!selectedProjectInfo) {
      return null;
    }

    const options = [];

    for (const division of selectedProjectInfo.divisions) {
      if (!division.sections.length) {
        continue;
      }

      options.push(
        <ListSubheader key={`d${division.id}`} className={classes.optionHeader}>
          {`${selectedProjectInfo.cust.division} ${division.label || division.number}: ${division.name}`}
        </ListSubheader>
      );

      for (let i = 0; i < division.sections.length; i++) {
        const section = division.sections[i];
        const sectionTitle = section.title || 'Untitled';
        const sectionArea = (section.area) ? ` - ${section.area}` : '';

        options.push(
          <MenuItem key={section.id} value={section.id} data-cy="section-option">
            <span className={classes.selectSubOption}>
              {`${_.padStart(i + 1, 2, '0')}: ${sectionTitle + sectionArea}`}
            </span>
          </MenuItem>
        );
      }
    }

    return options;
  }, [classes, selectedProjectInfo]);

  const sectionSelect = useMemo(() => {

    if (!selectedProjectInfo) {
      return null;
    }

    let clearButton = null;
    if (selectedSectionId) {
      clearButton = (
        <IconButton
          className={classes.sectionTitleFullScreenButton}
          onClick={handleClearSelectedSection}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      );
    }

    let divisionName = null;
    let sectionSubdirectoryIcon = null;
    if (selectedSectionInfo) {

      const division = selectedSectionInfo.hierarchy.divisions[0];
      divisionName = (
        <span className={classes.selectedDivisionName}>
          <SubdirectoryArrowRightIcon className={classes.sectionSelectIcon} color="secondary" />
          {`${selectedProjectInfo.cust.division} ${division.label || division.number}:  ${division.name}`}
        </span>
      );

      sectionSubdirectoryIcon =
        <SubdirectoryArrowRightIcon className={classes.sectionSelectIcon + ' level2'} color="secondary" />;
    }
    else {
      sectionSubdirectoryIcon = <SubdirectoryArrowRightIcon className={classes.sectionSelectIcon} color="secondary" />;
    }

    return (
      <Fragment>
        {divisionName}
        <div className={classes.sectionSelectWrapper}>
          {sectionSubdirectoryIcon}
          <Select
            className={classes.projectSelect}
            displayEmpty
            value={selectedSectionId || ''}
            onChange={handleSectionSelectChange}
            data-cy="project-options.section-select"
          >
            <MenuItem value="" dense>No {selectedProjectInfo.cust.lcSection} selected</MenuItem>
            {sectionSelectOptions}
          </Select>
          {clearButton}
        </div>
      </Fragment>
    );
  }, [
    classes,
    handleClearSelectedSection,
    handleSectionSelectChange,
    sectionSelectOptions,
    selectedProjectInfo,
    selectedSectionId,
    selectedSectionInfo
  ]);

  const filesButton = useMemo(() => {

    if (!(selectedProjectInfo || selectedSectionInfo)) {
      return null;
    }

    const typeLabel = (selectedSectionId && selectedSectionInfo) ? selectedProjectInfo.cust.area : 'Project';

    return (
      <Button
        className={classes.mainButton}
        variant="contained"
        color="default"
        startIcon={<FolderIcon />}
        onClick={handleOpenAttachmentsDialog}
      >
        {typeLabel} Files
      </Button>
    );
  }, [classes, handleOpenAttachmentsDialog, selectedProjectInfo, selectedSectionId, selectedSectionInfo]);

  const teamBoardUI = useMemo(() => {

    if (!selectedProjectId) {
      return null;
    }

    const expandIcon = (isTeamBoardExpanded) ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    return (
      <div className={classes.activitySection}>
        <div
          className={classes.activitySectionHeader}
          onClick={handleToggleTeamBoardExpanded}
        >
          Team Board
          {expandIcon}
        </div>
        <Collapse in={isTeamBoardExpanded}>
          <div className={classes.teamBoardInputWrapper}>
            <PlainInput
              inputProps={{
                className: classes.teamBoardInput,
                fullWidth: true,
                multiline: true,
                value: inputs.teamBoard,
                onChange: handleTeamBoardInputChange,
                inputProps: {
                  'data-cy': 'project-options.team-board-input',
                  maxLength: ProjectConsts.QuickNoteMaxLength,
                  placeholder: 'Add a message'
                }
              }}
            />
            <span className={classes.teamBoardInput}>
              <IconButton
                className={classes.teamBoardInputSendButton}
                size="small"
                onClick={handleSubmitTeamBoardMessage}
                data-cy="project-options.send-button"
                disabled={!inputs.teamBoard.length}
              >
                <SendIcon />
              </IconButton>
            </span>
          </div>
          <ActivityList
            activityItems={filteredLogItems}
            filters={[ActivityLogCategory.QuickNote]}
            styleOverrides={{ maxWidth: '100%' }}
          />
        </Collapse>
      </div>
    );
  }, [
    classes,
    filteredLogItems,
    handleSubmitTeamBoardMessage,
    handleTeamBoardInputChange,
    handleToggleTeamBoardExpanded,
    inputs,
    isTeamBoardExpanded,
    selectedProjectId
  ]);

  const externalMessagingUI = useMemo(() => {

    if (!selectedProjectId) {
      return null;
    }

    const expandIcon = (isExternalMessagingExpanded) ? <ExpandLessIcon /> : <ExpandMoreIcon />;

    return (
      <div className={classes.activitySection}>
        <div
          className={classes.activitySectionHeader}
          onClick={handleToggleExternalMessagingExpanded}
        >
          External Messaging
          {expandIcon}
        </div>
        <Collapse in={isExternalMessagingExpanded}>
          <ActivityList
            activityItems={filteredLogItems}
            filters={[ActivityLogCategory.ItemMessage]}
            styleOverrides={{ maxWidth: '100%' }}
          />
        </Collapse>
      </div>
    );
  }, [
    classes,
    filteredLogItems,
    handleToggleExternalMessagingExpanded,
    isExternalMessagingExpanded,
    selectedProjectId
  ]);

  const attachmentsDialogProps = useMemo(() => {

    if (selectedSectionInfo && selectedSectionId) {
      const selectedSection = selectedSectionInfo?.hierarchy?.divisions?.[0]?.sections?.[0];
      const attachments = {
        project: selectedProjectInfo?.attachments || [],
        section: selectedSection?.attachments || []
      };

      return {
        onDocumentUploaded: getSectionInfo,
        onBidAttachmentUploaded: getSectionInfo,
        onAttachmentDeleted: getSectionInfo,
        permissions: sectionPermissions,
        allowAllFileTypes: true,
        attachments,
        parentType: 'section',
        project: selectedSectionInfo?.hierarchy,
        section: selectedSection
      };
    }

    if (selectedProjectInfo) {

      return {
        onDocumentUploaded: onProjectUpdated,
        onBidAttachmentUploaded: onProjectUpdated,
        onBidAttachmentsUpdated: onProjectUpdated,
        onAttachmentDeleted: onProjectUpdated,
        permissions: projectPermissions,
        allowAllFileTypes: true,
        attachments: { project: selectedProjectInfo?.attachments || [] },
        cust: selectedProjectInfo.cust,
        parentType: 'project',
        project: selectedProjectInfo
      };
    }

    return {};
  }, [
    getSectionInfo,
    onProjectUpdated,
    projectPermissions,
    sectionPermissions,
    selectedProjectInfo,
    selectedSectionId,
    selectedSectionInfo
  ]);

  const controlsOrLoadingIndicator = useMemo(() => {

    if (selectedSectionId && selectedSectionId !== selectedSectionInfo?.hierarchy?.divisions?.[0]?.sections?.[0]?.id) {
      return <CircularProgress className={classes.controlsLoadingIndicator} color="secondary" />;
    }

    return (
      <Fragment>
        {filesButton}
        {teamBoardUI}
        {externalMessagingUI}
      </Fragment>
    );
  }, [
    classes,
    externalMessagingUI,
    filesButton,
    selectedSectionId,
    selectedSectionInfo,
    teamBoardUI
  ]);

  const componentOutput = useMemo(() => {

    return (
      <div className={classes.root} data-cy="project-options-list">
        {projectSelect}
        {sectionSelect}
        {controlsOrLoadingIndicator}

        <AttachmentsDialog
          id={'attachments'}
          open={showAttachmentsDialog}
          handleCancel={handleCloseAttachmentsDialog}
          {...attachmentsDialogProps}
        />
      </div>
    );
  }, [
    attachmentsDialogProps,
    classes,
    controlsOrLoadingIndicator,
    handleCloseAttachmentsDialog,
    projectSelect,
    sectionSelect,
    showAttachmentsDialog
  ]);

  return useMemo(() => {

    if (isFullScreen) {
      return (
        <FullScreenDialog
          title="Project Options"
          content={componentOutput}
          onClose={onCloseFullScreen}
          open={true}
        />
      );
    }

    return componentOutput;

  }, [componentOutput, isFullScreen, onCloseFullScreen]);
};

export default ProjectOptions;
