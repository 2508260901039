export const numericOnlyString = (value: string, allowNegative?: boolean) => {

  const includingDashRegex = /[^0-9.-]/g;
  const numericOnlyRegex = /[^0-9.]/g;
  const isNegative = (value.replace(includingDashRegex, '')[0] === '-');

  if (isNegative && allowNegative) {
    return `-${value.replace(numericOnlyRegex, '')}`;
  }

  return value.replace(numericOnlyRegex, '');
};
