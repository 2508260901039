import * as React from 'react';
import { createTheme, Theme } from '@material-ui/core/styles';
import { pink, purple, indigo, cyan, green, lime, amber } from '@material-ui/core/colors';
import SidebarLogo from './assets/images/logo_relay';

const avatarColors = [
  { primary: pink[900], secondary: pink[50] },
  { primary: purple[900], secondary: purple[50] },
  { primary: indigo[900], secondary: indigo[50] },
  { primary: cyan[900], secondary: cyan[50] },
  { primary: green[900], secondary: green[50] },
  { primary: lime[900], secondary: lime[50] },
  { primary: amber[900], secondary: amber[50] }
];

interface Images {
  sidebarLogo: React.ComponentType<any> | null
}

export const themeDefinition = (images: Images) => ({

  mediaQueries: {
    leftNav: '@media (max-height: 900px)'
  },
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif'
    ].join(','),
    headerFontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(','),
    fontWeightRegular: 400,
    fontWeightLight: 500,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightDark: 700,
    fontWeightHeavy: 800,
    fontWeightBlack: 900,
    sectionHeaderFontSize: '0.8rem'
  },
  images: {
    ...images
  },
  css: {
    sidebar: {
      closed: {
        footer: {
          svg: {
            /**/
          }
        }
      },
      footer: {
        svg: {
          /**/
        }
      }
    }
  },
  palette: {
    app: {
      activityIcon: '#0a84ff',
      background: '#e5e9f0',
      defaultText: '#3b3b3b',
      disabledElement: '#e9e9e9',
      errorText: '#d00000',
      greyedOutText: '#999999',
      headerImageBackground: '#e5e9f0',
      highlightCell: '#ffff00',
      innerBorder: '#ec593c',
      innerBorderEdge: '#ec593c',
      lightButton: '#A7C5D8',
      notice: '#daa520',
      outerBorder: '#e4e4e4',
      placeholderText: '#bbbbbb',
      selected: '#34a853',
      selectedDivision: '#3da9fc',
      selectedDivisionText: '#fefeff',
      shadow: 'rgba(64, 64, 64, 0.25)',
      shadowDark: 'rgba(64, 64, 64, 0.75)',
      shadowLight: 'rgba(64, 64, 64, 0.2)'
    },
    sidebar: {
      activeIcon: '#ef4423',
      background: '#2a2c2f',
      defaultText: '#ffffff',
      logoHighlight: '#ef4423',
      titleText: '#252526'
    },
    toolbar: {
      grandTotalBorder: '#000000',
      grandTotalBackground: '#000000',
      grandTotalNumber: '#ffffff',
      grandTotalNumberBorder: 'transparent',
      icon: '#242732',
      iconActive: '#f8b6a8',
      labelText: '#000000',
      searchBackground: '#f5f9fc',
      searchStroke: '#C7C7C7'
    },
    labelDisplay: {
      hoverDeselect: '#81c784',
      hoverSelect: '#666666',
      outlineColor: '#000',
      selected: '#34a853'
    },
    section: {
      actionButtonsBackground: '#e9eef2',
      actionButtonsBackgroundDark: '#263341',
      actionButtonsBackgroundMedium: '#bac1c8',
      folder: '#fefeff',
      column: '#d8dde9',
      columnLight: '#f4f6f9',
      innerBorder: '#cccccc'
    },
    table: {
      oddRow: '#f3f6f9',
      evenRow: '#ffffff',
      rowBorder: '#c0c3c6',
      rowBorderLight: '#dddee2',
      rowBorderDark: '',
      headerRowBorder: '#000000',
      light: '#ffffff',
      medium: '#f5f5f5',
      dark: '#f1f1f1',
      headerLabel: '#666666',
      extraColumn: '#ddd'
    },
    userManagement: {
      approver: '#E8C70E',
      contributor: '#8C9966',
      estimator: '#517FC9',
      manager: '#FF3C00',
      viewer: '#2EB82E',
      approverHover: '#E8C70E80',
      contributorHover: '#8C996680',
      estimatorHover: '#517FC980',
      viewerHover: '#2EB82E80'
    },
    bidDocumentation: {
      toolbar: 'rgb(215,230,234)',
      background: 'rgb(127,127,127)'
    },
    primary: {
      light: '#f5f9fc',
      main: '#e1e9f0',
      dark: '#cdd7e0',
      contrastText: '#3b3b3b',
      containersBgColor: '#FFFFFF',
      containersInnerBorderColor: '#FAFAFA',
      textColorDark: '#000000',
      textColorMedium: '#808080'
    },
    secondary: {
      light: '#9fb2c2',
      main: '#8298ab',
      dark: '#677a8a',
      contrastText: '#f5f9fc'
    },
    tertiary: {
      light: '#f4f6f9',
      main: '#f5f9fc',
      dark: '#e1e9f0'
    },
    bidMessages: {
      fromUser: '#e8eaf6',
      fromOther: '#e0f2f1'
    },
    bidSubActivity: {
      accepted: {
        accent: '#C9D2C0',
        text: 'auto',
        button: 'auto'
      },
      editing: {
        accent: '#30B050',
        text: 'auto',
        button: 'auto'
      }
    },
    bidStatus: {
      awarded: {
        accent: '#fac704',
        hover: '#ffd638',
        text: 'auto',
        button: 'auto'
      },
      starred: {
        accent: '#34a853',
        hover: '#81c784',
        text: 'auto',
        button: 'auto'
      },
      undefined: {
        accent: '#5fc7e0',
        text: 'auto',
        button: 'auto'
      },
      limitedAccess: {
        accent: '#f4f6f9',
        text: 'auto',
        button: 'auto'
      },
      pendingInvite: {
        accent: '#a67fec',
        text: 'rgba(144,144,144,0.5)',
        button: '#a67fec'
      },
      queuedInvite: {
        accent: '#0067ff',
        text: 'auto',
        button: '#0067ff'
      }
    },
    division: {
      finalCostsBackground: '#f5f7fa'
    },
    phase: {
      active: '#1c91be'
    },
    response: {
      plug: '#a67fec',
      override: '#a67fec',
      overrideDarker: '#865fcc',
      notIncludedBackground: '#fccaca',
      projectVariable: '#1c91be',
      warning: '#d00000',
      warningHover: '#e88989'
    },
    keyLegend: {
      newData: '#12b31f',
      matchedData: '#f1f507',
      wbsUneditable: '#b6b9ba',
      wbsError: '#fc2008',
      wbsRelated: '#2e98ec'
    },
    summarySheet: {
      awarded: '#34a853'
    },
    flowChart: {
      nodeBackground: '#e5e9f0',
      nodeBackgroundInvalid: '#ffb3b3',
      nodeBorderInvalid: '#ff5050',
      nodeBackgroundSelected: '#cfeaff',
      nodeBorderSelected: '#2fa3f6',
      levelTypeNodeBackground: '#e2eef4'
    }
  },
  transition: {
    duration: {
      medium: '0.4s'
    }
  },

  getAvatarColor: (id: number) => {

    return avatarColors[id % (avatarColors.length - 1)];
  }
});

type ThemeExtras = {
  shadows: string[];
  spacing: (value: number) => string;
  transitions: any;
}

export type AppTheme = ReturnType<typeof themeDefinition> & ThemeExtras & Theme;

const theme = createTheme(themeDefinition( { sidebarLogo: SidebarLogo }));

export default theme;
