import React from 'react';
import { styles } from '../styles';

import Typography from '@material-ui/core/Typography';

export interface UserMessageUIProps {
  className: string;
  userMessage: string;
}

const UserMessageUI = ({ className, userMessage }: UserMessageUIProps) => {

  const classes = Object.create(styles());

  return (
    <Typography
      className={classes[className]}
      variant="h6"
      gutterBottom
      data-cy="login.message"
    >
      {userMessage}
    </Typography>
  );
};

export { UserMessageUI };
