import React, { Fragment } from 'react';
import { Link as BrowserLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import SmallAvatar from '../Icons/SmallAvatar';

import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '8px',
    fontWeight: theme.typography.fontWeightBold
  },
  inline: {
    display: 'inline'
  }
}));

const ActivityLogItemMessage = (props) => {

  const classes = useStyles();

  const {
    activityProjectId,
    bidId,
    bidCompanyId,
    bidCompanyName,
    content,
    divisionLabel,
    divisionName,
    divisionNumber,
    fromUserFullName,
    fromUserCompanyName,
    fromUserCompanyId,
    projectCompanyId,
    projectCompanyName,
    projectName,
    sectionArea,
    sectionId,
    sectionItemTitle,
    sectionTitle
  } = props;

  let senderName = '';
  if (fromUserFullName) {
    senderName = fromUserFullName;
  }
  else if (fromUserCompanyName) {
    senderName = fromUserCompanyName;
  }

  if (!fromUserCompanyId) {
    return null;
  }

  let link = `/projects/${activityProjectId}/section/${sectionId}`;
  if (bidId) {
    link += `/bid/${bidId}`;
  }

  let toCompanyId = bidCompanyId;
  let toCompanyName = bidCompanyName;
  if (bidCompanyId === fromUserCompanyId) {
    toCompanyId = projectCompanyId;
    toCompanyName = projectCompanyName;
  }

  return (
    <Fragment>
      <ListItem alignItems="flex-start" data-cy="activity-log.item" dense>
        <SmallAvatar
          name={(senderName.length) ? senderName[0] : ''}
          id={fromUserCompanyId || 0}
          style={{ marginTop: '8px' }}
        />
        <ListItemText
          primary={
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {senderName} (
              <Link
                component={BrowserLink}
                to={'/companies/' + fromUserCompanyId}
                variant="body2"
                color="textPrimary"
                style={{ textDecoration: 'underline' }}
              >
                {fromUserCompanyName}
              </Link>
              )
            </Typography>
          }
          secondary={
            <Fragment>
              <Link
                component={BrowserLink}
                to={link}
                variant="body2"
                color="textPrimary"
                style={{ textDecoration: 'underline' }}
              >
                {
                  `${projectName}: ${divisionLabel || divisionNumber} - ${divisionName || 'Untitled'} - ` +
                  `${sectionArea || 'Untitled'} - ${sectionTitle || 'Untitled'} - ${sectionItemTitle || 'Untitled'}`
                }
              </Link>
              <Typography
                component="span"
                variant="body1"
                className={classes.content}
                color="textPrimary"
              >
                <br/>
                {content}
                <br/>
              </Typography>
              <Link
                component={BrowserLink}
                to={'/companies/' + toCompanyId}
                variant="body2"
                color="textPrimary"
                style={{ textDecoration: 'underline' }}
              >
                To: {toCompanyName}
              </Link>
            </Fragment>
          }
        />
      </ListItem>
      <Divider varant="inset" component="li" />
    </Fragment>
  );
};

export default ActivityLogItemMessage;
