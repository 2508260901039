import React, { Fragment, memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropzoneDialog } from 'material-ui-dropzone';
import _ from 'lodash';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import api from '../../api';
import { AddButton } from '../Shared/Buttons/AddButton';
import AssignFilesToBidsDialog from './AssignFilesToBidsDialog/AssignFilesToBidsDialog';
import AttachmentSplitViewDialog from './AttachmentSplitViewDialog';
import ConfirmDialog from './ConfirmDialog';
import FileElement from '../Project/FileElement';
import FileHistory from '../Menus/FileHistory';
import LinearProgressWithLabel from '../Generic/LinearProgressWithLabel';
import { ProjectApiSlice } from '../../api/project';
import SwagPDFDialog from './SwagPDFDialog';

import Backdrop from '@material-ui/core/Backdrop';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import CancelIcon from '@material-ui/icons/Cancel';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import HomeIcon from '@material-ui/icons/Home';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { BidStatus } from '../../types/enums/BidStatus';


const useStyles = makeStyles((theme) => ({
  addMarkupButton: {
    marginTop: '8px',
    minWidth: '135px',
    color: theme.palette.primary.contrastText
  },
  assignmentButton: {
    marginRight: theme.spacing(2)
  },
  breadcrumbLink: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer'
  },
  buttonPlaceHolder: {
    height: theme.spacing(3)
  },
  deleteAttachmentButton: {
    position: 'absolute',
    top: '8px',
    right: '10px',
    cursor: 'pointer',

    '&.folderButton': {
      color: theme.palette.secondary.light
    },

    '&:hover': {
      color: theme.palette.response.warning
    }
  },
  deleteMarkupButton: {
    top: '-1px',
    right: 0,
    color: theme.palette.primary.contrastText
  },
  dialogControls: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(2)

  },
  disabledButton: {
    color: theme.palette.app.greyedOutText,
    cursor: 'auto'
  },
  documentIcon: {
    fontSize: '80px',
    color: theme.palette.primary.contrastText
  },
  documentRoot: {
    padding: '8px 24px',
    position: 'relative'
  },
  everyOther: {
    backgroundColor: theme.palette.tertiary.dark
  },
  fileLink: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap'
  },
  fileList: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: theme.spacing(2)
  },
  fileOptionsButton: {
    alignSelf: 'end',

    '&.disabled': {
      color: theme.palette.app.greyedOutText
    },

    '&:hover': {
      color: theme.palette.section.folder
    }
  },
  fileWithMarkupWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.app.background,
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: `${theme.spacing(0.75)}px 0`,
    padding: theme.spacing(1),
    width: '100%'
  },
  folder: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    position: 'relative',
    userSelect: 'none'
  },
  folderIcon: {
    marginRight: theme.spacing(1)
  },
  folderItemContent: {
    pointerEvents: 'none',
    display: 'flex'
  },
  folderList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  folderTitle: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.5)
  },
  homeButton: {
    border: `2px solid ${theme.palette.secondary.light}`,
    marginLeft: theme.spacing(2)
  },
  markupList: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This costs memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    width: 'fit-content'
  },
  markupRoot: {
    display: 'flex',
    overflow: 'auto',
    alignItems: 'baseline'
  },
  markupThumbnail: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    marginRight: '8px',
    marginTop: '8px',
    cursor: 'pointer',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8
  },
  markupThumbnailContainer: {
    position: 'relative'
  },
  privacyIndicator: {
    marginRight: '0.35rem'
  },
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: '0px',
    overflowX: 'hidden',
    overflowY: 'scroll',

    '&.title': {
      padding: '4px 33px',
      paddingTop: '16px',
      overflowY: 'auto'
    },
    '& h2': {
      fontSize: '1.3em',
      lineHeight: '1.3em',
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  takeoffMarkupModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    paddingBottom: theme.spacing(2)
  },
  uploadButtonWrapper: {
    marginTop: '8px',
    marginLeft: '28px'
  },
  uploadDialogTitle: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '98%',
    top: '1%'
  }
}));

const StyledDropzoneDialog = withStyles((theme) => {

  return {
    '@global': {
      '.MuiDialogContent-root': {
        overflow: 'hidden'
      },
      '.MuiButton-textPrimary': {
        color: theme.palette.secondary.dark
      },
      '.MuiDropzonePreviewList-root': {
        overflowY: 'auto',
        maxHeight: '50vh',
        zIndex: 2,
        position: 'relative'
      },
      '.MuiDropzonePreviewList-imageContainer': {
        textAlign: 'left'
      }
    }
  };
})(DropzoneDialog);

const getAttachmentUrl = (attachment, parentType, isThumbnail) => {

  let ImageUrlApiFunction;
  switch (parentType) {
    case 'project':
      ImageUrlApiFunction = api.Project.GetProjectAttachmentUrl;
      break;
    case 'section':
      ImageUrlApiFunction = api.Project.GetSectionAttachmentUrl;
      break;
    case 'bid':
      ImageUrlApiFunction = api.Project.GetBidAttachmentUrl;
      break;
    case 'qualification':
      ImageUrlApiFunction = api.General.PrependApiUrl;
      break;
    default: // this should never happen, but just in case...
      ImageUrlApiFunction = () => '';
      break;
  }

  return ImageUrlApiFunction(attachment.id, isThumbnail);
};

const getAttachmentUrlNoRedirect = (attachment, parentType) => {

  let ImageUrlApiFunction;
  switch (parentType) {
    case 'project':
      ImageUrlApiFunction = api.Project.GetProjectAttachmentUrlNoRedirect;
      break;
    case 'section':
      ImageUrlApiFunction = api.Project.GetSectionAttachmentUrlNoRedirect;
      break;
    case 'bid':
      ImageUrlApiFunction = api.Project.GetBidAttachmentUrlNoRedirect;
      break;
    case 'qualification':
      ImageUrlApiFunction = api.General.PrependApiUrlNoRedirect;
      break;
    default: // this should never happen, but just in case...
      ImageUrlApiFunction = () => '';
      break;
  }

  return ImageUrlApiFunction(attachment.id);
};

const dataUrlToFile = (dataUrl, fileName, mimeType) => {

  return (fetch(dataUrl)
    .then((response) => {

      return response.arrayBuffer();
    })
    .then((buffer) => {

      return new File([buffer], fileName, { type: mimeType });
    })
  );
};

const AttachmentsDialog = memo((props) => {

  const classes = useStyles();

  const [assignDialogFiles, setAssignDialogFiles] = useState(null);
  const [showTakeoffMarkupModal, setShowTakeoffMarkupModal] = useState(false);
  const [currentTakeoffModalAttachmentUrl, setCurrentTakeoffModalAttachmentUrl] = useState('');
  const [fileHistoryMenuInfo, setFileHistoryMenuInfo] = useState({});
  const [hideDialog, setHideDialog] = useState(false);
  const [newFilesPrivate, setNewFilesPrivate] = useState(false);
  const [showSwagPdf, setShowSwagPdf] = useState(false);
  const [splitViewAttachment, setSplitViewAttachment] = useState(null);
  const [swagPdfAttachment, setSwagPdfAttachment] = useState({});
  const [swagPdfAttachmentUrl, setSwagPdfAttachmentUrl] = useState('');
  const [swagPdfAttachmentParentType, setSwagPdfAttachmentParentType] = useState(null);
  const [showDocumentUploadDialog, setShowDocumentUploadDialog] = useState(false);
  const [isUploadDialogFolderMode, setIsUploadDialogFolderMode] = useState(false);
  const [showConfirmDeleteAttachmentDialog, setShowConfirmDeleteAttachmentDialog] = useState(false);
  const [uploadOriginalVersionId, setUploadOriginalVersionId] = useState(undefined);
  const [parentTypeUploadOverride, setParentTypeUploadOverride] = useState(undefined);
  const [attachmentToDeleteInfo, setAttachmentToDeleteInfo] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadFileInfo, setUploadFileInfo] = useState('');
  const [shouldCancelUpload, setShouldCancelUpload] = useState(false);
  const [currentFolder, setCurrentFolder] = useState('');
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);
  const [couldNotDeleteFileNames, setCouldNotDeleteFileNames] = useState(null);

  const [deleteSectionAttachments] = ProjectApiSlice.useDeleteSectionAttachmentsMutation();
  const [deleteProjectAttachments] = ProjectApiSlice.useDeleteProjectAttachmentsMutation();
  const [deleteBidAttachments] = ProjectApiSlice.useDeleteBidAttachmentsMutation();
  const [deleteItemAttachments] = ProjectApiSlice.useDeleteItemAttachmentsMutation();
  const [editAttachment] = ProjectApiSlice.useEditAttachmentMutation();
  const [uploadBidAttachment] = ProjectApiSlice.useUploadBidAttachmentMutation();
  const [uploadItemAttachment] = ProjectApiSlice.useUploadItemAttachmentMutation();
  const [uploadProjectAttachment] = ProjectApiSlice.useUploadProjectAttachmentMutation();
  const [uploadSectionAttachment] = ProjectApiSlice.useUploadSectionAttachmentMutation();

  const bidderInfoSorted = useSelector((state) => state.project.bidderInfoSorted);
  const loggedInUserRepCompanyId = useSelector((state) => state.project.originalProject.loggedInUserRepCompanyId);

  const {
    autoOpenAssignBidsDialog,
    allowAllFileTypes,
    attachmentNumber,
    attachments,
    beforeClose,
    bid,
    bids,
    fieldsIfDetailsDoNotExist,
    handleCancel,
    id,
    item,
    moduleData,
    onAttachmentDeleted,
    onBidAttachmentUploaded,
    onChangeSectionUpdatesLocked,
    onDocumentUploaded,
    onPinBidAttachment,
    onSplitScreenChange,
    onUnpinBidAttachment,
    open,
    parentType,
    permissions,
    project,
    projectDivisionsAndSections,
    projectLevelAttachments,
    qualificationType,
    section,
    sectionIdLookup,
    sectionIds
  } = props;

  let isAreaLevel = false;
  let isBidLevel = false;
  let isItemLevel = false;
  let isProjectLevel = false;
  let canArchive = false;
  let canChangeVisibility = false;
  let canUpload = false;
  let canUploadNewVersion = false;

  if (parentType === 'bid') {
    isBidLevel = true;
    canArchive = permissions.BidFiles.Archive;
    canChangeVisibility = permissions.BidFiles.ChangeVisibility;
    canUpload = permissions.BidFiles.Upload;
    canUploadNewVersion = permissions.BidFiles.UploadNewVersion;
  }
  else if (parentType === 'item') {
    isItemLevel = true;
    canArchive = permissions.ItemFiles.Archive;
    canChangeVisibility = permissions.ItemFiles.ChangeVisibility;
    canUpload = permissions.ItemFiles.Upload;
    canUploadNewVersion = permissions.ItemFiles.UploadNewVersion;
  }
  else if (parentType === 'project') {
    isProjectLevel = true;
    canArchive = permissions.ProjectFiles.UploadDelete;
    canChangeVisibility = permissions.ProjectFiles.ChangeVisibility;
    canUpload = permissions.ProjectFiles.UploadDelete;
    canUploadNewVersion = permissions.ProjectFiles.UploadNewVersion;
  }
  else if (parentType === 'section') {
    isAreaLevel = true;
    canArchive = permissions.AreaFiles.Archive;
    canChangeVisibility = permissions.AreaFiles.ChangeVisibility;
    canUpload = permissions.AreaFiles.Upload;
    canUploadNewVersion = permissions.AreaFiles.UploadNewVersion;
  }
  else if (parentType === 'qualification') {
    canArchive = permissions.CompanyQualifications.Edit;
    canChangeVisibility = permissions.CompanyQualifications.Edit;
    canUpload = permissions.CompanyQualifications.Edit;
    canUploadNewVersion = permissions.CompanyQualifications.Edit;
  }

  const afterDocumentUpload = useCallback(async (result) => {

    if (result && (result.err || result.error)) {
      // TODO: error out?
      return;
    }

    await onDocumentUploaded?.();

    if (splitViewAttachment) {
      const updatedSplitViewAttachment = attachments.bid
        .find((att) => att.id === splitViewAttachment.id);

      setSplitViewAttachment(updatedSplitViewAttachment);
    }
  }, [attachments, onDocumentUploaded, splitViewAttachment]);

  const getFilesAndChildFolders = useCallback((allAttachments, attachmentsParentType, isQualification) => {

    const output = {
      folders: [],
      files: []
    };

    if (!allAttachments) {
      return output;
    }

    const folder = currentFolder || '';
    const filteredAttachments = [];

    for (const attachment of allAttachments) {

      if (!allowAllFileTypes && attachment.attachmentType !== api.Project.AttachmentType.PDF) {
        continue;
      }

      const attachmentFolders = attachment.folders || '';
      if (attachmentFolders === folder) {
        filteredAttachments.push(attachment);
      }
      else if (attachmentFolders.startsWith(folder)) {
        const nameIndex = (folder) ? 1 : 0;
        const childFolderName = attachmentFolders.replace(folder, '').split('/')[nameIndex];

        if (childFolderName) {
          output.folders.push(childFolderName);
        }
      }
    }

    const mostRecentFileVersions = {};
    for (let i = 0; i < filteredAttachments.length; i++) {
      const attachment = filteredAttachments[i];
      if (!attachment.attachmentType) {
        continue;
      }

      if (isQualification) {
        if (attachment.newVersions?.length) {
          const versionIndex = attachment.newVersions.length - 1;
          const mostRecent = attachment.newVersions[versionIndex];
          mostRecent.id = attachment.id;
          mostRecent.originalAttachmentId = attachment.id;
          mostRecent.url = api.Qualification
            .GetQualificationAttachmentUrl(attachment.id, attachmentNumber, versionIndex);
          mostRecentFileVersions[i] = mostRecent;
        }
        else {
          attachment.originalAttachmentId = attachment.id;
          attachment.url = api.Qualification.GetQualificationAttachmentUrl(attachment.id, attachmentNumber);
          mostRecentFileVersions[i] = attachment;
        }
      }

      else {
        if (attachment.originalAttachmentId) {
          mostRecentFileVersions[attachment.originalAttachmentId] = attachment;
        }
        else {
          mostRecentFileVersions[attachment.id] = attachment;
        }
      }
    }

    _.each(Object.values(mostRecentFileVersions), (attachment) => output.files.push([attachment, attachmentsParentType]));
    return output;
  }, [allowAllFileTypes, attachmentNumber, currentFolder]);


  const getChildFoldersAndRelevantDocuments = useCallback((attachment) => {

    let childFolders = [];
    const relevantDocuments = [];

    if (parentType === 'bid') {
      const bidFoldersAndFiles = getFilesAndChildFolders(attachment.bid, 'bid');
      childFolders.push(...bidFoldersAndFiles.folders);
      relevantDocuments.push(...bidFoldersAndFiles.files);
    }

    if (parentType === 'item') {
      const itemFoldersAndFiles = getFilesAndChildFolders(attachment.item, 'item');
      childFolders.push(...itemFoldersAndFiles.folders);
      relevantDocuments.push(...itemFoldersAndFiles.files);
    }

    const sectionFoldersAndFiles = getFilesAndChildFolders(attachment.section, 'section');
    childFolders.push(...sectionFoldersAndFiles.folders);
    relevantDocuments.push(...sectionFoldersAndFiles.files);

    const qualificationFoldersAndFiles = getFilesAndChildFolders(attachment.qualification, 'qualification', true);
    childFolders.push(...qualificationFoldersAndFiles.folders);
    relevantDocuments.push(...qualificationFoldersAndFiles.files);

    const projectFoldersAndFiles = getFilesAndChildFolders(attachment.project, 'project');
    childFolders.push(...projectFoldersAndFiles.folders);
    relevantDocuments.push(...projectFoldersAndFiles.files);

    childFolders = [...new Set(childFolders)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    return { childFolders, relevantDocuments };
  }, [getFilesAndChildFolders, parentType]);

  const openTakeoffMarkupModal = useCallback((attachment, attachmentParentType) => {

    setShowTakeoffMarkupModal(true);
    setCurrentTakeoffModalAttachmentUrl(getAttachmentUrl(attachment, attachmentParentType));
  }, []);

  const closeTakeoffMarkupModal = useCallback(() => {

    setShowTakeoffMarkupModal(false);
    setCurrentTakeoffModalAttachmentUrl('');
  }, []);

  const openSwagPdf = useCallback((attachment, attachmentParentType) => {

    getAttachmentUrlNoRedirect(attachment, attachmentParentType).then((attachmentUrl) => {

      setSwagPdfAttachment(attachment);
      setSwagPdfAttachmentUrl(attachmentUrl);
      setSwagPdfAttachmentParentType(attachmentParentType);
      setShowSwagPdf(true);
    });
  }, []);

  const handleAddMarkupClick = useCallback((parentAttachment, attachmentParentType) => {

    openSwagPdf(parentAttachment, attachmentParentType);
  }, [openSwagPdf]);

  const closeSwagPdf = useCallback(() => {

    setShowSwagPdf(false);
    setSwagPdfAttachment({});
    setSwagPdfAttachmentUrl('');
  }, []);

  const handleSavePdfSnippet = useCallback((snippetDataUrl) => {

    const bidId = bid?.id || moduleData?.bidId;
    const itemId = moduleData?.itemId;
    const parentId = swagPdfAttachment.id;

    closeSwagPdf();

    dataUrlToFile(snippetDataUrl, 'markup.png', 'image/png')
      .then((file) => {

        if (file) {
          const parentProjectAttachmentId = (swagPdfAttachmentParentType === 'project') ? parentId : undefined;
          const parentSectionAttachmentId = (swagPdfAttachmentParentType === 'section') ? parentId : undefined;
          const parentBidAttachmentId = (swagPdfAttachmentParentType === 'bid') ? parentId : undefined;

          uploadBidAttachment({
            bidId,
            itemId,
            originalAttachmentId: undefined,
            attachmentType: api.Project.AttachmentType.TakeoffMarkup,
            displayFilename: 'markup.png',
            folders: currentFolder || null,
            file,
            parentProjectAttachmentId,
            parentSectionAttachmentId,
            parentBidAttachmentId
          })
            .then(() => {

              onBidAttachmentUploaded?.();
            });
        }
        else {
          // TODO: error out?
        }
      });
  }, [
    bid,
    closeSwagPdf,
    currentFolder,
    moduleData,
    onBidAttachmentUploaded,
    swagPdfAttachment,
    swagPdfAttachmentParentType,
    uploadBidAttachment
  ]);

  const handleClickUploadDocument = useCallback(() => {

    setIsUploadDialogFolderMode(false);
    setShowDocumentUploadDialog(true);
  }, []);

  const handleClickUploadFolder = useCallback(() => {

    setIsUploadDialogFolderMode(true);
    setShowDocumentUploadDialog(true);
  }, []);

  const handleClickUploadNewVersion = useCallback((newUploadOriginalVersionId, newParentTypeUploadOverride) => {

    setUploadOriginalVersionId(newUploadOriginalVersionId);
    setParentTypeUploadOverride(newParentTypeUploadOverride);
    setShowDocumentUploadDialog(true);
  }, []);

  const handleNewFilesPrivateClick = useCallback(() => setNewFilesPrivate((prev) => !prev), []);

  const handlePrivacyToggleClick = useCallback((evt) => {

    const { id: attachmentId, type, isPrivate, isProjectFile } = evt.currentTarget.dataset;
    editAttachment({
      id: attachmentId,
      itemId: item?.id,
      type,
      isPrivate: !(isPrivate === 'true')
    });

    if (isProjectFile === 'true') {
      onDocumentUploaded?.(true);
    }
  }, [editAttachment, item, onDocumentUploaded]);

  const handleCloseClick = useCallback((evt) => {

    if (isUploading) {
      setShouldCancelUpload(true);
    }
    else {
      //TODO: [CA] This may be deprecated
      beforeClose?.();

      setCurrentFolder('');
      handleCancel(evt);
    }
  }, [beforeClose, handleCancel, isUploading]);

  const handleCloseAssignFilesDialog = useCallback(() => {

    if (autoOpenAssignBidsDialog) {
      handleCancel();
    }

    setAssignDialogFiles(null);
  }, [autoOpenAssignBidsDialog, handleCancel]);

  const getOnlyMostRecentAttachmentVersions = useCallback((allAttachments) => {

    const mostRecentFileVersions = {};
    for (const attachment of allAttachments) {
      if (attachment.originalAttachmentId) {
        mostRecentFileVersions[attachment.originalAttachmentId] = attachment;
      }
      else {
        mostRecentFileVersions[attachment.id] = attachment;
      }
    }

    return Object.values(mostRecentFileVersions);
  }, []);

  const handleAssignFilesClick = useCallback(() => {

    const newAssignDialogFiles = getOnlyMostRecentAttachmentVersions(projectLevelAttachments)
      .filter((a) => {

        const numAssignees = (
          (a.assigneeBidAttachments?.length || 0) +
          (a.assigneeSectionAttachments?.length || 0) +
          (a.assigneeProjectAttachments?.length || 0)
        );

        return !numAssignees;
      });

    setAssignDialogFiles(newAssignDialogFiles);
  }, [getOnlyMostRecentAttachmentVersions, projectLevelAttachments]);

  useEffect(() => {

    if (autoOpenAssignBidsDialog) {
      handleAssignFilesClick();
    }
  }, [autoOpenAssignBidsDialog, handleAssignFilesClick]);

  const handleFilesAssigned = useCallback(() => {

    handleCloseAssignFilesDialog();
  }, [handleCloseAssignFilesDialog]);

  const handleDeleteAttachmentClick = useCallback((
    attachmentId,
    attachmentParentType,
    description,
    isProjectAttachmentMarkup
  ) => {

    setShowConfirmDeleteAttachmentDialog(true);
    setAttachmentToDeleteInfo({ id: attachmentId, isProjectAttachmentMarkup, attachmentParentType, description });
  }, []);

  const closeDeleteAttachmentDialog = useCallback(() => {

    setShowConfirmDeleteAttachmentDialog( false);
    setAttachmentToDeleteInfo({});
  }, []);

  const changeFolder = useCallback((newFolder) => setCurrentFolder(newFolder || ''), []);

  const handleBreadcrumbClick = useCallback((evt) => changeFolder(evt.target.dataset.folder || ''), [changeFolder]);

  const handleFolderClick = useCallback((evt) => {

    const prefix = currentFolder ? `${currentFolder}/` : '';
    changeFolder(`${prefix}${evt.target.dataset.folder || ''}`);
  }, [changeFolder, currentFolder]);

  const handleDeleteFolder = useCallback(async (folderToDelete) => {

    if (currentFolder) {
      folderToDelete = `${currentFolder}/${folderToDelete}`;
    }

    setIsDeletingFolder(true);
    const currentAttachments = attachments || {};
    const couldNotDeleteFiles = [];
    const loopData = [
      ['bid', deleteBidAttachments],
      ['item', deleteItemAttachments],
      ['section', deleteSectionAttachments],
      ['project', deleteProjectAttachments]
    ];

    for (const [parentAttachmentType, deleteEndpoint] of loopData) {
      const idsToDelete = [];
      const attachmentsToDeleteById = {};

      for (const attachment of (currentAttachments[parentAttachmentType] || [])) {
        if (attachment.folders?.startsWith(folderToDelete)) {
          idsToDelete.push(attachment.id);
          attachmentsToDeleteById[attachment.id] = attachment;
        }
      }

      if (idsToDelete.length) {
        const deleteResult = await deleteEndpoint({ ids: idsToDelete });
        onAttachmentDeleted?.(deleteResult);

        if (deleteResult?.data?.success) {
          for (const deletedId of deleteResult.data.deletedAttachmentIds) {
            delete attachmentsToDeleteById[deletedId];
          }
        }

        for (const attachment of Object.values(attachmentsToDeleteById)) {
          couldNotDeleteFiles.push(attachment);
        }
      }
    }

    const newCouldNotDeleteFileNames = (couldNotDeleteFiles.length) ?
      couldNotDeleteFiles.map((attachment) => attachment.displayFilename) :
      null;

    setCouldNotDeleteFileNames(newCouldNotDeleteFileNames);
    setIsDeletingFolder(false);
    closeDeleteAttachmentDialog();
  }, [
    attachments,
    closeDeleteAttachmentDialog,
    currentFolder,
    deleteBidAttachments,
    deleteItemAttachments,
    deleteProjectAttachments,
    deleteSectionAttachments,
    onAttachmentDeleted
  ]);

  const handleConfirmCouldNotDeleteFiles = useCallback(() => setCouldNotDeleteFileNames(null), []);

  const handleConfirmDeleteAttachment = useCallback(() => {

    const { id: idToDelete, isProjectAttachmentMarkup, attachmentParentType } = attachmentToDeleteInfo;
    switch (attachmentParentType) {
      case 'project':
        deleteProjectAttachments({ ids: [idToDelete] }).then(onAttachmentDeleted);
        break;
      case 'section':
        deleteSectionAttachments({ ids: [idToDelete] }).then(onAttachmentDeleted);
        break;
      case 'bid':
        deleteBidAttachments({ ids: [idToDelete] }).then(() => onAttachmentDeleted(isProjectAttachmentMarkup));
        break;
      case 'item':
        deleteItemAttachments({ ids: [idToDelete] }).then(onAttachmentDeleted);
        break;
      case 'qualification':
        api.Qualification.deleteAttachment(idToDelete, attachmentNumber).then(onAttachmentDeleted);
        break;
      case 'folder':
        handleDeleteFolder(idToDelete);
        return;
      default:
        // do nothing
    }

    closeDeleteAttachmentDialog();
  }, [
    attachmentNumber,
    attachmentToDeleteInfo,
    closeDeleteAttachmentDialog,
    deleteBidAttachments,
    deleteItemAttachments,
    deleteProjectAttachments,
    deleteSectionAttachments,
    handleDeleteFolder,
    onAttachmentDeleted
  ]);

  const closeDocumentUploadDialog = useCallback(() => {

    setShowDocumentUploadDialog(false);
    setIsUploadDialogFolderMode(false);
    setUploadOriginalVersionId(undefined);
    setParentTypeUploadOverride(undefined);
  }, []);

  const onUploadProgress = useCallback((uploadEvent) => {

    if (uploadEvent.direction === 'upload') {
      setUploadProgress(uploadEvent.percent);
    }
  }, []);

  const handleDocumentFileUpload = useCallback(async (files) => {

    closeDocumentUploadDialog();
    setUploadProgress(0);
    setUploadFileInfo(`1 of ${files.length}`);

    const attachmentParentType = parentTypeUploadOverride || parentType;

    let uploadFileNumber = 0;
    for (const file of files) {

      if (shouldCancelUpload) {
        break;
      }

      const fileType = (file.name.toLowerCase().endsWith('pdf')) ? 'PDF' : 'File';
      let folders = file.webkitRelativePath;

      // Remove filename from filepath
      if (typeof folders === 'string') {
        folders = folders.substring(0, folders.lastIndexOf('/'));
      }

      // Upload files into the current folder
      if (currentFolder) {
        folders = (folders) ? `${currentFolder}/${folders}` : currentFolder;
      }

      if (!folders) {
        folders = null;
      }

      let endpoint;
      let params = [];

      switch (attachmentParentType) {
        case 'project':
          endpoint = uploadProjectAttachment;
          params = [{
            projectId: project.id,
            originalAttachmentId: uploadOriginalVersionId,
            attachmentType: fileType,
            displayFilename: file.name,
            folders,
            file,
            isPrivate: newFilesPrivate,
            onUploadProgress
          }];
          break;
        case 'section':
          endpoint = uploadSectionAttachment;
          params = [{
            sectionIds: sectionIds || [section.id],
            originalAttachmentId: uploadOriginalVersionId,
            attachmentType: fileType,
            displayFilename: file.name,
            folders,
            file,
            isPrivate: newFilesPrivate,
            onUploadProgress
          }];
          break;
        case 'bid':
          endpoint = uploadBidAttachment;
          params = [{
            bidId: bid.id,
            itemId: moduleData?.itemId,
            originalAttachmentId: uploadOriginalVersionId,
            attachmentType: fileType,
            displayFilename: file.name,
            folders,
            file,
            isPrivate: newFilesPrivate,
            onUploadProgress
          }];
          break;
        case 'item':
          endpoint = uploadItemAttachment;
          params = [{
            bidId: bid.id,
            itemId: item.id,
            originalAttachmentId: uploadOriginalVersionId,
            attachmentType: fileType,
            displayFilename: file.name,
            folders,
            file,
            isPrivate: newFilesPrivate,
            onUploadProgress
          }];
          break;
        case 'qualification':
          endpoint = api.Qualification.UploadAttachment;
          params = [
            moduleData._id,
            fileType,
            file.name,
            file,
            qualificationType,
            fieldsIfDetailsDoNotExist,
            attachmentNumber,
            onUploadProgress
          ];
          break;
        default:
          // do nothing
      }

      setIsUploading(true);
      setUploadFileInfo(`${uploadFileNumber} of ${files.length}`);
      const result = await endpoint(...params);
      afterDocumentUpload?.(result);
      uploadFileNumber++;
    }

    if (uploadOriginalVersionId) {
      setFileHistoryMenuInfo({});
      setParentTypeUploadOverride(undefined);
      setUploadOriginalVersionId(undefined);
    }

    setIsUploading(false);
    setShouldCancelUpload(false);
    setFileHistoryMenuInfo({});
  }, [
    afterDocumentUpload,
    attachmentNumber,
    bid,
    closeDocumentUploadDialog,
    currentFolder,
    fieldsIfDetailsDoNotExist,
    item,
    moduleData,
    newFilesPrivate,
    onUploadProgress,
    parentType,
    parentTypeUploadOverride,
    project,
    qualificationType,
    section,
    sectionIds,
    shouldCancelUpload,
    uploadBidAttachment,
    uploadItemAttachment,
    uploadOriginalVersionId,
    uploadProjectAttachment,
    uploadSectionAttachment
  ]);

  const handleSplitViewClosed = useCallback((closeAttachmentsDialog) => {

    setHideDialog(false);
    setSplitViewAttachment(null);

    if (closeAttachmentsDialog === true) {
      handleCancel();
    }
  }, [handleCancel]);

  const handleFileHistoryClose = useCallback(() => {

    setFileHistoryMenuInfo({});
  }, []);

  const showFileHistoryMenu = useCallback((evt) => {

    let {
      attachmentId,
      attachmentCompanyId,
      canUpload: canUploadNewVer,
      originalAttachmentId,
      scope
    } = evt.currentTarget.dataset;
    const isQualification = (scope === 'qualification');
    let pastFileVersions = [];

    if (isQualification) {
      const originalAttachment =
        (attachments?.qualification || []).find((a) => a.id === originalAttachmentId);
      if (originalAttachment.newVersions?.length) {
        pastFileVersions = [originalAttachment, ...originalAttachment.newVersions];
        // The last item in the array will be the newest attachment which is already displayed, so remove it
        pastFileVersions.pop();
      }
    }

    else {
      attachmentId = parseInt(attachmentId);
      originalAttachmentId = parseInt(originalAttachmentId);
      attachmentCompanyId = parseInt(attachmentCompanyId);
      const allAttachments = [
        ...(attachments?.bid || []),
        ...(attachments?.item || []),
        ...(attachments?.section || []),
        ...(attachments?.project || [])
      ];

      if (originalAttachmentId) {
        const oid = originalAttachmentId;
        pastFileVersions =
          allAttachments.filter((a) => a.id !== attachmentId && (a.originalAttachmentId === oid || a.id === oid));
      }
    }

    setFileHistoryMenuInfo({
      attachmentNumber,
      anchorElement: evt.target,
      canUpload: (attachmentCompanyId === loggedInUserRepCompanyId) && canUploadNewVer === 'true',
      pastFileVersions,
      onClose: handleFileHistoryClose,
      onUpload: handleClickUploadNewVersion,
      originalAttachmentId: originalAttachmentId || attachmentId,
      scope
    });
  }, [
    attachmentNumber,
    attachments,
    handleClickUploadNewVersion,
    handleFileHistoryClose,
    loggedInUserRepCompanyId
  ]);

  const renderMarkup = useCallback((markup) => {

    let deleteMarkupButton = null;
    const canSeeDeleteMarkupButton = (markup.companyId === loggedInUserRepCompanyId) && permissions.PDFModule.DeleteMarkup;
    if (canSeeDeleteMarkupButton) {
      deleteMarkupButton = (
        <Tooltip title="Delete markup" placement="bottom" arrow>
          <CancelIcon
            className={classes.deleteAttachmentButton + ' ' + classes.deleteMarkupButton}
            fontSize="small"
            onClick={() => {

              const isProjectAttachmentMarkup = Boolean(markup.parentProjectAttachmentId);
              handleDeleteAttachmentClick(
                markup.id,
                'bid',
                'Really delete the selected markup?',
                isProjectAttachmentMarkup
              );
            }}
            data-cy="markup-thumbnail.delete-markup-button"
          />
        </Tooltip>
      );
    }

    return (
      <div
        key={`mu${markup.id}`}
        className={classes.markupThumbnailContainer}
        data-cy="attachment-dialog.markup-thumbnail"
      >
        {deleteMarkupButton}
        <Link
          onClick={() => openTakeoffMarkupModal(markup, 'bid')}
        >
          <img
            src={getAttachmentUrl(markup, 'bid', true)}
            alt="Takeoff Markup Thumbnail"
            className={classes.markupThumbnail}
          />
        </Link>
      </div>
    );
  }, [
    classes,
    handleDeleteAttachmentClick,
    loggedInUserRepCompanyId,
    openTakeoffMarkupModal,
    permissions
  ]);

  const renderAttachment = useCallback((attachment, attachmentParentType) => {

    const isInStrictMode = Boolean(project?.isStrictMode);
    const attachmentModuleData = moduleData || {};
    const extraButtons = [];
    const markupUI = [];
    let canArchiveFile = false;
    let canChangeFileVisibility = false;
    let canUploadFile = false;

    const parentTypeIsProjectLevel = (attachmentParentType === 'project');
    const parentTypeIsAreaLevel = (attachmentParentType === 'section');
    const parentTypeIsBidLevel = (attachmentParentType === 'bid');
    const parentTypeIsItemLevel = (attachmentParentType === 'item');
    const parentTypeIsQualification = (attachmentParentType === 'qualification');

    if (parentTypeIsProjectLevel) {
      if (permissions.ProjectFiles.UploadDelete) {
        canArchiveFile = true;
        canUploadFile = true;
      }

      canChangeFileVisibility = permissions.ProjectFiles.ChangeVisibility;
    }
    else if (parentTypeIsAreaLevel) {
      canUploadFile = permissions.AreaFiles.Upload;
      canArchiveFile = permissions.AreaFiles.Archive;
      canChangeFileVisibility = permissions.AreaFiles.ChangeVisibility;
    }
    else if (parentTypeIsBidLevel) {
      canUploadFile = permissions.BidFiles.Upload;
      canArchiveFile = permissions.BidFiles.Archive;
      canChangeFileVisibility = permissions.BidFiles.ChangeVisibility;
    }
    else if (parentTypeIsItemLevel) {
      canUploadFile = permissions.ItemFiles.Upload;
      canArchiveFile = permissions.ItemFiles.Archive && attachment.companyId === loggedInUserRepCompanyId;
      canChangeFileVisibility = permissions.ItemFiles.ChangeVisibility;
    }
    else if (parentTypeIsQualification && permissions.CompanyQualifications.Edit) {
      canUploadFile = true;
      canArchiveFile = true;
    }


    _.each(attachment.bidAttachments, (markup) => {

      if (
        markup.attachmentType === api.Project.AttachmentType.TakeoffMarkup
        && (markup.bidId === attachmentModuleData.bidId || !permissions.AdditionalPerms.IsViewingOwnCompanysProject)
        && markup.itemId === attachmentModuleData.itemId
      ) {
        markupUI.push(renderMarkup(markup));
      }
    });

    if (isBidLevel && bid?.companyId !== loggedInUserRepCompanyId && attachment.attachmentType === 'PDF') {

      const handleSplitView = () => {

        setHideDialog(true);
        setSplitViewAttachment(attachment);
      };

      extraButtons.push(
        <Tooltip
          title={(isInStrictMode) ? 'Disabled in strict mode' : 'Split View'}
          placement="top"
          arrow
          key={extraButtons.length}
        >
          <IconButton
            data-cy="file-browser.file.split-view-button"
            className={(isInStrictMode) ? classes.disabledButton : undefined}
            onClick={(isInStrictMode) ? null : handleSplitView}
            size="small"
          >
            <ViewWeekRoundedIcon
              // color="primary"
              fontSize="small"
              className={classes.fileOptionsButton + ((isInStrictMode) ? ' disabled' : '')}
            />
          </IconButton>
        </Tooltip>
      );
    }

    const isAwardedOrStarred = [BidStatus.Awarded, BidStatus.Starred].includes(bid?.status);
    if (
      attachmentParentType !== 'qualification' &&
      !isAwardedOrStarred &&
      !_.isEmpty(attachmentModuleData) &&
      permissions.PDFModule.AddMarkup
    ) {

      extraButtons.push(

        <Tooltip title="Add Markup" placement="top" arrow key={extraButtons.length} >
          <span className={classes.fileOptionsButton}>
            <AddButton
              dataCy="file.add-markup-button"
              onClick={() => handleAddMarkupClick(attachment, attachmentParentType)}
            />
          </span>
        </Tooltip>
      );
    }

    if (attachmentParentType !== 'qualification') {
      let canTogglePrivacy = (
        !bid?.readOnly &&
        attachment.companyId === loggedInUserRepCompanyId &&
        canChangeFileVisibility
      );

      if (['bid', 'section'].includes(attachmentParentType)) {
        canTogglePrivacy = (
          canChangeFileVisibility &&
          !bid?.readOnly &&
          !isAwardedOrStarred &&
          attachment.companyId === loggedInUserRepCompanyId
        );
      }

      const PrivacyIcon = (attachment.isPrivate) ? VisibilityOffIcon : VisibilityIcon;
      const privacyIconClass = (canTogglePrivacy) ? '' : ' disabled';
      let privacyTooltipTitle = (attachment.isPrivate) ? 'Private' : 'Public';
      if (bid?.readOnly) {
        privacyTooltipTitle = 'Bid is locked';
      }

      extraButtons.push(
        <Tooltip title={privacyTooltipTitle} placement="top" arrow key={extraButtons.length}>
          <span>
            <IconButton
              data-id={attachment.id}
              data-type={attachmentParentType}
              data-is-private={attachment.isPrivate}
              data-is-project-file={Boolean(attachmentParentType === 'project')}
              data-cy="file-browser.file.privacy-toggle"
              onClick={handlePrivacyToggleClick}
              disabled={!canTogglePrivacy}
              size="small"
            >
              <PrivacyIcon
                color="primary"
                fontSize="small"
                className={classes.fileOptionsButton + privacyIconClass}
              />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    let scope;
    if (attachment.projectId) {
      scope = 'project';
    }
    else if (attachment.sectionId) {
      scope = 'section';
    }
    else if (attachment.isDirectItemAttachment) {
      scope = 'item';
    }
    else if (attachment.bidId) {
      scope = 'bid';
    }
    else {
      scope = 'qualification';
    }

    const fileHistoryButtonProps = {
      'data-attachment-company-id': attachment.companyId,
      'data-scope': scope
    };

    const handleArchiveAttachment = () => {

      handleDeleteAttachmentClick(
        attachment.originalAttachmentId || attachment.id,
        attachmentParentType,
        `Really delete all versions of "${attachment.displayFilename}" and any child attachments?`
      );
    };

    const fileStyle = { margin: '6px 0' };

    if (!markupUI.length) {
      fileStyle.width = 'calc(100% - 18px)';
    }

    let displayFilename;
    let archiveDisabledMessage;
    switch (attachmentParentType) {
      case 'bid':
        displayFilename = project.name + ' / '
          + section.title + ' / '
          + bid.company.name + ' / '
          + attachment.displayFilename;
        if (!canArchiveFile) {
          archiveDisabledMessage = 'Only managers can archive bid level files';
        }

        break;
      case 'item':
        displayFilename = project.name + ' / '
          + section?.title + ' / '
          + bid.company.name + ' / '
          + item.title + ' / '
          + attachment.displayFilename;
        if (!canArchiveFile) {
          archiveDisabledMessage = 'Only managers can archive item level files';
        }

        break;
      case 'section':
        displayFilename = project.name + ' / '
          + (section?.title || 'multiple sections') + ' / '
          + attachment.displayFilename;
        break;
      case 'project':
        displayFilename = project.name + ' / '
          + attachment.displayFilename;
        break;
      default:
        displayFilename = attachment.displayFilename;
        break;
    }

    const fileUI = (
      <FileElement
        key={attachmentParentType + attachment.id}
        archiveDisabledMessage={archiveDisabledMessage}
        bids={bids}
        canArchive={canArchiveFile}
        canUpload={canUploadFile}
        extraButtons={extraButtons}
        file={attachment}
        fileHistoryButtonProps={fileHistoryButtonProps}
        fullWidth
        onArchive={handleArchiveAttachment}
        onClick={null}
        onFileHistoryClick={showFileHistoryMenu}
        pathAndFilename={displayFilename}
        sectionIdLookup={sectionIdLookup}
        style={fileStyle}
      />
    );

    if (markupUI.length) {
      return (
        <div
          key={attachmentParentType + attachment.id}
          className={classes.fileWithMarkupWrapper}
          data-cy="file-wrapper"
        >
          {fileUI}
          <div className={classes.markupList}>
            {markupUI}
          </div>
        </div>
      );
    }

    return fileUI;
  }, [
    bid,
    bids,
    classes,
    handleAddMarkupClick,
    handleDeleteAttachmentClick,
    handlePrivacyToggleClick,
    isBidLevel,
    item,
    loggedInUserRepCompanyId,
    moduleData,
    permissions,
    project,
    renderMarkup,
    section,
    sectionIdLookup,
    showFileHistoryMenu
  ]);


  const renderChildFoldersAndRelevantDocuments = useCallback((childFolders, relevantDocuments) => {

    const splitCurrentFolders = currentFolder.split('/');
    const lastFolder = splitCurrentFolders.pop();
    const breadCrumbs = [];
    let folderPath = '';

    for (const folder of splitCurrentFolders) {
      folderPath += folderPath ? ('/' + folder) : folder;

      breadCrumbs.push(
        <Link
          className={classes.breadcrumbLink}
          data-folder={folderPath}
          data-cy="attachment-dialog.breadcrumb"
          key={folderPath}
          onClick={handleBreadcrumbClick}
        >
          {folder}
        </Link>
      );
    }

    if (currentFolder) {
      breadCrumbs.unshift(
        <IconButton
          className={classes.homeButton}
          data-folder=""
          data-cy="attachment-dialog.breadcrumb.home"
          key={'//homeButton'}
          onClick={handleBreadcrumbClick}
          size="small"
        >
          <HomeIcon color="secondary" />
        </IconButton>
      );
    }

    if (lastFolder) {
      breadCrumbs.push(
        <Typography color="inherit" key={`last//${lastFolder}`}>
          {lastFolder}
        </Typography>
      );
    }

    const folderElements = childFolders.map((folder) => {

      let deleteButton = null;
      if (canArchive) {
        deleteButton = (
          <Tooltip title="Delete folder" placement="top" arrow>
            <CancelOutlinedIcon
              className={classes.deleteAttachmentButton + ' folderButton'}
              onClick={(evt) => {

                evt.stopPropagation();

                handleDeleteAttachmentClick(
                  folder,
                  'folder',
                  `Really attempt to delete all files and subfolders within "${currentFolder}/${folder}"?` +
                  `  The folder will only be entirely removed if you have permission to delete every file within.`
                );
              }}
              data-cy="attachment-dialog.folder.delete-button"
            />
          </Tooltip>
        );
      }

      return (
        <Paper
          className={classes.folder}
          data-folder={folder}
          data-cy="attachment-dialog.folder"
          key={folder}
          onClick={handleFolderClick}
        >
          <span className={classes.folderItemContent}>
            <FolderIcon className={classes.folderIcon} color="secondary" />
            <Typography noWrap>{folder}</Typography>
          </span>
          {deleteButton}
        </Paper>
      );
    });

    const documentsElements =
      relevantDocuments.map(([attachment, attachmentParentType]) => renderAttachment(attachment, attachmentParentType));

    return (
      <Fragment>
        <Breadcrumbs>{breadCrumbs}</Breadcrumbs>
        <div className={classes.folderList}>{folderElements}</div>
        <div className={classes.fileList}>
          {documentsElements}
        </div>
      </Fragment>
    );
  }, [
    canArchive,
    classes.breadcrumbLink,
    classes.deleteAttachmentButton,
    classes.fileList,
    classes.folder,
    classes.folderIcon,
    classes.folderItemContent,
    classes.folderList,
    classes.homeButton,
    currentFolder,
    handleBreadcrumbClick,
    handleDeleteAttachmentClick,
    handleFolderClick,
    renderAttachment
  ]);

  const renderUploadProgress = useCallback(() => {

    if (!isUploading) {
      return null;
    }

    return (
      <LinearProgressWithLabel
        color="secondary"
        label={uploadFileInfo}
        value={uploadProgress}
      />
    );
  }, [isUploading, uploadFileInfo, uploadProgress]);

  const renderUploadButton = useCallback((isFolderButton) => {

    let canSeeUploadButton = false;

    if (isProjectLevel && permissions.ProjectFiles.UploadDelete) {
      canSeeUploadButton = true;
    }
    else if (isAreaLevel && permissions.AreaFiles.Upload) {
      canSeeUploadButton = true;
    }
    else if (isItemLevel && permissions.ItemFiles.Upload) {
      canSeeUploadButton = true;
    }
    else if (isBidLevel && permissions.BidFiles.Upload) {
      canSeeUploadButton = true;
    }
    else if (parentType === 'qualification' && permissions.CompanyQualifications.Edit) {
      canSeeUploadButton = true;
    }

    if (!canSeeUploadButton || (isFolderButton && parentType === 'qualification')) {
      return <div className={classes.buttonPlaceHolder}/>;
    }

    const fileType = allowAllFileTypes ? 'File' : 'Document';
    let buttonText = `Upload ${isFolderButton ? 'Folder' : `${fileType}s`}`;
    let handler = isFolderButton ? handleClickUploadFolder : handleClickUploadDocument;

    if (parentType === 'qualification') {
      if (attachments.qualification?.[0]?.url?.length) {
        buttonText = 'Replace Document';
        handler = () => handleClickUploadNewVersion(attachments.qualification[0].id, 'qualification');
      }
    }
    else if (!isFolderButton && (parentType === 'section' || parentType === 'bid')) {
      buttonText = `Upload ${fileType}s`;
    }

    return (
      <span className={classes.uploadButtonWrapper}>
        <AddButton
          onClick={handler}
          dataCy="attachment-dialog.upload-button"
          label={buttonText}
        />
      </span>
    );
  }, [
    allowAllFileTypes,
    attachments,
    classes,
    handleClickUploadDocument,
    handleClickUploadFolder,
    handleClickUploadNewVersion,
    isProjectLevel,
    isAreaLevel,
    isBidLevel,
    isItemLevel,
    parentType,
    permissions
  ]);

  return useMemo(() => {

    if (!open) {
      return null;
    }

    const attachmentsToRender = attachments || {};

    let closeButtonText;
    if (shouldCancelUpload) {
      closeButtonText = 'Canceling Upload...';
    }
    else if (isUploading) {
      closeButtonText = 'Cancel Upload';
    }
    else {
      closeButtonText = 'Close';
    }

    const deleteDialogType = (attachmentToDeleteInfo?.attachmentParentType === 'folder') ?
      'all files in this folder' :
      'this attachment';

    let uploadType;
    if (isUploadDialogFolderMode) {
      uploadType = 'A Folder';
    }
    else {
      uploadType = allowAllFileTypes ? 'Files' : 'Documents';
    }

    const { childFolders, relevantDocuments } = getChildFoldersAndRelevantDocuments(attachmentsToRender);

    let assignmentButton = null;
    let assignFilesDialog = null;

    if (
      project &&
      parentType === 'project' &&
      projectLevelAttachments?.length &&
      projectDivisionsAndSections &&
      bidderInfoSorted?.length
    ) {

      const numAssignedFiles = projectLevelAttachments.filter((a) => {

        return Boolean(
          (a.assigneeBidAttachments?.length || 0) +
          (a.assigneeSectionAttachments?.length || 0) +
          (a.assigneeProjectAttachments?.length || 0)
        );
      }).length;

      if (numAssignedFiles < projectLevelAttachments.length && permissions.ProjectFiles.AssignToBids) {

        assignmentButton = (
          <Button
            data-cy="attachment-dialog.assignment-button"
            className={classes.assignmentButton}
            onClick={handleAssignFilesClick}
            startIcon={<AssignmentReturnedIcon />}
            variant="contained"
          >
            Assign to bids
          </Button>
        );

        assignFilesDialog = (
          <AssignFilesToBidsDialog
            files={assignDialogFiles}
            onCancel={handleCloseAssignFilesDialog}
            onFilesAssigned={handleFilesAssigned}
            projectCompanyId={project.companyId}
            projectDivisionsAndSections={projectDivisionsAndSections}
          />
        );
      }

      else if (permissions.ProjectFiles.UploadDelete) {
        assignmentButton = (
          <Tooltip title="Upload more to assign to bids">
            <Button
              data-cy="attachment-dialog.assignment-button"
              className={classes.assignmentButton}
              onClick={handleClickUploadDocument}
              startIcon={<AssignmentReturnedIcon />}
              variant="contained"
            >
              All files assigned
            </Button>
          </Tooltip>
        );
      }
    }

    return (
      <Fragment>
        <Dialog
          id={id}
          open={open && !hideDialog}
          onClose={handleCloseClick}
          aria-labelledby={id + '_title'}
          aria-describedby={id + '_form'}
          fullWidth
          maxWidth="md"
          data-cy="attachment-dialog"
          disableEnforceFocus={true}
        >
          <div className={classes.titleWrapper}>
            <DialogTitle id={id + '_title'} className={classes.root + ' title'}>
              {allowAllFileTypes ? 'Files' : 'Documents'}
            </DialogTitle>
            <div className={classes.dialogControls}>
              {assignmentButton}
              <Button
                onClick={handleCloseClick}
                color="secondary"
                disabled={shouldCancelUpload}
                variant="contained"
                data-cy="attachment-dialog.close-button"
              >
                {closeButtonText}
              </Button>
            </div>
          </div>
          <DialogContent className={classes.root}>
            {renderChildFoldersAndRelevantDocuments(childFolders, relevantDocuments)}
            {renderUploadProgress()}
            {renderUploadButton()}
            {renderUploadButton(true)}
          </DialogContent>
        </Dialog>

        <Modal
          className={classes.takeoffMarkupModal}
          open={showTakeoffMarkupModal}
          onClose={closeTakeoffMarkupModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          data-cy="attachment-dialog.markup-modal"
          disableEnforceFocus={true}
        >
          <Fade in={showTakeoffMarkupModal}>
            <img src={currentTakeoffModalAttachmentUrl} alt="Markup" />
          </Fade>
        </Modal>

        <FileHistory {...fileHistoryMenuInfo} />

        <SwagPDFDialog
          id={'swagpdf'}
          open={showSwagPdf}
          handleCancel={closeSwagPdf}
          title="PDF Markup"
          pdfUrl={swagPdfAttachmentUrl}
          onSaveSnippet={handleSavePdfSnippet}
        />

        <AttachmentSplitViewDialog
          attachments={attachments}
          bid={bid}
          onDocumentUploaded={afterDocumentUpload}
          onChangeSectionUpdatesLocked={onChangeSectionUpdatesLocked}
          onPinBidAttachment={onPinBidAttachment}
          onSplitScreenChange={onSplitScreenChange}
          onSplitViewClose={handleSplitViewClosed}
          onUnpinBidAttachment={onUnpinBidAttachment}
          section={section}
          splitViewAttachment={splitViewAttachment}
        />

        <StyledDropzoneDialog
          acceptedFiles={allowAllFileTypes ? [] : ['application/pdf']}
          cancelButtonText="Cancel"
          submitButtonText="Upload"
          dialogTitle={
            <div className={classes.uploadDialogTitle}>
              Upload {uploadType}
              <FormControlLabel
                control={<Checkbox checked={newFilesPrivate} onChange={handleNewFilesPrivateClick}/>}
                label="Private"
              />
            </div>
          }
          dropzoneText={`Drag and drop ${uploadType.toLowerCase()} here or click`}
          maxFileSize={1024 * 1024 * 500}
          open={showDocumentUploadDialog}
          onClose={closeDocumentUploadDialog}
          onSave={handleDocumentFileUpload}
          showPreviews={true}
          showPreviewsInDropzone={false}
          showFileNamesInPreview={true}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          filesLimit={(uploadOriginalVersionId || parentType === 'qualification') ? 1 : 99999}
          inputProps={{ webkitdirectory: isUploadDialogFolderMode ? 'true' : undefined }}
          showAlerts={['error']}
          getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {

            if (rejectedFile.size > maxFileSize) {
              return 'File size cannot exceed ' + (maxFileSize / 1024 / 1024) + ' MB.  Contact support for options.';
            }

            return 'File could not be uploaded.  Contact support for options.';
          }}
        />

        <ConfirmDialog
          open={showConfirmDeleteAttachmentDialog}
          handleCancel={closeDeleteAttachmentDialog}
          handleConfirm={handleConfirmDeleteAttachment}
          title={`Delete ${deleteDialogType}?`}
          description={attachmentToDeleteInfo.description}
          cancelLabel="Cancel"
          confirmLabel="Delete"
        />

        <ConfirmDialog
          open={isDeletingFolder}
          title={`Deleting Folder "${attachmentToDeleteInfo.id}"`}
          description={
            <Fragment>
              <span>{`Deleting files in folder "${attachmentToDeleteInfo.id}".  Please wait...`}</span>
              <LinearProgress color="secondary" />
            </Fragment>
          }
        />

        <ConfirmDialog
          open={Boolean(couldNotDeleteFileNames)}
          title="Could not delete some files"
          description={
            <Fragment>
              <span>The following files could not be deleted:</span>
              {couldNotDeleteFileNames?.map((fileName) => <Fragment> <br/> {fileName} </Fragment>)}
            </Fragment>
          }
          confirmLabel="OK"
          handleConfirm={handleConfirmCouldNotDeleteFiles}
        />
        {assignFilesDialog}
      </Fragment>
    );
  }, [
    afterDocumentUpload,
    allowAllFileTypes,
    assignDialogFiles,
    attachmentToDeleteInfo,
    attachments,
    bid,
    bidderInfoSorted,
    classes.assignmentButton,
    classes.dialogControls,
    classes.root,
    classes.takeoffMarkupModal,
    classes.titleWrapper,
    classes.uploadDialogTitle,
    closeDeleteAttachmentDialog,
    closeDocumentUploadDialog,
    closeSwagPdf,
    closeTakeoffMarkupModal,
    couldNotDeleteFileNames,
    currentTakeoffModalAttachmentUrl,
    fileHistoryMenuInfo,
    getChildFoldersAndRelevantDocuments,
    handleAssignFilesClick,
    handleClickUploadDocument,
    handleCloseAssignFilesDialog,
    handleCloseClick,
    handleConfirmCouldNotDeleteFiles,
    handleConfirmDeleteAttachment,
    handleDocumentFileUpload,
    handleFilesAssigned,
    handleNewFilesPrivateClick,
    handleSavePdfSnippet,
    handleSplitViewClosed,
    hideDialog,
    id,
    isDeletingFolder,
    isUploadDialogFolderMode,
    isUploading,
    newFilesPrivate,
    onChangeSectionUpdatesLocked,
    onPinBidAttachment,
    onSplitScreenChange,
    onUnpinBidAttachment,
    open,
    parentType,
    permissions,
    project,
    projectDivisionsAndSections,
    projectLevelAttachments,
    renderChildFoldersAndRelevantDocuments,
    renderUploadButton,
    renderUploadProgress,
    section,
    shouldCancelUpload,
    showConfirmDeleteAttachmentDialog,
    showDocumentUploadDialog,
    showSwagPdf,
    showTakeoffMarkupModal,
    splitViewAttachment,
    swagPdfAttachmentUrl,
    uploadOriginalVersionId
  ]);

});


export default AttachmentsDialog;
