"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleTableSpecificCreation = exports.createWbsLookup = void 0;
var _parseCodeNumbers = require("./parseCodeNumbers");
var _isValidWbsCode = require("./isValidWbsCode");
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var applySiblingRowsToLookup = function applySiblingRowsToLookup(allWbsDataRowsSorted, lookup, tableType) {
  var allTopLevelRows = allWbsDataRowsSorted.filter(function (row) {
    return (0, _parseCodeNumbers.parseCodeNumbers)(row.wbsCode).length === 1;
  });
  var _iterator = _createForOfIteratorHelper(allWbsDataRowsSorted),
    _step;
  try {
    var _loop = function _loop() {
      var row = _step.value;
      var parentRow = lookup[tableType].codeLookup[row.wbsCode].parentRow;
      var rowCodeNumbers = (0, _parseCodeNumbers.parseCodeNumbers)(row.wbsCode);
      var siblings;
      if (parentRow !== undefined) {
        var childrenOfParent = lookup[tableType].codeLookup[parentRow.wbsCode].children;
        siblings = childrenOfParent.filter(function (row) {
          return (0, _parseCodeNumbers.parseCodeNumbers)(row.wbsCode).length === rowCodeNumbers.length;
        });
      } else {
        // If no parent exists, it must be a top level row
        siblings = allTopLevelRows;
      }
      lookup[tableType].codeLookup[row.wbsCode].siblings = siblings;
    };
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
};
var applyParentAndTopMostRowsToLookup = function applyParentAndTopMostRowsToLookup(row, lookup, tableType) {
  var codeNumbers = (0, _parseCodeNumbers.parseCodeNumbers)(row.wbsCode);
  if (codeNumbers.length === 1) {
    // Add to top most rows
    lookup[tableType].topLevelDataRows.push(row);
    return;
  }
  codeNumbers.pop();
  var parentCode = codeNumbers.join(' ');
  var parentRow = lookup[tableType].codeLookup[parentCode];
  if (parentRow) {
    // Add parent row
    lookup[tableType].codeLookup[row.wbsCode].parentRow = parentRow.self;
  }
};
var applyChildRowsToLookup = function applyChildRowsToLookup(row, lookup, tableType) {
  var codeNumbers = (0, _parseCodeNumbers.parseCodeNumbers)(row.wbsCode);
  codeNumbers.pop();
  var parentNumbers = codeNumbers.join(' ');
  if (lookup[tableType].codeLookup[parentNumbers]) {
    lookup[tableType].codeLookup[parentNumbers].children.push(row);
  }
};
var applyLeafRowsToLookup = function applyLeafRowsToLookup(allWbsDataRow, lookup, tableType) {
  var applyLeafRowsRecursively = function applyLeafRowsRecursively(wbsCode, childRows) {
    var _iterator2 = _createForOfIteratorHelper(childRows),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var childRow = _step2.value;
        var childChildRows = lookup[tableType].codeLookup[childRow.wbsCode].children;
        if (childChildRows.length === 0) {
          lookup[tableType].codeLookup[wbsCode].leafRows.push(childRow);
        } else {
          applyLeafRowsRecursively(wbsCode, childChildRows);
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  };
  var _iterator3 = _createForOfIteratorHelper(allWbsDataRow),
    _step3;
  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var row = _step3.value;
      var childRows = lookup[tableType].codeLookup[row.wbsCode].children;
      if (childRows.length === 0) {
        var thisRow = lookup[tableType].codeLookup[row.wbsCode].self;
        lookup[tableType].codeLookup[row.wbsCode].leafRows = [thisRow];
        continue;
      }
      applyLeafRowsRecursively(row.wbsCode, childRows);
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
};
var handleTableSpecificCreation = function handleTableSpecificCreation(tableType, allDataRowsSorted, lookup) {
  var getDefaultObject = function getDefaultObject(self) {
    return {
      self: self,
      children: [],
      siblings: [],
      leafRows: [],
      parentRow: undefined
    };
  };
  var allValidRowsSorted = [];
  var _iterator4 = _createForOfIteratorHelper(allDataRowsSorted),
    _step4;
  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var row = _step4.value;
      if (!(0, _isValidWbsCode.isValidWbsCode)(row.wbsCode)) {
        continue;
      }
      allValidRowsSorted.push(row);
      if (lookup[tableType].codeLookup[row.wbsCode] === undefined) {
        lookup[tableType].codeLookup[row.wbsCode] = getDefaultObject(row);
      }
      applyChildRowsToLookup(row, lookup, tableType);
      applyParentAndTopMostRowsToLookup(row, lookup, tableType);
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }
  applySiblingRowsToLookup(allValidRowsSorted, lookup, tableType);
  applyLeafRowsToLookup(allValidRowsSorted, lookup, tableType);
};

/**
 * This will output a table specific lookup with everything you should need (topLevelDataRows, childRows, siblings, parents, leafRows and self).
 * Each and every wbs code will be in this lookup.
 * (e.g.) lookup = {
 *          wbs: {
 *            topLevelDataRows: [...],
 *            codeLookup: {
 *              '01': { self: {...}, children: [...], leafRows: [...], siblings: [...], parentRow: {...} }
*              }
 *          },
 *          rbs: {
 *            topLevelDataRows: [...],
 *            codeLookup: {...}
 *          }
 *        }
 * @param {WBSDataRowShape} allRbsDataRowsSorted - all sorted rows from the Resource Breakdown Structure table
 * @param {WBSDataRowShape} allWbsDataRowsSorted - all sorted rows from the Work Breakdown Structure table
 */
exports.handleTableSpecificCreation = handleTableSpecificCreation;
var createWbsLookup = function createWbsLookup(allRbsDataRowsSorted, allWbsDataRowsSorted) {
  // This should be called as little as possible. This should never be called in a loop or any area that will cause this
  // to run more than one time. Try to call this at the highest scope possible and pass to any functions that may need it
  // if necessary. Client side this should be set to redux and accessed as needed through useSelector.
  var lookup = {
    wbs: {
      topLevelDataRows: [],
      codeLookup: {}
    },
    rbs: {
      topLevelDataRows: [],
      codeLookup: {}
    }
  };
  handleTableSpecificCreation('wbs', allWbsDataRowsSorted, lookup);
  handleTableSpecificCreation('rbs', allRbsDataRowsSorted, lookup);
  return lookup;
};
exports.createWbsLookup = createWbsLookup;