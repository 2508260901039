import request from 'superagent';

import apiCommon from './common';
import { baseApi, bidTags, projectDetailsTags } from './baseApi';

export const InvitationApiSlice = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({

    respond: builder.mutation({
      query: ({ inviteToken, isAccepted, rejectReason }) => ({
        url: '/invitation/respond',
        method: 'PATCH',
        data: { inviteToken, isAccepted, rejectReason }
      }),
      invalidatesTags: (result) => [
        ...bidTags(result.bidId),
        ...projectDetailsTags(result.projectId)
      ]
    }),

    send: builder.mutation({
      query: ({ invitationId }) => ({
        url: '/invitation/send',
        method: 'POST',
        data: { invitationId }
      }),
      invalidatesTags: ['Section']
    })

  })
});

export default {

  AddEstimatorInvite: async function (projectId, invitedUserId, divisionId, sectionId, phaseId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/invitation/add-estimator-invite')
      .send({
        projectId,
        invitedUserId,
        divisionId,
        sectionId,
        phaseId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Clicked: async function (inviteToken) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/invitation/clicked')
      .send({ inviteToken });

    return await apiCommon.ProcessRequest(apiRequest, { skipCsrf: true });
  },

  Delete: async function (id) {

    const apiRequest = request
      .del(process.env.REACT_APP_API_URL + '/invitation/delete')
      .send({ id });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  DeleteEstimatorInvite: async function (inviteId) {

    const apiRequest = request
      .delete(process.env.REACT_APP_API_URL + '/invitation/delete-estimator-invite')
      .send({
        inviteId
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetActiveInvitations: async function (projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/invitation/get-active-invitations')
      .query({ projectId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetAllInvitations: async function (companyId, page, resultsPerPage, searchString, activeTab, selectedLabel) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/invitation/get-all-invitations')
      .query({ companyId, activeTab, page, resultsPerPage, searchString, selectedLabel });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetCompanyInvitations: async function (companyId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/invitation/company-invitations')
      .query({ companyId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetEstimatorInvites: async function (projectId) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/invitation/estimator-invites')
      .query({ projectId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  GetDetails: async function (inviteToken) {

    const apiRequest = request
      .get(process.env.REACT_APP_API_URL + '/invitation/get-details')
      .query({ inviteToken });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Reissue: async function (invitationId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/invitation/reissue')
      .send({ invitationId });

    return await apiCommon.ProcessRequest(apiRequest, { skipCsrf: true });
  },

  Respond: async function (inviteToken, isAccepted, rejectReason) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/invitation/respond')
      .send({ inviteToken, isAccepted, rejectReason });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  RespondToEstimatorInvite: async function (inviteId, isAccepted) {

    const apiRequest = request
      .patch(process.env.REACT_APP_API_URL + '/invitation/respond-to-estimator-invite')
      .send({
        inviteId,
        isAccepted
      });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  Send: async function (invitationId) {

    const apiRequest = request.post(process.env.REACT_APP_API_URL + '/invitation/send')
      .send({ invitationId });

    return await apiCommon.ProcessRequest(apiRequest);
  },

  SetLastSeenEstimatorInvite: async function (inviteId) {

    const apiRequest = request
      .post(process.env.REACT_APP_API_URL + '/invitation/set-last-seen-estimator-invite')
      .send({ inviteId });

    return await apiCommon.ProcessRequest(apiRequest);
  }

};
