import { createTheme } from '@material-ui/core/styles';
import { themeDefinition } from './theme-relay';
import _ from 'lodash';

import SidebarLogo from './assets/images/logo_rfp';

const theme = createTheme(_.merge(themeDefinition({ sidebarLogo: SidebarLogo }), {
  css: {
    sidebar: {
      closed: {
        footer: {
          svg: {
            left: 'calc(50% - 20px)',
            clip: 'rect(auto, 36px, auto, auto)'
          }
        }
      },
      footer: {
        svg: {
          left: 'calc(50% - 60px)',
          clip: 'rect(auto, 200px, auto, auto)'
        }
      }
    }
  },
  palette: {
    app: {
      innerBorder: '#7939cb',
      innerBorderEdge: '#7939cb',
      notice: '#daa520'
    },
    sidebar: {
      activeIcon: '#0C7275',
      background: '#050405',
      defaultText: '#cccccc'
    },
    toolbar: {
      grandTotalBorder: '#528d92',
      grandTotalBackground: '#0004',
      grandTotalNumber: '#ffffff',
      grandTotalNumberBorder: '#000000',
      icon: '#ffffff',
      iconActive: '#b696da',
      labelText: '#ffffff',
      searchBackground: '#d9d9d9'
    },
    userManagement: {
      approver: '#2C0E2F',
      estimator: '#0C7275',
      manager: '#718D43'
    }
  }
}));

export default theme;
