export enum ParentType {
  Bid = 'bid',
  Project = 'project',
  Section = 'section',
}

export interface Company {
  companyId: number,
  companyName: string,
}

export interface SelectedCompany extends Company {
  isNewBidder: boolean
}

export interface InputsShape {
  [index: string]: { selectedCompanies: SelectedCompany[] }
}

export interface SectionInfoShape {
  id: number,
  name: string
}

export interface DivisionInfoShape {
  id: number,
  name: string,
  sections: SectionInfoShape[]
}

export interface BidToAddToProject extends Company {
  divisionId: number,
  divisionName: string,
  sectionId: number,
  sectionName: string
}
