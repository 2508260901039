import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';
import { parseCodeNumbers } from 'wbs/dist/parseCodeNumbers';
import { getChildRows } from 'wbs/dist/getChildRows';
import { WBSCodeLookupShape } from 'wbs/dist/types/WBSLookupShape';
import { createWbsLookup } from 'wbs/dist/createWbsLookup';
import { WBSColumnDataDataFields, WBSColumnDataShape } from 'wbs/dist/types/WBSColumnDataShape';
import { WBSMetadataShape } from 'wbs/dist/types/WBSMetadataShape';
import { getIsRowTitleValidAndMaxTitleLength } from '../../helpers/WBS/rowTitleHelpers';
import { WBSUnitsShape } from 'wbs/dist/packages/wbs/src/types/WBSUnitsShape';

export interface RowUIState {
  rowCanBeExpanded: boolean;
  isExpanded?: boolean;
  hidden: boolean;
  isSelected: boolean;
  isDeleting?: boolean;
  isRelatedToSelectedRow?: boolean;
  isLastRow: boolean;
  isTitleInvalid?: boolean;
  maxTitleLength?: number;
}

export type RowColumnState = Record<number, WBSColumnDataDataFields>;

export interface WBSDataRowShapeWithStates extends WBSDataRowShape {
  uiState: RowUIState;
  columnState: RowColumnState;
  originalValue?: {
    quantity: string,
    cost: string,
    inputCost: string,
    inputProjectCurrencyId: number | null,
    units: WBSUnitsShape
  };
}

const getMinRowLevel = (allRows: WBSDataRowShape[]): number => {

  let minLevel = 999;
  for (const { wbsCode } of allRows) {
    minLevel = Math.min(parseCodeNumbers(wbsCode).length, minLevel);
    if (minLevel === 1) {
      break;
    }
  }

  return minLevel;
};

export const getDetailLevel = (allRows: WBSDataRowShape[]): number => {

  const desiredDetailLevel = getDefaultDetailLevel(allRows);
  const minRowLevel = getMinRowLevel(allRows);

  return (minRowLevel > desiredDetailLevel) ? minRowLevel : desiredDetailLevel;
};

const getIsRowExpandable = (detailLevel: number, wbsCode: string, wbsCodeLookupToUse: WBSCodeLookupShape) => {

  let rowCanBeExpanded = false;

  const rowLevel = parseCodeNumbers(wbsCode).length;
  const childRows = getChildRows(wbsCodeLookupToUse, wbsCode);

  if (rowLevel >= detailLevel && childRows.length) {
    rowCanBeExpanded = true;
  }

  return rowCanBeExpanded;
};

const getDefaultDetailLevel = (allWbsDataRows: WBSDataRowShape[]) => {

  let levelToShow = 1;
  let closestNumRowsToMax = 0;
  const maxVisibleRows = 30;
  const levelsByNumRows: { [index: number]: number } = {};
  const levelsByTotalVisibleRows: { [index: number]: number } = {};

  for (const dataRow of allWbsDataRows) {
    if (dataRow.wbsCode === '') {
      continue;
    }

    const rowLevel = parseCodeNumbers(dataRow.wbsCode).length;
    if (!levelsByNumRows[rowLevel]) {
      levelsByNumRows[rowLevel] = 0;
    }

    levelsByNumRows[rowLevel]++;
  }

  // The reason we need to do this is because there may be only 3 rows for level 3, but the rows with levels less than
  // that are still visible, so we need to calculate those as well.
  for (const [level] of Object.entries(levelsByNumRows)) {
    const currentLevel = parseInt(level);
    let levelIterator = currentLevel;
    if (!levelsByTotalVisibleRows[currentLevel]) {
      levelsByTotalVisibleRows[currentLevel] = 0;
    }

    while (levelIterator > 0) {
      levelsByTotalVisibleRows[currentLevel] += levelsByNumRows[levelIterator];
      levelIterator--;
    }
  }

  for (const [level, totalVisibleRowsForLevel] of Object.entries(levelsByTotalVisibleRows)) {
    if (totalVisibleRowsForLevel < maxVisibleRows && totalVisibleRowsForLevel > closestNumRowsToMax) {
      closestNumRowsToMax = totalVisibleRowsForLevel;
      levelToShow = parseInt(level);
    }
  }

  return levelToShow;
};

export const calculateTitleUIState = (
  allRows: WBSDataRowShapeWithStates[],
  metadata: WBSMetadataShape | undefined = undefined,
  companyLimitations: { maxItemTitleLength: number | undefined } = { maxItemTitleLength: undefined }
) => {

  const output = allRows.map((row) => {

    const { isTitleValid, maxTitleLength } = getIsRowTitleValidAndMaxTitleLength(metadata, row, companyLimitations);

    return {
      ...row,
      uiState: {
        ...row.uiState,
        isTitleInvalid: !isTitleValid,
        maxTitleLength
      }
    };
  });

  return output;
};

export const updateDetailLevel = (allRows: WBSDataRowShapeWithStates[], detailLevel: number) => {

  const existWbsCodeLookup = createWbsLookup([], allRows).wbs.codeLookup;

  for (const row of allRows) {

    const rowCanBeExpanded = getIsRowExpandable(detailLevel, row.wbsCode, existWbsCodeLookup);
    row.uiState.rowCanBeExpanded = rowCanBeExpanded;
    row.uiState.isExpanded = rowCanBeExpanded ? false : undefined;
  }

  const allWBSCodes = allRows.map((row) => row.wbsCode);
  const collapsedWBSCodes = allRows.filter((row) => row.uiState.rowCanBeExpanded).map((row) => row.wbsCode);
  const hiddenWBSCodes = allWBSCodes.filter((wbsCode) => collapsedWBSCodes.find((collapsedCode) => wbsCode.startsWith(`${collapsedCode} `)));

  for (const row of allRows) {

    row.uiState.hidden = hiddenWBSCodes.includes(row.wbsCode);
  }
};

export const calculateWBSState = (
  newRows: WBSDataRowShape[],
  columnDataByColumnId: { [index: string]: WBSColumnDataShape[] } = {},
  metadata: WBSMetadataShape | undefined = undefined,
  companyLimitations: { maxItemTitleLength: number | undefined } = { maxItemTitleLength: undefined },
  oldRows: WBSDataRowShapeWithStates[]
): WBSDataRowShapeWithStates[] => {

  let withUIState = newRows.slice().sort((a, b) => {

    if (a.sortOrder === b.sortOrder) {
      return a.wbsCode.localeCompare(b.wbsCode);
    }

    return a.sortOrder - b.sortOrder;
  }).map((row) => {

    return {
      ...row,
      uiState: {
        rowCanBeExpanded: false,
        isExpanded: false
      }
    };
  });

  const columnDataByWbsColumnId: { [index: string]: { [index: string]: WBSColumnDataDataFields } } = {};
  for (const [columnId, columnData] of Object.entries(columnDataByColumnId)) {
    for (const { wbsDataId, data, rawData, setMultiplier, units } of columnData) {
      if (!columnDataByWbsColumnId[wbsDataId]) {
        columnDataByWbsColumnId[wbsDataId] = {};
      }

      columnDataByWbsColumnId[wbsDataId][columnId] = { data, rawData, setMultiplier, units };
    }
  }

  const lastRow = withUIState[withUIState.length - 1];

  withUIState = withUIState.map((row, idx) => {

    let clientSideGuid = row.clientSideGuid;
    const existingGuid = oldRows[idx]?.clientSideGuid;
    if (existingGuid) {
      // use existing clientSideGuid for this row instead of the new one from the server.
      clientSideGuid = existingGuid;
    }

    return {
      ...row,
      clientSideGuid,
      clientSidePredictionId: row.id!,
      uiState: {
        ...row.uiState,
        hidden: false,
        isSelected: false,
        isLastRow: row === lastRow
      },
      columnState: columnDataByWbsColumnId[row.id ?? -1] ?? {}
    };
  });

  return calculateTitleUIState(withUIState as WBSDataRowShapeWithStates[], metadata, companyLimitations);
};
