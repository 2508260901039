import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SectionSliceRootState } from '../stateTypes';
import { ResizingModuleInfo } from '../../components/Project/Section/Types';


const initialState: SectionSliceRootState = {
  resizingModuleInfo: null
};

export const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    reset: () => initialState,

    updateResizingModuleInfo: (state, action: PayloadAction<ResizingModuleInfo | null>) => {

      state.resizingModuleInfo = action.payload;
    }
  }
});

export const {
  reset,
  updateResizingModuleInfo
} = sectionSlice.actions;

export default sectionSlice.reducer;
