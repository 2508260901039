import { ReactComponent as Svg } from '../../assets/images/icon_cost_management_graph.svg';
import SvgIcon from '@material-ui/core/SvgIcon';


export default (props) => {

  return (
    <SvgIcon {...props} component={Svg} viewBox="0 0 1024 1024" />
  );
};
