import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import api from '../../../api';
import * as mainActions from '../../../redux/mainActions';
import store from '../../../redux/store';

import { LoginForm } from '../LoginForm/LoginForm';
import { ProjectRole } from '../../../types/enums/ProjectRole';
import { UserShape } from '../../../types/api/UserShape';
import { EnterToSubmit } from '../../../helpers/HandleKeyDown';
import { InvitationShape } from '../../../types/api/InvitationShape';
import { IsMatchingStringCaseInsensitive } from '../../../helpers/Compare';
import { GetInviteDetails } from '../../../helpers/GetInviteDetails';
import { SingleSignOn } from '../SingleSignOn/SingleSignOn';
import superagent from 'superagent';

interface getCanSubmitShape {
  email: string;
  displayForgotPasswordPrompt: boolean;
  password: string;
  shouldDisplayCreateNewUserPrompt: boolean;
  confirmPassword: string;
}

export const getCanSubmit = (
  {
    email,
    displayForgotPasswordPrompt,
    password,
    shouldDisplayCreateNewUserPrompt,
    confirmPassword
  }: getCanSubmitShape) => {

  return (
    !_.isEmpty(email) &&
    /(.+)@(.+){2,}\.(.+){2,}/.test(email) &&
    (displayForgotPasswordPrompt || !_.isEmpty(password)) &&
    (!shouldDisplayCreateNewUserPrompt || !_.isEmpty(confirmPassword))
  );
};

interface getFullNameShape {
  fullName: string;
}

export const getFullName = ({ fullName }: getFullNameShape) => (fullName || 'user');

export const getHasInviteDetails = ({ inviteDetails }: InviteDetails) => !_.isEmpty(inviteDetails);

interface getIsInvalidInviteShape {
  inviteToken: string | null;
  responseInviteDetails: InvitationShape | null;
}

export const getIsInvalidInvite = ({ inviteToken, responseInviteDetails }: getIsInvalidInviteShape) => {

  return Boolean(inviteToken && (responseInviteDetails === null));
};

interface getIsNotCompanyMemberShape {
  notCompanyMemberMessage: string;
  userMessage: string;
}

export const getIsNotCompanyMember = ({ notCompanyMemberMessage, userMessage }: getIsNotCompanyMemberShape) => {

  return (userMessage === notCompanyMemberMessage);
};

export interface Inputs {
  email: string;
  fullName: string;
  password: string;
  confirmPassword: string;
}

interface AuthUrls {
  ssoEnabled: boolean;
  linkedInAuthUrl: string | null;
  googleAuthUrl: string | null;
}

interface InviteDetails {
  [key: string]: any // TODO: flesh this out
}

interface LoginProps {
  loadSsoUrls?: boolean;
}

const notCompanyMemberMessage = 'Not a member of invited company';


const Login = ({ loadSsoUrls = false }: LoginProps) => {

  const getOriginalInputs = useCallback((): Inputs => {

    return {
      email: '',
      fullName: '',
      password: '',
      confirmPassword: ''
    };
  }, []);

  const [sso, setSso] = useState<boolean>(false);
  const [authUrls, setAuthUrls] = useState<AuthUrls>({ linkedInAuthUrl: null, googleAuthUrl: null, ssoEnabled: false });
  const [displayForgotPasswordPrompt, setDisplayForgotPasswordPrompt] = useState(false);
  const [shouldDisplayCreateNewUserPrompt, setShouldDisplayCreateNewUserPrompt] = useState(false);
  const [inputs, setInputs] = useState(getOriginalInputs());
  const [inviteDetails, setInviteDetails] = useState<InviteDetails>({});
  const [userMessage, setUserMessage] = useState('');
  const [userMessageIsError, setUserMessageIsError] = useState(false);
  const [loggedInUserDetails, setLoggedInUserDetails] = useState<UserShape | null>(null);

  const history = useHistory();

  useEffect(() => {

    GetInviteDetails({
      setInviteDetails,
      setUserMessage,
      setUserMessageIsError
    });

    const urlParams = new URLSearchParams(window.location.search);
    const invalidOAuth = urlParams.get('invalidOAuth');
    if (invalidOAuth === 'true') {
      setUserMessage('Sorry, it seems like there is no account associated with this email address.');
      setUserMessageIsError(true);
    }
  }, []);

  useEffect(() => {

    if (loadSsoUrls) {
      superagent.get(process.env.REACT_APP_API_URL + `/auth/urls`).then((response) => {

        const body = response.body as AuthUrls;
        setAuthUrls(body);
      });
    }
  }, [setAuthUrls, loadSsoUrls]);

  const handleInputChange = useCallback((e: React.ChangeEvent<{ id: any, value: any }>) => {

    e.preventDefault();

    const currentInputs: Inputs = { ...inputs };
    const inputType: keyof Inputs = e.target.id;
    currentInputs[inputType] = e.target.value;
    setInputs(currentInputs);
    setUserMessage('');
    setUserMessageIsError(false);
  }, [inputs]);

  const handleToggleCreateNewUserPrompt = useCallback((evt: React.MouseEvent) => {

    if (evt) {
      evt.preventDefault();
    }

    setShouldDisplayCreateNewUserPrompt(!shouldDisplayCreateNewUserPrompt);
    setInputs(getOriginalInputs());
    setUserMessage('');
    setUserMessageIsError(false);
  }, [shouldDisplayCreateNewUserPrompt, getOriginalInputs]);

  const handleToggleForgotPasswordPrompt = useCallback((evt: React.MouseEvent) => {

    if (evt) {
      evt.preventDefault();
    }

    setDisplayForgotPasswordPrompt(!displayForgotPasswordPrompt);
    setInputs(getOriginalInputs());
    setUserMessage('');
    setUserMessageIsError(false);
  }, [displayForgotPasswordPrompt, getOriginalInputs]);

  const canSubmit = getCanSubmit({
    email: inputs.email,
    confirmPassword: inputs.confirmPassword,
    displayForgotPasswordPrompt,
    password: inputs.password,
    shouldDisplayCreateNewUserPrompt
  });
  const displayName = getFullName({ fullName: loggedInUserDetails?.fullName || '' });
  const hasInviteDetails = getHasInviteDetails({ inviteDetails });
  const isNotCompanyMember = getIsNotCompanyMember({ notCompanyMemberMessage, userMessage });

  const emailDomainMatches = IsMatchingStringCaseInsensitive(
    {
      firstString: inputs.email.split('@')[1],
      secondString: inviteDetails?.invitedUser?.email?.split('@')[1]
    }
  );

  // TODO: flesh out this any
  const afterLogin = useCallback(async (result: any) => {

    if (result.err) {
      setUserMessage('Invalid email or password');
      setUserMessageIsError(true);
    }
    else {
      // Linked from invitation
      const loggedInUser: UserShape = result.res.body;
      store.dispatch(mainActions.setIsLoggedIn(true));
      if (!_.isEmpty(inviteDetails)) {
        const { invitedCompanyId, projectId, bid, inviteToken } = inviteDetails;
        store.dispatch(mainActions.setLoggedInUserDetails({ ...loggedInUser }));
        if (invitedCompanyId === result.res.body.primaryCompanyId) {
          // User logged in as member of invited company: Assign to project then send to bid
          await api.Project.AssignProjectUsers(
            projectId,
            [{ userId: loggedInUser.id, role: ProjectRole.Manager }],
            inviteToken
          );
          history.push(`/projects/${projectId}?scrollToSectionId=${bid.sectionId}`);
        }
        else {
          // User logged in as member of other company
          setUserMessage(notCompanyMemberMessage);
          setUserMessageIsError(true);
          setLoggedInUserDetails(result.res.body);
        }
      }
      else {
        if (window.location.pathname.toLowerCase().startsWith('/login')) {
          window.location.href = '/';
        }
      }
    }
  }, [history, inviteDetails]);

  const handleSubmitLogin = useCallback((e: React.MouseEvent | undefined) => {

    if (e) {
      e.preventDefault();
    }

    const email = inputs.email;
    const password = inputs.password;
    api.User.LogIn(email, password).then(afterLogin);
  }, [afterLogin, inputs]);

  // TODO: flesh out this any
  const afterCreateUser = useCallback( (result: any) => {

    if (result.err) {
      console.error(result.err);
      setUserMessage('Error creating new user');
      setUserMessageIsError(true);
    }
    else {
      const { projectId, sectionId } = inviteDetails;
      store.dispatch(mainActions.setIsLoggedIn(true));
      store.dispatch(mainActions.setLoggedInUserDetails({ ...result.res.body }));
      setTimeout(() => {

        history.push(`/projects/${projectId}?scrollToSectionId=${sectionId}`);
      }, 500);
    }
  }, [history, inviteDetails]);

  const handleSubmitCreateUser = useCallback((e: React.MouseEvent) => {

    if (e) {
      e.preventDefault();
    }

    const { email, fullName, password, confirmPassword } = inputs;
    if (password !== confirmPassword) {
      setUserMessage('Passwords must match');
      setUserMessageIsError(true);
    }
    else if (!emailDomainMatches) {
      setUserMessage(`Email domain must match invited company's`);
      setUserMessageIsError(true);
    }
    else {
      api.User.Create(
        fullName,
        api.User.UserTypes.User,
        email,
        password,
        '',
        api.User.ContactTypes.Email,
        '',
        inviteDetails.invitedCompanyId,
        inviteDetails.id
      ).then(afterCreateUser);
    }
  }, [afterCreateUser, emailDomainMatches, inputs, inviteDetails]);

  const afterSubmitForgotPassword = useCallback(() => {

    setUserMessage(`An email has been sent to ${inputs.email} with a link to reset your password.`);
    setUserMessageIsError(false);
    setDisplayForgotPasswordPrompt(false);
    setInputs(getOriginalInputs());
  },[getOriginalInputs, inputs]);

  const handleSubmitForgotPassword = useCallback((evt: React.MouseEvent | undefined) => {

    if (evt) {
      evt.preventDefault();
    }

    const email = inputs.email;
    api.User.ForgotPassword(email).then(afterSubmitForgotPassword);
  }, [afterSubmitForgotPassword, inputs]);

  const handleKeyDown = useCallback((evt) => {

    return EnterToSubmit(
      {
        evt,
        canSubmit,
        useOptionalCallback: !displayForgotPasswordPrompt,
        callback: handleSubmitForgotPassword,
        optionalCallback: handleSubmitLogin
      });
  }, [canSubmit, displayForgotPasswordPrompt, handleSubmitForgotPassword, handleSubmitLogin]);

  return useMemo(() => {

    return (
      <Container maxWidth="xs">
        <Paper>
          {
            sso ?
              <SingleSignOn />
              :
              <div>
                <LoginForm
                  displayCreateNewUserPrompt={shouldDisplayCreateNewUserPrompt}
                  displayForgotPasswordPrompt={displayForgotPasswordPrompt}
                  canSubmit={canSubmit}
                  fullName={displayName}
                  hasInviteDetails={hasInviteDetails}
                  inputs={inputs}
                  isNotCompanyMember={isNotCompanyMember}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onSubmitForgotPassword={handleSubmitForgotPassword}
                  onSubmitLogin={handleSubmitLogin}
                  onSubmitCreateUser={handleSubmitCreateUser}
                  onToggleCreateNewUserPrompt={handleToggleCreateNewUserPrompt}
                  onToggleForgotPasswordPrompt={handleToggleForgotPasswordPrompt}
                  shouldDisplayCreateNewUserPrompt={shouldDisplayCreateNewUserPrompt}
                  userMessage={userMessage}
                  userMessageIsError={userMessageIsError}
                />
              </div>
          }
        </Paper>

        {
          authUrls.ssoEnabled && <div style={{ textAlign: 'center', marginTop: 12 }}>
            <NavLink
              to=""
              onClick={() => setSso((prev) => !prev)}
              color="secondary"
              data-cy="login.sso-link"
            >
              {sso ? 'Sign in without SSO' : 'Sign in with Single Sign On'}
            </NavLink>
          </div>
        }
        {
          !sso && authUrls.googleAuthUrl && <div style={{ textAlign: 'center', marginTop: 12 }}>
            <button onClick={() => {

              window.location.href = authUrls.googleAuthUrl!;
            }}>Sign in with Google
            </button>
          </div>
        }
        {
          !sso && authUrls.linkedInAuthUrl && <div style={{ textAlign: 'center', marginTop: 12 }}>
            <button onClick={() => {

              window.location.href = authUrls.linkedInAuthUrl!;
            }}>
              Sign in with LinkedIn
            </button>
          </div>
        }
      </Container>
    );
  }, [
    authUrls,
    displayForgotPasswordPrompt,
    canSubmit,
    displayName,
    hasInviteDetails,
    isNotCompanyMember,
    handleInputChange,
    handleKeyDown,
    handleSubmitCreateUser,
    handleSubmitForgotPassword,
    handleSubmitLogin,
    handleToggleCreateNewUserPrompt,
    handleToggleForgotPasswordPrompt,
    inputs,
    shouldDisplayCreateNewUserPrompt,
    sso,
    userMessage,
    userMessageIsError
  ]);
};

export default Login;
