"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultUnits = void 0;
var getDefaultUnits = function getDefaultUnits(unitType) {
  if (unitType === 'wbs') {
    return {
      quantity: ''
    };
  } else if (unitType === 'columnData') {
    return {
      setMultiplier: ''
    };
  }
};
exports.getDefaultUnits = getDefaultUnits;