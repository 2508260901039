import React, { Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { createTheme, makeStyles, ThemeOptions, ThemeProvider, useTheme } from '@material-ui/core/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { EmptyFunction } from '../../consts/EmptyFunction';

import DateFnsUtils from '@date-io/date-fns';

interface DatePickerThemeOptions extends Omit<ThemeOptions, 'overrides'> {
  overrides: any;
}

const useStyles = makeStyles((theme: any) => ({
  actionButton: {
    color: theme.palette.app.innerBorder
  },
  calendarIcon: {
    position: 'absolute',
    backgroundColor: theme.palette.table.light,
    right: 0,
    top: '18px',
    color: 'rgba(0, 0, 0, 0.70)',
    zIndex: 500,
    cursor: 'pointer'
  },
  dateError: {
    color: theme.palette.app.errorText
  },
  dialogContent: {
    marginTop: '16px',
    position: 'relative'
  },
  fixedPosition: {
    width: 0,
    position: 'fixed',
    left: '50%',
    top: '50%'
  },
  titleBackground: {
    background: theme.palette.app.innerBorder
  },
  title: {
    color: '#fff',
    fontSize: '1.6rem',
    fontWeight: 500
  },
  test: {
    position: 'fixed',
    left: '50%'
  },
  textField: {
    width: '100%',

    '& label': {
      color: theme.palette.app.innerBorder
    },

    '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
      display: 'none',
      '-webkit-appearance': 'none'
    },

    '& input[type="datetime-local"]::-webkit-clear-button': {
      display: 'none',
      '-webkit-appearance': 'none'
    }
  }
}));

// Without this, native date inputs allow us to type years longer than 4 digits, e.g. 202222
const MAX_DATE = '9999-01-01T00:00';

// Use this to add back our offset before sending to server
const returnAsISOString = (date: Date | string) => new Date(date).toISOString();

const getCorrectFormat = (date: Date | string) => {

  if (!date) {
    return '';
  }

  // This is used to remove offset and format date to be read by material-ui date pickers
  let newDate = new Date(date);
  newDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
  const ISODate = newDate.toISOString();
  return ISODate.slice(0, ISODate.lastIndexOf(':'));
};

const getDefaultDate = () => {

  // Default date to 2:00 P.M.
  const date = new Date();
  date.setHours(14);
  date.setMinutes(0, 0);
  return getCorrectFormat(date);
};

interface Props {
  dataCy?: string;
  defaultDate?: Date | string;
  format?: string;
  isOpenDefault?: boolean;
  isHidden?: boolean;
  label?: string;
  onChange: (value: string | Date) => void;
  onClose?: (evt?: MouseEvent | undefined) => void;
  shouldDelayOnChangeUntilConfirm?: boolean;
  styles?: React.CSSProperties;
  maxDate?: Date | string;
  minDate?: Date | string;
}

const DateTimeDialog = (props: Props) => {

  const {
    dataCy = '',
    defaultDate = getDefaultDate(),
    format = '',
    isOpenDefault = false,
    styles = {},
    isHidden,
    label,
    onChange = EmptyFunction,
    onClose = EmptyFunction,
    shouldDelayOnChangeUntilConfirm,
    maxDate = '',
    minDate = ''
  } = props;

  const [isMainDatePickerOpen, setIsMainDatePickerOpen] = useState(isOpenDefault);
  const [isSubDatePickerOpen, setIsSubDatePickerOpen] = useState(false);
  const [date, setDate] = useState(getCorrectFormat(defaultDate));
  const [errorMessage, setErrorMessage] = useState('');

  const classes = useStyles();
  const theme: any = useTheme();

  useEffect(() => {

    if (isOpenDefault && !isMainDatePickerOpen) {
      setIsMainDatePickerOpen(true);
    }
  }, [isMainDatePickerOpen, isOpenDefault]);

  useEffect(() => {

    // If null is passed for the defaultDate prop, 'getDefaultDate' will not be called so we check here on mount
    if (!defaultDate && !date) {
      setDate(getDefaultDate());
    }
  }, [defaultDate, date]);

  const getIsDateError = useCallback(() => {

    if (!minDate && !maxDate) {
      return false;
    }

    let isError = false;
    const currentDateTimeInput = new Date(date).getTime();
    if (maxDate) {
      if (new Date(maxDate).getTime() < currentDateTimeInput) {
        isError = true;
        setErrorMessage('Maximum Date Surpassed');
      }
    }

    if (minDate) {
      if (new Date(minDate).getTime() > currentDateTimeInput) {
        isError = true;
        setErrorMessage('Minimum Date Surpassed');
      }
    }

    return isError;
  }, [date, maxDate, minDate]);

  const handleIconClick = useCallback((evt: MouseEvent) => {

    if (evt) {
      evt.preventDefault();
    }

    setIsSubDatePickerOpen(true);
  }, []);

  const handleChange = useCallback((changedDate: Date | string) => onChange(returnAsISOString(changedDate)), [onChange]);

  const handleCloseDialog = useCallback(() => {

    setIsMainDatePickerOpen(false);
    setDate(getCorrectFormat(defaultDate));
    onClose();
  }, [defaultDate, onClose]);

  const handleSubmit = useCallback(() => {

    if (getIsDateError()) {
      return;
    }

    setIsMainDatePickerOpen(false);
    onClose();
    if (shouldDelayOnChangeUntilConfirm) {
      handleChange(date);
    }
  }, [date, getIsDateError, shouldDelayOnChangeUntilConfirm, onClose, handleChange]);

  const handleChangeMainDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    // Main date does not need to be converted because this picker already provides the correct format
    if (!e.target.value) {
      return;
    }

    setDate(e.target.value);
    if (!shouldDelayOnChangeUntilConfirm) {
      handleChange(e.target.value);
    }
  }, [handleChange, shouldDelayOnChangeUntilConfirm]);

  const handleChangeSubDate = useCallback((newDate: Date | null) => {

    if (newDate === null) {
      return;
    }

    // Sub date needs to be converted to the ISO date because this picker provides a different format
    const formattedDate = getCorrectFormat(newDate);
    setDate(formattedDate);
    if (!shouldDelayOnChangeUntilConfirm) {
      handleChange(formattedDate);
    }
  }, [handleChange, shouldDelayOnChangeUntilConfirm]);

  const muiDatePickerTheme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.palette.app.innerBorder
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: theme.palette.sidebar.activeIcon,
          '&:hover': {
            backgroundColor: theme.palette.sidebar.activeIcon,
            opacity: 0.8
          }
        }
      },
      MuiTabs: {
        flexContainer: {
          backgroundColor: theme.palette.app.innerBorder
        }
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: theme.palette.sidebar.activeIcon
        }
      },
      MuiPickersClockNumber: {
        clockNumberSelected: {
          backgroundColor: theme.palette.sidebar.activeIcon
        }
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: theme.palette.sidebar.activeIcon
        },
        thumb: {
          backgroundColor: theme.palette.app.background,
          borderColor: theme.palette.sidebar.activeIcon
        }
      }
    }
  } as DatePickerThemeOptions);

  return useMemo(() => (

    <Fragment>
      <Dialog open={isMainDatePickerOpen} onClose={handleCloseDialog} fullWidth={true} maxWidth="xs">
        <DialogTitle className={classes.titleBackground}>
          <Typography className={classes.title}>Enter Date And Time</Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            <EventIcon data-cy='date-time-dialog.icon' className={classes.calendarIcon} onClick={handleIconClick}/>
            <TextField
              error={Boolean(errorMessage)}
              className={classes.textField}
              InputProps={{ inputProps: { max: MAX_DATE, 'data-cy': 'date-time-dialog.input' } }}
              label='Enter date and time'
              onChange={handleChangeMainDate}
              type='datetime-local'
              value={date}
            />
            <Typography className={classes.dateError}>{errorMessage}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button data-cy='date-time-dialog.cancel' onClick={handleCloseDialog} className={classes.actionButton}>
            Cancel
          </Button>
          <Button data-cy='date-time-dialog.confirm' onClick={handleSubmit} className={classes.actionButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <div onClick={() => setIsMainDatePickerOpen(true)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} key="2">
          <ThemeProvider theme={muiDatePickerTheme}>
            <DateTimePicker
              animateYearScrolling
              className={isHidden ? classes.fixedPosition : ''}
              data-cy={dataCy}
              format={format}
              label={label}
              maxDateMessage=''
              minDateMessage=''
              maxDate={getCorrectFormat(maxDate) || undefined}
              minDate={getCorrectFormat(minDate) || undefined}
              open={isSubDatePickerOpen}
              onChange={handleChangeSubDate}
              onClose={() => setIsSubDatePickerOpen(false)}
              style={{ ...styles }}
              variant='inline'
              value={date}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    </Fragment>
  ), [
    classes,
    date,
    dataCy,
    errorMessage,
    format,
    handleIconClick,
    muiDatePickerTheme,
    handleCloseDialog,
    handleChangeMainDate,
    handleChangeSubDate,
    handleSubmit,
    isSubDatePickerOpen,
    isMainDatePickerOpen,
    isHidden,
    label,
    minDate,
    maxDate,
    styles
  ]);
};

export default DateTimeDialog;
