import _ from 'lodash';
import request from 'superagent';

import store from '../redux/store';
import * as mainActions from '../redux/mainActions';

let CSRF_TOKEN = '';

const isCSRFError = (responseObj) => responseObj?.body?.message === 'isCSRF';

export const refreshCsrfToken = async () => {

  const tokenResult = await request
    .get(process.env.REACT_APP_API_URL + '/user/csrf')
    .withCredentials();

  if (tokenResult.err) {
    throw tokenResult.err;
  }

  CSRF_TOKEN = tokenResult.body.token;
};

export const getCsrfToken = async () => {

  if (CSRF_TOKEN === '') {
    await refreshCsrfToken();
  }

  return CSRF_TOKEN;
};

export default {

  ProcessRequest: async function (apiRequest, options = {}) {

    const mergedOptions = {
      affectsGrandTotal: false,
      excludeCredentials: false,
      redirectOnError: false,
      skipCsrf: false,
      skipSetIsLoading: false,
      useJsonRequestType: true,
      isRetry: false,
      shouldNavigateToRootOnUnauthorized: true,
      ...options
    };

    if (!mergedOptions.skipSetIsLoading) {
      store.dispatch(mainActions.setIsLoading(true));
      if (mergedOptions.affectsGrandTotal) {
        store.dispatch(mainActions.setIsLoadingGrandTotal(true));
      }
    }

    if (apiRequest.method !== 'GET') {
      store.dispatch(mainActions.setDidMakeApiChange());
    }

    const response = {};
    let messageVariant = 'info';
    let responseObject = {};

    try {
      if (!mergedOptions.skipCsrf && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(apiRequest.method)) {
        if (CSRF_TOKEN === '') {
          await refreshCsrfToken();
        }

        apiRequest.set('X-CSRF-Token', CSRF_TOKEN);
      }

      if (mergedOptions.useJsonRequestType) {
        apiRequest.type('json');
      }

      if (!mergedOptions.excludeCredentials) {
        apiRequest.withCredentials();
      }

      if (mergedOptions.onProgress) {
        apiRequest.on('progress', mergedOptions.onProgress);
      }

      response.res = await apiRequest;
      response.err = null;

      responseObject = response.res;

      if (responseObject?.body?.status === 'Server Initializing') {
        window.location.replace('/maintenance');
        return response;
      }
    }
    catch (e) {
      const unauthorizedStatus = 401;
      let shouldShowErrorMsg = !isCSRFError(e.response);

      if (_.get(e, 'response.statusCode') === unauthorizedStatus) {

        shouldShowErrorMsg = false;
        if (!mergedOptions.skipSetIsLoading) {
          if (mergedOptions.redirectOnError) {
            store.dispatch(mainActions.setRedirectOnError(true));
          }
          else if (mergedOptions.shouldNavigateToRootOnUnauthorized) {
            sessionStorage.setItem('navigateToRootIfLoggedOut', 'true');
            store.dispatch(mainActions.setIsLoggedIn(false));
          }
        }
      }

      if (shouldShowErrorMsg) {
        store.dispatch(mainActions.setShowError(true));
      }

      response.res = null;
      response.err = e;

      responseObject = response.err.response;
      messageVariant = 'error';
    }

    if (responseObject && responseObject.body && !_.isEmpty(responseObject.body.message)) {
      // TODO: display errors
      console.log(messageVariant);
    }

    // delay setting loading to false to give other api calls a chance to start running and set loading to true.
    // otherwise the loading state is constantly being turned off and on, and the loader in the gui keeps restarting.
    _.delay(() => {

      if (!mergedOptions.skipSetIsLoading) {
        store.dispatch(mainActions.setIsLoading(false));
        if (mergedOptions.affectsGrandTotal) {
          store.dispatch(mainActions.setIsLoadingGrandTotal(false));
        }
      }
    }, 200);

    if (!mergedOptions.isRetry && response?.err?.status === 403 && isCSRFError(responseObject)) {
      CSRF_TOKEN = '';

      // reset apiRequest so it can be called again
      delete apiRequest.xhr;
      delete apiRequest._callback;
      delete apiRequest._endCalled;
      delete apiRequest._fullfilledPromise;

      return await this.ProcessRequest(apiRequest, { ...mergedOptions, isRetry: true });
    }

    return response;
  }

};
