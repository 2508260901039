import Acknowledgement from './acknowledgement';
import Activity from './activity';
import Company from './company';
import General from './general';
import Invitation from './invitation';
import Message from './message';
import Note from './note';
import DataImport from './dataImport';
import Project from './project';
import Qualification from './qualification';
import Realtime from './realtime';
import Search from './search';
import User from './user';

export default {

  Acknowledgement,
  Activity,
  Company,
  DataImport,
  General,
  Invitation,
  Message,
  Note,
  Project,
  Qualification,
  Realtime,
  Search,
  User

};
