import { getCsrfToken, refreshCsrfToken } from '../../../api/common';
import superagent from 'superagent';
import { WBSCodeCustomizationShape } from 'wbs/dist/types/WBSCodeCustomizationShape';


export const updateWbsRows = async (array: any[]) => {

  const wbsDataArray = array.filter((r) => r.id > 0);

  if (wbsDataArray.length === 0) {
    return;
  }

  // it's possible that some of the calc functions have populated the cost field, but cost is not a valid field
  // to send to the server (inputCost is what the server expects).
  // TODO: If we ever switch back to the redux toolkit updateWbsRows function, we need to be sure to still use this.
  for (const change of array) {
    delete change.cost;

    if (change.resourceOverrides) {
      for (const override of change.resourceOverrides) {
        delete override.cost;
      }
    }
  }

  // Fix for CSRF failing when api is called inside of wbsDataTableSlice
  if (sessionStorage.getItem('needsCsrfRefreshNextRequest') === 'true') {
    sessionStorage.removeItem('needsCsrfRefreshNextRequest');
    await refreshCsrfToken();
  }

  const csrfToken = await getCsrfToken();
  const request = superagent.patch(process.env.REACT_APP_API_URL + '/project/wbs/update-rows')
    .withCredentials()
    .send({ wbsDataArray });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};

export const addWbsRows = async (wbsDataArray: any[]) => {

  const csrfToken = await getCsrfToken();
  const request = superagent.post(process.env.REACT_APP_API_URL + '/project/wbs/add-row')
    .withCredentials()
    .send({ wbsDataArray });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};

export const defineResources = async (projectId: number, rowSplits: any) => {

  const csrfToken = await getCsrfToken();
  const request = superagent.patch(process.env.REACT_APP_API_URL + '/project/wbs/set-define-resources')
    .withCredentials()
    .send({ projectId, rowSplits });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};

export const getWbsData = async (projectId: number, isResources: boolean) => {

  const csrfToken = await getCsrfToken();
  const request = superagent.get(process.env.REACT_APP_API_URL + '/project/wbs/get-data')
    .withCredentials()
    .query({ projectId, isResources });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};

export const updateWbsMetadata = async (projectId: number, metadata: any) => {

  const csrfToken = await getCsrfToken();
  const request = superagent.patch(process.env.REACT_APP_API_URL + '/project/wbs/update-metadata')
    .withCredentials()
    .send({ projectId, ...metadata });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};

export const addWBSCodeCustomizations = async (wbsCodeCustomizations: WBSCodeCustomizationShape[]) => {

  if (wbsCodeCustomizations.length === 0) {
    return;
  }

  const csrfToken = await getCsrfToken();
  const request = superagent.post(process.env.REACT_APP_API_URL + '/project/wbs/add-wbs-code-customizations')
    .withCredentials()
    .send({ projectId: wbsCodeCustomizations[0].projectId, data: wbsCodeCustomizations.map((row) => ({ level: row.level, value: row.value })) });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};

export const deleteWbsRows = async (ids: number[]) => {

  const csrfToken = await getCsrfToken();
  const request = superagent.delete(process.env.REACT_APP_API_URL + '/project/wbs/delete-rows')
    .withCredentials()
    .send({ ids });

  return new Promise((resolve) => {

    request.set('X-CSRF-Token', csrfToken).then(({ body }) => resolve(body));
  });
};
