import { Fragment, ChangeEvent, ReactElement, useMemo, useCallback, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import api from '../../api';
import ListSorting from '../../helpers/ListSorting';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

import { AddButton } from '../Shared/Buttons/AddButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';

import { AppTheme } from '../../theme-relay';
import { ProjectShape } from '../../types/api/ProjectShape';
import { ProjectLabelShape } from '../../types/api/ProjectLabelShape';
import { UserShape } from '../../types/api/UserShape';
import { getCompanyPermissions } from '../../helpers/getCompanyPermissions';

const useStyles = makeStyles((theme: AppTheme) => ({
  addLabelField: {
    verticalAlign: 'top',
    marginRight: 'auto',
    marginLeft: '4px',
    marginTop: '4px',
    width: '160px'
  },
  companyLabels: {
    margin: '4px',
    '&:hover': {
      backgroundColor: theme.palette.labelDisplay.hoverSelect
    }
  },
  companyLabelsTitle: {
    textAlign: 'center'
  },
  includedLabels: {
    backgroundColor: theme.palette.labelDisplay.selected,
    '&:hover': {
      backgroundColor: theme.palette.labelDisplay.hoverDeselect
    },
    margin: '4px'
  },
  paper: {
    position: 'absolute',
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid ' + theme.palette.labelDisplay.outlineColor,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column'
  }
}));

interface Props {
  allLabels: ProjectLabelShape[],
  forCompanyId?: number,
  isForNewProject?: boolean,
  onLabelsUpdated: () => void,
  onModifyProjectLabels?: (label: ProjectLabelShape, remove: boolean) => void,
  project?: ProjectShape,
  projectLabels: ProjectLabelShape[]
}

const Labels = (props: Props) => {

  const {
    allLabels,
    forCompanyId,
    isForNewProject = false,
    onLabelsUpdated,
    onModifyProjectLabels,
    project,
    projectLabels
  } = props;

  const classes = useStyles();
  const loggedInUserDetails: UserShape = useSelector((state: RootStateOrAny) => state.ephemeralState.loggedInUserDetails);
  const companyPermissions = getCompanyPermissions(loggedInUserDetails);

  const [addLabelValue, setAddLabelValue] = useState('');
  const [addLabelError, setAddLabelError] = useState<string | null>(null);
  const [labelToDelete, setLabelToDelete] = useState<ProjectLabelShape | null>(null);

  const currentLabelIds = useMemo(() => {

    if (!project && !isForNewProject) {
      return [];
    }

    return projectLabels.map((label) => label.id);
  }, [isForNewProject, project, projectLabels]);

  const handleAssignLabelToProject = useCallback((e: { currentTarget: { value: string } }) => {

    const labelId = parseInt(e.currentTarget.value);
    const hasLabelAlready = Boolean(_.find(projectLabels, { id: labelId }));

    if (!project || isForNewProject) {
      const label = allLabels.find((l) => l.id === labelId);
      if (label && onModifyProjectLabels) {
        onModifyProjectLabels(label, hasLabelAlready);
      }

      return;
    }

    // checks for a labels existence, if it doesn't exist already we add it
    const endpoint = (hasLabelAlready) ? api.Company.RemoveProjectLabel : api.Company.AssignLabelToProject;
    endpoint(labelId, project.id).then(onLabelsUpdated);
  }, [allLabels, isForNewProject, onLabelsUpdated, onModifyProjectLabels, project, projectLabels]);

  const handleConfirmDeleteLabel = useCallback(() => {

    api.Company.DeleteProjectLabel(labelToDelete?.id).then(onLabelsUpdated);
    setLabelToDelete(null);
  }, [onLabelsUpdated, labelToDelete]);

  const handleDeleteLabelClick = useCallback((label: ProjectLabelShape) => {

    setLabelToDelete(label);
  }, []);

  const checkValidLabel = useCallback((newValue: string) => {

    if (allLabels.some((label) => label.label.localeCompare(newValue, 'en', { sensitivity: 'base' }) === 0)) {
      setAddLabelError('Label already exists');
    }
    else {
      setAddLabelError(null);
    }
  }, [allLabels]);

  const handleAddLabelChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    const { value } = event.target;
    currentLabelIds.push(parseInt(value));
    setAddLabelValue(value);
    checkValidLabel(value);
  }, [checkValidLabel, currentLabelIds]);

  const handleSubmitNewLabel = useCallback(async () => {

    if (addLabelValue && !addLabelError) {
      const addLabelResponse =
        await api.Company.AddProjectLabel(forCompanyId || loggedInUserDetails.primaryCompanyId, addLabelValue);
      const newLabel = addLabelResponse.res.body;
      onLabelsUpdated();
      setAddLabelValue('');
      if (project) {
        handleAssignLabelToProject({ currentTarget: { value: newLabel.id } });
      }
    }
  }, [
    addLabelError,
    addLabelValue,
    forCompanyId,
    handleAssignLabelToProject,
    loggedInUserDetails.primaryCompanyId,
    onLabelsUpdated,
    project
  ]);

  const renderAddLabelField = useMemo(() => {

    const canAddLabel = (project) ?
      companyPermissions?.ProjectsPage?.CreateLabel :
      companyPermissions?.CompanySettings?.AddDeleteProjectLabel;

    if (!canAddLabel) {
      return null;
    }

    return (
      <TextField
        className={classes.addLabelField}
        data-cy="project-list.assign-project-modal.new-label-field"
        error={!!addLabelError}
        helperText={addLabelError}
        InputProps={{
          endAdornment: (
            <AddButton
              onClick={handleSubmitNewLabel}
            />
          )
        }}
        label="Add Label"
        onChange={handleAddLabelChange}
        onKeyPress={(evt) => {

          if (evt.key === 'Enter' && addLabelValue && !addLabelError) {
            evt.preventDefault();
            handleSubmitNewLabel();
          }
        }}
        size="small"
        value={addLabelValue}
        variant="outlined"
      />
    );
  }, [addLabelError, addLabelValue, classes, companyPermissions, handleAddLabelChange, handleSubmitNewLabel, project]);

  const returnCompanyLabels = useMemo(() => {

    const labelElements: ReactElement[] = [];
    const sortedLabels = allLabels.sort(ListSorting.sortAlphaCaseInsensitive);

    _.each(sortedLabels, (label) => {

      labelElements.push(
        <Button
          key={label.id}
          value={label.id}
          onClick={(isForNewProject || project) ? handleAssignLabelToProject : () => handleDeleteLabelClick(label)}
          startIcon={(isForNewProject || project) ? null : <DeleteIcon />}
          color="secondary"
          variant="contained"
          className={currentLabelIds.includes(label.id) ? classes.includedLabels : classes.companyLabels}
          data-cy="project-list.company-labels-to-assign"
        >
          {label.label}
        </Button>
      );
    });

    return labelElements;
  }, [allLabels, classes, currentLabelIds, handleAssignLabelToProject, handleDeleteLabelClick, isForNewProject, project]);

  return (
    <Fragment>
      {returnCompanyLabels}
      {renderAddLabelField}
      <ConfirmDialog
        cancelLabel="Cancel"
        confirmLabel="Delete Label"
        description="Are you sure you want to delete the label?"
        handleCancel={() => setLabelToDelete(null)}
        handleConfirm={handleConfirmDeleteLabel}
        open={!!labelToDelete}
        title={`Delete Label: ${labelToDelete?.label}`}
      />
    </Fragment>
  );
};

export default Labels;
