import React, { Fragment, ReactElement, useMemo } from 'react';
import { Link as BrowserLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import api from '../../api';
import { makeDivisionDisplayName, makeSectionDisplayName } from '../../helpers/DataToDisplay';
import SmallAvatar from '../Icons/SmallAvatar';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { AppTheme } from '../../theme-relay';
import { CustShape } from '../../types/api/CustShape';
import { DummyDivisionShape } from '../../types/api/DummyDivisionShape';
import { DummySectionShape } from '../../types/api/DummySectionShape';
import { ProjectPermissionInterface } from '../../PermissionInterface';
import { ActivityLogCategory } from '../../types/enums/ActivityLogCategory';


const useStyles = makeStyles((theme: AppTheme) => ({
  acceptedStatus: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: theme.palette.bidStatus.starred.accent,
    padding: '2px',

    '&.declined': {
      color: theme.palette.response.warningHover
    }
  },
  button: {
    marginRight: theme.spacing(1)
  },
  controls: {
    marginTop: '3px'
  },
  hasActivityIcon: {
    color: theme.palette.app.activityIcon,
    position: 'absolute',
    height: '30px',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '-8px'
  },
  listItem: {
    position: 'relative'
  },
  numDuplicatesText: {
    color: theme.palette.app.greyedOutText
  }
}));

interface Props {
  activityProjectId: number;
  category: ActivityLogCategory;
  cust?: CustShape;
  divisionId: number;
  divisionLabel: string;
  divisionName: string;
  divisionNumber: string;
  fromUserFullName: string;
  fromUserCompanyId: number;
  inviteId: number;
  isAccepted: boolean;
  isForCurrentUser: boolean;
  isForInvitedCompany: boolean;
  isFromUser: boolean;
  numDuplicates?: number;
  onDeleteEstimatorInvite?: () => void;
  onRespondToEstimatorInvite?: () => void;
  projectName: string;
  permissions: Partial<ProjectPermissionInterface>;
  sectionArea: string;
  sectionId: number;
  sectionTitle: string;
  toCompanyId: number;
  toCompanyName: string;
  toUserCompanyId: number;
  toUserFullName: string;
  type: string;
}

const ActivityLogInvite = (props: Props) => {

  const {
    activityProjectId,
    category,
    cust,
    divisionId,
    divisionLabel,
    divisionName,
    divisionNumber,
    fromUserFullName,
    fromUserCompanyId,
    inviteId,
    isAccepted,
    isForCurrentUser,
    isForInvitedCompany,
    isFromUser,
    numDuplicates,
    onDeleteEstimatorInvite,
    onRespondToEstimatorInvite,
    projectName,
    permissions,
    sectionArea,
    sectionId,
    sectionTitle,
    toCompanyId,
    toCompanyName,
    toUserCompanyId,
    toUserFullName,
    type
  } = props;

  const classes = useStyles();

  return useMemo(() => {

    let projectUrl = '/projects/' + activityProjectId;
    if (sectionId) {
      projectUrl += '/section/' + sectionId;
    }
    else if (divisionId) {
      projectUrl += '/division/' + divisionId;
    }

    if (type === api.Project.InviteTypes.EstimatorAssignment && category === ActivityLogCategory.Invite) {
      const notAccepted = (
        isAccepted === null &&
        (isForCurrentUser || isFromUser || isForInvitedCompany || permissions?.Invites?.AllAreVisible)
      );
      const notificationDot = (notAccepted) ?
        <FiberManualRecordIcon className={classes.hasActivityIcon} fontSize="small" data-cy="notification-dot" /> :
        null;

      let projectLocationText = '';
      let sectionText = null;
      if (sectionTitle !== null && sectionTitle !== undefined) {
        const dummySection: DummySectionShape = {
          area: sectionArea,
          title: sectionTitle
        };

        sectionText = makeSectionDisplayName(cust, dummySection, undefined, true);
      }

      let divisionText = null;
      if (divisionName !== null && divisionName !== undefined) {
        const dummyDivision: DummyDivisionShape = {
          area: divisionLabel,
          name: divisionName,
          number: divisionNumber,
          label: ''
        };

        divisionText = makeDivisionDisplayName(cust, dummyDivision);
      }

      if (sectionText && divisionText) {
        projectLocationText = `${sectionText} of ${divisionText} in `;
      }
      else if (sectionText || divisionText) {
        projectLocationText = `${sectionText || divisionText} in `;
      }

      let controls = null;
      if (notAccepted && onRespondToEstimatorInvite) {
        const buttons = [];

        if (isForCurrentUser) {
          if (isAccepted === null) {
            buttons.push(
              <Button
                key="accept"
                className={classes.button}
                color="primary"
                data-cy="invite.accept-button"
                data-id={inviteId}
                data-accepted="true"
                onClick={onRespondToEstimatorInvite}
                size="small"
                startIcon={<CheckIcon style={{ color: 'green' }} />}
                variant="contained"
              >
                  Accept
              </Button>
            );
            buttons.push(
              <Button
                key="decline"
                className={classes.button}
                color="primary"
                data-cy="invite.decline-button"
                data-id={inviteId}
                data-accepted="false"
                onClick={onRespondToEstimatorInvite}
                size="small"
                startIcon={<CloseIcon color="error" />}
                variant="contained"
              >
                  Decline
              </Button>
            );
          }
        }

        if ((isFromUser || permissions?.Invites?.DeleteAnyEstimatorInvite) && onDeleteEstimatorInvite) {
          buttons.push(
            <Button
              key="delete"
              className={classes.button}
              color="primary"
              data-cy="invite.delete-button"
              data-id={inviteId}
              onClick={onDeleteEstimatorInvite}
              size="small"
              startIcon={<CancelIcon color="error" />}
              variant="contained"
            >
              Delete
            </Button>
          );
        }

        controls = <div className={classes.controls}>{buttons}</div>;
      }
      else if (isAccepted !== null) {
        const statusText = (isAccepted) ?
          <span className={classes.acceptedStatus}>Accepted</span> :
          <span className={classes.acceptedStatus + ' declined'}>Declined</span>;

        controls = <div className={classes.controls}>{statusText}</div>;
      }

      return (
        <Fragment>
          <ListItem className={classes.listItem} alignItems="flex-start" data-cy="activity-log.item" dense>
            {notificationDot}
            <SmallAvatar
              name={(fromUserFullName.length) ? fromUserFullName[0] : ''}
              id={fromUserCompanyId || 0}
              style={{ marginTop: '6px' }}
            />
            <ListItemText
              primary={
                <Fragment>
                  <Link
                    component={BrowserLink}
                    to={'/companies/' + fromUserCompanyId}
                    variant="body1"
                    color="textPrimary"
                    style={{ textDecoration: 'underline' }}
                  >
                    {fromUserFullName}
                  </Link>
                  {' invited '}
                  <Link
                    component={BrowserLink}
                    to={'/companies/' + toUserCompanyId}
                    variant="body1"
                    color="textPrimary"
                    style={{ textDecoration: 'underline' }}
                  >
                    {toUserFullName}
                  </Link>
                  {' to be a estimator in '}
                  {projectLocationText}
                  <Link
                    component={BrowserLink}
                    to={projectUrl}
                    variant="body1"
                    color="textPrimary"
                    style={{ textDecoration: 'underline' }}
                  >
                    {projectName}
                  </Link>
                </Fragment>
              }
              secondary={controls}
            />
          </ListItem>
          <Divider  component="li" />
        </Fragment>
      );
    }

    const notificationDot =
      (!isAccepted && isForInvitedCompany && category !== ActivityLogCategory.DeclineInvite) ?
        <FiberManualRecordIcon className={classes.hasActivityIcon} fontSize="small" data-cy="notification-dot"/> :
        null;

    const numDuplicatesText = (numDuplicates !== undefined && numDuplicates > 1) ?
      <span className={classes.numDuplicatesText}>{` (${numDuplicates} invites total)`}</span> :
      null;

    // defaulting to invitation type Bid
    let invitedText = ' invited ';
    let invitedEntityText = toCompanyName;
    let connectingText = ' to bid on ';
    let projectSpecificLevelText = `${divisionName || 'Unspecified Division'} - ${sectionTitle || 'Unspecified Area'}`;
    if (category === ActivityLogCategory.Invite) {
      if (type === api.Project.InviteTypes.Project) {
        connectingText = ' to be a third party user.';
        invitedEntityText = toUserFullName + ' at ' + invitedEntityText;
        projectSpecificLevelText = '';
      }
    }

    let toCompanyLink: ReactElement | null = (
      <Link
        component={BrowserLink}
        to={'/companies/' + toCompanyId}
        variant="body1"
        color="textPrimary"
        style={{ textDecoration: 'underline' }}
      >
        {invitedEntityText}
      </Link>
    );

    if (category === ActivityLogCategory.AcceptInvite) {
      invitedText = '';
      connectingText = ' accepted invitation to bid on ';
      toCompanyLink = null;
    }
    else if (category === ActivityLogCategory.DeclineInvite) {
      invitedText = '';
      connectingText = ' declined invitation to bid on ';
      toCompanyLink = null;
    }

    return (
      <Fragment>
        <ListItem className={classes.listItem} alignItems="flex-start" data-cy="activity-log.item" dense>
          {notificationDot}
          <SmallAvatar
            name={(fromUserFullName.length) ? fromUserFullName[0] : ''}
            id={fromUserCompanyId || 0}
            style={{ marginTop: '6px' }}
          />
          <ListItemText
            primary={
              <Fragment>
                <Link
                  component={BrowserLink}
                  to={'/companies/' + fromUserCompanyId}
                  variant="body1"
                  color="textPrimary"
                  style={{ textDecoration: 'underline' }}
                >
                  {fromUserFullName}
                </Link>
                {invitedText}
                {toCompanyLink}
                {connectingText}
                <Link
                  component={BrowserLink}
                  to={projectUrl}
                  variant="body1"
                  color="textPrimary"
                  style={{ textDecoration: 'underline' }}
                >
                  {projectSpecificLevelText}
                </Link>
                {numDuplicatesText}
              </Fragment>
            }
          />
        </ListItem>
        <Divider component="li" />
      </Fragment>
    );
  }, [
    activityProjectId,
    category,
    classes,
    cust,
    divisionId,
    divisionLabel,
    divisionName,
    divisionNumber,
    fromUserCompanyId,
    fromUserFullName,
    inviteId,
    isAccepted,
    isForInvitedCompany,
    isForCurrentUser,
    isFromUser,
    numDuplicates,
    onDeleteEstimatorInvite,
    onRespondToEstimatorInvite,
    projectName,
    permissions,
    sectionArea,
    sectionId,
    sectionTitle,
    toCompanyId,
    toCompanyName,
    toUserCompanyId,
    toUserFullName,
    type
  ]);
};

export default ActivityLogInvite;
