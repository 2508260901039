import React, { useState, useCallback, useMemo, useRef, useEffect, Fragment } from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';

const doNothing = () => {};

const UnstyledInput = withStyles((theme) => {

  return {
    input: {
      padding: 0,
      margin: 0,
      textTransform: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      color: 'inherit',
      lineHeight: 'inherit',
      textAlign: 'inherit'
    },
    root: {
      padding: 0,
      margin: 0,
      textTransform: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      color: 'inherit',
      lineHeight: 'inherit'
    }
  };
})(InputBase);

const PlainInput = (props) => {

  const [matchingVariables, setMatchingVariables] = useState([]);
  const [charsTypedSinceFocus, setCharsTypedSinceFocus] = useState('');
  const [anchorElement, setAnchorElement] = useState(null);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [shouldExpand, setShouldExpand] = useState(false);
  const [caretPositionAfterExpand, setCaretPositionAfterExpand] = useState(-1);
  const [focusTimeoutId, setFocusTimeoutId] = useState(null);
  const [mouseEnterTimeoutId, setMouseEnterTimeoutId] = useState(null);

  const {
    inputProps,
    inputProps: {
      onClick,
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
      onMouseEnter,
      onMouseLeave
    },
    allowReadOnlyExpand,
    autoExpand,
    allowProjectVariables,
    checkVariablesForCharacters,
    checkVariablesAtNumCharacters,
    collapsedExtraStyle,
    collapsedInputComponent,
    disablePopoverOnHover,
    expandedExtraStyle,
    expandWhenFocused,
    forceExpand,
    forceSingleLine = false,
    projectVariables,
    valueIsProjectVariable,
    multilineWhenCollapsed = false,
    onExpandChange,
    onProjectVariableSelected,
    onRemoveProjectVariable,
    onVariableMenuStateChange = doNothing,
    showFirstLineOnlyWhenNotExpanded,
    spellCheck = true,
    useTypedCharacterOrderVariableDetection,
    userCompanyId,
    useAutoComplete,
    autoCompleteParams,
    onCheckCopySectionData
  } = props;

  const inputRef = useRef();
  if (props.inputRef) {
    props.inputRef.current = inputRef.current;
  }

  const canExpand = (!inputProps.readOnly || allowReadOnlyExpand);

  const caretPositionAfterExpandRef = useRef(-1);
  caretPositionAfterExpandRef.current = caretPositionAfterExpand;
  const caretPositionAfterExpandRefCurrent = caretPositionAfterExpandRef.current;

  // this is necessary because handleTriggerExpand gets captured inside handleMouseEnter, and its copy of isMouseOver
  // is stored at that point, instead of when the setTimeout inside handleMouseEnter expires.  so we need a reference
  // that handleTriggerExpand can refer to in order to get the actual current value of isMouseOver.
  const isMouseOverRef = useRef();
  isMouseOverRef.current = isMouseOver;

  useEffect(() => {

    return () => {

      clearTimeout(mouseEnterTimeoutId);
      clearTimeout(focusTimeoutId);
    };
  }, [focusTimeoutId, mouseEnterTimeoutId]);

  useEffect(() => {

    if (caretPositionAfterExpandRefCurrent >= 0) {
      const caretPosition = caretPositionAfterExpandRefCurrent;
      const textAreas = inputRef.current.getElementsByTagName('textarea');
      if (textAreas.length > 0) {
        textAreas[0].focus();
        textAreas[0].setSelectionRange(caretPosition, caretPosition);
        setCaretPositionAfterExpand(-1);
      }
    }
  }, [caretPositionAfterExpandRefCurrent]);

  useEffect(() => {

    if (!allowProjectVariables && anchorElement && inputRef.current) {
      inputRef.current.blur();
    }
  }, [allowProjectVariables, anchorElement]);

  useEffect(() => {

    if (!autoExpand && shouldExpand) {
      setShouldExpand(false);
      if (onExpandChange) {
        onExpandChange(false);
      }
    }
  }, [autoExpand, onExpandChange, shouldExpand]);

  const isUsingProjectVariable = (allowProjectVariables && valueIsProjectVariable);

  const clearMatchingVariables = useCallback(() => {

    if (matchingVariables.length > 0) {
      setMatchingVariables([]);
      setAnchorElement(null);
      onVariableMenuStateChange(false);
    }
  }, [matchingVariables.length, onVariableMenuStateChange]);

  const handleClick = useCallback((e) => {

    const topPadding = 64.0 + 10.0;
    const bottomPadding = 20.0;

    const boundingRect = e.target.getBoundingClientRect();
    if (boundingRect.top < topPadding) {
      window.scrollBy({
        top: -(topPadding - boundingRect.top),
        behavior: 'smooth'
      });
    }
    else if (boundingRect.bottom > window.innerHeight - bottomPadding) {
      window.scrollBy({
        top: (boundingRect.bottom - window.innerHeight) + bottomPadding,
        behavior: 'smooth'
      });
    }

    if (autoExpand && !shouldExpand && canExpand) {
      setShouldExpand(true);

      if (onExpandChange) {
        onExpandChange(true);
      }

      setCaretPositionAfterExpand(e.target.selectionStart);
    }

    if (onClick) {
      onClick(e);
    }
  }, [autoExpand, canExpand, onClick, onExpandChange, shouldExpand]);

  const handleActivatingVariableMenu = useCallback((typedCharacters, anchorTarget) => {

    if (allowProjectVariables) {
      const numCharacters = checkVariablesAtNumCharacters || 1;

      if (typedCharacters.length >= numCharacters) {

        const filteredVariables = _.filter(projectVariables, (variable) => {

          if (variable.companyId !== userCompanyId) {
            return false;
          }

          // the .slice() here at the end allows us to ignore the first X number of characters of input before
          // when checking for matching variables.  this is necessary for example in the case of cost fields,
          // which always start with a $ but we don't want to consider that $ when searching for variables
          return _.startsWith(_.toLower(variable.name), _.toLower(typedCharacters.slice(numCharacters - 1)));
        });

        if (filteredVariables.length > 0) {
          setMatchingVariables(filteredVariables);
          setAnchorElement(anchorTarget);
          onVariableMenuStateChange(true);
        }
        else {
          clearMatchingVariables();
        }
      }
      else {
        clearMatchingVariables();
      }
    }
  }, [
    allowProjectVariables,
    checkVariablesAtNumCharacters,
    clearMatchingVariables,
    onVariableMenuStateChange,
    projectVariables,
    userCompanyId
  ]);

  useEffect(() => {

    if (checkVariablesForCharacters && inputRef.current) {
      setCharsTypedSinceFocus(checkVariablesForCharacters);
      handleActivatingVariableMenu(checkVariablesForCharacters, inputRef.current);
    }
  }, [checkVariablesForCharacters, handleActivatingVariableMenu]);

  const handleInputChange = useCallback((evt) => {

    const enteredValue = (useTypedCharacterOrderVariableDetection) ? charsTypedSinceFocus : evt.target.value;

    if (!evt.ctrlKey) {
      handleActivatingVariableMenu(enteredValue, evt.target);
    }

    if (onChange) {
      onChange(evt);
    }
  }, [
    charsTypedSinceFocus,
    handleActivatingVariableMenu,
    onChange,
    useTypedCharacterOrderVariableDetection
  ]);

  const handleBlur = useCallback((e) => {

    // don't want onBlur to trigger if the project variable popover is visible because that will in turn
    // trigger an unnecessary api call to edit the item and also to project/details
    // onBlur is allowed to be triggered if allowProjectVariables has been switched to false and the menu is still open
    // (happens when the variable menu is opened and the user presses 'enter' to deselect and save a cell)
    if ((Boolean(anchorElement) && !allowProjectVariables) || isUsingProjectVariable) {
      e.preventDefault();
      return;
    }

    if (autoExpand && canExpand && !forceExpand) {
      setShouldExpand(false);

      if (onExpandChange) {
        onExpandChange(false);
      }
    }

    setCharsTypedSinceFocus('');

    if (onBlur && !inputProps.readOnly) {
      onBlur(e);
    }
  }, [
    allowProjectVariables,
    anchorElement,
    autoExpand,
    canExpand,
    forceExpand,
    isUsingProjectVariable,
    inputProps.readOnly,
    onBlur,
    onExpandChange
  ]);

  const handleKeyDown = useCallback((evt) => {

    if (useTypedCharacterOrderVariableDetection && !evt.ctrlKey) {
      if (evt.key.length === 1) {
        setCharsTypedSinceFocus(charsTypedSinceFocus + evt.key);
      }
      else if (evt.key === 'Backspace' || evt.key === 'Delete') {
        setCharsTypedSinceFocus('');
      }
    }

    if (evt.key.length === 1 && !evt.ctrlKey) {
      const enteredValue = evt.key;
      handleActivatingVariableMenu(enteredValue, evt.target);
    }

    if (onKeyDown) {
      onKeyDown(evt);
    }

    if (onCheckCopySectionData) {
      onCheckCopySectionData(evt);
    }
  }, [
    charsTypedSinceFocus,
    handleActivatingVariableMenu,
    onKeyDown,
    useTypedCharacterOrderVariableDetection,
    onCheckCopySectionData
  ]);

  const triggerHoverExpand = useCallback(() => {

    if (autoExpand && !shouldExpand && (isMouseOverRef.current || expandWhenFocused) && canExpand) {
      setShouldExpand(true);

      if (onExpandChange) {
        onExpandChange(true);
      }
    }
  }, [autoExpand, canExpand, expandWhenFocused, onExpandChange, shouldExpand]);

  const handleFocus = useCallback(() => {

    if (expandWhenFocused && autoExpand && !isUsingProjectVariable && !inputProps.readOnly) {
      setFocusTimeoutId(setTimeout(triggerHoverExpand, 500));
      if (onFocus) {
        onFocus();
      }
    }
  }, [autoExpand, expandWhenFocused, inputProps.readOnly, isUsingProjectVariable, onFocus, triggerHoverExpand]);

  const handleMouseEnter = useCallback((e) => {

    if (autoExpand && !isUsingProjectVariable && !inputProps.readOnly && !disablePopoverOnHover) {
      setIsMouseOver(true);
      setMouseEnterTimeoutId(setTimeout(triggerHoverExpand, 500));
    }

    if (onMouseEnter) {
      onMouseEnter(e);
    }
  }, [onMouseEnter, autoExpand, triggerHoverExpand, isUsingProjectVariable, inputProps.readOnly, disablePopoverOnHover]);

  const handleMouseLeave = useCallback((e) => {

    if (autoExpand && !isUsingProjectVariable && !inputProps.readOnly) {
      setIsMouseOver(false);

      if (
        (shouldExpand && document.activeElement.parentElement !== inputRef.current) &&
        (document.activeElement !== inputRef.current)) {
        setShouldExpand(false);

        if (onExpandChange) {
          onExpandChange(false);
        }
      }
    }

    if (onMouseLeave) {
      onMouseLeave(e);
    }
  }, [onMouseLeave, autoExpand, shouldExpand, isUsingProjectVariable, inputProps.readOnly, onExpandChange]);

  const onClosePopover = useCallback(() => {

    setAnchorElement(null);
    onVariableMenuStateChange(false);
  }, [onVariableMenuStateChange]);

  const handleClickProjectVariable = useCallback((e) => {

    const variableId = e.currentTarget.id.replace('projectVariableMenuItem_', '');

    onClosePopover();
    onProjectVariableSelected(variableId);
  }, [onClosePopover, onProjectVariableSelected]);

  const handleClickResetVariable = useCallback(() => {

    onRemoveProjectVariable();
  }, [onRemoveProjectVariable]);

  const popoverUI = useMemo(() => {

    if (!allowProjectVariables || matchingVariables.length === 0) {
      return null;
    }

    const menuItems = [];

    _.each(matchingVariables, (variable) => {

      menuItems.push(
        <MenuItem
          key={variable.id}
          id={'projectVariableMenuItem_' + variable.id}
          data-cy="input.variable-menu-item"
          is-variable-menu-item
          dense
          selected={false}
          onClick={handleClickProjectVariable}
        >
          <ListItemText primary={variable.name} secondary={variable.value} />
        </MenuItem>
      );
    });

    return (
      <Popover
        data-cy="input.variable-menu"
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={onClosePopover}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {menuItems}
      </Popover>
    );
  }, [allowProjectVariables, matchingVariables, handleClickProjectVariable, anchorElement, onClosePopover]);

  const layoutExtras = useMemo(() => {

    let ConditionalTooltip = Fragment;
    let tooltipProps = {};
    const extraInputProps = {};

    if (isUsingProjectVariable) {
      extraInputProps.readOnly = true;
      extraInputProps.onBlur = undefined;

      if (!inputProps.readOnly) {
        ConditionalTooltip = Tooltip;
        tooltipProps = {
          title: (
            <Link
              data-cy="input.remove-variable-button"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={handleClickResetVariable}
              tabIndex={-1} //needed so that deselectIfNoCellClicked in ModuleHelper.js can detect this from the blur event
            >
            Remove project variable
            </Link>
          ),
          placement: 'top',
          interactive: true,
          arrow: true
        };
      }
    }
    else {

      if (autoExpand && canExpand) {
        if (_.isEmpty(extraInputProps.inputProps)) {
          extraInputProps.inputProps = _.cloneDeep(inputProps.inputProps) || {};
        }

        if (_.isEmpty(extraInputProps.inputProps.style)) {
          extraInputProps.inputProps.style = {};
        }

        if (expandWhenFocused) {
          extraInputProps.onFocus = handleFocus;
        }

        if (shouldExpand || forceExpand) {
          extraInputProps.multiline = !forceSingleLine;

          extraInputProps.inputProps.style.position = 'absolute';
          extraInputProps.inputProps.style.top = '0px';
          extraInputProps.inputProps.style.backgroundColor = 'white';
          extraInputProps.inputProps.style.zIndex = 4;
          extraInputProps.inputProps.style.padding = '5px';
          extraInputProps.inputProps.style.transform = 'translate(-6px, -6px)';
          extraInputProps.inputProps.style.filter = 'drop-shadow(-4px 8px 4px rgba(0,0,0,0.2))';
          extraInputProps.inputProps.style.borderWidth = '1px';
          extraInputProps.inputProps.style.borderStyle = 'solid';
          extraInputProps.inputProps.style.borderColor = 'rgba(0,0,0,0.1)';
          if (expandedExtraStyle) {
            for (const [key, value] of Object.entries(expandedExtraStyle)) {
              extraInputProps.inputProps.style[key] = value;
            }
          }
        }
        else {
          extraInputProps.multiline = multilineWhenCollapsed;

          if (showFirstLineOnlyWhenNotExpanded && inputProps.value?.length) {
            extraInputProps.inputProps.value = inputProps.value?.split('\n')[0];
          }

          extraInputProps.inputProps.style.textOverflow = 'ellipsis';
          extraInputProps.inputProps.style.whiteSpace = 'nowrap';
          extraInputProps.inputProps.style.overflow = 'hidden';
          extraInputProps.inputProps.style.height = '100%';
          if (collapsedExtraStyle) {
            for (const [key, value] of Object.entries(collapsedExtraStyle)) {
              extraInputProps.inputProps.style[key] = value;
            }
          }
        }
      }
    }

    return { ConditionalTooltip, tooltipProps, extraInputProps };
  }, [
    inputProps.readOnly,
    inputProps.inputProps,
    inputProps.value,
    autoExpand,
    canExpand,
    collapsedExtraStyle,
    expandedExtraStyle,
    expandWhenFocused,
    forceExpand,
    forceSingleLine,
    handleFocus,
    multilineWhenCollapsed,
    shouldExpand,
    showFirstLineOnlyWhenNotExpanded,
    handleClickResetVariable,
    isUsingProjectVariable
  ]);

  if (useAutoComplete) {

    if (isMouseOver) {
      inputRef.current.parentNode.style.overflow = 'inherit'; // bugfix textField clashing with autocomplete CSS
    }

    // autoComplete has its own onBlur, meaning the onBlur function in this component will never get called
    // unless we omit it from input props. In this components onBlur, we still have access to autoCompletes
    // onBlur since we are creating a copy of inputProps here with omit.
    const autoCompleteInputProps = _.omit(inputProps, ['onChange', 'onBlur']);
    return (
      <Fragment>
        <layoutExtras.ConditionalTooltip {...layoutExtras.tooltipProps}>
          <TextField
            inputRef={inputRef}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            spellCheck={spellCheck}
            {...autoCompleteParams}
            {...autoCompleteInputProps}
            {...layoutExtras.extraInputProps}
          />
        </layoutExtras.ConditionalTooltip>
        {popoverUI}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <layoutExtras.ConditionalTooltip {...layoutExtras.tooltipProps}>
        <UnstyledInput
          {...inputProps}
          {...layoutExtras.extraInputProps}
          inputComponent={shouldExpand ? undefined : collapsedInputComponent}
          ref={inputRef}
          onClick={handleClick}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          spellCheck={spellCheck}
        />
      </layoutExtras.ConditionalTooltip>
      {popoverUI}
    </Fragment>
  );

};

export default PlainInput;
