import React from 'react';

import Box from '@material-ui/core/Box';
import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme, color) => ({
  '@keyframes indeterminateAnimation': {
    '0%': { transform: 'translateX(0) scaleX(0);' },
    '40%': { transform: 'translateX(0) scaleX(0.4);' },
    '100%': { transform: 'translateX(100%) scaleX(0.5);' }
  },
  progressBar: {
    height: '4px',
    width: '100%',
    backgroundColor: alpha(color ?? theme.palette.secondary.main, 0.2),
    overflow: 'hidden'
  },
  progressBarValue: {
    height: '100%',
    width: '100%',
    backgroundColor: color ?? theme.palette.secondary.main,
    animation: `$indeterminateAnimation 1s linear infinite`,
    transformOrigin: '0% 50%'
  }
}));

const CSSLinearProgress = ({ color, ...props }) => {

  const classes = useStyles(color);
  return (
    <Box {...props}>
      <div className={classes.progressBar}>
        <div className={classes.progressBarValue} />
      </div>
    </Box>
  );
};

export default CSSLinearProgress;
