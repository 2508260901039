import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import * as Consts from '../../consts/Project';

import Avatar from '@material-ui/core/Avatar';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const stableDummyArray = [];

const preventLinkClick = (evt) => evt.preventDefault();

const sortByName = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

const useStyles = makeStyles((theme) => ({
  archiveButton: {
    '&:hover:not(.disabled)': {
      color: theme.palette.response.warningHover
    },

    '&.disabled': {
      color: theme.palette.app.greyedOutText,
      cursor: 'default'
    }
  },
  assigneeNumCompanies: {
    color: theme.palette.section.folder,
    backgroundColor: theme.palette.response.warning,
    borderRadius: 20,
    fontWeight: 'bold',
    height: 'fit-content',
    margin: '0 2px',
    padding: '0 6px'
  },
  assigneeNumLocations: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    fontWeight: 'bold',
    height: 'fit-content',
    margin: '0 2px',
    padding: '0 4px'
  },
  browserItem: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.app.shadow}`,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5),
    userSelect: 'none'
  },
  browserItemLink: {
    textDecoration: 'none'
  },
  browserItemTitle: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.5),
    marginRight: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  dropDownTooltip: {
    backgroundColor: theme.palette.section.folder,
    border: `1px solid ${theme.palette.app.shadowLight}`,
    filter: `drop-shadow(2px 2px 2px ${theme.palette.app.shadow})`
  },
  inlineIcons: {
    alignItems: 'center',
    backgroundColor: theme.palette.section.folder,
    borderRadius: 5,
    display: 'flex',

    '& svg': {
      color: theme.palette.section.actionButtonsBackgroundDark
    },

    '& svg:hover:not(.fileArchiveButton)': {
      color: theme.palette.secondary.dark
    }
  },
  file: {
    border: `1px solid transparent`,
    borderRadius: '5px',
    margin: theme.spacing(0.75),
    position: 'relative',
    width: '24rem',

    '&.fullWidth': {
      width: '100%'
    },

    '&:hover': {
      borderColor: theme.palette.secondary.main
    }
  },
  fileName: {
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.primary.contrastText,
    height: '100%',
    overflow: 'hidden',
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  },
  flex: {
    display: 'flex'
  },
  imageIcon: {
    height: '20px',
    width: '20px'
  },
  imageTooltip: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '400px',
    maxWidth: '400px'
  },
  optionButton: {
    alignSelf: 'end'
  },
  optionButtonIcon: {
    '&:hover': {
      color: theme.palette.section.folder
    }
  },
  optionsTooltip: {
    backgroundColor: theme.palette.section.actionButtonsBackgroundDark,
    filter: `drop-shadow(2px 2px 2px ${theme.palette.app.shadow})`
  },
  tooltipTitle: {
    paddingBottom: theme.spacing(2)
  }
}));

const FileElement = (props) => {

  const cust = useSelector((state) => state.project.customDesignations);

  const {
    archiveDisabledMessage,
    bids,
    canArchive,
    canUpload,
    extraButtons = [],
    file,
    file: {
      assigneeBidAttachments = [],
      assigneeProjectAttachments = [],
      assigneeSectionAttachments = [],
      attachmentType,
      displayFilename,
      folders,
      id: attachmentId,
      originalAttachmentId,
      parentBidAttachmentId,
      parentSectionAttachmentId,
      parentProjectAttachmentId,
      type,
      url
    },
    fileHistoryButtonProps = {},
    fullWidth,
    highlightText,
    maxDisplayedTitleChars = 60,
    onArchive,
    onClick,
    onFileHistoryClick = null,
    onScrollToItem,
    pathAndFilename,
    sectionIdLookup,
    style
  } = props;

  const classes = useStyles();

  const [aMenuIsOpen, setAMenuIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const titleRef = useRef();

  useEffect(() => {

    const handleResize = () => {

      if (titleRef.current) {
        if (titleRef.current.scrollWidth > titleRef.current.clientWidth) {
          // Title is overflowing into icons
          setIsOverflowing(true);
        }
        else {
          setIsOverflowing(false);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleArchive = useCallback(() => onArchive(file), [file, onArchive]);

  const handleClick = useCallback((evt) => {

    if (onClick) {
      evt.preventDefault();
      onClick(file);
    }
  }, [file, onClick]);

  const handleMenuOpen = useCallback(() => setAMenuIsOpen(true), []);

  const handleMenuClose = useCallback(() => setAMenuIsOpen(false), []);

  const handleLocationClick = useCallback((evt) => {

    if (onScrollToItem) {
      let bidId = parseInt(evt.currentTarget.dataset.bidId) || undefined;
      let sectionId = parseInt(evt.currentTarget.dataset.sectionId) || undefined;

      if (bidId && !document.getElementById(`bid-${bidId}`)) {
        sectionId = bids?.find((bid) => bid.id === bidId)?.sectionId || '';
        bidId = undefined;
      }

      onScrollToItem({ bidId, sectionId });
    }
  }, [bids, onScrollToItem]);

  const assignees = useMemo(() => {

    return [...assigneeBidAttachments, ...assigneeSectionAttachments, ...assigneeProjectAttachments];
  }, [assigneeBidAttachments, assigneeSectionAttachments, assigneeProjectAttachments]);

  const assigneeLocationMenuItems = useMemo(() => {

    const menuItems = assignees.map(({ id, bidId, sectionId }) => {

      let name = '';
      let parentType;

      if (bidId) {
        parentType = 'Bid';
        const bid = bids?.find((b) => b.id === bidId);

        if (bid) {
          const bidSection = sectionIdLookup.get(bid.sectionId);
          name = (bidSection) ? `${bidSection.title || 'Untitled'} - ${bid.company.name}` : bid.company.name;
        }
      }
      else if (sectionId) {
        name = sectionIdLookup.get(sectionId)?.title || '';
        parentType = cust?.section || 'Section';
      }
      else {
        name = 'Project';
        parentType = 'Project';
      }

      return {
        bidId,
        id,
        name,
        parentType,
        sectionId
      };
    });

    menuItems.sort(sortByName);

    return menuItems.map(({ bidId, id, name, parentType, sectionId }) => {

      return (
        <ListItem
          key={`${parentType}${id}`}
          button
          dense
          data-bid-id={bidId}
          data-section-id={sectionId}
          data-cy="file.assignee-location"
          onClick={handleLocationClick}
        >
          <ListItemText
            primary={name}
            secondary={parentType}
            primaryTypographyProps={{ color: 'textPrimary' }}
          />
        </ListItem>
      );
    });
  }, [assignees, bids, cust, handleLocationClick, sectionIdLookup]);

  const assigneeLocationMenu = useMemo(() => {

    return (
      <List dense disablePadding>
        {assigneeLocationMenuItems}
      </List>
    );
  }, [assigneeLocationMenuItems]);

  const assigneeCompanyMenuItems = useMemo(() => {

    const menuItems = [];
    if (!bids) {
      return menuItems;
    }

    const companyIds = new Set();

    for (const { id, bidId } of assignees) {
      if (!bidId) {
        continue;
      }

      const bid = bids?.find((b) => b.id === bidId);
      if (bid && !companyIds.has(bid.companyId)) {
        menuItems.push({ id, name: bid.company.name || '' });
        companyIds.add(bid.companyId);
      }
    }

    menuItems.sort(sortByName);

    return menuItems.map(({ id, name }) => {

      return (
        <ListItem key={id} dense data-cy="file.assignee-company">
          <ListItemText primary={name} primaryTypographyProps={{ color: 'textPrimary' }} />
        </ListItem>
      );
    });
  }, [assignees, bids]);

  const assigneeCompanyMenu = useMemo(() => {

    return (
      <List dense disablePadding>
        {assigneeCompanyMenuItems}
      </List>
    );
  }, [assigneeCompanyMenuItems]);

  const archiveButton = useMemo(() => {

    if (canArchive) {
      const typeOfAttachment = (parentBidAttachmentId || parentSectionAttachmentId || parentProjectAttachmentId) ?
        'bidAttachment' :
        type;

      return (
        <Tooltip title="Archive" placement="top" interactive arrow>
          <IconButton
            onClick={handleArchive}
            size="small"
            data-display-filename={displayFilename}
            data-id={attachmentId}
            data-type={typeOfAttachment}
            data-cy="file.archive-button"
          >
            <CancelIcon color="primary" fontSize="small" className={classes.archiveButton + ' fileArchiveButton'} />
          </IconButton>
        </Tooltip>
      );
    }
    else if (archiveDisabledMessage) {
      return (
        <Tooltip title={archiveDisabledMessage} placement="top" interactive arrow>
          <IconButton disableRipple disableFocusRipple size="small">
            <CancelIcon color="disabled" fontSize="small" className={classes.archiveButton + ' disabled'} />
          </IconButton>
        </Tooltip>
      );
    }

    return null;
  }, [
    archiveDisabledMessage,
    attachmentId,
    canArchive,
    classes,
    displayFilename,
    handleArchive,
    parentBidAttachmentId,
    parentProjectAttachmentId,
    parentSectionAttachmentId,
    type
  ]);

  const fileHistoryButton = useMemo(() => {

    if (attachmentType !== 'TakeoffMarkup') {
      return (
        <Tooltip title="Attachment History" placement="top" arrow>
          <IconButton
            {...fileHistoryButtonProps}
            className={classes.optionButton}
            data-attachment-id={attachmentId}
            data-original-attachment-id={originalAttachmentId}
            data-type={type}
            data-folders={folders || ''}
            data-can-upload={canUpload}
            data-cy="file.history-button"
            onClick={onFileHistoryClick}
            size="small"
          >
            <AccessTimeIcon color="primary" fontSize="small" className={classes.optionButtonIcon} />
          </IconButton>
        </Tooltip>
      );
    }

    return null;
  }, [
    attachmentId,
    attachmentType,
    canUpload,
    classes,
    fileHistoryButtonProps,
    folders,
    onFileHistoryClick,
    originalAttachmentId,
    type
  ]);

  const numOptionButtons = useMemo(() => {

    return Boolean(archiveButton) + Boolean(fileHistoryButton) + extraButtons.length;
  }, [archiveButton, extraButtons, fileHistoryButton]);

  const assigneeButtons = useMemo(() => {

    const buttons = [];

    if (assigneeLocationMenuItems.length) {
      buttons.push(
        <Tooltip
          key="assigneeLocation"
          title={assigneeLocationMenu}
          classes={{ tooltip: classes.dropDownTooltip }}
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
          placement="bottom"
          arrow
          interactive
        >
          <span className={classes.assigneeNumLocations} data-cy="file.assignee-num-locations">
            {assigneeLocationMenuItems.length}
          </span>
        </Tooltip>
      );
    }

    if (assigneeCompanyMenuItems.length) {
      buttons.push(
        <Tooltip
          key="assigneeCompany"
          title={assigneeCompanyMenu}
          classes={{ tooltip: classes.dropDownTooltip }}
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
          placement="bottom"
          arrow
          interactive
        >
          <span className={classes.assigneeNumCompanies} data-cy="file.assignee-num-companies">
            {assigneeCompanyMenuItems.length}
          </span>
        </Tooltip>
      );
    }

    return buttons;
  }, [
    assigneeCompanyMenu,
    assigneeCompanyMenuItems.length,
    assigneeLocationMenu,
    assigneeLocationMenuItems.length,
    classes,
    handleMenuClose,
    handleMenuOpen
  ]);

  const fileOptions = useMemo(() => {

    if (!(archiveButton || fileHistoryButton)) {
      return null;
    }

    return (
      <div className={classes.flex} data-cy="file.options-menu" onClick={preventLinkClick}>
        {fileHistoryButton}
        {extraButtons}
        {archiveButton}
      </div>
    );
  }, [
    archiveButton,
    classes,
    extraButtons,
    fileHistoryButton
  ]);

  const optionsIconOrButtons = useMemo(() => {

    if (!numOptionButtons && !assigneeButtons.length) {
      return null;
    }

    if (isOverflowing) {
      return (
        <Tooltip
          title={fileOptions}
          arrow
          classes={{ tooltip: classes.optionsTooltip }}
          interactive
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
          placement="right"
        >
          <MoreVertIcon fontSize="small" data-cy="file.options-button" onClick={preventLinkClick} />
        </Tooltip>
      );
    }

    return (
      <div className={classes.inlineIcons} data-cy="file.options-button" onClick={preventLinkClick}>
        {assigneeButtons}
        {fileOptions}
      </div>
    );
  }, [assigneeButtons, classes, isOverflowing, fileOptions, handleMenuClose, handleMenuOpen, numOptionButtons]);

  return useMemo(() => {

    const searchResultClass = (fullWidth && attachmentType !== 'TakeoffMarkup') ? ' fullWidth' : '';

    let icon = null;
    let tooltipTitle = pathAndFilename;
    const isImage = Consts.ProjectFileBrowserImageAttachmentTypes.has(attachmentType);
    if (isImage) {
      icon = <Avatar className={classes.imageIcon} src={url} fontSize="small" />;
      tooltipTitle = (
        <div className={classes.imageTooltip} data-cy="file.item-tooltip.image">
          <span className={classes.tooltipTitle}>{pathAndFilename}</span>
          <img src={url} alt={displayFilename} />
        </div>
      );
    }
    else {
      icon = <DescriptionOutlinedIcon color="secondary" fontSize="small" />;
    }

    let fileTitle = pathAndFilename;
    if (fileTitle && fileTitle.length > maxDisplayedTitleChars) {
      fileTitle = '...' + fileTitle.substr(fileTitle.length - maxDisplayedTitleChars);
    }

    if (highlightText && attachmentType !== 'TakeoffMarkup') {
      const splitText = pathAndFilename.split(new RegExp(`(${highlightText})`, 'gi'));
      const highlightedTitle = splitText.map((text, idx) => {

        if (text.toLowerCase() === highlightText.toLowerCase()) {
          return <mark key={idx}>{text}</mark>;
        }

        return text;
      });
      fileTitle = <span>{highlightedTitle}</span>;
    }

    const fileNameTooltip = (aMenuIsOpen) ? '' : <div data-cy="file.name-tooltip">{tooltipTitle}</div>;
    const assigneeIcons = (isOverflowing) ? assigneeButtons : stableDummyArray;

    return (
      <div className={classes.file + searchResultClass} style={style} data-cy="file">
        <a
          className={classes.browserItemLink}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          data-cy="file.file-link"
          onClick={handleClick}
        >
          <Tooltip title={fileNameTooltip} placement="top" arrow>
            <Paper className={classes.browserItem} data-type="attachment" data-id={attachmentId}>
              <div className={classes.fileName}>
                {icon}
                <Typography className={classes.browserItemTitle} noWrap data-cy="file.filename" ref={titleRef}>
                  {fileTitle}
                </Typography>
              </div>
              <span className={classes.flex}>
                {assigneeIcons}
                {optionsIconOrButtons}
              </span>
            </Paper>
          </Tooltip>
        </a>
      </div>
    );
  }, [
    aMenuIsOpen,
    assigneeButtons,
    attachmentId,
    attachmentType,
    classes,
    displayFilename,
    fullWidth,
    handleClick,
    highlightText,
    isOverflowing,
    maxDisplayedTitleChars,
    optionsIconOrButtons,
    pathAndFilename,
    style,
    url
  ]);

};

export default FileElement;
