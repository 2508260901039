import { generateCustomWbsCode } from 'wbs/dist/generateCustomWbsCode';
import { parseCodeNumbers } from 'wbs/dist/parseCodeNumbers';
import { RowColumnState } from '../../wbs/calculateWBSState';
import { WBSDataRowShape } from 'wbs/dist/types/WBSDataRowShape';
import { WBSDataRowShapeWithStates } from '../../wbs/calculateWBSState';
import { WBSCodeCustomizationShape } from 'wbs/dist/types/WBSCodeCustomizationShape';
import { WBSOverrideShapeClient } from 'wbs/dist/types/WBSOverrideShapeClient';
import { WBSRowSplitShape } from '../../../types/api/WBSRowSplitShape';

import { cloneDeep, padStart } from 'lodash';

import { WBSUnitsShape } from 'wbs/dist/packages/wbs/src/types/WBSUnitsShape';
import { getDefaultUnits } from 'wbs/dist/getDefaultUnits';
import { v4 as Uuidv4 } from 'uuid';
import { createWbsLookup } from 'wbs/dist/createWbsLookup';
import { getLeafRows } from 'wbs/dist/getLeafRows';
import { getChildRows } from 'wbs/dist/getChildRows';


let nextId: number = -1;

export interface NewRowOverride {
  name?: string;
  belowRowId?: number;
  cost?: string;
  inputCost?: string;
  inputProjectCurrencyId?: number | null;
  quantity?: string;
  total?: string;
  increaseLevel?: number;
  columnState?: RowColumnState;
  resourceOverrides?: WBSOverrideShapeClient[];
  wbsCode?: string;
  units?: WBSUnitsShape
}

export const generateNextTopLevelWbsCode = (dataRows: WBSDataRowShapeWithStates[]) => {

  const topLevelRows = dataRows.filter((r) => parseCodeNumbers(r.wbsCode).length === 1);
  const lastTopLevelRow = topLevelRows[topLevelRows.length - 1];
  if (!lastTopLevelRow) {
    return '';
  }

  return padStart((parseInt(lastTopLevelRow.wbsCode) + 1).toString(), 2, '0');
};

export const generateLastChildWbsCode = (parentWbsCode: string, childRows: any[]) => {

  if (childRows.length === 0) {
    return `${parentWbsCode} 01`;
  }

  const currentLastChildRow = childRows[childRows.length - 1];
  const lastChildWbsCodeParsed = parseCodeNumbers(currentLastChildRow.wbsCode);
  const lastChildRowCodeEnd = parseInt(lastChildWbsCodeParsed[lastChildWbsCodeParsed.length - 1]);

  const newRowCodeEnd = `0${lastChildRowCodeEnd + 1}`;
  lastChildWbsCodeParsed.pop();
  const actualNewRowCode = `${lastChildWbsCodeParsed.join(' ')} ${newRowCodeEnd}`;
  return actualNewRowCode;
};

export const nextWbsCode = (wbsCode: string): string => {

  const codeArray = wbsCode.split(' ');
  const lastElement = codeArray[codeArray.length - 1];
  const nextNumber = parseInt(lastElement) + 1;
  codeArray[codeArray.length - 1] = nextNumber.toString().padStart(lastElement.length, '0');
  return codeArray.join(' ');
};

export const nextWbsCodeWithLevel = (wbsCode: string, level: number): string => {

  const codeArray = wbsCode.split(' ');
  const intCodePart = parseInt(codeArray[level - 1]);

  if (isNaN(intCodePart)) {
    return wbsCode;
  }

  codeArray[level - 1] = (intCodePart + 1).toString().padStart(2, '0');
  return codeArray.join(' ');
};

export const nextWbsCodeWithLevelWithTimes = (wbsCode: string, level: number, times: number): string => {

  let result = wbsCode;
  for (let i = 0; i < times; i++) {
    result = nextWbsCodeWithLevel(result, level);
  }

  return result;
};

export const getCorrectWBSName = (wbsCode: string, name: string) => {

  const amountToIndent = wbsCode.split(' ').length - 1;
  const indentation = ' '.repeat(amountToIndent);
  return `${indentation}${name.trim()}`;
};

export const nextRow = (
  isResources: boolean,
  primaryCurrencySymbol: string,
  lastRow: WBSDataRowShape,
  override: NewRowOverride = {},
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  currentPhaseId: number | null,
  baseLevel?: number,
  forcedTopLevelWbsCode?: string,
  forcedLastChildWbsCode?: string
): WBSDataRowShapeWithStates => {

  const amountToIndent = (baseLevel !== undefined) ? baseLevel : lastRow.name.search(/\S|$/);
  const indentation = ' '.repeat(amountToIndent);

  const wbsCode = forcedTopLevelWbsCode ?? forcedLastChildWbsCode ?? override.wbsCode ?? nextWbsCode(lastRow.wbsCode);
  const cost = override.cost ?? '0';
  const inputCost = override.inputCost ?? '0';
  const inputProjectCurrencyId = (override.inputCost) ? (override.inputProjectCurrencyId ?? null) : null;
  const quantity = override.quantity ?? '0';
  const units = { quantity: override.units?.quantity || '' };

  const newRow: WBSDataRowShape = {
    clientSideGuid: Uuidv4(),
    createdInPhaseId: currentPhaseId,
    id: nextId,
    isResource: isResources,
    name: override.name ? `${indentation}${' '.repeat(override.increaseLevel ?? 0)}${override.name.trim()}` : indentation,
    wbsCode,
    customWbsCode: generateCustomWbsCode(wbsCode, wbsCodeSeparator, wbsCodeCustomizationsMatrix),
    total: override.total ?? `${primaryCurrencySymbol}0.00`,
    clientSidePredictionId: nextId,
    projectId: lastRow.projectId,
    sortOrder: lastRow.sortOrder + 1,
    resourceOverrides: override.resourceOverrides,
    quantity,
    cost,
    inputCost,
    units,
    startDate: null,
    endDate: null,
    inputProjectCurrencyId
  };

  nextId--;

  return {
    ...newRow,
    uiState: { isExpanded: false, hidden: false, rowCanBeExpanded: false, isSelected: true, isLastRow: true },
    columnState: override.columnState ?? {},
    originalValue: { cost, inputCost, inputProjectCurrencyId, quantity, units }
  };
};

export const addAsFirstRow = (
  isResources: boolean,
  projectId: number,
  primaryCurrencySymbol: string,
  override: NewRowOverride = {},
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  currentPhaseId: number | null,
  baseLevel?: number
): WBSDataRowShapeWithStates => {

  const amountToIndent = baseLevel ?? 0;
  const indentation = ' '.repeat(amountToIndent);
  const wbsCode = override.wbsCode ?? '01';
  const inputProjectCurrencyId = (override.inputCost) ? (override.inputProjectCurrencyId ?? null) : null;

  const newRow: WBSDataRowShape = {
    clientSideGuid: Uuidv4(),
    createdInPhaseId: currentPhaseId,
    id: nextId,
    isResource: isResources,
    name: override.name ? `${indentation}${' '.repeat(override.increaseLevel ?? 0)}${override.name.trim()}` : indentation,
    wbsCode,
    customWbsCode: generateCustomWbsCode(wbsCode, wbsCodeSeparator, wbsCodeCustomizationsMatrix),
    quantity: override.quantity ?? '0',
    cost: override.cost ?? '0',
    inputCost: override.inputCost ?? '0',
    total: override.total ?? `${primaryCurrencySymbol}0.00`,
    clientSidePredictionId: nextId,
    projectId,
    sortOrder: 0,
    resourceOverrides: override.resourceOverrides,
    units: { quantity: override.units?.quantity || '' },
    startDate: null,
    endDate: null,
    inputProjectCurrencyId
  };

  nextId--;

  return {
    ...newRow,
    uiState: { isExpanded: false, hidden: false, rowCanBeExpanded: false, isSelected: true, isLastRow: false },
    columnState: override.columnState ?? {}
  };
};

export const firstRow = (
  isResources: boolean,
  projectId: number,
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  currentPhaseId: number | null
): WBSDataRowShapeWithStates => {

  const newRow: WBSDataRowShape = {
    clientSideGuid: Uuidv4(),
    createdInPhaseId: currentPhaseId,
    id: nextId,
    isResource: isResources,
    name: '',
    wbsCode: '01',
    customWbsCode: generateCustomWbsCode('01', wbsCodeSeparator, wbsCodeCustomizationsMatrix),
    quantity: '0',
    cost: '0',
    inputCost: '0',
    clientSidePredictionId: nextId,
    projectId,
    sortOrder: 1,
    units: getDefaultUnits('wbs') as WBSUnitsShape,
    startDate: null,
    endDate: null,
    inputProjectCurrencyId: null
  };

  nextId--;

  return {
    ...newRow,
    uiState: { isExpanded: false, hidden: false, rowCanBeExpanded: false, isSelected: true, isLastRow: true },
    columnState: {}
  };
};

export const addNewWbsRows = (
  isResources: boolean,
  primaryCurrencySymbol: string,
  dataRows: WBSDataRowShapeWithStates[],
  currentRow: WBSDataRowShapeWithStates,
  overrides: NewRowOverride[] = [{}],
  wbsCodeSeparator: string,
  wbsCodeCustomizationsMatrix: WBSCodeCustomizationShape[][],
  rbsDataRows: WBSDataRowShapeWithStates[],
  wbsDataRows: WBSDataRowShapeWithStates[],
  hasDefinedResources: boolean,
  currentPhaseId: number | null,
  ignoreBaseLevel?: boolean,
  forceTopLevelRow?: boolean,
  forceLastChild?: boolean
) => {

  let forcedTopLevelWbsCode: undefined | string = undefined;
  let forcedLastChildWbsCode: undefined | string = undefined;
  let rowIndex = dataRows.findIndex((r) => r.clientSidePredictionId === currentRow.clientSidePredictionId) + 1;
  let newRow: WBSDataRowShape | undefined = undefined;
  let wbsLevel = parseCodeNumbers(currentRow.wbsCode).length;

  const currentRowName = currentRow.name;
  const projectId = currentRow.projectId;
  const baseLevel = (forceTopLevelRow) ? 0 : (forceLastChild) ? wbsLevel : currentRow.name.search(/\S|$/);
  const newDataRows: WBSDataRowShapeWithStates[] = [];
  const relevantDataRows = (isResources) ? rbsDataRows : wbsDataRows;
  const wbsLookup = createWbsLookup(rbsDataRows, wbsDataRows);
  const firstRbsRowLeafNodes = getLeafRows(wbsLookup.rbs.codeLookup, '01');
  const initialCurrentRowChildren = getChildRows((isResources) ? wbsLookup.rbs.codeLookup : wbsLookup.wbs.codeLookup, currentRow.wbsCode);
  const initialCurrentRowLeafs = getLeafRows((isResources) ? wbsLookup.rbs.codeLookup : wbsLookup.wbs.codeLookup, currentRow.wbsCode);

  if (forceLastChild) {
    forcedLastChildWbsCode = generateLastChildWbsCode(currentRow.wbsCode, initialCurrentRowChildren);
    if (initialCurrentRowChildren.length > 0) {
      // If forcing a last child we need to work off of the last leaf row instead of the initial currentRow.
      const lastLeaf = initialCurrentRowLeafs[initialCurrentRowLeafs.length - 1] as WBSDataRowShapeWithStates;
      const lastChild = initialCurrentRowChildren[initialCurrentRowChildren.length - 1] as WBSDataRowShapeWithStates;
      currentRow = lastLeaf;
      wbsLevel = parseCodeNumbers(lastChild.wbsCode).length;
      rowIndex = dataRows.findIndex((r) => r.clientSidePredictionId === lastLeaf.clientSidePredictionId) + 1;
    }
  }
  else if (forceTopLevelRow) {
    forcedTopLevelWbsCode = generateNextTopLevelWbsCode(relevantDataRows);
  }

  overrides.forEach((override) => {

    // Place row at top of list
    if (override.belowRowId === -1) {
      newRow = addAsFirstRow(
        isResources,
        projectId,
        primaryCurrencySymbol,
        override,
        wbsCodeSeparator,
        wbsCodeCustomizationsMatrix,
        currentPhaseId,
        (ignoreBaseLevel) ? (override?.name ?? currentRowName).search(/\S|$/) : baseLevel
      );

      // since the row was inserted at the top, we need to update the correct index and wbs level.
      rowIndex = 0;
      wbsLevel = 1;
    }
    else {
      if (override.belowRowId !== undefined) {
        currentRow = dataRows.find((r) => r.id === override.belowRowId) ?? currentRow;
      }

      newRow = nextRow(
        isResources,
        primaryCurrencySymbol,
        currentRow,
        override,
        wbsCodeSeparator,
        wbsCodeCustomizationsMatrix,
        currentPhaseId,
        (ignoreBaseLevel) ? (override?.name ?? currentRowName).search(/\S|$/) : baseLevel,
        forcedTopLevelWbsCode,
        forcedLastChildWbsCode
      );
    }

    // When adding new wbs rows after resources have been defined, we need to assign a resource override to the new row for
    // each leaf row of the first rbs row.
    if (!isResources && hasDefinedResources) {

      const resourceOverrides = [];
      for (const leafNode of firstRbsRowLeafNodes) {
        const newOverride = {
          id: leafNode.id,
          cost: override.inputCost,
          inputCost: override.inputCost,
          inputProjectCurrencyId: null,
          quantity: (parseFloat(override.quantity ?? '1') / firstRbsRowLeafNodes.length).toString(),
          units: override.units ?? getDefaultUnits('wbs')
        };

        resourceOverrides.push(newOverride);
      }

      newRow.resourceOverrides = resourceOverrides as WBSOverrideShapeClient[];
    }

    newDataRows.push(newRow as WBSDataRowShapeWithStates);
  });

  const beforeCurrentRows = dataRows.slice(0, rowIndex);
  const afterCurrentRows = dataRows.slice(rowIndex);
  const changeSet: any[] = [];

  afterCurrentRows.forEach((r) => {

    const sortOrder = r.sortOrder + overrides.length;
    const changeSetData: { [index: string]: any } = {
      id: r.id,
      sortOrder,
      inputCost: r.inputCost,
      inputProjectCurrencyId: r.inputProjectCurrencyId,
      quantity: r.quantity,
      units: r.units
    };
    r.sortOrder = sortOrder;

    // No need to update wbs codes after the new row if we are forcing a last child since last children do not affect other rows.
    if (!forceLastChild) {
      const wbsCode = nextWbsCodeWithLevel(r.wbsCode, wbsLevel);
      r.wbsCode = wbsCode;
      r.customWbsCode = generateCustomWbsCode(wbsCode, wbsCodeSeparator, wbsCodeCustomizationsMatrix);
      changeSetData.wbsCode = wbsCode;
    }

    changeSet.push(changeSetData);
  });

  const updatedDataRows: WBSDataRowShapeWithStates[] = [
    ...beforeCurrentRows,
    ...newDataRows,
    ...afterCurrentRows
  ];

  updatedDataRows.forEach((r) => (r.uiState.isLastRow = false));

  const lastRow = updatedDataRows[updatedDataRows.length - 1];
  lastRow.uiState.isLastRow = true;

  return { updatedDataRows, newDataRows, changeSet };
};

export const duplicateWbsRow = (
  currentDataRows: WBSDataRowShapeWithStates[],
  rowToDuplicate: WBSDataRowShapeWithStates,
  allChildren: WBSDataRowShapeWithStates[]
) => {

  const rowToDuplicateIdx = currentDataRows.findIndex((dataRow) => dataRow === rowToDuplicate);
  const indexToAddTo = rowToDuplicateIdx + allChildren.length + 1;
  const selectedChildren = allChildren.filter((child) => child.uiState.isSelected);
  let childrenToDuplicate: WBSDataRowShapeWithStates[] = [];
  if (selectedChildren.length > 0 && selectedChildren.length !== allChildren.length) {
    // Copy parent and only the selected children
    childrenToDuplicate = selectedChildren;
  }
  else if (selectedChildren.length === 0 || selectedChildren.length === allChildren.length) {
    // Copy parent and all children
    childrenToDuplicate = allChildren;
  }

  const duplicateRowNextWbsCode = nextWbsCode(rowToDuplicate.wbsCode);
  const duplicateRowCodeNumbers = parseCodeNumbers(duplicateRowNextWbsCode);
  const isDuplicatingChildren = (childrenToDuplicate.length > 0);
  const duplicateRowSortOrder = (isDuplicatingChildren) ?
    allChildren[allChildren.length - 1].sortOrder + 1 :
    rowToDuplicate.sortOrder + 1;

  const duplicatedRow = {
    ...rowToDuplicate,
    clientSideGuid: Uuidv4(),
    id: nextId,
    clientSidePredictionId: nextId,
    sortOrder: duplicateRowSortOrder,
    wbsCode: duplicateRowNextWbsCode,
    uiState: { ...rowToDuplicate.uiState, isSelected: false }
  };

  nextId--;

  const allRowsToDuplicate: WBSDataRowShapeWithStates[] = [duplicatedRow];

  if (isDuplicatingChildren) {
    let trackedNumChildren = 1;
    for (const child of childrenToDuplicate) {
      const childCodeNumbers = parseCodeNumbers(child.wbsCode);
      const duplicateChildCodeWithoutParent = childCodeNumbers.slice(duplicateRowCodeNumbers.length).join(' ');
      const newWbsCode = `${duplicateRowCodeNumbers.join(' ')} ${duplicateChildCodeWithoutParent}`;
      const childDuplicate = {
        ...child,
        clientSideGuid: Uuidv4(),
        wbsCode: newWbsCode,
        id: nextId,
        clientSidePredictionId: nextId,
        sortOrder: duplicateRowSortOrder + trackedNumChildren,
        uiState: { ...child.uiState, isSelected: false }
      };

      allRowsToDuplicate.push(childDuplicate);
      trackedNumChildren++;
      nextId--;
    }
  }

  const newDataRows = [...currentDataRows];
  newDataRows.splice(indexToAddTo, 0, ...allRowsToDuplicate);

  return newDataRows;
};

const getLeadingWhitespace = (str: string): string => {

  const match = str.match(/^\s+/);
  return match ? match[0] : '';
};

export const splitWbsRow = (
  dataRows: WBSDataRowShapeWithStates[],
  row: WBSDataRowShapeWithStates,
  rowSplits: WBSRowSplitShape[],
  isResources: boolean,
  matchingOverridesByWbsRowId: { [index: string]: WBSOverrideShapeClient[] }
) => {

  // Use only leaf splits for total ratio
  let totalRatio = 0;
  for (let i = 0; i < rowSplits.length; i++) {
    const thisSplit = rowSplits[i];
    const nextSplit = rowSplits[i + 1];

    if (nextSplit && getLeadingWhitespace(nextSplit.name) > getLeadingWhitespace(thisSplit.name)) {
      continue;
    }

    totalRatio += parseFloat(thisSplit.ratio);
  }

  const newDataRows = [...dataRows];
  const newOverridesByWbsRowId: { [index: string]: WBSOverrideShapeClient[] } = {};
  const rowCodeLength = parseCodeNumbers(row.wbsCode).length;
  const originalRowIndex = newDataRows.findIndex((item) => item === row);
  const allRowChildren = [];
  const originalChildrenIds = new Set<number>();
  let possibleChildRowIndex = originalRowIndex + 1;
  let possibleChildRow = newDataRows[possibleChildRowIndex];
  while (possibleChildRow) {
    if (parseCodeNumbers(possibleChildRow.wbsCode).length > rowCodeLength) {
      allRowChildren.push(possibleChildRow);
      originalChildrenIds.add(possibleChildRow.clientSidePredictionId);
      possibleChildRow.uiState.isDeleting = true;
      possibleChildRow.sortOrder = 99999;
      possibleChildRowIndex++;
      possibleChildRow = newDataRows[possibleChildRowIndex];
    }
    else {
      break;
    }
  }

  if (allRowChildren.length) {
    newDataRows.splice(originalRowIndex + 1, allRowChildren.length);
  }

  for (let i = rowSplits.length - 1; i >= 0; i--) {
    const { name, ratio } = rowSplits[i];
    const ratioFloat = parseFloat(ratio);
    const ratioMultiplier = ratioFloat / (totalRatio || 1);
    const rowIndex = newDataRows.findIndex((item) => item === row);

    const quantity = (parseFloat(row.originalValue?.quantity ?? row.quantity) * ratioMultiplier).toString();
    const cost = row.originalValue?.cost ?? row.cost;
    const inputCost = row.originalValue?.inputCost ?? row.inputCost;
    const inputProjectCurrencyId = (row.originalValue?.inputProjectCurrencyId !== undefined) ?
      row.originalValue?.inputProjectCurrencyId :
      row.inputProjectCurrencyId;

    const newRow = cloneDeep(row);
    newRow.name = ` ${getLeadingWhitespace(row.name)}${(allRowChildren.length) ? name.trim() : name}`;
    newRow.quantity = quantity;
    newRow.cost = cost;
    newRow.inputCost = inputCost;
    newRow.inputProjectCurrencyId = inputProjectCurrencyId;
    newRow.originalValue = {
      quantity,
      cost,
      inputCost,
      inputProjectCurrencyId,
      units: newRow.originalValue?.units ?? { quantity: '' }
    };

    newRow.clientSideGuid = Uuidv4();
    newRow.id = nextId;
    newRow.clientSidePredictionId = nextId;
    nextId--;

    // For the new WBS row replace the original row overrides with new overrides adjusted to the split ratio
    if (!isResources && newRow.resourceOverrides) {
      for (const override of newRow.resourceOverrides) {
        const newResourceOverride = {
          id: override.id,
          quantity: (parseFloat(override.quantity ?? '0') * ratioMultiplier).toString(),
          cost: override.cost,
          inputCost: override.cost,
          inputProjectCurrencyId: null,
          units: override.units,
          needsAddToServer: true
        };

        if (!newOverridesByWbsRowId[newRow.clientSidePredictionId]) {
          newOverridesByWbsRowId[newRow.clientSidePredictionId] = [];
        }

        newOverridesByWbsRowId[newRow.clientSidePredictionId].push(newResourceOverride);
      }

      newRow.resourceOverrides = [];
    }

    const rowsToInsert = [newRow];
    const oldChildIdToNew: { [index: string]: number } = {};

    // Copy any children of the split row
    if (allRowChildren.length) {
      for (const childRow of allRowChildren) {
        const newChild = cloneDeep(childRow);
        newChild.uiState.isDeleting = false;

        const newChildQuantity = (
          parseFloat(newChild.quantity) *
          ratioMultiplier
        ).toString();
        const newChildCost = newChild.cost;

        newChild.name = ` ${newChild.name}`;
        newChild.quantity = newChildQuantity;
        newChild.cost = newChildCost;
        newChild.inputCost = newChildCost;
        newChild.inputProjectCurrencyId = null;
        newChild.originalValue = {
          quantity: newChildQuantity,
          cost: newChildCost,
          inputCost: newChildCost,
          inputProjectCurrencyId: null,
          units: newChild.originalValue?.units ?? { quantity: '' }
        };

        newChild.clientSideGuid = Uuidv4();
        newChild.id = nextId;
        newChild.clientSidePredictionId = nextId;
        oldChildIdToNew[childRow.clientSidePredictionId] = nextId;
        nextId--;

        if (!isResources && newChild.resourceOverrides) {
          // For new WBS row children replace the original row overrides with new overrides adjusted to the split ratio
          for (const originalOverride of newChild.resourceOverrides) {
            if (originalOverride.inputCost === undefined) {
              continue;
            }

            const newResourceOverride = {
              id: originalOverride.id,
              quantity: (parseFloat(originalOverride.quantity ?? '0') * ratioMultiplier).toString(),
              cost: originalOverride.cost,
              inputCost: originalOverride.inputCost,
              inputProjectCurrencyId: null,
              units: originalOverride.units,
              needsAddToServer: true
            };

            if (!newOverridesByWbsRowId[newChild.clientSidePredictionId]) {
              newOverridesByWbsRowId[newChild.clientSidePredictionId] = [];
            }

            newOverridesByWbsRowId[newChild.clientSidePredictionId].push(newResourceOverride);
          }

          newChild.resourceOverrides = [];
        }
        else if (isResources) {
          // For resource rows find resource overrides for the original child row add a new override for this new child
          // row adjusted to the split ratio
          for (const [wbsRowId, overrides] of Object.entries(matchingOverridesByWbsRowId)) {
            const matchingOverride = overrides.find((o) => o.id === childRow.clientSidePredictionId);
            if (!matchingOverride) {
              continue;
            }

            const newResourceOverride = {
              id: newChild.id,
              quantity: (parseFloat(matchingOverride.quantity ?? '0') * ratioMultiplier).toString(),
              cost: matchingOverride.cost,
              inputCost: matchingOverride.inputCost,
              inputProjectCurrencyId: null,
              units: matchingOverride.units,
              needsAddToServer: true
            };

            if (!newOverridesByWbsRowId[wbsRowId]) {
              newOverridesByWbsRowId[wbsRowId] = [];
            }

            newOverridesByWbsRowId[wbsRowId].push(newResourceOverride);
          }
        }

        rowsToInsert.push(newChild);
      }
    }

    newDataRows.splice(rowIndex + 1, 0, ...rowsToInsert);

    if (isResources) {
      // For resource rows find resource overrides for the original row add a new override for this new row
      // adjusted to the split ratio
      for (const [wbsRowId, overrides] of Object.entries(matchingOverridesByWbsRowId)) {
        const matchingOverride = overrides.find((o) => o.id === row.clientSidePredictionId);
        if (!matchingOverride) {
          continue;
        }

        const newResourceOverride = {
          id: newRow.id,
          quantity: (parseFloat(matchingOverride.quantity ?? '0') * ratioMultiplier).toString(),
          cost: matchingOverride.cost,
          inputCost: matchingOverride.inputCost,
          inputProjectCurrencyId: null,
          units: matchingOverride.units,
          needsAddToServer: true
        };

        if (!newOverridesByWbsRowId[wbsRowId]) {
          newOverridesByWbsRowId[wbsRowId] = [];
        }

        newOverridesByWbsRowId[wbsRowId].push(newResourceOverride);
      }
    }
  }

  let newSortOrder = 0;
  for (let i = 0; i < newDataRows.length; i++) {
    const newRow = newDataRows[i];
    if (newRow.uiState.isDeleting) {
      continue;
    }

    newRow.sortOrder = newSortOrder++;
  }

  newDataRows.push(...allRowChildren);
  return { newDataRows, newOverridesByWbsRowId };
};

export const generatePreviousWBSLevels = (wbsCode: string): string[] => {

  const levels: string[] = [];
  const segments = wbsCode.split(' ');

  while (segments.length > 0) {
    levels.push(segments.join(' '));
    segments.pop();
  }

  return levels.reverse();
};
