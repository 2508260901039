import { CostManagementRootState, WBSRow } from '../../stateTypes';
import {
  getDataByTimeInterval,
  getDataByTimeIntervalMap,
  TimeInterval
} from '../../../components/Project/ProjectDashboard/utils/chart';
import { getRowForecast } from './forecastTable';
import { differenceInDays } from 'date-fns';
import { getChildRows } from 'wbs/dist/getChildRows';
import { current } from '@reduxjs/toolkit';
import { getNextPeriod } from '../../../helpers/date';
import { CostManagementDashboardScenarioShape } from '../../../types/api/CostManagementDashboardShape';

const getElementsAfterLabels = (label: string, array: string[]): string[] => {

  const index = array.indexOf(label);

  if (index !== -1) {
    return array.slice(index + 1);
  }

  return [];
};

export const calculateSelectedWBSRowCalculateForecast = (state: CostManagementRootState) => {

  const rowIds = Object.keys(state.selectedWBSRowForecastTable);
  state.selectedWBSRowCalculateForecast = state.selectedWBSRowLabels.map((rowLabel) => {

    let total = 0;
    rowIds.forEach((rowId) => {

      total += state.selectedWBSRowForecastTable[rowId][rowLabel] ?? 0;
    });

    return total;
  });
};

export const calculateSelectedWBSRowPreviousForecastTable = (state: CostManagementRootState) => {

  state.selectedWBSRowPreviousForecastTable = state.selectedWBSRowChildren.reduce((map, wbsRow) => {

    map[wbsRow.id] = getRowForecast(state, wbsRow, state.selectedScenario?.previousPeriod);
    return map;
  }, {} as Record<number, Record<string, number>>);
};


export const calculateSelectedWBSRowCalculatePreviousForecast = (state: CostManagementRootState) => {

  const rowIds = Object.keys(state.selectedWBSRowForecastTable);
  state.selectedWBSRowCalculatePreviousForecast = state.selectedWBSRowLabels.map((rowLabel) => {

    let total = 0;
    rowIds.forEach((rowId) => {

      total += state.selectedWBSRowPreviousForecastTable[rowId][rowLabel] ?? 0;
    });

    return total;
  });
};

export const calculateSelectedWBSRowForecastTable = (state: CostManagementRootState) => {

  state.selectedWBSRowForecastTable = state.selectedWBSRowChildren.reduce((map, wbsRow) => {

    map[wbsRow.id] = getRowForecast(state, wbsRow, state.selectedScenario?.currentPeriod);
    return map;
  }, {} as Record<number, Record<string, number>>);
};

export const getUnmodifiedSelectedWBSRowForecastTable = (state: CostManagementRootState) => {

  return state.selectedWBSRowChildren.reduce((map, wbsRow) => {

    map[wbsRow.id] = getRowForecast(state, wbsRow, state.selectedScenario?.currentPeriod, true);
    return map;
  }, {} as Record<number, Record<string, number>>);
};

export const getUnmodifiedEditedWBSRowForecastTable = (state: CostManagementRootState, leafRows: any[]) => {

  return leafRows.reduce((map, wbsRow) => {

    map[wbsRow.id] = getRowForecast(state, wbsRow, state.selectedScenario?.currentPeriod, true);
    return map;
  }, {} as Record<number, Record<string, number>>);
};

export const updateScenarioCurveForRow = (
  selectedScenario: CostManagementDashboardScenarioShape,
  label: string,
  unmodifiedForecastTable: Record<number, Record<string, number>>,
  rowToUpdate: WBSRow,
  increasedValue: number
) => {

  let newRowEndDate: undefined | string;
  const afterLabels = getElementsAfterLabels(label, Object.keys(unmodifiedForecastTable[rowToUpdate.id]));

  if (afterLabels.length === 0) {
    const { date: newEndDate, label: newNextLabel } = getNextPeriod(selectedScenario.reportingPeriod, label);
    unmodifiedForecastTable[rowToUpdate.id][newNextLabel] = -increasedValue;

    if (!rowToUpdate.endDate || (new Date(rowToUpdate.endDate) < newEndDate)) {
      newRowEndDate = newEndDate.toISOString();
      rowToUpdate.endDate = newRowEndDate;
    }
  }
  else {
    if (increasedValue > 0) {
      // Starting from the last period, attempt to subtract the increased value from each period
      let remainingIncrease = increasedValue;
      for (let i = afterLabels.length - 1; i >= 0; i--) {
        if (remainingIncrease <= 0) {
          break;
        }

        const currentLabel = afterLabels[i];
        const currentVal = unmodifiedForecastTable[rowToUpdate.id][currentLabel];
        if (i === 0 && remainingIncrease > currentVal) {
          // if we reach the last after label (next period after edited period since iterating backwards),
          // and there is still a remaining increase, allow it to become negative with the leftover amount
          unmodifiedForecastTable[rowToUpdate.id][currentLabel] = currentVal - remainingIncrease;
        }
        else {
          unmodifiedForecastTable[rowToUpdate.id][currentLabel] = Math.max(0, currentVal - remainingIncrease);
        }

        remainingIncrease -= currentVal;
      }
    }
    else {
      unmodifiedForecastTable[rowToUpdate.id][afterLabels[0]] -= increasedValue;
    }
  }

  const curveData = selectedScenario.curveData.find(
    (curve) => (

      curve.wbsId === rowToUpdate.id &&
      curve.reportingPeriod === selectedScenario.reportingPeriod &&
      curve.period === selectedScenario.currentPeriod
    )
  );

  let newCurveData = unmodifiedForecastTable[rowToUpdate.id];
  try {
    newCurveData = current(unmodifiedForecastTable[rowToUpdate.id]);
  }
  catch (error) {
    newCurveData = unmodifiedForecastTable[rowToUpdate.id];
  }

  if (curveData) {
    curveData.curveData = newCurveData;
  }
  else {
    selectedScenario.curveData.push({
      id: -1,
      scenarioId: selectedScenario.id,
      wbsId: rowToUpdate.id,
      wbsCode: rowToUpdate.wbsCode,
      wbsName: rowToUpdate.name,
      reportingPeriod: selectedScenario.reportingPeriod,
      period: selectedScenario.currentPeriod ?? '',
      curveData: newCurveData
    });
  }

  const previousCurveData = selectedScenario.curveData.find(
    (curve) => (

      curve.wbsId === rowToUpdate.id &&
      curve.reportingPeriod === selectedScenario.reportingPeriod &&
      curve.period === selectedScenario.previousPeriod
    )
  );

  if (!previousCurveData) {
    selectedScenario.curveData.push({
      id: -1,
      scenarioId: selectedScenario.id,
      wbsId: rowToUpdate.id,
      wbsCode: rowToUpdate.wbsCode,
      wbsName: rowToUpdate.name,
      reportingPeriod: selectedScenario.reportingPeriod,
      period: selectedScenario.previousPeriod ?? '',
      curveData: newCurveData
    });
  }

  return {
    scenarioId: selectedScenario.id,
    reportingPeriod: selectedScenario.reportingPeriod,
    wbsRowId: rowToUpdate.id,
    wbsRowCode: rowToUpdate.wbsCode,
    wbsRowName: rowToUpdate.name,
    curveData: newCurveData,
    newRowEndDate
  };
};

export const updateVariances = (state: CostManagementRootState) => {

  const reportingPeriod = (state.selectedScenario?.reportingPeriod as TimeInterval) ?? 'Month';

  state.wbsRows = state.wbsRows.map((currentRow) => {

    const currentWbsCode = currentRow.wbsCode;
    const wbsRowChildren = getChildRows(state.wbsLookup.codeLookup, currentWbsCode) as WBSRow[];
    const currentActuals = getDataByTimeIntervalMap(
      currentRow.monthlyCosts.actual,
      reportingPeriod as TimeInterval,
      currentRow.monthlyCosts.startDate,
      currentRow.monthlyCosts.endDate
    );

    const wbsRowForecastTable = wbsRowChildren.reduce((map, wbsRow) => {

      map[wbsRow.id] = getRowForecast(state, wbsRow, state.selectedScenario?.currentPeriod);
      return map;
    }, {} as Record<number, Record<string, number>>);

    const wbsRowPreviousForecastTable = wbsRowChildren.reduce((map, wbsRow) => {

      map[wbsRow.id] = getRowForecast(state, wbsRow, state.selectedScenario?.previousPeriod);

      return map;
    }, {} as Record<number, Record<string, number>>);

    const [labels] = getDataByTimeInterval(
      currentRow.monthlyCosts.actual,
      reportingPeriod as TimeInterval,
      currentRow.monthlyCosts.startDate,
      currentRow.monthlyCosts.endDate
    );

    const rowIds = Object.keys(wbsRowPreviousForecastTable) as unknown as number[];
    const wbsRowCalculateForecast = labels.map((rowLabel) => {

      let total = 0;
      rowIds.forEach((rowId) => {

        total += Object.values(wbsRowForecastTable[rowId] ?? {}).reduce((sum, a) => sum + a, 0);
      });

      return total;
    });

    const previousPeriod = state.selectedScenario?.previousPeriod ?? '';
    const previousPeriodIndex = labels.indexOf(previousPeriod);
    const actualPreviousPeriod = previousPeriodIndex === -1 ? 0 : currentActuals[previousPeriodIndex] ?? 0;
    const currentForecastPreviousPeriod = previousPeriodIndex === -1 ? 0 : wbsRowCalculateForecast[previousPeriodIndex] ?? 0;

    const actualVariance = actualPreviousPeriod - currentForecastPreviousPeriod;

    return {
      ...currentRow,
      actualVariance
    };
  });
};


export const selectRowId = (state: CostManagementRootState, selectedWBSId: number) => {

  const reportingPeriod = (state.selectedScenario?.reportingPeriod as TimeInterval) ?? 'Month';
  const selectedWBSRow = state.wbsRows.find((row) => row.id === selectedWBSId);
  state.selectedWBSRow = selectedWBSRow;

  const selectedWbsCode = state.selectedWBSRow?.wbsCode;
  state.selectedWBSRowChildren = (selectedWbsCode) ?
    state.wbsRows?.filter((row: WBSRow) => {

      return (
        row.wbsCode.startsWith(selectedWbsCode) &&
        row.wbsCode.split(' ').length === selectedWbsCode.split(' ').length + 1
      );
    }) :
    [] as WBSRow[];

  state.selectedWBSValueCustomCurves =
    state.wbsValues.filter((val) => val.wbsCode.includes(state.selectedWBSRow?.wbsCode ?? ''));
  const selectedCurveIds = new Set(state.selectedWBSValueCustomCurves.map((val) => val.customCurveId));
  const selectedAdditionalCurves = state.additionalCurves.filter((curve) => selectedCurveIds.has(curve.id));
  state.selectedAdditionalCurves = selectedAdditionalCurves;

  calculateSelectedWBSRowForecastTable(state);
  calculateSelectedWBSRowPreviousForecastTable(state);

  state.maxNumberOfDays =
    Math.max(...state.wbsRows.map((row) => (differenceInDays(new Date(row.endDate), new Date(row.startDate)))));

  if (selectedWbsCode && selectedWBSRow) {

    const monthlyCosts = selectedWBSRow.monthlyCosts;
    const [labels] = getDataByTimeInterval(
      monthlyCosts.actual,
      reportingPeriod as TimeInterval,
      monthlyCosts.startDate,
      monthlyCosts.endDate
    );
    const [, calculateActualValues] = getDataByTimeInterval(
      monthlyCosts.actual,
      reportingPeriod as TimeInterval,
      monthlyCosts.startDate,
      monthlyCosts.endDate
    );

    state.selectedWBSRowLabels = labels;

    calculateSelectedWBSRowCalculateForecast(state);
    calculateSelectedWBSRowCalculatePreviousForecast(state);
    state.unmodifiedSelectedWBSRowForecastTable = getUnmodifiedSelectedWBSRowForecastTable(state);

    state.selectedWBSRowPeriodLabels = labels;
    state.selectedWBSRowCalculateActuals = calculateActualValues;
  }
};
