import { Draft } from '@reduxjs/toolkit';
import { WBSTableRootState } from '../../stateTypes';
import { parseCodeNumbers } from 'wbs/dist/parseCodeNumbers';
import _ from 'lodash';
import { WBSDataRowShapeWithStates } from '../../wbs/calculateWBSState';
import { nextRow, nextWbsCodeWithLevel } from './wbsUtils';
import { updateWbsRows } from './api';
import { calculateWbsCodeAndGetChangeSet } from './wbsCode';
import store from '../../store';
import { updateWbsPushRequested } from '../wbsDataTableSlice';
import { getCurrentPhaseId } from 'wbs/dist/getCurrentPhaseId';


export const insertInitialLevelsToWbsDataBeforePush = (state: Draft<WBSTableRootState>, primaryCurrencySymbol: string) => {

  const {
    wbsMetadata: metadata,
    wbsDataRows: allWbsDataRows
  } = state;

  if (!metadata?.itemLevel || !metadata?.divisionLevel) {
    return;
  }

  const maxCodeNumberLevel = metadata.itemLevel;
  const hasChangesetIds = new Set<number>();
  let latestWbsDataRows = allWbsDataRows;
  let changeSet: any[] = [];

  for (let i = 0; i < allWbsDataRows.length; i++) {
    const currentDataRow = allWbsDataRows[i];
    const nextDataRow = allWbsDataRows[i + 1];
    const currentCodeLevel = parseCodeNumbers(currentDataRow.wbsCode).length;
    const nextCodeLevel = _.defaultTo(parseCodeNumbers(nextDataRow?.wbsCode).length, 0);
    const thisRowCanHaveDefaultRows = (currentCodeLevel < maxCodeNumberLevel);
    const shouldInsertInitialLevelsToWBSData = (thisRowCanHaveDefaultRows && nextCodeLevel <= currentCodeLevel);

    if (!shouldInsertInitialLevelsToWBSData) {
      continue;
    }

    let numLevelsToAdd = maxCodeNumberLevel - currentCodeLevel;
    const newDataRows: WBSDataRowShapeWithStates[] = [];
    let updatedCurrentRow = currentDataRow;

    while (numLevelsToAdd > 0) {

      const overrides = {
        increaseLevel: 1,
        name: currentDataRow.name,
        cost: currentDataRow.cost,
        quantity: currentDataRow.quantity,
        units: currentDataRow.units,
        total: currentDataRow.total,
        resourceOverrides: currentDataRow.resourceOverrides
      };

      updatedCurrentRow = nextRow(
        false,
        primaryCurrencySymbol,
        updatedCurrentRow,
        overrides,
        metadata?.wbsCodeSeparator ?? ' ',
        metadata?.wbsCodeCustomizations ?? [],
        getCurrentPhaseId(state.milestones, false)
      );
      newDataRows.push(updatedCurrentRow);
      numLevelsToAdd--;
    }

    const currentIndex =
      latestWbsDataRows.findIndex((r) => r.clientSidePredictionId === currentDataRow.clientSidePredictionId) + 1;
    const beforeCurrentRows = latestWbsDataRows.slice(0, currentIndex);
    const afterCurrentRows = latestWbsDataRows.slice(currentIndex);

    for (const row of afterCurrentRows) {
      const wbsCode = nextWbsCodeWithLevel(row.wbsCode, numLevelsToAdd);
      const sortOrder = row.sortOrder + newDataRows.length;

      row.wbsCode = wbsCode;
      row.sortOrder = sortOrder;
      changeSet.push({ id: row.id, wbsCode, sortOrder });
      if (row.clientSidePredictionId > 0) {
        hasChangesetIds.add(row.clientSidePredictionId);
      }
    }

    const allUpdatedWbsDataRows: WBSDataRowShapeWithStates[] = [
      ...beforeCurrentRows,
      ...newDataRows,
      ...afterCurrentRows
    ];

    latestWbsDataRows = allUpdatedWbsDataRows;
  }

  const sortOrderById: { [index: string]: number } = {};
  for (let i = 0; i < latestWbsDataRows.length; i++) {
    const row = latestWbsDataRows[i];
    row.sortOrder = i;
    sortOrderById[row.clientSidePredictionId] = i;

    // Add sort order changes to the changeset if it has not already been changed
    if (row.clientSidePredictionId > 0 && !hasChangesetIds.has(row.clientSidePredictionId)) {
      changeSet.push({ id: row.clientSidePredictionId, sortOrder: i });
    }
  }

  changeSet = calculateWbsCodeAndGetChangeSet(
    latestWbsDataRows,
    changeSet,
    state.wbsMetadata?.rbsCodeSeparator ?? ' ',
    state.wbsMetadata?.rbsCodeCustomizations ?? [],
    state.wbsMetadata?.wbsCodeSeparator ?? ' ',
    state.wbsMetadata?.wbsCodeCustomizations ?? [],
    state.wbsMetadata?.projectDataId ?? -1,
    state.wbsMetadata?.directCostDataId ?? -1,
    state.wbsMetadata?.finalCostsDataId ?? -1
  );

  const changesById: { [index: string]: any } = {};
  const combinedChangeset: any[] = [];

  for (const change of changeSet) {
    if (changesById[change.id]) {
      changesById[change.id] = { ...changesById[change.id], ...change };
    }
    else {
      changesById[change.id] = { ...change };
      combinedChangeset.push(changesById[change.id]);
    }

    changesById[change.id].sortOrder = sortOrderById[change.id] ?? changesById[change.id].sortOrder;
  }

  if (combinedChangeset.length > 0) {
    // This should be move out of there as reducer should not call apis.
    updateWbsRows(JSON.parse(JSON.stringify(combinedChangeset)))
      .then(() => store.dispatch(updateWbsPushRequested(true)));
  }
  else {
    state.wbsPushRequested = true;
  }

  state.wbsDataRows = latestWbsDataRows;
};
