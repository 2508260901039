export const APP_SET_IS_LOGGED_IN = 'APP_SET_IS_LOGGED_IN';
export const APP_SET_IS_LOADING = 'APP_SET_IS_LOADING';
export const APP_SET_IS_LOADING_GRAND_TOTAL = 'APP_SET_IS_LOADING_GRAND_TOTAL';
export const APP_SET_REDIRECT_ON_ERROR = 'APP_SET_REDIRECT_ON_ERROR';
export const APP_SET_SHOW_ERROR = 'APP_SET_SHOW_ERROR';
export const APP_SET_LOGGED_IN_USER_DETAILS = 'APP_SET_LOGGED_IN_USER_DETAILS';
export const APP_SET_DID_MAKE_API_CHANGE = 'APP_SET_DID_MAKE_API_CHANGE';
export const APP_RESET_DID_MAKE_API_CHANGE = 'APP_RESET_DID_MAKE_API_CHANGE';
export const APP_SET_HAS_SEEN_DRAG_TOOLTIP = 'APP_SET_HAS_SEEN_DRAG_TOOLTIP';
export const APP_SET_HAS_SET_PASSWORD = 'APP_SET_HAS_SET_PASSWORD';
export const APP_SET_SOCKETIO_CONNECTED = 'APP_SET_SOCKETIO_CONNECTED';
export const APP_SET_PROJECT_IMPORTING_DATA_ID = 'APP_SET_PROJECT_IMPORTING_DATA_ID';

export const setIsLoggedIn = (isLoggedIn) => ({
  type: APP_SET_IS_LOGGED_IN,
  isLoggedIn
});

export const setIsLoading = (isLoading) => ({
  type: APP_SET_IS_LOADING,
  isLoading
});

export const setIsLoadingGrandTotal = (isLoadingGrandTotal) => ({
  type: APP_SET_IS_LOADING_GRAND_TOTAL,
  isLoadingGrandTotal
});

export const setShowError = (showError) => ({
  type: APP_SET_SHOW_ERROR,
  showError
});

export const setRedirectOnError = (redirectOnError) => ({
  type: APP_SET_REDIRECT_ON_ERROR,
  redirectOnError
});

export const setLoggedInUserDetails = (userDetails) => ({
  type: APP_SET_LOGGED_IN_USER_DETAILS,
  userDetails
});

export const setDidMakeApiChange = () => ({
  type: APP_SET_DID_MAKE_API_CHANGE
});

export const resetDidMakeApiChange = () => ({
  type: APP_RESET_DID_MAKE_API_CHANGE
});

export const setHasSeenDragTooltip = () => ({
  type: APP_SET_HAS_SEEN_DRAG_TOOLTIP
});


export const setHasSetPassword = () => ({
  type: APP_SET_HAS_SET_PASSWORD
});

export const setSocketioConnected = (isSocketConnected) => ({
  type: APP_SET_SOCKETIO_CONNECTED,
  socketioConnected: isSocketConnected
});

export const setProjectImportingDataId = (projectId) => ({
  type: APP_SET_PROJECT_IMPORTING_DATA_ID,
  projectImportingDataId: projectId
});
