import { BidAttachmentShape } from '../../../types/api/BidAttachmentShape';
import { ModuleDataShape } from '../../../types/api/ModuleDataShape';
import { SectionItemShape } from '../../../types/api/SectionItemShape';


export const MODULE_PDFMARKUP_OPEN_SWAGPDF = 'MODULE_PDFMARKUP_OPEN_SWAGPDF';
export const MODULE_PDFMARKUP_CLOSE_SWAGPDF = 'MODULE_PDFMARKUP_CLOSE_SWAGPDF';
export const MODULE_PDFMARKUP_OPEN_TAKEOFF_MARKUP_MODAL = 'MODULE_PDFMARKUP_OPEN_TAKEOFF_MARKUP_MODAL';
export const MODULE_PDFMARKUP_CLOSE_TAKEOFF_MARKUP_MODAL = 'MODULE_PDFMARKUP_CLOSE_TAKEOFF_MARKUP_MODAL';

export const openSwagPdf = (item: SectionItemShape, moduleData: ModuleDataShape, scope: string) => ({
  type: MODULE_PDFMARKUP_OPEN_SWAGPDF,
  item,
  moduleData,
  scope
});

export const closeSwagPdf = () => ({
  type: MODULE_PDFMARKUP_CLOSE_SWAGPDF
});

export const openTakeoffMarkupModal = (attachment: BidAttachmentShape, scope: string) => ({
  type: MODULE_PDFMARKUP_OPEN_TAKEOFF_MARKUP_MODAL,
  attachment,
  scope
});

export const closeTakeoffMarkupModal = () => ({
  type: MODULE_PDFMARKUP_CLOSE_TAKEOFF_MARKUP_MODAL
});
