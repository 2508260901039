import React, { useState, useCallback } from 'react';
import api from '../../../api';

import { makeStyles } from '@material-ui/core/styles';

import { AppTheme } from '../../../theme-relay';
import ConfirmDialog from '../ConfirmDialog';
import DateTimeDialog from '../DateTimeDialog';
import { TodoMaxLength } from '../../../consts/Todo';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { TodoData } from './Types';

const useStyles = makeStyles((theme: AppTheme) => ({
  checkbox: {
    marginRight: '6px',
    padding: '0',
    top: '6px'
  },
  delete: {
    backgroundColor: theme.palette.response.warningHover
  },
  row: {
    display: 'flex',
    alignItems: 'baseline'
  }
}));

interface Props {
  id: number | string,
  todoData: TodoData,
  open: boolean,
  onClose: () => void
}

const EditTodoDialog = (props: Props) => {

  const {
    todoData,
    open,
    onClose
  } = props;
  const { id, text, isDone, dueDate } = todoData;

  const classes = useStyles();

  const [todoStatus, setTodoStatus] = useState(isDone);
  const [todoDueDate, setTodoDueDate] = useState<string | Date>(dueDate);
  const [todoText, setTodoText] = useState(text);
  const [showDeleteTodoDialog, setShowDeleteTodoDialog] = useState(false);

  const handleChangeDueDate = useCallback(async (newDueDate: string | Date) => {

    const editResponse = await api.User.EditTodo(id, undefined, undefined, newDueDate);

    if (editResponse.res.err) {
      console.error(editResponse.res.err);
    }
    else {
      setTodoDueDate(newDueDate);
    }
  }, [id]);

  const handleDeleteTodo = useCallback(async () => {

    await api.User.DeleteTodos(id);

    setShowDeleteTodoDialog(false);
    onClose();
  }, [id, onClose, setShowDeleteTodoDialog]);

  const handleEditTodo = useCallback(async () => {

    await api.User.EditTodo(id, todoStatus, todoText, todoDueDate || undefined);
  }, [id, todoDueDate, todoStatus, todoText]);

  const handleToggleDone = useCallback(async () => {

    const newTodoStatus = !todoStatus;
    const editResponse = await api.User.EditTodo(id, newTodoStatus);

    if (editResponse.err) {
      console.error(editResponse.err);
    }
    else {
      setTodoStatus(newTodoStatus);
    }
  }, [id, todoStatus]);

  return (
    <Dialog
      id={'edit_todo_' + id}
      data-cy="dialog.edit-todo"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id={id + '_title'}>{'Edit Todo'}</DialogTitle>
      <DialogContent>

        <div className={classes.row}>
          <Checkbox
            id="doneToggle"
            className={classes.checkbox}
            data-cy="edit-todo-dialog.checkbox"
            aria-label="toggle-done"
            onClick={handleToggleDone}
            checked={todoStatus}
          />
          <TextField
            margin='dense'
            id='todoText'
            type='text'
            fullWidth
            value={todoText}
            multiline
            onChange={(event) => setTodoText(event.target.value)}
            onBlur={handleEditTodo}
            inputProps={{
              maxLength: TodoMaxLength,
              'data-cy': 'edit-todo-dialog.text'
            }}
          />
        </div>
        <DateTimeDialog
          dataCy='edit-todo-dialog.dueDate'
          onChange={handleChangeDueDate}
          shouldDelayOnChangeUntilConfirm={true}
          defaultDate={todoDueDate}
          label='Due Date'
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="edit-todo-dialog.done-button"
          onClick={onClose}
          color='secondary'
          variant='contained'
        >
          Done
        </Button>
        <Button
          data-cy="edit-todo-dialog.delete-button"
          onClick={() => setShowDeleteTodoDialog(true)}
          className={classes.delete}
          variant='contained'
        >
          Delete
        </Button>
        <ConfirmDialog
          open={showDeleteTodoDialog}
          title="Delete completed todos"
          description={`Permanently delete todo ${todoText}`}
          handleConfirm={handleDeleteTodo}
          confirmLabel="Delete"
          handleCancel={() => setShowDeleteTodoDialog(false)}
          cancelLabel="Cancel"
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditTodoDialog;
