import React, { MouseEvent, KeyboardEvent, ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import api from '../../../api';
import { AppTheme } from '../../../theme-relay';
import { ProjectApiSlice } from '../../../api/project';
import SelectSectionInProjectDialog from '../SelectSectionInProjectDialog';
import SmallAvatar from '../../Icons/SmallAvatar';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EditIcon from '@material-ui/icons/Edit';

import { AnyAttachment } from '../../Project/FileBrowser/Types';
import { BidderInfo } from '../../../redux/stateTypes';

import {
  BidToAddToProject, Company,
  DivisionInfoShape,
  InputsShape,
  ParentType,
  SelectedCompany
} from './Types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';

const makeFileKey = <T extends AnyAttachment>(file: T) => {

  const projectId = ('projectId' in file) ? file.projectId : 0;
  const sectionId = ('sectionId') in file ? file.sectionId : 0;
  const bidId = ('bidId' in file) ? file.bidId : 0;

  return `${file.id}_${projectId}_${sectionId}_${bidId}`;
};

const getFileParentType = <T extends AnyAttachment>(file: T) => {

  if ('bidId' in file) {
    return ParentType.Bid;
  }

  if ('sectionId' in file) {
    return ParentType.Section;
  }

  return ParentType.Project;
};

const useStyles = makeStyles((theme: AppTheme) => ({
  bidderToAddItem: {
    border: `1px solid ${theme.palette.primary.main}`,

    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  biddersToAddList: {
    width: '100%'
  },
  dialog: {
    padding: theme.spacing(1)
  },
  dialogTitle: {
    textAlign: 'center'
  },
  file: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.app.shadow}`,
    borderRadius: '5px',
    boxShadow: `1px 1px 1px ${theme.palette.app.shadowDark}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    margin: `${theme.spacing(0.75)}px 0`,
    maxHeight: '2.25rem',
    padding: theme.spacing(0.5),
    width: '100%',

    '&:hover': {
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark
    }
  },
  fileCompanyInput: {
    width: '100%'
  },
  fileName: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginLeft: theme.spacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  fileSelectedBidderChip: {
    display: 'flex',
    flexDirection: 'row'
  },
  fileRow: {
    alignItems: 'center'
  },
  matchedBidders: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: theme.spacing(4),
    padding: `0 ${theme.spacing(1)}px`,
    width: '100%'
  },
  matchedBidderChip: {
    margin: theme.spacing(0.5)
  }
}));

interface Props {
  files: AnyAttachment[],
  onCancel: () => void,
  onFilesAssigned: () => void,
  projectCompanyId: number,
  projectDivisionsAndSections?: DivisionInfoShape[]
}

const AssignFilesToBidsDialog = (props: Props) => {

  const classes = useStyles();

  const bidderInfoSorted: BidderInfo[] = useSelector((state: RootStateOrAny) => state.project.bidderInfoSorted);
  const cust = useSelector((state: RootStateOrAny) => state.project.customDesignations);
  const projectId = useSelector((state: RootStateOrAny) => state.project.project.id);

  const {
    files,
    onCancel,
    onFilesAssigned,
    projectCompanyId,
    projectDivisionsAndSections = []
  } = props;

  const originalInputs = useMemo(() => {

    const output: InputsShape = {};

    if (files) {
      for (const file of files) {
        const key = makeFileKey(file);
        output[key] = { selectedCompanies: [] };
      }
    }

    return output;
  }, [files]);

  const [assignBidderInfo, setAssignBidderInfo] = useState<Company | null>(null);
  const [bidsToAddToProject, setBiddersToAddToProject] = useState<{[index: string]: BidToAddToProject}>({});
  const [inputs, setInputs] = useState(originalInputs);
  const [searchResults, setSearchResults] = useState<SelectedCompany[]>([]);

  const [copyFilesToBidders] = ProjectApiSlice.useCopyFilesToBiddersMutation();

  useEffect(() => {

    if (files) {
      setInputs((prev) => {

        const newInputs = { ...prev };
        const keysToDelete = new Set(Object.keys(newInputs));

        for (const file of files) {
          const key = makeFileKey(file);
          if (!newInputs[key]) {
            newInputs[key] = { selectedCompanies: [] };
            keysToDelete.delete(key);
          }
        }

        for (const key of Array.from(keysToDelete)) {
          delete newInputs[key];
        }

        return newInputs;
      });
    }
    else {
      setAssignBidderInfo(null);
      setBiddersToAddToProject({});
      setInputs({});
      setSearchResults([]);
    }
  }, [files]);

  const handleCancel = useCallback(() => {

    setBiddersToAddToProject({});
    setInputs(originalInputs);
    onCancel();
  }, [onCancel, originalInputs]);

  const handleFileClick = useCallback((evt: MouseEvent<HTMLElement>) => {

    if (evt.button > 1) {
      return;
    }

    const url = evt.currentTarget.dataset.url;
    window.open(url, '_blank');
  }, []);

  const afterAssignFiles = useCallback((result: QueryReturnValue) => {

    if (result.error) {
      return;
    }

    if (onFilesAssigned) {
      onFilesAssigned();
    }
  }, [onFilesAssigned]);

  const handleAssignFiles = useCallback(() => {

    const requestData: {
      [ParentType.Bid]?: [number, number[]][],
      bidsToAdd?: { [index: string]: number}
      [ParentType.Project]?: [number, number[]][],
      [ParentType.Section]?: [number, number[]][]
    } = {
      bid: [],
      bidsToAdd: undefined,
      project: [],
      section: []
    };

    for (const [companyId, { sectionId }] of Object.entries(bidsToAddToProject)) {
      if (!requestData.bidsToAdd) {
        requestData.bidsToAdd = {};
      }

      requestData.bidsToAdd[companyId] = sectionId;
    }

    for (const file of files) {
      const key = makeFileKey(file);

      if (inputs[key]?.selectedCompanies?.length) {
        const parent = getFileParentType(file);
        const companyIds = inputs[key].selectedCompanies.map((c) => c.companyId);
        requestData[parent]?.push([file.id, companyIds]);
      }
    }

    for (const parent of [ParentType.Bid, ParentType.Project, ParentType.Section]) {
      if (!requestData[parent]?.length) {
        delete requestData[parent];
      }
    }

    copyFilesToBidders({
      projectId,
      bidsToAdd: requestData.bidsToAdd,
      bidFiles: requestData.bid,
      projectFiles: requestData.project,
      sectionFiles: requestData.section
    }).then(afterAssignFiles);
  }, [afterAssignFiles, bidsToAddToProject, copyFilesToBidders, files, inputs, projectId]);

  const handleFileInputBlur = useCallback(() => setSearchResults([]), []);

  const handleAutocompleteKeyDown = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {

    if (evt.key === 'Enter' && (evt.currentTarget.value.length || !searchResults.length)) {
      const inputIndex = parseInt(evt.currentTarget.dataset.searchInputIndex as string);
      const nextInput: HTMLElement | null = document.querySelector(`input[data-search-input-index="${inputIndex + 1}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  }, [searchResults.length]);

  const removeBidderFromInputs = useCallback((bidderCompanyId: number) => {

    setInputs((prev) => {

      const newInputs = { ...prev };

      for (const value of Object.values(newInputs)) {
        value.selectedCompanies =
          value.selectedCompanies.filter((company) => company.companyId !== bidderCompanyId);
      }

      return newInputs;
    });
  }, []);

  const updateBiddersToAdd = useCallback(() => {

    const companyIdsToRemove = new Set(Object.keys(bidsToAddToProject).map((strId) => parseInt(strId)));

    for (const { selectedCompanies } of Object.values(inputs)) {
      for (const { companyId } of selectedCompanies) {
        companyIdsToRemove.delete(companyId);
      }
    }

    if (companyIdsToRemove.size) {
      setBiddersToAddToProject((prev) => {

        const newValue = { ...prev };
        for (const companyId of Array.from(companyIdsToRemove)) {
          delete newValue[companyId];
        }

        return newValue;
      });
    }
  }, [bidsToAddToProject, inputs]);

  const handleAddBidderListRemoveClick = useCallback((evt: MouseEvent<HTMLElement>) => {

    const companyId = parseInt(evt.currentTarget.dataset.companyId as string);
    removeBidderFromInputs(companyId);
    updateBiddersToAdd();
  }, [removeBidderFromInputs, updateBiddersToAdd]);

  const handleAddBidderListEditClick = useCallback((evt: MouseEvent<HTMLElement>) => {

    const companyId = evt.currentTarget.dataset.companyId as string;
    const bidderInfo = bidsToAddToProject[companyId] || null;
    setAssignBidderInfo(bidderInfo);
  }, [bidsToAddToProject]);

  const handleCloseAssignBidderDialog = useCallback((bidderWasAssigned: boolean) => {

    // If this company is in biddersToAddToProject then the dialog was an edit dialog and we don't want to remove
    // the bidders if the user canceled out of it
    if (!bidderWasAssigned && assignBidderInfo && !bidsToAddToProject[assignBidderInfo.companyId]) {
      removeBidderFromInputs(assignBidderInfo.companyId);
    }

    setAssignBidderInfo(null);
  }, [assignBidderInfo, bidsToAddToProject, removeBidderFromInputs]);

  const handleAssignBidder = useCallback((sectionId: number, divisionId: number) => {

    setBiddersToAddToProject((prev) => {

      if (!assignBidderInfo) {
        return prev;
      }

      const newValue = { ...prev };

      const division = projectDivisionsAndSections.find((d) => d.id === divisionId);

      if (!division) {
        return newValue;
      }

      const divisionName = division.name;
      const section = division.sections.find((s) => s.id === sectionId);
      if (!section) {
        return newValue;
      }

      const sectionName = section.name;

      newValue[assignBidderInfo.companyId] = {
        companyId: assignBidderInfo.companyId,
        companyName: assignBidderInfo.companyName,
        divisionId,
        divisionName,
        sectionId,
        sectionName
      };

      return newValue;
    });

    handleCloseAssignBidderDialog(true);
  }, [assignBidderInfo, handleCloseAssignBidderDialog, projectDivisionsAndSections]);

  const handleShowAssignBidderDialog = useCallback((bidderInfo: Company) => setAssignBidderInfo(bidderInfo), []);

  const handleMatchedBidderClick = useCallback((evt: MouseEvent<HTMLElement>) => {

    const companyId = parseInt(evt.currentTarget.dataset.companyId as string);
    const companyName = evt.currentTarget.dataset.companyName as string;
    const fileKey = evt.currentTarget.dataset.fileKey as string;
    const isNewBidder = (evt.currentTarget.dataset.isNewBidder === 'true');

    setInputs((prev) => {

      const newInputs = { ...prev };

      if (!newInputs[fileKey].selectedCompanies.find((c: SelectedCompany) => c.companyId === companyId)) {
        newInputs[fileKey].selectedCompanies.push({ companyId, companyName, isNewBidder });
      }

      return newInputs;
    });
  }, []);

  const searchCompanies = useCallback((searchText: string, omitCompanyIds: number[]) => {

    if (searchText.length < 3) {
      return;
    }

    api.Project.SearchCompanies(searchText, omitCompanyIds, true, 100).then((result) => {

      if (!result.err) {

        const existingCompanyIds = new Set(bidderInfoSorted.map((b) => b.companyId));
        const alreadyInResults = new Set();
        const filteredResults = [];

        for (const resultInfo of result.res.body) {
          if (alreadyInResults.has(resultInfo.companyId)) {
            continue;
          }

          filteredResults.push({
            companyId: resultInfo.companyId,
            companyName: resultInfo.companyName,
            isNewBidder: !existingCompanyIds.has(resultInfo.companyId)
          });

          alreadyInResults.add(resultInfo.companyId);
        }

        setSearchResults(filteredResults);
      }
    });
  }, [bidderInfoSorted]);

  const handleFileInputChange =
    useCallback((evt: ChangeEvent<{}>, enteredText: string, reason: AutocompleteInputChangeReason) => {

      if (!enteredText || reason === 'reset') {
        return;
      }

      const target = evt.currentTarget as HTMLElement;
      const fileKeyElement = target.closest<HTMLElement>('[data-file-key]') as HTMLElement;
      const fileKey = fileKeyElement.dataset.fileKey as string;
      const omitCompanyIds = inputs[fileKey].selectedCompanies.map((c) => c.companyId);
      omitCompanyIds.push(projectCompanyId);
      searchCompanies(enteredText, omitCompanyIds);
    }, [inputs, projectCompanyId, searchCompanies]);

  const handleSelectedBiddersChanged =
    useCallback((evt: ChangeEvent<{}>, selectedBidders: (string | SelectedCompany)[], reason: string) => {

      if (reason === 'reset') {
        return;
      }

      let fileKey: string | undefined;
      if (reason === 'remove-option' || reason === 'clear') {
        const target = evt.currentTarget as HTMLElement;
        const fileKeyElement = target.closest<HTMLElement>('[data-file-key]') as HTMLElement;
        fileKey = fileKeyElement.dataset.fileKey as string;
      }
      else if (reason === 'select-option') {
        const currentTarget = evt.currentTarget as HTMLElement;
        const parentElement = currentTarget.parentElement as HTMLElement;
        const fileKeyElement = parentElement.querySelector<HTMLElement>('[data-file-key]') as HTMLElement;
        fileKey = fileKeyElement.dataset.fileKey as string;
      }

      if (!fileKey) {
        return;
      }

      setInputs((prev) => {

        const newInputs = { ...prev };
        if (fileKey !== undefined) {
          newInputs[fileKey].selectedCompanies = selectedBidders as SelectedCompany[];
        }

        return newInputs;
      });

      const newBidderToAssign = (selectedBidders as unknown[] as SelectedCompany[])
        .find((c) => c.isNewBidder && !bidsToAddToProject[c.companyId]);
      if (newBidderToAssign) {
        handleShowAssignBidderDialog(newBidderToAssign);
      }

      if (reason === 'remove-option' || reason === 'clear') {
        updateBiddersToAdd();
      }
    }, [bidsToAddToProject, handleShowAssignBidderDialog, updateBiddersToAdd]);

  const renderAutocompleteSelectedCompany =
    useCallback((selectedOptions: SelectedCompany[], getTagProps: AutocompleteGetTagProps) => {

      const existingBidderIds = new Set(bidderInfoSorted.map((b) => b.companyId));

      return selectedOptions.map((option: SelectedCompany, index: number) => {

        let isNewBidderIcon = null;
        if (!existingBidderIds.has(option.companyId)) {
          const color = (!bidsToAddToProject[option.companyId]) ? 'error' : 'primary';
          isNewBidderIcon = <AddIcon fontSize="small" color={color} />;
        }

        const avatarStyle = {
          fontSize: '10px',
          height: '16px',
          marginRight: '2px',
          marginTop: '2px',
          width: '16px'
        };

        const label = (
          <span className={classes.fileSelectedBidderChip}>
            <SmallAvatar
              disableTooltip
              id={option.companyId}
              name={option.companyName}
              style={avatarStyle}
              useDefaultSize={false}
            />
            {isNewBidderIcon}
            {option.companyName}
          </span>
        );

        return (
          <Chip
            variant="outlined"
            label={label}
            size="small"
            data-cy={`assign-files-dialog.file-input.chip.${option.companyId}`}
            {...getTagProps({ index })}
          />
        );
      });
    }, [classes, bidderInfoSorted, bidsToAddToProject]);

  const renderMatchedBidderChips = useCallback((file: AnyAttachment) => {

    const thisFileKey = makeFileKey(file);
    const thisFileNameSplit = file.displayFilename.toLowerCase().split(/[_ .]/g);

    if (!inputs[thisFileKey]) {
      return null;
    }

    const matchingCompanyInfo = [];
    const output = [];
    const thisFileCompanyIds = inputs[thisFileKey].selectedCompanies.map((c) => c.companyId);
    const filteredBidderOptions = bidderInfoSorted.filter((bidder) => !thisFileCompanyIds.includes(bidder.companyId));

    for (const bidder of filteredBidderOptions) {
      if (thisFileCompanyIds.includes(bidder.companyId)) {
        continue;
      }

      const bidderCopy = { isNewBidder: false, ...bidder, count: 0 };
      const bidderNameSplit = bidderCopy.name.toLowerCase().split(/[_ .]/g);
      for (const part of bidderNameSplit) {
        if (thisFileNameSplit.includes(part)) {
          bidderCopy.count = (bidderCopy.count || 0) + 1;
        }

      }

      if (bidderCopy.count) {
        matchingCompanyInfo.push(bidderCopy);
      }
    }

    const suggestedCompanies = matchingCompanyInfo.sort((a, b) => b.count - a.count).slice(0, 3);

    const renderedCompanyIds = new Set();
    for (const companyInfo of suggestedCompanies) {
      if (renderedCompanyIds.has(companyInfo.companyId)) {
        continue;
      }

      renderedCompanyIds.add(companyInfo.companyId);

      const label = (
        <span className={classes.fileSelectedBidderChip}>
          <SmallAvatar name={companyInfo.name} id={companyInfo.companyId} />
          {companyInfo.name}
        </span>
      );

      output.push(
        <Chip
          key={companyInfo.companyId}
          className={classes.matchedBidderChip}
          clickable
          color="primary"
          data-cy={`assign-files-dialog.matched-bidder.${companyInfo.companyId}`}
          data-company-id={companyInfo.companyId}
          data-company-name={companyInfo.name}
          data-file-key={thisFileKey}
          data-is-new-bidder={companyInfo.isNewBidder}
          label={label}
          onClick={handleMatchedBidderClick}
        />
      );
    }

    return output;
  }, [bidderInfoSorted, classes, handleMatchedBidderClick, inputs]);

  const renderFileRow = useCallback((file: AnyAttachment, index: number) => {

    const key = makeFileKey(file);
    const currentSelectedCompanies = inputs[key]?.selectedCompanies || [];
    const matchedBidders = renderMatchedBidderChips(file);

    return (
      <Grid key={key} className={classes.fileRow} container spacing={3}>
        <Grid className={classes.file} data-url={file.url} onMouseDown={handleFileClick} item md={4}>
          <DescriptionOutlinedIcon color="secondary" fontSize="small" />
          <div className={classes.fileName} title={file.displayFilename}>
            {file.displayFilename}
          </div>
        </Grid>

        <Grid className={classes.fileCompanyInput} item md={4}>
          <Autocomplete
            autoHighlight
            autoSelect
            data-file-key={key}
            filterSelectedOptions
            freeSolo
            getOptionLabel={(option) => option.companyName}
            getOptionSelected={(option, value) => option.companyId === value.companyId}
            multiple
            onBlur={handleFileInputBlur}
            onChange={handleSelectedBiddersChanged}
            onInputChange={handleFileInputChange}
            options={currentSelectedCompanies.concat(searchResults)}
            renderInput={(params) => {

              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: handleAutocompleteKeyDown,
                    'data-cy': `assign-files-dialog.file-input.${file.displayFilename}`,
                    'data-search-input-index': index
                  }}
                  label="Choose bidders"
                  size="small"
                  variant="outlined"
                />
              );
            }}
            renderOption={(option) => <span data-file-key={key} data-cy="search-result">{option.companyName}</span>}
            renderTags={renderAutocompleteSelectedCompany}
            size="small"
            value={currentSelectedCompanies}
          />
        </Grid>

        <Grid className={classes.matchedBidders} item md={4}>
          {matchedBidders}
        </Grid>
      </Grid>
    );
  }, [
    classes,
    handleAutocompleteKeyDown,
    handleFileClick,
    handleFileInputBlur,
    handleFileInputChange,
    handleSelectedBiddersChanged,
    inputs,
    renderAutocompleteSelectedCompany,
    renderMatchedBidderChips,
    searchResults
  ]);

  const fileAssignmentList = useMemo(() => {

    if (!files?.length) {
      return null;
    }

    return (
      <div>
        {files.map(renderFileRow)}
      </div>
    );
  }, [files, renderFileRow]);

  const isAssignButtonDisabled = useMemo(() => {

    let biddersAreSelected = false;
    for (const { selectedCompanies } of Object.values(inputs)) {
      if (selectedCompanies.length) {
        biddersAreSelected = true;
      }

      for (const { companyId, isNewBidder } of selectedCompanies) {
        if (isNewBidder && !bidsToAddToProject[companyId]) {
          return true;
        }
      }
    }

    return !biddersAreSelected;
  }, [bidsToAddToProject, inputs]);

  const biddersToAddUI = useMemo(() => {

    if (!Object.keys(bidsToAddToProject).length) {
      return null;
    }

    const biddersToAddItems = Object.entries(bidsToAddToProject).map(([companyId, assignmentInfo]) => {

      const { companyName, divisionName, sectionName } = assignmentInfo;
      const companyIdInt = parseInt(companyId);

      const itemText = `${divisionName} > ${sectionName}`;

      return (
        <ListItem
          key={companyId}
          className={classes.bidderToAddItem}
          data-company-id={companyId}
          data-cy="assign-files-dialog.new-bidder"
          dense
        >
          <ListItemAvatar>
            <SmallAvatar id={companyIdInt} name={companyName} useDefaultSize={false} />
          </ListItemAvatar>
          <ListItemText
            primary={companyName}
            secondary={itemText}
          />
          <ListItemSecondaryAction>
            <IconButton
              data-company-id={companyId}
              data-cy="assign-files-dialog.new-bidder.edit-button"
              edge="end"
              onClick={handleAddBidderListEditClick}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              data-company-id={companyId}
              data-cy="assign-files-dialog.new-bidder.delete-button"
              edge="end"
              onClick={handleAddBidderListRemoveClick}
            >
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

    return (
      <List
        className={classes.biddersToAddList}
        dense
        subheader={<ListSubheader component="div">New Bidders</ListSubheader>}
      >
        {biddersToAddItems}
      </List>
    );
  }, [classes, bidsToAddToProject, handleAddBidderListEditClick, handleAddBidderListRemoveClick]);

  return useMemo(() => {

    return (
      <Dialog
        className={classes.dialog}
        data-cy="assign-files-dialog"
        fullWidth
        maxWidth="xl"
        onClose={handleCancel}
        open={Boolean(files)}
        PaperProps={{ className: classes.dialog }}
      >
        <DialogTitle className={classes.dialogTitle}>
          Assign Files to Bids
        </DialogTitle>
        <DialogContent>
          {fileAssignmentList}
          {biddersToAddUI}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="secondary"
            data-cy="assign-files-dialog.cancel-button"
          >
          Cancel
          </Button>
          <Button
            onClick={handleAssignFiles}
            color="secondary"
            disabled={isAssignButtonDisabled}
            variant="contained"
            data-cy="assign-files-dialog.assign-button"
          >
          Confirm
          </Button>
        </DialogActions>

        <SelectSectionInProjectDialog
          divisions={projectDivisionsAndSections}
          isOpen={Boolean(assignBidderInfo)}
          onCancel={handleCloseAssignBidderDialog}
          onSectionSelected={handleAssignBidder}
          title={`Select ${cust.area?.toLowerCase() || 'section'} to add bidder ${assignBidderInfo?.companyName}`}
        />

      </Dialog>
    );
  }, [
    assignBidderInfo,
    biddersToAddUI,
    classes,
    cust,
    fileAssignmentList,
    files,
    handleAssignBidder,
    handleAssignFiles,
    handleCancel,
    handleCloseAssignBidderDialog,
    isAssignButtonDisabled,
    projectDivisionsAndSections
  ]);
};

export default AssignFilesToBidsDialog;
